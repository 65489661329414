import LOGOUT from '../auth'
import {
  DOWNLOAD_INSPECTIONS_LIST_REQUESTED,
  DOWNLOAD_INSPECTIONS_LIST_SUCCESS,
  DOWNLOAD_INSPECTIONS_LIST_FAIL,
  DOWNLOAD_INSPECTIONS_LIST_RESET,
  GET_DOWNLOAD_STATE_REQUESTED,
  GET_DOWNLOAD_STATE_SUCCESS,
  GET_DOWNLOAD_STATE_FAIL,
  VIEW_ALL_DOWNLOADS_REQUESTED,
  VIEW_ALL_DOWNLOADS_SUCCESS,
  VIEW_ALL_DOWNLOADS_FAIL
} from './types'

const initialState = {
  listRequested: false,
  listError: false,
  list: null,
  downloadState: false,
  getDownloadStateRequested: false,
  viewAllDownloadsRequested: false
}

/**
 * Download Inspections data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_INSPECTIONS_LIST_REQUESTED:
      return {
        ...state,
        listRequested: true,
        listError: false
      }
    case DOWNLOAD_INSPECTIONS_LIST_SUCCESS:
      return {
        ...state,
        listRequested: false,
        list: action.list
      }
    case DOWNLOAD_INSPECTIONS_LIST_FAIL:
      return {
        ...state,
        listRequested: false,
        listError: true
      }
    case DOWNLOAD_INSPECTIONS_LIST_RESET:
      return {
        ...state,
        listRequested: false,
        listError: false,
        list: null
      }
    case GET_DOWNLOAD_STATE_REQUESTED:
      return {
        ...state,
        getDownloadStateRequested: true
      }
    case GET_DOWNLOAD_STATE_SUCCESS:
      return {
        ...state,
        downloadState: action.downloadState,
        getDownloadStateRequested: false
      }
    case GET_DOWNLOAD_STATE_FAIL:
      return {
        ...state,
        getDownloadStateRequested: false
      }
    case VIEW_ALL_DOWNLOADS_REQUESTED:
      return {
        ...state,
        viewAllDownloadsRequested: true
      }
    case VIEW_ALL_DOWNLOADS_SUCCESS:
      return {
        ...state,
        downloadState: action.downloadState,
        viewAllDownloadsRequested: false
      }
    case VIEW_ALL_DOWNLOADS_FAIL:
      return {
        ...state,
        viewAllDownloadsRequested: false
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
