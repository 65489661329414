import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'

import * as Yup from 'yup'
import Short from 'short-uuid'
import { isEmpty, omit } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import ErrorTemplate from '../../components/forms-helper/ErrorTemplate'
import GeneralField from '../../components/forms-helper/GeneralField'
import PasswordField from '../../components/forms-helper/PasswordField'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { selectStyles } from '../../components/forms-helper/MultiDropdownStyles'
import BigLoading from '../../components/big-loading'
import PermissionGate from '../../components/permission-gate'
import {
  emailPolicy,
  passwordPolicy
} from '../../components/forms-helper/ValidationPolicies'

import './index.scss'
import { useModals } from '../../helpers/hooks'
import ConfirmationModal from '../../components/confirmation-modal'
import SwitchButton from '../../components/forms-helper/SwitchButton'

const SetupStaffManage = props => {
  const {
    user,
    context,
    history,
    setupUsers,
    resetCheckEmail,
    newUserDetail,
    clearUser,
    createOrEditUser,
    setAlert
  } = props

  const params = useParams()
  const [showModal, dismissModal] = useModals()

  const { verifiedEmail } = setupUsers || {}

  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [createOrEditUserLoading, setCreateOrEditUserLoading] = useState(false)
  const [createOrEditUserError, setCreateOrEditUserError] = useState('')

  const { pdbid } = user?.user_metadata
  const { userDetail, propertiesList, rolesList } = setupUsers || {}
  const { hasMadeInitialRequest, isRequesting, data: staffUser } =
    userDetail || {}

  const [currentRole, setCurrentRole] = useState(null)
  const [permissionRule, setPermissionRule] = useState(null)
  const [formData, setFormData] = useState({
    user_id: null,
    name: '',
    title: '',
    type: null,
    phone: '',
    alt_phone: '',
    email: '',
    role: null,
    notes: '',
    status: true,
    login_access: true,
    generatePassword: true,
    password: '',
    confirmPassword: '',
    permission_rules: []
  })

  const [errors, setErrors] = useState({})

  const isPropertyManager = useMemo(() => {
    const { roles } = user || {}
    return (roles || []).some(role => role === 'Property Manager')
  }, [user])

  const canToggleAccessLogin = useMemo(() => {
    const isCurrentUserRoleIsPropertyManager =
      currentRole?.name === 'Property Manager'
    const isCurrentUserRoleIsAccounting = currentRole?.name === 'Accounting'

    return !(
      isPropertyManager &&
      params.id &&
      (isCurrentUserRoleIsPropertyManager || isCurrentUserRoleIsAccounting)
    )
  }, [isPropertyManager, params, user, permissionRule, currentRole])

  const isPMAndCurrentUserIsLoggedInUser = useMemo(() => {
    return isPropertyManager && user?.email === staffUser?.email
  }, [user, staffUser, isPropertyManager])

  const isNewVerifiedStaffUser = useMemo(() => {
    return !params.id && !isEmpty(verifiedEmail?.email)
  }, [verifiedEmail, params])

  const filteredRoles = useMemo(() => {
    const rolesToToRemove = ['Vendor', 'Administrator', 'Accounting']

    return (rolesList || []).filter(
      role => !rolesToToRemove.includes(role.name)
    )
  }, [rolesList])

  const staffRole = useMemo(
    () => (filteredRoles || []).find(d => d.name === 'Staff'),
    [filteredRoles]
  )

  const typeOptions = useMemo(() => {
    return [
      { value: 'Temporary', name: 'Temporary' },
      { value: 'Part Time', name: 'Part Time' },
      { value: 'Full Time', name: 'Full Time' }
    ]
  }, [])

  const StaffUserSchema = useMemo(() => {
    let schema = Yup.object().shape({
      name: Yup.string().required('Please enter the Name.'),
      title: Yup.string().nullable(),
      type: Yup.object()
        .shape({
          value: Yup.string(),
          name: Yup.string()
        })
        .nullable()
        .required('Please select a type'),
      phone: Yup.string().nullable(),
      alt_phone: Yup.string().nullable(),
      notes: Yup.string().nullable(),
      role: Yup.object()
        .shape({
          role_id: Yup.string(),
          name: Yup.string()
        })
        .nullable()
        .required('Please select a role'),

      email: Yup.string()
        .matches(emailPolicy, () => (
          <div>
            <p>
              The email address you entered is invalid. Please enter your email
              address using standard format.
            </p>
            <p>
              Example:{' '}
              <span style={{ fontWeight: 'bold' }}>noname@myturnable.com</span>
            </p>
          </div>
        ))
        .required('Please enter the Email.'),
      permission_rules: Yup.array()
    })

    if (formData.login_access && !formData.generatePassword) {
      schema = schema.shape({
        password: Yup.string()
          .matches(passwordPolicy, () => (
            <div>
              <p>
                Invalid password. Password must match the following criteria:
              </p>
              <ol className="password-error">
                <li>Password must contain at least 8 Characters.</li>
                <li>An Uppercase character.</li>
                <li>A lowercase character.</li>
                <li>A Number.</li>
                <li>A Special Character.</li>
              </ol>
            </div>
          ))
          .required(),
        confirmPassword: Yup.string().when('password', {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string()
            .matches(passwordPolicy, () => (
              <div>
                <p>
                  Invalid password. Password must match the following criteria:
                </p>
                <ol className="password-error">
                  <li>Password must contain at least 8 Characters.</li>
                  <li>An Uppercase character.</li>
                  <li>A lowercase character.</li>
                  <li>A Number.</li>
                  <li>A Special Character.</li>
                </ol>
              </div>
            ))
            .oneOf(
              [Yup.ref('password'), null],
              'Password and Confirm Password do not match'
            )
            .required(),
          otherwise: Yup.string().nullable(true)
        })
      })
    }

    return schema
  }, [formData.login_access, formData.generatePassword])

  const isFormValid = useMemo(() => {
    try {
      StaffUserSchema.validateSync(formData, { abortEarly: false })
      return true
    } catch (error) {
      return false
    }
  }, [formData])

  const handleChange = ({ currentTarget: input }) => {
    if (input.name === 'password' || input.name === 'confirmPassword') {
      input.value = input.value.replace(' ', '')
    }

    let newFormData = { ...formData, [input.name]: input.value }

    if (input.name === 'password') {
      setErrors(prevErrors => ({ ...prevErrors, confirmPassword: '' }))
      newFormData = { ...newFormData, confirmPassword: '' }
    }

    setFormData(newFormData)

    StaffUserSchema.validateAt(input.name, newFormData)
      .then(() => {
        setErrors(prevErrors => ({ ...prevErrors, [input.name]: '' }))
      })
      .catch(err => {
        setErrors(prevErrors => ({
          ...prevErrors,
          [input.name]: err.errors[0]
        }))
      })
  }

  const handleRoleChange = selectedRole => {
    const newFormData = { ...formData, role: selectedRole }
    setFormData(newFormData)

    StaffUserSchema.validateAt('role', newFormData.role, { abortEarly: false })
      .then(() => {
        setErrors({ ...errors, role: '' })
      })
      .catch(err => {
        if (err.inner.length > 0) {
          setErrors({ ...errors, role: err.inner[0].message })
        }
      })
  }

  const handleTypeChange = selectedType => {
    const newFormData = { ...formData, type: selectedType }
    setFormData(newFormData)

    StaffUserSchema.validateAt('type', newFormData.type, { abortEarly: false })
      .then(() => {
        setErrors({ ...errors, type: '' })
      })
      .catch(err => {
        if (err.inner.length > 0) {
          setErrors({ ...errors, type: err.inner[0].message })
        }
      })
  }

  const handleGeneratePasswordChange = ({ currentTarget: input }) => {
    setFormData({ ...formData, generatePassword: input.checked })
  }

  const handleLoginAccessChange = value => {
    setFormData({ ...formData, login_access: value })
  }

  const handleSubmit = () => {
    const { property, project } = context || {}
    const internal_permission_rule_id = Short.uuid()

    const propertyFound = (propertiesList || []).find(
      ({ property_code, project_code }) =>
        property_code === property && project_code === project
    )

    const {
      login_access,
      status,
      role,
      type,
      permission_rules,
      generatePassword,
      password,
      email_verified,
      ...restStaff
    } = omit(formData, ['confirmPassword'])

    const new_Permission_rule = {
      action: 'Create',
      internal_permission_rule_id,
      permission_rule_id: null,
      property_id: propertyFound?.property_id,
      project_id: propertyFound?.project_id,
      role_id: role?.role_id,
      login_access: login_access ? null : 'false',
      status: status ? null : 'false'
    }

    let body = {
      type: type?.value,
      ...restStaff
    }

    if (params.id && !isEmpty(staffUser)) {
      const updatedPermissionRules = (permission_rules || []).map(rule => {
        if (
          rule.property_id === propertyFound?.property_id &&
          rule.project_id === propertyFound?.project_id
        ) {
          return {
            ...rule,
            role_id: role?.role_id,
            login_access: login_access ? null : 'false',
            status: status ? null : 'false'
          }
        }
        return rule
      })

      body = {
        ...body,
        app_access: restStaff?.app_access ? null : 'false',
        permission_rules: updatedPermissionRules
      }
    } else {
      body = {
        ...body,
        hire_date: null,
        term_date: null,
        auth_id: null,
        is_inactive: 'false',
        app_access: null,
        permission_rules: [new_Permission_rule]
      }
    }

    if (!email_verified && !generatePassword) {
      body.password = password
    }

    const onSuccess = () => {
      setCreateOrEditUserLoading(false)

      const successMessage = params.id
        ? 'Staff User is successfully Updated'
        : 'Staff User is successfully Created'

      const redirectUrl = params.id
        ? `/setup/staff/user/detail/${params.id}`
        : '/setup/staff'

      setAlert(successMessage, 'success')
      return history.push(redirectUrl)
    }

    const onError = message => {
      setCreateOrEditUserLoading(false)
      if (message) {
        setCreateOrEditUserError(message)
      } else {
        setCreateOrEditUserError(
          'Something went wrong. Please try again later !!!'
        )
      }
    }

    setCreateOrEditUserError('')
    setCreateOrEditUserLoading(true)
    createOrEditUser(pdbid, body, onSuccess, onError)
  }

  useEffect(() => {
    if (!isEmpty(staffUser) && params.id) {
      const { property, project } = context || {}
      const { permission_rules, ...restStaffData } = staffUser

      const mappedPermissionRules = (permission_rules || []).map(rule => ({
        action: 'Update',
        internal_permission_rule_id: rule.internal_permission_rule_id,
        login_access: rule.login_access,
        permission_rule_id: rule.permission_rule_id,
        project_id: rule.project?.[0]?.project_id,
        property_id: rule.property_id || rule.project[0]?.property_id,
        role_id: rule.role_id,
        status: rule.status
      }))

      const propertyFound = (propertiesList || []).find(
        ({ property_code, project_code }) =>
          property_code === property && project_code === project
      )

      const foundRule = (mappedPermissionRules || []).find(
        ({ property_id, project_id }) =>
          propertyFound?.property_id === property_id &&
          propertyFound?.project_id === project_id
      )

      const role = (filteredRoles || []).find(
        role => role.role_id === foundRule?.role_id
      )

      const staffFormData = {
        ...restStaffData,
        status: !foundRule?.status,
        login_access: !foundRule?.login_access ? true : false,
        type: typeOptions.find(option => option.value === restStaffData?.type),
        role,
        permission_rules: mappedPermissionRules
      }

      setPermissionRule(foundRule)
      setCurrentRole(role)
      setFormData(prev => ({
        ...prev,
        ...staffFormData
      }))
    } else {
      setFormData(prevState => ({ ...prevState, role: staffRole }))
    }
  }, [staffUser, params, propertiesList, staffRole])

  useEffect(() => {
    if (isNewVerifiedStaffUser) {
      setFormData(prevState => ({ ...prevState, email: verifiedEmail?.email }))
    }
  }, [isNewVerifiedStaffUser, verifiedEmail])

  useEffect(() => {
    return () => {
      resetCheckEmail()
    }
  }, [])

  const onError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onSuccess = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    if (params.id) {
      setIsLoading(true)
      newUserDetail(pdbid, params.id, onSuccess, onError)
    }

    return () => {
      clearUser()
    }
  }, [params.id])

  useEffect(() => {
    if (!formData.login_access) {
      setFormData(prevState => ({ ...prevState, generatePassword: true }))
    }
  }, [formData.login_access])

  if (errorMessage) {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>{errorMessage}</p>
      </div>
    )
  }

  if ((hasMadeInitialRequest && !isRequesting) || isLoading) {
    return <BigLoading />
  }

  return (
    <div className="columns is-multiline invite-staff">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          {createOrEditUserError && (
            <div className="notification is-danger is-light is-flex">
              <p>{createOrEditUserError}</p>
            </div>
          )}
          <FieldTemplate>
            <GeneralField fieldLabel="Name">
              <input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                type="text"
                className="input form-control"
                placeholder="Enter Name"></input>
            </GeneralField>
          </FieldTemplate>
          {errors.name && (
            <ErrorTemplate>
              <p>{errors.name}</p>
            </ErrorTemplate>
          )}
          <FieldTemplate>
            <GeneralField fieldLabel="Title" isRequired={false}>
              <input
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                type="text"
                className="input form-control"
                placeholder="Enter Title"></input>
            </GeneralField>
          </FieldTemplate>

          <FieldTemplate>
            <GeneralField fieldLabel="Type">
              <MultiSelectDropdown
                displayKey="name"
                value={formData.type}
                defaultValues={typeOptions}
                onChange={handleTypeChange}
                dropdownIcon
                isMulti={false}
                placeholder="Select Type"
                noOptionsMessage="No Type Available"
                customStyles={selectStyles}
                menuPortalTarget={document.body}
              />
            </GeneralField>
          </FieldTemplate>
          {errors.type && (
            <ErrorTemplate>
              <p>{errors.type}</p>
            </ErrorTemplate>
          )}

          <FieldTemplate>
            <GeneralField fieldLabel="Phone" isRequired={false}>
              <input
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="text"
                className="input form-control"
                placeholder="Enter Phone"></input>
            </GeneralField>
          </FieldTemplate>

          <FieldTemplate>
            <GeneralField fieldLabel="Alt Phone" isRequired={false}>
              <input
                id="alt_phone"
                name="alt_phone"
                value={formData.alt_phone}
                onChange={handleChange}
                type="text"
                className="input form-control"
                placeholder="Alt Phone"></input>
            </GeneralField>
          </FieldTemplate>

          <FieldTemplate>
            <GeneralField fieldLabel="Email Address">
              <input
                id="email"
                name="email"
                value={formData.email}
                type="text"
                disabled={true}
                autoComplete="off"
                placeholder="Enter Email Address"
                className="input form-control"></input>
            </GeneralField>
          </FieldTemplate>
          {errors.email && (
            <ErrorTemplate>
              <p>{errors.email}</p>
              <p>
                Example:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  noname@myturnable.com
                </span>
              </p>
            </ErrorTemplate>
          )}
          <FieldTemplate>
            <GeneralField fieldLabel="Enable Login" isRequired={false}>
              <SwitchButton
                isActive={formData.login_access}
                disabled={!canToggleAccessLogin}
                onChange={handleLoginAccessChange}
              />
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Role">
              <MultiSelectDropdown
                displayKey="name"
                value={formData.role}
                defaultValues={filteredRoles}
                onChange={handleRoleChange}
                isDisabled={
                  !formData.login_access || isPMAndCurrentUserIsLoggedInUser
                }
                dropdownIcon
                isMulti={false}
                placeholder="Select Role"
                noOptionsMessage="No Role Available"
                customStyles={selectStyles}
                menuPortalTarget={document.body}
              />
            </GeneralField>
          </FieldTemplate>
          {errors.role && (
            <ErrorTemplate>
              <p>{errors.role}</p>
            </ErrorTemplate>
          )}
          <FieldTemplate isDisabledVcentered>
            <GeneralField fieldLabel="Notes" isRequired={false}>
              <textarea
                className="input form-control"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                style={{ height: '80px', resize: 'none' }}
              />
            </GeneralField>
          </FieldTemplate>

          {formData.login_access && !staffUser.email_verified && (
            <>
              <FieldTemplate>
                <div className="label-heading column">
                  <strong>Password</strong>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <div className="column">
                  <label>
                    The password will be shared with the user via email
                  </label>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <div className="column">
                  <label
                    className={`checkbox ${formData.generatePassword &&
                      'is-active'}`}>
                    <span
                      className={`checkbox-input ${formData.generatePassword &&
                        'is-active'}`}>
                      <span className="checkbox-inner" />
                      <input
                        id="generatePassword"
                        name="generatePassword"
                        type="checkbox"
                        className="checkbox-original"
                        checked={formData.generatePassword}
                        onChange={handleGeneratePasswordChange}
                      />
                    </span>
                    <span
                      className={`checkbox-label ${formData.generatePassword &&
                        'is-active'}`}>
                      Generate Password
                    </span>
                  </label>
                </div>
              </FieldTemplate>
              {!formData.generatePassword && (
                <>
                  <FieldTemplate>
                    <GeneralField fieldLabel="Password">
                      <PasswordField
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </GeneralField>
                  </FieldTemplate>
                  {errors.password && (
                    <ErrorTemplate>
                      <p>{errors.password}</p>
                    </ErrorTemplate>
                  )}
                  <FieldTemplate>
                    <GeneralField fieldLabel="Confirm Password">
                      <PasswordField
                        id="confirmPassword"
                        name="confirmPassword"
                        disabled={errors.password || !formData.password}
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                    </GeneralField>
                  </FieldTemplate>
                  {errors.confirmPassword && (
                    <ErrorTemplate>
                      <p>{errors.confirmPassword}</p>
                    </ErrorTemplate>
                  )}
                </>
              )}
            </>
          )}
          <div>
            <PermissionGate name={'add-staff-member'}>
              <div className="button-wrapper">
                <button
                  className="button main-button is-secondary m-r-md"
                  onClick={() => {
                    if (params.id) {
                      return history.push(
                        `/setup/staff/user/detail/${params.id}`
                      )
                    }
                    return history.push('/setup/staff')
                  }}>
                  Cancel
                </button>
                <button
                  data-tip
                  data-for="save-Staff"
                  onClick={handleSubmit}
                  className={`button main-button is-primary ${
                    !isFormValid || createOrEditUserLoading
                      ? 'button-disabled'
                      : ''
                  }`}>
                  Save
                  {createOrEditUserLoading && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                  )}
                </button>
                <ReactTooltip
                  className="customTooltipTheme"
                  id="save-Staff"
                  place="top"
                  disable={isFormValid}
                  effect="solid">
                  <div>
                    <p>
                      Please fill all the required (
                      <span className="required-star">*</span>) fields
                    </p>
                  </div>
                </ReactTooltip>
              </div>
            </PermissionGate>
          </div>
        </div>
      </div>
    </div>
  )
}

SetupStaffManage.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupStaff: PropTypes.object,
  setupOneStaffGet: PropTypes.func,
  setTooltipContent: PropTypes.func,
  openTooltip: PropTypes.func,
  closeTooltip: PropTypes.func
}

export default SetupStaffManage
