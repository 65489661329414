import React, { useEffect, useState, useMemo } from 'react'
import ToggleEnableLogin from './ToggleEnableLogin'
import ErrorTemplateNoPadding from '../ErrorTemplateNoPadding'
import { closeFilled } from 'ui/icons'
import { usePrevious } from '../../../helpers/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createOrEditUser, newUserDetail } from '../../../modules/setup-users'

const EnableUserLogin = props => {
  const {
    user,
    setupUsers,
    context,
    createOrEditUser,
    newUserDetail,
    onEnableLoginChange,
    isStaff = false
  } = props || {}
  const [isUserEnableLoginLoading, setIsUserEnableLoginLoading] = useState(
    false
  )
  const [
    errorMessageUserEnableLogin,
    setErrorMessageUserEnableLogin
  ] = useState('')
  const [loginAccess, setLoginAccess] = useState(false)

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  // Redux
  const { propertiesList, rolesList, userDetail } = setupUsers || {}
  const { data: userDetailData } = userDetail || {}

  const getProperty = useMemo(
    () =>
      (propertiesList || []).filter(
        d =>
          d?.property_code === context?.property &&
          d?.project_code === context?.project
      ),
    [propertiesList, context]
  )

  const { permission_rules, email_verified, user_id } = userDetailData || {}

  const onUserDetailSuccess = () => {
    setIsUserEnableLoginLoading(false)
  }

  const onUserDetailError = () => {
    setIsUserEnableLoginLoading(false)
  }

  const onHandleForceReload = () => {
    newUserDetail(pdbid, user_id, onUserDetailSuccess, onUserDetailError)
  }

  const checkEnableLoginStatus = useMemo(() => {
    if ((getProperty || []).length) {
      const getPermissionRule = (permission_rules || []).filter(
        permission =>
          permission?.property_id === getProperty[0]?.property_id &&
          permission?.project[0]?.project_id === getProperty[0]?.project_id
      )
      return getPermissionRule
    }
    return []
  }, [userDetailData, getProperty])

  const getcheckEnableLoginStatus = useMemo(() => {
    if ((checkEnableLoginStatus || []).length) {
      return checkEnableLoginStatus[0]?.login_access === null ? true : false
    }
    return false
  }, [checkEnableLoginStatus])

  useEffect(() => {
    setLoginAccess(getcheckEnableLoginStatus)
  }, [getcheckEnableLoginStatus])

  const prevLoginAccess = usePrevious(getcheckEnableLoginStatus)

  const onUserEnableLoginError = () => {
    setIsUserEnableLoginLoading(false)
    setErrorMessageUserEnableLogin(
      'Something went wrong. Please try again later!!!'
    )
    setLoginAccess(prevLoginAccess)
  }

  const onUserEnableLoginSuccess = () => {
    setErrorMessageUserEnableLogin('')
    if (!email_verified) {
      onHandleForceReload()
    } else {
      setIsUserEnableLoginLoading(false)
    }
  }

  const onHandleUserEnableLogin = state => {
    setIsUserEnableLoginLoading(true)
    setLoginAccess(state)
    const body = {
      ...userDetailData
    }
    body.permission_rules = (permission_rules || []).map(p => {
      const { property_id } = p || {}
      if (property_id === getProperty[0]?.property_id) {
        p.login_access = state ? null : 'false'?.toString()
        p.action = 'Update'
        p.project_id = getProperty[0]?.project_id
      }
      return p
    })

    const roleIds = (body.permission_rules || []).map(rule => rule.role_id)
    const roleNames = (rolesList || [])
      .filter(({ role_id }) => (roleIds || []).includes(role_id))
      .map(({ name }) => name)

    const hasVendorRole = (roleNames || []).some(
      name => name?.toLowerCase() === 'vendor'
    )

    if (!hasVendorRole) {
      body.app_access = userDetailData?.app_access ? null : 'false'
    } else {
      body.app_access = 'false'
    }

    createOrEditUser(
      pdbid,
      body,
      onUserEnableLoginSuccess,
      onUserEnableLoginError
    )
  }

  useEffect(() => {
    if (onEnableLoginChange) {
      onEnableLoginChange(loginAccess)
    }
  }, [loginAccess])

  const getPermissionRuleStatus = useMemo(() => {
    if (isStaff) return false
    const getSelectedPermissionRule = (permission_rules || []).filter(
      perm =>
        perm.property_id === getProperty[0]?.property_id &&
        perm.project[0]?.project_id
    )
    if ((getSelectedPermissionRule || []).length) {
      return getSelectedPermissionRule[0]?.status
    }
    return false
  }, [permission_rules, getProperty, userDetailData, isStaff])

  return (
    <div className="box is-shadowless has-transition-opacity">
      {errorMessageUserEnableLogin !== '' ? (
        <ErrorTemplateNoPadding>
          <p>Something went wrong. Please try again later!!!</p>
          <button
            className="error-button"
            onClick={() => setErrorMessageUserEnableLogin('')}>
            <img src={closeFilled} alt="" />
          </button>
        </ErrorTemplateNoPadding>
      ) : null}
      <div className="columns is-vcentered mb-0 secondary-card-space">
        <div className="column is-full">
          <div className="columns is-desktop is-mobile" style={{ gap: '1rem' }}>
            <ToggleEnableLogin
              isActive={loginAccess}
              prevIsActive={prevLoginAccess}
              isDisabled={getPermissionRuleStatus}
              onChange={state => {
                setErrorMessageUserEnableLogin('')
                onHandleUserEnableLogin(state)
              }}
            />
            {isUserEnableLoginLoading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="m-l-sm loading-icon"
                size="lg"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user, context, setupUsers }) => ({
  user,
  setupUsers,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createOrEditUser,
      newUserDetail
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EnableUserLogin)
)
