import React, { useEffect, useState } from 'react'
import './index.scss'

const ToggleCheckbox = ({ onChange, isActive = false }) => {
  const [isChecked, setChecked] = useState(false)

  const handleChange = () => {
    setChecked(!isChecked)
    onChange(!isChecked)
  }

  useEffect(() => {
    setChecked(isActive)
  }, [isActive])

  return (
    <label className="toggle-control">
      <input type="checkbox" checked={isChecked} onChange={handleChange} />
      <span className="control"></span>
    </label>
  )
}

export default ToggleCheckbox
