import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import flattenDepth from 'lodash/flattenDepth'
import GeneralDetails from './general-details'
import SectionDetails from './section-details'
import { getTabs } from './tabs'
import { useAppContext, useModals } from 'hooks'
import { deletePropertyForm } from '../../../modules/property-forms'
import InlineTabs from '../../../components/inline-tabs'
import ConfirmationModal from '../../../components/confirmation-modal'
import { formatDateString } from 'utils'
import DuplicateFormModal from './duplicate-form-modal'
import { capitalize } from '../../../utils/helperFunctions'

const PropertyFormsDetails = ({
  activeSpace,
  history,
  formData,
  setFormData,
  onRemoveFormElement,
  onOpenSection,
  formToExport,
  originalForm,
  hasPermission,
  canEditForm,
  onSubmit
}) => {
  const dispatch = useDispatch()
  const { deleteIsRequesting, deleteSuccess, deleteIsError } = useSelector(
    state => state.propertyForms
  )
  const {
    user: { pdbid },
    context
  } = useAppContext()
  const [activeTab, setActiveTab] = useState({})
  const [tabs] = useState(getTabs(setActiveTab))
  const [showModal, dismissModal] = useModals()

  const { form_id, spaces, template_name, inspection_id } = formData

  useEffect(() => {
    setActiveTab(tabs[0])
  }, [])

  useEffect(() => {
    if (!deleteIsRequesting && deleteSuccess) {
      dismissModal()
      history.push('/inspections-setup/property-forms')
    } else if (!deleteIsRequesting && deleteIsError) {
      dismissModal()
    }
  }, [deleteIsRequesting, deleteSuccess])

  useEffect(() => {
    if (activeTab.id === 'section' && activeSpace !== null) {
      onOpenSection(true)
    } else {
      onOpenSection(false)
    }

    return () => {
      onOpenSection(false)
    }
  }, [activeTab, activeSpace])

  const deleteForm = useCallback(() => {
    if (form_id && !inspection_id && hasPermission) {
      showModal(
        { width: '480px' },
        <ConfirmationModal
          msg="You’re about to delete this form. This action cannot be undone. Are you sure you want to delete?"
          submit={() => {
            dispatch(deletePropertyForm(pdbid, context, form_id))
          }}
          dismiss={dismissModal}
          useLoading
        />
      )
    }
  }, [formData, hasPermission])

  const downloadForm = useCallback(() => {
    const mappedSpaces = (formToExport?.spaces || []).map(space => ({
      rows: flattenDepth(
        space.items.length
          ? space.items.map(item => {
              return item.observations.length
                ? item.observations.map(observation => {
                    return observation.solutions.length
                      ? observation.solutions.map(solution => [
                          space.space_name,
                          item.item_name,
                          observation.observation_name,
                          solution.solution_name,
                          observation.media_required === null
                            ? 'Required'
                            : 'Not Required',
                          (observation.media_minimum =
                            observation.media_required === null
                              ? observation.media_minimum
                              : 0),
                          (observation.solution_type = capitalize(
                            observation.solution_type || ''
                          ))
                        ])
                      : [
                          [
                            space.space_name,
                            item.item_name,
                            observation.observation_name
                          ]
                        ]
                  })
                : [[[space.space_name, item.item_name]]]
            })
          : [[[[space.space_name]]]],
        2
      )
    }))

    let formCsvData = []
    mappedSpaces.forEach(space => {
      formCsvData = [...formCsvData, ...space.rows]
    })

    const rows = [
      [
        'Spaces',
        'Items',
        'Observations',
        'Solutions',
        'Photo Required',
        'Minimum # of Photo',
        'Solutions Possible'
      ],
      ...formCsvData
    ]

    const csvContent = [
      'data:text/csv;charset=utf-8,',
      rows.map(e => e.join(',')).join('\n')
    ].join('')

    const encodedUri = encodeURI(csvContent).replace(/#/g, '%23')
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    const filename = [
      formToExport.name,
      formatDateString(new Date(), 'MM_DD_YYYY')
    ].join('-')
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [formToExport])

  const duplicateForm = useCallback(() => {
    if (hasPermission) {
      showModal(
        { width: '480px' },
        <DuplicateFormModal
          onConfirm={dismissModal}
          onDismiss={dismissModal}
          onSubmit={onSubmit}
          form={originalForm}
        />
      )
    }
  }, [originalForm, onSubmit, hasPermission])

  const removeActiveSpace = () => {
    showModal(
      { width: '480px' },
      <ConfirmationModal
        msg="You’re about to delete this section. This action cannot be undone. Are you sure you want to delete?"
        submit={() => {
          onRemoveFormElement(formData.spaces[activeSpace])
          dismissModal()
        }}
        dismiss={dismissModal}
      />
    )
  }

  const getContent = () => {
    switch (activeTab['id']) {
      case tabs[0]['id']:
        return (
          <GeneralDetails
            parentTemplate={template_name}
            formStatus={formData?.status}
            setFormData={setFormData}
            deleteForm={{
              title: inspection_id
                ? 'You cannot delete this form because it is being used in an inspection.'
                : '',
              disabled: !form_id || inspection_id || !hasPermission,
              action: deleteForm
            }}
            downloadForm={{
              disabled: spaces.length === 0,
              action: downloadForm
            }}
            duplicateForm={{
              disabled: !form_id || !hasPermission,
              action: duplicateForm
            }}
          />
        )

      case tabs[1]['id']:
        return (
          <SectionDetails
            activeSpace={activeSpace}
            removeActiveSpace={removeActiveSpace}
            formData={formData}
            canEditForm={canEditForm}
            setFormData={setFormData}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      <InlineTabs tabs={tabs} style={{ top: -34 }} />
      {getContent()}
    </>
  )
}

PropertyFormsDetails.propTypes = {
  activeSpace: PropTypes.number,
  history: PropTypes.object,
  formData: PropTypes.object,
  formToExport: PropTypes.object,
  setFormData: PropTypes.func,
  onRemoveFormElement: PropTypes.func,
  onOpenSection: PropTypes.func,
  originalForm: PropTypes.object,
  canEditForm: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default PropertyFormsDetails
