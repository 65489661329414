import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from '../../components/date-picker'

const SetupGoalsServicesModalCreate = ({
  context,
  user,
  closeModal,
  setupGoals,
  setupGoalLineCreate,
  setupGoalLineUpdate,
  setupAllGoalsGet,
  servicetype_uri,
  start_date,
  end_date,
  goal_rooms,
  goal_line_uri
}) => {
  const [selectedService, setSelectedService] = useState(
    servicetype_uri || 'none'
  )
  const [startDate, setStartDate] = useState(
    start_date ? moment(start_date, 'MM-DD-YYYY').toDate() : new Date()
  )
  const [endDate, setEndDate] = useState(
    end_date ? moment(end_date, 'MM-DD-YYYY').toDate() : new Date()
  )
  const [goalRooms, setGoalRooms] = useState(goal_rooms || '')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (goal_line_uri) {
      setEndDate(moment(end_date, 'MM-DD-YYYY').toDate())
      setGoalRooms(goal_rooms)
      setSelectedService(servicetype_uri)
      setStartDate(moment(start_date, 'MM-DD-YYYY').toDate())
      setSubmitted(false)
    } else {
      setEndDate(new Date())
      setGoalRooms('')
      setSelectedService('none')
      setStartDate(new Date())
      setSubmitted(false)
    }
  }, [
    goal_line_uri,
    end_date,
    start_date,
    goal_line_uri,
    goal_rooms,
    servicetype_uri
  ])

  const submit = async () => {
    const { goal_id, goal_uri } = setupGoals.goal.data
    const { pdbid } = user.user_metadata

    if (validate()) {
      const body = {
        goal_uri,
        goal_rooms: String(goalRooms),
        start_date: moment(startDate).format('MM-DD-YYYY'),
        end_date: moment(endDate).format('MM-DD-YYYY'),
        servicetype_uri: selectedService
      }
      if (goal_line_uri) {
        await setupGoalLineUpdate(pdbid, context, goal_id, body)
      } else {
        await setupGoalLineCreate(pdbid, context, goal_id, body)
      }
      setupAllGoalsGet(pdbid, context)
      closeModal()
    }
  }

  const validate = () => {
    setSubmitted(true)
    return (
      !getFieldError('selectedService', true) &&
      !getFieldError('startDate', true) &&
      !getFieldError('endDate', true)
      // !getFieldError('goalRooms', true)
    )
  }

  const getFieldError = (fieldName, force) => {
    if (!force && !submitted) {
      return null
    }
    if (fieldName === 'selectedService' && selectedService === 'none') {
      return 'Service is required'
    }
    if (fieldName === 'startDate' && !moment(startDate).isValid()) {
      return 'Start Date is not valid'
    }
    if (fieldName === 'endDate' && !moment(endDate).isValid()) {
      return 'Start Date is not valid'
    }
    if (
      fieldName === 'goalRooms' &&
      (isNaN(parseInt(goalRooms)) || parseInt(goalRooms) <= 0)
    ) {
      return 'Number of Spaces is not valid number'
    }
    return null
  }

  const getServiceLabel = label => label.toLowerCase() === 'cleaning' ? 'Clean' : label

  const getServices = () => {
    const { data } = setupGoals.allServices
    const { goal_lines = [] } = setupGoals.goal.data
    let template = []
    for (let i = 0; i < data.length; i += 1) {
      const { servicetype_uri, servicetype_id, servicetype } = data[i]
      if (
        !goal_lines.some(gl => gl.servicetype_uri === servicetype_uri) ||
        selectedService === servicetype_uri
      ) {
        template.push(
          <option
            value={servicetype_uri}
            key={`service-option-${servicetype_id}`}>
            {getServiceLabel(servicetype)}
          </option>
        )
      }
    }
    return template
  }

  const onSubmit = (e) => e.preventDefault()

  return (
    <form onSubmit={(e) => onSubmit(e)} id="setup-goal-services-modal-create">
      <div className="columns is-multiline">
        <div className="column is-two-thirds">
          <p className="is-size-4 m-b-md">Service Goals</p>
          <div className="form-control">
            <label forhtml="service" className="m-b-xs">
              Service
            </label>
            <div className="select is-fullwidth">
              <select
                className={`input is-fullwidth ${
                  getFieldError('selectedService') ? 'is-danger' : ''
                }`}
                id="vendor-services-modal-service"
                onChange={e => setSelectedService(e.target.value)}
                value={selectedService}>
                <option value="none">Select Service</option>
                {getServices()}
              </select>
              <input
                type="hidden"
                name="servicetype_uri"
                value={selectedService}
              />
            </div>
            <p className="help is-danger">
              {getFieldError('selectedService') || ''}
            </p>
          </div>
        </div>
      </div>
      <div className="columns is-multiline">
        <div className="column is-one-third p-r-xs">
          <label className="m-b-xs">Start Date</label>
          <DatePicker value={startDate} onChange={date => setStartDate(date)} />
          <p className="help is-danger">{getFieldError('startDate') || ''}</p>
        </div>
        <div className="column is-one-third p-l-xs">
          <label className="m-b-xs">End Date</label>
          <DatePicker value={endDate} onChange={date => setEndDate(date)} />
          <p className="help is-danger">{getFieldError('endDate') || ''}</p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-two-thirds">
          <div className="form-control">
            <label forhtml="option" className="m-b-xs">
              Number of Spaces&nbsp;<i>(needing this service)</i>
            </label>
            <div className="column is-half is-paddingless">
              <input
                type="number"
                className={`input ${
                  !goalRooms ? '' : getFieldError('goalRooms') ? 'is-danger' : ''
                }`}
                min={1}
                step={1}
                name="goal_rooms"
                value={goalRooms}
                onChange={e => setGoalRooms(e.target.value)}
              />
            </div>
            <p className="help is-danger">{goalRooms ? getFieldError('goalRooms') : ''}</p>
          </div>
        </div>
      </div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-one-quarter is-offset-one-quarter has-text-right">
          <div className="form-control">
            <button
              type="button"
              className="button is-secondary"
              onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
        <div className="column is-one-quarter has-text-left">
          <div className="form-control">
            <button
              type="button"
              className="button is-primary"
              onClick={() => submit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetupGoalsServicesModalCreate.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupVendors: PropTypes.object,
  setupGoals: PropTypes.object,
  setupGoalLineCreate: PropTypes.func,
  closeModal: PropTypes.func,
  end_date: PropTypes.string,
  goal_line_uri: PropTypes.string,
  goal_rooms: PropTypes.string,
  rooms_per_day: PropTypes.number,
  servicetype: PropTypes.string,
  servicetype_id: PropTypes.string,
  servicetype_uri: PropTypes.string,
  start_date: PropTypes.string
}

export default SetupGoalsServicesModalCreate
