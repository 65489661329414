import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'

/**
 * Dashboard header and health data module
 * @redux
 * @reduxActionScope dashboard-header
 * @module dashboard-header
 */

const MIN_NUMBER_OF_STARS = 0
const MAX_NUMBER_OF_STARS = 5

/**
 * Request dashboard header data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_HEADER_REQUESTED = 'dashboard/DASHBOARD_HEADER_REQUESTED'
/**
 * Dashboard header data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_HEADER_FAIL = 'dashboard/DASHBOARD_HEADER_FAIL'
/**
 * Update request dashboard header with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_HEADER_SUCCESS = 'dashboard/DASHBOARD_HEADER_SUCCESS'

/**
 * Request dashboard health data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_HEALTH_REQUESTED = 'dashboard/DASHBOARD_HEALTH_REQUESTED'
/**
 * Dashboard health data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_HEALTH_FAIL = 'dashboard/DASHBOARD_HEALTH_FAIL'
/**
 * Update request dashboard health with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_HEALTH_SUCCESS = 'dashboard/DASHBOARD_HEALTH_SUCCESS'

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  totalCost: 0,
  totalProperties: 0,
  completedProperties: 0,
  health: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    summary: [],
    data: []
  }
}

/**
 * Dashboard header and health reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_HEADER_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case DASHBOARD_HEADER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        totalCost: action.totalCost,
        totalProperties: action.totalProperties,
        completedProperties: action.completedProperties
      }
    case DASHBOARD_HEADER_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true,
        totalCost: 0,
        totalProperties: 0,
        completedProperties: 0
      }
    case DASHBOARD_HEALTH_REQUESTED:
      return {
        ...state,
        health: {
          ...state.health,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case DASHBOARD_HEALTH_SUCCESS:
      return {
        ...state,
        health: {
          ...state.health,
          isRequesting: false,
          isError: false,
          data: action.data,
          summary: action.summary
        }
      }
    case DASHBOARD_HEALTH_FAIL:
      return {
        ...state,
        health: {
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Updates dashboard header data
 * @redux
 * @reduxActionCreator DASHBOARD_HEADER_SUMMARY_REQUESTED, DASHBOARD_HEADER_SUMMARY_SUCCESS, DASHBOARD_HEADER_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getDashboardHeader = (pdbid, project, properties = '') => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_HEADER_REQUESTED
    })
    return Service.getDashboardChartsSummary(pdbid, { project, properties })
      .then(async res => {
        if (res.ok) {
          try {
            const data = await res.json()
            const transformedHeader = headerAdapter(data)
            dispatch({
              type: DASHBOARD_HEADER_SUCCESS,
              ...transformedHeader
            })
          } catch {
            _dashboardHeaderFail(dispatch)
          }
        } else {
          _dashboardHeaderFail(dispatch)
        }
      })
      .catch(err => {
        _dashboardHeaderFail(dispatch)
        throw err
      })
  }
}

const _dashboardHeaderFail = dispatch => {
  dispatch({
    type: DASHBOARD_HEADER_FAIL
  })
}

const headerAdapter = data => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return {
    totalCost: formatter.format(data.total_cost),
    totalProperties: parseInt(data.total_properties),
    completedProperties: parseInt(data.total_completed)
  }
}

/**
 * Updates dashboard health data
 * @redux
 * @reduxActionCreator DASHBOARD_HEALTH_SUMMARY_REQUESTED, DASHBOARD_HEALTH_SUMMARY_SUCCESS, DASHBOARD_HEALTH_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getHealthData = (pdbid, project, properties = '') => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_HEALTH_REQUESTED
    })
    return Service.getDashboardGoalsSummary(pdbid, { project, properties })
      .then(async res => {
        if (res.ok) {
          try {
            const data = await res.json()
            const transformedData = healthAdapter(data.dashboard)
            dispatch({
              type: DASHBOARD_HEALTH_SUCCESS,
              ...transformedData
            })
          } catch {
            _corporateHealthFail(dispatch)
          }
        } else {
          _corporateHealthFail(dispatch)
        }
      })
      .catch(err => {
        _corporateHealthFail(dispatch)
        throw err
      })
  }
}

const _corporateHealthFail = dispatch => {
  dispatch({
    type: DASHBOARD_HEALTH_FAIL
  })
}

const getEmptyHealthArray = () => {
  const emptyArray = []
  for (let i = MIN_NUMBER_OF_STARS; i <= MAX_NUMBER_OF_STARS; i++) {
    emptyArray.push({
      stars: i.toString(),
      count: 0
    })
  }
  return emptyArray
}

const healthAdapter = data => ({
  summary: _.unionBy(
    _.map(_.groupBy(data.goals, goal => goal.goal_star), (g, id) => ({
      stars: id,
      count: g.length
    })),
    getEmptyHealthArray(),
    goal => goal.stars
  ),
  data: _.groupBy(
    _.map(data.goals, goal => ({
      name: goal.property_name,
      id: goal.property_id,
      stars: goal.goal_star
    })),
    g => g.stars
  )
})
