import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
// TODO 004:
// add back later as a feature as we need an endpoint to search all data
// import GlobalSearch from '../../containers/global-search'
import key from './key.json'
import './index.scss'
import { useSelector } from 'react-redux'

const Breadcrumbs = ({ rightSide = null }) => {
  const { pathname, search } = useLocation()
  const { user_metadata } = useSelector(state => state.user)
  const context = useSelector(state => state.context)
  const { properties } = user_metadata || {}
  const property = useMemo(
    () => (properties || []).find(p => p.id === context?.property),
    [user_metadata, context]
  )

  // TODO: Find a way to reduce this processing
  const adjustName = label =>
    label === 'Carpet Replace' && property
      ? property?.cr_label || 'Carpet Replace'
      : label

  const slugPath = path => path.replace(/[^a-zA-Z0-9 ]/g, '')

  const isPath = (paths, pathname) => paths[0] === pathname

  const getPaths = () => {
    const params = new URLSearchParams(search)
    const paths = pathname.split('/').slice(1)

    // TODO: Fix services routes to avoid this processing
    if (isPath(paths, 'services')) {
      paths.push(params.get('s'))
      paths.push(params.get('p') || 'Assign')
      if (params.get('s') === 'Walk' || params.get('s') === 'Holdover')
        paths[paths.indexOf('services')] = 'walk'
    }
    const checkURLList = [
      'manage-inspection',
      'view-detail',
      'addvendoruser',
      'edit',
      'view',
      'add',
      'detail',
      'pricing',
      'damagesetup',
      'damageinspection',
      'createadhocdamage'
    ]

    const elementPos = (paths || []).findIndex(p => checkURLList?.includes(p))

    if (elementPos !== -1) {
      ;(paths || []).splice(elementPos + 1)
    }

    return paths.map((path, i, paths) => ({
      path: key[slugPath(path)] || path,
      isLast: i === paths.length - 1
    }))
  }

  return (
    <div className="container animated fadeInDown nav-bar">
      <nav
        className="breadcrumb has-succeeds-separator m-b-lg"
        aria-label="breadcrumbs">
        <div className="columns is-vcentered">
          <div className="column is-three-quarters-destkop">
            <div className="columns is-vcentered">
              <div className="column">
                <ul>
                  <li>
                    <Link to="/" className="has-text-grey p-r-none">
                      Home
                    </Link>
                  </li>
                  {getPaths().map(({ isLast, path }) => (
                    <li
                      className={
                        isLast ? 'has-text-weight-bold' : 'has-text-grey'
                      }
                      key={path}>
                      {adjustName(path)}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column has-text-right is-narrow">{rightSide}</div>
            </div>
          </div>
          {/* TODO 004:
          add back later as a feature as we need an endpoint to search all data
          <div className="column is-one-quarter-destkop is-hidden-mobile">
            <GlobalSearch />
          </div> */}
        </div>
      </nav>
    </div>
  )
}

Breadcrumbs.propTypes = {
  location: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object
}

export default Breadcrumbs
