import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

import close from './icons/close.svg'
import './index.scss'

const TurnboardGroupUnitsModal = ({
  onConfirm, 
  onCancel, 
  pdbid,
  context,
  unitIds,
  getAllGroups,
  groupOrUngroupUnits,
  shouldGroupUnits
}) => {
  const { allGroups, groupOrUngroupUnitsLoader } = useSelector(state => state.turnboard)

  const [groupName, setGroupName] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = () => {
    const newGroup = groupName || selectedGroup

    const data = {
      items: (unitIds || []).map(unit_id => ({
        unit_id,
        grouping: shouldGroupUnits ? newGroup : null
      }))
    }

    groupOrUngroupUnits({
      data,
      pdbid,
      context,
      onSuccess: () => {
        onConfirm()
      }, 
      onError: () => {
        onCancel()
      }
    })
  }

  const handleChangeGroupName = ({currentTarget: input}) => {
    const trimedValue = input.value?.trim()

    setGroupName(input.value)

    const found = (allGroups || []).find(({ grouping }) => grouping?.toLowerCase() === trimedValue?.toLowerCase())
    
    if(found) {
      setError('Group with the given name already exists.')
    } else {
      setError('')
    }
  }

  useEffect(() => {
    getAllGroups({
      pdbid,
      context  
    })
  },[getAllGroups])

  return (
    <div className="group-units-modal">
      <div className="modal-heading">
        <h3>
          {shouldGroupUnits ? 'Group' : 'Ungroup'}
        </h3>
        <p
          className="close is-pointer has-text-grey-light"
          onClick={onCancel}>
          <img
            alt="Close Modal"
            src={close}
          />
          </p>
      </div>
      <div className="modal-desceription">
        {shouldGroupUnits 
          ? (
            <>
              <p>Note: Grouping is done at the unit level, so all spaces within the units(s)</p>
              <p>selected will be added to this group.</p>
            </>
          ) : (
            <>
              <p>You are about to remove the group from the selected</p>
              <p>units, so the will no longer be associated with any group.</p>
            </>
          )
        }
      </div>
      {shouldGroupUnits 
        && (
          <div className="form">
            <div className="columns is-mobile">
              <div className="column is-half">
                <h3><b>Add to Existing Group</b></h3>
                <div className="select is-fullwidth">
                  <select
                    className="input"
                    name="selectedGroup"
                    value={selectedGroup}
                    disabled={groupName}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                  >
                    <option value="">Choose Option</option>
                    {(allGroups || []).map(({ grouping }) => (
                      <option value={grouping}>{grouping}</option>
                    ))}
                  </select> 
                </div>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column is-half">
                <h3> <b>Create & Add to New Group</b> </h3>
                <div className="is-fullwidth">
                  <input 
                    className={`input ${error && 'is-danger'}`} 
                    name="groupName"
                    placeholder="Enter new group name"
                    value={groupName}
                    disabled={selectedGroup}
                    onChange={handleChangeGroupName}
                  /> 
                  {error && <p className="help is-danger">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        )
      }
      {!shouldGroupUnits 
        && (
          <div className="modal-confirmation-text center-container">
            <p>Would you like to continue?</p>
          </div>
        )
      }
      <div className="modal-actions center-container">
        <button 
          className="button main-button is-secondary m-r-md"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button 
          className="button main-button is-primary"
          disabled={shouldGroupUnits && !groupName && !selectedGroup || error}
          onClick={handleSubmit}
        >
          {shouldGroupUnits ? 'Save': 'Yes'}
          {groupOrUngroupUnitsLoader && <FontAwesomeIcon icon={faSpinner} spin color="#ffffff" className="m-l-sm" />}
        </button>
      </div>
    </div>
  )
}

export default TurnboardGroupUnitsModal
