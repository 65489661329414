import _ from 'lodash'
import React, { useRef } from 'react'
import Short from 'short-uuid'

const ChangePropertyModal = ({
  properties,
  current = 'none',
  onPropertyChange,
  onClose
}) => {
  const ref = useRef()
  return (
    <div>
      <div className="columns is-desktop is-mobile is-multiline">
        <div className="column is-full p-b-none">
          <p className="heading-4 is-pulled-left">
            Which property would you like to view?
          </p>
          <p
            className="has-text-grey-light is-pulled-right is-pointer is-unselectable"
            onClick={() => onClose()}>
            X
          </p>
        </div>
        <div className="column is-full p-t-none">
          <p className="is-size-6"></p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <label htmlFor="project" className="heading-5">
            Property
          </label>
          <div className="select is-fullwidth">
            <select
              className="is-fullwidth input"
              id="property"
              ref={ref}
              defaultValue={current}>
              {current === 'none' && (
                <option value="none">Select one...</option>
              )}
              {_.map(properties, p => (
                <option key={Short.uuid()} value={p.id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-right">
          <button
            className="button is-secondary m-r-sm"
            onClick={() => {
              onClose()
            }}>
            Close
          </button>
          <button
            className="button is-primary"
            onClick={() => onPropertyChange(ref.current.value)}>
            Change
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChangePropertyModal
