import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

import close from './icons/close.svg'
import './index.scss'

const TurnboardMarkAsIsModal = ({ 
  onConfirm, 
  onCancel, 
  pdbid,
  context, 
  updateAsIsStatus, 
  dataToSubmit,
  shouldMarkAsIs
}) => {
  const { asIsStatusLoader } = useSelector(state => state.turnboard)
  
  const handleSubmit = async() => {
    const data = {
      items: dataToSubmit
    }

    await updateAsIsStatus({
      data,
      pdbid,
      context,
      onSuccess: () => {
        onConfirm()
      }, 
      onError: () => {
        onCancel()
      }
    })
  }

  return (
    <div className="mark-as-is-modal">
      <div className="modal-heading">
        <h3>
          {shouldMarkAsIs ? 'Mark As-Is' : 'Unmark As-Is'} 
        </h3>
        <p
          className="close is-pointer has-text-grey-light"
          onClick={onCancel}>
          <img
            alt="Close Modal"
            src={close}
          />
          </p>
      </div>
      <div className="modal-desceription">
        {shouldMarkAsIs 
          ? (
            <>
              <p>You are about to mark the selected unit(s) "As-Is"</p>
              <p>This will override the current lease status moving forward</p>
            </> 
          ) : (
            <>
              <p>You are about to remove the "As-Is" status from the</p>
              <p>selected unit(s)</p>
            </> 
          )
        }
      </div>
      <div className="modal-confirmation-text center-container">
        <p>Would you like to continue?</p>
      </div>
      <div className="modal-actions center-container">
        <button 
          className="button main-button is-secondary m-r-md"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button 
          className="button main-button is-primary"
          onClick={handleSubmit}
        >
          Yes
          {asIsStatusLoader && <FontAwesomeIcon icon={faSpinner} spin color="#ffffff" className="m-l-sm" />}
        </button>
      </div>
    </div>
  )
}

export default TurnboardMarkAsIsModal
