import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import QcHeader from '../qc-header'
import VendorHeader from '../vendor-header'
import { toggleSidebar } from '../../modules/sidebar'

import './index.scss'

const PropertyMap = ({ qc, vendor, toggleSidebar }) => {
  const { pathname } = useLocation()

  let map = null
  if (pathname.startsWith('/vendor/property-map') && vendor.property) {
    map = vendor.property.propmap
  } else if (pathname.startsWith('/qc/property-map') && qc.property) {
    map = qc.property.propmap
  }
  const isVendor = pathname.startsWith('/vendor')

  return (
    <div className="is-fullheight property-map">
      {isVendor ? (
        <VendorHeader title="Property Map" toggleSidebar={toggleSidebar} />
      ) : (
        <QcHeader title="Property Map" toggleSidebar={toggleSidebar} />
      )}
      <figure className="image">
        {map ? (
          <img src={map} alt="Property Map" />
        ) : (
          <div className="level is-mobile m-t-lg">
            <div className="level-item has-text-centered">
              <div className="has-text-grey">
                <FontAwesomeIcon icon={faFileImage} size="2x" />
                <p className="title has-text-grey">No property image</p>
              </div>
            </div>
          </div>
        )}
      </figure>
    </div>
  )
}

PropertyMap.propTypes = {
  qc: PropTypes.object,
  vendor: PropTypes.object,
  toggleSidebar: PropTypes.func
}

const mapStateToProps = ({ qc, vendor }) => ({
  qc,
  vendor
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleSidebar,
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyMap)
