import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from 'components/date-picker'
import Short from 'short-uuid'
import { Modal } from 'ui'
import { removeArrayDuplicates } from 'utils'
import './index.scss'

const START_OF_DAYS = new Date(0)
const ALL_VALUE = 'all'

const InvoicesGenerateModal = ({ setCurrentInvoice }) => {
  const tasks = useSelector(state => state.invoices.tasks)
  const [range, setRange] = useState([null, null])
  const [customRange, setCustomRange] = useState(false)
  const [availableServices] = useState(() =>
    removeArrayDuplicates(tasks.map(task => task.servicetype))
  )
  const [service, setService] = useState(ALL_VALUE)
  const [tasksToAdd, setTasksToAdd] = useState([...tasks])
  const buttonIsDisabled =
    range[0] === null ||
    range[1] === null ||
    !tasks.length ||
    !tasksToAdd.length

  useEffect(() => {
    const [startDate, endDate] = range
    if (startDate && endDate) {
      setTasksToAdd(
        tasks.filter(task => {
          const taskDate = new Date(task.complete_date).setHours(23, 59, 59)
          if (service !== ALL_VALUE) {
            return (
              taskDate >= startDate.setHours(0, 0, 0) &&
              taskDate <= endDate.setHours(23, 59, 59) &&
              task.servicetype === service
            )
          } else {
            return (
              taskDate >= startDate.setHours(0, 0, 0) &&
              taskDate <= endDate.setHours(23, 59, 59)
            )
          }
        })
      )
    }
  }, [range, service])

  const setRangeStatus = (theRange, theCustomRange) => {
    setRange(theRange)
    setCustomRange(theCustomRange)
  }

  const updateService = selectedService => {
    setService(selectedService)
  }

  const updateRange = option => {
    if (option === 'no-range') {
      setRangeStatus([null, null], false)
    } else if (option === '-1') {
      setRangeStatus([START_OF_DAYS, new Date()], false)
    } else if (option === '0') {
      setRangeStatus([new Date(), new Date()], true)
    } else {
      const start = new Date()
      start.setDate(start.getDate() - parseInt(option))
      setRangeStatus([start, new Date()], false)
    }
  }

  const onGenerate = _range => {
    setCurrentInvoice(current => {
      const mappedTasks = tasksToAdd.map(task => ({
        amount: task.cost,
        item: task.item,
        po_line_id: Short.uuid(),
        quantity: 1,
        rate: task.cost,
        task_id: task.task_id,
        unit: task.unit,
        unit_space: task.unit_space,
        isNew: true
      }))

      return {
        ...current,
        po_lines: [
          ...current.po_lines.splice(0, current.po_lines.length - 1),
          ...mappedTasks
        ]
      }
    })
  }

  return (
    <Modal
      title="What timeframes would you like to add services for?"
      titleSize="4"
      okText="Add Services"
      okDisabled={buttonIsDisabled ? 'disabled' : ''}
      onConfirm={() => {
        onGenerate(range)
        setRange([null, null])
      }}>
      <div className="columns">
        <div className="column is-half">
          <p className="is-size-6">Service</p>
          <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              onChange={e => updateService(e.target.value)}
              disabled={!tasks.length}
              value={service}>
              <option value={ALL_VALUE}>All</option>
              {availableServices.map(serv => (
                <option value={serv} key={serv}>
                  {serv}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="column is-half">
          <p className="is-size-6">Timeframe</p>
          <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              onChange={e => updateRange(e.target.value)}
              disabled={!tasks.length}>
              <option value="no-range">Select Date Range</option>
              <option value="-1">All Time</option>
              <option value="7">Last 7 Days</option>
              <option value="14">Last 14 Days</option>
              <option value="30">Last 30 Days</option>
              <option value="0">Custom</option>
            </select>
          </div>
        </div>
      </div>
      {!tasks.length && (
        <div className="columns">
          <div className="column">
            <p style={{ color: '#ea3f32', fontSize: 12 }}>
              There are no available tasks to add.
            </p>
          </div>
        </div>
      )}
      {tasks.length > 0 && !tasksToAdd.length && (
        <div className="columns">
          <div className="column">
            <p style={{ color: '#ea3f32', fontSize: 12 }}>
              There are no available tasks on the selected service or the
              selected range.
            </p>
          </div>
        </div>
      )}
      {customRange && (
        <div className="columns">
          <div className="column is-half">
            <span style={{ verticalAlign: '-webkit-baseline-middle' }}>
              From:
            </span>
            <span className="invoices-generate-modal__date-picker">
              <DatePicker
                value={range[0]}
                onChange={value => setRange([value, range[1]])}
              />
            </span>
          </div>
          <div className="column is-half">
            <span style={{ verticalAlign: '-webkit-baseline-middle' }}>
              To:
            </span>
            <span className="invoices-generate-modal__date-picker">
              <DatePicker
                value={range[1]}
                onChange={value => setRange([range[0], value])}
              />
            </span>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default InvoicesGenerateModal
