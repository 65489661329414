import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  setupAllVendorsGet,
  setupOneVendorGet,
  setupOneVendorUpdate,
  setupOneVendorCreate,
  setupOneVendorDelete,
  setupServicesGet,
  allVendorListGet,
  deleteVendor,
  toggleVendorAvailablitity,
  deleteVendorServices
} from '../../modules/setup-vendors'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'
import { checkVendorExists } from '../../modules/request-vendors'
import {
  checkEmailExists,
  resetCheckEmail,
  allPropertiesList,
  allRolesList,
  createOrEditUser,
  clearUser,
  allUsersGet,
  associateUserToVendor,
  userAccountDelete,
  allVendorAssociatedUsers,
  updateUserPassword
} from '../../modules/setup-users'
import SetupVendorsMain from '../../parents/setup-vendors-main'
import BigLoading from '../../components/big-loading'
import { setAlert } from '../../modules/alerts'

const SetupVendors = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <SetupVendorsMain {...props} />
  }

  return <BigLoading />
}

SetupVendors.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupVendors: PropTypes.object
}

const mapStateToProps = ({
  user,
  context,
  setupVendors,
  modal,
  tooltip,
  setupUsers
}) => ({
  user,
  context,
  setupVendors,
  modal,
  tooltip,
  setupUsers
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setupAllVendorsGet,
      setupOneVendorGet,
      setupOneVendorUpdate,
      setupOneVendorCreate,
      setupOneVendorDelete,
      setupServicesGet,
      openModal,
      closeModal,
      setModalContent,
      openTooltip,
      closeTooltip,
      setTooltipContent,
      allVendorListGet,
      setAlert,
      checkVendorExists,
      checkEmailExists,
      resetCheckEmail,
      allPropertiesList,
      allRolesList,
      createOrEditUser,
      clearUser,
      allUsersGet,
      associateUserToVendor,
      userAccountDelete,
      allVendorAssociatedUsers,
      updateUserPassword,
      deleteVendor,
      toggleVendorAvailablitity,
      deleteVendorServices
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupVendors)
)
