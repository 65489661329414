import React, { useMemo } from 'react'
import { edit, undo } from 'ui/icons'
import DataTableMenu from '../../../../components/data-table-menu'
import { useHistory } from 'react-router'
import { checkPermission, sectionManageable } from '../../../../security'
import { useAppUser } from '../../../../helpers/hooks'
import DelinkUserModal from '../DelinkUserModal'
import { useModals } from 'hooks'

const ActionColumn = ({ row }) => {
  const history = useHistory()
  const { user } = useAppUser()
  const [showModal, dismissModal] = useModals()

  const { cell } = row || {}
  const { row: currentRow } = cell || {}
  const { original } = currentRow || {}
  const { user_id } = original || {}
  const menuOptions = useMemo(() => {
    const options = [
      {
        caption: 'Edit Vendor Login',
        icon: edit,
        action: () => {
          return history.push(`/setup/vendors/edit/${user_id}`)
        }
      }
      // {
      //   caption: 'Delink Vendor Login',
      //   icon: undo,
      //   action: () => {
      //     return showModal(
      //       { width: '480px' },
      //       <DelinkUserModal row={original} dismissModal={dismissModal} />
      //     )
      //   }
      // }
    ]

    return options
  }, [user, row])

  const showActionMenu = useMemo(
    () => checkPermission('manage-staff-member', user),
    [user]
  )

  return (
    <div className="menu-item">
      {showActionMenu && (
        <DataTableMenu appendToBody={true} options={menuOptions} row={row} />
      )}
    </div>
  )
}

export default ActionColumn
