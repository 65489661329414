import React from 'react'

const tagClasses = {
  'In Progress': 'tag is-link is-light',
  Completed: 'tag is-success is-light',
  Deleted: 'tag is-danger is-light',
  Failed: 'tag is-danger is-light'
}

const StatusColumn = ({ row }) => {
  const { cell } = row || {}
  const { value } = cell || {}
  return (
    <div className="cell-item" title={value}>
      <span
        style={{ fontSize: '14px', lineHeight: 'unset', display: 'unset' }}
        className={tagClasses[value]}>
        {value}
      </span>
    </div>
  )
}

export default StatusColumn
