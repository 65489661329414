import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import store, { history } from './store'
import App from './containers/app'
import ErrorBoundary from './containers/error-boundary'
import { initLogger } from './service/logger'
import * as serviceWorker from './serviceWorker'
import 'sanitize.css/sanitize.css'
import 'simplebar/dist/simplebar.min.css'
import './index.css'
import CrispChat from './containers/app/crisp'

let loggerInitialized = false
if (!loggerInitialized) {
  initLogger()
  loggerInitialized = true
}

const target = document.querySelector('#root')

render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div className="is-fullheight">
          <ErrorBoundary>
            <CrispChat />
            <App />
          </ErrorBoundary>
        </div>
      </ConnectedRouter>
    </Provider>
  </DndProvider>,
  target
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister()
