import React, {
  forwardRef,
  memo,
  useImperativeHandle,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  useBlockLayout,
  useFilters,
  useSortBy,
  useTable,
  useRowSelect,
  usePagination
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import { FixedSizeList } from 'react-window'
import $ from 'jquery'
import { debounce } from 'lodash'

import InHouseTableCheckbox from './in-house-table-checkbox'
import DefaultColumnFilter from '../../../components/react-table-column-filters/DefaultColumnFilter'
import { InHouseTableStyles } from './in-house-table-styles'

import { removeExtraWhiteSpaces } from '../../../utils/helperFunctions'
import SortIcon from '../../../components/common/SortIcon/SortIcon'
import './in-house-table.scss'

const RenderRow = ({ data, index, style }) => {
  const { rows, prepareRow, totalColumnsWidth } = data || {}
  const row = rows[index]

  prepareRow(row)

  return (
    <div
      {...row.getRowProps({
        style: {
          ...style,
          backgroundColor: '#ffffff',
          width: totalColumnsWidth
        }
      })}
      className="tr"
      s>
      {row.cells.map(cell => {
        let styleBody = {}
        if (cell.column.minWidth !== 0) {
          styleBody.minWidth = cell.column.minWidth
        }
        if (cell.column.flexGrow !== 0) {
          styleBody.flexGrow = cell.column.flexGrow
        }
        return (
          <div
            {...cell.getCellProps({
              style: {
                textAlign: cell.column.textAlign,
                width: cell.column.width,
                ...styleBody
              }
            })}
            className="td">
            {cell.render('Cell')}
          </div>
        )
      })}
    </div>
  )
}

const InHouseTable = (
  {
    data,
    tableColumns,
    skipReset,
    setIsFilterApplied,
    onServiceStatusChange,
    onRowSelectStateChange
  },
  ref
) => {
  const [tableHeight, setTableHeight] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isHeaderScrollable, setIsHeaderScrollable] = useState(false)

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterTypes = React.useMemo(
    () => ({
      customEquals: (rows, id, filterValue) => {
        if (!filterValue) return rows
        id = id[0]
        return rows.filter(row => filterValue?.[id] === row.values?.[id])
      },
      multiEquals: (rows, id, filterValue) => {
        if ((filterValue || []).length === 0) return rows

        id = id[0]

        return (rows || []).filter(row => {
          return filterValue?.some(
            filterVal => filterVal?.[id] === row.values?.[id]
          )
        })
      }
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    previousPage,

    rows,
    totalColumnsWidth,
    prepareRow,
    state: { filters, selectedRowIds },
    selectedFlatRows,
    setAllFilters,
    toggleAllRowsSelected
  } = useTable(
    {
      columns: tableColumns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: !skipReset,
      autoResetFilters: !skipReset,
      onServiceStatusChange,
      sortTypes: {
        alphanumeric: (rowOne, rowTwo, columnId) => {
          const valueOne = removeExtraWhiteSpaces(
            rowOne?.values[columnId] || ''
          )
          const valueTwo = removeExtraWhiteSpaces(
            rowTwo?.values[columnId] || ''
          )

          // If the data type of the column is a string, perform a case-sensitive comparison

          return valueOne.localeCompare(valueTwo, 'en', { numeric: true })
        }
      },
      initialState: {
        sortBy: [{ id: 'unit', asc: true }]
      }
    },
    useBlockLayout,
    useSticky,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       width: 45,
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <InHouseTableCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div style={{ marginTop: '3px' }}>
    //           <InHouseTableCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       )
    //     },
    //     ...columns
    //   ])
    // }
  )

  useImperativeHandle(ref, () => ({
    getRows: () => {
      return (rows || []).map(row => row.original)
    },

    getSelectedRows: () => {
      return (selectedFlatRows || []).map(row => row.original)
    },
    clearFilter: setAllFilters,
    toggleAllRowsSelected: toggleAllRowsSelected
  }))

  useEffect(() => {
    setIsFilterApplied(filters)
  }, [filters])

  const itemSize = 45
  const defaultListHeight = 300

  const optimizedScroll = useCallback(
    debounce(scrollLeft => {
      setScrollPosition(() => scrollLeft)
    }, 200)
  )

  useEffect(() => {
    const element = $('.in-house-list').parent()

    $(element).on('scroll', function() {
      const scrollLeft = $(this).scrollLeft()
      const height = $(this).height()

      if (scrollLeft >= 0) {
        $('.in-house-table .header').scrollLeft(scrollLeft)

        if (height > itemSize) {
          optimizedScroll(scrollLeft)
        } else {
          setIsHeaderScrollable(() => true)
        }
      }
    })
  }, [])

  useEffect(() => {
    const header = $('.in-house-table .header')

    if (isHeaderScrollable) {
      $(header).scrollLeft(scrollPosition)
    }
  }, [isHeaderScrollable])

  useEffect(() => {
    const header = $('.in-house-table .header')
    const listElement = $('.in-house-list').parent()
    const listHeight = $(listElement).height()

    if (listHeight <= itemSize && isHeaderScrollable) {
      $(header).on('scroll', function() {
        const scrollLeft = $(this).scrollLeft()
        optimizedScroll(scrollLeft)
      })
    }
  }, [isHeaderScrollable])

  useEffect(() => {
    const listElement = $('.in-house-list').parent()
    const listHeight = $(listElement).height()

    if (listHeight > itemSize && isHeaderScrollable) {
      $(listElement).scrollLeft(scrollPosition)
      setIsHeaderScrollable(() => false)
    }
  }, [isHeaderScrollable, scrollPosition, rows])

  useEffect(() => {
    const updatedListSize = itemSize * (rows || []).length

    if (updatedListSize < defaultListHeight) {
      setTableHeight(updatedListSize + itemSize)
    } else {
      setTableHeight(defaultListHeight)
    }
  }, [rows, defaultListHeight])

  useEffect(() => {
    onRowSelectStateChange && onRowSelectStateChange(selectedFlatRows)
  }, [selectedRowIds])

  return (
    <div>
      <InHouseTableStyles>
        <div
          {...getTableProps()}
          className="in-house-table sticky"
          style={{ width: '100%' }}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              flex: 1,
              zIndex: 0
            }}>
            <div className={`header ${!rows.length ? 'scroll' : ''} `}>
              <div>
                {headerGroups.map(headerGroup => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map(column => (
                      <div
                        {...column.getHeaderProps({
                          style: {
                            textAlign: column.textAlign,
                            width: column.width,
                            flexGrow: column.flexGrow,
                            backgroundColor: column.backgroundColor,
                            color: column.color
                          }
                        })}
                        className="th">
                        <div>
                          {column.render('Header')}
                          <span {...column.getSortByToggleProps()}>
                            <SortIcon column={column} showWhite={true} />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div>
                {headerGroups.map(headerGroup => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map(column => (
                      <div
                        {...column.getHeaderProps({
                          style: {
                            textAlign: column.textAlign,
                            width: column.width,
                            flexGrow: column.flexGrow
                          }
                        })}
                        className="th">
                        <div title="Filter">
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <FixedSizeList
              height={tableHeight}
              itemCount={(rows || []).length}
              width={'100%'}
              itemSize={itemSize}
              itemData={{ rows, prepareRow, totalColumnsWidth }}
              innerElementType={({ children, style, ...rest }) => {
                return (
                  <div className="in-house-list">
                    <div {...getTableBodyProps()} {...rest} style={style}>
                      {children}
                    </div>
                  </div>
                )
              }}>
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </InHouseTableStyles>
    </div>
  )
}

export default memo(forwardRef(InHouseTable))
