import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../modules/modal'
import { close } from 'ui/icons'

const ClearAllModal = ({ submitAction }) => {
  const dispatch = useDispatch()

  const closeModalHandler = () => dispatch(closeModal())
  
  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column">
          <p className="heading-3 is-pulled-left">Clear All</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModalHandler}>
            <img alt="close modal" src={close} />
          </p>
        </div>
      </div>
      <div className="div columns">
        <div className="column">
          <p className="schedule-all-modal__text">
            You’re about to clear all the selections you’ve made,
            <br />
            excluding any selections that are locked. This action
            <br />
            cannot be undone.
          </p>

          <p className="has-text-centered schedule-all-modal__text m-t-lg">
            Are you sure you want to clear all selections?
          </p>
        </div>
      </div>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={closeModalHandler}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={submitAction}>
            Yes, Clear
          </button>
        </div>
      </div>
    </div>
  )
}

ClearAllModal.propTypes = {
  submitAction: PropTypes.func
}

export default ClearAllModal
