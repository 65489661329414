import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'
import _ from 'lodash'
import { formatDateString, removeArrayDuplicates } from '../../../utils'
import './index.scss'

const ScheduleTable = ({ dates, teamsCapacity }) => {
  const teams = removeArrayDuplicates( _.flatten(teamsCapacity.map(item => item.team_capacity)).map(team => team.team_name))

  return (
    <SimpleBar>
      <table className="schedule-table m-b-lg">
        <thead>
          <tr>
            <th className="first-column">Date</th>
            {teams.map((teamName) => (
              <th key={teamName}>
                {teamName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {teamsCapacity.map((item, index) => (
            <Fragment key={index}>
              <tr>
                <th>
                  {formatDateString(item.date, 'ddd, MM/DD')}
                </th>
                {teams.map((team, index) => (
                  <th key={index}>
                    {item.team_capacity[index] ? `${item.team_capacity[index]['availableSpaces']} Spaces` : '--'}
                  </th>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </SimpleBar>
  )
}

ScheduleTable.propTypes = {
  dates: PropTypes.array,
  teamsCapacity: PropTypes.array,
}

export default ScheduleTable
