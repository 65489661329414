import React from 'react'
import PropTypes from 'prop-types'
import SetupBudgetsServicesModalCreate from '../setup-budgets-services-modal-create'
import SetupBudgetsServicesModalAllocate from '../setup-budgets-services-modal-allocate'
import { getMoneyNumber } from '../../helpers'
import { icons } from './icons'

const SetupBudgetsServices = ({
  context,
  user,
  openModal,
  setModalContent,
  closeModal,
  isFaded = false,
  setupBudgets,
  setupBudgetLineCreate,
  setupBudgetLineUpdate,
  updateData,
  setupVendors,
  modal,
  checkIfActionAllowed
}) => {
  const getServiceBudgetModal = (
    which = 'create',
    servicetype_uri = null,
    vendor_uri = null,
    allocation_amount = null,
    isNew = true,
    bl = null
  ) => {
    let template = (
      <SetupBudgetsServicesModalCreate
        context={context}
        setupBudgets={setupBudgets}
        closeModal={closeModal}
        isNew={isNew}
        bl={bl}
        setupBudgetLineCreate={setupBudgetLineCreate}
        setupBudgetLineUpdate={setupBudgetLineUpdate}
        setupVendors={setupVendors}
        user={user}
        updateData={updateData}
      />
    )
    if (which === 'allocate') {
      template = (
        <SetupBudgetsServicesModalAllocate
          context={context}
          modal={modal}
          closeModal={closeModal}
          setupBudgetLineUpdate={setupBudgetLineUpdate}
          updateData={updateData}
          setupBudgets={setupBudgets}
          setupVendors={setupVendors}
          user={user}
          servicetype_uri={servicetype_uri}
          vendor_uri={vendor_uri}
          allocation_amount={allocation_amount}
        />
      )
    }
    return template
  }

  const getAllocationProps = (allocations, key) => {
    let template = []
    for (let i = 0; i < allocations.length; i += 1) {
      let a = allocations[i]
      let data =
        key === 'allocation_amount' ? getMoneyNumber(a[key], 'USD') : a[key]
      template.push(
        <p className="is-size-6 m-b-sm" key={`allocation-${key}-${i}`}>
          {data}
        </p>
      )
    }
    return template
  }

  const getServiceCards = () => {
    const { data } = setupBudgets.budget
    const { budget_lines = [] } = data
    let template = []
    for (let i = 0; i < budget_lines.length; i += 1) {
      const bl = budget_lines[i]
      const opacity = isFaded ? '0.5' : '1'
      template.push(
        <div
          className="box is-shadowless has-transition-opacity"
          key={`budget-service-${i}`}
          style={{ opacity }}>
          <div className="columns is-desktop is-mobile">
            <div className="column is-narrow p-b-none">
              <img alt="Service Icon" src={icons.services} />
            </div>
            <div className="column">
              <div className="columns">
                <div className="column is-half p-b-none">
                  <p
                    className="is-size-4 m-b-sm"
                    style={{
                      overflow: 'hidden',
                      height: '36px'
                    }}>
                    {bl.servicetype === 'Cleaning' ? 'Clean' : bl.servicetype}
                  </p>
                  {getAllocationProps(bl.allocations, 'vendor')}
                </div>
                <div className="column is-half-touch is-half-desktop is-one-third-widescreen is-one-third-fullhd has-text-right p-b-none">
                  <p
                    className="is-size-4 m-b-sm"
                    style={{
                      height: '36px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                    {getMoneyNumber(bl.budget, 'USD', '', 2)}
                  </p>
                  {getAllocationProps(bl.allocations, 'allocation_amount')}
                </div>
              </div>
            </div>
            {checkIfActionAllowed ? (
              <div className="column is-narrow">
                <img
                  alt="Edit Service Budget"
                  src={icons.edit}
                  className="is-inline is-pointer"
                  onClick={() => {
                    setModalContent(() =>
                      getServiceBudgetModal(null, null, null, null, false, bl)
                    )
                    openModal({ width: '480px' })
                  }}
                />
              </div>
            ) : null}
          </div>
          {/*
          <div className="columns">
            <div className="column p-t-none" style={{ paddingLeft: '66px' }}>
              <p
                className={`is-size-7 is-pointer is-unselectable ${allocationButtonHidden}`}
                onClick={() => {
                  setModalContent(
                    this.getServiceBudgetModal.bind(
                      this,
                      'allocate',
                      bl.servicetype_uri
                    )
                  )
                  openModal({ width: '480px' })
                }}>
                <img
                  alt="Allocate to Vendor"
                  src={icons.addBlack}
                  className="m-r-xs"
                />
                Allocate to Vendor
              </p>
            </div>
          </div>
          */}
        </div>
      )
    }
    return template
  }

  /*const getIcons = budgetLine => {
    let template = []
    for (let i = 0; i < budgetLine.allocations.length; i += 1) {
      const a = budgetLine.allocations[i]
      template.push(
        <div
          className="is-size-6 m-b-sm"
          key={`service-budget-${budgetLine.service_name}-${i}`}>
          <img
            alt="Edit Line"
            src={icons.edit}
            className="is-inline is-pointer"
            onClick={() => {
              setModalContent(() =>
                getServiceBudgetModal(
                  'allocate',
                  budgetLine.servicetype_uri,
                  a.vendor_uri,
                  a.allocation_amount
                )
              )
              openModal({ width: '480px' })
            }}
          />
          <img
            alt="Delete Line"
            src={icons.trash}
            className="is-inline is-pointer m-l-sm is-hidden"
          />
        </div>
      )
    }
    return template
  }

  const getRemainder = (allocations, total) => {
    if (typeof total !== 'number') total = parseFloat(total)
    let used = 0
    for (let i = 0; i < allocations.length; i += 1) {
      const a = allocations[i]
      used += a.allocation_amount
    }
    return total - used
  }*/

  const opacity = isFaded ? '0.5' : '1'

  return (
    <div>
      {getServiceCards()}
      {checkIfActionAllowed ? (
        <div
          className={`box is-shadowless ${
            isFaded ? '' : 'is-pointer'
          } has-transition-opacity`}
          onClick={() => {
            if (!isFaded) {
              setModalContent(() => getServiceBudgetModal())
              openModal({ width: '480px' })
            }
          }}
          style={{ opacity }}>
          <div className="columns is-vcentered">
            <div
              className="column is-size-6 is-unselectable"
              style={{ lineHeight: '36px' }}>
              <img
                alt="Add Service Budget"
                src={icons.setupAdd}
                className="m-r-sm"
                style={{ marginTop: '-2px' }}
              />
              Add Service Budget
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

SetupBudgetsServices.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  isFaded: PropTypes.bool,
  updateData: PropTypes.func
}

export default SetupBudgetsServices
