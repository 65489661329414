import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BigLoading from '../../components/big-loading' 
import ScheduleBoardsMain from '../../parents/schedule-boards-main'
import { getScheduleBoardsMainData, resetScheduleBoardsMainData } from '../../modules/schedule'

const ScheduleBoards = props => {
  const { getScheduleBoardsMainData, resetScheduleBoardsMainData, schedule, user, context, auth } = props
  const { boardsMainDataHasRequested } = schedule
  
  useEffect(() => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { pdbid } = user.user_metadata
    
    if (isAuthenticated && hasMgmtToken && pdbid !== null) {
      const { pdbid } = user.user_metadata
      getScheduleBoardsMainData(pdbid, context)
    }

    return () => resetScheduleBoardsMainData()
  }, [
    context,
    user.user_metadata,
  ])

  return (user.isRequesting || !user.hasMadeInitialRequest) || !boardsMainDataHasRequested ? <BigLoading /> : <ScheduleBoardsMain {...props} />
}

ScheduleBoards.propTypes = {
  auth: PropTypes.object,
  turnboard: PropTypes.object,
  context: PropTypes.object,
}

const mapStateToProps = ({
  auth,
  user,
  context,
  schedule,
}) => ({
  auth,
  user,
  context,
  schedule,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getScheduleBoardsMainData,
      resetScheduleBoardsMainData,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleBoards))
