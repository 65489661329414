import React, { useCallback, useMemo } from 'react'
import Short from 'short-uuid'

import Dashboard from '../../dashboard'
// import DashboardProperty from '../../../parents/dashboard-property'
import InvoicesEdit from '../../../parents/invoices-edit'
import Charges from '../../charges'
import Approvals from '../../approvals'
import VendorLoginUserManage from '../../vendor-login-user-manage'

import Turnboard2 from '../../turnboard-2'

import Schedule from '../../schedule'
import ScheduleBoards from '../../schedule-boards'

import SetupVendors from '../../setup-vendors'
import SetupBudgets from '../../setup-budgets'
// import SetupProperties from '../../setup-properties'
// import SetupServices from '../../setup-services'
import SetupStaff from '../../setup-staff'
import SetupTeams from '../../setup-teams'
import SetupGoals from '../../setup-goals'
import SetupUsers from '../../setup-users'
import Keys from '../../keys'
import RequestsVendors from '../../requests-vendors'
import RequestsForProposal from '../../requests-for-proposal'
import VendorsQuotation from '../../vendors-quotation'
import Inspect from '../../inspect'
import Invoices from '../../invoices'
import Services from '../../services'
import Holdovers from '../../holdovers'
import Pricing from '../../pricing-manage'
import DamagesSingleInspection from '../../damages-single-inspection'
import DamageInspections from '../../damages-inspections'
import DamageSetup from '../../damages-setup'
import DamageForm from '../../damages-form'
// import Reports from '../../reports'
import Inspections from '../../inspections'
import DownloadInspections from '../../download-inspections'
import InspectionsManages from '../../inspections-manages'
import CompanyTemplates from '../../company-templates'
import PropertyForms from '../../property-forms'
import Elements from '../../elements'
import * as section from '../../../utils/sectionConstants'
import { useAppUser } from '../../../helpers/hooks'
import { LIST } from '../../../utils/componentTypes'
import { cloneDeep } from 'lodash'

const allRoutes = [
  {
    id: Short.uuid(),
    sectionKey: section.DASHBOARD,
    componentType: LIST,
    skipContext: false,
    path: '/dashboard',
    Component: Dashboard
  },
  // {
  //   id: Short.uuid(),
  //   sectionKey: section.INSPECTION_SETUP,
  //   skipContext: false,
  //   path: '/dashboard-property',
  //   Component: DashboardProperty
  // },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.TURNBOARD,
    path: '/turnboard-2.0',
    Component: Turnboard2
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.KEYS,
    path: '/keys',
    Component: Keys
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.WALK,
    path: '/walk',
    Component: Services
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.WALK,
    path: '/walk/holdovers',
    Component: Holdovers
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.INSPECT,
    path: '/inspect',
    Component: Inspect
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.SERVICES,
    path: '/services',
    Component: Services
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.CHARGES,
    path: '/payables/charges',
    Component: Charges
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.PURCHASE_ORDERS,
    path: '/payables/purchase-orders',
    Component: Invoices
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.APPROVALS,
    path: '/payables/approvals',
    skipContext: true,
    Component: Approvals
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.PURCHASE_ORDERS,
    path: '/payables/purchase-orders/:id/:vendor_id',
    Component: InvoicesEdit
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.RFP,
    path: '/requests-vendors',
    Component: RequestsVendors
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.RFP,
    path: '/requests-for-proposal',
    skipContext: true,
    Component: RequestsForProposal
  },
  {
    id: Short.uuid(),
    exact: true,
    skipContext: true,
    sectionKey: section.RFP,
    path: '/request/:id/quotation',
    Component: VendorsQuotation
  },

  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.TURN_SETUP,
    path: '/setup/vendors',
    Component: SetupVendors
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/vendor-manage',
    Component: SetupVendors
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/vendors/view/:id',
    Component: VendorLoginUserManage
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/vendors/edit/:id',
    Component: VendorLoginUserManage
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/vendors/add/:id/:email',
    Component: VendorLoginUserManage
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.TURN_SETUP,
    path: '/setup/budgets',
    Component: SetupBudgets
  },
  // {
  //   id: Short.uuid(),
  //   exact: true,
  //   sectionKey: section.TURN_SETUP,
  //   path: '/setup/properties',
  //   Component: SetupProperties
  // },
  // {
  //   id: Short.uuid(),
  //   exact: true,
  //   sectionKey: section.TURN_SETUP,
  //   path: '/setup/property-manage',
  //   Component: SetupProperties
  // },
  // {
  //   id: Short.uuid(),
  //   exact: true,
  //   sectionKey: section.TURN_SETUP,
  //   path: '/setup/services',
  //   Component: SetupServices
  // },
  // {
  //   id: Short.uuid(),
  //   exact: true,
  //   sectionKey: section.TURN_SETUP,
  //   path: '/setup/service-manage',
  //   Component: SetupServices
  // },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/staff/user/edit/:id',
    type: 'EditStaffDetail',
    Component: SetupStaff
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/staff/user/detail/:id',
    type: 'ViewStaffDetail',
    Component: SetupStaff
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/staff/user/new',
    type: 'NewStaff',
    Component: SetupStaff
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/staff-manage',
    Component: SetupStaff
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.TURN_SETUP,
    path: '/setup/staff',
    Component: SetupStaff
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.TURN_SETUP,
    path: '/setup/team-manage',
    Component: SetupTeams
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.TURN_SETUP,
    path: '/setup/teams',
    Component: SetupTeams
  },
  {
    id: Short.uuid(),
    exact: true,
    componentType: LIST,
    sectionKey: section.TURN_SETUP,
    path: '/setup/goals',
    Component: SetupGoals
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.USERS,
    path: '/setup/users/new',
    type: 'NewUser',
    skipContext: true,
    Component: SetupUsers
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.USERS,
    path: '/setup/users/view/:id',
    type: 'UserDetail',
    skipContext: true,
    Component: SetupUsers
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.USERS,
    path: '/setup/users/edit/:id',
    type: 'EditUserDetail',
    skipContext: true,
    Component: SetupUsers
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.USERS,
    path: '/setup/users',
    type: 'UserList',
    skipContext: true,
    Component: SetupUsers
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.WEB_INSPECTIONS,
    path: '/inspections/dashboard',
    Component: Inspections
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.WEB_INSPECTIONS,
    path: '/inspections/manage-inspection/:id',
    Component: InspectionsManages
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.WEB_INSPECTIONS,
    path: '/inspections/inspectboard',
    Component: Inspections
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.WEB_INSPECTIONS,
    path: '/inspections/downloads',
    Component: DownloadInspections
  },
  // {
  //   id: Short.uuid(),
  //   exact: true,
  //   sectionKey: section.INSPECTION_SETUP,
  //   path: '/inspections-setup/manage-inspection/:id',
  //   Component: InspectionsManages
  // },
  {
    id: Short.uuid(),
    sectionKey: section.INSPECTION_SETUP,
    children: [
      {
        id: Short.uuid(),
        exact: true,
        sectionKey: section.ELEMENTS,
        path: '/inspections-setup/elements',
        skipContext: true,
        Component: Elements
      },
      {
        id: Short.uuid(),
        exact: true,
        sectionKey: section.TEMPLATES,
        path: '/inspections-setup/company-templates',
        skipContext: true,
        Component: CompanyTemplates
      },
      {
        id: Short.uuid(),
        exact: true,
        sectionKey: section.TEMPLATES,
        path: '/inspections-setup/company-templates/manage',
        skipContext: true,
        Component: CompanyTemplates
      },
      {
        id: Short.uuid(),
        exact: true,
        sectionKey: section.FORMS,
        path: '/inspections-setup/property-forms',
        Component: PropertyForms
      },
      {
        id: Short.uuid(),
        exact: true,
        sectionKey: section.FORMS,
        path: '/inspections-setup/property-forms/manage',
        Component: PropertyForms
      }
    ]
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.SCHEDULER,
    path: '/schedule/planning-tool',
    Component: Schedule
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.SCHEDULER,
    path: '/schedule/boards',
    Component: ScheduleBoards
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.PRICING,
    path: '/setup/vendor/pricing/:vendorId/:serviceId',
    Component: Pricing
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.DAMAGES,
    path: '/inspections/damagesetup',
    Component: DamageForm
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.DAMAGES,
    path: '/inspections/damagesetup/:formId',
    Component: DamageSetup
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.DAMAGES,
    path: '/inspections/damageinspection',
    Component: DamageInspections
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.DAMAGES,
    path: '/inspections/damageinspection/:inspection_id',
    Component: DamagesSingleInspection
  },
  {
    id: Short.uuid(),
    exact: true,
    sectionKey: section.DAMAGES,
    path: '/inspections/damages/createadhocdamage/:id/:inspection_id',
    Component: DamageInspections
  }
]

const useAppRoutes = () => {
  const { user } = useAppUser()
  const { user_metadata } = user || {}
  const { allowedSections, auto_goals } = user_metadata || {}

  const getFilteredRoutes = useCallback((routes = [], sectionKeys = []) => {
    return (routes || []).filter(route => {
      if (route.children) {
        route.children = getFilteredRoutes(route.children, sectionKeys)
      }

      const isChildrenEmpty = route.children && !route.children.length
      return (sectionKeys || []).includes(route.sectionKey) && !isChildrenEmpty
    })
  }, [])

  const getFlatRoutes = useCallback((routes = []) => {
    return (routes || []).flatMap(route =>
      route.children ? getFlatRoutes(route.children) : route
    )
  }, [])

  const routes = useMemo(() => {
    const sectionKeys = (allowedSections || []).map(({ section }) => section)
    const clonedRoutes = cloneDeep(allRoutes)
    let filteredRoutes = getFilteredRoutes(clonedRoutes, sectionKeys)
    filteredRoutes = getFlatRoutes(filteredRoutes)

    if (!auto_goals) {
      filteredRoutes = (filteredRoutes || []).filter(
        route => route.path !== '/setup/goals'
      )
    }

    return filteredRoutes
  }, [allowedSections, auto_goals])

  const getPathAndCheckPrevPath = (sections, prevPath) => {
    const sectionKeys = (sections || []).map(({ section }) => section)
    const clonedRoutes = cloneDeep(allRoutes)
    let filteredRoutes = getFilteredRoutes(clonedRoutes, sectionKeys)
    filteredRoutes = getFlatRoutes(filteredRoutes)

    if (!auto_goals) {
      filteredRoutes = (filteredRoutes || []).filter(
        route => route.path !== '/setup/goals'
      )
    }

    const prevPathExists = (filteredRoutes || []).some(
      route => route.path === prevPath
    )

    const listingRoutes = (filteredRoutes || []).filter(
      route => route.componentType === LIST
    )

    return {
      path: listingRoutes.length ? listingRoutes[0]?.path : '/',
      prevPathExists
    }
  }

  return { routes, getPathAndCheckPrevPath }
}

export default useAppRoutes
