import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { closeFilled } from 'ui/icons'
import ErrorTemplate from '../../components/forms-helper/ErrorTemplate'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import {
  emailPolicy,
  passwordPolicy
} from '../../components/forms-helper/ValidationPolicies'
import TurnToggle from '../../components/turn-toggle'
import GeneralField from '../../components/forms-helper/GeneralField'
import './index.scss'
import ReactTooltip from 'react-tooltip'
import PasswordField from '../../components/forms-helper/PasswordField'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import BigLoading from '../../components/big-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Short from 'short-uuid'
import '../../styles/common.scss'

const VendorLoginUserAdd = props => {
  const {
    history,
    user,
    checkEmailExists,
    resetCheckEmail,
    allPropertiesList,
    allRolesList,
    context,
    createOrEditUser,
    setAlert,
    associateUserToVendor,
    userAccountDelete
  } = props || {}
  const { email: urlEmail, id: vendorId } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)

  const [UserName, setUserName] = useState('')

  const [shouldGeneratePassword, setShouldGeneratePassword] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidpassword, setIsValidPassword] = useState(false)
  const [isValidConfirmpassword, setIsValidConfirmPassword] = useState(false)
  const [userTitle, setUserTitle] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userAltPhone, setUserAltPhone] = useState('')
  const [userNote, setUserNote] = useState('')
  const [enableLogin, setEnableLogin] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(null)
  const { verifiedEmail, propertiesList, rolesList } = useSelector(
    state => state?.setupUsers
  )

  const getVendorRole = useMemo(() => {
    const role = (rolesList || []).filter(d => d.name === 'Vendor')
    if ((role || []).length) {
      return role[0]?.role_id
    }
    return 'Role6'
  }, [rolesList])

  const getProperty = useMemo(
    () =>
      (propertiesList || []).filter(
        d =>
          d?.project_code === context?.project &&
          d?.property_code === context?.property
      ),
    [propertiesList, context]
  )

  // Redux
  const {
    email: checkedEmail,
    emailExists: checkEmailExist,
    loading: checkEmailLoading,
    emailVerified: checkEmailVerified,
    serverError: checkEmailServerError
  } = verifiedEmail || {}

  const getValidPassword = value => value?.match(passwordPolicy) !== null
  const getValidEmail = value => value?.match(emailPolicy) !== null
  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  useEffect(() => {
    allPropertiesList(pdbid)
    allRolesList(pdbid)
  }, [])

  useEffect(() => {
    if (!checkedEmail) {
      if (!getValidEmail(urlEmail)) {
        setIsEmailValid(false)
      } else {
        checkEmailExists(pdbid, urlEmail, () => {}, () => {})
      }
    }
    return () => resetCheckEmail()
  }, [])

  const isSubmitButtonEnable = useMemo(() => {
    if (!(UserName || '').trim() || !(checkedEmail || '').trim()) {
      return true
    }
    if (
      !shouldGeneratePassword &&
      (!(password || '').trim() ||
        !(confirmPassword || '').trim() ||
        isValidpassword ||
        isValidConfirmpassword ||
        (password || '').trim() !== (confirmPassword || '').trim())
    ) {
      return true
    }
    return false
  }, [
    UserName,
    checkedEmail,
    shouldGeneratePassword,
    password,
    confirmPassword,
    isValidConfirmpassword,
    isValidpassword
  ])

  const notValidPasswordError = () => {
    return (
      <ErrorTemplate>
        <div>
          <p>Invalid password. Password must match the following criteria:</p>
          <ol className="password-error">
            <li>Password must contain at least 8 Characters.</li>
            <li>An Uppercase character.</li>
            <li>A lowercase character.</li>
            <li>A Number.</li>
            <li>A Special Character.</li>
          </ol>
        </div>
      </ErrorTemplate>
    )
  }

  const optimizedPasswordValidation = useCallback((value, type) => {
    if (!getValidPassword(value)) {
      type(true)
    } else {
      type(false)
    }
  }, [])

  const toggleGeneratePassword = value => {
    setShouldGeneratePassword(value)
    setPassword('')
    setConfirmPassword('')
    setIsValidConfirmPassword(false)
    setIsValidPassword(false)
  }

  if (isEmailValid === false) {
    return (
      <div className="notification is-danger is-light is-flex">
        <div>
          <p>
            {' '}
            The email address you entered is invalid. Please enter your email
            address using standard format.
          </p>
          <p>
            Example:{' '}
            <span style={{ fontWeight: 'bold' }}>noname@myturnable.com</span>
          </p>
        </div>
      </div>
    )
  }

  if (checkEmailServerError) {
    return (
      <div className="notification is-danger is-light is-flex">
        <div>
          <p>Something went wrong. Please try again later !!!</p>
        </div>
      </div>
    )
  }

  if (checkEmailLoading) {
    return <BigLoading />
  }

  if (checkEmailExist && checkEmailVerified) {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>
          We’re sorry. This email address is already associated with an account.
          Please try a different email address.
        </p>
      </div>
    )
  }

  const onSuccessVendorAccociated = () => {
    setSubmitting(false)
    history.goBack()
    setAlert('Vendor User is successfully created', 'success')
  }

  const onUserDeleteAccountError = () => {
    setSubmitting(false)
    setAlert('Something went wrong. Please try again later!!!', 'danger')
  }

  const onUserDeleteAccountSuccess = () => {
    setSubmitting(false)
    setAlert('Something went wrong. Please try again later!!!', 'danger')
  }

  const onSuccess = data => {
    if ((data || []).length) {
      const body = {
        vendor_user_id: null,
        user_id: data[0]?.user_id,
        vendor_id: vendorId
      }
      associateUserToVendor(
        pdbid,
        context,
        body,
        onSuccessVendorAccociated,
        () => {
          userAccountDelete(
            pdbid,
            data[0]?.user_id,
            onUserDeleteAccountSuccess,
            onUserDeleteAccountError
          )
        }
      )
    }
  }

  const onError = message => {
    setSubmitting(false)
    if (message) {
      setAlert(message, 'danger')
    } else {
      setAlert('Something went wrong. Please try again later!!!', 'danger')
    }
  }

  const onHandleAddVendor = () => {
    setSubmitting(true)
    const body = {
      user_id: null,
      auth_id: null,
      email: checkedEmail,
      name: UserName,
      type: 'Vendor',
      title: userTitle || null,
      phone: userPhone || null,
      alt_phone: userAltPhone || null,
      notes: userNote || null,
      hire_date: null,
      term_date: null,
      is_inactive: 'false',
      app_access: 'false',
      permission_rules: [
        {
          property_id: getProperty[0]?.property_id,
          project_id: getProperty[0]?.project_id,
          role_id: getVendorRole,
          internal_permission_rule_id: Short.uuid(),
          login_access: enableLogin ? null : 'false'?.toString(),
          action: 'Create',
          status: null,
          permission_rule_id: null
        }
      ]
    }
    if (!shouldGeneratePassword) {
      body.password = password
    }
    if (vendorId && urlEmail) {
      createOrEditUser(pdbid, body, onSuccess, onError)
    }
  }

  return (
    <div className="columns is-multiline add-vendor-login-user">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <FieldTemplate>
            <div className="label-heading column">
              <strong className="is-size-5">Personal Information</strong>
            </div>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Name">
              <input
                id="name"
                name="name"
                value={UserName}
                autoFocus
                onChange={e => setUserName(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Name"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Email Address">
              <input
                id="email"
                name="email"
                value={urlEmail}
                type="text"
                autoComplete="off"
                disabled
                placeholder="Enter Email Address"
                className="input form-control"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Title" isRequired={false}>
              <input
                id="title"
                name="title"
                value={userTitle}
                onChange={e => setUserTitle(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Title"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Phone" isRequired={false}>
              <input
                id="phone"
                name="phone"
                value={userPhone}
                onChange={e => setUserPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Alt Phone" isRequired={false}>
              <input
                id="altphone"
                name="altphone"
                value={userAltPhone}
                onChange={e => setUserAltPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Alt Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate isDisabledVcentered>
            <GeneralField fieldLabel="Notes" isRequired={false}>
              <textarea
                className="textarea  input form-control"
                rows={3}
                style={{ resize: 'none', marginTop: '2px' }}
                defaultValue={userNote}
                onChange={e => setUserNote(e.target.value)}
              />
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Enable Login" isRequired={false}>
              <TurnToggle
                isActive={enableLogin}
                onChange={state => {
                  setEnableLogin(state)
                  toggleGeneratePassword(true)
                }}
              />
            </GeneralField>
          </FieldTemplate>
          {enableLogin ? (
            <>
              {' '}
              <FieldTemplate>
                <div className="label-heading column">
                  <strong>Password</strong>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <div className="column">
                  <label>
                    The password will be shared with the user via email
                  </label>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <div className="column">
                  <label
                    className={`checkbox ${shouldGeneratePassword &&
                      'is-active'}`}>
                    <span
                      className={`checkbox-input ${shouldGeneratePassword &&
                        'is-active'}`}>
                      <span className="checkbox-inner" />
                      <input
                        type="checkbox"
                        className="checkbox-original"
                        checked={shouldGeneratePassword}
                        name="generatepassword"
                        onChange={e => toggleGeneratePassword(e.target.checked)}
                      />
                    </span>
                    <span
                      className={`checkbox-label ${shouldGeneratePassword &&
                        'is-active'}`}>
                      Generate Password
                    </span>
                  </label>
                </div>
              </FieldTemplate>
              {!shouldGeneratePassword && (
                <>
                  <FieldTemplate>
                    <GeneralField fieldLabel="Password">
                      <PasswordField
                        id="password"
                        name="password"
                        value={password}
                        onChange={e => {
                          setPassword(e.target.value)
                          setConfirmPassword('')
                          setIsValidConfirmPassword(false)
                          optimizedPasswordValidation(
                            e.target.value,
                            setIsValidPassword
                          )
                        }}
                      />
                    </GeneralField>
                  </FieldTemplate>
                  {isValidpassword && notValidPasswordError()}
                  <FieldTemplate>
                    <GeneralField fieldLabel="Confirm Password">
                      <PasswordField
                        id="confirmpassword"
                        name="confirmpassword"
                        disabled={!(password || '').trim() || isValidpassword}
                        value={confirmPassword}
                        onChange={e => {
                          setConfirmPassword(e.target.value)
                          optimizedPasswordValidation(
                            e.target.value,
                            setIsValidConfirmPassword
                          )
                        }}
                      />
                    </GeneralField>
                  </FieldTemplate>
                  {isValidConfirmpassword && notValidPasswordError()}
                  {!isValidpassword &&
                    !isValidConfirmpassword &&
                    password &&
                    confirmPassword &&
                    (password || '').trim() !==
                      (confirmPassword || '').trim() && (
                      <ErrorTemplate>
                        <p>Password and Confirm Password do not match</p>
                      </ErrorTemplate>
                    )}
                </>
              )}
            </>
          ) : null}

          <div>
            <div className="button-wrapper">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => history.goBack()}>
                Cancel
              </button>
              <button
                data-tip
                data-for="addNewUser"
                onClick={() => {
                  if (!isSubmitButtonEnable) {
                    onHandleAddVendor()
                  }
                }}
                className={`button main-button is-primary ${
                  isSubmitButtonEnable ? 'button-disabled' : ''
                }`}>
                Add User
                {isSubmitting && (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                )}
              </button>
              <ReactTooltip
                className="customTooltipTheme"
                id="addNewUser"
                place="top"
                disable={!isSubmitButtonEnable}
                effect="solid">
                <div>
                  <p>
                    Please fill all the required (
                    <span className="required-star">*</span>) fields
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorLoginUserAdd
