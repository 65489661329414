import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ChargesDashboardMain from '../../parents/charges-dashboard-main'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { getChargesList } from '../../modules/charge'
import { resetPurchaseOrderDetail } from '../../modules/purchase-orders'

const Charges = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <ChargesDashboardMain {...props} />
  }

  return <BigLoading />
}

Charges.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setupAllVendorsGet: PropTypes.func,
  resetPurchaseOrderDetail: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, setupVendors, charge }) => ({
  user,
  context,
  modal,
  setupVendors,
  charge
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      getChargesList,
      resetPurchaseOrderDetail
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Charges)
)
