import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import searchListMock from '../mocks/search_list.mock'

/**
 * Global search data module
 * @redux
 * @reduxActionScope search
 * @module search
 */

/**
 * Reset search data
 * @type {Redux.ActionType}
 */
export const RESET_SEARCH = 'search/RESET_SEARCH'
/**
 * Request search list
 * @type {Redux.ActionType}
 */
export const SEARCH_REQUESTED = 'search/SEARCH_REQUESTED'
/**
 * Search list failed
 * @type {Redux.ActionType}
 */
export const SEARCH_FAIL = 'search/SEARCH_FAIL'
/**
 * Update requested search list with successful data from API
 * @type {Redux.ActionType}
 */
export const SEARCH_SUCCESS = 'search/SEARCH_SUCCESS'

const initialState = {
  hasRequested: false,
  isRequesting: false,
  isError: false,
  results: []
}

/**
 * Search data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_SEARCH:
      return initialState
    case SEARCH_REQUESTED:
      return {
        ...state,
        hasRequested: true,
        isRequesting: true,
        isError: false
      }
    case SEARCH_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        results: action.results
      }
    case SEARCH_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

const _listFail = dispatch => {
  dispatch({
    type: SEARCH_FAIL
  })
}

const DEFAULT_VALUE = ''

const _resultAdapter = item => ({
  match: typeof item.match === 'string' ? item.match : DEFAULT_VALUE,
  service_uri:
    typeof item.service_uri === 'string' ? item.service_uri : DEFAULT_VALUE,
  servicetype_id:
    typeof item.servicetype_id === 'string'
      ? item.servicetype_id
      : DEFAULT_VALUE,
  service_name:
    typeof item.service_name === 'string' ? item.service_name : DEFAULT_VALUE,
  phase: typeof item.phase === 'string' ? item.phase : DEFAULT_VALUE
})

const _resultsAdapter = data => _.map(data, result => _resultAdapter(result))

/**
 * Resets search data
 * @redux
 * @reduxActionCreator RESET_SEARCH
 */
export const resetSearch = () => {
  return dispatch => {
    dispatch({
      type: RESET_SEARCH
    })
  }
}

/**
 * Search units
 * @redux
 * @reduxActionCreator SEARCH_REQUESTED, SEARCH_SUCCESS, SEARCH_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const searchUnits = (pdbid, context, q) => {
  return dispatch => {
    dispatch({
      type: SEARCH_REQUESTED
    })
    Service.getGlobalSearch(pdbid, context, q)
      .then(async res => {
        //if (res.ok) {
        //const list = await res.json()
        const list = searchListMock
        dispatch({
          type: SEARCH_SUCCESS,
          results: _resultsAdapter(list.results)
        })
        /*} else {
          _listFail(dispatch)
        }*/
      })
      .catch(() => {
        _listFail(dispatch)
      })
  }
}
