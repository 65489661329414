import _ from 'lodash'
import React from 'react'

export const getHeaderData = () => [
  {
    key: 'vender_id',
    display: 'Vendor Id',
    textAlign: 'left',
    notSortable: true,
    hidden: true
  },
  {
    key: 'name',
    display: 'Business Name',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
  },
  {
    key: 'contact_name',
    display: 'Contact Name',
    textAlign: 'left',
    notSortable: false,
    filterable: true,

  },
  {
    key: 'email',
    display: 'Email Address',
    textAlign: 'left',
    notSortable: false,
    filterable: true,

  },
  {
    key: 'simplifiedServices',
    display: 'Service(s)',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
    getValues: data => {
      const allServices = data || []
      return _.filter(
        _.reduce(
          _.flatten(
            _.map(allServices, o =>
              _.map(o.services, s => ({
                key: s.name,
                caption: s.name
              }))
            )
          ),
          (acc, itr) =>
            _.find(acc, a => a.key === itr.key) ? acc : [...acc, itr],
          []
        ),
        Boolean
      )
    }, 
    evaluateMatch: (filter, element={}) =>{
      return _.intersection(_.map(element.services, s => s.name), (filter || [])).length > 0
    }
  },
  {
    key: 'status',
    display: 'Status',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
    template: row => {
      const { status } = row || {}
      if(status === 'Vendor') {
        return <span className="is-existing-vendor">Vendor</span>
      }
      return <span>{status}</span>
    },
    getValues: data =>  _.uniq(_.map((data || []), item =>  item.status)),
    evaluateMatch: (filter, element={}) => (filter || []).indexOf(element.status) >= 0
  },
]

