import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import TogglePurchaseOrder from './TogglePurchaseOrder'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import { unitNotesCreate, unitNotesUpdate } from '../../modules/notes'
import { allUnitsGet, updateAutomaticCharge } from '../../modules/charge'
import BasicLoading from '../../components/basic-loading'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import './index.scss'

const AddChargeModal = props => {
  const {
    onClose,
    setupVendors,
    charge,
    unitNotesCreate,
    isEdit = false,
    user,
    context,
    setupAllVendorsGet,
    allUnitsGet,
    added = null,
    amount,
    unit_id,
    vendor_id,
    servicetype_id,
    task_id,
    updateAutomaticCharge,
    unitNotesUpdate,
    setUpdateChargesList,
    note
  } = props || {}

  // States
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [chargeAmount, setChargeAmount] = useState(amount || '')
  const [chargeNote, setChargeNote] = useState(note || '')
  const [activePurchaseOrder, setActivePurchaseOrder] = useState(
    added === null ? false : true
  )
  const [selectedVendorId, setSelectedVendorId] = useState(vendor_id || '')
  const [vendorServices, setVendorServices] = useState([])
  const [selectedVendorServiceId, setSelectedVendorServiceId] = useState(
    servicetype_id || ''
  )
  const [selectedUnitId, setSelectedUnitId] = useState(unit_id || '')
  const [isVendorLoading, setIsVendorLoading] = useState(true)
  const [isUnitsLoading, setIsUnitsLoading] = useState(true)
  const [isVendorServicesLoading, setIsVendorServicesLoading] = useState(false)

  // Redux data
  const { allVendors } = setupVendors || {}
  const { data: allVendorData, isRequesting } = allVendors || {}
  const { allUnits } = charge || {}
  const { data: allUnitsData, isRequesting: isUnitsRequesting } = allUnits || {}

  useEffect(() => {
    if (vendor_id !== '') {
      setIsVendorServicesLoading(true)
      const getServices = (allVendorData || []).filter(
        d => d?.vendor_id === vendor_id
      )
      if ((getServices || []).length) {
        setVendorServices(getServices[0]?.Services)
      }
      setIsVendorServicesLoading(false)
    }
  }, [vendor_id, allVendorData])

  useEffect(() => {
    getFieldsDefaultData()
  }, [])

  const onGetVendorSuccess = () => {
    setIsVendorLoading(false)
  }

  const onGetVendorError = () => {
    setIsVendorLoading(false)
  }

  const getFieldsDefaultData = async () => {
    setIsVendorLoading(true)
    setIsUnitsLoading(true)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const onSuccess = onGetVendorSuccess()
    const onError = onGetVendorError()
    await setupAllVendorsGet(pdbid, context, onSuccess, onError)
    await allUnitsGet({
      pdbid,
      context,
      onSuccess: () => {
        setIsUnitsLoading(false)
      },
      onError: () => {
        setIsUnitsLoading(false)
      }
    })
  }

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '').toString().indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '').length - x
  }

  const onHandleAmountChange = ({ target }) => {
    const { value } = target || {}
    let filterValue = value || ''
    // Only allow Numbers and .
    filterValue = (value || '').replace(/[^0-9\.]/g, '')
    // Don't allow . as first character
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    // Check if . already exists
    if ((filterValue || '').toString().split('.').length > 2) {
      filterValue = (filterValue || '').replace(/\.+$/, '')
    }
    // Dont allow more than 2 decimal points
    if (hasDecimalPlace(filterValue, 3)) {
      return
    }
    setChargeAmount(filterValue)
  }

  const onSuccess = () => {
    setErrorMessage('')
    setSubmitting(false)
    setUpdateChargesList(true)
    onClose()
  }
  const onError = () => {
    setSubmitting(false)
    setErrorMessage('Something went wrong, Please try again !!!')
  }

  const onSave = async () => {
    setSubmitting(true)
    const { name, user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    let body = {
      note: chargeNote,
      type: 'Additional Charge',
      creation_date: moment().format('DD-MMM-YY'),
      created_by: name || '',
      charge: chargeAmount,
      servicetype: '',
      servicetype_id: selectedVendorServiceId || '',
      do_not_invoice: activePurchaseOrder === false ? null : 'true',
      vendor_id: selectedVendorId || '',
      updated_amount: 0
    }
    const unitId = selectedUnitId?.unit_id || ''
    if (isEdit) {
      const getEditType = (task_id || '').toString().toLowerCase()
      if ((getEditType || '').includes('note')) {
        const unitId = unit_id?.unit_id || ''
        return unitNotesUpdate(
          pdbid,
          context,
          unitId,
          { ...body, note_id: task_id },
          onSuccess,
          onError
        )
      }
      if ((getEditType || '').includes('task')) {
        const body = {
          updated_amount: chargeAmount,
          do_not_invoice: activePurchaseOrder === true ? 'true' : null
        }
        return updateAutomaticCharge({
          pdbid,
          context,
          body,
          task_id,
          onSuccess,
          onError
        })
      }
    }
    return unitNotesCreate(pdbid, context, unitId, body, onSuccess, onError)
  }

  const getSaveButtonText = () => {
    if (isEdit) {
      return isSubmitting ? 'Updating' : 'Update'
    }
    return isSubmitting ? 'Creating' : 'Create'
  }

  const isButtonDisable = () => {
    if (isEdit) {
      return (
        (allVendorData || []).length === 0 ||
        (allUnits || []).length === 0 ||
        !selectedVendorId ||
        !selectedVendorServiceId ||
        !selectedUnitId ||
        !chargeAmount ||
        parseFloat(chargeAmount || 0) == 0 ||
        (chargeAmount || '').toString().startsWith('.') ||
        errorMessage ||
        ((task_id || '')
          .toString()
          .toLowerCase()
          .includes('note') &&
          (chargeNote || '')?.toString()?.trim() === '')
      )
    }
    return (
      (allVendorData || []).length === 0 ||
      (allUnits || []).length === 0 ||
      !selectedVendorId ||
      !selectedVendorServiceId ||
      !selectedUnitId ||
      !chargeAmount ||
      parseFloat(chargeAmount || 0) == 0 ||
      (chargeAmount || '').toString().startsWith('.') ||
      errorMessage ||
      (chargeNote || '')?.toString()?.trim() === ''
    )
  }

  const handleChargeNotesChange = ({ currentTarget: input }) => {
    input.value = input.value?.replace(/^[-\s]+/, '')
    setChargeNote(input.value)
  }

  return (
    <div className="add-charges-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            {isEdit ? 'Edit Charge' : 'Create Charge'}
          </p>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      {isVendorLoading || isUnitsLoading || isVendorServicesLoading ? (
        <BasicLoading />
      ) : (
        <div className="is-full">
          <form>
            <div className="form-group columns">
              <div className="label column is-one-third">
                <label>Vendor:</label>
              </div>
              <div className="input-field column is-two-thirds">
                <select
                  disabled={isEdit}
                  className="input is-inline"
                  onChange={e => {
                    setSelectedVendorServiceId('')
                    setVendorServices([])
                    setSelectedVendorId(e.target.value)
                    const getServices = (allVendorData || []).filter(
                      d => d?.vendor_id === e.target.value
                    )
                    if ((getServices || []).length) {
                      setVendorServices(getServices[0]?.Services)
                    }
                  }}
                  value={selectedVendorId}>
                  <option value="">Select</option>
                  {(allVendorData || []).map((vendor, i) => {
                    const { name, vendor_id } = vendor || {}
                    return (
                      <option value={vendor_id} key={i}>
                        {name || ''}
                      </option>
                    )
                  })}
                </select>
                {(allVendorData || []).length === 0 &&
                  !isVendorLoading &&
                  !isRequesting && (
                    <span className="general-error">
                      There are no vendors in this turn. You can add vendors
                      from Turn Setup.
                    </span>
                  )}
              </div>
            </div>
            <div className="form-group columns">
              <div className="label column is-one-third">
                <label>Service:</label>
              </div>
              <div className="input-field column is-two-thirds">
                <select
                  className="input is-inline"
                  disabled={!selectedVendorId || isEdit}
                  onChange={e => setSelectedVendorServiceId(e.target.value)}
                  value={selectedVendorServiceId}>
                  <option value="">Select</option>
                  {(vendorServices || []).map((service, i) => {
                    const { servicetype_id, service_name } = service || {}
                    return (
                      <option value={servicetype_id} key={i}>
                        {service_name || ''}
                      </option>
                    )
                  })}
                </select>
                {(vendorServices || []).length === 0 &&
                  selectedVendorId &&
                  !isVendorServicesLoading && (
                    <span className="general-error">
                      There are no services associated with this vendor.
                    </span>
                  )}
              </div>
            </div>
            <div className="form-group columns">
              <div className="label column is-one-third">
                <label>Unit:</label>
              </div>
              <div className="input-field column is-two-thirds">
                <MultiSelectDropdown
                  displayKey="unit"
                  value={selectedUnitId}
                  defaultValues={allUnitsData}
                  isDisabled={isEdit}
                  onChange={v => setSelectedUnitId(v)}
                  isMulti={false}
                  isClearable={true}
                  placeholder="Select"
                  noOptionsMessage="No Unit Available"
                  customStyles={{
                    valueContainer: provided => ({
                      ...provided,
                      minHeight: '45px',
                      maxHeight: '100px',
                      overflow: 'auto',
                      position: 'relative',
                      borderRadius: '3px'
                    }),
                    control: (base, state) => ({
                      ...base,
                      border: '1px solid #e5e9f2',
                      boxShadow: 'none',
                      '&:hover': {
                        border: '1px solid #e5e9f2'
                      },
                      backgroundColor: state.isDisabled
                        ? 'ghostwhite'
                        : base.backgroundColor,
                      cursor: isEdit ? 'not-allowed' : 'default'
                    }),
                    container: styles => ({
                      ...styles,
                      pointerEvents: 'all'
                    })
                  }}
                />
                {(allUnitsData || []).length === 0 &&
                  !isUnitsLoading &&
                  !isUnitsRequesting && (
                    <span className="general-error">
                      There are no unit associated with this vendor.
                    </span>
                  )}
              </div>
            </div>
            <div className="form-group columns">
              <div className="label column is-one-third">
                <label>Amount:</label>
              </div>
              <div className="input-field column is-two-thirds">
                <input
                  id="chargeAmount"
                  name="chargeAmount"
                  type="text"
                  placeholder=""
                  value={chargeAmount}
                  onChange={e => onHandleAmountChange(e)}
                  className="form-control "></input>
              </div>
            </div>
            <div className="form-group columns note-field-spacing ">
              <div className="label column is-one-third">
                <label>Note:</label>
              </div>
              <div className="input-field column is-two-thirds">
                <textarea
                  className="textarea m-t-sm m-b-40"
                  rows={3}
                  maxLength={3000}
                  style={{ resize: 'none' }}
                  id="note-edit"
                  defaultValue={chargeNote}
                  onChange={handleChargeNotesChange}
                />
                <p className="character-limit">
                  Character Limit: <span>{(chargeNote || []).length}</span>
                  /3000
                </p>
              </div>
            </div>
            <div className="form-group columns toggle-field-spacing">
              <div className="label column is-one-third">
                <label>Do Not Invoice:</label>
              </div>
              <div className="input-field column is-two-thirds">
                <TogglePurchaseOrder
                  isActive={activePurchaseOrder}
                  onChange={state => setActivePurchaseOrder(state)}
                />
              </div>
            </div>
            {errorMessage !== '' && (
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            )}
            <div className="columns is-vcentered m-b-sm m-t-sm m-b-space">
              <div className="center-container">
                <button
                  className="button main-button is-secondary m-r-md"
                  onClick={() => {
                    onClose()
                  }}>
                  Cancel
                </button>
                <button
                  disabled={isButtonDisable()}
                  className="button main-button is-primary"
                  onClick={event => {
                    event.preventDefault()
                    onSave()
                  }}>
                  {getSaveButtonText()}
                  {isSubmitting && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

AddChargeModal.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ setupVendors, charge, user, context }) => ({
  setupVendors,
  charge,
  user,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setupAllVendorsGet,
      allUnitsGet,
      unitNotesCreate,
      updateAutomaticCharge,
      unitNotesUpdate
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddChargeModal)
