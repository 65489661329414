import styled from 'styled-components'

export const InHouseTableStyles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }

  .in-house-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;

    .select {
      z-index: 0;
      margin-top: 8px !important;
      margin-left: 8px !important;
      width: 90%;

      select {
        width: 100% !important;
      }
    }

    .header {
      width: 100% !important;
      overflow: hidden;
      margin-bottom: unset !important;

      &.scroll {
        overflow-x: scroll;
      }
    }

    .tr {
      position: relative;

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      /* :first-child {
        .th {
          border-top: 1px solid #e5e9f2;
        }
      } */
      .th {
        background: #ffffff;
      }
    }
    .th {
      text-align: center;
      padding: 0px;
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      border-right: 1px solid #e5e9f2;
      position: relative;
      background: inherit;
    }

    .th:nth-child(2),
    .td:nth-child(2) {
      border-right: 1px solid #122048 !important;
    }

    &.sticky {
      width: 100%;
      overflow: hidden;
      .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        z-index: 4;
        top: 0;
      }

      .body {
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`
