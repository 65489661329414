import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'
import Service from '../../service'
import { getUser } from '../../modules/user'
import {
  updateContext,
  cancelContextChange,
  updateSkipContext
} from '../../modules/context'
import { openModal, closeModal, setModalContent } from '../../modules/modal'
import ContextSelector from '../../parents/context-selector'
import { useAuth0 } from '../../components/lock/auth0-spa'
// import { sectionAllowed } from '../../security'
// import { isEmpty } from 'lodash'
import { getDownloadState } from '../../modules/download-inspections'

const PrivateRoute = props => {
  const {
    auth,
    context,
    user,
    location,
    history,
    getUser,
    updateContext,
    openModal,
    closeModal,
    skipContext = false,
    onlyProperties = false,
    setModalContent,
    cancelContextChange,
    updateSkipContext,
    path,
    component: Component,
    getDownloadState,
    ...rest
  } = props
  const { isAuthenticated } = useAuth0()
  const contextIsSelected =
    context.project !== null && context.property !== null
  const contextCookie = Service.getCtxCookie()
  const isNullContextValue =
    typeof contextCookie === 'string' && contextCookie.search('null') !== -1
  const tenantCookie = Service.getTntCookie()

  useEffect(() => {
    updateSkipContext(skipContext)
  }, [skipContext])

  // if (user.hasMadeInitialRequest && !user.isRequesting) {
  //   const allowed = sectionAllowed(location.pathname, location.search, user)
  //   if (!allowed) {
  //     const { from } = location.state || { from: { pathname: '/' } }
  //     return <Redirect to={from} />
  //   }
  // }

  const render = props => {
    if (isAuthenticated === true) {
      let template = [
        <div key="main-component" className="is-fullheight">
          <Component {...rest} />
        </div>
      ]
      if (
        contextCookie === null ||
        context.changeRequested ||
        isNullContextValue
      ) {
        if (context.changeRequested || (!contextIsSelected && !skipContext)) {
          if (!context.changeRequested) template = []

          template.push(
            <ContextSelector
              user={user}
              context={context}
              updateContext={updateContext}
              openModal={openModal}
              closeModal={closeModal}
              setModalContent={setModalContent}
              cancelContextChange={cancelContextChange}
              contextCookie={contextCookie}
              onlyProperties={onlyProperties}
              history={history}
              location={location}
              tenantCookie={tenantCookie}
              key="context-selector"
            />
          )
        }
      }
      return template
    }
    return null
  }
  return <Route path={path} render={render} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  auth: PropTypes.object,
  context: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  skipContext: PropTypes.bool,
  getUser: PropTypes.func,
  updateContext: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  cancelContextChange: PropTypes.func
}

const mapStateToProps = ({ user, auth, context }) => ({
  user,
  auth,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser,
      updateContext,
      openModal,
      closeModal,
      setModalContent,
      cancelContextChange,
      updateSkipContext,
      getDownloadState
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivateRoute)
)
