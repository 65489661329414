import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
// TODO 005:
// Gear icon - will be needed when the user setup feature has been launched
import iconSettings from './icons/settings@1x.svg'
import iconProfile from './icons/profile@1x.svg'
import { sectionAllowed } from '../../security'
import './index.scss'
// TODO 005:
// Gear icon - will be needed when the user setup feature has been launched
import ManageOptions from './manage-options'
import { Link } from 'react-router-dom'
import PermissionGate from '../../components/permission-gate'
import { useDispatch } from 'react-redux'
import { changePassword } from '../../modules/user'
import { setAlert } from '../../modules/alerts'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const BLACKLIST_PATHS = ['/inspections-setup']
const ONLY_PROPERTIES_PATHS = ['/inspections']

const TopBar = props => {
  const {
    auth,
    toggleSidebar,
    scootOver,
    context,
    user,
    requestContextChange,
    sidebar,
    isLoading
  } = props

  const history = useHistory()
  const [usersAccess, setUsersAccess] = useState(false)
  const dispatch = useDispatch()

  let isOpen = scootOver === true && sidebar.isOpen === true && !isLoading

  const { user_metadata, roles, changePasswordLoader } = user || {}
  const { projects, properties, pdbids, pdbid } = user_metadata || {}
  const checkIfVendorLogin = (roles || []).includes('RFP Vendor')

  useEffect(() => {
    const { isAuthenticated, hasMgmtToken } = auth
    if (isAuthenticated && hasMgmtToken) {
      setUsersAccess(sectionAllowed('/setup/users', null, user))
    }
  }, [auth, user])

  const display = {
    project: (projects || []).find(p => p.id === context.project) || {
      name: ''
    },
    property: (properties || []).find(p => p.id === context.property) || {
      name: ''
    },
    tenant: (pdbids || []).find(p => p === pdbid) || ''
  }
  const tenantName = display.tenant
    ? (display.tenant || '').toString().replace('_', ' ')
    : ''
  const userReady = user.hasMadeInitialRequest && !user.isRequesting
  const contextIsSelected =
    context.project !== null && context.property !== null

  const onSuccess = () => {
    dispatch(
      setAlert(
        'An email with password reset link has been shared on your email address.',
        'success'
      )
    )
  }

  const onError = () => {
    dispatch(
      setAlert('Something went wrong. Please try again later!!!', 'danger')
    )
  }

  const handleChangePassword = e => {
    e.preventDefault()
    dispatch(changePassword(pdbid, context, {}, onSuccess, onError))
  }

  const handleLogout = () => {
    return history.push('/logout')
  }

  const isHidden =
    contextIsSelected && userReady && !context?.changeRequested
      ? ''
      : 'is-hidden'

  return (
    <nav
      className={`navbar topbar ${isOpen ? 'is-open' : ''}`}
      role="navigation"
      aria-label="main navigation">
      <div className="navbar-menu">
        <div className="navbar-start">
          <div className="navbar-item">
            {!sidebar.isOpen && (
              <FontAwesomeIcon
                icon={faChevronRight}
                className="has-text-primary m-t-none m-b-none m-r-md is-hidden-touch is-pointer"
                onClick={toggleSidebar}
              />
            )}
            <FontAwesomeIcon
              icon={faBars}
              size="2x"
              className="is-hidden-desktop is-pointer is-dark-blue"
              onClick={toggleSidebar}
            />
            {!context?.skipContext && (
              <>
                {!BLACKLIST_PATHS.includes(history.location.pathname) &&
                  (checkIfVendorLogin ? (
                    <>
                      <p className="animated fadeIn is-inline is-hidden-touch heading-4 m-r-sm is-capitalized">
                        {!ONLY_PROPERTIES_PATHS.includes(
                          history.location.pathname
                        ) && (
                          <>
                            {tenantName}

                            {tenantName && (
                              <>
                                <FontAwesomeIcon
                                  size="xs"
                                  icon={faChevronDown}
                                  className="animated fadeIn is-hidden-touch is-pointer m-l-sm"
                                  onClick={requestContextChange}
                                />
                              </>
                            )}
                          </>
                        )}
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className={`animated fadeIn is-inline is-hidden-touch heading-4 m-r-sm ${isHidden}`}>
                        {!ONLY_PROPERTIES_PATHS.includes(
                          history.location.pathname
                        ) && (
                          <>
                            {display.project.name}

                            {display.project.name && (
                              <>
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  size="xs"
                                  className="has-text-primary m-l-md m-t-none m-b-none m-r-none"
                                />
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  size="xs"
                                  className="has-text-primary m-r-md m-t-none m-b-none m-l-none"
                                />
                              </>
                            )}
                          </>
                        )}

                        {display.property.name}
                      </p>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`animated fadeIn is-hidden-touch ${isHidden} is-pointer m-l-sm`}
                        onClick={requestContextChange}
                      />
                    </>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className="navbar-end">
          {/* TODO 005:
          Gear icon - will be needed when the user setup feature has been launched */}
          <PermissionGate name={'settings-icon'}>
            <div className="navbar-item has-dropdown is-hoverable">
              <span className="navbar-link is-arrowless">
                <img src={iconSettings} alt="Settings Icon" />
              </span>
              <div className="navbar-dropdown toolbar-dropdown is-right is-boxed">
                <ManageOptions user={user} context={context} />
              </div>
            </div>
          </PermissionGate>
          <div className="navbar-item has-dropdown is-hoverable">
            <span className="navbar-link is-arrowless">
              <img src={iconProfile} alt="Profile Icon" className="big-icon" />
            </span>
            <div className="navbar-dropdown is-right is-boxed">
              <span className="navbar-item" onClick={handleLogout}>
                <Link
                  href="/"
                  onClick={e => {
                    // e.stopPropagation()
                    e.preventDefault()
                  }}>
                  Logout
                </Link>
              </span>
              <span className="navbar-item">
                <Link
                  to="/"
                  className={`${changePasswordLoader ? 'isDisabled' : ''}`}
                  onClick={handleChangePassword}>
                  Reset Password
                  {changePasswordLoader && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                  )}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

TopBar.propTypes = {
  toggleSidebar: PropTypes.func,
  scootOver: PropTypes.bool,
  context: PropTypes.object,
  user: PropTypes.object,
  requestContextChange: PropTypes.func,
  openTooltip: PropTypes.func,
  closeTooltip: PropTypes.func,
  setTooltipContent: PropTypes.func,
  tooltip: PropTypes.object,
  logout: PropTypes.func,
  sidebar: PropTypes.object
}

export default TopBar
