import React, { useCallback, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { icons } from './icons'
import FilterButton from './filter-button'
import AppliedFilters from './applied-filters'

const TableTop = ({
  noTableTop,
  noBorders = false,
  isLoading,
  isUpdating = false,
  title,
  header,
  allowFilter,
  showCustomFilterOption,
  allowSort,
  buttonsSection,
  additionalControls,
  additionalOptions,
  additionalInfo = null,
  textFilter,
  onSetTextFilter,
  activeFilters,
  customFilters,
  oneFilterAtTime,
  onSetFilters,
  onFilterRemove,
  onToggleFilterModal,
  onToggleSortModal,
  onExport,
  getTagCaption,
  tabOptions = []
}) => {
  const [tabOptionsCopy, setTabOptionsCopy] = useState([...tabOptions])
  
  useEffect(() => {
    if (tabOptions.length) {
      setTabOptionsCopy(tabOptions)
    }
  }, [tabOptions])

  const getTitle = () => {
    if (typeof title === 'string') {
      return (
        <div className="columns">
          <div className="column tabs is-boxed">
            <ul className="heading-5">{title}</ul>
          </div>
        </div>
      )
    }
    return title
  }

  const handleTabOptionsClick = useCallback(tab => {
    tab.onClick(tab)
    setTabOptionsCopy(currentTabs => currentTabs.map(item => {
      item === tab ? item.active = true : item.active = false
      return item
    }))

  }, [tabOptions])

  const tabOptionHoverHandler = (color, {target}) => target.style.background = color
  const tabOptionOutHandler = (isActiveTab, {target}) => !isActiveTab ? target.style.background = '' : null

  return (
    <div
      className={[
        'section',
        'has-background-white',
        'table-header',
        noTableTop ? 'no-table-top' : '',
        tabOptions.length > 0 ? 'm-t-xl' : '',
        Object.keys(activeFilters).length ||
        !allowFilter ||
        additionalInfo !== null
          ? 'has-filters-expanded'
          : ''
      ].join(' ')}
      style={
        !noBorders
          ? {
            border: '1px solid #e5e9f2',
            borderBottom: 'none'
          }
          : {}
      }>

      {tabOptions.length > 0 && !isLoading && (
        <div className="column tab-options-container">
          <ul className="tab-options">
            {tabOptionsCopy.map(tab => (
              <li
                key={tab.label}
                className={`${tab.active ? 'active' : ''}`}
                onMouseOver={(event) => tabOptionHoverHandler(tab.overrideActiveBackground, event)}
                onMouseOut={(event) => tabOptionOutHandler(tab.active, event)}
                style={{ background: (tab.active && tab.overrideActiveBackground) || '' }}
                onClick={() => handleTabOptionsClick(tab)}>
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
      )}

      {!noTableTop && (
        <div className="columns is-vcentered m-b-md is-mobile">
          {title && (
            <div className="column is-half-mobile">
              {getTitle()}
            </div>
          )}

          {isLoading ? (
            <div className="column has-text-right is-narrow is-size-4">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          ) : (
            onSetTextFilter && (
              <div className="column has-text-right is-narrow">
                <input
                  type="text"
                  placeholder="Search..."
                  className="input is-inline text-filter"
                  value={textFilter}
                  onChange={e => onSetTextFilter(e.target.value)}
                />
              </div>
            )
          )}
          {additionalControls && (
            <div className="column has-text-right is-narrow is-half-mobile">
              {additionalControls}
            </div>
          )}
        </div>
      )}

      <div className="columns m-t-xs is-bottomaligned m-b-none is-desktop">
        <div className="column p-t-none">
          {buttonsSection}
          {true !== null && (
            <div className="columns">
              <div className="column is-full">{additionalInfo}</div>
            </div>
          )}
        </div>
        {!isLoading && (
          <div className="column p-t-none is-narrow toolbar-parent">
            <div className="columns toolbar is-vcentered">
              {additionalOptions && (
                <>
                  <div className="column is-narrow tool-section aditional-options">
                    {additionalOptions}
                  </div>
                  <div className="column divider" />
                </>
              )}
              <div className="column is-narrow tool-section">
                {(allowFilter) && (
                  <FilterButton
                    header={header}
                    activeFilters={activeFilters}
                    customFilters={customFilters}
                    oneFilterAtTime={oneFilterAtTime}
                    onSetFilters={onSetFilters}
                    onToggleModal={onToggleFilterModal}
                    showCustomFilterOption={showCustomFilterOption}
                  />
                )}
                {allowSort && (
                  <button
                    className="button tool-button"
                    type="button"
                    onClick={() => onToggleSortModal(true)}>
                    <img src={icons.sort} alt="Sort data" className="m-r-xs" />
                    Sort
                  </button>
                )}
                {onExport && (
                  <button
                    className="button tool-button"
                    type="button"
                    onClick={() => onExport()}>
                    <img
                      src={icons.exportCsv}
                      alt="Export data"
                      className="m-r-xs"
                    />
                    Export
                  </button>
                )}
              </div>
            </div>
            <AppliedFilters
              className="pill-container is-mobile"
              activeFilters={activeFilters}
              customFilters={customFilters}
              definitions={header}
              onFilterRemove={onFilterRemove}
              getTagCaption={getTagCaption}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TableTop
