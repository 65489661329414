import _ from 'lodash'
import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import SimpleBar from 'simplebar-react'
import Paginator from '../paginator'
import TableTop from './table-top'
import TableHead from './table-head'
import TableRow from './table-row'
import FilterModal from './filter-modal'
import SortModal from './sort-modal'
import { removeFilterValue } from './helpers'
import './index.scss'

/**
 * Component for showing a confirmation modal
 *
 * @component
 * @example
 * const header = [ {
 *   key: 'service',
 *   display: ''
 * }, {
 *   key: 'budget',
 *   display: 'Budget',
 *   textAlign: 'right'
 * }, {
 *   key: 'estimate',
 *   display: 'Estimate',
 *   textAlign: 'right'
 * }, {
 *   key: 'actual',
 *   display: 'Actual',
 *   textAlign: 'right'
 * }, {
 *   key: 'difference',
 *   display: 'Difference',
 *   textAlign: 'right'
 * }]
 *
 * const data = [ {
 *   service: 'Paint',
 *   budget: '$0.00',
 *   estimate: '$0.00',
 *   actual: '$0.00',
 *   difference: '$0.00'
 * }, {
 *   service: 'Clean',
 *   budget: '$0.00',
 *   estimate: '$0.00',
 *   actual: '$0.00',
 *   difference: '$0.00'
 * }, {
 *   service: 'CP Clean',
 *   budget: '$0.00',
 *   estimate: '$0.00',
 *   actual: '$0.00',
 *   difference: '$0.00'
 * }, {
 *   service: 'CP Rep',
 *   budget: '$0.00',
 *   estimate: '$0.00',
 *   actual: '$0.00',
 *   difference: '$0.00'
 * }, {
 *   service: 'CR/V Rep',
 *   budget: '$0.00',
 *   estimate: '$0.00',
 *   actual: '$0.00',
 *   difference: '$0.00'
 * } ]
 *
 * return (
 *   <DataTable
 *     header={header}
 *     data={data}
 *     isLoading={false}
 *     tableTop={<div style={{ borderBottom: '2px solid #e5e9f2' }}></div>}
 *     resetPage={false}
 *     filterKey={null}
 *     filterValue={''}
 *     hasCheckboxes={false}
 *     key={''}
 *   />
 * )
 */
const DataTable = forwardRef(
  (
    {
      header,
      noTableTop = false,
      maxTableHeight,
      onSortChanged,
      singleSelectionColumn = 'unit',
      resetPage = false,
      resetChecked = false,
      customFilters,
      oneFilterAtTime = false,
      data,
      hasCheckboxes = false,
      singleSelection = false,
      checkboxClicked,
      checkboxDisabled,
      emptyValue,
      tooltip,
      tooltipSizeAuto = false,
      openTooltip,
      setTooltipContent,
      tooltipTemplate,
      getMenuDimensions,
      closeTooltip,
      parentProps,
      iconClicked,
      methods,
      rowKey = 'unit',
      emptyColor = null,
      filterKey,
      filterValue,
      isLoading,
      isUpdating,
      trStyle,
      pivotConfig = null,
      getColWidth = null,
      title = null,
      allowFilter = true,
      showCustomFilterOption = true,
      allowSort = true,
      onExport = null,
      additionalOptions = null,
      buttonsSection = null,
      onSetTextFilter = null,
      textFilter = '',
      additionalControls = null,
      rowOptions = [],
      calcTableMaxValue = false,
      tabOptions,
      outsideFilters = null,
      emptyDataMessage = 'No data to show',
      customResultsPerPage,
      additionalClasses,
      setSelectedUserStatus = null,
      onActiveRow,
      customCheckboxDisabledAllowed = () => {},
      customCheckboxDisabledKey = () => {}
    },
    ref
  ) => {
    const headerRefs = useRef([])
    const tableBodyRef = useRef()
    const tableHeaderHeight = 39
    const asyncActionOptionOnGoing =
      rowOptions.filter(option => option.loading).length > 0

    const [resultsPerPage, setResultsPerPage] = useState(
      customResultsPerPage || 15
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [checked, setChecked] = useState([])
    const [activeFilters, setActiveFilters] = useState({})
    const [activeSort, setActiveSort] = useState([])
    const [filterModalOpen, setFilterModalOpen] = useState(false)
    const [sortModalOpen, setSortModalOpen] = useState(false)
    const [visibleOnPage, setVisibleOnPage] = useState([])
    const [currentRow, setCurrentRow] = useState(null)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [tableMaxValue, setTableMaxValue] = useState(0)

    useEffect(() => {
      if (resetPage) {
        setCurrentPage(1)
        setActiveFilters({})
      }
    }, [resetPage])

    useEffect(() => {
      if (resetChecked) {
        setChecked([])
      }
    }, [resetChecked])

    useLayoutEffect(() => {
      if (!maxTableHeight && calcTableMaxValue) {
        setTableMaxValue(
          tableBodyRef.current.getBoundingClientRect().height +
            tableHeaderHeight
        )
      }
    }, [visibleOnPage])

    useEffect(() => {
      setChecked([])
    }, [customCheckboxDisabledAllowed()])

    const setOptionOpen = (value, item) => {
      setSelectedUserStatus && setSelectedUserStatus(item.blocked)
      setOptionsOpen(value)
    }

    const getTableHead = (columns, pivotConfig, isChild) => (
      <TableHead
        isLoading={isLoading}
        columns={columns}
        hasCheckboxes={hasCheckboxes}
        singleSelection={singleSelection}
        pivotConfig={pivotConfig}
        isChild={isChild}
        checkboxDisabled={checkboxDisabled}
        allChecked={
          checked.length > 0 && checked.length === visibleOnPage.length
        }
        setAllChecked={e => {
          let c = []
          if (e.target.checked) {
            if (customCheckboxDisabledAllowed()) {
              const getNonBlockedUnit = (data || [])
                .filter(d => d[customCheckboxDisabledKey()] === false)
                .map(d => d.unit)
              const intersectionOfUnits = (visibleOnPage || []).filter(
                element => getNonBlockedUnit.includes(element)
              )
              if (checked?.length !== 0) {
                c = []
              } else {
                c = intersectionOfUnits
              }
            } else {
              c = visibleOnPage
            }
          }
          setChecked(c)
          if (typeof checkboxClicked === 'function') checkboxClicked(c)
        }}
        headerRefs={headerRefs}
        setHeaderRef={(i, ref) => _.set(headerRefs, `current.${i}`, ref)}
        getColWidth={getColWidth}
        activeSort={activeSort}
        onSort={sort => {
          setActiveSort(sort)
          if (onSortChanged) {
            onSortChanged(sort)
          }
        }}
        hasRowOptions={rowOptions && rowOptions.length}
        asyncActionOptionOnGoing={asyncActionOptionOnGoing}
      />
    )

    const getCols = (dataItem, isLast, index) => (
      <TableRow
        key={`data-table-${index}`}
        d={dataItem}
        isLast={isLast}
        singleSelectionColumn={singleSelectionColumn}
        index={index}
        trStyle={trStyle}
        getTableHead={getTableHead}
        rowOptions={rowOptions}
        hasCheckboxes={hasCheckboxes}
        rowKey={rowKey}
        singleSelection={singleSelection}
        checkboxDisabled={checkboxDisabled}
        customCheckboxDisabledAllowed={customCheckboxDisabledAllowed}
        customCheckboxDisabledKey={customCheckboxDisabledKey}
        checked={checked}
        setChecked={newChecked => {
          setChecked(newChecked)
          if (typeof checkboxClicked === 'function') checkboxClicked(newChecked)
        }}
        emptyColor={emptyColor}
        pivotConfig={pivotConfig}
        methods={methods}
        data={data}
        header={header}
        emptyValue={emptyValue}
        iconClicked={iconClicked}
        tooltip={tooltip}
        tooltipSizeAuto={tooltipSizeAuto}
        openTooltip={openTooltip}
        setTooltipContent={setTooltipContent}
        closeTooltip={closeTooltip}
        tooltipTemplate={tooltipTemplate}
        headerRefs={headerRefs}
        getMenuDimensions={getMenuDimensions}
        parentProps={parentProps}
        currentRow={currentRow}
        setCurrentRow={value => {
          setCurrentRow(value)
          onActiveRow && onActiveRow(value)
        }}
        optionsOpen={optionsOpen}
        setOptionsOpen={value => setOptionOpen(value, dataItem)}
        asyncActionOptionOnGoing={asyncActionOptionOnGoing}
      />
    )

    const filterTable = useCallback(
      data => {
        let filtered = data
        let unitFilteredArray = []
        let unitResidentFilteredArray = []

        if (filterKey && filterValue) {
          if (filterKey.includes('unit')) {
            const regex = new RegExp(filterValue, 'gi')
            unitFilteredArray = data.filter(d => d['unit'].search(regex) > -1)
          }

          if (filterKey.includes('unit-resident')) {
            unitResidentFilteredArray = data.filter(d => {
              let spaceMatches = false
              Object.keys(d['unitSpaces']).forEach(space => {
                spaceMatches = d['unitSpaces'][space]['resident']
                  ?.toLowerCase()
                  ?.includes(filterValue)
              })
              return spaceMatches
            })
          }

          unitResidentFilteredArray = (unitResidentFilteredArray || []).filter(
            unitResident =>
              (unitFilteredArray || []).some(
                unit => !_.isEqual(unitResident, unit)
              )
          )

          filtered = [...unitFilteredArray, ...unitResidentFilteredArray]
        }

        const hasFilters = _.flatten(Object.values(activeFilters)).length > 0
        if (hasFilters) {
          filtered = filtered.filter(d => {
            let matches = true
            for (const key in activeFilters) {
              const filterValues = activeFilters[key]
              const customFilter = _.find(customFilters, f => f.key === key)
              const column = _.find(header, col => col.key === key)
              if (customFilter) {
                matches = matches && customFilter.match(d, filterValues)
              } else if (
                filterValues &&
                (!Array.isArray(filterValues) || filterValues.length)
              ) {
                if (
                  (filterValues === 'None' ||
                    filterValues.indexOf('None') >= 0) &&
                  (!d[key] || d[key] === '' || d[key] === [])
                ) {
                  matches = true
                } else if (
                  column.evaluateMatch &&
                  !column.evaluateMatch(filterValues, d)
                ) {
                  matches = false
                } else if (
                  (!column || !column.evaluateMatch) &&
                  filterValues !== d[key] &&
                  filterValues.indexOf(d[key]) === -1
                ) {
                  matches = false
                }
              }
            }
            return matches
          })
        }

        if (outsideFilters && Object.keys(outsideFilters).length) {
          Object.keys(outsideFilters).forEach(filter => {
            if (outsideFilters[filter]['active']) {
              filtered = filtered.filter(dataItem => {
                const currentKey = outsideFilters[filter]['keyToFilter']
                const currentAction = outsideFilters[filter]['filterAction']
                return currentAction(dataItem[currentKey])
              })
            }
          })
        }

        const hasSort = Object.values(activeSort).length > 0
        if (hasSort) {
          const generateSortFunction = (column, ascending) => {
            const headerColumn = _.find(header, h => h.key === column)
            const sortColumn = _.get(headerColumn, 'sortField', column)
            if (ascending) {
              return _.get(
                _.find(header, h => h.key === column),
                'customSortAsc',
                (row1, row2) => {
                  if (
                    (row1[sortColumn] || '').toString().toLowerCase() <
                    (row2[sortColumn] || '').toString().toLowerCase()
                  )
                    return -1
                  else if (
                    (row1[sortColumn] || '').toString().toLowerCase() >
                    (row2[sortColumn] || '').toString().toLowerCase()
                  )
                    return 1
                  return 0
                }
              )
            }
            return _.get(
              _.find(header, h => h.key === column),
              'customSortDesc',
              (row1, row2) => {
                if (
                  (row1[sortColumn] || '').toString().toLowerCase() >
                  (row2[sortColumn] || '').toString().toLowerCase()
                )
                  return -1
                else if (
                  (row1[sortColumn] || '').toString().toLowerCase() <
                  (row2[sortColumn] || '').toString().toLowerCase()
                )
                  return 1
                return 0
              }
            )
          }

          for (let i = activeSort.length - 1; i > -1; i--) {
            filtered = filtered.sort(
              generateSortFunction(
                activeSort[i].replace(/^-/, ''),
                activeSort[i][0] !== '-'
              )
            )
          }
        }

        return filtered
      },
      [activeFilters, activeSort, outsideFilters, filterKey, filterValue]
    )

    const getTableBody = () => {
      let template = []
      const filtered = filterTable(data)

      if (isLoading) {
        template.push(
          <tr key="loading">
            <td className="is-size-4 no-wrap">
              <FontAwesomeIcon icon={faSpinner} spin /> Loading...
            </td>
          </tr>
        )
      } else if (filtered.length === 0) {
        template.push(
          <tr key="no-data">
            <td
              className="is-size-6 p-t-md p-b-md"
              style={{
                borderLeft: '1px solid #e5e9f2',
                whiteSpace: 'nowrap'
              }}>
              <span style={{ marginLeft: 13.5 }}>{emptyDataMessage}</span>
            </td>
          </tr>
        )
      } else {
        const end = currentPage * resultsPerPage - 1
        const start = end - (resultsPerPage - 1)
        let newVisibleOnPage = []
        for (let i = start; i <= end; i += 1) {
          const dataItem = filtered[i]
          if (typeof dataItem === 'undefined') continue
          let isLast = false
          if (i === end || !filtered[i + 1]) isLast = true
          if (hasCheckboxes) newVisibleOnPage.push(dataItem[rowKey])

          template.push(getCols(dataItem, isLast, i))
        }
        if (!_.isEqual(visibleOnPage, newVisibleOnPage)) {
          setVisibleOnPage(newVisibleOnPage)
        }
      }
      return template
    }

    const getTableFooter = () => {
      const filtered = filterTable(data)
      if (isLoading || !filtered.length) return
      return (
        <div className="column is-full p-t-none">
          <div className="has-background-white">
            <div
              className="column is-full"
              style={{
                border: '1px solid #e5e9f2',
                borderTop: 'none'
              }}>
              <Paginator
                count={filtered.length}
                limit={resultsPerPage}
                limitOptions={[15, 30, 50, 70, 100]}
                page={currentPage}
                action={page => {
                  setCurrentPage(page)
                }}
                onLimitChange={(resultsPerPage, currentPage) => {
                  setCurrentPage(currentPage)
                  setResultsPerPage(resultsPerPage)
                }}
              />
            </div>
          </div>
        </div>
      )
    }

    const onFilterRemove = ({ key, value, rawValue } = {}) => {
      setActiveFilters(removeFilterValue(activeFilters, key, value, rawValue))
    }

    const onFilterAdded = filters => {
      setCurrentPage(1)
      setActiveFilters(filters)
    }

    const getOptions = key => {
      const column = _.find(header, col => col.key === key)
      if (column && column.getValues) {
        return column.getValues(data)
      }
      return _.orderBy(
        _.uniq(_.filter(_.map(data, d => d[key]), d => d !== ''))
      )
    }

    useImperativeHandle(
      ref,
      () => ({
        getRows: () => {
          return filterTable(data)
        },
        getCheckRows: () => {
          const intersectionOfUnits = (visibleOnPage || []).filter(element => {
            return (checked || []).includes(element)
          })
          const getUnitData = (data || []).filter(unitData =>
            (intersectionOfUnits || []).includes(unitData?.unit)
          )
          return getUnitData
        }
      }),
      [data, filterTable]
    )

    return (
      <>
        <div className={`columns is-multiline data-table ${additionalClasses}`}>
          <div className="column is-full p-b-none">
            <TableTop
              isLoading={isLoading}
              noTableTop={noTableTop}
              isUpdating={isUpdating}
              title={title}
              header={header}
              allowFilter={allowFilter}
              showCustomFilterOption={showCustomFilterOption}
              allowSort={allowSort}
              buttonsSection={buttonsSection}
              additionalControls={additionalControls}
              additionalOptions={additionalOptions}
              textFilter={textFilter}
              onSetTextFilter={value => {
                onSetTextFilter(value)
                setCurrentPage(1)
              }}
              activeFilters={activeFilters}
              customFilters={customFilters}
              oneFilterAtTime={oneFilterAtTime}
              onSetFilters={onFilterAdded}
              onFilterRemove={onFilterRemove}
              onToggleFilterModal={setFilterModalOpen}
              onToggleSortModal={setSortModalOpen}
              onExport={onExport}
              tabOptions={tabOptions}
            />
          </div>
          <div
            className="column is-full p-t-none p-b-none"
            style={{ zIndex: 2 }}>
            <SimpleBar
              style={{
                maxHeight: maxTableHeight || tableMaxValue || 'inherit',
                overflowY: maxTableHeight ? 'inherit' : 'hidden'
              }}>
              <table
                className={`table ${isLoading ? 'with-border' : ''}`}
                style={{
                  width: '100%'
                }}>
                <thead>{getTableHead(header, pivotConfig, false)}</thead>
                <tbody
                  style={{
                    outline: isLoading ? 'none' : '1px solid #e5e9f2',
                    borderTop: 'none',
                    borderBottom: 'none'
                  }}
                  ref={tableBodyRef}
                  onMouseOut={e => {
                    const {
                      x,
                      y,
                      width,
                      height
                    } = tableBodyRef.current.getBoundingClientRect()
                    if (
                      e.clientX < x ||
                      e.clientX > x + width ||
                      e.clientY < y ||
                      e.clientY > y + height
                    ) {
                      if (!optionsOpen) {
                        setCurrentRow(null)
                      }
                    }
                  }}>
                  {getTableBody()}
                </tbody>
              </table>
            </SimpleBar>
          </div>
          {getTableFooter()}
        </div>
        {filterModalOpen && (
          <FilterModal
            open={filterModalOpen}
            startFilter={activeFilters}
            customFilters={customFilters}
            fieldFilters={_.map(_.filter(header, h => h.filterable), h => ({
              ...h,
              options: getOptions(h.key)
            }))}
            onFilter={filter => {
              setActiveFilters(filter)
              setCurrentPage(1)
            }}
            onClose={() => setFilterModalOpen(false)}
          />
        )}
        <SortModal
          open={sortModalOpen}
          startSort={activeSort}
          allColumns={_.map(_.filter(header, h => !h.notSortable), column => ({
            key: column.key,
            caption: column.sortDisplay || column.display
          }))}
          onSort={values => {
            setActiveSort(values)
            setSortModalOpen(false)
            if (onSortChanged) {
              onSortChanged(values)
            }
          }}
          onClose={() => setSortModalOpen(false)}
        />
      </>
    )
  }
)

DataTable.propTypes = {
  /**
   * Header columns
   */
  header: PropTypes.array,
  /**
   * Data to be shown in the table
   */
  data: PropTypes.array,
  pivotConfig: PropTypes.object,
  trStyle: PropTypes.object,
  /**
   * Flag to detemine if the table is currently loading data
   */
  isLoading: PropTypes.bool,
  /**
   * Event handler for icon clicked action
   */
  iconClicked: PropTypes.func,
  /**
   * Additional element at table top
   */
  tableTop: PropTypes.element,
  /**
   * Flag to determine if the page should be reset to the first page available
   */
  resetPage: PropTypes.bool,
  /**
   * Filter key to be applied to the table data
   */
  filterKey: PropTypes.array,
  /**
   * Filter value to be applied to the table data
   */
  filterValue: PropTypes.string,
  /**
   * Determines if the table should display checkboxes per row
   */
  hasCheckboxes: PropTypes.bool,
  /**
   * Field name for the row key
   */
  key: PropTypes.string,
  /**
   * Event handler for checkbox clicked action
   */
  checkboxClicked: PropTypes.func,
  /**
   * Text to show instead of an empty value
   */
  emptyValue: PropTypes.string,
  /**
   * Color to use when showing an empty value
   */
  emptyColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * Additional methods to be passed to the column rendering function, if any
   */
  methods: PropTypes.object,
  /**
   * Caller props to be passed to the tooltip function generator
   */
  parentProps: PropTypes.object,
  /**
   * Event handler for sort column changed
   */
  onSortChanged: PropTypes.func,
  /**
   * Boolean to hide the title ans search section inside a table / also removes bordered topTable
   */
  noTableTop: PropTypes.bool,
  /**
   * Max height value passed to simple bar component that wraps the entire table to create a custom scroll
   */
  maxTableHeight: PropTypes.number,
  /**
   * Whether the tooltip should have an auto size
   */
  tooltipSizeAuto: PropTypes.bool,
  /**
   * Whether the component should calculate by itself the maxHeihg value for the simplebar component
   */
  calcTableMaxValue: PropTypes.bool,
  /**
   * Whether the custom filter item should be rendered inside the filter's dropdown
   */
  showCustomFilterOption: PropTypes.bool,
  /**
   * Whether or not to allow just one filter at a time
   */
  oneFilterAtTime: PropTypes.bool,
  /**
   * Tab options to show in the header of tableTop
   */
  tabOptions: PropTypes.array,
  /**
   * Tab options to show in the header of tableTop
   */
  outsideFilters: PropTypes.object,
  /**
   * Number of result per page to show
   */
  customResultsPerPage: PropTypes.number,
  /**
   * Column name for single row select
   */
  singleSelectionColumn: PropTypes.string,
  /**
   * if you want to add additional css classes
   */
  additionalClasses: PropTypes.string,

  // callback to set current active row for the parent component

  onActiveRow: PropTypes.func
}

export default DataTable
