import { Link } from 'react-router-dom'
import React from 'react'
import DefaultColumn from './setup-staff-table/setup-staff-table-columns/default-column'
import NameColumn from './setup-staff-table/setup-staff-table-columns/name-column'
import StatusColumn from './setup-staff-table/setup-staff-table-columns/status-column'
import ActionColumn from './setup-staff-table/setup-staff-table-columns/action-column'
import StatusColumnFilter from './setup-staff-table/setup-staff-table-column-filters/status-column-filter'
import MultiSelectSearchDropdownFilter from '../../components/react-table-column-filters/MultiSelectSearchDropdownFilter'
import { question, questionFilled } from 'ui/icons'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export const headerData = ({
  onShowDeleteModal,
  onShowReactivateOrInactivateModal
}) => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Filter: MultiSelectSearchDropdownFilter,
      filter: 'multiEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <NameColumn row={row} />
      }
    },
    {
      Header: 'Email Address',
      accessor: 'email',
      Filter: MultiSelectSearchDropdownFilter,
      filter: 'multiEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Role',
      accessor: 'role_name',
      width: 150,
      Filter: MultiSelectSearchDropdownFilter,
      filter: 'multiEquals',
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: () => (
        <div style={{ display: 'inline' }}>
          <span className="m-r-5">Property Status</span>
          <Tippy
            appendTo={() => document.body}
            content={
              <p>
                This shows if the user is active or has been made inactive by
                the Property Manager.
              </p>
            }
            placement="bottom-start"
            allowHTML
            theme="light">
            <img
              src={question}
              alt="help"
              onMouseOver={e => (e.currentTarget.src = questionFilled)}
              onMouseLeave={e => (e.currentTarget.src = question)}
            />
          </Tippy>
        </div>
      ),
      accessor: 'status',
      filter: 'equals',
      width: 180,
      Filter: StatusColumnFilter,
      textAlign: 'left',
      Cell: row => {
        return <StatusColumn row={row} />
      }
    },
    {
      Header: () => (
        <div style={{ display: 'inline' }}>
          <span className="m-r-5">Enable Login</span>
          <Tippy
            appendTo={() => document.body}
            content={
              <p>This refers to the user's ability to access this property.</p>
            }
            placement="bottom-start"
            allowHTML
            theme="light">
            <img
              src={question}
              alt="help"
              onMouseOver={e => (e.currentTarget.src = questionFilled)}
              onMouseLeave={e => (e.currentTarget.src = question)}
            />
          </Tippy>
        </div>
      ),
      accessor: 'login_access',
      filter: 'equals',
      Filter: StatusColumnFilter,
      textAlign: 'left',
      Cell: row => {
        return <StatusColumn row={row} />
      }
    },
    {
      Header: () => (
        <div style={{ display: 'inline' }}>
          <span className="m-r-5">Account Status</span>
          <Tippy
            appendTo={() => document.body}
            content={
              <p>
                This shows if the user is active/blocked on Turnable. This can
                be changed from User Management by Admins.
              </p>
            }
            placement="bottom-start"
            allowHTML
            theme="light">
            <img
              src={question}
              alt="help"
              onMouseOver={e => (e.currentTarget.src = questionFilled)}
              onMouseLeave={e => (e.currentTarget.src = question)}
            />
          </Tippy>
        </div>
      ),
      accessor: 'account_status',
      filter: 'equals',
      width: 180,
      Filter: StatusColumnFilter,
      textAlign: 'left',
      Cell: row => {
        return <StatusColumn row={row} />
      }
    },

    {
      Header: '',
      accessor: 'template_id',
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        return (
          <ActionColumn
            row={row}
            onShowDeleteModal={onShowDeleteModal}
            onShowReactivateOrInactivateModal={
              onShowReactivateOrInactivateModal
            }
          />
        )
      }
    }
  ]
}
