import React from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'

const Draggable = ({ dragType, dragData, previewClass, dragClass, children, canDrag, siblingSection }) => {

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    item: { type: dragType, ...dragData },
    canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [dragData])

  return (
    <>
      <div className={previewClass ? previewClass.join(' ') : ''} ref={dragPreview} style={{ border: isDragging ? '1px dotted #3DB3E2' : '1px solid #122048'}}>
        <div ref={drag} className={dragClass ? dragClass.join(' ') : ''}>
          {children}
        </div>
      </div>
      {siblingSection}
    </>
  )
}

Draggable.propTypes = {
  canDrag: PropTypes.bool,
  dragClass: PropTypes.array,
  dragData: PropTypes.object,
  previewClass: PropTypes.array,
  dragType: PropTypes.string,
}

export default Draggable
