import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import CONTEXT_UPDATE from './context'
import { UNIT_NOTES_CREATE_SUCCESS, UNIT_NOTES_DELETE_SUCCESS } from './notes'
import { formatDateString } from '../utils'

import unitHistoryMock from '../mocks/unit_history.mock'

/**
 * Services data module
 * @redux
 * @reduxActionScope services
 * @module services
 */

/**
 * Request services list
 * @type {Redux.ActionType}
 */
export const SERVICES_LIST_REQUESTED = 'services/SERVICES_LIST_REQUESTED'
/**
 * Services list failed
 * @type {Redux.ActionType}
 */
export const SERVICES_LIST_FAIL = 'services/SERVICES_LIST_FAIL'
/**
 * Update requested services list with successful data from API
 * @type {Redux.ActionType}
 */
export const SERVICES_LIST_SUCCESS = 'services/SERVICES_LIST_SUCCESS'

/**
 * Request services stats
 * @type {Redux.ActionType}
 */
export const SERVICES_STATS_REQUESTED = 'services/SERVICES_STATS_REQUESTED'
/**
 * Services stats failed
 * @type {Redux.ActionType}
 */
export const SERVICES_STATS_FAIL = 'services/SERVICES_STATS_FAIL'
/**
 * Update requested services stats with successful data from API
 * @type {Redux.ActionType}
 */
export const SERVICES_STATS_SUCCESS = 'services/SERVICES_STATS_SUCCESS'

/**
 * Request services teams
 * @type {Redux.ActionType}
 */
export const SERVICES_TEAMS_REQUESTED = 'services/SERVICES_TEAMS_REQUESTED'
/**
 * Services teams request failed
 * @type {Redux.ActionType}
 */
export const SERVICES_TEAMS_FAIL = 'services/SERVICES_TEAMS_FAIL'
/**
 * Update requested services teams with successful data from API
 * @type {Redux.ActionType}
 */
export const SERVICES_TEAMS_SUCCESS = 'services/SERVICES_TEAMS_SUCCESS'

/**
 * Toggle current selected service phase
 * @type {Redux.ActionType}
 */
export const SERVICES_SET_PHASE = 'services/SERVICES_SET_PHASE'
/**
 * Toggle current selected service
 * @type {Redux.ActionType}
 */
export const SERVICES_SET_SERVICE = 'services/SERVICES_SET_SERVICE'

/**
 * Update service data for an operation
 * @type {Redux.ActionType}
 */
export const SERVICES_UPDATE_REQUESTED = 'services/SERVICES_UPDATE_REQUESTED'
/**
 * Services update failed
 * @type {Redux.ActionType}
 */
export const SERVICES_UPDATE_FAIL = 'services/SERVICES_UPDATE_FAIL'
/**
 * Refresh services after a successful service update with the API
 * @type {Redux.ActionType}
 */
export const SERVICES_UPDATE_SUCCESS = 'services/SERVICES_UPDATE_SUCCESS'

/**
 * Get service unit history
 * @type {Redux.ActionType}
 */
export const SERVICES_HISTORY_REQUESTED = 'services/SERVICES_HISTORY_REQUESTED'
/**
 * Get service unit history failed
 * @type {Redux.ActionType}
 */
export const SERVICES_HISTORY_FAIL = 'services/SERVICES_HISTORY_FAIL'
/**
 * Refresh service unit history after a successful request
 * @type {Redux.ActionType}
 */
export const SERVICES_HISTORY_SUCCESS = 'services/SERVICES_HISTORY_SUCCESS'
/**
 * Resets service unit history
 * @type {Redux.ActionType}
 */
export const SERVICES_HISTORY_RESET = 'services/SERVICES_HISTORY_RESET'

export const SERVICE_COLUMNS_LIST = 'services/SERVICE_COLUMNS_LIST'

const PREFERRED_OPTION = 'PUP'

const initialStats = {
  not_assigned: 0,
  assigned: 0,
  in_progress: 0,
  ready_for_qc: 0,
  pending_approval: 0,
  approved: 0,
  days_to_move_in: 0,
  total_beds: 0,
  total_renewals: 0,
  total_to_turn: 0,
  percent_complete: 0,
  days_remaining: 0
}

const initialState = {
  listHasRequested: false,
  serviceColumns: [],
  listHasData: false,
  listIsRequesting: false,
  listServiceRequested: null,
  listPhaseRequested: null,
  listIsError: false,
  list: [],
  forceListRequest: false,
  statsHasRequested: false,
  statsHasData: false,
  statsIsRequesting: false,
  statsServiceRequested: null,
  statsIsError: false,
  stats: initialStats,
  updateIsRequesting: false,
  updateIsError: false,
  teamsHasRequested: false,
  teamsHasData: false,
  teamsIsRequesting: false,
  teamsIsError: false,
  teams: [],
  currentPhase: 'Assign',
  currentService: 'Paint',
  historyIsRequesting: false,
  historyIsError: false,
  history: []
}

/**
 * Services reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICES_LIST_REQUESTED:
      return {
        ...state,
        listHasRequested: true,
        listIsRequesting: true,
        listIsError: false,
        listServiceRequested: action.service,
        listPhaseRequested: action.phase
      }
    case SERVICES_LIST_SUCCESS: {
      if (
        action.service === state.listServiceRequested &&
        action.phase === state.listPhaseRequested
      ) {
        return {
          ...state,
          listHasData: true,
          listIsRequesting: false,
          listIsError: false,
          list: action.list,
          forceListRequest: false
        }
      }
      return state
    }
    case SERVICE_COLUMNS_LIST: {
      const { data } = action || {}
      return {
        ...state,
        serviceColumns: data || []
      }
    }
    case SERVICES_LIST_FAIL: {
      if (
        action.service === state.listServiceRequested &&
        action.phase === state.listPhaseRequested
      ) {
        return {
          ...state,
          listHasData: false,
          listIsRequesting: false,
          listIsError: true,
          forceListRequest: false
        }
      }
      return state
    }
    case SERVICES_STATS_REQUESTED:
      return {
        ...state,
        statsHasRequested: true,
        statsIsRequesting: true,
        statsIsError: false,
        statsServiceRequested: action.service
      }
    case SERVICES_STATS_SUCCESS: {
      if (action.service === state.statsServiceRequested) {
        return {
          ...state,
          statsHasData: true,
          statsIsRequesting: false,
          statsIsError: false,
          stats: action.stats
        }
      }
      return state
    }
    case SERVICES_STATS_FAIL: {
      if (action.service === state.statsServiceRequested) {
        return {
          ...state,
          statsHasData: false,
          statsIsRequesting: false,
          statsIsError: true
        }
      }
      return state
    }
    case SERVICES_TEAMS_REQUESTED:
      return {
        ...state,
        teamsHasRequested: true,
        teamsIsRequesting: true,
        teamsIsError: false
      }
    case SERVICES_TEAMS_SUCCESS:
      return {
        ...state,
        teamsHasData: true,
        teamsIsRequesting: false,
        teamsIsError: false,
        teams: action.teams
      }
    case SERVICES_TEAMS_FAIL:
      return {
        ...state,
        teamsHasData: false,
        teamsIsRequesting: false,
        teamsIsError: true
      }
    case SERVICES_SET_PHASE:
      return {
        ...state,
        currentPhase: action.phase
      }
    case SERVICES_SET_SERVICE:
      return {
        ...state,
        currentService: action.service
      }
    case SERVICES_UPDATE_REQUESTED:
      return {
        ...state,
        updateIsRequesting: true,
        updateIsError: false
      }
    case SERVICES_UPDATE_SUCCESS:
      return {
        ...state,
        updateIsRequesting: false,
        updateIsError: false,
        list: action.list || state.list,
        forceListRequest: action.forceListRequest || false
      }
    case SERVICES_UPDATE_FAIL:
      return {
        ...state,
        updateIsRequesting: false,
        updateIsError: true
      }
    case SERVICES_HISTORY_REQUESTED:
      return {
        ...state,
        historyIsRequesting: true,
        historyIsError: false
      }
    case SERVICES_HISTORY_SUCCESS:
      return {
        ...state,
        historyIsRequesting: false,
        historyIsError: false,
        history: action.history
      }
    case SERVICES_HISTORY_RESET:
      return {
        ...state,
        historyIsRequesting: false,
        historyIsError: false,
        history: []
      }
    case SERVICES_HISTORY_FAIL:
      return {
        ...state,
        historyIsRequesting: false,
        historyIsError: true
      }
    case UNIT_NOTES_CREATE_SUCCESS: {
      const index = _.findIndex(state.list, u => u.unit_id === action.unitId)
      return {
        ...state,
        list: state.list
          .slice(0, index)
          .concat([{ ...state.list[index], has_note: action.data.length > 0 }])
          .concat(state.list.slice(index + 1))
      }
    }
    case UNIT_NOTES_DELETE_SUCCESS: {
      const index = _.findIndex(state.list, u => u.unit_id === action.unitId)
      return {
        ...state,
        list: state.list
          .slice(0, index)
          .concat([{ ...state.list[index], has_note: action.data.length > 0 }])
          .concat(state.list.slice(index + 1))
      }
    }
    case CONTEXT_UPDATE:
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Sets current selected phase
 * @redux
 * @reduxActionCreator SERVICES_SET_PHASE
 * @param {string} phase - the new currently selected phase
 */
export const setCurrentPhase = phase => {
  return dispatch => {
    dispatch({
      type: SERVICES_SET_PHASE,
      phase
    })
  }
}

/**
 * Sets current selected service
 * @redux
 * @reduxActionCreator SERVICES_SET_SERVICE
 * @param {string} service - the new currently selected service
 */
export const setCurrentService = service => {
  return dispatch => {
    dispatch({
      type: SERVICES_SET_SERVICE,
      service
    })
  }
}

/**
 * Gets the complete list for the current context, service and phase
 * @redux
 * @reduxActionCreator SERVICES_LIST_REQUESTED, SERVICES_LIST_SUCCESS, SERVICES_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the requested service
 * @param {string} phase - the requested phase
 */
export const getServicesList = (pdbid, context, service, phase, columns) => {
  return dispatch => {
    dispatch({
      type: SERVICES_LIST_REQUESTED,
      service,
      phase
    })
    Service.getServicesList(pdbid, context, service, phase)
      .then(async res => {
        if (res.ok) {
          try {
            const list = await res.json()
            dispatch({
              type: SERVICES_LIST_SUCCESS,
              list: _listAdapter(list, columns),
              service,
              phase
            })
          } catch {
            dispatch({
              type: SERVICES_LIST_SUCCESS,
              list: [],
              service,
              phase
            })
          }
        } else {
          dispatch({
            type: SERVICES_LIST_FAIL,
            service,
            phase
          })
        }
      })
      .catch(e => {
        dispatch({
          type: SERVICES_LIST_FAIL,
          service,
          phase
        })
      })
  }
}

/**
 * Gets the stats for the current service
 * @redux
 * @reduxActionCreator SERVICES_STATS_REQUESTED, SERVICES_STATS_SUCCESS, SERVICES_STATS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the requested service
 */
export const getServicesStats = (pdbid, context, service) => {
  return dispatch => {
    dispatch({
      type: SERVICES_STATS_REQUESTED,
      service
    })
    Service.getServicesStats(pdbid, context, service)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SERVICES_STATS_SUCCESS,
            stats: _statsAdapter(data),
            service
          })
        } else {
          dispatch({
            type: SERVICES_STATS_FAIL,
            service
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SERVICES_STATS_FAIL,
          service
        })
      })
  }
}

let teamsTimeout = null

/**
 * Gets the available teams for the current service and phase
 * @redux
 * @reduxActionCreator SERVICES_TEAMS_REQUESTED, SERVICES_TEAMS_SUCCESS, SERVICES_TEAMS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the requested service
 * @param {string} phase - the requested phase
 */
export const getServicesTeams = (pdbid, context, service, phase) => {
  return dispatch => {
    dispatch({
      type: SERVICES_TEAMS_REQUESTED
    })
    clearTimeout(teamsTimeout)
    teamsTimeout = setTimeout(() => {
      Service.getServicesTeams(pdbid, context, service, phase)
        .then(async res => {
          if (res.ok) {
            const data = await res.json()
            dispatch({
              type: SERVICES_TEAMS_SUCCESS,
              teams: _teamsAdapter(data, phase)
            })
          } else {
            dispatch({
              type: SERVICES_TEAMS_FAIL
            })
          }
        })
        .catch(() => {
          dispatch({
            type: SERVICES_TEAMS_FAIL
          })
        })
    }, 1000)
  }
}

/**
 * Completes a service specification
 * @redux
 * @reduxActionCreator SERVICE_UPDATE_REQUESTED, SERVICE_UPDATE_SUCCESS, SERVICE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the service to be completed
 * @param {Object} body - the JSON data for the service completion (depending on the action)
 */
export const markCompleteService = (pdbid, context, service, body = {}) => {
  return dispatch => {
    dispatch({
      type: SERVICES_UPDATE_REQUESTED
    })
    Service.markCompleteService(pdbid, context, service, body)
      .then(async res => {
        if (res.ok) {
          try {
            dispatch({
              type: SERVICES_UPDATE_SUCCESS,
              forceListRequest: true
            })
          } catch {
            dispatch({
              type: SERVICES_UPDATE_SUCCESS,
              list: []
            })
          }
        } else {
          dispatch({
            type: SERVICES_UPDATE_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SERVICES_UPDATE_FAIL
        })
      })
  }
}

/**
 * Completes a service specification
 * @redux
 * @reduxActionCreator SERVICE_UPDATE_REQUESTED, SERVICE_UPDATE_SUCCESS, SERVICE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the service to be completed
 * @param {Object} body - the JSON data for the service completion (depending on the action)
 */
export const unblockService = (
  pdbid,
  context,
  service,
  body = {},
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: SERVICES_UPDATE_REQUESTED
    })
    Service.unblockService(pdbid, context, service, body)
      .then(async res => {
        if (res.ok) {
          try {
            if (onSuccess) {
              onSuccess(true)
            } else {
              dispatch({
                type: SERVICES_UPDATE_SUCCESS,
                forceListRequest: true
              })
            }
          } catch {
            if (onError) {
              onError(true)
              dispatch({
                type: SERVICES_UPDATE_FAIL
              })
            } else {
              dispatch({
                type: SERVICES_UPDATE_SUCCESS,
                list: []
              })
            }
          }
        } else {
          if (onError) {
            onError(true)
          }
          dispatch({
            type: SERVICES_UPDATE_FAIL
          })
        }
      })
      .catch(() => {
        if (onError) {
          onError(true)
        }
        dispatch({
          type: SERVICES_UPDATE_FAIL
        })
      })
  }
}

/**
 * Updates a service specification
 * @redux
 * @reduxActionCreator SERVICE_HISTORY_REQUESTED, SERVICE_HISTORY_SUCCESS, SERVICE_HISTORY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the service to be updated
 * @param {string} action - the specific action requested on a service
 * @param {Object} body - the JSON data for the service update (depending on the action)
 */
export const updateServices = (
  pdbid,
  context,
  service,
  action,
  body = {},
  onSuccess,
  onError,
  trim = true
) => {
  return dispatch => {
    dispatch({
      type: SERVICES_UPDATE_REQUESTED
    })
    Service.updateServices(pdbid, context, service, action, body, trim)
      .then(async res => {
        let emptyRes = false

        if (res.ok) {
          for (let pair of res.headers.entries()) {
            if (pair[0] === 'content-length' && pair[1] < 1) {
              emptyRes = true
            }
          }

          try {
            const list = emptyRes ? await res.text() : await res.json()

            dispatch({
              type: SERVICES_UPDATE_SUCCESS,
              list: _listAdapter(list)
            })
            if (onSuccess) {
              onSuccess()
            }
          } catch {
            dispatch({
              type: SERVICES_UPDATE_FAIL,
              list: []
            })
            if (onError) {
              onError()
            }
          }
        } else {
          if (onError) {
            onError()
          }
          dispatch({
            type: SERVICES_UPDATE_FAIL
          })
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
        dispatch({
          type: SERVICES_UPDATE_FAIL
        })
      })
  }
}

/**
 * Gets the history of a unit
 * @redux
 * @reduxActionCreator SERVICE_HISTORY_REQUESTED, SERVICE_HISTORY_SUCCESS, SERVICE_HISTORY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} service - the service to be updated
 * @param {string} action - the specific action requested on a service
 * @param {Object} body - the JSON data for the service update (depending on the action)
 */
export const getUnitHistory = (pdbid, context, service) => {
  return dispatch => {
    dispatch({
      type: SERVICES_HISTORY_REQUESTED
    })
    Service.getUnitHistory(pdbid, context, service)
      .then(async res => {
        if (res.ok) {
          try {
            // TODO remove the mock and use the actual data
            // const list = await res.json()
            setTimeout(() => {
              dispatch({
                type: SERVICES_HISTORY_SUCCESS,
                history: _historyAdapter(unitHistoryMock)
              })
            }, 1500)
          } catch {
            dispatch({
              type: SERVICES_HISTORY_FAIL,
              history: []
            })
          }
        } else {
          dispatch({
            type: SERVICES_HISTORY_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SERVICES_HISTORY_FAIL
        })
      })
  }
}

export const resetUnitHistory = () => {
  return dispatch => {
    dispatch({
      type: SERVICES_HISTORY_RESET
    })
  }
}

export const getServiceColumns = ({ pdbid, context, onSuccess }) => {
  return dispatch => {
    Service.getServiceColumns(pdbid, context)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const list = await res.json()
          const columnList = (list || []).filter(
            item =>
              item.unitspace !== 'Common' ||
              item.unitspace?.toLowerCase() !== 'common'
          )
          dispatch({
            type: SERVICE_COLUMNS_LIST,
            data: _.orderBy(columnList, [item => item.unitspace], ['ASC']) || []
          })
          if (onSuccess) {
            onSuccess(list)
          }
        }
      })
      .catch(e => {})
  }
}

const BLANK = ''
const DEFAULT_UNIT_STATUS = {
  service: BLANK,
  display: BLANK,
  tooltip: BLANK,
  unitspace_name: BLANK
}

const _getRelevantUnitSpace = (unit, unitSpace) => {
  const filteredSpaces = _.filter(
    unit.UnitSpaces,
    us => us.unitspace === unitSpace
  )
  if (!filteredSpaces.length) return undefined
  else if (filteredSpaces.length === 1) return filteredSpaces[0]
  else {
    const preferredSpace = _.find(
      filteredSpaces,
      s => s.item_option === PREFERRED_OPTION
    )
    return preferredSpace || filteredSpaces[0]
  }
}

const _getUnitStatus = us => {
  return {
    has_charge: us.has_charge === 'T',
    service: typeof us.item === 'string' ? us.item : BLANK,
    display: (typeof us.item_option === 'string' ? us.item_option : BLANK)
      .replace('Bed/Bath', '')
      .replace('Common', ''),
    tooltip:
      typeof us.item_option_description === 'string'
        ? us.item_option_description
        : BLANK,
    unitspace_name:
      typeof us.unitspace_name === 'string' ? us.unitspace_name : BLANK,
    is_transfer: typeof us.is_transfer === 'string',
    is_goback: typeof us.is_goback === 'string',
    is_asis: typeof us.is_asis === 'string',
    is_priority: typeof us.is_priority === 'string',
    is_carpet: typeof us.is_carpet === 'string',
    task_uri: typeof us.task_uri === 'string' ? us.task_uri : BLANK
  }
}

const _listAdapter = (data, columns) => {
  if (!data.length) return []

  if (!columns) {
    let temp = {}
    _.forEach(data, dataItem => {
      // eslint-disable-next-line no-unused-expressions
      dataItem.UnitSpaces?.forEach(u => {
        temp[u.unitspace] = true
      })
    })
    columns = Object.keys(temp).map(space => ({ unitspace: space }))
  }

  const list = data.map(dataItem => {
    const { UnitSpaces: unitSpaces } = dataItem || {}
    const unitSpaceItems = []
    _.forEach(unitSpaces, item => {
      if (item)
        unitSpaceItems[item.unitspace] = _getRelevantUnitSpace(
          dataItem,
          item.unitspace
        )
    })

    let formattedUnitSpaces = []
    columns.forEach(item => {
      const { unitspace } = item || {}
      formattedUnitSpaces[unitspace] =
        typeof unitSpaceItems[unitspace] === 'undefined'
          ? DEFAULT_UNIT_STATUS
          : _getUnitStatus(unitSpaceItems[unitspace])
    })

    const has_charge = (Object.values(formattedUnitSpaces) || []).some(
      unitSpace => unitSpace?.has_charge
    )

    return {
      has_charge,
      is_blocked: dataItem.blocked
        ? dataItem.blocked === 'false'
          ? false
          : true
        : false,
      inspection_id: dataItem.inspection_id ? dataItem.inspection_id : BLANK,
      model: dataItem.model ? dataItem.model : BLANK,
      unit: typeof dataItem.unit === 'string' ? dataItem.unit : BLANK,
      unit_id: typeof dataItem.unit_id === 'string' ? dataItem.unit_id : BLANK,
      unit_uri:
        typeof dataItem.unit_uri === 'string' ? dataItem.unit_uri : BLANK,
      qc_team_uri:
        typeof dataItem.qc_team_uri === 'string' ? dataItem.qc_team_uri : BLANK,
      qc_team_member:
        typeof dataItem.qc_team_member === 'string'
          ? dataItem.qc_team_member
          : BLANK,
      type: typeof dataItem.type === 'string' ? dataItem.type : BLANK,
      building:
        typeof dataItem.building === 'string' ? dataItem.building : BLANK,
      floorplan:
        typeof dataItem.floorplan === 'string' ? dataItem.floorplan : BLANK,
      workorder:
        typeof dataItem.workorder === 'string' ? dataItem.workorder : BLANK,
      grouping:
        typeof dataItem.grouping === 'string' ? dataItem.grouping : BLANK,
      scheduled_date:
        dataItem.scheduled_date || dataItem.schedule_date || BLANK,
      complete_at: dataItem?.complete_at || BLANK,
      ...formattedUnitSpaces,
      team_member:
        typeof dataItem.team_member !== 'undefined' &&
        dataItem.team_member !== null
          ? dataItem.team_member
          : 'Unassigned',
      team_uri:
        typeof dataItem.team_uri !== 'undefined' && dataItem.team_uri !== null
          ? dataItem.team_uri
          : 'Unassigned',
      operation_uri:
        typeof dataItem.operation_uri === 'string'
          ? dataItem.operation_uri
          : BLANK,
      has_note: typeof dataItem.has_note === 'string',
      vendor_notes:
        typeof dataItem.vendor_notes === 'string'
          ? dataItem.vendor_notes
          : BLANK,
      service_notes:
        typeof dataItem.service_notes === 'string'
          ? dataItem.service_notes
          : BLANK,
      servicetype_id: dataItem.servicetype_id || null,
      is_transfer:
        dataItem.UnitSpaces.filter(us => typeof us.is_transfer === 'string')
          .length > 0,
      is_goback:
        dataItem.UnitSpaces.filter(us => typeof us.is_goback === 'string')
          .length > 0,
      is_asis:
        dataItem.UnitSpaces.filter(us => typeof us.is_asis === 'string')
          .length > 0,
      is_priority:
        dataItem.UnitSpaces.filter(us => typeof us.is_priority === 'string')
          .length > 0,
      is_carpet:
        dataItem.UnitSpaces.filter(us => typeof us.is_carpet === 'string')
          .length > 0
    }
  })

  return list
}

const DEFAULT_STAT_VALUE = 0
const _statsAdapter = data => {
  const { items } = data
  if (!Array.isArray(items)) return initialStats
  const i = items[0]
  let stats = {
    not_assigned:
      typeof i.not_assigned === 'number' ? i.not_assigned : DEFAULT_STAT_VALUE,
    assigned: typeof i.assigned === 'number' ? i.assigned : DEFAULT_STAT_VALUE,
    in_progress:
      typeof i.in_progress === 'number' ? i.in_progress : DEFAULT_STAT_VALUE,
    ready_for_qc:
      typeof i.ready_for_qc === 'number' ? i.ready_for_qc : DEFAULT_STAT_VALUE,
    pending_approval:
      typeof i.pending_approval === 'number'
        ? i.pending_approval
        : DEFAULT_STAT_VALUE,
    approved: typeof i.approved === 'number' ? i.approved : DEFAULT_STAT_VALUE,
    total_not_assigned_beds:
      typeof i.not_assigned_beds === 'number'
        ? i.not_assigned_beds
        : DEFAULT_STAT_VALUE,
    total_assigned_beds:
      typeof i.assigned_beds === 'number'
        ? i.assigned_beds
        : DEFAULT_STAT_VALUE,
    total_in_progress_beds:
      typeof i.in_progress_beds === 'number'
        ? i.in_progress_beds
        : DEFAULT_STAT_VALUE,
    total_ready_for_qc_beds:
      typeof i.ready_for_qc_beds === 'number'
        ? i.ready_for_qc_beds
        : DEFAULT_STAT_VALUE,
    total_pending_approval_beds:
      typeof i.pending_approval_beds === 'number'
        ? i.pending_approval_beds
        : DEFAULT_STAT_VALUE,
    total_approved_beds:
      typeof i.approved_beds === 'number'
        ? i.approved_beds
        : DEFAULT_STAT_VALUE,
    completed_beds:
      typeof i.completed_beds === 'number'
        ? i.completed_beds
        : DEFAULT_STAT_VALUE,
    completed_units:
      typeof i.completed_units === 'number'
        ? i.completed_units
        : DEFAULT_STAT_VALUE,
    not_needed_beds:
      typeof i.not_needed_beds === 'number'
        ? i.not_needed_beds
        : DEFAULT_STAT_VALUE,
    not_needed_units:
      typeof i.not_needed_units === 'number'
        ? i.not_needed_units
        : DEFAULT_STAT_VALUE,
    remaining_beds:
      typeof i.remaining_beds === 'number'
        ? i.remaining_beds
        : DEFAULT_STAT_VALUE,
    remaining_units:
      typeof i.remaining_units === 'number'
        ? i.remaining_units
        : DEFAULT_STAT_VALUE,
    as_is_beds:
      typeof i.as_is_beds === 'number' ? i.as_is_beds : DEFAULT_STAT_VALUE,
    renewal_beds:
      typeof i.renewal_beds === 'number' ? i.renewal_beds : DEFAULT_STAT_VALUE,
    no_service_beds:
      typeof i.no_service_beds === 'number'
        ? i.no_service_beds
        : DEFAULT_STAT_VALUE,
    as_is_units:
      typeof i.as_is_units === 'number' ? i.as_is_units : DEFAULT_STAT_VALUE,
    renewal_units:
      typeof i.renewal_units === 'number'
        ? i.renewal_units
        : DEFAULT_STAT_VALUE,
    no_service_units:
      typeof i.no_service_units === 'number'
        ? i.no_service_units
        : DEFAULT_STAT_VALUE,
    total_beds:
      typeof i.total_beds === 'number' ? i.total_beds : DEFAULT_STAT_VALUE,
    total_units:
      typeof i.total_units === 'number' ? i.total_units : DEFAULT_STAT_VALUE,
    total_renewals:
      typeof i.total_renewals === 'string'
        ? parseInt(i.total_renewals)
        : DEFAULT_STAT_VALUE,
    total_to_turn:
      typeof i.total_to_turn === 'string'
        ? parseInt(i.total_to_turn)
        : DEFAULT_STAT_VALUE,
    percent_complete:
      typeof i.percent_complete === 'number'
        ? i.percent_complete
        : DEFAULT_STAT_VALUE,
    days_remaining:
      typeof i.days_remaining === 'string'
        ? parseInt(i.days_remaining)
        : DEFAULT_STAT_VALUE
  }
  return stats
}

const _teamsAdapter = (data, phase) => {
  let teams = []

  if (Array.isArray(data.items)) {
    teams = data.items.map(t => ({
      member: typeof t.member === 'string' ? t.member : null,
      uri: typeof t.uri === 'string' ? t.uri : null,
      type: t.team_type
    }))
  }

  if (phase === 'AssignQC' || phase === 'Approve') {
    return teams.filter(team => team.type === 'Internal QC')
  }

  return teams.filter(team => team.type !== 'Internal QC')
}

const _historyAdapter = data => {
  return data.map(item => ({
    ...item,
    date: formatDateString(item.date, 'MM/DD/YYYY hh:mm A')
  }))
}
