import React from 'react'
import DefaultColumn from '../../DataTable/pricing-form-table/pricing-table-columns/default-column'
import MultiSearchDropdownFilter from '../../DataTable/pricing-form-table/MultiSearchDropdownFilter'
import PriceField from '../EditFields/PriceField'
import ServiceOptionField from '../EditFields/ServiceOptionField'
import UnitField from '../EditFields/UnitField'
import SpaceField from '../EditFields/SpaceField'
import CustomMultiSearchDropdownFilter from '../../DataTable/pricing-form-table/CustomMultiSearchDropdownFilter'
import { close } from 'ui/icons'

export const headerData = ({
  isEdit,
  onHandleAmountChange,
  onDeleteRow,
  onHandleDeleteRowModal,
  tabId
}) => {
  return [
    {
      Header: 'Service Option',
      accessor: 'name',
      Filter: MultiSearchDropdownFilter,
      filter: 'multiEquals',
      width: 300,
      textAlign: 'left',
      Cell: row => {
        if (isEdit) {
          return (
            <ServiceOptionField
              row={row}
              onHandleAmountChange={onHandleAmountChange}
            />
          )
        }
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Unit',
      accessor: 'unit',
      Filter: MultiSearchDropdownFilter,
      filter: 'multiEquals',
      width: 150,
      textAlign: 'left',
      Cell: row => {
        if (isEdit) {
          return (
            <UnitField row={row} onHandleAmountChange={onHandleAmountChange} />
          )
        }
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Space',
      accessor: 'space',
      Filter: CustomMultiSearchDropdownFilter,
      filter: 'multiEquals',
      width: 150,
      textAlign: 'left',
      Cell: row => {
        if (isEdit) {
          return (
            <SpaceField row={row} onHandleAmountChange={onHandleAmountChange} />
          )
        }
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Price',
      accessor: 'price',
      width: 150,
      textAlign: 'left',
      disableFilters: true,
      disableSortBy: true,
      Cell: row => {
        if (isEdit) {
          return (
            <PriceField row={row} onHandleAmountChange={onHandleAmountChange} />
          )
        }
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: '',
      accessor: 'charge_id1',
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        const { cell } = row || {}
        const { row: currentRow } = cell || {}
        const { index, original } = currentRow || {}
        if (!isEdit) return null
        return (
          <img
            className="close-button"
            alt="Close Modal"
            src={close}
            style={{ marginTop: '2px', cursor: 'pointer' }}
            title="Delete Row"
            onClick={() => {
              onHandleDeleteRowModal([currentRow], tabId)
              //onDeleteRow(5, index)
            }}
          />
        )
      }
    }
  ]
}
