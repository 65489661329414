import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import QcHeader from '../qc-header'
import BigLoading from '../../components/big-loading'
import { ServiceColors } from '../../data'
import { getApproved } from '../../modules/qc'
import { toggleSidebar } from '../../modules/sidebar'
import MobileEmptyState from '../../components/mobile-empty-state'
import { noPendingWork } from '../../ui/icons'
import './index.scss'

function QcApproved({ qc, user, context, getApproved, toggleSidebar }) {
  const { approved, isRequesting } = qc
  const { project, property } = context
  const { projects, properties, pdbid, staffid } = user.user_metadata
  const projectsById = projects.reduce((acc, project) => ({...acc, [project.id]: project}), {})
  const propertiesById = properties.reduce((acc, property) => ({...acc, [property.id]: property}), {})
  const projectName = projectsById[project] ? projectsById[project].name : 'Undefined'
  const propertyName = propertiesById[property] ? propertiesById[property].name : 'Undefined'

  useEffect(() => {
    if (project && property && pdbid && staffid && !isRequesting) {
      getApproved(pdbid, { project, property }, staffid)
    }
  }, [context, user])

  const jobsByServiceType = approved.reduce((acc, job) => {
    if (job) {
      const service = job.service || 'Unknown'
      return {
        ...acc,
        [service]: acc[service] ? [...acc[service], job] : [job]
      }
    }
    return acc
  }, {})

  const getApprovedCount = (job) => {
    return (job.UnitSpaces && job.UnitSpaces.length) || 0
  }

  const getGobackCount = (job) => {
    return (job.UnitSpaces_goback && job.UnitSpaces_goback.length) || 0
  }

  return (
    <div className="qc-approved is-fullheight">
      <QcHeader title="Approved" toggleSidebar={toggleSidebar} />

      <div className="qc-approved-content">
        <nav className="breadcrumb is-medium is-centered">
          <ul>
            <li className="subtitle is-6 has-text-weight-normal m-b-none">{projectName}</li>
            <li className="subtitle is-6 has-text-weight-normal m-b-none">{propertyName}</li>
          </ul>
        </nav>
        {isRequesting ? (
          <BigLoading />
        ) : approved.length ? (
          <div className="p-l-md p-r-md">
            <div className="subtitle is-5 has-text-weight-medium m-b-sm">
              These units are completed.
            </div>
            <div className="subtitle is-6 has-text-weight-normal">
              Thanks for keeping our inspection game strong!
            </div>
            {Object.keys(jobsByServiceType).map(serviceType => (
              <table className="table is-fullwidth" key={serviceType}>
                <thead>
                  <tr>
                    <th className="p-b-md">
                      <span
                        className="tag is-medium is-uppercase is-dark has-text-weight-medium"
                        style={{backgroundColor: ServiceColors[serviceType] || 'grey'}}
                      >{serviceType}</span>
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {jobsByServiceType[serviceType].length ? jobsByServiceType[serviceType].map(job => (
                    <tr key={job.workorder}>
                      <td>{`Unit ${job.unit}`}</td>
                      <td align="right">
                        {getApprovedCount(job) > 0 ? `Approved ${getApprovedCount(job)}` : ''}
                        {getGobackCount(job) > 0 ? ' | ' : ''}
                        {getGobackCount(job) > 0 ? (
                          <span className="highlight-text">
                            Go-Back {getGobackCount(job)}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td>No {serviceType}(s) have been approved yet.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ))}
          </div>
        ) : (
          <MobileEmptyState 
            image={noPendingWork}
            title="No approvals…yet!"
            subtitle="All approved work orders will be listed here." />
        )}
      </div>
    </div>
  )
}

QcApproved.propTypes = {
  qc: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  toggleSidebar: PropTypes.func,
  getApproved: PropTypes.func,
}

const mapStateToProps = ({ user, context, qc }) => ({
  qc,
  user,
  context,
})

const mapDispatchToProps = {
  toggleSidebar,
  getApproved
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcApproved)

