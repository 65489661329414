import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { keysIcons } from '../keys-main/keys-icons'
import './index.scss'

const KeysModal = ({
  context,
  unit,
  room,
  unitSpace,
  modal,
  updateKey,
  user,
  closeModal
}) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    const { status } = unitSpace
    syncState(status)
  }, [])

  const syncState = status => {
    switch (status) {
      case 'Yes':
        setSelected('returned')
        break
      case 'holdover':
        setSelected('holdover')
        break
      default:
        setSelected(null)
        break
    }
  }

  const iconClicked = value => {
    if (value === selected) value = null
    setSelected(value)
  }

  const submit = () => {
    const { pdbid } = user.user_metadata
    let status = 'No'
    if (selected === 'returned') status = 'Yes'
    else if (selected === 'holdover') status = 'holdover'
    updateKey(pdbid, context, unit.unit, room, status)
    closeModal()
  }

  const getIcon = unitSpace => {
    let icon = ''
    if (unitSpace.status === 'R') icon = keysIcons.renewal
    else if (unitSpace.status === 'V') icon = keysIcons.vacant
    else if (unitSpace.tentant_status === 'RTI') icon = keysIcons.rTO
    else if (unitSpace.tentant_status === 'RTO') icon = keysIcons.rTI
    return icon
  }

  const getStatusDisplay = unitSpace => {
    let display = ''
    if (unitSpace.status === 'R') display = 'Renewal'
    else if (unitSpace.status === 'V') display = 'Vacant'
    else if (unitSpace.tentant_status === 'RTI') display = 'Return Transfer In'
    else if (unitSpace.tentant_status === 'RTO') display = 'Return Transfer Out'
    return display
  }

  const returnedClass = selected === 'returned' ? 'active' : ''
  const holdoverClass = selected === 'holdover' ? 'active' : ''
  const hasOptions = unitSpace.status !== 'R' && unitSpace.status !== 'V'
  const title = hasOptions ? 'Key Return' : 'Status'
  const isHidden = hasOptions ? '' : 'is-hidden'
  const isNotHidden = !hasOptions ? '' : 'is-hidden'

  return (
    <div className="keys-modal">
      <div
        className={[
          'is-pulled-right',
          'is-pointer',
          'has-text-grey-light'
        ].join(' ')}
        onClick={() => {
          closeModal()
        }}>
        X
      </div>
      <p className="is-size-4">{title}</p>
      <p className="is-size-6">
        Unit {unit.unit} - Bedroom {unitSpace.unitspace}
      </p>
      <p className="is-size-6 m-b-md">Current Resident: {unitSpace.resident}</p>
      <p className={`is-size-6 m-b-md ${isNotHidden}`}>
        <img
          alt="Unit Status"
          src={getIcon(unitSpace)}
          style={{
            marginTop: '-3px'
          }}
        />{' '}
        {getStatusDisplay(unitSpace)}
      </p>
      <p className={`is-size-6 m-b-md ${isHidden}`}>Select One:</p>
      <div
        className={[
          'm-b-md',
          'm-l-md',
          'key-returned',
          returnedClass,
          isHidden
        ].join(' ')}
        onClick={() => iconClicked('returned')}>
        <div className="position-relative is-unselectable">
          <img alt="Returned" className="check-icon" src={keysIcons.returned} />
          Key Returned
        </div>
      </div>
      <div
        className={[
          'm-b-md',
          'm-l-md',
          'holdover',
          holdoverClass,
          isHidden
        ].join(' ')}
        onClick={() => iconClicked('holdover')}>
        <div className="position-relative is-unselectable">
          <img alt="returned" className="x-icon" src={keysIcons.holdover} />
          Holdover
        </div>
      </div>
      <div className={`has-text-centered ${isHidden}`}>
        <button
          className={[
            'button-fixed-width',
            'button is-secondary',
            'm-r-sm'
          ].join(' ')}
          onClick={() => {
            closeModal()
          }}>
          Cancel
        </button>
        <button
          className={['button-fixed-width', 'button', 'is-primary'].join(' ')}
          onClick={() => {
            closeModal()
            submit()
          }}>
          Save
        </button>
      </div>
    </div>
  )
}

KeysModal.propTypes = {
  updateKey: PropTypes.func,
  user: PropTypes.object,
  unit: PropTypes.object,
  room: PropTypes.string,
  unitSpace: PropTypes.object,
  closeModal: PropTypes.func
}

export default KeysModal
