import React, { useEffect, useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import './index.scss'

const CapacityForm = ({fromDate, toDate, capacityValue, itemIndex, parentUpdate, parentIndex, selectedService, currentKey }) => {
  const [from, setFrom] = fromDate ? useState(new Date(fromDate)) : useState(new Date())
  const [to, setTo] = toDate ? useState(new Date(toDate)) : useState(new Date())
  const [capacity, setCapacity] = useState(capacityValue)

  useEffect(() => {
    if (itemIndex >= 0) {
      const body= {
        start_date: from,
        end_date: to,
        capacity,
        currentKey
      }
      if(selectedService !== '') {
        body.service_id = selectedService
      }
      parentUpdate(parentIndex, itemIndex, body)
    }
  }, [from, to, capacity, selectedService])

  const setCurrentCapacity = ({ target }) => {
    const { value } = target || {}
    let filterValue = value
    // Only allow Numbers
    filterValue = (value || '').replace(/[^0-9]/g,'').replace(/\.+$/,'')
    setCapacity(filterValue)
  }

  const handleDayClick = (day, type) => 
    type === 'from'
      ? 
      // To reset to date as well
      <>
        {setFrom(() => {
          let newDate = new Date(day)
          newDate.setHours(0, 0, 0, 0)
          return newDate
        })
        }
        {setTo(() => {
          let newDate = new Date(day)
          newDate.setHours(0, 0, 0, 0)
          return newDate
        })}
      </>
      : setTo(() => {
        let newDate = new Date(day)
        newDate.setHours(0, 0, 0, 0)
        return newDate
      })

  const modifiers = { start: from, end: to }

  return (
    <div className="m-t-sm">
      <div className="capacity-form__row">
        <p className="capacity-form__label">Date(s):</p>
        <div>
          <DayPickerInput
            onDayChange={day => handleDayClick(day, 'from')}
            formatDate={formatDate}
            parseDate={parseDate}
            value={from}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              firstDayOfWeek: 1,
              weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
              modifiers,
            }}
          />
          <DayPickerInput
            onDayChange={day => handleDayClick(day, 'to')}
            formatDate={formatDate}
            parseDate={parseDate}
            value={to}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              firstDayOfWeek: 1,
              weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
              modifiers,
              disabledDays: { before: from }
            }}
          />
        </div>
      </div>
      <div className="capacity-form__row">
        <p className="capacity-form__label">
          Max Spaces:
        </p>
        <input type="text" className="input" value={capacity} onChange={e => setCurrentCapacity(e)} placeholder="(ex: 10)"/>
      </div>
    </div>
  )
}

export default CapacityForm
