import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { selectStyles } from '../../components/forms-helper/MultiDropdownStyles'
import TurnToggle from '../../components/turn-toggle'
import { cloneDeep, isEmpty } from 'lodash'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'
import Short from 'short-uuid'

const CreateStaffUserModal = ({
  roles = [],
  loading,
  currentProperty,
  propertyToAdd,
  serverError,
  staffUser,
  onDismiss,
  onSubmit
}) => {
  const [formData, setFormData] = useState({
    login_access: true,
    role: null
  })

  const [errors, setErrors] = useState({})

  const isValidForm = useMemo(() => {
    const formErrors = {}

    if (isEmpty(formData.role)) {
      formErrors.role = 'Please Select a role.'
    }

    return !Object.keys(formErrors).length
  }, [errors, formData])

  const staffRole = useMemo(() => (roles || []).find(d => d.name === 'Staff'), [
    roles
  ])

  const handleRoleChange = selectedRole => {
    setFormData({ ...formData, role: selectedRole })

    const formError = { ...errors }

    if (isEmpty(selectedRole)) {
      formError.role = 'Please select a role.'
    } else {
      delete formError.role
    }

    setErrors(formError)
  }

  const handleEnableLoginChange = value => {
    setFormData({ ...formData, login_access: value })
  }

  useEffect(() => {
    if (!isEmpty(staffUser)) {
      const { permission_rules, ...restUserDetails } = staffUser

      const mappedPermissionRules = (permission_rules || []).map(rule => ({
        action: 'Update',
        internal_permission_rule_id: rule.internal_permission_rule_id,
        login_access: rule.login_access,
        permission_rule_id: rule.permission_rule_id,
        project_id: rule.project?.[0]?.project_id,
        property_id: rule.property_id || rule.project[0]?.property_id,
        role_id: rule.role_id,
        status: rule.status
      }))

      setFormData(prevState => ({
        ...prevState,
        ...restUserDetails,
        permission_rules: mappedPermissionRules,
        role: staffRole
      }))
    }
  }, [staffUser])

  const handleSubmit = () => {
    const { login_access, role, permission_rules, ...restFormData } = formData

    const internal_permission_rule_id = Short.uuid()
    const updatedPermissionRules = cloneDeep(permission_rules)

    updatedPermissionRules.push({
      action: 'Create',
      permission_rule_id: null,
      internal_permission_rule_id,
      property_id: propertyToAdd?.property_id,
      project_id: propertyToAdd?.project_id,
      role_id: role.role_id,
      status: null,
      login_access: login_access ? null : 'false'
    })

    const body = {
      ...restFormData,
      permission_rules: updatedPermissionRules
    }

    onSubmit(body)
  }

  return (
    <>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Create Staff Login And Role
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onDismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="form">
        <div>
          <div className="columns is-gapless">
            <label className="column is-one-third input-label">
              Enable Login
            </label>
            <TurnToggle
              wrapperClassName="column"
              isActive={formData.login_access}
              onChange={handleEnableLoginChange}
            />
          </div>
          <div>
            <label className="input-label-two">
              Select the role of this user in <strong>{currentProperty}</strong>{' '}
            </label>
            <div className="m-b-sm m-t-sm">
              <MultiSelectDropdown
                displayKey="name"
                value={formData.role}
                defaultValues={roles}
                onChange={handleRoleChange}
                isDisabled={!formData.login_access}
                dropdownIcon={true}
                isMulti={false}
                placeholder="Select Role"
                noOptionsMessage="No Role Available"
                customStyles={{
                  ...selectStyles,
                  menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
              />
            </div>
            {errors.role && (
              <ErrorMessage>
                <p>{errors.role}</p>
              </ErrorMessage>
            )}

            {serverError && (
              <ErrorMessage>
                <p>{serverError}</p>
              </ErrorMessage>
            )}
          </div>
        </div>
        <div className="columns is-vcentered m-b-sm m-t-md">
          <div className="center-container">
            <button
              className="button main-button is-secondary m-r-md"
              onClick={onDismiss}>
              Cancel
            </button>
            <button
              className="button main-button is-primary"
              onClick={handleSubmit}
              disabled={!isValidForm || loading}>
              Submit
              {loading && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateStaffUserModal
