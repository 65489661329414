import caretUpOn from './icons/caret-up-full.svg'
import caretUpOff from './icons/caret-up-empty.svg'
import caretDownOn from './icons/caret-down-full.svg'
import caretDownOff from './icons/caret-down-empty.svg'
import invoiceAttachment from './icons/invoice-attachment.svg'
import lock from './icons/lock.svg'
import edit from './icons/edit.svg'

export const icons = {
  caretUpOn,
  caretUpOff,
  caretDownOn,
  caretDownOff,
  invoiceAttachment,
  lock,
  edit
}
