import React, { useState, useEffect, useRef, useMemo } from 'react'
import { headerData } from './header'
import BigLoading from '../../components/big-loading'
import { usePermissionGate } from '../..//helpers/hooks'
import styled from 'styled-components'
import moment from 'moment'
import { exportFile, closeFilled } from 'ui/icons'
import UserTable from './components/UserTable'
import Paginator from '../../components/react-table-pagination'
import BlockUserModal from './components/BlockUserModal'
import DeleteUserModal from '../view-user/components/DeleteUserModal'
import './index.scss'
import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;

  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }
  select {
    width: 100%;
    margin-top: 8px !important;
  }
  .approvals-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;

    .tr {
      position: relative;

      :first-child {
        .th {
          display: none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      :first-child {
        // border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      // :last-child {
      //   border-right: 1px solid #e5e9f2;
      // }
    }
  }
`
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // To remove empty values and - from filter
  options = (options || []).filter(e => e && e !== '-')

  return (
    <select
      value={filterValue || 'All'}
      onChange={e => {
        if (e.target.value === 'all') {
          setFilter(undefined)
        } else {
          setFilter(e.target.value || '')
        }
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="all">All</option>
      {(options || []).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const UserList = props => {
  const {
    user,
    isLoading,
    history,
    openModal,
    setModalContent,
    closeModal,
    openTooltip,
    setTooltipContent,
    closeTooltip,
    tooltip,
    setupUsers,
    setupOneUserDelete,
    setupOneUserBlock,
    context,
    allUsersGet,
    updateUserAccountStatus,
    userAccountDelete
  } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [forceReload, setForceReload] = useState(false)

  const { pdbid } = user?.user_metadata

  const tableRef = useRef()

  const { hasPermission } = usePermissionGate('add-member')

  const { allUsers } = setupUsers || {}
  const { isRequesting, data, hasMadeInitialRequest } = allUsers || {}

  const exportCSV = () => {
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows

    const filterData = (defaultData || []).map(list => {
      const {
        user_id,
        name,
        email,
        roles,
        status,
        simplified_project,
        simplified_properties,
        last_login
      } = list || {}
      return {
        'User ID': user_id || '',
        Name: name || '',
        Email: `"${email}"` || '',
        Role: roles || '',
        Properties: `"${simplified_properties}"` || '',
        Project: `"${simplified_project}"` || '',
        'Last Login': `"${last_login}"` || '',
        Status: status || ''
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${datetime}-users.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onHandleForceReload = status => {
    setForceReload(status)
  }

  useEffect(() => {
    if (forceReload) {
      allUsersGet(pdbid)
      setForceReload(false)
    }
  }, [forceReload, onHandleForceReload])

  const handleShowBlockUserModal = (user, isMultiple = false) => {
    setModalContent(() => (
      <BlockUserModal
        closeModal={closeModal}
        updateUserAccountStatus={updateUserAccountStatus}
        user={user}
        isMultiple={isMultiple}
        onHandleForceReload={onHandleForceReload}
      />
    ))
    openModal({ width: '480px' })
  }

  const handleDeleteUserModal = currentUser => {
    const { user_id } = currentUser || {}
    setModalContent(() => (
      <DeleteUserModal
        closeModal={closeModal}
        user={user}
        history={history}
        userId={user_id}
        onHandleForceReload={onHandleForceReload}
        userAccountDelete={userAccountDelete}
      />
    ))
    openModal({ width: '480px' })
  }

  const tableColumns = headerData({
    SelectColumnFilter,
    user,
    onShowBlockModal: handleShowBlockUserModal,
    onshowDeleteModal: handleDeleteUserModal
  })

  const columns = useMemo(() => tableColumns, [])

  const onResetFilter = () => tableRef.current?.clearFilter([])

  const checkIsFilterApplied = useMemo(() => {
    const checkSingleFilter = (isFilterApplied || []).filter(
      o => (o.value || []).length !== 0
    )
    return checkSingleFilter
  }, [isFilterApplied])

  useEffect(() => {
    allUsersGet(pdbid)
  }, [])

  const isAllBlockUser = useMemo(() => {
    const defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : []
    return (defaultData || []).every(data => data.blocked === 'Active')
  }, [selectedRows])

  const isAllActiveUser = useMemo(() => {
    const defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : []
    return (defaultData || []).every(data => data.blocked === 'Blocked')
  }, [selectedRows])

  return (
    <div className="users-list-container ">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">Users</h2>
          </div>
          <div className="action-button">
            <div className="users-action-buttons">
              <button
                className="button  is-success"
                disabled={!hasPermission}
                onClick={e => {
                  e.preventDefault()
                  history.push('/setup/users/new')
                }}>
                Add User
              </button>
              {(selectedRows || []).length && hasPermission ? (
                <>
                  <button
                    data-tip
                    data-for="blockuser"
                    className={`button is-secondary ${
                      !isAllBlockUser ? 'button-disabled' : ''
                    }`}
                    onClick={e => {
                      if (isAllBlockUser) {
                        const defaultData = (selectedRows || []).length
                          ? (selectedRows || []).map(row => row?.original)
                          : []
                        const arrayUniqueByKey = [
                          ...new Map(
                            (defaultData || []).map(item => [
                              item['user_id'],
                              item
                            ])
                          ).values()
                        ]
                        handleShowBlockUserModal(arrayUniqueByKey, true)
                      }
                    }}>
                    Block
                  </button>
                  <ReactTooltip
                    className="customTooltipTheme"
                    id="blockuser"
                    place="top"
                    disable={isAllBlockUser}
                    effect="solid">
                    <div>
                      <p>
                        {!isAllActiveUser && !isAllBlockUser
                          ? 'Please select users belonging to the same status to use the block/unblock feature.'
                          : 'Please select users belonging to the active status'}
                      </p>
                    </div>
                  </ReactTooltip>
                  <button
                    data-tip
                    data-for="activeuser"
                    className={`button is-secondary ${
                      !isAllActiveUser ? 'button-disabled' : ''
                    }`}
                    onClick={e => {
                      if (isAllActiveUser) {
                        const defaultData = (selectedRows || []).length
                          ? (selectedRows || []).map(row => row?.original)
                          : []
                        const arrayUniqueByKey = [
                          ...new Map(
                            (defaultData || []).map(item => [
                              item['user_id'],
                              item
                            ])
                          ).values()
                        ]
                        handleShowBlockUserModal(arrayUniqueByKey, true)
                      }
                    }}>
                    Unblock
                  </button>
                  <ReactTooltip
                    className="customTooltipTheme"
                    id="activeuser"
                    place="top"
                    disable={isAllActiveUser}
                    effect="solid">
                    <div>
                      <p>
                        {!isAllActiveUser && !isAllBlockUser
                          ? 'Please select users belonging to the same status to use the block/unblock feature.'
                          : 'Please select users belonging to the blocked status'}
                      </p>
                    </div>
                  </ReactTooltip>
                </>
              ) : null}

              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <button className="export" onClick={() => exportCSV()}>
              <img src={exportFile} alt="Export Inspections" />
              Export
            </button>
          </div>
          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        {hasMadeInitialRequest && !isRequesting ? (
          <Styles>
            <UserTable
              ref={tableRef}
              columns={columns}
              data={data}
              onRowSelectStateChange={setSelectedRows}
              setIsFilterApplied={setIsFilterApplied}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Styles>
        ) : (
          <BigLoading />
        )}
        <div className="table-pagination">
          {hasMadeInitialRequest && !isRequesting ? (
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default UserList
