import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  updateVendorBusinessInformation
} from '../../modules/vendor-quotation'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { countries, states, currencies } from '../../utils/country-state'

const BusinessInformation = ({ vendorQuotation, activeProgressItem, businessTabData, onChangeBusinessTabData, updateVendorBusinessInformation, isEdit, id, user, context, updateActiveProgressItem }) => {

  // Get data from redux
  const { vendorQuotationBusinessInfo } = vendorQuotation || {}
  const { data } = vendorQuotationBusinessInfo

  // All Countries data from package

  const [apiCall, setApiCall]  = useState(false)
  const [selectedCountryStates, setSelectedCountryStates]  = useState([])

  // Get complete tabs list
  const { completed } = activeProgressItem || {}

  // Set Business Information to Active in Progress Section
  useEffect(() => {
    updateActiveProgressItem( id || '', 'active')
  },[])

  // Update Business Tab Data state if redux data change
  useEffect(() => {
    onChangeBusinessTabData(data)
  },[data, vendorQuotationBusinessInfo])

  // Destructing
  const { address_line_1, address_line_2, city, country='United States', currency, notes, phone, state, tax_id, term, zip } = businessTabData || {}

    
  // Set Input data change to state
  const onHandleInputChange = ({ target }) => {
    const { name, value } = target || {}
    if(Object.keys(businessTabData || {}).length !== 0) {
      let newVal = value || ''
      if(name === 'phone') {
        newVal= (value || '').replace(/[^0-9-+()\s]/g, '')
      }
      let getBusinessTabData = {...businessTabData}
      const newData  = {
        ...getBusinessTabData,
        [name]: newVal || ''
      }
      // Remove service from completed progress item
      if((completed || []).includes(id)) {
        const newCompleted = (completed || []).filter(complete => complete !== id)
        updateActiveProgressItem((newCompleted || []), 'completed')
      }
      onChangeBusinessTabData(newData)
      setApiCall(true)
    }
  }

  // Call Submit after Mouse leave
  const onUserMouseLeave = () => {
    if(apiCall) {
      onHandleSubmit()
      setApiCall(false)
    }
  }

  const onHandleSubmit = () => {
    if(Object.keys(businessTabData || {}).length !== 0) {
      const { user_metadata } = user || {}
      const { pdbid, pdbids } = user_metadata || {}
      let currentInfo = {...businessTabData}
      const { address_line_1, address_line_2, city, country, currency, notes, phone, rfp_request_id, state, tax_id, term, zip } = currentInfo || {}
      const body = {
        id: rfp_request_id || '',
        data: {
          address_line_1,
          address_line_2,
          notes,
          city,
          state,
          zip,
          country,
          phone,
          tax_id,
          currency,
          term
        }
      }
      updateVendorBusinessInformation({
        pdbid: (pdbid || pdbids[0]),
        context,
        body,
        oldData: data || {},
      })
    }
  }

  // Show input field based on props
  const getInputData = (name, value, placeholder, className) => {
    if(isEdit) {
      return <input type="text" autoComplete="off" className={className} onBlur={() => onHandleSubmit()} onChange={(e) => onHandleInputChange(e)} name={name} value={ value || '' } placeholder={placeholder} />
    }
    return <span>{value}</span>
  }
  // Show Textarea field based on props
  const getTextAreaData = (name, value, placeholder) => {
    if(isEdit) {
      return <textarea  onBlur={() => onHandleSubmit()} onChange={(e) => onHandleInputChange(e)} name={name} value={ value || '' } placeholder={placeholder}></textarea>
    }
    return <span>{value}</span>
  }
  // Show select field based on props
  const getSelectData = (name, value, data , className) => {
    if(isEdit) {
      return <div className={`select ${className}`}>
        <select
          className="input"
          onChange={(e) => onHandleInputChange(e)}
          value={ value || '' }
          name={name}
          onBlur={() => onHandleSubmit()}
        >
          <option value="">Select</option>
          {(data || []).map((item, index) => <option key = { index }  value={name === 'term' ? item.value : item.label}>{ item.label }</option>)}
        </select>
      </div>
    }
    return <span>{value}</span>
  }

  // Default Term data

  const termData = [
    {
      value:'1',
      label:'Net 30'
    },
    {
      value:'2',
      label:'Net 15'
    },
  ]
 

  return (
    <div className="business-information-wrapper">
      <div className={`columns input-field ${!isEdit && 'view-state'}`}>
        <div className="column">
          <div className="columns address-fields">
            <div className="column is-one-quarter">
              <p>Address: *</p>
            </div>
            <div className="column">
              {!isEdit ? <>
                                { (address_line_1 === null || address_line_1 === '')  ? <p></p> :  <>
                                    <p>{`${address_line_1 || 'N/A'} ${address_line_2 || ''}`}</p>
                                    <p>{`${city || ''}, ${state || ''} ${zip || ''}`}</p>
                                    <p>{country || ''}</p>
                                </>}
                            </> : <>
                            {getInputData('address_line_1',address_line_1,'Address Line 1')}
                            {getInputData('address_line_2',address_line_2,'Address Line 2')}
                            {getInputData('city',city,'City','w-83')}
                            {getSelectData('state',state,states,'w-110')}
                            {getInputData('zip',zip,'Zip','w-68')}
                            {getSelectData('country',country,countries,'is-fullwidth')}
                            </>}
                            
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <p>Phone: *</p>
            </div>
            <div className="column">
              {getInputData('phone',phone,'Phone Number')}
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <p>Tax ID: *</p>
            </div>
            <div className="column">
              {getInputData('tax_id',tax_id,'Tax ID')}
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <p>Currency:</p>
            </div>
            <div className="column">
              {getSelectData('currency',currency,currencies,'w-50')}
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <p>Term: *</p>
            </div>
            <div className="column">
              {getSelectData('term',term,termData,'w-50')}
            </div>
          </div>
        </div>
        <div className="column note-field">
          <label>Notes</label>
          {getTextAreaData('notes',notes,'Notes')}
        </div>
      </div> 
            
            
    </div>
  )
}


BusinessInformation.propTypes = {
  user: PropTypes.object,
  id: PropTypes.string,
  context: PropTypes.object,
  vendorQuotation: PropTypes.object,
  businessTabData: PropTypes.object,
  updateVendorBusinessInformation: PropTypes.func,
  updateActiveProgressItem: PropTypes.func,
  onChangeBusinessTabData: PropTypes.func,
  isEdit: PropTypes.bool,
  activeProgressItem: PropTypes.object,
}

const mapStateToProps = ({ user, context, vendorQuotation }) => ({
  user,
  context,
  vendorQuotation
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVendorBusinessInformation,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessInformation)

