import _ from 'lodash'

export const getOutsideFilters = (dates, initialSetup = false) => {
  const currentDateStart = new Date().setHours(0,0,0,0)
  const currentDateEnd = new Date().setHours(23,59,59,0)

  return {
    last30Days: {
      label: 'Last 30 Days',
      keyToFilter: 'inspection_date',
      filterAction: itemDate => {
        const dateTime = new Date(itemDate).getTime()
        const yesterdayStart = new Date(currentDateStart).setDate(new Date(currentDateStart).getDate() - 30)
        const yesterdayEnd = new Date(currentDateEnd).setDate(new Date(currentDateEnd).getDate() - 30)
        return dateTime >= yesterdayStart
      },
      active: initialSetup ? true : false
    },
    tomorrow: {
      label: 'Tomorrow',
      keyToFilter: 'inspection_date',
      filterAction: itemDate => {
        // Tomorrow - Should only show data with tomorrow’s date in the schedule date
        const dateTime = new Date(itemDate).getTime()
        const tomorrowStart = new Date(currentDateStart).setDate(new Date(currentDateStart).getDate() + 1)
        const tomorrowEnd = new Date(currentDateEnd).setDate(new Date(currentDateEnd).getDate() + 1)
        return dateTime >= tomorrowStart && dateTime <= tomorrowEnd
      },
      active: false
    },
    yesterday: {
      label: 'Yesterday',
      keyToFilter: 'inspection_date',
      filterAction: itemDate => {
        // Yesterday - Should only show data with yesterday’s date in the schedule date
        const dateTime = new Date(itemDate).getTime()
        const yesterdayStart = new Date(currentDateStart).setDate(new Date(currentDateStart).getDate() - 1)
        const yesterdayEnd = new Date(currentDateEnd).setDate(new Date(currentDateEnd).getDate() - 1)
        return dateTime >= yesterdayStart && dateTime <= yesterdayEnd
      },
      active: false
    },
    all: {
      label: 'All',
      keyToFilter: 'inspection_date',
      filterAction: itemDate => {
        // All - Should show all data regardless of schedule date (or if schedule date is blank)
        return itemDate || !itemDate
      },
      active: false
    },
    custom: {
      label: 'Custom',
      keyToFilter: 'inspection_date',
      readOnly: true,
      filterAction: itemDate => {
        const dateTime = new Date(itemDate).getTime()
        return dateTime >= dates.from?.getTime() && dateTime <= dates.to?.getTime()
      },
      active: false
    }
  }
}
