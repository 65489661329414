import Service from '../service'
import LOGOUT from './auth'
import _ from 'lodash'

export const SETUP_ALL_TEAMS_GET_REQUESTED =
  'setup-teams/SETUP_ALL_TEAMS_GET_REQUESTED'
export const SETUP_ALL_TEAMS_GET_FAIL = 'setup-teams/SETUP_ALL_TEAMS_GET_FAIL'
export const SETUP_ALL_TEAMS_GET_SUCCESS =
  'setup-teams/SETUP_ALL_TEAMS_GET_SUCCESS'

export const SETUP_ONE_TEAM_GET_REQUESTED =
  'setup-teams/SETUP_ONE_TEAM_GET_REQUESTED'
export const SETUP_ONE_TEAM_GET_FAIL = 'setup-teams/SETUP_ONE_TEAM_GET_FAIL'
export const SETUP_ONE_TEAM_GET_SUCCESS =
  'setup-teams/SETUP_ONE_TEAM_GET_SUCCESS'

export const SETUP_ONE_TEAM_CREATE_REQUESTED =
  'setup-teams/SETUP_ONE_TEAM_CREATE_REQUESTED'
export const SETUP_ONE_TEAM_CREATE_FAIL =
  'setup-teams/SETUP_ONE_TEAM_CREATE_FAIL'
export const SETUP_ONE_RESET_TEAM_CREATE_SUCCESS =
  'setup-teams/SETUP_ONE_RESET_TEAM_CREATE_SUCCESS'
export const SETUP_ONE_TEAM_CREATE_SUCCESS =
  'setup-teams/SETUP_ONE_TEAM_CREATE_SUCCESS'

export const SETUP_ONE_TEAM_UPDATE_REQUESTED =
  'setup-teams/SETUP_ONE_TEAM_UPDATE_REQUESTED'
export const SETUP_ONE_TEAM_UPDATE_FAIL =
  'setup-teams/SETUP_ONE_TEAM_UPDATE_FAIL'
export const SETUP_ONE_TEAM_UPDATE_SUCCESS =
  'setup-teams/SETUP_ONE_TEAM_UPDATE_SUCCESS'

export const SETUP_ONE_TEAM_DELETE_REQUESTED =
  'setup-teams/SETUP_ONE_TEAM_DELETE_REQUESTED'
export const SETUP_ONE_TEAM_DELETE_FAIL =
  'setup-teams/SETUP_ONE_TEAM_DELETE_FAIL'
export const SETUP_ONE_TEAM_DELETE_SUCCESS =
  'setup-teams/SETUP_ONE_TEAM_DELETE_SUCCESS'

export const SETUP_ONE_TEAM_CAPACITY_CREATE_REQUESTED =
  'setup-teams/SETUP_ONE_TEAM_CAPACITY_CREATE_REQUESTED'
export const SETUP_ONE_TEAM_CAPACITY_CREATE_FAIL =
  'setup-teams/SETUP_ONE_TEAM_CAPACITY_CREATE_FAIL'
export const SETUP_ONE_TEAM_CAPACITY_CREATE_SUCCESS =
  'setup-teams/SETUP_ONE_TEAM_CAPACITY_CREATE_SUCCESS'

export const SETUP_ONE_TEAM_CAPACITY_UPDATE_REQUESTED =
  'setup-teams/SETUP_ONE_TEAM_CAPACITY_UPDATE_REQUESTED'
export const SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL =
  'setup-teams/SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL'
export const SETUP_ONE_TEAM_CAPACITY_UPDATE_SUCCESS =
  'setup-teams/SETUP_ONE_TEAM_CAPACITY_UPDATE_SUCCESS'

export const SETUP_ALL_SERVICES_GET_REQUESTED =
  'setup-teams/SETUP_ALL_SERVICES_GET_REQUESTED'
export const SETUP_ALL_SERVICES_GET_SUCCESS =
  'setup-teams/SETUP_ALL_SERVICES_GET_SUCCESS'
export const SETUP_ALL_SERVICES_GET_FAIL =
  'setup-teams/SETUP_ALL_SERVICES_GET_FAIL'

export const ALL_TEAMS_LIST_REQUESTED = 'setup-teams/ALL_TEAMS_LIST_REQUESTED'

export const ALL_TEAMS_LIST_SUCCESS = 'setup-teams/ALL_TEAMS_LIST_SUCCESS'

export const ALL_TEAMS_LIST_FAIL = 'setup-teams/ALL_TEAMS_LIST_FAIL'

const initialTeam = {
  team_id: null,
  team_name: null,
  team_type: null,
  servicetype_uri: null,
  servicetype_id: null,
  servicetype_name: null,
  members: []
}
const initialState = {
  allTeams: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  oneTeam: {
    hasMadeInitialRequest: false,
    successfullCreation: null,
    isRequesting: false,
    isError: false,
    data: initialTeam
  },
  allServices: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_ALL_TEAMS_GET_REQUESTED:
    case ALL_TEAMS_LIST_REQUESTED:
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_TEAMS_GET_SUCCESS:
    case ALL_TEAMS_LIST_SUCCESS:
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_TEAMS_GET_FAIL:
    case ALL_TEAMS_LIST_FAIL:
      return {
        ...state,
        allTeams: {
          ...state.allTeams,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ONE_TEAM_CREATE_REQUESTED:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ONE_TEAM_GET_REQUESTED:
    case SETUP_ONE_TEAM_UPDATE_REQUESTED:
    case SETUP_ONE_TEAM_DELETE_REQUESTED:
    case SETUP_ONE_TEAM_CAPACITY_CREATE_REQUESTED:
    case SETUP_ONE_TEAM_CAPACITY_UPDATE_REQUESTED:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ONE_TEAM_CREATE_SUCCESS:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          isRequesting: false,
          isError: false,
          data: action.data,
          successfullCreation: true
        }
      }
    case SETUP_ONE_TEAM_GET_SUCCESS:
    case SETUP_ONE_TEAM_UPDATE_SUCCESS:
    case SETUP_ONE_TEAM_CAPACITY_CREATE_SUCCESS:
    case SETUP_ONE_TEAM_CAPACITY_UPDATE_SUCCESS:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_TEAM_DELETE_SUCCESS:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          isRequesting: false,
          isError: false,
          data: initialTeam
        }
      }
    case SETUP_ONE_TEAM_CREATE_FAIL:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          isRequesting: false,
          isError: true,
          successfullCreation: false
        }
      }
    case SETUP_ONE_TEAM_GET_FAIL:
    case SETUP_ONE_TEAM_UPDATE_FAIL:
    case SETUP_ONE_TEAM_CAPACITY_CREATE_FAIL:
    case SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL:
    case SETUP_ONE_TEAM_DELETE_FAIL:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ONE_RESET_TEAM_CREATE_SUCCESS:
      return {
        ...state,
        oneTeam: {
          ...state.oneTeam,
          successfullCreation: null
        }
      }

    case SETUP_ALL_SERVICES_GET_REQUESTED:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_SERVICES_GET_SUCCESS:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_SERVICES_GET_FAIL:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export const setupAllTeamsGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_TEAMS_GET_REQUESTED
    })
    Service.setupAllTeamsGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const updateData = (data || []).map(capacity => {
            const { capacities } = capacity || {}
            capacity.newcapacities =
              capacities === null
                ? null
                : _.groupBy(
                    capacities || [],
                    currCapacity => currCapacity.servicetype_name
                  )
            return capacity
          })
          dispatch({
            type: SETUP_ALL_TEAMS_GET_SUCCESS,
            data: _allTeamsAdapter(updateData)
          })
        } else {
          _setupAllTeamsGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupAllTeamsGetFail(dispatch)
      })
  }
}

export const allTeamListGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: ALL_TEAMS_LIST_REQUESTED
    })
    Service.allTeamListGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const updateData = (data || []).map(capacity => {
            const { capacities } = capacity || {}
            capacity.newcapacities =
              capacities === null
                ? null
                : _.groupBy(
                    capacities || [],
                    currCapacity => currCapacity.servicetype_name
                  )
            return capacity
          })
          dispatch({
            type: ALL_TEAMS_LIST_SUCCESS,
            data: _allTeamsAdapter(updateData)
          })
        } else {
          _allTeamlistGetFail(dispatch)
        }
      })
      .catch(() => {
        _allTeamlistGetFail(dispatch)
      })
  }
}

const _setupAllTeamsGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_TEAMS_GET_FAIL
  })
}

const _allTeamlistGetFail = dispatch => {
  dispatch({
    type: ALL_TEAMS_LIST_FAIL
  })
}

export const setupOneTeamGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_TEAM_GET_REQUESTED
    })
    Service.setupOneTeamGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_TEAM_GET_SUCCESS,
            data: _oneTeamAdapter(data[0])
          })
        } else {
          _setupOneTeamGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneTeamGetFail(dispatch)
      })
  }
}

const _setupOneTeamGetFail = dispatch => {
  dispatch({
    type: SETUP_ONE_TEAM_GET_FAIL
  })
}

const NS = ''
const SNS = ''
const _membersAdapter = members => {
  return members
    .filter(m => {
      let isEmpty = true
      for (let key in m) {
        if (m[key] !== null) isEmpty = false
      }
      return !isEmpty
    })
    .map(m => {
      return {
        uri: typeof m.vendor_uri === 'string' ? m.vendor_uri : m.staff_uri,
        vendor_uri: typeof m.vendor_uri === 'string' ? m.vendor_uri : NS,
        vendor_id: typeof m.vendor_id === 'string' ? m.vendor_id : NS,
        vendor_name: typeof m.vendor_name === 'string' ? m.vendor_name : NS,
        vendor_email: typeof m.vendor_email === 'string' ? m.vendor_email : SNS,
        vendor_phone: typeof m.vendor_phone === 'string' ? m.vendor_phone : SNS,
        staff_uri: typeof m.staff_uri === 'string' ? m.staff_uri : NS,
        staff_id: typeof m.staff_id === 'string' ? m.staff_id : NS,
        staff_name: typeof m.staff_name === 'string' ? m.staff_name : SNS,
        staff_email: typeof m.staff_email === 'string' ? m.staff_email : SNS,
        staff_phone: typeof m.staff_phone === 'string' ? m.staff_phone : SNS
      }
    })
}

const getSimplifiedServices = services => {
  return (services || []).map(service => service.service_name).join(', ')
}

const getSimplifiedMembers = members => {
  return (members || [])
    .map(member => member.staff_name || member.vendor_name)
    .join(', ')
}

const _oneTeamAdapter = data => {
  return {
    used: data?.used === 'T' ? true : false,
    status: data?.status === null ? 'Active' : 'Inactive',
    team_uri: typeof data?.team_uri === 'string' ? data.team_uri : NS,
    services: data?.services || [],
    simplifiedServices:
      typeof data?.services === 'object'
        ? getSimplifiedServices(data.services)
        : [],
    simplifiedMembers:
      typeof data?.services === 'object'
        ? getSimplifiedMembers(data.members)
        : [],
    team_id: typeof data?.team_id === 'string' ? data.team_id : NS,
    team_name: typeof data?.team_name === 'string' ? data.team_name : NS,
    team_type: typeof data?.team_type === 'string' ? data.team_type : NS,
    servicetype_uri:
      typeof data?.servicetype_uri === 'string' ? data.servicetype_uri : NS,
    servicetype_id:
      typeof data?.servicetype_id === 'string' ? data.servicetype_id : NS,
    servicetype_name:
      typeof data?.servicetype_name === 'string' ? data.servicetype_name : NS,
    members: Array.isArray(data?.members) ? _membersAdapter(data.members) : [],
    assigned_services: data?.assigned_services === 'T' ? true : false,
    capacities: data?.capacities || [],
    newcapacities: data?.newcapacities || []
  }
}

const _allTeamsAdapter = data => {
  return Array.isArray(data) ? data.map(d => _oneTeamAdapter(d)) : []
}

export const setupOneTeamCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_TEAM_CREATE_REQUESTED
    })
    Service.setupOneTeamCreate(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_TEAM_CREATE_SUCCESS,
            data: _oneTeamAdapter(data[0])
          })
        } else {
          _setupOneTeamCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneTeamCreateFail(dispatch)
      })
  }
}

export const resetTeamCreation = () => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_RESET_TEAM_CREATE_SUCCESS
    })
  }
}

const _setupOneTeamCreateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_TEAM_CREATE_FAIL
  })
}

export const setupOneTeamUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_TEAM_UPDATE_REQUESTED
    })
    Service.setupOneTeamUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_TEAM_UPDATE_SUCCESS,
            data: _oneTeamAdapter(data[0])
          })
        } else {
          _setupOneTeamUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneTeamUpdateFail(dispatch)
      })
  }
}

const _setupOneTeamUpdateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_TEAM_UPDATE_FAIL
  })
}

export const setupOneTeamDelete = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_TEAM_DELETE_REQUESTED
    })
    Service.setupOneTeamDelete(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: SETUP_ONE_TEAM_DELETE_SUCCESS
          })
        } else {
          _setupOneTeamDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneTeamDeleteFail(dispatch)
      })
  }
}

const _setupOneTeamDeleteFail = dispatch => {
  dispatch({
    type: SETUP_ONE_TEAM_DELETE_FAIL
  })
}

export const setupOneTeamCapacityCreate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_TEAM_CAPACITY_UPDATE_REQUESTED
    })
    Service.setupOneTeamCapacityCreate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_TEAM_CAPACITY_UPDATE_SUCCESS,
            data: _oneTeamAdapter(data[0])
          })
        } else {
          dispatch({
            type: SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL
        })
      })
  }
}

export const setupOneTeamCapacityUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_TEAM_CAPACITY_UPDATE_REQUESTED
    })
    Service.setupOneTeamCapacityUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_TEAM_CAPACITY_UPDATE_SUCCESS,
            data: _oneTeamAdapter(data[0])
          })
        } else {
          dispatch({
            type: SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SETUP_ONE_TEAM_CAPACITY_UPDATE_FAIL
        })
      })
  }
}

export const setupAllServicesGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_SERVICES_GET_REQUESTED
    })
    Service.setupAllServicesGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_SERVICES_GET_SUCCESS,
            data: _allServicesAdapter(data)
          })
        } else {
          _setupAllServicesGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupAllServicesGetFail(dispatch)
      })
  }
}

const _setupAllServicesGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_SERVICES_GET_FAIL
  })
}

const _allServicesAdapter = data => {
  return Array.isArray(data)
    ? data.map(s => ({
        servicetype_uri:
          typeof s.servicetype_uri === 'string' ? s.servicetype_uri : NS,
        servicetype_id:
          typeof s.servicetype_id === 'string' ? s.servicetype_id : NS,
        servicetype: typeof s.servicetype === 'string' ? s.servicetype : NS
      }))
    : []
}
