import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import close from '../../icons/close.svg'
import './index.scss'
import ErrorMessage from '../../../../components/forms-helper/ErrorMessage'

const ApprovalAndRejectModal = ({
  pdbid,
  context,
  user,
  rows,
  modalTitle,
  status,
  bulkApprovalPurchaseOrder,
  onConfirm,
  onCancel
}) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [note, setNote] = useState('')
  const [noteError, setNoteError] = useState('')

  const notesRequired = status === 'rejected' && !note

  const modalDescription = useMemo(() => {
    switch (status) {
      case 'approved':
        return 'You’re about to Approve the selected purchase order(s). Please ensure these are ready to be paid before confirming.'
      case 'rejected':
        return 'You are about to reject the selected purchase order(s).'
      default:
        return 'You’re about to Approve the selected purchase order(s). Please ensure these are ready to be paid before confirming.'
    }
  }, [status])

  const confirmationMessage = useMemo(() => {
    switch (status) {
      case 'approved':
        return 'Would you like to continue with approval?'
      case 'rejected':
        return 'Are you sure you want to proceed?'
      default:
        return 'Would you like to continue with approval?'
    }
  }, [status])

  const handleSubmit = async () => {
    if (notesRequired) return

    let data = []
    const new_date = new Date()
    const customDate = moment(new_date).format('YYYY/MM/DD HH:mm:ss')
    const { user_id } = user || {}

    setLoading(true)
    setErrorMessage('')

    if ((rows || []).length) {
      ;(rows || []).forEach(({ purchase_order_id, do_not_sync }) => {
        const approvalObject = {
          purchase_order_id,
          approved_by: user_id || '',
          approved_at: customDate,
          reason: status === 'rejected' ? note : null,
          do_not_sync: status === 'rejected' ? null : do_not_sync,
          status
        }

        data.push(approvalObject)
      })

      bulkApprovalPurchaseOrder({
        pdbid,
        context,
        body: {
          items: data || []
        },
        onSuccess: () => {
          setLoading(false)
          onConfirm()
        },
        onError: () => {
          setLoading(false)
          setErrorMessage('Something went wrong. Please try again later !!')
        }
      })
    }
  }

  const handleNoteChange = ({ currentTarget: input }) => {
    const error = !input?.value?.trim()
      ? 'Please enter rejection reason note.'
      : ''

    setNote(input.value)
    setNoteError(error)
  }

  return (
    <div className="approval-and-rejection-modal">
      <div className="modal-heading">
        <h3>{modalTitle}</h3>
        <p className="close is-pointer has-text-grey-light" onClick={onCancel}>
          <img alt="Close Modal" src={close} />
        </p>
      </div>
      {errorMessage && (
        <ErrorMessage>
          <p>{errorMessage}</p>
        </ErrorMessage>
      )}
      <div className="modal-desceription">
        <p>{modalDescription}</p>
      </div>
      {status === 'rejected' && (
        <div className="columns">
          <div className="column is-full">
            <div className="m-b-sm m-t-md">
              <label className="is-size-6">Reason Note</label>
              <textarea
                className="textarea"
                rows={3}
                name="notes"
                value={note}
                onChange={handleNoteChange}
              />
            </div>
            {noteError && (
              <ErrorMessage>
                <p>{noteError}</p>
              </ErrorMessage>
            )}
          </div>
        </div>
      )}
      <div className="modal-confirmation-text center-container">
        <p>{confirmationMessage}</p>
      </div>
      <div className="modal-actions center-container">
        <button
          className="button main-button is-secondary m-r-md"
          onClick={onCancel}>
          Cancel
        </button>
        <button
          className="button main-button is-primary"
          onClick={handleSubmit}
          disabled={notesRequired}>
          Yes
          {loading && (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              color="#ffffff"
              className="m-l-sm"
            />
          )}
        </button>
      </div>
    </div>
  )
}

export default ApprovalAndRejectModal
