import React from 'react'
import DefaultColumn from './vendor-login-table/vendor-login-table-columns/default-column'
import StatusColumn from './vendor-login-table/vendor-login-table-columns/status-column'
import ActionColumn from './vendor-login-table/vendor-login-table-columns/action-column'
import NameColumn from './vendor-login-table/vendor-login-table-columns/name-column'
import SearchDropdownFilterMultiple from './vendor-login-table/vendor-login-table-column-filters/SearchDropdownFilter'
import StatusColumnFilter from './vendor-login-table/vendor-login-table-column-filters/status-column-filter'
import { question, questionFilled } from 'ui/icons'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export const headerData = () => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      width: 100,
      Filter: SearchDropdownFilterMultiple,
      filter: 'multiEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <NameColumn row={row} />
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      Filter: SearchDropdownFilterMultiple,
      filter: 'multiEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: () => (
        <div style={{ display: 'inline' }}>
          <span className="m-r-5">Enable Login</span>
          <Tippy
            appendTo={() => document.body}
            content={
              <p>This refers to the user's ability to access this property.</p>
            }
            placement="auto"
            allowHTML
            theme="light">
            <img
              src={question}
              alt="help"
              onMouseOver={e => (e.currentTarget.src = questionFilled)}
              onMouseLeave={e => (e.currentTarget.src = question)}
            />
          </Tippy>
        </div>
      ),
      accessor: 'login_access',
      filter: 'equals',
      width: 150,
      Filter: StatusColumnFilter,
      textAlign: 'left',
      Cell: row => {
        return <StatusColumn row={row} />
      }
    },
    {
      Header: () => (
        <div style={{ display: 'inline' }}>
          <span className="m-r-5">Status</span>
          <Tippy
            appendTo={() => document.body}
            content={
              <p>
                <strong>Account Status</strong>: This shows if the user is
                active/blocked on Turnable. This can be changed from User
                Management by Admins.
              </p>
            }
            placement="auto"
            allowHTML
            theme="light">
            <img
              src={question}
              alt="help"
              onMouseOver={e => (e.currentTarget.src = questionFilled)}
              onMouseLeave={e => (e.currentTarget.src = question)}
            />
          </Tippy>
        </div>
      ),
      accessor: 'status',
      Filter: StatusColumnFilter,
      filter: 'equals',
      width: 110,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: '',
      accessor: 'template_id',
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        return <ActionColumn row={row} />
      }
    }
  ]
}
