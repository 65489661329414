import React, { useState, useEffect } from 'react'
import './index.scss'

const loadingItems = ['Checking dates…', 'Verifying teams...', 'Organizing units...']

const AutoschedulingLoader = () => {
  const [loaderItemsRendered, setLoaderItemsRendered] = useState(0)

  useEffect(() => {
    let interval = null

    if (loaderItemsRendered <= 2) {
      interval = setInterval(() => {
        setLoaderItemsRendered(loaderItemsRendered + 1)
      }, 2000)
    }

    return () => clearInterval(interval)
  }, [loaderItemsRendered])

  return (
    <div className="schedule-first-step">
      <p className="title m-b-md">
        This will just take <span className="highlighted">a moment</span>
      </p>
      <p className="m-b-md large-text">
        We’re tidying up the details.
      </p>
      <div className="loaders-container">
        {loadingItems.map((item, index) =>
          index <= loaderItemsRendered ? (
            <div className="loader-item" key={item}>
              <svg height="100" width="100">
                <circle cx="50" cy="50" r="40" stroke="#3DB3E2" strokeWidth="4" fill="#FFF"/>
              </svg>
              <p>{item}</p>
            </div>
          ) : (
            <div className="loader-item placeholder" key={item}></div>
          )
        )}
      </div>
    </div>
  )
}

export default AutoschedulingLoader
