import _, { isEmpty } from 'lodash'
import Service from '../service'
import LOGOUT from './auth'

export const SETUP_ALL_VENDORS_GET_REQUESTED =
  'setup-vendors/SETUP_ALL_VENDORS_GET_REQUESTED'
export const SETUP_ALL_VENDORS_GET_FAIL =
  'setup-vendors/SETUP_ALL_VENDORS_GET_FAIL'
export const SETUP_ALL_VENDORS_GET_SUCCESS =
  'setup-vendors/SETUP_ALL_VENDORS_GET_SUCCESS'

export const SETUP_ONE_VENDOR_GET_REQUESTED =
  'setup-vendors/SETUP_ONE_VENDOR_GET_REQUESTED'
export const SETUP_ONE_VENDOR_GET_FAIL =
  'setup-vendors/SETUP_ONE_VENDOR_GET_FAIL'
export const SETUP_ONE_VENDOR_GET_SUCCESS =
  'setup-vendors/SETUP_ONE_VENDOR_GET_SUCCESS'

export const SETUP_ALL_SERVICES_REQUESTED =
  'setup-vendors/SETUP_ALL_SERVICES_REQUESTED'
export const SETUP_ALL_SERVICES_FAIL = 'setup-vendors/SETUP_ALL_SERVICES_FAIL'
export const SETUP_ALL_SERVICES_SUCCESS =
  'setup-vendors/SETUP_ALL_SERVICES_SUCCESS'

export const SETUP_ONE_VENDOR_CREATE_REQUESTED =
  'setup-vendors/SETUP_ONE_VENDOR_CREATE_REQUESTED'
export const SETUP_ONE_VENDOR_CREATE_FAIL =
  'setup-vendors/SETUP_ONE_VENDOR_CREATE_FAIL'
export const SETUP_ONE_VENDOR_CREATE_SUCCESS =
  'setup-vendors/SETUP_ONE_VENDOR_CREATE_SUCCESS'

export const SETUP_ONE_VENDOR_UPDATE_REQUESTED =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_REQUESTED'
export const SETUP_ONE_VENDOR_UPDATE_FAIL =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_FAIL'
export const SETUP_ONE_VENDOR_UPDATE_SUCCESS =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_SUCCESS'

export const SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_REQUESTED =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_REQUESTED'
export const SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_FAIL =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_FAIL'
export const SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_SUCCESS =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_SUCCESS'
export const SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_RESET =
  'setup-vendors/SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_RESET'

export const SETUP_ONE_VENDOR_DELETE_REQUESTED =
  'setup-vendors/SETUP_ONE_VENDOR_DELETE_REQUESTED'
export const SETUP_ONE_VENDOR_DELETE_FAIL =
  'setup-vendors/SETUP_ONE_VENDOR_DELETE_FAIL'
export const SETUP_ONE_VENDOR_DELETE_SUCCESS =
  'setup-vendors/SETUP_ONE_VENDOR_DELETE_SUCCESS'

export const ALL_VENDORS_LIST_REQUESTED =
  'setup-vendors/ALL_VENDORS_LIST_REQUESTED'

export const ALL_VENDORS_LIST_SUCCESS = 'setup-vendors/ALL_VENDORS_LIST_SUCCESS'

export const ALL_VENDORS_LIST_FAIL = 'setup-vendors/ALL_VENDORS_LIST_FAIL'

export const GET_ALL_VENDOR_SERVICE_PRICING_REQUESTED =
  'setup-vendors/GET_ALL_VENDOR_SERVICE_PRICING_REQUESTED'
export const GET_ALL_VENDOR_SERVICE_PRICING_FAIL =
  'setup-vendors/GET_ALL_VENDOR_SERVICE_PRICING_FAIL'
export const GET_ALL_VENDOR_SERVICE_PRICING_SUCCESS =
  'setup-vendors/GET_ALL_VENDOR_SERVICE_PRICING_SUCCESS'
export const GET_ALL_VENDOR_SERVICE_PRICING_ITEM_OPTION_SUCCESS =
  'setup-vendors/GET_ALL_VENDOR_SERVICE_PRICING_ITEM_OPTION_SUCCESS'

export const GET_ALL_PROPERTY_UNIT_SPACE_REQUESTED =
  'setup-vendors/GET_ALL_PROPERTY_UNIT_SPACE_REQUESTED'
export const GET_ALL_PROPERTY_UNIT_SPACE_FAIL =
  'setup-vendors/GET_ALL_PROPERTY_UNIT_SPACE_FAIL'
export const GET_ALL_PROPERTY_UNIT_SPACE_SUCCESS =
  'setup-vendors/GET_ALL_PROPERTY_UNIT_SPACE_SUCCESS'

const DV = ''
const vendorInitialState = {
  name: DV,
  uri: DV,
  id: DV,
  main: {
    email: DV,
    address: {
      address1: DV,
      address2: DV,
      address3: DV,
      city: DV,
      state: DV,
      zip: DV
    },
    phone: DV,
    cell_phone: DV
  },
  billing: {},
  services: []
}
const initialState = {
  allVendors: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  oneVendor: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: vendorInitialState,
    floorplanUpdating: false,
    floorplanRequested: false
  },
  allServices: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  servicePricing: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: {}
  },
  pricingItemOptionList: [],
  propertyUnitSpaces: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_ALL_VENDORS_GET_REQUESTED:
    case ALL_VENDORS_LIST_REQUESTED:
      return {
        ...state,
        allVendors: {
          ...state.allVendors,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_VENDORS_GET_SUCCESS:
    case ALL_VENDORS_LIST_SUCCESS:
      return {
        ...state,
        allVendors: {
          ...state.allVendors,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_VENDORS_GET_FAIL:
    case ALL_VENDORS_LIST_FAIL:
      return {
        ...state,
        allVendors: {
          ...state.allVendors,
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    case GET_ALL_VENDOR_SERVICE_PRICING_REQUESTED:
      return {
        ...state,
        servicePricing: {
          ...state.servicePricing,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_ALL_VENDOR_SERVICE_PRICING_SUCCESS:
      return {
        ...state,
        servicePricing: {
          ...state.servicePricing,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }

    case GET_ALL_VENDOR_SERVICE_PRICING_FAIL:
      return {
        ...state,
        servicePricing: {
          ...state.servicePricing,
          isRequesting: false,
          isError: true,
          data: {}
        }
      }
    case GET_ALL_PROPERTY_UNIT_SPACE_REQUESTED:
      return {
        ...state,
        propertyUnitSpaces: []
      }
    case GET_ALL_PROPERTY_UNIT_SPACE_SUCCESS:
      return {
        ...state,
        propertyUnitSpaces: action.data
      }

    case GET_ALL_PROPERTY_UNIT_SPACE_FAIL:
      return {
        ...state,
        propertyUnitSpaces: []
      }
    case GET_ALL_VENDOR_SERVICE_PRICING_ITEM_OPTION_SUCCESS:
      return {
        ...state,
        pricingItemOptionList: action.data
      }
    case SETUP_ONE_VENDOR_GET_REQUESTED:
    case SETUP_ONE_VENDOR_CREATE_REQUESTED:
    case SETUP_ONE_VENDOR_UPDATE_REQUESTED:
    case SETUP_ONE_VENDOR_DELETE_REQUESTED:
      return {
        ...state,
        oneVendor: {
          ...state.oneVendor,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ONE_VENDOR_GET_SUCCESS:
    case SETUP_ONE_VENDOR_CREATE_SUCCESS:
    case SETUP_ONE_VENDOR_UPDATE_SUCCESS:
    case SETUP_ONE_VENDOR_DELETE_SUCCESS:
      return {
        ...state,
        oneVendor: {
          ...state.oneVendor,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_VENDOR_GET_FAIL:
    case SETUP_ONE_VENDOR_CREATE_FAIL:
    case SETUP_ONE_VENDOR_UPDATE_FAIL:
    case SETUP_ONE_VENDOR_DELETE_FAIL:
      return {
        ...state,
        oneVendor: {
          ...state.oneVendor,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ALL_SERVICES_REQUESTED:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_SERVICES_FAIL:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_REQUESTED:
      return {
        ...state,
        oneVendor: {
          ...state.oneVendor,
          floorplanUpdating: true,
          floorplanRequested: false
        }
      }
    case SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_SUCCESS:
      return {
        ...state,
        oneVendor: {
          ...state.oneVendor,
          floorplanUpdating: false,
          floorplanRequested: true
        }
      }
    case SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_RESET:
      return {
        ...state,
        oneVendor: {
          ...state.oneVendor,
          floorplanUpdating: false,
          floorplanRequested: false
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export const setupAllVendorsGet = (pdbid, context, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_VENDORS_GET_REQUESTED
    })
    Service.setupAllVendorsGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_VENDORS_GET_SUCCESS,
            data: _allVendorsAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          _setupAllVendorsGetFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _setupAllVendorsGetFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

export const allVendorListGet = (pdbid, context, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: ALL_VENDORS_LIST_REQUESTED
    })
    Service.allVendorListGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: ALL_VENDORS_LIST_SUCCESS,
            data: _allVendorsAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          _allVendorlistGetFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _allVendorlistGetFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

export const allVendorServicePricing = (
  pdbid,
  context,
  serviceid,
  vendorid,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_VENDOR_SERVICE_PRICING_REQUESTED
    })
    Service.allVendorServicePricing(pdbid, context, serviceid, vendorid)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const getData = !isEmpty(data) ? data?.workbook : {}
          dispatch({
            type: GET_ALL_VENDOR_SERVICE_PRICING_SUCCESS,
            data: _allVendorServicePricing(getData)
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch({
            type: GET_ALL_VENDOR_SERVICE_PRICING_FAIL
          })
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_VENDOR_SERVICE_PRICING_FAIL
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const getAllPropertyUnitSpace = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_PROPERTY_UNIT_SPACE_REQUESTED
    })
    Service.getAllPropertyUnitSpace(pdbid, context)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const allUnitSpaces = await res.json()
          return dispatch({
            type: GET_ALL_PROPERTY_UNIT_SPACE_SUCCESS,
            data: allUnitSpaces
          })
        }
        return dispatch({
          type: GET_ALL_PROPERTY_UNIT_SPACE_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_PROPERTY_UNIT_SPACE_FAIL
        })
      })
  }
}

export const allVendorPricingItemOption = (
  pdbid,
  context,
  id,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.allVendorPricingItemOption(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: GET_ALL_VENDOR_SERVICE_PRICING_ITEM_OPTION_SUCCESS,
            data
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch({
            type: GET_ALL_VENDOR_SERVICE_PRICING_ITEM_OPTION_SUCCESS,
            data: []
          })
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_VENDOR_SERVICE_PRICING_ITEM_OPTION_SUCCESS,
          data: []
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const updateBulkVendorServicePricing = (
  pdbid,
  context,
  serviceid,
  vendorid,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.updateBulkVendorServicePricing(
      pdbid,
      context,
      serviceid,
      vendorid,
      body
    )
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const updateSingleVendorServicePricing = (
  pdbid,
  context,
  serviceid,
  vendorid,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.updateSingleVendorServicePricing(
      pdbid,
      context,
      serviceid,
      vendorid,
      body
    )
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const deleteSingleVendorServiceUnitSpacePricing = (
  pdbid,
  context,
  serviceid,
  vendorid,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.deleteSingleVendorServiceUnitSpacePricing(
      pdbid,
      context,
      serviceid,
      vendorid,
      body
    )
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const _allVendorServicePricing = data => {
  const {
    full_unit,
    full_unit_type,
    per_space,
    a_la_carte,
    per_unit_and_space
  } = data || {}
  const updateAlacate = (a_la_carte || []).map(a_carte => ({
    ...a_carte,
    name: a_carte?.item_option
  }))
  const updateFullUnit = (full_unit || []).map(f_unit => ({
    ...f_unit,
    floor_plan: f_unit?.floorplan || '',
    name: f_unit?.service_group
  }))
  const updateFullUnitType = (full_unit_type || []).map(f_unit_type => ({
    ...f_unit_type,
    floor_plan: f_unit_type?.floorplan || '',
    name: f_unit_type?.service_group
  }))
  const updatePerSpace = (per_space || []).map(f_space => ({
    ...f_space,
    floor_plan: f_space?.floorplan || '',
    name: f_space?.service_group
  }))
  const updatePerUnitAndSpace = (per_unit_and_space || []).map(p_u_space => ({
    ...p_u_space,
    name: p_u_space?.item_option
  }))
  const constructData = [
    {
      id: 1,
      title: 'A la carte',
      content: updateAlacate,
      tooltip:
        'A la Carte pricing is applied when no other pricing option is configured and applied.',
      error: false
    },
    {
      id: 2,
      title: 'Full Unit',
      content: updateFullUnit,
      tooltip:
        'Full Unit pricing is applied when all unit spaces require a service for a specific floorplan.',
      error: false
    },
    {
      id: 3,
      title: 'Full Unit Type',
      content: updateFullUnitType,
      tooltip:
        'Full Unit Type pricing is applied when all unit spaces require a service for a specific floorplan and unit type.',
      error: false
    },
    {
      id: 4,
      title: 'Per Space',
      content: updatePerSpace,
      tooltip:
        'Per Space pricing is applied to a specific space within a specific floorplan.',
      error: false
    },
    {
      id: 5,
      title: 'Per Unit & Space',
      content: updatePerUnitAndSpace,
      tooltip:
        'Per Unit and Space pricing is applied to a specific unit and a specific space within that unit.  This is the most granular pricing model.',
      error: false
    }
  ]
  return constructData
}

const getSimplifiedServices = services => {
  return (services || []).map(service => service.service_name).join(', ')
}

const _allVendorsAdapter = data => {
  return data.map(d => {
    let { Services } = d
    Services = Services.filter(s => s.servicetype_id)
    if (Services.length === 1) {
      const s = Services[0]
      if (s.service_uri === null) Services = []
    }
    // for (let i = 0; i < Services.length; i += 1) {
    //   let { options } = Services[i]
    //   //      if (typeof options === 'undefined') options = []
    //   for (let ii = 0; ii < options.length; ii += 1) {
    //     let { cost } = options[ii]
    //     if (typeof cost !== 'string' && typeof cost !== 'number') {
    //       Services[i].options[ii].cost = 0
    //     }
    //   }
    // }
    return {
      ...d,
      used: d?.used === 'T' ? true : false,
      status: d?.status === null ? 'Active' : 'Inactive',
      simplifiedServices:
        typeof d.Services === 'object' ? getSimplifiedServices(d.Services) : [],
      Services,
      render_services: _.flatten(
        _.map(
          d.Services,
          service => ({
            id: [service.service_uri],
            servicetype_id: service.servicetype_id,
            servicename: service.service_name
            //item_option_name: option.item_option_name,
          })
          // _.map(service.options, option => ({
          //   id: [service.service_uri],
          //   servicetype_id: service.servicetype_id,
          //   servicename: service.service_name,
          //   //item_option_name: option.item_option_name,
          //   cost: option.cost,
          //   unit: option.unit
          // }))
        )
      )
    }
  })
}

const _setupAllVendorsGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_VENDORS_GET_FAIL
  })
}

const _allVendorlistGetFail = dispatch => {
  dispatch({
    type: ALL_VENDORS_LIST_FAIL
  })
}

export const setupOneVendorGet = (pdbid, context, id, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: SETUP_ONE_VENDOR_GET_REQUESTED
    })
    Service.setupOneVendorGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const oneVendorDataRes = await res.json()
          dispatch({
            type: SETUP_ONE_VENDOR_GET_SUCCESS,
            data: _oneVendorAdapter(oneVendorDataRes, [])
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          _setupOneVendorFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _setupOneVendorFail(dispatch)
        if (onError) {
          onError()
        }
      })

    // try {
    //   let [oneVendorData, floorplanData] = await Promise.all([
    //     Service.setupOneVendorGet(pdbid, context, id),
    //     Service.setupOneVendorFloorplanGet(pdbid, context, id)
    //   ])

    //   if (oneVendorData.ok) {
    //     const oneVendorDataRes = await oneVendorData.json()
    //     const floorplanDataRes = await floorplanData.json()
    //     dispatch({
    //       type: SETUP_ONE_VENDOR_GET_SUCCESS,
    //       data: _oneVendorAdapter(oneVendorDataRes, floorplanDataRes)
    //     })
    //   }
    // } catch (err) {
    //   _setupOneVendorFail(dispatch)
    // }
  }
}

const _setupOneVendorFail = dispatch => {
  dispatch({
    type: SETUP_ONE_VENDOR_GET_FAIL
  })
}

export const setupOneVendorUpdate = (
  pdbid,
  context,
  id,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_VENDOR_UPDATE_REQUESTED
    })
    const body1 = _bodyAdapter({
      vendor_name: body.name,
      ...body
    })

    Service.setupOneVendorUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_VENDOR_UPDATE_SUCCESS,
            data: _oneVendorAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
          // Service.setupOneVendorFloorplanGet(pdbid, context, id)
          //   .then(async resFloorplan => {
          //     const floorplanData = await resFloorplan.json()
          //     dispatch({
          //       type: SETUP_ONE_VENDOR_UPDATE_SUCCESS,
          //       data: _oneVendorAdapter(data, floorplanData)
          //     })
          //     if (onSuccess) {
          //       onSuccess()
          //     }
          //   })
          //   .catch(() => {
          //     _setupOneVendorUpdateFail(dispatch)
          //     if (onError) {
          //       onError()
          //     }
          //   })
        } else {
          _setupOneVendorUpdateFail(dispatch)
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        _setupOneVendorUpdateFail(dispatch)
        if (onError) {
          onError()
        }
      })
  }
}

const _setupOneVendorUpdateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_VENDOR_UPDATE_FAIL
  })
}

export const setupOneVendorCreate = (pdbid, context, vendor) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_VENDOR_CREATE_REQUESTED
    })
    const body1 = _bodyAdapter({ vendor_name: vendor.name })
    if (Object.keys(body1).length < 1) _setupOneVendorCreateFail(dispatch)
    Service.setupOneVendorCreate(pdbid, context, body1)
      .then(async res => {
        if (res.ok) {
          const data1 = await res.json()
          const res2 = await Service.setupOneVendorUpdate(
            pdbid,
            context,
            data1[0].vendor_id,
            _bodyAdapter(vendor)
          )
          const data2 = await res2.json()
          dispatch({
            type: SETUP_ONE_VENDOR_CREATE_SUCCESS,
            data: _oneVendorAdapter(data2[0])
          })
        } else {
          _setupOneVendorCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneVendorCreateFail(dispatch)
      })
  }
}

const _setupOneVendorCreateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_VENDOR_CREATE_FAIL
  })
}

export const setupServicesGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_SERVICES_REQUESTED
    })
    Service.setupServicesGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_SERVICES_SUCCESS,
            data: _allServicesAdapter(data)
          })
        } else {
          _setupServicesFail(dispatch)
        }
      })
      .catch(() => {
        _setupServicesFail(dispatch)
      })
  }
}

export const setupOneVendorDelete = (
  pdbid,
  context,
  id,
  item_option_uri = null
) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_VENDOR_DELETE_REQUESTED
    })
    Service.setupOneVendorDelete(pdbid, context, id, item_option_uri)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_VENDOR_DELETE_SUCCESS,
            data: _oneVendorAdapter(data)
          })
        } else {
          _setupOneVendorDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneVendorDeleteFail(dispatch)
      })
  }
}

export const deleteVendor = (pdbid, context, id, onSuccess, onError) => {
  return dispatch => {
    Service.deleteVendorCompany(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const toggleVendorAvailablitity = (
  pdbid,
  context,
  id,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.toggleVendorAvailablitity(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const deleteVendorServices = (
  pdbid,
  context,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.deleteVendorServices(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const _setupOneVendorDeleteFail = dispatch => {
  dispatch({
    type: SETUP_ONE_VENDOR_DELETE_FAIL
  })
}

export const setupOneVendorFloorplanUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_REQUESTED
    })

    Service.setupOneVendorFloorplanUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          dispatch({ type: SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_SUCCESS })
        } else {
          dispatch({
            type: SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_FAIL
        })
      })
  }
}

export const setupOneVendorFloorplanReset = () => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_VENDOR_UPDATE_FLOORPLAN_RESET
    })
  }
}

// Adapters

const _optionsAdapter = options => {
  return options.map(o => ({
    uri: typeof o.item_option_uri === 'string' ? o.item_option_uri : DV,
    id: typeof o.item_option_id === 'string' ? o.item_option_id : DV,
    name: typeof o.item_option_name === 'string' ? o.item_option_name : DV,
    cost: typeof o.cost === 'string' ? o.cost : DV,
    unit: typeof o.unit === 'string' ? o.unit : DV
  }))
}
const _servicesAdapter = services => {
  return services
    .filter(s => {
      let isEmpty = true
      for (let key in s) {
        if (s[key] !== null) isEmpty = false
      }
      return !isEmpty
    })
    .map(s => {
      let data = {}
      let display = {}
      if (Array.isArray(s.options)) {
        for (let i = 0; i < s.options.length; i += 1) {
          let o = s.options[i]
          const id =
            typeof o.item_option_uri === 'string'
              ? o.item_option_uri
              : `DV-${i}`
          data[id] = `$${o.cost || 0} / ${o.unit || ''}`

          display[id] =
            typeof o.item_option_name === 'string' ? o.item_option_name : DV
        }
      }
      return {
        uri: typeof s.service_uri === 'string' ? s.service_uri : DV,
        status: typeof s.service_delete === 'string' ? s.service_delete : DV,
        name: typeof s.service_name === 'string' ? s.service_name : DV,
        options: Array.isArray(s.options) ? _optionsAdapter(s.options) : [],
        options_kv: {
          data,
          display
        }
      }
    })
}
const _makeEmptyIfEmpty = obj => {
  let shouldBeEmpty = false
  let numEntries = Object.keys(obj).length
  let numEmpty = 0
  for (let key in obj) {
    if (obj[key] === DV) numEmpty++
  }
  if (numEmpty === numEntries) shouldBeEmpty = true
  return shouldBeEmpty ? {} : obj
}
const _oneVendorAdapter = (vendorData, floorplanData = []) => {
  if (Array.isArray(vendorData) && vendorData.length > 0)
    vendorData = vendorData[0]
  let main = {
    email: typeof vendorData.email === 'string' ? vendorData.email : DV,
    address: {
      address1:
        typeof vendorData.address1 === 'string' ? vendorData.address1 : DV,
      address2:
        typeof vendorData.address2 === 'string' ? vendorData.address2 : DV,
      address3:
        typeof vendorData.address3 === 'string' ? vendorData.address3 : DV,
      city: typeof vendorData.city === 'string' ? vendorData.city : DV,
      state: typeof vendorData.state === 'string' ? vendorData.state : DV,
      zip: typeof vendorData.zip === 'string' ? vendorData.zip : DV,
      country: typeof vendorData.country === 'string' ? vendorData.country : DV
    },
    phone: typeof vendorData.phone === 'string' ? vendorData.phone : DV,
    cell_phone:
      typeof vendorData.cell_phone === 'string' ? vendorData.cell_phone : DV
  }
  let billing = {
    legal_name:
      typeof vendorData.legal_name === 'string' ? vendorData.legal_name : DV,
    billing_address:
      typeof vendorData.billing_address === 'string'
        ? vendorData.billing_address
        : DV,
    taxid: typeof vendorData.taxid === 'string' ? vendorData.taxid : DV,
    currency:
      typeof vendorData.currency === 'string' ? vendorData.currency : DV,
    terms: typeof vendorData.terms === 'string' ? vendorData.terms : DV
  }
  return {
    used: vendorData?.used === 'T' ? true : false,
    status: vendorData?.status === null ? 'Active' : 'Inactive',
    name: typeof vendorData.name === 'string' ? vendorData.name : DV,
    integration_id:
      typeof vendorData.integration_id === 'string'
        ? vendorData.integration_id
        : DV,
    id:
      typeof vendorData.vendor_id === 'string'
        ? vendorData.vendor_id
        : 'new-vendor',
    main,
    billing: _makeEmptyIfEmpty(billing),
    services: _servicesAdapter(
      Array.isArray(vendorData.Services) ? vendorData.Services : []
    ),
    floorplan: floorplanData ? floorplanData : null,
    falttenFloorplans: floorplanData
      ? _.flatten(floorplanData.map(item => item.services))
      : null
  }
}

const _bodyAdapter = body => {
  let obj = {}
  for (let key in body) {
    const value = body[key]
    if (typeof value !== 'string' || value === DV) continue
    obj[key] = value
  }
  return obj
}

const _itemsAdapter = items => {
  return items.map(i => ({
    item: typeof i.item === 'string' ? i.item : '',
    item_uri: typeof i.item_uri === 'string' ? i.item_uri : '',
    item_id: typeof i.item_id === 'string' ? i.item_id : '',
    item_options: Array.isArray(i.item_options) ? i.item_options : []
  }))
}
const _allServicesAdapter = data => {
  return data.map(d => ({
    servicetype_uri:
      typeof d.servicetype_uri === 'string' ? d.servicetype_uri : '',
    servicetype_id:
      typeof d.servicetype_id === 'string' ? d.servicetype_id : '',
    servicetype: typeof d.servicetype === 'string' ? d.servicetype : '',
    items: Array.isArray(d.items) ? _itemsAdapter(d.items) : []
  }))
}

const _setupServicesFail = dispatch => {
  dispatch({
    type: SETUP_ALL_SERVICES_FAIL
  })
}
