import React from 'react'
import Breadcrumbs from '../breadcrumbs'
import ChargesList from '../charges-list'

const ChargesDashboardMain = (props) => {
  const { location } = props
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
      </div> 
      <div className="section">
        <ChargesList {...props}/>
      </div>
    </div>
  )
}

export default ChargesDashboardMain
