import _ from 'lodash'
import Short from 'short-uuid'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { List } from 'react-content-loader'
// TODO 009:
// This is a future feature, actual endpoint needs to be used
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import SimpleBar from 'simplebar-react'
import { useModals } from 'hooks'
import ServiceOptionModal from '../../parents/service-option-modal'
import Notes from '../../containers/notes'
import RestrictedElement from '../../containers/restricted-element'
// TODO 009:
// This is a future feature, actual endpoint needs to be used
// import DropdownTogglerBtn from '../../components/dropdown-toggler-btn'
// import ActiviyLogger from '../../components/activiy-log'
import { getMoneyNumber, formatDate } from '../../helpers'
import { isDescendant } from '../../utils'
import { icons } from './icons'

import './index.scss'
import PermissionGate from '../permission-gate'

const MODAL_WIDTH = { width: '480px', maxWidth: '94%' }
const LOAD_DELAY = 400

const transformUnitSpace = unitSpace => {
  if (unitSpace.toLowerCase() === 'common') return 'CA'
  return unitSpace.toUpperCase()
}

const getOption = (us, key) => {
  if (us[key] === undefined || us[key] === null) return ''
  return us[key].toUpperCase()
}

const getOptionTooltip = (service, key, optionLabels) =>
  key !== '' && key !== null && key !== undefined
    ? _.get(
      _.find(optionLabels, l => l.service === service && l.label === key),
      'key',
      key
    )
    : key

/**
 * Component for showing a single unit card in inspect board
 *
 * @component
 * @example
 * const unit = {
 *   "unit": "1101",
 *   "unit_id": "1101",
 *   "unit_cost": 500,
 *   "unit_spaces": <unit_spaces>,
 *   "has_note": true,
 *   "is_transfer": false,
 *   "is_asis": false,
 *   "is_full_asis": false,
 *   "is_carpet": false
 * }
 * const optionsMap = [
 *     {
 *       "service": "Service 1",
 *       "key": "Key 1",
 *       "label": "Label 1"
 *     },
 *     ...
 * ]
 * rowOptions={[
 *  {
 *    available: true,
 *    icon: icons.noteAdd,
 *    caption: 'Add note',
 *  },
 *  {
 *    available: false,
 *    icon: icons.note,
 *    caption: 'Edit notes',
 *  }
 * ]}
 * return (
 *   <InspectCard
 *     context={context}
 *     user={user}
 *     unit={unit}
 *     labelMap={{
 *         "Paint": "Paint",
 *         "Punch": "Punch",
 *         "Cleaning": "Clean",
 *         "Carpet Replace": "CP Rep",
 *         "Concrete/Vinyl Replace": "CR/V Rep",
 *         "Sparkle": "Sparkle"
 *     }}
 *     optionsMap={optionsMap}
 *     inspectActivity={inspectActivity}
 *     getInspectActivityLog={getInspectActivityLog}
 *     setModalContent={setModalContent}
 *     openModal={openModal}
 *     closeModal={closeModal}
 *     rowOptions={}
 *     services={[services]}
 *   />
 * )
 */

const InspectCard = ({
  context,
  user,
  unit,
  labelMap,
  optionsMap,
  inspectActivity,
  getInspectActivityLog,
  setModalContent,
  openModal,
  closeModal,
  rowOptions,
  services
}) => {
  const [showModal] = useModals()
  const visualColumns = Object.keys(labelMap)
  const [isReady, setIsReady] = useState(false)
  // TODO 009:
  // This is a future feature, actual endpoint needs to be used
  // const [detailOpened, setDetailOpened] = useState(false)
  // const [loadingDetail, setLoadingDetail] = useState(false)
  // const [logActivity, setLogActivity] = useState([])
  const [ellipsisMenuOpened, setEllipsisMenuOpened] = useState(false)
  const ref = useRef()
  
  const handleObserver = entities => {
    if (entities[0].intersectionRatio === 1) {
      setTimeout(() => {
        setIsReady(true)
      }, LOAD_DELAY)
    }
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    }
    const observer = new IntersectionObserver(
      entities => handleObserver(entities),
      options
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  // TODO 009:
  // This is a future feature, actual endpoint needs to be used
  // useEffect(() => {
  //   if(loadingDetail) {
  //     setLoadingDetail(inspectActivity.activityLogIsRequesting)
  //     setLogActivity(inspectActivity.logItems)
  //   }
  // }, [inspectActivity])

  useEffect(() => {
    document.addEventListener('click', onWindowClick)
    return () => document.removeEventListener('click', onWindowClick)
  }, [ellipsisMenuOpened])

  const onWindowClick = (e) => {
    if (e.target && !isDescendant(e.target, 'options-button')) {
      if (ellipsisMenuOpened) {
        setEllipsisMenuOpened(false)
      }
    }
  }

  // Ellipsis menu options
  const renderOptions = unit => {
    if (unit) {
      return (
        <ul>
          {_.map(
            _.filter(rowOptions, option => option.available(unit)),
            (option, index) => (
              <li key={`option-trigger-${index}`}>
                <button
                  className="options-button"
                  onClick={() => {
                    setEllipsisMenuOpened(false)
                    openNotes(unit)
                  }}>
                  <img
                    src={option.icon}
                    alt={option.caption}
                    className="p-r-xs"
                  />
                  {option.caption}
                </button>
              </li>
            )
          )}
        </ul>
      )
    }
    return null
  }

  const openNotes = unit => {
    setModalContent(() => (
      <Notes
        unit={unit.unit}
        unitId={unit.unit_id}
        service=""
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal(MODAL_WIDTH)
  }

  // TODO 009:
  // This is a future feature, actual endpoint needs to be used
  // const openActivityLog = unit => {
  //   if(!detailOpened) {
  //     setLoadingDetail(true)
  //     const { unit_id } = unit
  //     const { user_metadata: { pdbid } } = user
  //     getInspectActivityLog(unit_id, pdbid, context)
  //   }
  //   setDetailOpened(!detailOpened)
  // }

  const renderLoading = () => {
    return (
      <div ref={ref}>
        <List viewBox="0 0 380 70" id={Short.uuid()} />
      </div>
    )
  }

  // Table header
  const getHeader = () => (
    <thead>
      <tr>
        <th className="is-borderless unit-space">
          Unit | Space
        </th>
        <th className="is-borderless keys-returned">
          Keys Returned
        </th>
        <th className="is-borderless inspection-date">
          Inspected
        </th>
        {_.map(visualColumns, key => (
          <th
            className={[
              'is-borderless',
              'box-header'
            ].join(' ')}
            key={Short.uuid()}>
            {labelMap[key]}
          </th>
        ))}
        <th className="is-borderless lease-status">
          Lease Status
        </th>
        <th className="is-borderless exception">
          Exception
        </th>
        <th className="is-borderless has-text-right space-cost">
          Space Cost
        </th>
      </tr>
    </thead>
  )

  const handleUnitItemClick = (unit, unitSpace, service) => {
    showModal({ width: '480px' }, <ServiceOptionModal unitInfo={{...unit, ...unitSpace}} service={service} /> )
  }

  // Table body
  const getUnitSpace = (unit_space, isLastItem) => {
    return (
      <tr
        key={Short.uuid()}
        id={`turnboard_row_${unit.unit}_${unit_space.unit_space}`}
        className="unit-space-row"
        onMouseOver={e => {
          e.target.closest('tr').classList.add('focused')
        }}
        onMouseOut={e => {
          e.target.closest('tr').classList.remove('focused')
        }}>
        <td className="is-borderless vcenter medium-large-column has-text-left">
          <span>
            {unit.unit} | {transformUnitSpace(unit_space.unit_space)}
          </span>
        </td>
        <td className="is-borderless vcenter">
          <span>{unit_space.key_status ? unit_space.key_status : (<hr className="navbar-divider" />)}</span>
        </td>
        <td className="is-borderless vcenter">
          <span>{formatDate(unit_space.inspected)}</span>
        </td>
        {_.map(visualColumns, (key, index) => (
          <td className="is-borderless vcenter boxes has-text-centered" key={Short.uuid()}>
            <div
              className={`has-tooltip ${
                unit_space[key] !== null && unit_space[key] !== undefined
                  ? 'full'
                  : 'empty'
              }`}
              data-tooltip={getOptionTooltip(
                key,
                _.get(unit_space, key, ''),
                optionsMap
              )}
              onClick={() => handleUnitItemClick(unit, unit_space, services[index])}
            >
              <span className="boxes-value">
                {getOption(unit_space, key)}
              </span>
            </div>
          </td>
        ))}
        <td className="is-borderless vcenter">
          {unit_space.lease_status && unit_space.lease_status !== '' ? (
            <span>{unit_space.lease_status}</span>
          ) : (
            <hr className="navbar-divider" />
          )}
        </td>
        <td className="is-borderless vcenter">
          {unit_space.is_asis && (
            <span
              className={`p-none ${isLastItem ? 'has-tooltip-top' : 'has-tooltip-bottom'} p-t-sm p-r-sm`}
              data-tooltip="As-Is Space">
              <img src={icons.asIs} alt="As-Is Space" />
            </span>
          )}
          {unit_space.is_full_asis && (
            <span
              className={`p-none ${isLastItem ? 'has-tooltip-top' : 'has-tooltip-bottom'} p-t-sm p-r-sm`}
              data-tooltip="Full As-Is">
              <img src={icons.asFull} alt="Full As-Is" />
            </span>
          )}
          {unit_space.is_transfer && (
            <span
              className={`p-none ${isLastItem ? 'has-tooltip-top' : 'has-tooltip-bottom'} p-t-sm p-r-sm`}
              data-tooltip="Is-Transfer">
              <img src={icons.transfersActiveBlue} alt="Is-Transfer" />
            </span>
          )}
          {unit_space.is_carpet && (
            <span
              className={`p-none ${isLastItem ? 'has-tooltip-top' : 'has-tooltip-bottom'} p-t-sm p-r-sm`}
              data-tooltip="Carpet">
              <img src={icons.carpet} alt="Carpet" />
            </span>
          )}
        </td>
        <td className="is-borderless vcenter has-text-right">
          <span>{getMoneyNumber(unit_space.unit_space_cost, 'USD')}</span>
        </td>
      </tr>
    )
  }

  if (!isReady) {
    return renderLoading()
  }

  return (
    <div
      className={[
        'box',
        'is-paddingless',
        'is-shadowless',
        'has-border-light'
      ].join(' ')}
      key={`service-card-${unit.unit}`}>
      <div className="inspect-card-header p-md m-b-md">
        {unit.has_note && (
          <span
            className="is-pulled-left p-none has-tooltip-bottom p-t-sm p-l-sm"
            data-tooltip="This unit has notes"
            onClick={() => openNotes(unit)}>
            <img src={icons.note} alt="Note" className="note-icon is-pointer" />
          </span>
        )}
        {unit.is_full_asis && (
          <span
            className="is-pulled-left p-none has-tooltip-bottom p-t-sm p-l-sm"
            data-tooltip="Full As-Is">
            <img src={icons.fullAsIs} alt="Full As-Is Unit" className="note-icon"/>
          </span>
        )}
        {unit.fully_vacant && (
          <span
            className="is-pulled-left p-none has-tooltip-bottom p-t-sm p-l-sm"
            data-tooltip="Fully Vacant">
            <img src={icons.fullyVacant} alt="Fully Vacant" className="note-icon"/>
          </span>
        )}
        {unit.partially_occupied && (
          <span
            className="is-pulled-left p-none has-tooltip-bottom p-t-sm p-l-sm"
            data-tooltip="Partially Occupied">
            <img src={icons.partiallyOccupied} alt="Partially Occupied" className="note-icon"/>
          </span>
        )}
        {/* {unit.is_carpet && (
          <span
            className="is-pulled-left p-none has-tooltip-bottom p-t-sm p-l-sm"
            data-tooltip="Carpet">
            <img src={icons.carpet} alt="Carpet" className="note-icon" />
          </span>
        )} */}
        <PermissionGate name={'manage-inspection-notes'}>
          <RestrictedElement>
            <div
              className={`dropdown is-pulled-right ${
                ellipsisMenuOpened ? 'is-active' : ''
              }`}>
              <div className="dropdown-trigger">
                <button
                  className="button options-button"
                  type="button"
                  style={{ height: '32px' }}
                  aria-haspopup={true}
                  aria-controls="row-options"
                  onClick={() => setEllipsisMenuOpened(!ellipsisMenuOpened)}>
                  <img src={icons.dark} alt="Options" />
                </button>
              </div>
              <div className="dropdown-menu" role="menu" id="row-options">
                <div className="dropdown-content has-text-left p-none m-none">
                  {renderOptions(unit)}
                </div>
              </div>
            </div>
          </RestrictedElement>
        </PermissionGate>
     
      </div>
      <div className="p-md inspect-card-body">
        <div style={{ overflowY: 'hidden' }}>
          <SimpleBar>
            <table className="table m-none inspect-table">
              {getHeader()}
              <tbody>
                {_.map(unit.unit_spaces, (unit_space, index) =>
                  getUnitSpace(
                    unit_space,
                    index === unit.unit_spaces.length - 1
                  )
                )}
              </tbody>
            </table>
          </SimpleBar>
        </div>
      </div>
      {/* TODO 009:
      This is a future feature, actual endpoint needs to be used
      logItems are comming from the inspectActivity state
      <div className="p-md inspect-card-footer">
        <DropdownTogglerBtn
          className="toggler m-t-sm m-b-sm"
          toggle={() => openActivityLog(unit)}
          dropwdownOpened={detailOpened}
        />
        <div className="total">
          <span className="m-r-sm">Unit Cost:</span>
          {getMoneyNumber(unit.unit_cost, 'USD')}
        </div>
      </div>
      <div className={`p-md details-container ${detailOpened ? '' : 'closed'}`}>
        {loadingDetail && <FontAwesomeIcon icon={faSpinner} spin />}
        {!loadingDetail && logActivity && (
          <ActiviyLogger title="Activity Log" logItems={logActivity} />
        )}
      </div>
      */}
    </div>
  )
}

InspectCard.propTypes = {
  /**
   * Context Redux State
   */
  context: PropTypes.object.isRequired,
  /**
   * User info Redux state
   */
  user: PropTypes.object.isRequired,
  /**
   * Unit to be displayed
   */
  unit: PropTypes.object.isRequired,
  /**
   * Label map for available services
   */
  labelMap: PropTypes.object.isRequired,
  /**
   * Options map for available services
   */
  optionsMap: PropTypes.array.isRequired,
  /**
   * Get unit inspection activity log Redux action
   */
  inspectActivity: PropTypes.object.isRequired,
  /**
   * Get unit inspection activity log Redux action
   */
  getInspectActivityLog: PropTypes.func.isRequired,
  /**
   * Set modal content Redux action
   */
  setModalContent: PropTypes.func.isRequired,
  /**
   * Open modal content Redux action
   */
  openModal: PropTypes.func.isRequired,
  /**
   * Close modal content Redux action
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * List of options for ellipsis menu
   */
  rowOptions: PropTypes.array.isRequired,
  /**
   * List of services
   */
  services: PropTypes.array.isRequired
}

export default InspectCard
