import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { download, closeOutline } from 'ui/icons'
import { formatDateString } from '../../utils'
import PropTypes from 'prop-types'

import './index.scss'
import { useModals } from '../../helpers/hooks'
import ConfirmationModal from '../confirmation-modal'

const InspectionMediaModal = props => {
  const {
    context,
    inspectionMediaList,
    inspectionDownloadMediaURL,
    downloadInspectionMedia,
    onError,
    currentRow,
    unit = '',
    user,
    inspect: { downloadInspectionMediaRequested },
    type = 'Inspection'
  } = props || {}

  const [inspectionFiles, setInspectionFiles] = useState([])
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(1)
  const [sliderNav1, setSliderNav1] = useState(null)
  const [sliderNav2, setSliderNav2] = useState(null)

  const [showConfirmModal, dismissConfirmModal] = useModals()

  let slider1 = useRef()
  let slider2 = useRef()

  useEffect(() => {
    setSliderNav1(slider1)
    setSliderNav2(slider2)
  }, [])

  useEffect(() => {
    if (
      (inspectionMediaList || []).length !== 0 &&
      inspectionDownloadMediaURL !== ''
    ) {
      // Meta Data
      const { project, property } = context || {}
      const updateMediaList = (inspectionMediaList || []).map(mediaList => {
        const { file_name, fileName, URI } = mediaList || {}
        const name = file_name || fileName || URI
        const appendDatatoFileName = `${inspectionDownloadMediaURL ||
          ''}Inspections/${project || ''}_${property || ''}/${name || ''}`
        return {
          ...mediaList,
          file_name: appendDatatoFileName
        }
      })
      setInspectionFiles(updateMediaList)
    }
  }, [inspectionMediaList])

  const handleClose = () => {
    const { onToggle } = props || {}
    onToggle()
  }

  const getOnlyFileName = fileName => {
    const getLastIndexOfSlash = (fileName || '')?.toString()?.lastIndexOf('/')
    const result = (fileName || '')
      ?.toString()
      ?.substring(getLastIndexOfSlash + 1)
    return result ? result : fileName
  }

  const downloadMedia = () => {
    // Get current display media file name
    const getMediaURL =
      inspectionFiles[(selectedMediaIndex || 0) - 1]?.file_name
    const getFileName = getOnlyFileName(getMediaURL)
    const location =
      inspectionFiles[(selectedMediaIndex || 0) - 1]?.location || ''
    const unit = inspectionFiles[(selectedMediaIndex || 0) - 1]?.unit || ''
    // Check if file exists in storage
    fetch(getMediaURL)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `${unit || ''}_${location || ''}_${getFileName || ''}`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        console.log('Error:', err)
      })
    // fetch(getMediaURL, { method: 'HEAD' })
    //   .then(res => {
    //     if (res.ok) {
    //       let link = document.createElement('a')
    //       link.href = getMediaURL
    //       link.download = 'Download.jpg'
    //       document.body.appendChild(link)
    //       link.click()
    //       document.body.removeChild(link)
    //     }
    //   })
    //   .catch(err => console.log('Error:', err))
  }

  const downloadedAllMedia = async () => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}

    const payload = {
      items: [
        {
          name: formatDateString(new Date()),
          building_id: currentRow.building,
          status: null,
          job_lines: [
            {
              unit_id: currentRow.unit_id,
              inspection_id: currentRow.inspection_id
            }
          ]
        }
      ]
    }

    downloadInspectionMedia(
      pdbid,
      context,
      payload,
      () => {},
      () => {
        onError && onError('Something went wrong. Please try again later !!!')
      }
    )
  }

  const handleConfirmDownloadMedia = () => {
    showConfirmModal(
      { width: '480px' },
      <ConfirmationModal
        modalTitle="Do you want to proceed?"
        dismissLabel="Cancel"
        submitLabel="Confirm"
        msg="Once you confirm, the download will be added to the queue. You will be able to download the zip folder onto your device when the download is ready from the Downloads section. However, please note that that the download link will expire after 72 hours from the time the link is ready."
        submit={() => {
          downloadedAllMedia()
          dismissConfirmModal()
        }}
        dismiss={dismissConfirmModal}
        useLoading
      />
    )
  }

  const settings = {
    afterChange: indexOfCurrentSlide => {
      setSelectedMediaIndex(indexOfCurrentSlide + 1)
    }
  }

  return (
    <div className="inspection-modal-wrapper">
      <div className="inspection-modal-header">
        <div className="columns">
          <div className="column">
            <h4>{`Unit ${(inspectionFiles || [])[0]?.unit || unit}`}</h4>
          </div>
          <div className="column">
            <p
              style={{ textAlign: type === 'Inspection' ? 'right' : 'center' }}>
              {`${
                (inspectionFiles || []).length !== 0 ? selectedMediaIndex : 0
              } / ${(inspectionFiles || []).length}`}
            </p>
          </div>
          {type === 'Inspection' && (
            <div className="column" style={{ display: 'flex' }}>
              <span onClick={downloadMedia}>
                <img
                  className="action-button"
                  src={download}
                  alt="Single Download"
                />
              </span>
              <span
                alt="All Download"
                className="download-all"
                onClick={handleConfirmDownloadMedia}>
                Download All
                {downloadInspectionMediaRequested && (
                  <FontAwesomeIcon
                    color="##ffffff"
                    icon={faSpinner}
                    spin
                    className="m-l-sm"
                  />
                )}
              </span>
            </div>
          )}

          {/* <div className="column" style={{ display: 'flex' }}>
            <span
              alt="All Download"
              className="download-all"
              onClick={getAllDownloadedMedia}>
              Download All
              {allDownloadLoading && (
                <FontAwesomeIcon
                  color="##ffffff"
                  icon={faSpinner}
                  spin
                  className="m-l-sm"
                />
              )}
            </span>
          </div> */}
          <div className="column">
            <span onClick={handleClose}>
              <img
                className="action-button action-button-close"
                src={closeOutline}
                alt="close"
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        <Slider
          {...settings}
          asNavFor={sliderNav2}
          ref={slider => (slider1 = slider)}>
          {(inspectionFiles || []).map(mediaFile => {
            const { media_id, file_name, type } = mediaFile || {}
            const toLowerType = (type || '').toString().toLowerCase()
            return (
              <div
                key={media_id}
                className="media-container is-flex is-align-items-center">
                {toLowerType === 'photo' && (
                  <img className="main-image" src={file_name} alt="image" />
                )}
                {toLowerType === 'video' && (
                  <video controls>
                    <source key={media_id} src={file_name} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )
          })}
        </Slider>
        <div className="thumbnail-slider-container">
          <Slider
            asNavFor={sliderNav1}
            ref={slider => (slider2 = slider)}
            slidesToShow={12}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}>
            {(inspectionFiles || []).map(mediaFile => {
              const { media_id, file_name, type } = mediaFile || {}
              const toLowerType = (type || '').toString().toLowerCase()
              return (
                <div
                  key={media_id}
                  className="media-thumbnail-container is-flex is-align-items-center">
                  {toLowerType === 'photo' && (
                    <img className="main-image" src={file_name} alt="image" />
                  )}
                  {toLowerType === 'video' && (
                    <video src={file_name} key={media_id} />
                  )}
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

InspectionMediaModal.propTypes = {
  inspectionDownloadMediaURL: PropTypes.string,
  inspectionMediaList: PropTypes.array,
  context: PropTypes.object,
  unit: PropTypes.string
}

export default InspectionMediaModal
