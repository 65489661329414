import React from 'react'
import './index.scss'
const BasicLoading = () => {
  return (
    <div className="basic-loading-wrapper">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    
  )
}


export default BasicLoading
