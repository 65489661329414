import React from 'react'
import {icons} from './icons'

const TurnableLoader = () => {
  return (
    <img src={icons.loader} alt="loader"/>
  )
}

export default TurnableLoader
