export const ServiceColors = {
  Clean: '#0081C3',
  Paint: '#E0E66B',
  Punch: '#55C2B8',
  'CP Clean': '#FF9D52',
  'CP Rep': '#3DB3E2',
  'CR/V Rep': '#AA9CDE',
}

export const NOT_COMPLETED = 'not-completed'
export const INADEQUATE_WORK = 'inadequate-work'
export const BROKEN_ITEM = 'broken-item'

export const GoBackReasonSelectItems = [
  { label: 'Not Completed', value: NOT_COMPLETED },
  { label: 'Inadequate Work', value: INADEQUATE_WORK },
  { label: 'Broken Item', value: BROKEN_ITEM },
]
