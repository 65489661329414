import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useAuth0 } from '../../components/lock/auth0-spa'
import { setAlert } from '../../modules/alerts'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'
import {
  setupAllRolesGet,
  setupAllUsersGet,
  setupOneUserGet,
  setupOneUserCreate,
  setupSingleUserCreate,
  setupSingleUserResetPassword,
  setupOneUserUpdate,
  setupSingleUserUpdate,
  setupOneUserRole,
  setupOneUserDelete,
  setupOneUserBlock,
  toggleProject,
  toggleProperty,
  setUpdating,
  resetUser,
  clearOneUser,
  createOrEditUser,
  newUserDetail,
  clearUser,
  allPropertiesList,
  allRolesList,
  allUsersGet,
  updateUserAccountStatus,
  checkEmailExists,
  resetCheckEmail,
  userAccountDelete,
  updateUserPassword
} from '../../modules/setup-users'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import { setupAllStaffGet } from '../../modules/setup-staff'
import SetupUsersMain from '../../parents/setup-users-main'
import BigLoading from '../../components/big-loading'

const SetupUsers = ({
  user,
  tooltip,
  modal,
  openModal,
  setModalContent,
  closeModal,
  openTooltip,
  setTooltipContent,
  closeTooltip,
  location,
  history,
  auth,
  setAlert,
  setupUsers,
  setupAllRolesGet,
  setupAllUsersGet,
  setupOneUserGet,
  clearOneUser,
  setupOneUserCreate,
  setupSingleUserCreate,
  setupSingleUserResetPassword,
  setupOneUserUpdate,
  setupSingleUserUpdate,
  setupOneUserRole,
  setupOneUserDelete,
  setupOneUserBlock,
  toggleProject,
  toggleProperty,
  setUpdating,
  resetUser,
  type,
  setupVendors,
  setupAllVendorsGet,
  setupAllStaffGet,
  setupStaff,
  context,
  createOrEditUser,
  newUserDetail,
  clearUser,
  allPropertiesList,
  allRolesList,
  allUsersGet,
  updateUserAccountStatus,
  checkEmailExists,
  resetCheckEmail,
  userAccountDelete,
  updateUserPassword
}) => {
  const { isAuthenticated } = useAuth0()

  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return (
      <SetupUsersMain
        isAuthenticated={isAuthenticated && auth.hasMgmtToken}
        user={user}
        tooltip={tooltip}
        modal={modal}
        openModal={openModal}
        setModalContent={setModalContent}
        closeModal={closeModal}
        openTooltip={openTooltip}
        setTooltipContent={setTooltipContent}
        closeTooltip={closeTooltip}
        location={location}
        history={history}
        setupUsers={setupUsers}
        setAlert={setAlert}
        setupAllUsersGet={setupAllUsersGet}
        setupAllRolesGet={setupAllRolesGet}
        setupOneUserGet={setupOneUserGet}
        clearOneUser={clearOneUser}
        setupOneUserCreate={setupOneUserCreate}
        setupSingleUserCreate={setupSingleUserCreate}
        setupSingleUserResetPassword={setupSingleUserResetPassword}
        setupOneUserUpdate={setupOneUserUpdate}
        setupSingleUserUpdate={setupSingleUserUpdate}
        setupOneUserRole={setupOneUserRole}
        setupOneUserDelete={setupOneUserDelete}
        setupOneUserBlock={setupOneUserBlock}
        toggleProject={toggleProject}
        toggleProperty={toggleProperty}
        setUpdating={setUpdating}
        resetUser={resetUser}
        setupAllVendorsGet={setupAllVendorsGet}
        setupVendors={setupVendors}
        setupAllStaffGet={setupAllStaffGet}
        setupStaff={setupStaff}
        type={type}
        context={context}
        createOrEditUser={createOrEditUser}
        newUserDetail={newUserDetail}
        clearUser={clearUser}
        allPropertiesList={allPropertiesList}
        allRolesList={allRolesList}
        allUsersGet={allUsersGet}
        updateUserAccountStatus={updateUserAccountStatus}
        checkEmailExists={checkEmailExists}
        resetCheckEmail={resetCheckEmail}
        userAccountDelete={userAccountDelete}
        updateUserPassword={updateUserPassword}
      />
    )
  }

  return <BigLoading />
}

const mapStateToProps = ({
  user,
  context,
  setupUsers,
  setupVendors,
  setupStaff,
  auth,
  tooltip,
  modal
}) => ({
  user,
  setupUsers,
  auth,
  tooltip,
  modal,
  setupVendors,
  setupStaff,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      setModalContent,
      closeModal,
      openTooltip,
      setTooltipContent,
      closeTooltip,
      setAlert,
      setupAllRolesGet,
      setupAllUsersGet,
      setupOneUserGet,
      clearOneUser,
      setupOneUserCreate,
      setupSingleUserCreate,
      setupSingleUserResetPassword,
      setupOneUserUpdate,
      setupSingleUserUpdate,
      setupOneUserRole,
      setupOneUserDelete,
      setupOneUserBlock,
      toggleProject,
      toggleProperty,
      setUpdating,
      resetUser,
      setupAllVendorsGet,
      setupAllStaffGet,
      createOrEditUser,
      newUserDetail,
      clearUser,
      allPropertiesList,
      allRolesList,
      allUsersGet,
      updateUserAccountStatus,
      checkEmailExists,
      resetCheckEmail,
      userAccountDelete,
      updateUserPassword
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupUsers)
)
