import React from 'react'


const TurnboardCellComponent = ({ row }) => {
  const { cell } = row || {}
  const { value } = cell || {}

  return (
    <>
      <div className="cell-item" title={ value }>
        <span>
          { value }
        </span>
      </div>
    </>
    

  )
}


export default TurnboardCellComponent
