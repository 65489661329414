import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import { isEmpty } from 'lodash'

const DeleteUserModal = props => {
  const {
    serviceId,
    vendorId,
    closeModal,
    user,
    context,
    onHandleForceReload,
    rows,
    tabId,
    indexes,
    onRemoveRows,
    deleteSingleVendorServiceUnitSpacePricing
  } = props || {}
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  const selectedTableRowsLength = useMemo(() => rows?.length, [rows])

  const onPricingDeleteError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onPricingDeleteSuccess = () => {
    setIsLoading(false)
    setErrorMessage('')
    // onHandleForceReload()
    onRemoveRows(indexes, tabId)
    closeModal()
  }

  const handleDelete = () => {
    setIsLoading(true)
    setErrorMessage('')
    const constructData = (rows || [])
      .filter(
        ({ team_service_unit_pricing_id }) =>
          !isEmpty(team_service_unit_pricing_id)
      )
      .map(({ team_service_unit_pricing_id }) => ({
        team_service_unit_pricing_id
      }))

    if (!constructData.length) {
      onRemoveRows(indexes, tabId)
      closeModal()
      return
    }

    const body = {
      items: constructData || []
    }

    deleteSingleVendorServiceUnitSpacePricing(
      pdbid,
      context,
      serviceId,
      vendorId,
      body,
      onPricingDeleteSuccess,
      onPricingDeleteError
    )
  }

  return (
    <div className="confirmation-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Warning</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModal}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full delete-user">
          <p className="is-size-6">
            {`This action cannot be undone and the ${
              selectedTableRowsLength > 1 ? 'rows' : 'row'
            } will need to be manually
            re-added if needed in the future.`}
          </p>
          <p className="is-size-6 sub-message">
            {`Are you sure you want to delete  ${
              selectedTableRowsLength > 1 ? 'these' : 'this'
            } ${selectedTableRowsLength > 1 ? 'rows' : 'row'}?`}
          </p>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <div>
            <p>Something went wrong. Please try again later !!!</p>
          </div>
          <button className="error-button" onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-primary m-r-md"
            onClick={closeModal}>
            Cancel
          </button>
          <button
            className="button main-button is-danger"
            onClick={handleDelete}>
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteUserModal
