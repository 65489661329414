import React, { useEffect, useState } from 'react'

import './index.scss'

const TogglePurchaseOrder = ({ onChange, isActive }) => {
  const [isChecked, setChecked] = useState(isActive || false)

  const handleChange = () => {
    setChecked(!isChecked)
  }

  useEffect(() => {
    onChange(isChecked)
  }, [isChecked])
  return (
    <div>
      <label className="toggle-control" style={{ marginBottom: 12 }}>
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <span className="control"></span>
      </label>
      <span className="active-text">
        {isChecked ? (
          <>
            Charge <span>cannot</span> be added to PO
          </>
        ) : (
          <>
            Charge <span>can</span> be added to PO
          </>
        )}
      </span>
    </div>
  )
}

export default TogglePurchaseOrder
