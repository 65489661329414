import React from 'react'
import { usePermissionGate } from '../../helpers/hooks'

const PermissionGate = ({ children, name }) => {
  const { hasPermission } = usePermissionGate(name)

  return (
      <>
        { hasPermission ? children : null }
      </>
  )
}


export default PermissionGate
