import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const UpdatePasswordMain = props => {
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: ''
  })
  const [errors, setErrors] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [message, setMessage] = useState('')

  const handleChange = ({ target }) => {
    const { value, name } = target || {}
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const checkErrors = ({ target }, fieldName) => {
    const { value, name } = target || {}

    if (!value) {
      setErrors({
        ...errors,
        [name]: `${fieldName || name} is required.`
      })

      return
    }

    if (name === 'password' && value.length < 6) {
      setErrors({
        ...errors,
        [name]: `${fieldName || name} should be at least 6 characters long.`
      })

      return
    }

    if (name === 'confirm_password') {
      const { confirm_password, password } = formData || {}
      if (confirm_password !== password) {
        setErrors({
          ...errors,
          [name]: `${fieldName || name} needs to be same as password.`
        })
      }
      return
    }

    const getVal = {
      attbrName: [name]
    }

    delete errors[getVal.attbrName]
    setErrors(errors)
  }

  const handleUpdate = () => {
    const { name, phone_number, password, confirm_password } = formData || {}

    if (
      name === '' ||
      phone_number === '' ||
      password === '' ||
      confirm_password === ''
    ) {
      setErrors({
        ...errors,
        message: 'You need to fill all the required fields.'
      })
      return
    }

    const { updateProfile, context, user, history } = props || {}
    const { user_id, user_metadata } = user || {}
    const { pdbid, pdbids } = user_metadata || {}

    setSubmitting(true)

    updateProfile({
      context,
      pdbid: pdbid || pdbids[0],
      data: {
        user_id,
        ...formData
      },
      onSuccess: () => {
        setMessage('Success, your profile has been updated')
      },
      onError: error => {
        const { message } = error || {}
        setErrors({
          ...error,
          message: message || 'Something went wrong. Try again later.'
        })
        setSubmitting(false)
      },
      onRedirect: () => {
        return history.push('/')
      }
    })
  }

  const { password, confirm_password } = formData || {}

  return (
    <div className="update-password-wrapper">
      <div className="update-password-container">
        <div className="top-header-section">
          <img
            src="https://img1.wsimg.com/isteam/ip/370b4cbe-a544-4cba-954b-e87e54bc4709/logo/2fabdccd-9195-4f79-9d50-728742dee84f.png"
            alt="logo"
          />
        </div>
        <h2>Update your Password</h2>
        <div className="form-container">
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={event => handleChange(event)}
              name="password"
              placeholder="New Password *"
              onBlur={event => checkErrors(event, 'Password')}
            />
            {errors['password'] && (
              <div className="help-block text-danger">{errors['password']}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              value={confirm_password}
              onChange={event => handleChange(event)}
              onBlur={event => checkErrors(event, 'Confirm Password')}
              name="confirm_password"
              placeholder="Confirm Password *"
            />
            {errors['confirm_password'] && (
              <div className="help-block text-danger">
                {errors['confirm_password']}
              </div>
            )}
          </div>
          <div className="error-wrapper">
            {errors['message'] && (
              <div className="help-block text-danger">{errors['message']}</div>
            )}
          </div>
          <div className="form-group">
            <button
              type="button"
              disabled={isSubmitting}
              onClick={handleUpdate}
              className="button main-button is-primary">
              Update
            </button>
          </div>
          <div className="">
            {message !== '' && (
              <div class="notification is-success">{message}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

UpdatePasswordMain.propTypes = {
  location: PropTypes.object
}

export default UpdatePasswordMain
