import React, { useState, useMemo, useEffect } from 'react'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import HeadingTemplate from '../../components/forms-helper/HeadingTemplate'
import GeneralFieldNoPadding from '../../components/forms-helper/GeneralFieldNoPadding'
import _, { isEmpty } from 'lodash'
import EditIcon from './icons/edit.svg'
import { trash, lock, unlock } from 'ui/icons'

import { useParams } from 'react-router-dom'
import DataTableMenu from '../../components/data-table-menu'
import './index.scss'
import '../../styles/common.scss'
import { checkPermission, sectionManageable } from '../../security'
import SetupStaffManageModalDelete from '../setup-staff-manage-modal-delete'
import BigLoading from '../../components/big-loading'
import EnableUserLogin from '../../components/forms-helper/EnableUserLogin'
import PasswordReset from '../../components/forms-helper/PasswordReset'
import SetupUserReactivateOrInactivateStepperModal from '../setup-user-reactivate-or-inactivate-stepper-modal'

const SetupStaffViewDetail = props => {
  const {
    user,
    context,
    setupUsers,
    history,
    setModalContent,
    openModal,
    closeModal,
    resetCheckEmail,
    newUserDetail,
    clearUser
  } = props
  const params = useParams()

  const [role, setRole] = useState(null)
  const [permissionRule, setPermissionRule] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { pdbid } = user?.user_metadata
  const { userDetail, propertiesList, rolesList } = setupUsers || {}
  const { hasMadeInitialRequest, isRequesting, data: staffUser } =
    userDetail || {}

  const isPropertyManager = useMemo(() => {
    const { roles } = user || {}
    return (roles || []).some(role => role === 'Property Manager')
  }, [user])

  const isPMAndCurrentUserIsLoggedInUser = useMemo(() => {
    return isPropertyManager && user?.email === staffUser?.email
  }, [user, staffUser, isPropertyManager])

  const {
    isCurrentUserRoleIsPropertyManager,
    isCurrentUserRoleIsAccounting
  } = useMemo(() => {
    const isCurrentUserRoleIsPropertyManager = role?.name === 'Property Manager'
    const isCurrentUserRoleIsAccounting = role?.name === 'Accounting'

    return {
      isCurrentUserRoleIsPropertyManager,
      isCurrentUserRoleIsAccounting
    }
  }, [role, params, user, permissionRule])

  const canToggleAccessLogin = useMemo(() => {
    return !(
      isPropertyManager &&
      params.id &&
      (isCurrentUserRoleIsPropertyManager || isCurrentUserRoleIsAccounting)
    )
  }, [
    isPropertyManager,
    isCurrentUserRoleIsPropertyManager,
    isCurrentUserRoleIsAccounting
  ])

  const canResetPassword = useMemo(() => {
    return !(
      isPropertyManager &&
      params.id &&
      (isCurrentUserRoleIsPropertyManager || isCurrentUserRoleIsAccounting)
    )
  }, [
    isPropertyManager,
    isCurrentUserRoleIsPropertyManager,
    isCurrentUserRoleIsAccounting
  ])

  const menuOptions = useMemo(() => {
    const { permission_rule_id, status } = permissionRule || {}

    const options = [
      {
        caption: 'Edit User',
        icon: EditIcon,
        action: () => {
          return history.push(`/setup/staff/user/edit/${params.id}`)
        }
      }
    ]

    if (
      sectionManageable('manage-staff-member', user) &&
      staffUser?.used === 'T' &&
      !isPMAndCurrentUserIsLoggedInUser
    ) {
      options.push({
        caption: !status ? 'Inactivate User' : 'Reactivate User',
        icon: !status ? unlock : lock,
        action: () => {
          showInactivateOrReactivateUserModal(permission_rule_id, status)
        }
      })
    }

    if (
      sectionManageable('manage-staff-member', user) &&
      staffUser?.used === 'F' &&
      !isPMAndCurrentUserIsLoggedInUser
    ) {
      options.push({
        icon: trash,
        caption: 'Delete Staff Member',
        action: () => {
          showDeleteModal(
            staffUser?.user_id,
            permissionRule?.permission_rule_id
          )
        }
      })
    }

    return options
  }, [user, params, permissionRule, isPMAndCurrentUserIsLoggedInUser])

  const hasManagePermission = useMemo(
    () => checkPermission('manage-staff-member', user),
    [user]
  )

  const showDeleteModal = (user_id, permission_rule_id) => {
    setModalContent(() => (
      <SetupStaffManageModalDelete
        {...props}
        user={user}
        context={context}
        staffId={user_id}
        permissionRuleId={permission_rule_id}
        onCancel={closeModal}
        onConfirm={() => {
          closeModal()
          history.push('/setup/staff')
        }}
      />
    ))
    openModal({ width: '500px' })
  }

  const showInactivateOrReactivateUserModal = (permission_rule_id, status) => {
    setModalContent(() => (
      <SetupUserReactivateOrInactivateStepperModal
        {...props}
        user={user}
        context={context}
        permission_rule_id={permission_rule_id}
        status={!status}
        onCancel={closeModal}
        onConfirm={() => {
          setIsLoading(true)
          newUserDetail(pdbid, params.id, onSuccess, onError)
          closeModal()
        }}
      />
    ))
    openModal({ width: '500px' })
  }

  const handleEnableLoginChange = value => {
    const login_access = value ? null : 'false'

    setPermissionRule(prevRule => ({
      ...prevRule,
      login_access
    }))
  }

  const onError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onSuccess = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    newUserDetail(pdbid, params.id, onSuccess, onError)

    return () => {
      clearUser()
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(staffUser)) {
      const mappedPermissionRules = (staffUser?.permission_rules || []).map(
        rule => ({
          action: 'Update',
          internal_permission_rule_id: rule.internal_permission_rule_id,
          login_access: rule.login_access,
          permission_rule_id: rule.permission_rule_id,
          project_id: rule.project?.[0]?.project_id,
          property_id: rule.property_id || rule.project[0]?.property_id,
          role_id: rule.role_id,
          status: rule.status
        })
      )

      const propertyFound = (propertiesList || []).find(
        ({ property_code, project_code }) =>
          property_code === context.property && project_code === context.project
      )

      const foundRule = (mappedPermissionRules || []).find(
        ({ property_id, project_id }) =>
          propertyFound?.property_id === property_id &&
          propertyFound?.project_id === project_id
      )

      const roleFound = (rolesList || []).find(
        role => role.role_id === foundRule?.role_id
      )
      setRole(roleFound)
      setPermissionRule(foundRule)
    }
  }, [staffUser, propertiesList])

  if (errorMessage) {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>{errorMessage}</p>
      </div>
    )
  }

  if ((hasMadeInitialRequest && !isRequesting) || isLoading) {
    return <BigLoading />
  }

  return (
    <div className="columns is-multiline view-staff">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <HeadingTemplate>
            <div className="label-heading column mb-8">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong className="is-size-5">Personal Information</strong>
                <div className="list-container">
                  {hasManagePermission && (
                    <DataTableMenu
                      options={menuOptions}
                      row={{
                        staff_id: params.id
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </HeadingTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Name:">
              <label>{staffUser?.name}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Email Address:">
              <label>{staffUser?.email}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Title:">
              <label>{staffUser?.title}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Type:">
              <label>{staffUser?.type}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Phone:">
              <label>{staffUser?.phone}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Alt Phone:">
              <label>{staffUser?.alt_phone}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Enable Login:">
              <div className="note-container">
                <label>
                  {!permissionRule?.login_access ? 'Enabled' : 'Disabled'}
                </label>
              </div>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Role:">
              <div className="note-container">
                <label>{role?.name}</label>
              </div>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Notes:">
              <div className="note-container">
                <label>{staffUser?.notes}</label>
              </div>
            </GeneralFieldNoPadding>
          </FieldTemplate>

          {hasManagePermission && (
            <div>
              <div className="button-wrapper">
                <button
                  className="button main-button is-primary"
                  onClick={() =>
                    history.push(`/setup/staff/user/edit/${params.id}`)
                  }>
                  Edit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="column is-half animated">
        {!isEmpty(staffUser) && hasManagePermission && (
          <>
            {canToggleAccessLogin && (
              <EnableUserLogin
                onEnableLoginChange={handleEnableLoginChange}
                isStaff={true}
              />
            )}
            {staffUser?.email_verified && canResetPassword ? (
              <PasswordReset />
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

export default SetupStaffViewDetail
