import React from 'react'

const SharedColumnFilter = ({ column,   toggleAllRowsSelected,
  selectedFlatRows }) => {
  const { filterValue, setFilter } = column || {}

  return (
    <select
      style={{ marginTop: '8px' }}
      name="shared"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value)
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }
      }>
      <option value="">All</option>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  )
}

export default SharedColumnFilter
