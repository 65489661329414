import { useCallback, useEffect } from 'react'

const usePageReload = unsavedChanges => {
  const handleBeforeUnload = useCallback(
    e => {
      if (unsavedChanges) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    [unsavedChanges]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [unsavedChanges])
}

export default usePageReload
