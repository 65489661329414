import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faFileImage } from '@fortawesome/free-solid-svg-icons'
import BigLoading from '../../components/big-loading'
import MobileWorkDetailNote from '../../components/mobile-work-detail-note'
import BottomSheet, { useBottomSheet } from '../../components/bottomsheet'
import MobileSumaryOrder from '../../components/mobile-sumary-order'
import { requestInspection } from '../../modules/vendor'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { unitNotesGet, getQCVendorUnitServiceNotes } from '../../modules/notes'
import { done } from '../../ui/icons'
import VendorOrderUnitDetail from './unitDetail'
import { useParams } from 'react-router'
import { setAlert } from '../../modules/alerts'

import './index.scss'

const VendorOrderDetail = ({
  user,
  context,
  vendor,
  notes,
  requestInspection,
  setModalContent,
  openModal,
  closeModal,
  getQCVendorUnitServiceNotes,
  setAlert
}) => {
  const history = useHistory()

  const { job: order, property } = history.location.state
  const { pdbid, vendorid } = user.user_metadata
  const { isRequesting } = vendor
  const [checklistOpen, setChecklistOpen] = useState(false)
  const { ref, show } = useBottomSheet()
  const { id } = useParams()

  useEffect(() => {
    if (pdbid && context) {
      getNotes()
    }
  }, [user, context])

  const getServiceIdFromParams = useMemo(() => {
    const getId = id?.substring(0, id.indexOf('__'))
    return getId || ''
  }, [id])

  const getNotes = () => {
    const [, unitId] = order.workorder.split('__')
    getQCVendorUnitServiceNotes(
      pdbid,
      context,
      unitId,
      getServiceIdFromParams,
      () => {},
      () => {
        setAlert('Something went wrong while fetching notes', 'danger')
      }
    )
  }

  const goBack = () => {
    history.goBack()
  }

  const handleLets = () => {
    closeModal()
    history.push('/vendor/work')
  }

  const handleConfirmComplete = () => {
    openModal({ width: '94%' })
    setModalContent(() => (
      <div className="unit-confirm-complete-dialog">
        <button className="delete" aria-label="close" onClick={closeModal} />
        <div className="subtitle is-4 unit-confirm-complete-dialog-title">
          Mark Complete
        </div>
        <div className="unit-confirm-complete-dialog-subtitle">
          {' '}
          This unit will be immediately assigned to our inspection team for
          approval.{' '}
        </div>
        <div className="unit-confirm-complete-dialog-question">
          {' '}
          Are you ready to mark this unit complete?{' '}
        </div>
        <button
          className="button is-primary is-medium is-fullwidth"
          onClick={onMarkComplete}>
          Confirm
        </button>
      </div>
    ))
  }

  const onMarkComplete = async () => {
    await requestInspection(pdbid, context, vendorid, order)

    openModal({ width: '300px' })

    setModalContent(() => (
      <div className="has-text-centered">
        <img src={done} alt="done" />
        <p className="order-complete-title">Work order complete!</p>
        <button className="button is-primary is-medium" onClick={handleLets}>
          Continue
        </button>
      </div>
    ))
  }

  const handleCheckList = () => {
    show()
    setChecklistOpen(true)
  }

  const handleMapClick = () => {
    const propertyMap = property && property.propmap
    openModal({ width: '100%', backgroundColor: 'transparent' })
    setModalContent(() => (
      <div className="unit-map-dialog">
        <button className="delete" aria-label="close" onClick={closeModal} />
        <figure className="image">
          {propertyMap ? (
            <img src={propertyMap} alt="Unit floorplan" />
          ) : (
            <div className="level is-mobile m-t-lg">
              <div className="level-item has-text-centered">
                <div className="has-text-grey">
                  <FontAwesomeIcon icon={faFileImage} size="2x" />
                  <p className="title is-5 has-text-grey m-t-md">
                    No property image
                  </p>
                </div>
              </div>
            </div>
          )}
        </figure>
      </div>
    ))
  }

  const handleFloorplanClick = () => {
    if (order) {
      const floorplan = order.unit_floorplan
      openModal({ width: '94%' })
      setModalContent(() => (
        <div className="unit-floorplan-dialog">
          <button className="delete" aria-label="close" onClick={closeModal} />
          <div className="subtitle is-4 m-b-sm">{`Unit ${order.unit}`}</div>
          <p className="subtitle is-6">{order.type}</p>
          <figure className="image">
            {floorplan ? (
              <img src={floorplan} alt="Unit floorplan" />
            ) : (
              <div className="level is-mobile m-t-lg">
                <div className="level-item has-text-centered">
                  <div className="has-text-grey">
                    <FontAwesomeIcon icon={faFileImage} size="2x" />
                    <p className="title is-5 has-text-grey m-t-md">
                      No property image
                    </p>
                  </div>
                </div>
              </div>
            )}
          </figure>
        </div>
      ))
    }
  }

  if (isRequesting) {
    return <BigLoading />
  }

  return (
    <div className="vendor-order-detail is-fullheight">
      <div className="vendor-order-detail-header">
        <div className="vendor-order-detail-header-back">
          <FontAwesomeIcon icon={faChevronLeft} size="lg" onClick={goBack} />
        </div>
        <div className="vendor-order-detail-header-title">
          Work Order Details
        </div>
      </div>

      <div className="vendor-order-detail-content">
        {order ? (
          <div className="vendor-order-detail-container">
            <MobileSumaryOrder
              order={order}
              checklistOpen={checklistOpen}
              handleCheckList={handleCheckList}
              handleMapClick={handleMapClick}
              handleFloorplanClick={handleFloorplanClick}
            />

            {notes.isRequesting ? (
              <div className="vendor-order-detail-loading-notes">
                Loading notes...
              </div>
            ) : (
              <MobileWorkDetailNote title="Notes" notes={notes.data} />
            )}

            <div>
              <VendorOrderUnitDetail
                title
                unitspace="Space"
                description="Option"
                status="Status"
              />
              {order.UnitSpaces.map((o, index) => (
                <VendorOrderUnitDetail
                  key={index}
                  unitspace={o.unitspace}
                  description={o.item_option_description}
                  status={o.status}
                />
              ))}
            </div>

            <div className="p-l-md p-r-md p-t-md">
              <button
                className="button is-primary is-medium is-fullwidth"
                onClick={handleConfirmComplete}>
                Mark Unit Complete
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-4 ml-4">Job not found</div>
        )}
      </div>

      <BottomSheet ref={ref} />
    </div>
  )
}

VendorOrderDetail.propTypes = {
  vendor: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  modal: PropTypes.object,
  requestInspection: PropTypes.func,
  unitNotesGet: PropTypes.func,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({ vendor, user, context, notes, modal }) => ({
  vendor,
  user,
  context,
  notes,
  modal
})

const mapDispatchToProps = {
  requestInspection,
  unitNotesGet,
  setModalContent,
  openModal,
  closeModal,
  getQCVendorUnitServiceNotes,
  setAlert
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorOrderDetail)
