import React from 'react'
import UserCell from './components/UserCell'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import SearchDropdownFilterMultiple from './components/SearchDropdownFilter'
import { Link } from 'react-router-dom'
import { capitalize } from '../../utils/helperFunctions'
import UserActionMenu from './components/UserActionMenu'

export const headerData = ({
  SelectColumnFilter,
  user,
  onShowBlockModal,
  onshowDeleteModal
}) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'User ID',
        accessor: 'user_id',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 90,
        Cell: row => {
          const { cell } = row || {}
          const { value } = cell || {}

          return (
            <div className="cell-item" title={value}>
              {value}
            </div>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          const { cell } = row || {}
          const { row: originalRow } = cell || {}
          const { original: currentRow } = originalRow || {}
          const { user_id } = currentRow || {}
          return (
            <div className="cell-item" title={currentRow?.name}>
              <Link
                to={{
                  pathname: `/setup/users/view/${user_id}`,
                  state: {
                    id: currentRow || {}
                  }
                }}
                className="link">
                {capitalize(currentRow?.name || '')}
              </Link>
            </div>
          )
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        textAlign: 'left',
        width: 180,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: 'Role',
        accessor: 'roles',
        textAlign: 'left',
        Filter: SearchDropdownFilterMultiple,
        filter: 'multiEquals',
        width: 120,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: 'Properties',
        accessor: 'simplified_properties',
        textAlign: 'left',
        Filter: SearchDropdownFilterMultiple,
        filter: 'multiEquals',
        width: 300,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: 'Projects',
        accessor: 'simplified_project',
        textAlign: 'left',
        Filter: SearchDropdownFilterMultiple,
        filter: 'multiEquals',
        width: 170,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: 'Last Login',
        accessor: 'last_login',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 180,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlign: 'left',
        Filter: SelectColumnFilter,
        filter: 'equals',
        width: 90,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { status } = original || {}
          const c_status = status === 'false' ? 'Blocked' : 'Active'
          return (
            <div className="cell-item" title={c_status}>
              {status}
            </div>
          )
        }
      },
      {
        Header: '',
        accessor: 'charge_id1',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 40,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          return (
            <UserActionMenu
              userRow={original}
              user={user}
              onShowBlockModal={onShowBlockModal}
              onshowDeleteModal={onshowDeleteModal}
            />
          )
        }
      }
    ]
  }
]
