import { combineReducers } from 'redux'
import charts from './dashboard-charts'
import header from './dashboard-header'
import services from './dashboard-services'
import goals from './dashboard-goals'
import cost from './dashboard-cost'
import map from './dashboard-map'
import turn from './dashboard-turn'

/**
 * Dashboard data module
 * @redux
 * @reduxActionScope dashboard
 * @module dashboard
 */

export default combineReducers({
  charts,
  header,
  services,
  goals,
  cost,
  map,
  turn
})
