import React from 'react'
import './index.scss'

const DataTablePagination = ({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, // available but not used here
  currentPage }) => {


  const getNumberOfPages = (rowCount, rowsPerPage) => {
    return Math.ceil(rowCount / rowsPerPage)
  }


  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1)
  }

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1)
  }

  const handlePageNumber = ({ target }) => {
    const { value } = target || {}
    onChangePage(Number(value))
  }

  const toPages = (pages) => {
    const results = []
  
    for (let i = 1; i <= pages; i++) {
      results.push(i)
    }
  
    return results
  }

  const activeResultCount = () => {
    if(rowsPerPage * currentPage > rowCount) {
      return rowCount
    }
    return rowsPerPage * currentPage
  }
 

  const pages = getNumberOfPages(rowCount, rowsPerPage)

  const pageItems = toPages(pages)

  const nextDisabled = currentPage === pageItems.length
  const previosDisabled = currentPage === 1

  return (
    <div className="pagination-wrapper">
      <div className="pagination-count">
        <p>{`Showing ${currentPage > 1 ? (rowsPerPage * (currentPage - 1)) + 1 : 1 } to ${ pages === 1 ? rowCount : activeResultCount() } of ${rowCount} entries`}</p>
      </div>
      <div className="pages">
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleBackButtonClick}
                disabled={previosDisabled}
                aria-disabled={previosDisabled}
                aria-label="previous page"
              >
            Previous
              </button>
            </li>
            {(pageItems || []).map((page) => {
              const className =
            page === currentPage ? 'page-item active' : 'page-item'

              return (
                <li key={page} className={className}>
                  <button
                    className="page-link"
                    onClick={handlePageNumber}
                    value={page}
                  >
                    {page}
                  </button>
                </li>
              )
            })}
            <li className="page-item">
              <button
                className="page-link"
                onClick={handleNextButtonClick}
                disabled={nextDisabled}
                aria-disabled={nextDisabled}
                aria-label="next page"
              >
            Next Page
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}


export default DataTablePagination
