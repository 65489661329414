import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  setupServicesGet,
  setupAllGoalsGet,
  setupGoalLineCreate,
  setupGoalLineUpdate
} from '../../modules/setup-goals'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import { openModal, closeModal, setModalContent } from '../../modules/modal'
import {
  openTooltip,
  closeTooltip,
  setTooltipContent
} from '../../modules/tooltip'
import SetupGoalsMain from '../../parents/setup-goals-main'
import BigLoading from '../../components/big-loading'

const SetupGoals = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <SetupGoalsMain {...props} />
  }

  return <BigLoading />
}

SetupGoals.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupGoals: PropTypes.object
}

const mapStateToProps = ({
  user,
  context,
  modal,
  tooltip,
  setupVendors,
  setupGoals
}) => ({
  user,
  context,
  modal,
  tooltip,
  setupVendors,
  setupGoals
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setupAllVendorsGet,
      setupServicesGet,
      setupAllGoalsGet,
      setupGoalLineCreate,
      setupGoalLineUpdate,
      openModal,
      closeModal,
      setModalContent,
      openTooltip,
      closeTooltip,
      setTooltipContent
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupGoals)
)
