import React, { useState, useEffect, useCallback, useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { headerData } from './header'
import DataTableComponent from '../../components/data-table-component'
import { closeFilled } from 'ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import BasicLoading from '../../components/basic-loading'
import CustomTable from './table'
import { icons } from './icons'

import './index.scss'
import { isEmpty } from 'lodash'

const defaultStyles = {
  table: {
    style: {
      borderWidth: '1px',
      borderColor: '#e5e9f2',
      borderStyle: 'solid',
      borderTopWidth: '0px',
      borderBottomWidth: '0px'
    }
  },
  headCells: {
    style: {
      color: '#122048',
      fontFamily: 'Avenir',
      fontSize: '10px',
      fontWeight: '700'
    }
  },
  headRow: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: '#e5e9f2',
      borderBottomStyle: 'solid',
      height: '46px',
      //paddingLeft: '12px',
      //paddingRight: '12px',
      backgroundColor: '#CED4DA'
    }
  },
  cells: {
    style: {
      fontSize: '9px',
      fontWeight: '400',
      fontFamily: 'Avenir',
      color: '#122048',
      height: '45px'
    }
  },
  rows: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: '#e5e9f2',
      borderBottomStyle: 'solid'
    }
  }
}

const TurnboardServiceOptionChangesModal = props => {
  const {
    onClose,
    units,
    allService,
    selectedUnitIds = [],
    isFilterApplied,
    updateServiceOptions,
    user,
    context,
    reCallTurnboardData
  } = props || {}
  const [changedServiceOptions, setChangedServiceOptions] = useState([])
  const [oneTimeServiceOptions, setOneTimeServiceOptions] = useState([])
  const [allServiceRadioOptions, setAllServiceRadioOptions] = useState([])
  const [selectedService, setSelectedService] = useState([])
  const [unitData, setUnitData] = useState([])
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isDataSaving, setIsDataSaving] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [notAvailableData, setNotAvailableData] = useState([])
  const [userUpdatedData, setUserUpdatedData] = useState([])
  const [isAllNotAvailableRadio, setIsAllNotAvailableRadio] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  // Icons
  const { close } = icons || {}

  const currentLoginUserRole = useMemo(() => {
    const { roles } = user || {}
    if ((roles || []).length !== 0) {
      if (roles?.[0] === 'Administrator' || roles?.[0] === 'Property Manager') {
        return true
      }
      return false
    }
    return false
  }, [user])

  const onHandleServiceChange = (value, serviceType) => {
    const { task_id, item_option_id, unit, unit_space, unit_space_id } =
      value || {}
    value.new = true
    setIsAllNotAvailableRadio(false)
    ;(allServiceRadioOptions || []).map(serviceRadio => {
      const { serviceTag } = serviceRadio || {}
      if (serviceTag === serviceType) {
        serviceRadio.check = false
      }
      return serviceRadio
    })
    let updateNotAvailableData = [...(notAvailableData || {})]
    let copyChangeServiceOptionData = []
    let checkNotAvailableService = false
    if ((oneTimeServiceOptions || []).length !== 0) {
      copyChangeServiceOptionData = [...changedServiceOptions]
      const getExistingTaskId = (copyChangeServiceOptionData || []).filter(
        option =>
          option.unit === unit &&
          option.unit_space === unit_space &&
          option.item_option_id === item_option_id
      )
      if ((getExistingTaskId || []).length !== 0) {
        console.log('first')
        const removeExistingTaskId = (copyChangeServiceOptionData || []).filter(
          option =>
            !(
              option.unit === unit &&
              option.unit_space === unit_space &&
              option.item_option_id === item_option_id
            )
        )
        setChangedServiceOptions(removeExistingTaskId)
        if (task_id) {
          const data = {
            task_id,
            unit_space_id: null,
            item_option_id: item_option_id,
            deleted: true,
            unit,
            unit_space
          }
          setUserUpdatedData(prevData => [...prevData, data])
        } else {
          setUserUpdatedData(prevData =>
            (prevData || []).filter(
              d =>
                !(
                  d.item_option_id === item_option_id &&
                  d.unit === unit &&
                  d.unit_space === unit_space
                )
            )
          )
        }
        if (
          (removeExistingTaskId || []).filter(
            option => option.unit === unit && option.unit_space === unit_space
          ).length === 0
        ) {
          checkNotAvailableService = true
        }
      } else {
        console.log('second')
        if (task_id) {
          const removeService = (userUpdatedData || []).filter(
            d => d.task_id !== task_id
          )
          setUserUpdatedData(removeService)
        } else {
          // For adding object in user updated data for API call
          const data = {
            task_id: null,
            unit_space_id,
            item_option_id,
            unit,
            unit_space
          }
          setUserUpdatedData(prevData => [...prevData, data])
        }
        setChangedServiceOptions([...copyChangeServiceOptionData, value])
      }
    } else {
      console.log('third')
      copyChangeServiceOptionData = [value]
      //setChangedServiceOptions([value])
      setChangedServiceOptions(prevData => [...prevData, value])
      // For adding object in user updated data for API call
      const data = {
        task_id: null,
        unit_space_id,
        item_option_id,
        unit,
        unit_space
      }
      setUserUpdatedData(prevData => [...prevData, data])
    }
    const checkIfNotAvailableExists = (updateNotAvailableData || []).find(
      d => d.unit === unit && d.unit_space === unit_space
    )
    if (checkIfNotAvailableExists) {
      updateNotAvailableData = (updateNotAvailableData || []).filter(
        d => !(d.unit === unit && d.unit_space === unit_space)
      )
      setNotAvailableData(updateNotAvailableData)
    }
    if (checkNotAvailableService) {
      const newData = {
        task_id: undefined,
        item_option_id: item_option_id,
        deleted: true,
        new: true,
        unit,
        unit_space
      }
      updateNotAvailableData = [...updateNotAvailableData, newData]
      setNotAvailableData(updateNotAvailableData)
    }
    setIsDataUpdated(true)
  }

  const onHandleNotAvailableService = (unit, unit_space) => {
    setIsAllNotAvailableRadio(false)
    const isAllServiceRadioChecked = (allServiceRadioOptions || []).find(
      d => d.check === true
    )
    if (isAllServiceRadioChecked) {
      const updateData = (allServiceRadioOptions || []).map(serviceRadio => {
        serviceRadio.check = false
        return serviceRadio
      })
      setAllServiceRadioOptions(updateData)
    }
    const checkIfNewRecordExists = (notAvailableData || []).find(
      d => d.unit === unit && d.unit_space === unit_space
    )
    if (!checkIfNewRecordExists) {
      const newData = {
        task_id: undefined,
        deleted: true,
        item_option_id: 'Pass',
        new: true,
        unit,
        unit_space
      }
      setNotAvailableData(prevData => [...prevData, newData])
      setChangedServiceOptions(prevData =>
        (prevData || []).filter(
          d => !(d.unit === unit && d.unit_space === unit_space)
        )
      )
      const getPrevSelectedServices = (oneTimeServiceOptions || [])
        .filter(
          d => d.unit === unit && d.unit_space === unit_space && d.task_id
        )
        .map(d => {
          delete d.new
          return {
            ...d,
            item_option_id: 'Pass',
            unit_space_id: null,
            deleted: true
          }
        })
      setUserUpdatedData(prevData => {
        const removeUserSelectedService = (prevData || []).filter(
          d => !(d.unit === unit && d.unit_space === unit_space)
        )
        return [
          ...(removeUserSelectedService || {}),
          ...(getPrevSelectedServices || {})
        ]
      })
    }
    setIsDataUpdated(true)
  }

  const onHandleChangeSelectedService = e => {
    const { target } = e || {}
    const { value } = target || {}
    const getSelectedService = (allService || []).filter(
      ser => ser.servicetype === value
    )
    if ((getSelectedService || []).length !== 0) {
      setSelectedService(getSelectedService)
    } else {
      setSelectedService([])
    }
    setChangedServiceOptions([])
    setAllServiceRadioOptions([])
    setIsAllNotAvailableRadio(false)
    setIsDataUpdated(false)
  }

  const onChangeAllRadioButton = (serviceName, name, option_id) => {
    let unitData = units || []
    if ((selectedUnitIds || []).length !== 0 || isFilterApplied) {
      const filterSelectedData = (selectedUnitIds || []).map(
        d => `${d.unit}_${d.unit_space}_${d.unit_id}`
      )
      unitData = (units || []).filter(d =>
        (filterSelectedData || []).includes(
          `${d.unit}_${d.unit_space}_${d.unit_id}`
        )
      )
    }
    let updateData = changedServiceOptions || []
    let removeAllRow = false
    setIsAllNotAvailableRadio(false)
    ;(allServiceRadioOptions || []).map(d => {
      const { type, check, serviceTag } = d || {}
      // Checked the header table radio option
      if (serviceTag === serviceName) {
        d.check = false
        if (type === `${serviceName}_${name}_${option_id}`) {
          d.check = true
          removeAllRow = false
        }
      }
      // if click on button which is already checked then remove all selected data for that particular column
      if (type === `${serviceName}_${name}_${option_id}` && check === true) {
        d.check = false
        removeAllRow = true
        updateData = (updateData || []).filter(
          uData => uData.item_option_id !== option_id
        )
        return
      }
      return d
    })
    if (!removeAllRow) {
      const data = (unitData || [])
        .map(allUnit => {
          const { [`${serviceName}_task_id`]: taskId, unit, unit_space } =
            allUnit || {}
          return {
            task_id: taskId,
            item_option_id: option_id,
            new: true,
            unit,
            unit_space
          }
        })
        .filter(n => n !== undefined)
      if ((updateData || []).length !== 0) {
        setChangedServiceOptions([...data, ...updateData])
      } else {
        setChangedServiceOptions(data)
      }
      // Filter to get those units where service exists
      const { servicetype } = selectedService[0] || {}
      const filterData = (unitData || []).flatMap(d => {
        const { unit, unit_space, unit_space_id, services } = d || {}
        let extractedServiceData = []
        const getFilteredServices = (services || []).filter(
          s => s.service === servicetype
        )
        ;(getFilteredServices || []).map(d => {
          const { option, task_id } = d || {}
          const optionName = (option || '')
            .toString()
            .replace(/ /g, '_')
            .toLowerCase()
          extractedServiceData.push({
            [`${optionName}_option`]: option,
            [`${optionName}_taskId`]: task_id
          })
        })
        let body = {
          unit,
          unit_space,
          unit_space_id
        }
        const result = Object.assign({}, body, ...(extractedServiceData || {}))
        // Return only required data
        return result
      })
      const optionName = (name || '')
        .toString()
        .replace(/ /g, '_')
        .toLowerCase()
      const specificAllSelectedService = (filterData || [])
        .filter(d => d[`${optionName}_option`] !== name)
        .map(d => {
          const { unit, unit_space, unit_space_id } = d || {}
          return {
            item_option_id: option_id,
            task_id: null,
            unit,
            unit_space,
            unit_space_id
          }
        })
      const defaultSelectedService = (filterData || [])
        .filter(d => d[`${optionName}_option`] === name)
        .map(d => {
          const { unit, unit_space, unit_space_id } = d || {}
          return {
            item_option_id: option_id,
            task_id: null,
            unit,
            unit_space,
            unit_space_id
          }
        })
      // Check if service is already inserted in user updated data
      const getUniqueDataIfReselect = (userUpdatedData || []).map(d => {
        const { item_option_id, unit, unit_space } = d || {}
        return `${item_option_id}_${unit}_${unit_space}`
      })
      console.log(getUniqueDataIfReselect)
      // Check if service is selected by default
      const getdefaultServices = (defaultSelectedService || []).map(d => {
        const { item_option_id, unit, unit_space } = d || {}
        return `${item_option_id}_${unit}_${unit_space}`
      })
      console.log(getUniqueDataIfReselect)
      // Get which services are not included in user updated data
      const getUniqueServices = (specificAllSelectedService || []).filter(
        d =>
          !(getUniqueDataIfReselect || []).includes(
            `${d.item_option_id}_${d.unit}_${d.unit_space}`
          )
      )
      if ((getUniqueServices || []).length !== 0) {
        //setUserUpdatedData(prevData => [...prevData, ...(getUniqueServices || {})])
        setUserUpdatedData(prevData => {
          const filterPrevData = (prevData || []).filter(
            d =>
              !(getdefaultServices || []).includes(
                `${d.item_option_id}_${d.unit}_${d.unit_space}`
              )
          )
          return [...(filterPrevData || {}), ...(getUniqueServices || {})]
        })
      }
      setNotAvailableData([])
    } else {
      // Filter to get those units where service exists
      const { servicetype } = selectedService[0] || {}
      const filterData = (unitData || []).flatMap(d => {
        const { unit, unit_space, unit_space_id, services } = d || {}
        let extractedServiceData = []
        const getFilteredServices = (services || []).filter(
          s => s.service === servicetype
        )
        ;(getFilteredServices || []).map(d => {
          const { option, task_id } = d || {}
          const optionName = (option || '')
            .toString()
            .replace(/ /g, '_')
            .toLowerCase()
          extractedServiceData.push({
            [`${optionName}_option`]: option,
            [`${optionName}_taskId`]: task_id
          })
        })
        let body = {
          unit,
          unit_space,
          unit_space_id
        }
        const result = Object.assign({}, body, ...(extractedServiceData || {}))
        // Return only required data
        return result
      })
      const optionName = (name || '')
        .toString()
        .replace(/ /g, '_')
        .toLowerCase()
      const specificAllUserSelectedService = (filterData || [])
        .filter(d => d[`${optionName}_option`] !== name)
        .map(d => {
          const { unit, unit_space, unit_space_id } = d || {}
          return {
            item_option_id: option_id,
            task_id: null,
            unit,
            unit_space,
            unit_space_id
          }
        })
      const specificAllDefaultSelectedService = (filterData || [])
        .filter(d => d[`${optionName}_option`] === name)
        .map(d => {
          const { unit, unit_space, [`${optionName}_taskId`]: task_id } =
            d || {}
          return {
            item_option_id: option_id,
            deleted: true,
            task_id,
            unit,
            unit_space,
            unit_space_id: null
          }
        })
      const getUniqueDataIfReselect = (
        specificAllUserSelectedService || []
      ).map(d => {
        const { item_option_id, unit, unit_space } = d || {}
        return `${item_option_id}_${unit}_${unit_space}`
      })
      // Get which services are not included in user updated data
      const getUniqueServices = (userUpdatedData || []).filter(
        d =>
          !(getUniqueDataIfReselect || []).includes(
            `${d.item_option_id}_${d.unit}_${d.unit_space}`
          )
      )
      const mergeData = [
        ...(getUniqueServices || {}),
        ...(specificAllDefaultSelectedService || {})
      ]
      setUserUpdatedData(mergeData)
      setChangedServiceOptions(updateData)
      const getSelectedServices = (updateData || []).map(
        d => `${d.unit}_${d.unit_space}`
      )
      const filterUnSelectedServices = (unitData || [])
        .filter(
          d =>
            !(getSelectedServices || []).includes(`${d.unit}_${d.unit_space}`)
        )
        .map(d => {
          const { unit, unit_space, unit_space_id } = d || {}
          return {
            item_option_id: null,
            new: false,
            task_id: undefined,
            unit,
            unit_space,
            unit_space_id
          }
        })
      setNotAvailableData(filterUnSelectedServices)
    }
    setIsDataUpdated(true)
  }

  const onChangeNAAllRadioButton = () => {
    setIsAllNotAvailableRadio(true)
    const isAllServiceRadioChecked = (allServiceRadioOptions || []).find(
      d => d.check === true
    )
    if (isAllServiceRadioChecked) {
      const updateData = (allServiceRadioOptions || []).map(serviceRadio => {
        serviceRadio.check = false
        return serviceRadio
      })
      setAllServiceRadioOptions(updateData)
    }
    const getPrevSelectedServices = (oneTimeServiceOptions || []).map(d => {
      delete d.new
      return {
        ...d,
        deleted: true,
        unit_space_id: null
      }
    })
    setChangedServiceOptions([])
    setUserUpdatedData(getPrevSelectedServices)
    // For setting all available services to N/A
    const filterData = getDataWhereServicesExists() || []
    const notAvailableServiceData = (filterData || []).flatMap(ser => {
      const { unit, unit_space, unit_space_id } = ser || {}
      let data = []
      data.push({
        task_id: undefined,
        item_option_id: null,
        new: false,
        unit,
        unit_space,
        unit_space_id
      })
      return data
    })
    setNotAvailableData(notAvailableServiceData)
    setIsDataUpdated(true)
  }

  const onSuccess = () => {
    setIsDataSaving(false)
    setIsDataUpdated(false)
    setErrorMessage('')
    onClose()
    reCallTurnboardData()
    console.log('success')
  }

  const onError = () => {
    setIsDataSaving(false)
    setErrorMessage('Something went wrong, Please try again !!!')
    console.log('error')
  }

  const onSave = () => {
    const filterData = (userUpdatedData || []).map(d => {
      if (d.deleted) {
        d.item_option_id = 'Pass'
        delete d.deleted
      }
      return d
    })
    setIsDataSaving(true)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const body = {
      items: filterData || []
    }
    updateServiceOptions({
      pdbid,
      context,
      body,
      onSuccess,
      onError
    })
  }

  const getDataWhereServicesExists = () => {
    const { servicetype } = selectedService[0]
    let data = units || []

    const serviceName = (servicetype || '')
      .toString()
      .replace(' ', '_')
      .toLowerCase()

    // For getting selected unit(s)
    if ((selectedUnitIds || []).length !== 0 || isFilterApplied) {
      const filterSelectedData = (selectedUnitIds || []).map(
        d => `${d.unit}_${d.unit_space}_${d.unit_id}`
      )
      data = (units || []).filter(
        d =>
          (filterSelectedData || []).includes(
            `${d.unit}_${d.unit_space}_${d.unit_id}`
          ) && isEmpty(d[`${serviceName}_inhouse_id`])
      )
    }
    // Filter to get those units where service exists
    const filterData = (data || []).flatMap(d => {
      const { unit, unit_space, building, floor, unit_space_id, services } =
        d || {}
      let extractedServiceData = []
      const getFilteredServices = (services || []).filter(
        s => s.service === servicetype
      )
      ;(getFilteredServices || []).map(d => {
        const { option, task_id } = d || {}
        const optionName = (option || '')
          .toString()
          .replace(/ /g, '_')
          .toLowerCase()
        extractedServiceData.push({
          [`${optionName}_option`]: option,
          [`${optionName}_taskId`]: task_id
        })
      })
      let body = {
        unit,
        unit_space,
        building,
        floor,
        unit_space_id
      }
      if ((getFilteredServices || []).length === 0) {
        body.isServiceAvailable = false
      } else {
        body.isServiceAvailable = true
      }
      const result = Object.assign({}, body, ...(extractedServiceData || {}))
      // Return only required data
      return result
    })
    return filterData
  }

  useEffect(() => {
    if ((selectedService || []).length !== 0) {
      const { services_options } = selectedService[0]
      const filterData = getDataWhereServicesExists() || []
      // For getting default selected service of a unit space
      const defaultSelectedServices = (filterData || []).flatMap(ser => {
        const { unit, unit_space, unit_space_id } = ser || {}
        let data = []
        const nnn = (services_options || []).map(d => {
          const { item_option_long, item_option_id } = d || {}
          const itemOptionName = (item_option_long || '')
            .toString()
            .replace(/ /g, '_')
            .toLowerCase()
          if (ser[`${itemOptionName}_option`] === item_option_long) {
            data.push({
              task_id: ser[`${itemOptionName}_taskId`],
              item_option_id: item_option_id,
              new: false,
              unit,
              unit_space,
              unit_space_id
            })
          }
        })
        return data
      })
      setChangedServiceOptions(defaultSelectedServices)
      // To always have an untouched default selected services
      setOneTimeServiceOptions(defaultSelectedServices)
      // For setting not available service data of a unit space
      const notAvailableServiceData = (filterData || []).flatMap(ser => {
        const { taskId, unit, unit_space, unit_space_id, isServiceAvailable } =
          ser || {}
        let data = []
        if (!isServiceAvailable) {
          data.push({
            task_id: taskId,
            item_option_id: null,
            new: false,
            unit,
            unit_space,
            unit_space_id
          })
        }
        return data
      })
      setNotAvailableData(notAvailableServiceData)
      setUnitData(filterData || [])
    }
  }, [selectedService])

  // Reason for this:
  // b/c we are not calling an api or redux function to get data.
  // We are getting data from parent coponent thats why loading is almost instant,
  // but data render in table component take time and in that time screen is freeze
  useEffect(() => {
    if (dataLoading) {
      const loadingTimeOut = setTimeout(() => {
        setDataLoading(false)
      }, 300)
      return () => clearTimeout(loadingTimeOut)
    }
  }, [dataLoading])

  useEffect(() => {
    if ((selectedService || []).length !== 0) {
      const currentService = (selectedService || []).flatMap(service => {
        const { servicetype, services_options } = service || {}
        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        let newData = []
        ;(services_options || []).map(option => {
          const { item_option, item_option_id } = option || {}
          newData.push({
            check: false,
            type: `${serviceName}_${item_option}_${item_option_id}`,
            serviceTag: serviceName
          })
          return newData
        })
        return newData
      })
      setAllServiceRadioOptions(currentService)
    }
  }, [selectedService])

  const memorizedTableData = useMemo(() => unitData, [unitData])

  const getChangedServiceOptionsId = useMemo(
    () =>
      (changedServiceOptions || []).map(
        s => `${s.unit}_${s.item_option_id}_${s.unit_space}`
      ),
    [changedServiceOptions]
  )
  const getNotAvailableServices = useMemo(
    () => (notAvailableData || []).map(s => `${s.unit}_${s.unit_space}`),
    [notAvailableData]
  )
  const header = headerData({
    services: selectedService,
    serviceOptions: onHandleServiceChange,
    changedServiceOptionsId: getChangedServiceOptionsId,
    notAvailableServiceData: getNotAvailableServices,
    notAvailableServiceOptions: onHandleNotAvailableService,
    isAllNotAvailableRadio,
    allServiceRadioOptions,
    onChangeAllRadioButton,
    onChangeNAAllRadioButton,
    currentLoginUserRole
  })

  return (
    <div>
      {isConfirmationOpen && (
        <div className="confirmation-container">
          <p
            onClick={() => setIsConfirmationOpen(false)}
            className="close is-pointer">
            <img alt="Close Modal" src={close} />
          </p>
          <h2>Change Service Options</h2>
          <div className="content">
            <p>
              It looks like you’ve changed at least one service option! Once you
              confirm, the current options will be changed to the ones you’ve
              selected.
            </p>
            <p>
              <span>Note: </span>There is no automated “undo.” Any change backs
              will have to be done manually.
            </p>
          </div>
          <span className="helper-text">Would you like to continue?</span>
          <div className="columns is-vcentered m-b-sm m-t-sm">
            <div className="center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => setIsConfirmationOpen(false)}>
                Cancel
              </button>
              <button
                onClick={() => {
                  setIsConfirmationOpen(false)
                  onSave()
                }}
                className="button main-button is-primary">
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`turnboard-change-service-options-modal ${
          isConfirmationOpen ? 'disable-content' : ''
        }`}>
        <div className="is-desktop is-mobile">
          <div className="is-full">
            <h2 className="modal-sync-title">Change Service Options</h2>
          </div>
        </div>
        <div className="service-selection">
          <label htmlFor="service-select">Select Service:</label>
          <select
            onChange={e => {
              e.preventDefault()
              setDataLoading(true)
              onHandleChangeSelectedService(e)
            }}
            className="input is-inline">
            <option>Select</option>
            {(allService || []).map(service => {
              const { servicetype } = service || {}
              return (
                <option key={servicetype} value={servicetype}>
                  {servicetype}
                </option>
              )
            })}
          </select>
        </div>
        {errorMessage !== '' && (
          <div className="notification is-danger is-light is-flex error-section">
            <p>{errorMessage}</p>
            <button onClick={() => setErrorMessage('')}>
              <img src={closeFilled} alt="" />
            </button>
          </div>
        )}
        {dataLoading ? (
          <BasicLoading />
        ) : (
          <>
            <div className="turnboard-container">
              {(selectedService || []).length !== 0 && (
                <>
                  {(unitData || []).length === 0 ||
                  (selectedService[0]?.services_options || []).length === 0 ? (
                    <p className="no-service-data">
                      There are no service options associated with this service
                      at the moment.
                    </p>
                  ) : (
                    <div className="turnboard-table-wrapper">
                      {(header || []).map((item, index) => {
                        const { title, backgroundColor, columns, color } =
                          item || {}
                        const defaultHeaderStyle = {
                          backgroundColor,
                          color,
                          borderRight: index !== 1 ? '1px solid #122048' : ''
                        }
                        return (
                          <div
                            className={`turnboard-item-wrapper ${
                              index === 0 ? 'unit-table' : ''
                            } ${index === 1 ? 'na-table' : ''} ${
                              index === 2 ? 'service-table' : ''
                            }`}
                            key={index}>
                            <div
                              className="turnboard-item-title"
                              style={defaultHeaderStyle}>
                              {title}
                            </div>
                            <div
                              className={`data-table-container ${
                                index === 0 ? 'unit-table' : ''
                              }`}>
                              <CustomTable
                                columns={columns}
                                memorizedTableData={memorizedTableData || []}
                                defaultStyles={defaultStyles}
                                dataLoading={dataLoading}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="columns is-vcentered m-b-sm m-t-sm">
              <div className="center-container">
                <button
                  disabled={isDataSaving}
                  className="button main-button is-secondary m-r-md"
                  onClick={e => {
                    e.preventDefault()
                    onClose()
                    setIsConfirmationOpen(false)
                  }}>
                  Cancel
                </button>
                <button
                  disabled={!isDataUpdated || isDataSaving}
                  className="button main-button is-primary"
                  onClick={e => {
                    e.preventDefault()
                    setErrorMessage('')
                    setIsConfirmationOpen(true)
                  }}>
                  {isDataSaving ? 'Saving' : 'Save'}
                  {isDataSaving && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      color="#ffffff"
                      className="m-l-sm"
                    />
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default memo(TurnboardServiceOptionChangesModal)
