import { combineReducers } from 'redux'
import allProperties from './setup-properties-all'
import oneProperty from './setup-properties-one'

/**
 * Setup properties data module
 * @redux
 * @reduxActionScope setup-properties
 * @module setup-properties
 */

export default combineReducers({
  allProperties,
  oneProperty
})
