import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { usePrevious } from '../../helpers/hooks'
import { setupIcons } from '../setup-vendors-main/setup-icons'
import Paginator from '../../components/paginator'
import { icons } from '../setup-staff-main/icons'
import './index.scss'

const HoldoversListOccupied = ({
  context,
  user,
  getHoldoversList,
  holdovers
}) => {
  const resultsPerPage = 10 // TODO: Check this
  const [currentPage, setCurrentPage] = useState(1)
  const [filterStr, setFilterStr] = useState('')
  const prevContext = usePrevious(context)

  useEffect(() => {
    const { pdbid } = user.user_metadata
    if (
      !holdovers.occupied.hasMadeInitialRequest ||
      (prevContext && !_.isEqual(context, prevContext))
    )
      getHoldoversList(pdbid, context)
  }, [context])

  const getEmptyState = () => {
    return (
      <div className="columns">
        <div className="column has-text-centered">
          <img src={icons.emptyPage} alt="Empty Page Icon" />
          <p className="is-size-5 m-t-md">
            Looks like you don't have any holdovers reported!
          </p>
        </div>
      </div>
    )
  }

  const getFilteredHoldovers = () => {
    const { list } = holdovers.occupied
    const re = new RegExp(filterStr, 'i')
    return list.filter(
      i =>
        re.test(i.unit) ||
        re.test(i.unitspace) ||
        re.test(i.resident) ||
        re.test(i.email)
    )
  }

  const exportCSV = () => {
    const { properties, projects } = user.user_metadata
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const list = holdovers.occupied.list.map(ho => ({
      unit: ho.unit,
      unitspace: ho.unitspace,
      resident: ho.resident,
      holdover_date: ho.holdover_date,
      returned_date: ho.returned_date,
      email: ho.email
    }))
    const keys = Object.keys(list[0])
    const csvData = [keys]
      .concat(list.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:text/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-staff-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getHoldoversTable = () => {
    const filteredHoldovers = getFilteredHoldovers()

    const startingIndex = (currentPage - 1) * resultsPerPage
    const endingIndex = Math.min(
      currentPage * resultsPerPage,
      filteredHoldovers.length
    )
    const dataVisible = filteredHoldovers.slice(startingIndex, endingIndex)
    let template = []
    template.push(
      <div
        className="columns has-text-weight-bold"
        style={{
          borderBottom: '2px solid #eef1f6',
          borderTop: '2px solid #eef1f6'
        }}
        key="staff-list-header">
        <div className="column is-narrow" style={{ width: '75px' }}>
          Unit
        </div>
        <div className="column is-narrow" style={{ width: '75px' }}>
          Bed
        </div>
        <div className="column">Resident Name</div>
        <div className="column">Email</div>
        <div className="column">Holdover Date (CST)</div>
        <div className="column">Returned Date (CST)</div>
        {/*<div className="column is-narrow" style={{ width: '75px' }}></div>*/}
      </div>
    )
    for (let i = 0; i < dataVisible.length; i += 1) {
      const holdover = dataVisible[i]
      template.push(
        <div
          className="columns is-vcentered"
          key={`setup-staff-${i}`}
          style={{
            borderBottom: '2px solid #eef1f6'
          }}>
          <div className="column is-narrow" style={{ width: '75px' }}>
            {holdover.unit}
          </div>
          <div className="column is-narrow" style={{ width: '75px' }}>
            {holdover.unitspace}
          </div>
          <div className="column">{holdover.resident}</div>
          <div className="column truncated-label">
            <a href={`mailto:${holdover.email}`} title={holdover.email}>
              {holdover.email}
            </a>
          </div>
          <div className="column">{holdover.holdover_date}</div>
          <div className="column">{holdover.returned_date}</div>
          {/*<div className="column is-narrow" style={{ width: '75px' }}>
            {holdover.status.toLowerCase() === 'holdover' && (
              <Link to="/keys">Return Key</Link>
            )}
            </div>*/}
        </div>
      )
    }
    return template
  }

  const { occupied } = holdovers
  const { list } = occupied
  if (list.length < 1) {
    return getEmptyState()
  }
  const filteredHoldovers = getFilteredHoldovers()
  return (
    <div className="columns animated fadeIn">
      <div className="column">
        <div className="box is-shadowless stackable-box">
          <p className="is-size-5 m-b-md">Holdovers</p>
          <div className="columns is-vcentered is-hidden-touch m-b-none">
            <div className="column is-one-quarter p-b-none">
              <input
                className="input"
                type="text"
                placeholder="Search"
                value={filterStr}
                onChange={e => {
                  setFilterStr(e.target.value)
                }}
              />
            </div>
            <div className="column is-one-quarter p-b-none">
              <img
                src={setupIcons.exportFile}
                onClick={() => exportCSV()}
                className="is-pointer"
                alt="Export Holdovers List"
              />
            </div>
          </div>
          <div className="columns is-vcentered is-multiline is-mobile is-hidden-desktop">
            <div className="column is-full">
              <input
                className="input"
                type="text"
                placeholder="Search"
                value={filterStr}
                onChange={e => {
                  setFilterStr(e.target.value)
                }}
              />
            </div>
            <div className="column is-half">
              <img
                src={setupIcons.exportFile}
                onClick={() => exportCSV()}
                className="is-pointer"
                alt="Export Holdovers"
              />
            </div>
          </div>
        </div>
        <div
          className="box is-shadowless stackable-box p-t-none p-b-none"
          style={{
            paddingRight: 10,
            paddingLeft: 10
          }}>
          {getHoldoversTable()}
        </div>
        <div className="box is-shadowless stackable-box is-paddingless height-40">
          <Paginator
            count={filteredHoldovers.length}
            limit={resultsPerPage}
            page={currentPage}
            action={page => {
              setCurrentPage(page)
            }}
          />
        </div>
      </div>
    </div>
  )
}

HoldoversListOccupied.propTypes = {
  user: PropTypes.object,
  setupStaff: PropTypes.object,
  setupAllStaffGet: PropTypes.func
}

export default HoldoversListOccupied
