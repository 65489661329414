import React, { useState, useEffect, useRef, useMemo } from 'react'
import { headerData } from './header'
import BigLoading from '../../components/big-loading'
import { usePermissionGate } from '../..//helpers/hooks'
import styled from 'styled-components'
import moment from 'moment'
import { formatDateString, numberOfDays } from '../../utils'
import { exportFile, calendar, closeFilled } from 'ui/icons'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { getOutsideFilters } from './header-data'
// For Date filter
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import cn from 'classnames'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../utils/calendarInputHelper'
import { DateUtils } from 'react-day-picker/DayPicker'
import PayableTable from './components/PayableTable'
import { useModals } from 'hooks'
import ConfirmationModal from '../../components/confirmation-modal'
import { InvoicesViewPdfModal } from 'components/invoice'
import Paginator from '../../components/react-table-pagination'
import { useHistory } from 'react-router-dom'

import { isEmpty } from 'lodash'
import SwitchButton from '../../components/forms-helper/SwitchButton'
import '../../styles/common.scss'
import './index.scss'
import ApprovalAndRejectModal from './components/approval-and-rejection-modal/ApprovalAndRejectionModal'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;

  input {
    margin-top:8px !important;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E9F2;
    margin: 0px auto;
    outline: none;
    font-size: 10px
  }
  select {
    width: 100%
    margin-top:8px !important;
  }
  .approvals-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;

    .tr {
      position: relative;
     
      :first-child {
        .th {
          display:none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px
      :first-child {
        border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      // :last-child {
      //   border-right: 1px solid #e5e9f2;
      // }
    }
    
  }
`
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // To remove empty values and - from filter
  options = (options || []).filter(e => e && e !== '-')

  return (
    <select
      value={filterValue || 'All'}
      onChange={e => {
        if (e.target.value === 'all') {
          setFilter(undefined)
        } else {
          setFilter(e.target.value || '')
        }
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="all">All</option>
      {(options || []).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const ChargesList = props => {
  const {
    user,
    context,
    getApprovalsList,
    resetPurchaseOrderDetail,
    approval,
    bulkApprovalPurchaseOrder,
    getInspectionDownloadMediaURL,
    inspect,
    requestContextChange,
    updateContext,
    updateUserAllowedSection
  } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [updateApprovalsList, setUpdateApprovalsList] = useState(false)
  const [displayInvoicePdf, setDisplayInvoicePdf] = useState(false)
  const [selectedInvoiceFileName, setSelectedInvoiceFileName] = useState('')
  const [selectedPONumber, setSelectedPONumber] = useState('')
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState('')
  const [showCustomModal, dismissCustomModal] = useModals()
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [showPendingAPApproval, setShowPendingAPApproval] = useState(true)

  const tableRef = useRef()
  const history = useHistory()

  const { hasPermission } = usePermissionGate('pay-order-actions')

  // Date Filter Code
  const [outsideFilters, setOutsideFilters] = useState({})
  const [dates, setDates] = useState({ from: new Date(), to: new Date() })
  const [
    scheduleFiltersDropdownOpen,
    setScheduleFiltersDropdownOpen
  ] = useState(false)

  const outsideFiltersRef = useDetectClickOutside({
    onTriggered: () => setScheduleFiltersDropdownOpen(false)
  })

  let datePickerRef = null

  const modifiers = {
    start: dates.from,
    end: dates.to,
    past: { before: new Date(dates.from) },
    pastSelection: { before: dates.from },
    weekS: { daysOfWeek: [1] },
    weekE: { daysOfWeek: [0] },
    firstOfMonth: day => day.getDate() === 1,
    lastOfMonth: day => day.getDate() === numberOfDays(null, day.getMonth() + 1)
  }

  const getActiveScheduledFilterLabel = () => {
    const activeFilter = Object.keys(outsideFilters).find(
      filter => outsideFilters[filter]['active']
    )
    if (activeFilter !== undefined) {
      return outsideFilters[activeFilter]['label']
    }
  }

  const handleDayClick = day => {
    day.setHours(0, 0, 0)
    const range = DateUtils.addDayToRange(day, dates)

    setOutsideFilters(getOutsideFilters({ from: range.from, to: range.to }))
    setOutsideFilters(clickOutsideFilters => {
      return {
        ...clickOutsideFilters,
        custom: {
          ...clickOutsideFilters['custom'],
          active: true
        }
      }
    })
    setDates(range)
  }

  const customPickerInput = React.forwardRef((inputProps, ref) => {
    const value =
      dates.from && dates.to
        ? `${dateFnsFormat(dates.from, 'MM/dd')} - ${dateFnsFormat(
            dates.to,
            'MM/dd'
          )}`
        : inputProps.value
    return (
      <input
        {...inputProps}
        value={value}
        ref={ref}
        id="datepicker-input"
        readOnly
      />
    )
  })

  useEffect(() => {
    setOutsideFilters(
      getOutsideFilters({ from: new Date(), to: new Date() }, true)
    )
  }, [])

  useEffect(() => {
    if (scheduleFiltersDropdownOpen)
      datePickerRef && datePickerRef.hideDayPicker()
  }, [scheduleFiltersDropdownOpen])
  // Date filter code end

  // Redux
  const { allApprovals } = approval || {}
  const { data: allApprovalsList, isRequesting, hasMadeInitialRequest } =
    allApprovals || {}
  const { inspectionDownloadMediaURL } = inspect || {}

  const onSuccess = () => {
    setErrorMessage('')
  }

  const onError = () => {
    setErrorMessage('Something went wrong. Please try again later !!!')
  }

  const pdbid = user?.user_metadata?.pdbid
  const { property, project } = context

  const filteredApprovalList = useMemo(() => {
    if (!showPendingAPApproval) return allApprovalsList

    return (allApprovalsList || []).filter(
      approval => approval.status === 'Pending AP Approval'
    )
  }, [allApprovalsList, showPendingAPApproval])

  useEffect(() => {
    if (!isEmpty(property) && !isEmpty(project) && !isEmpty(pdbid)) {
      // getDefaultFilteredApprovalData()
      getInspectionDownloadMediaURL(pdbid, context)
    }
  }, [property, project, pdbid])

  useEffect(() => {
    if (updateApprovalsList) {
      setOutsideFilters(
        getOutsideFilters({ from: new Date(), to: new Date() }, true)
      )
      getDefaultFilteredApprovalData()
    }
  }, [updateApprovalsList])

  const getDefaultFilteredApprovalData = () => {
    const today = new Date()
    const priorDate = new Date().setDate(today.getDate() - 30)
    const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
    const convertPriorDate = formatDateString(priorDate, 'DD-MMM-YY')
    getFilteredApprovalData(convertPriorDate, convertTodayDate)
  }

  const getFilteredApprovalData = async (
    selectedTodayDate,
    selectedPriorDate
  ) => {
    setSelectedPageRowData(0)
    setRowsPerPage(30)
    setUpdateApprovalsList(false)
    await getApprovalsList(
      pdbid,
      context,
      selectedTodayDate,
      selectedPriorDate,
      user?.ords_user_id,
      onSuccess,
      onError
    )
  }

  const exportCSV = () => {
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows
    const filterData = (defaultData || []).map(list => {
      const {
        total_amount,
        property: po_property,
        custom_complete_date,
        status,
        po_number,
        vendor_purchase_order_number,
        approved_by_name,
        approved_at,
        po_date,
        due_date,
        vendor_name,
        amount_paid
      } = list || {}
      return {
        Property: `"${po_property}"` || '',
        Vendor: vendor_name || '',
        'Invoice Number': `"${vendor_purchase_order_number}"` || '',
        'Invoice Date': po_date || '',
        'Due Date': due_date || '',
        'P.O. Number': po_number || '',
        'Total Amount ($)': total_amount || 0,
        'Amount Paid ($)': amount_paid || 0,
        'Date Created': custom_complete_date || '',
        'Approved By': `"${approved_by_name}"` || '',
        'Approval Date & Time': `"${approved_at}"` || '',
        Status: status || ''
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `payable-approvals-${datetime}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    if (outsideFilters && Object.keys(outsideFilters).length) {
      if (errorMessage) {
        setErrorMessage('')
      }
      let filterKey = {}
      Object.keys(outsideFilters).forEach(filter => {
        if (outsideFilters[filter]['active']) {
          filterKey = outsideFilters[filter]
        }
      })
      if (Object.keys(filterKey || {}).length !== 0) {
        if (filterKey?.label === 'Tomorrow') {
          const today = new Date()
          const tomorrowDate = new Date().setDate(today.getDate() + 1)
          const convertTomorrowDate = formatDateString(
            tomorrowDate,
            'DD-MMM-YY'
          )
          getFilteredApprovalData(convertTomorrowDate, convertTomorrowDate)
        } else if (filterKey?.label === 'Last 30 Days') {
          const today = new Date()
          const priorDate = new Date().setDate(today.getDate() - 30)
          const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
          const convertPriorDate = formatDateString(priorDate, 'DD-MMM-YY')
          getFilteredApprovalData(convertPriorDate, convertTodayDate)
        } else if (filterKey?.label === 'Yesterday') {
          const today = new Date()
          const yesterdayDate = new Date().setDate(today.getDate() - 1)
          const convertYesterdayDate = formatDateString(
            yesterdayDate,
            'DD-MMM-YY'
          )
          getFilteredApprovalData(convertYesterdayDate, convertYesterdayDate)
        } else if (filterKey?.label === 'All Time') {
          const today = new Date()
          const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
          getFilteredApprovalData(convertTodayDate, 0)
        } else if (filterKey?.label === 'Custom') {
          const { from, to } = dates || {}
          const convertFromDate = formatDateString(from, 'DD-MMM-YY')
          const convertToDate = formatDateString(to, 'DD-MMM-YY')
          getFilteredApprovalData(convertFromDate, convertToDate)
        }
      }
    }
  }, [outsideFilters])

  // Confirmation Modal for Approval
  const onHandleApproveModal = ({
    msg,
    submitAction,
    dismissLabel,
    modalTitle,
    submitLabel,
    subMsg,
    additionalSubmitButtonClasses
  }) => {
    showCustomModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        submit={submitAction}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        submitLabel={submitLabel}
        subMsg={subMsg}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        useLoading={true}
        dismiss={dismissCustomModal}
      />
    )
  }

  const onHandleApproveOrRejectPurchaseOrder = status => {
    const rows = (selectedRows || []).map(row => row.original)

    showCustomModal(
      { width: '480px' },
      <ApprovalAndRejectModal
        pdbid={pdbid}
        context={context}
        user={user}
        rows={rows}
        modalTitle={status === 'approved' ? 'Approve' : 'Reject'}
        status={status}
        bulkApprovalPurchaseOrder={bulkApprovalPurchaseOrder}
        onConfirm={() => {
          setUpdateApprovalsList(true)
          dismissCustomModal()
        }}
        onCancel={dismissCustomModal}
      />
    )
  }

  const getInvoicePdfUrl = () => {
    const { property, project } = context || {}
    return `${inspectionDownloadMediaURL ||
      ''}Inspections/${project}_${property}/${selectedInvoiceFileName}`
  }

  const onHandleViewInvoice = (invoiceFile, poNumber, invoiceNumber) => {
    setSelectedInvoiceFileName(invoiceFile)
    setSelectedPONumber(poNumber)
    if (invoiceNumber) {
      setSelectedInvoiceNumber(`${invoiceNumber} - `)
    }
    setDisplayInvoicePdf(true)
  }

  const tableColumns = headerData({
    SelectColumnFilter,
    resetPurchaseOrderDetail,
    onHandleViewInvoice,
    requestContextChange,
    updateContext,
    updateUserAllowedSection,
    history,
    user
  })

  const columns = useMemo(() => tableColumns, [])

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  return (
    <div className="approvals-list-container ">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">Approvals</h2>
          </div>
          <div className="action-button">
            <div className="inspection-action-buttons">
              <button
                className="button is-primary"
                disabled={!(selectedRows || []).length || !hasPermission}
                onClick={e => {
                  e.preventDefault()
                  onHandleApproveOrRejectPurchaseOrder('approved')
                }}>
                Approve
              </button>
              <button
                className="button is-danger"
                disabled={!(selectedRows || []).length || !hasPermission}
                onClick={e => {
                  e.preventDefault()
                  onHandleApproveOrRejectPurchaseOrder('rejected')
                }}>
                Reject
              </button>
              <div
                style={{ display: 'inline-block' }}
                className="calendar-container">
                <div
                  className={`dropdown filter-dropdown ${
                    scheduleFiltersDropdownOpen ? 'is-active' : ''
                  }`}
                  id="filter-dropdown"
                  ref={outsideFiltersRef}>
                  <div className="dropdown-trigger">
                    <button
                      className="button m-r-md is-secondary"
                      type="button"
                      aria-haspopup={true}
                      aria-controls="filter-options"
                      onClick={() =>
                        setScheduleFiltersDropdownOpen(
                          !scheduleFiltersDropdownOpen
                        )
                      }>
                      <img
                        src={calendar}
                        alt="Filter data"
                        className="m-r-xs"
                      />
                      :{' '}
                      {Object.keys(outsideFilters).length &&
                        getActiveScheduledFilterLabel()}
                    </button>
                  </div>
                  <div
                    className="dropdown-menu"
                    role="menu"
                    id="filter-options">
                    <div className="dropdown-content has-text-left">
                      {Object.keys(outsideFilters).map((filter, index) => (
                        <button
                          className={cn(
                            'button filter-button',
                            { 'is-primary': outsideFilters?.[filter]?.active },
                            {
                              'is-secondary': !outsideFilters?.[filter]?.active
                            }
                          )}
                          onClick={() => {
                            const currentActiveValue =
                              outsideFilters[filter]['active']
                            const activeFilter = Object.keys(
                              outsideFilters
                            ).find(
                              a_filter => outsideFilters[a_filter]['active']
                            )
                            setOutsideFilters(
                              getOutsideFilters({
                                from: dates.from,
                                to: dates.to
                              })
                            )
                            setOutsideFilters(dateFilter => {
                              return {
                                ...dateFilter,
                                [filter]: {
                                  ...dateFilter[filter],
                                  active:
                                    filter === activeFilter
                                      ? true
                                      : !currentActiveValue
                                }
                              }
                            })
                            setScheduleFiltersDropdownOpen(false)
                          }}
                          key={`custom-filter-${index}`}>
                          {outsideFilters[filter]['icon'] && (
                            <img
                              src={outsideFilters[filter]['icon']}
                              alt={outsideFilters[filter]['label']}
                              className="p-r-md"
                              style={{ width: 34 }}
                            />
                          )}
                          {outsideFilters[filter]['label']}
                        </button>
                      ))}
                      <div
                        style={{
                          borderRadius: 4,
                          paddingLeft: '1em',
                          paddingRight: '1em',
                          paddingBottom: 'calc(0.5em - 1px)',
                          background:
                            Object.keys(outsideFilters).length &&
                            outsideFilters['custom']['active']
                              ? '#3cb3e2'
                              : 'inherit'
                        }}>
                        <DayPickerInput
                          onDayChange={handleDayClick}
                          dayPickerProps={{
                            modifiers: modifiers,
                            firstDayOfWeek: calendarConfig.firstDayOfWeek,
                            weekdaysShort: calendarConfig.weekdaysShort,
                            selectedDays: [
                              dates.from,
                              { from: dates.from, to: dates.to }
                            ],
                            month: new Date(dates.from)
                          }}
                          hideOnDayClick={false}
                          formatDate={formatDate}
                          format={'MM/dd'}
                          parseDate={parseDate}
                          placeholder={`${dateFnsFormat(
                            new Date(),
                            'MM/dd'
                          )} - ${dateFnsFormat(new Date(), 'MM/dd')}`}
                          component={customPickerInput}
                          ref={ref => (datePickerRef = ref)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <div className="right">
              <SwitchButton
                isActive={showPendingAPApproval}
                onChange={setShowPendingAPApproval}
                removeMargin
                leftLabel="Show All"
                rightLabel="Show Pending AP Approval"
              />
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
          </div>
          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        {hasMadeInitialRequest && !isRequesting ? (
          <Styles>
            <PayableTable
              ref={tableRef}
              columns={columns}
              data={filteredApprovalList}
              onRowSelectStateChange={setSelectedRows}
              setIsFilterApplied={setIsFilterApplied}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Styles>
        ) : (
          <BigLoading />
        )}
        <div className="table-pagination">
          {hasMadeInitialRequest && !isRequesting ? (
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {displayInvoicePdf && (
        <InvoicesViewPdfModal
          close={() => setDisplayInvoicePdf(false)}
          source={getInvoicePdfUrl()}
          title={`Invoice #${selectedInvoiceNumber}${selectedPONumber || ''}`}
        />
      )}
    </div>
  )
}

export default ChargesList
