/**
 * Download Inspections data module
 * @redux
 * @reduxActionScope Download Inspections
 * @module DownloadInspections
 */

/**
 * Download Inspections list request
 * @type {Redux.ActionType}
 */

export const DOWNLOAD_INSPECTIONS_LIST_REQUESTED =
  'downloadInspections/DOWNLOAD_INSPECTIONS_LIST_REQUESTED'

/**
 * Download Inspections list success
 * @type {Redux.ActionType}
 */

export const DOWNLOAD_INSPECTIONS_LIST_SUCCESS =
  'downloadInspections/DOWNLOAD_INSPECTIONS_LIST_SUCCESS'

/**
 * Download Inspections list Fail
 * @type {Redux.ActionType}
 */

export const DOWNLOAD_INSPECTIONS_LIST_FAIL =
  'downloadInspections/DOWNLOAD_INSPECTIONS_LIST_FAIL'

/**
 * Download Inspections list Reset
 * @type {Redux.ActionType}
 */

export const DOWNLOAD_INSPECTIONS_LIST_RESET =
  'downloadInspections/DOWNLOAD_INSPECTIONS_LIST_RESET'

/**
 * Get Download State Request
 * @type {Redux.ActionType}
 */

export const GET_DOWNLOAD_STATE_REQUESTED =
  'downloadInspections/GET_DOWNLOAD_STATE_REQUESTED'

/**
 * Get Download State Success
 * @type {Redux.ActionType}
 */

export const GET_DOWNLOAD_STATE_SUCCESS =
  'downloadInspections/GET_DOWNLOAD_STATE_SUCCESS'

/**
 * Get Download State Fail
 * @type {Redux.ActionType}
 */

export const GET_DOWNLOAD_STATE_FAIL =
  'downloadInspections/GET_DOWNLOAD_STATE_FAIL'

/**
 * View all downloads Request
 * @type {Redux.ActionType}
 */

export const VIEW_ALL_DOWNLOADS_REQUESTED =
  'downloadInspections/VIEW_ALL_DOWNLOADS_REQUESTED'

/**
 * View all downloads Success
 * @type {Redux.ActionType}
 */

export const VIEW_ALL_DOWNLOADS_SUCCESS =
  'downloadInspections/VIEW_ALL_DOWNLOADS_SUCCESS'

/**
 * View all downloads Fail
 * @type {Redux.ActionType}
 */

export const VIEW_ALL_DOWNLOADS_FAIL =
  'downloadInspections/VIEW_ALL_DOWNLOADS_FAIL'
