import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { close } from 'ui/icons'
import { emailPolicy } from '../../components/forms-helper/ValidationPolicies'
import { useSelector } from 'react-redux'
import Short from 'short-uuid'
import {
  enterEmailScreen,
  emailAlreadyExistsScreen,
  addPropertyToUserScreen
} from './helper-methods'
import { isEmpty } from 'lodash'
import './index.scss'

const AddVendorLoginModal = props => {
  const {
    onClose,
    user,
    context,
    history,
    checkEmailExists,
    resetCheckEmail,
    allPropertiesList,
    createOrEditUser,
    allRolesList,
    vendorId,
    associateUserToVendor,
    onHandleForceUpdateVendor
  } = props || {}
  // States
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [addToExistingUser, setAddToExistingUser] = useState(false)
  const [enableVendorLogin, setEnableVendorLogin] = useState(true)
  const { verifiedEmail, propertiesList, rolesList } = useSelector(
    state => state.setupUsers
  )
  const [nonVendorError, setNonVendorError] = useState(false)
  const [
    existsInCurrentPropertyError,
    setExistsInCurrentPropertyError
  ] = useState(false)
  const [isServerError, setIsServerError] = useState(false)

  const getCurrentProperty = useMemo(() => {
    const { property } = context || {}
    const { user_metadata } = user || {}
    const { properties } = user_metadata || {}
    const getPropertyName = (properties || []).filter(
      sel_property => sel_property.id === property
    )
    if ((getPropertyName || []).length) {
      return getPropertyName[0]?.name
    }
    return '(Error: No Property Found)'
  }, [context, user])

  const getValidEmail = value => value?.match(emailPolicy) !== null

  const optimizedEmailHandleValidation = value => {
    if (!getValidEmail(value)) {
      setEmailError(
        'The email address you entered is invalid. Please enter your email address using standard format.'
      )
    } else {
      setEmailError('')
    }
  }

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  useEffect(() => {
    resetCheckEmail()
    allPropertiesList(pdbid)
    allRolesList(pdbid)
  }, [])

  // Redux
  const {
    loading: checkEmailLoading,
    emailExists: checkEmailExist,
    properties: emailExistsinProperties,
    data: existingEmailUserData,
    emailVerified: checkEmailVerified,
    serverError: checkEmailServerError
  } = verifiedEmail || {}

  useEffect(() => {
    if (
      !isEmpty(existingEmailUserData) &&
      existingEmailUserData?.type !== 'Vendor'
    ) {
      setNonVendorError(true)
    }
    if (
      (checkIfCurrentPropertyExists || []).length &&
      existingEmailUserData?.type === 'Vendor'
    ) {
      setExistsInCurrentPropertyError(true)
    }
    if (checkEmailServerError || errorMessage !== '') {
      setIsServerError(true)
    }
  }, [verifiedEmail, errorMessage])

  const getExistingEmailUserPropertyNames = useMemo(
    () =>
      (propertiesList || [])
        .filter(d => (emailExistsinProperties || [])?.includes(d?.property_id))
        .map(sel_prop => sel_prop?.property_name),
    [propertiesList, emailExistsinProperties]
  )

  const getContextualProperty = useMemo(
    () =>
      (propertiesList || []).filter(
        d =>
          d?.project_code === context?.project &&
          d?.property_code === context?.property
      ),
    [propertiesList, context]
  )

  const getVendorRole = useMemo(() => {
    const role = (rolesList || []).filter(d => d.name === 'Vendor')
    if ((role || []).length) {
      return role[0]?.role_id
    }
    return 'Role6'
  }, [rolesList])

  const checkIfCurrentPropertyExists = useMemo(
    () =>
      (getContextualProperty || []).filter(prop =>
        emailExistsinProperties?.includes(prop?.property_id)
      ),
    [getContextualProperty, emailExistsinProperties]
  )

  const onHandleCheckEmailExists = () => {
    const userEmail = email?.trim()?.toLowerCase()
    checkEmailExists(pdbid, userEmail, () => {}, () => {})
  }

  const displayPropertiesAsText = useMemo(() => {
    const text = (getExistingEmailUserPropertyNames || [])
      ?.slice(0, getExistingEmailUserPropertyNames?.length - 1)
      ?.join(', ')
      ?.trim(' ')

    if ((getExistingEmailUserPropertyNames || []).length === 1) {
      return getExistingEmailUserPropertyNames?.[
        getExistingEmailUserPropertyNames?.length - 1
      ]
    }
    return `${text} and ${
      getExistingEmailUserPropertyNames?.[
        getExistingEmailUserPropertyNames?.length - 1
      ]
    }`
  }, [getExistingEmailUserPropertyNames])

  const onHandleToggleLogin = state => {
    setErrorMessage('')
    setEnableVendorLogin(state)
  }

  const onSuccessVendorAccociated = () => {
    setSubmitting(false)
    onClose()
    resetCheckEmail()
    onHandleForceUpdateVendor(true)
  }

  const onSuccess = data => {
    if ((data || []).length) {
      const { permission_rules } = data[0]
      const body = {
        vendor_user_id: null,
        user_id: data[0]?.user_id,
        vendor_id: vendorId
      }
      const a = (propertiesList || []).filter(
        d =>
          d?.project_code === context?.project &&
          d?.property_code === context?.property
      )
      const filterPermitRule = (permission_rules || []).map(permission => {
        const { project } = permission || {}
        if (
          project[0]?.project_id === a[0]?.project_id &&
          project[0]?.property_id === a[0]?.property_id
        ) {
          permission.action = 'Delete'
        }
        return permission
      })
      associateUserToVendor(
        pdbid,
        context,
        body,
        onSuccessVendorAccociated,
        () => {
          const body = {
            ...existingEmailUserData,
            permission_rules: filterPermitRule
          }
          createOrEditUser(pdbid, body, () => {}, () => {})
          setSubmitting(false)
          setErrorMessage('Something went wrong. Please try again later!!!')
        }
      )
    }
  }

  const onError = () => {
    setSubmitting(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onHandleAddCurrentPropertyToExitingUser = () => {
    setSubmitting(true)
    let body = {}
    body = {
      ...existingEmailUserData
    }
    body.permission_rules = [
      ...body.permission_rules,
      {
        property_id: getContextualProperty[0]?.property_id,
        project_id: getContextualProperty[0]?.project_id,
        role_id: getVendorRole,
        internal_permission_rule_id:
          body.permission_rules[0]?.internal_permission_rule_id || Short.uuid(),
        login_access: enableVendorLogin ? null : 'false'?.toString(),
        action: 'Create',
        status: null,
        permission_rule_id: null
      }
    ]
    createOrEditUser(pdbid, body, onSuccess, onError)
  }

  const getModalContent = useMemo(() => {
    if (
      (email || '').trim() === '' ||
      emailError ||
      !checkEmailExist ||
      ((checkIfCurrentPropertyExists || []).length === 0 &&
        (!addToExistingUser && existingEmailUserData?.type !== 'Vendor'))
    ) {
      return enterEmailScreen({
        email,
        setEmail,
        optimizedEmailHandleValidation,
        emailError,
        setErrorMessage,
        onHandleCheckEmailExists,
        checkEmailLoading,
        setExistsInCurrentPropertyError,
        setIsServerError,
        setNonVendorError,
        resetCheckEmail
      })
    } else if (
      checkEmailExist &&
      (emailExistsinProperties || [])?.length !== 0 &&
      (checkIfCurrentPropertyExists || [])?.length === 0 &&
      !addToExistingUser &&
      existingEmailUserData?.type === 'Vendor'
    ) {
      return emailAlreadyExistsScreen({
        displayPropertiesAsText,
        getCurrentProperty,
        setAddToExistingUser,
        onClose,
        resetCheckEmail
      })
    } else if (
      checkEmailExist &&
      (emailExistsinProperties || [])?.length !== 0 &&
      (checkIfCurrentPropertyExists || [])?.length === 0 &&
      addToExistingUser
    ) {
      return addPropertyToUserScreen({
        enableVendorLogin,
        onHandleToggleLogin,
        setErrorMessage,
        onHandleAddCurrentPropertyToExitingUser,
        isSubmitting
      })
    } else {
      return enterEmailScreen({
        email,
        setEmail,
        optimizedEmailHandleValidation,
        emailError,
        setErrorMessage,
        onHandleCheckEmailExists,
        checkEmailLoading,
        setExistsInCurrentPropertyError,
        setIsServerError,
        setNonVendorError,
        resetCheckEmail
      })
    }
  }, [
    email,
    emailError,
    addToExistingUser,
    checkEmailLoading,
    checkEmailExist,
    verifiedEmail,
    emailExistsinProperties,
    isSubmitting,
    enableVendorLogin
  ])

  useEffect(() => {
    resetCheckEmail()
  }, [])

  useEffect(() => {
    if (
      checkEmailVerified &&
      !checkEmailExist &&
      !checkEmailLoading &&
      !checkEmailServerError
    ) {
      history.push(`/setup/vendors/add/${vendorId}/${email}`)
      onClose()
    }
  }, [verifiedEmail])

  const displayErrorMessages = useMemo(() => {
    if (emailError) {
      return (
        <div className="error-message-mb notification is-danger is-light is-flex">
          <div>
            <p>{emailError}</p>
            <p>
              Example:{' '}
              <span style={{ fontWeight: 'bold' }}>noname@myturnable.com</span>
            </p>
          </div>
        </div>
      )
    } else if (nonVendorError) {
      return (
        <div className="error-message-mb notification is-danger is-light is-flex">
          <div>
            <p>
              Ops! It seems that a user with this email address already exists
              as a <strong>non-vendor</strong> user. Kindly provide a different
              email address.
            </p>
          </div>
        </div>
      )
    } else if (existsInCurrentPropertyError) {
      return (
        <div className="error-message-mb notification is-danger is-light is-flex">
          <div>
            <p>
              Ops! It seems that a user with this email address already exists
              on this property. You cannot create duplicate users with the same
              email address. Kindly provide a different email address.
            </p>
          </div>
        </div>
      )
    } else if (isServerError) {
      return (
        <div className="error-message-mb notification is-danger is-light is-flex">
          <div>
            <p>Something went wrong. Please try again later !!!</p>
          </div>
        </div>
      )
    }
  }, [isServerError, existsInCurrentPropertyError, nonVendorError, emailError])

  return (
    <div className="add-vendor-login-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            {getModalContent?.tagline}
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              onClose()
              resetCheckEmail()
            }}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full">
        {getModalContent?.content}
        {displayErrorMessages}
        <div className="columns is-vcentered m-b-sm m-t-sm m-b-space">
          <div className="center-container" style={{ marginTop: '5px' }}>
            {getModalContent?.actionButton}
          </div>
        </div>
      </div>
    </div>
  )
}

AddVendorLoginModal.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object
}

export default AddVendorLoginModal
