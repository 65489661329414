import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import { getProjectId } from './dashboard-helpers'

/**
 * Dashboard services data module
 * @redux
 * @reduxActionScope dashboard-services
 * @module dashboard-services
 */

/**
 * Request dashboard services summary data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_SERVICES_SUMMARY_REQUESTED =
  'dashboard/DASHBOARD_SERVICES_SUMMARY_REQUESTED'
/**
 * Dashboard services summary data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_SERVICES_SUMMARY_FAIL =
  'dashboard/DASHBOARD_SERVICES_SUMMARY_FAIL'
/**
 * Update request dashboard services summary with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_SERVICES_SUMMARY_SUCCESS =
  'dashboard/DASHBOARD_SERVICES_SUMMARY_SUCCESS'

/**
 * Request dashboard keys summary data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_KEYS_SUMMARY_REQUESTED =
  'dashboard/DASHBOARD_KEYS_SUMMARY_REQUESTED'
/**
 * Dashboard services keys data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_KEYS_SUMMARY_FAIL =
  'dashboard/DASHBOARD_KEYS_SUMMARY_FAIL'
/**
 * Update request dashboard keys summary with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_KEYS_SUMMARY_SUCCESS =
  'dashboard/DASHBOARD_KEYS_SUMMARY_SUCCESS'

const initialProjectState = {
  keysSummary: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    summaryCards: []
  },
  servicesSummary: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    summaryCards: []
  }
}
const initialState = {}

/**
 * Dashboard services reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_SERVICES_SUMMARY_REQUESTED:
      const srData = state[getProjectId(action.context)] || initialProjectState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...srData,
          servicesSummary: {
            ...srData.servicesSummary,
            hasMadeInitialRequest: true,
            isRequesting: true,
            isError: false
          }
        }
      }
    case DASHBOARD_SERVICES_SUMMARY_SUCCESS:
      const ssData = state[getProjectId(action.context)] || initialProjectState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...ssData,
          servicesSummary: {
            ...ssData.servicesSummary,
            isRequesting: false,
            isError: false,
            summaryCards: action.summaryCards
          }
        }
      }
    case DASHBOARD_SERVICES_SUMMARY_FAIL:
      const sfData = state[getProjectId(action.context)] || initialProjectState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...sfData,
          servicesSummary: {
            ...sfData.servicesSummary,
            isRequesting: false,
            isError: true
          }
        }
      }
    case DASHBOARD_KEYS_SUMMARY_REQUESTED:
      const krData = state[getProjectId(action.context)] || initialProjectState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...krData,
          keysSummary: {
            ...krData.keysSummary,
            hasMadeInitialRequest: true,
            isRequesting: true,
            isError: false
          }
        }
      }
    case DASHBOARD_KEYS_SUMMARY_SUCCESS:
      const ksData = state[getProjectId(action.context)] || initialProjectState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...ksData,
          keysSummary: {
            ...ksData.keysSummary,
            isRequesting: false,
            isError: false,
            summaryCards: action.summaryCards
          }
        }
      }
    case DASHBOARD_KEYS_SUMMARY_FAIL:
      const kfData = state[getProjectId(action.context)] || initialProjectState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...kfData,
          keysSummary: {
            ...kfData.keysSummary,
            isRequesting: false,
            isError: true
          }
        }
      }
    case LOGOUT:
      return initialState

    default:
      return state
  }
}

/**
 * Updates dashboard services summary data
 * @redux
 * @reduxActionCreator DASHBOARD_SERVICES_SUMMARY_REQUESTED, DASHBOARD_SERVICES_SUMMARY_SUCCESS, DASHBOARD_SERVICES_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getServicesSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_SERVICES_SUMMARY_REQUESTED,
      context
    })
    return Service.getDashboardServicesSummary(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const summaryCards = await res.json()
            dispatch({
              type: DASHBOARD_SERVICES_SUMMARY_SUCCESS,
              summaryCards: servicesCardsAdapter(summaryCards.dashboard),
              context
            })
          } catch {
            _servicesSummaryFail(dispatch, context)
          }
        } else {
          _servicesSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _servicesSummaryFail(dispatch, context)
        throw err
      })
  }
}

const _servicesSummaryFail = (dispatch, context) => {
  dispatch({
    type: DASHBOARD_SERVICES_SUMMARY_FAIL,
    context
  })
}

const servicesCardsAdapter = data => {
  const { services, stats } = data
  return _.map(services, service => {
    const sc = _.find(stats, s => s.id === service.servicetype)
    let result = []
    if (sc) {
      for (let key in sc.stats[0]) {
        let data = sc.stats[0][key]
        if (data.id === 'NotAssigned') data.id = 'Assign'
        if (data.id === 'Assigned') data.id = 'Start'
        if (data.id === 'Complete') data.id = 'AssignQC'
        if (data.id === ' Approved') data.id = 'Approved'
        data.href = `/services?s=${sc.id}&p=${data.id}`
        result.push(data)
      }
    } else {
      result = [
        { id: 'Assign', href: `/services?s=${service.servicetype}&p=Assign` },
        { id: 'Start', href: `/services?s=${service.servicetype}&p=Start` },
        {
          id: 'AssignQC',
          href: `/services?s=${service.servicetype}&p=AssignQC`
        },
        {
          id: 'Approved',
          href: `/services?s=${service.servicetype}&p=Approved`
        }
      ]
    }
    return {
      id: service.servicetype,
      label: service.label,
      stats: result
    }
  })
}

/**
 * Updates keys services summary data
 * @redux
 * @reduxActionCreator DASHBOARD_KEYS_SUMMARY_REQUESTED, DASHBOARD_KEYS_SUMMARY_SUCCESS, DASHBOARD_KEYS_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getKeysSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_KEYS_SUMMARY_REQUESTED,
      context
    })
    return Service.getKeysStats(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const summaryCards = await res.json()
            dispatch({
              type: DASHBOARD_KEYS_SUMMARY_SUCCESS,
              summaryCards: keysCardsAdapter(summaryCards),
              context
            })
          } catch {
            _keysSummaryFail(dispatch, context)
          }
        } else {
          _keysSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _keysSummaryFail(dispatch, context)
        throw err
      })
  }
}

const _keysSummaryFail = (dispatch, context) => {
  dispatch({
    type: DASHBOARD_KEYS_SUMMARY_FAIL,
    context
  })
}

const keysCardsAdapter = summaryCards => {
  if (
    typeof summaryCards !== 'object' ||
    !Array.isArray(summaryCards.items) ||
    summaryCards.items.length < 1
  )
    return []
  const DEFAULT = 0
  const keys = summaryCards.items[0]
  return [
    {
      id: 'keys',
      label: 'Keys',
      stats: [
        {
          id: 'expected',
          label: 'Expected',
          value: keys.total_expected || DEFAULT,
          href: '/keys?filter=expected'
        },
        {
          id: 'returned',
          label: 'Returned',
          value: keys.total_returned || DEFAULT,
          href: '/keys?filter=returned'
        },
        {
          id: 'rti',
          label: 'Transfers',
          value: keys.total_rti || DEFAULT,
          href: '/keys?filter=rti'
        },
        {
          id: 'rto',
          label: 'Transfers',
          value: keys.total_rto || DEFAULT,
          href: '/keys?filter=rto'
        },
        {
          id: 'potential_holdovers',
          label: 'Potential HOs',
          value: keys.potential_holdover || DEFAULT,
          href: '/keys?filter=potential_holdovers'
        },
        {
          id: 'holdovers',
          label: 'Holdover',
          value: keys.holdover || DEFAULT,
          href: '/keys?filter=holdovers'
        },
        {
          id: 'leapfrogs',
          label: 'Leapfrog',
          value: keys.total_leapfrog || DEFAULT,
          href: '/keys?filter=holdovers'
        },
      ]
    }
  ]
}
