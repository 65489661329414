import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import './index.scss'

const StatusBar = ({ children, className, show, ...restProps }) => {
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    setDisplay(show)
  }, [show])

  return (
    <div className={cn('status-bar', { className: className }, { 'status-bar--show': display })} {...restProps}>
      {children}
    </div>
  )
}

export default StatusBar
