import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import Short from 'short-uuid'
import AppliedFilters from './applied-filters'
import { icons } from './icons'
import { setFilterValue, addFilterValue, removeFilterValue } from './helpers'
import MultiSelectDropdown from '../multi-select-dropdown'

const EmptyState = () => (
  <div className="columns">
    <div className="column has-text-centered">
      <img src={icons.emptyPage} alt="Empty Page Icon" />
      <p className="is-size-5 m-t-md">No filters has been specified yet!</p>
      <p className="has-text-grey is-size-7 m-t-sm">Please add new filters.</p>
    </div>
  </div>
)

const FilterColumn = ({
  customFilters,
  fieldFilters,
  filter = {},
  unfoldedFilter = null,
  item = null,
  updateFilter,
  onChange,
  initialField
}) => {
  const filteredFieldFilters = _.map(fieldFilters, ff => {
    const { options } = ff
    
    if(options && typeof options[0] !== 'object') {
      return {
        ...ff,
        options: _.difference(
          (options || []),
          _.filter(_.get(filter, ff.key, []), o => o !== _.get(item, 'value'))
        )
      }
    } else {
      const availableOptions = _.difference(
        (options || [])
          .map(item => item.key),
        _.filter(_.get(filter, ff.key, []), o => o !== _.get(item, 'value'))
      )

      return {
        ...ff,
        options: (options || []).filter(item => availableOptions.includes(item.key))
      }
    }
  })

  const keys = _.filter(
    Object.keys(filter),
    key => key !== _.get(item, 'value')
  )

  const availableFilters = _.concat(
    customFilters && customFilters.length
      ? {
        key: 'exception',
        caption: 'Exception',
        options: _.map(
          _.filter(customFilters, cf => keys.indexOf(cf.key) < 0),
          cf => ({
            key: cf.key,
            caption: cf.caption
          })
        )
      }
      : [],
    _.map(_.filter(filteredFieldFilters, ff => ff.options.length), ff => ({
      key: ff.key,
      caption: ff.display,
      options: _.map(ff.options, option => ({
        key: typeof option === 'string' ? option : option.key,
        caption: typeof option === 'string' ? option : option.caption
      })).filter(option => option.key && option.caption)
    }))
  )

  const [field, setField] = useState(
    _.find(
      availableFilters,
      af => af.key === _.get(item, 'key', initialField)
    ) || null
  )

  const updateKey = key => {
    if (key !== field) {
      onChange(key)
    }
    const f = _.find(availableFilters, af => af.key === key)
    setField(f)
  }

  const updateValue = value => {
    if (value !== '') {
      updateFilter(field.key, value, _.get(item, 'value', null))
    }
  }

  const handleChangeFieldName = newField => {
    const found = (availableFilters || []).find(
      item => item.key === newField.key && item.caption === newField.caption
    )

    if (found) {
      setField({ ...newField, options: found.options })
    }
  }

  const keyOptions = useMemo(() => {
    const selectedKeys = (unfoldedFilter || []).map(filter => filter.key)
    
    return  (availableFilters || [])
      .filter(data => !selectedKeys.includes(data.key))
      .map(data => _.omit(data, ['options']))
  }, [field, unfoldedFilter, availableFilters])

  const selectedValue = useMemo(() => {
    if (!item) return null
    
    const found = (field.options || []).find(option => option.key === item.value)
    return found || null
  }, [field, item])

  const selectedKey = useMemo(() => {
    if (!field) return null
    return _.omit(field, ['options'])
  }, [field])

  return (
    <div className="columns">
      <div className="is-fullwidth column">
        <MultiSelectDropdown
          placeholder="Filter by"
          isMulti={false}
          displayKey="caption"
          value={selectedKey}
          defaultValues={keyOptions}
          onChange={handleChangeFieldName}
          customStyles={{
            valueContainer: provided => ({
              ...provided,
              minHeight: '45px',
              maxHeight: '100px',
              overflow: 'auto',
              position: 'relative'
            })
          }}
        />
      </div>
      <div className="is-fullwidth column">
        <MultiSelectDropdown
          placeholder="Filter value"
          isMulti={false}
          displayKey="caption"
          value={selectedValue}
          defaultValues={field?.options || []}
          onChange={option => updateValue(option.key)}
          customStyles={{
            valueContainer: provided => ({
              ...provided,
              minHeight: '45px',
              maxHeight: '100px',
              overflow: 'auto',
              position: 'relative'
            })
          }}
        />
      </div>
    </div>
  )
}

const FilterModal = ({
  open,
  startFilter,
  customFilters,
  fieldFilters,
  getTagCaption,
  onChange,
  onFilter,
  onClose
}) => {
  const [filter, setFilter] = useState({})
  const [adding, setAdding] = useState(false)
  const [lastFilter, setLastFilter] = useState(null)
  const [field, setField] = useState(null)

  useEffect(() => {
    setFilter(startFilter)
    if (Object.keys(startFilter).length) {
      setAdding(false)
    }
  }, [startFilter])

  useEffect(() => {
    if (!Object.keys(filter).length) {
      setAdding(true)
    }
    if (onChange) {
      onChange(filter)
    }
  }, [filter, open])

  useEffect(() => {
    const keys = Object.keys(filter)
    if (keys && keys.length) {
      setLastFilter({
        key: keys[keys.length - 1],
        value: filter[keys[keys.length - 1]]
      })
    } else {
      setLastFilter(null)
    }
  }, [filter])

  const updateFilter = (key, value, originalValue) => {
    if (key === 'exception') {
      setFilter(
        setFilterValue(
          originalValue ? setFilterValue(filter, originalValue, null) : filter,
          value,
          true
        )
      )
    } else {
      setFilter(
        addFilterValue(
          originalValue
            ? removeFilterValue(filter, key, originalValue)
            : filter,
          key,
          value || null
        )
      )
    }
    setAdding(false)
  }

  const onFilterRemove = ({ key, value, rawValue } = {}, lastFilter) => {
    if (lastFilter) {
      const lastItem = unfoldedFilter[unfoldedFilter.length - 1]
      setFilter(
        removeFilterValue(
          filter,
          lastItem['key'],
          lastItem['value'],
          rawValue,
          lastItem
        )
      )
      return
    }

    setFilter(removeFilterValue(filter, key, value, rawValue))
  }

  const unfoldedFilter = _.flatten(
    _.map(Object.keys(filter), key => {
      if (Array.isArray(filter[key])) {
        return _.map(filter[key], value => ({
          key,
          value
        }))
      } else if (_.find(customFilters, cf => cf.key === key)) {
        return {
          key: 'exception',
          value: key
        }
      }
      return {
        key,
        value: filter[key]
      }
    })
  )

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body">
          <div className="columns is-desktop is-mobile">
            <div className="column">
              <p className="heading-4 is-pulled-left">Custom Filter</p>
              <p
                className="is-pulled-right is-pointer is-unselectable has-text-grey-light"
                onClick={() => {
                  onClose()
                }}>
                X
              </p>
            </div>
          </div>
          <div className="columns m-b-none">
            <div className="column is-full p-b-none">
              <AppliedFilters
                alignLeft
                activeFilters={filter}
                customFilters={customFilters}
                definitions={fieldFilters}
                onFilterRemove={onFilterRemove}
                getTagCaption={getTagCaption}
                isInner
              />
            </div>
          </div>
          <div className="columns is-multiline p-t-none m-t-none">
            <div className="column is-full p-t-none">
              {unfoldedFilter.length === 0 && !adding ? (
                <EmptyState />
              ) : (
                <div>
                  {_.map(unfoldedFilter, current => (
                    <FilterColumn
                      key={Short.uuid()}
                      customFilters={customFilters}
                      fieldFilters={fieldFilters}
                      filter={filter}
                      item={current}
                      unfoldedFilter={unfoldedFilter}
                      updateFilter={updateFilter}
                      onChange={key => {
                        onFilterRemove(current)
                        setAdding(true)
                        setField(key)
                      }}
                    />
                  ))}
                  {adding && (
                    <FilterColumn
                      key={Short.uuid()}
                      customFilters={customFilters}
                      fieldFilters={fieldFilters}
                      filter={filter}
                      unfoldedFilter={unfoldedFilter}
                      updateFilter={updateFilter}
                      onChange={() => {}}
                      initialField={field}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="columns is-vcentered">
            {(!adding && unfoldedFilter.length !== fieldFilters?.length) && (
              <div
                className="column is-narrow is-pointer p-r-md"
                onClick={() => setAdding(true)}>
                <img
                  alt="Add Filter"
                  style={{ height: '20px' }}
                  src={icons.setupAdd}
                  className="m-r-xs"
                />
                <span style={{ verticalAlign: 'bottom' }}>Add Filter</span>
              </div>
            )}
            {lastFilter !== null && (
              <div
                className="column is-narrow is-pointer"
                onClick={() => onFilterRemove({}, lastFilter)}>
                <img
                  alt="Remove Last Filter"
                  style={{ height: '20px' }}
                  src={icons.trash}
                  className="m-r-xs"
                />
                <span style={{ verticalAlign: 'bottom' }}>
                  Remove Last Filter
                </span>
              </div>
            )}
          </div>
          <div className="center-container">
            <button
              className="button main-button m-r-md is-secondary"
              onClick={onClose}>
              Cancel
            </button>
            <button
              className="button main-button is-primary"
              onClick={() => {
                onFilter(filter)
                onClose()
              }}>
              Filter
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default FilterModal
