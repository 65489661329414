import React, { useEffect, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { headerData } from './header'
import { Link } from 'react-router-dom'
import { emptyPage, warning, exportFile, closeFilled } from 'ui/icons'
import { resetPropertyFormCreate } from '../../modules/property-forms'
import RestrictedElement from '../../containers/restricted-element'
import BigLoading from '../../components/big-loading'
import { formatDateString } from '../../utils'
import CompanyTemplateTable from './company-template-table/company-template-table'
import './index.scss'
import PermissionGate from '../../components/permission-gate'
import { omit } from 'lodash'

const CompanyTemplateList = ({ history, user, companyTemplates, context }) => {
  const dispatch = useDispatch()
  const {
    createIsRequesting,
    createSuccess,
    createIsError,
    details
  } = useSelector(state => state.propertyForms)

  const [createFormTemplateLoading, setCreateFormTemplateLoading] = useState({})
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const tableRef = useRef(null)

  const { list: data, listIsError } = companyTemplates

  const exportCSV = () => {
    const rows = tableRef.current?.getRows() || []
    const selectedRows = tableRef.current?.getSelectedRows() || []

    const dataToExport = (selectedRows.length ? selectedRows : rows || [])
      .map(template => omit(template, ['created_by', 'template_id']))
      .map(data => ({
        name: data.name,
        type: data.type,
        created_by: data.created_by_name,
        created_at: data.created_at,
        state: data.state,
        shared: data.shared
      }))

    const keys = [
      'Template Name',
      'Template Type',
      'Created By',
      'Created Date',
      'State',
      'Shared'
    ]

    const csvData = [keys]
      .concat(dataToExport.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')

    const csvContent = 'data:text/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `all-templates-${formatDateString(new Date(), 'MM_DD_YYYY')}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns = useMemo(() => headerData(), [])
  const memoizedData = useMemo(() => data || [], [data])

  useEffect(() => {
    if (!createIsRequesting && createSuccess && details.template_id) {
      history.push(
        `/inspections-setup/property-forms/manage?action=edit&template=${details.template_id}`
      )
    } else if (!createIsRequesting && createIsError) {
      setError(true)
    }

    return () => dispatch(resetPropertyFormCreate())
  }, [createIsRequesting, createSuccess, createIsError, details])

  useEffect(() => {
    if (listIsError) setError(true)
  }, [listIsError])

  const getLoadingState = () => <BigLoading />

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">
          Looks like there are no templates yet!
        </p>
        <PermissionGate name="create-or-modify-template">
          <p className="has-text-grey is-size-7 m-t-sm">
            Let's get started by adding one new template.
          </p>
          <Link to="/inspections-setup/company-templates/manage?action=new-template">
            <button className="button is-primary m-t-md">Add Template</button>
          </Link>
        </PermissionGate>
      </div>
    </div>
  )

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  if (!companyTemplates.listHasRequested || companyTemplates.listIsRequesting) {
    return getLoadingState()
  }
  if (!error && companyTemplates?.list?.length < 1) {
    return getEmptyState()
  }

  return (
    <div className="template-list-container">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">All Templates</h2>
          </div>
          <div className="action-button">
            <div className="template-action-buttons ">
              <PermissionGate name="create-or-modify-template">
                <button
                  className="button is-success"
                  onClick={() =>
                    history.push(
                      '/inspections-setup/company-templates/manage?action=new-template'
                    )
                  }>
                  Create
                </button>
              </PermissionGate>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <RestrictedElement>
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </RestrictedElement>
          </div>
          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        <CompanyTemplateTable
          ref={tableRef}
          data={memoizedData}
          tableColumns={columns}
          onRowSelectStateChange={setSelectedRows}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
    </div>
  )
}

CompanyTemplateList.propTypes = {
  companyTemplates: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object
}

export default CompanyTemplateList
