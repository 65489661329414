import React from 'react'
import { isBlacklisted } from '../sidebarUtilities'
import NavItem from '../navItem'

const DropdownItem = ({ menuItem, currentPath, user, property }) => {
  const filteredChildren = menuItem.children
  // .filter(
  //   item => !isBlacklisted(user, item, property)
  // )

  if (filteredChildren.length === 0) {
    return null
  }

  return (
    <li>
      <div className="collapser has-text-white is-unselectable is-pointer is-block">
        <div className="columns is-desktop is-mobile is-vcentered is-gapless">
          <div className="column menu-item--section">
            {menuItem.parentSection}
          </div>
        </div>
      </div>
      <ul className="is-borderless is-paddingless is-marginless">
        {filteredChildren.map((childItem, i) => {
          return (
            <NavItem key={i} currentPath={currentPath} menuItem={childItem} />
          )
        })}
      </ul>
    </li>
  )
}

export default DropdownItem
