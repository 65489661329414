import React from 'react'
import ErrorMessage from './ErrorMessage'

const InputField = ({
  id,
  name,
  type = 'text',
  label,
  placeholder,
  value,
  disabled = false,
  error,
  onChange,
  className = 'input form-control',
  labelClassName = '',
  wrapperClassName = '',
  ...rest
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <div className={`m-b-sm m-t-sm ${wrapperClassName}`}>
        <label className={labelClassName}>{label}</label>
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          {...rest}
          className={className}></input>
      </div>
      {error && (
        <ErrorMessage>
          <p dangerouslySetInnerHTML={{ __html: error }}></p>
        </ErrorMessage>
      )}
    </div>
  )
}

export default InputField
