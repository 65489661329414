import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import MultiSelectDropdown from '../../../../../components/multi-select-dropdown'
import { selectStyles } from '../../DataTable/pricing-form-table/singleMultiSelectStyles'
import { cloneDeep } from 'lodash'

const SpaceField = ({ row, onHandleAmountChange }) => {
  const { cell } = row || {}
  const {
    row: {
      index,
      original: { space: initialValue }
    }
  } = cell || {}

  const [value, setValue] = useState([{ unit_space: initialValue }])

  const { propertyUnitSpaces } = useSelector(state => state.setupVendors)

  const sortUnitSpaceList = useMemo(() => {
    const data = cloneDeep(propertyUnitSpaces || [])
    const checkIfCommonIndex = data?.findIndex(x => x.unit_space === 'Common')
    if (checkIfCommonIndex !== -1) {
      delete data[checkIfCommonIndex]
      data.unshift({ unit_space: 'Common' })
    }
    return data || []
  }, [propertyUnitSpaces])

  return (
    <div className="cell-item space-option">
      <MultiSelectDropdown
        displayKey="unit_space"
        value={(value[0]?.unit_space ?? []) !== '' ? value : []}
        defaultValues={sortUnitSpaceList}
        onChange={v => {
          setValue([v])
          onHandleAmountChange(v.unit_id, index, 'dropdown', {
            ...v,
            dropdowntype: 'space-option'
          })
        }}
        isMulti={false}
        placeholder="Select Space"
        noOptionsMessage="No Space Available"
        menuPortalTarget={document.body}
        customStyles={selectStyles}
      />
      {/* <div className="select is-fullwidth select-wrapper">
        <select
          className="input is-fullwidth select-inner"
          value={value}
          onChange={e => {
            setValue(e.target.value)
            onHandleAmountChange(e.target.value, index, 'space')
          }}>
          {!value ? <option value="">Select Space</option> : null}
          {(unitSpaceList || []).map(d => (
            <option key={d.unit_space} value={d.unit_space}>
              {d.unit_space}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  )
}

export default SpaceField
