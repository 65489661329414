import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

/**
 * Component for showing a project in the main landing page
 *
 * @component
 * @example
 * return (
 *   <DasboardBox
 *     icon="./images/test.png"
 *     header="Test project"
 *     caption="Select your property to get started"
 *     isPrimary
 *   />
 * )
 */
const DashboardBox = ({
  icon,
  header,
  caption,
  className,
  onButtonClick = () => {},
  isPrimary = false
}) => (
  <div className={`dashboard-box box has-text-centered ${className}`}>
    <img
      src={icon}
      alt={`${header}-icon`}
      style={{
        height: '65px',
        width: '65px'
      }}
    />
    <p className="header">{header}</p>
    <p className="caption m-b-lg">{caption}</p>
    <button
      onClick={onButtonClick}
      className={`lets-go button ${isPrimary ? 'is-primary' : 'secondary'}`}>
      Let's Go
    </button>
  </div>
)

DashboardBox.propTypes = {
  /**
   * Icon for the main header of the box
   */
  icon: PropTypes.string,
  /**
   * Main header text
   */
  header: PropTypes.string,
  /**
   * Small caption just before the Let's Go button
   */
  caption: PropTypes.string,
  /**
   * Additional classes for the main div
   */
  className: PropTypes.string,
  /**
   * Determines if it's the primary project box
   */
  isPrimary: PropTypes.bool,
  /**
   * Action when the button is clicked
   */
  onButtonClick: PropTypes.func
}

export default DashboardBox
