import React, { useEffect, useState } from 'react'

import './index.scss'

const Toggle = ({
  onChange,
  isActive,
  disabled = false,
  wrapperClassName = ''
}) => {
  const [isChecked, setChecked] = useState(false)

  const handleChange = () => {
    setChecked(!isChecked)
    onChange(!isChecked)
  }

  useEffect(() => {
    setChecked(isActive)
  }, [isActive])

  return (
    <div className={wrapperClassName}>
      <label
        className="toggle-control"
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="control"></span>
      </label>
    </div>
  )
}

export default Toggle
