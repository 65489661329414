import React from 'react'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { question, questionFilled } from 'ui/icons'

const GeneralFieldNoPadding = ({
  children,
  fieldLabel,
  isRequired = false,
  labelClassName = '',
  isTooltip = false,
  tooltipText = ''
}) => {
  return (
    <>
      <div className="label column is-one-third m-0">
        <label className={labelClassName}>
          {fieldLabel}{' '}
          {isRequired ? <span className="required-star">*</span> : null}
          {isTooltip ? (
            <Tippy
              appendTo={() => document.body}
              content={
                <div className="content">
                  <p>{tooltipText}</p>
                </div>
              }
              placement="bottom-start"
              allowHTML
              theme="light">
              <img
                src={question}
                alt="help"
                onMouseOver={e => (e.currentTarget.src = questionFilled)}
                onMouseLeave={e => (e.currentTarget.src = question)}
              />
            </Tippy>
          ) : null}
        </label>
      </div>
      <div className=" column is-four-thirds input-field m-0">{children}</div>
    </>
  )
}

export default GeneralFieldNoPadding
