import _ from 'lodash'
import Short from 'short-uuid'
import React, { memo } from 'react'
import StatusColumnFilter from './vendor-table/vendor-table-column-filters/status-column-filter'
import ActionColumn from './vendor-table/vendor-table-columns/action-column'
import DefaultColumn from './vendor-table/vendor-table-columns/default-column'
import NameColumn from './vendor-table/vendor-table-columns/name-column'
import SearchDropdownFilterMultiple from './component/SearchDropdownFilterMultiple'
import { serviceIcon } from './services-mapping'

const ServiceIcon = ({ serviceId, spacing = '' }) => {
  const service = _.find(serviceIcon, icon => icon.id === serviceId) || {}
  return (
    <img
      key={Short.uuid()}
      src={service.icon}
      alt={service.caption}
      title={service.caption}
      className={spacing}
      style={{ width: 25, height: 25, marginTop: '-6px' }}
    />
  )
}

const MemoizedServiceIcon = memo(ServiceIcon)

export const headerData = ({
  onShowDeleteModal,
  onShowReactivateOrInactivateModal
}) => {
  return [
    {
      Header: 'Vendor Name',
      accessor: 'name',
      Filter: SearchDropdownFilterMultiple,
      filter: 'multiEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <NameColumn row={row} />
      }
    },
    {
      Header: 'Service(s)',
      accessor: 'simplifiedServices',
      Filter: SearchDropdownFilterMultiple,
      filter: 'multiEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        const { cell } = row || {}
        const { row: originalRow } = cell || {}
        const { original: currentRow } = originalRow || {}
        const { Services } = currentRow
        return (
          <div className="cell-item">
            {_.map(Services || [], service => (
              <MemoizedServiceIcon
                serviceId={service.service_name}
                spacing="p-r-sm"
                key={service.service_name}
              />
            ))}
          </div>
        )
      }
    },
    {
      Header: 'Email Address',
      accessor: 'email',
      Filter: SearchDropdownFilterMultiple,
      filter: 'multiEquals',
      width: 260,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Phone Number',
      accessor: 'phone',
      width: 190,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Integration ID',
      accessor: 'integration_id',
      width: 190,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: StatusColumnFilter,
      width: 100,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: '',
      accessor: 'template_id',
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        return (
          <ActionColumn
            row={row}
            onShowDeleteModal={onShowDeleteModal}
            onShowReactivateOrInactivateModal={
              onShowReactivateOrInactivateModal
            }
          />
        )
      }
    }
  ]
}
