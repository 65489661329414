import React from 'react'
import ToggleCheckbox from '../../../components/toggle-checkbox'

const ToggleColumnFilters = ({
  onChange: parentChangeHook,
  isActive = false
}) => {
  return (
    <div className="toggle-column-input-wrapper">
      <ToggleCheckbox
        onChange={state => {
          parentChangeHook(state)
        }}
        isActive={isActive}
      />
      <span>Show Filters</span>
    </div>
  )
}

export default ToggleColumnFilters
