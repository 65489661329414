import React from 'react'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const FutureLeaseStatusCell = ({ row }) => {
  const { cell, row: currentRow, state } = row || {}
  const { id, original } = currentRow || {}
  const { selectedRowIds } = state || {}
  const { value } = cell || {}
  const { current_resident, future_resident, is_override, override_status } =
    original || {}
  const statuses = ['Renewal', 'Renewed', 'Transfer In', 'Leapfrog']

  const showRedColor =
    (is_override || '').toLowerCase() === 'true' &&
    statuses.includes(override_status)

  const currentResident = current_resident
    ? current_resident.trim()
    : current_resident
  const futureResident = future_resident
    ? future_resident.trim()
    : future_resident

  const ToolTipElement = () => {
    const style = {
      fontWeight: 'bold',
      textTransform: 'capitalize',
      fontSize: '12px'
    }

    return (
      <div>
        {currentResident || futureResident ? (
          <>
            <p>
              <span style={style}>Current Resident: </span>
              &nbsp; {currentResident}
            </p>
            <p>
              <span style={style}>Future Resident: </span>
              &nbsp; {futureResident}
            </p>
          </>
        ) : (
          <p>No current and future residents.</p>
        )}
      </div>
    )
  }

  const style = {}

  if (showRedColor) {
    style['color'] = 'red'
  }

  return (
    <div
      className="cell-item"
      style={{
        backgroundColor: Object.keys(selectedRowIds).includes(id)
          ? '#55C2B8'
          : null
      }}>
      <Tippy
        className="tooltip-container"
        content={<ToolTipElement />}
        placement="bottom"
        theme="light">
        <span style={style}>{value}</span>
      </Tippy>
    </div>
  )
}

export default FutureLeaseStatusCell
