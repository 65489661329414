export const elementsDeleteAdapter = (
  elements,
  { elementToDelete, type, idKey }
) => {
  elements[type] = (elements[type] || []).filter(
    element => element[idKey] !== elementToDelete[idKey]
  )
  return elements
}

export const allUnitListAdapter = (list, uniqueKey, sortKey) => {
  const arrayUniqueByKey = [
    ...new Map((list || []).map(item => [item[uniqueKey], item])).values()
  ]
  return (arrayUniqueByKey || []).sort((a, b) => {
    const x = a?.[sortKey]?.toLowerCase()
    const y = b?.[sortKey]?.toLowerCase()
    if (x > y) return 1
    if (x < y) return -1
    return 0
  })
}

export const spaceAdapter = elements => {
  elements['spaces'] = (elements['spaces'] || []).map(sp => {
    const { unit_space } = sp || {}
    sp.unit_space = [
      ...new Map(
        (unit_space || []).map(item => [item['unit_space'], item])
      ).values()
    ].filter(u => u.unit_space !== null).map(unit => ({
      ...unit,
      already_added: true
    }))
    if ((sp?.unit_space || []).length) {
      sp.link = 'Yes'
    } else {
      sp.link = 'No'
    }
    return sp
  })
  return elements
}
