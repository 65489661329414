export const filteredProjectList = (selectedProperties, allProperties) => {
  const propertyIds = (selectedProperties || []).map(
    ({ property_id }) => property_id
  )
  const selectedProjects = (allProperties || []).filter(({ property_id }) =>
    propertyIds.includes(property_id)
  )
  const getOnlyUniqueProject = [
    ...new Map(
      (selectedProjects || []).map(item => [item['project_id'], item])
    ).values()
  ]
  return getOnlyUniqueProject || []
}

export const filteredPropertiesList = (selectedProjects, allProperties) => {
  const projectIds = (selectedProjects || []).map(
    ({ project_id }) => project_id
  )
  const selectedProperties = (allProperties || []).filter(({ project_id }) =>
    projectIds.includes(project_id)
  )
  const getOnlyUniqueProperties = [
    ...new Map(
      (selectedProperties || []).map(item => [item['property_id'], item])
    ).values()
  ]
  return getOnlyUniqueProperties || []
}

const destructurePermissionObjectForCreation = (
  property_id,
  project_id,
  role_id,
  internal_permission_rule_id,
  login_access
) => {
  return {
    property_id,
    project_id,
    role_id,
    internal_permission_rule_id,
    login_access: login_access ? null : 'false'?.toString(),
    action: 'Create',
    status: null,
    permission_rule_id: null
  }
}

export const destructDataForUserCreationPayload = (
  permissionData,
  allProperties
) => {
  let data = []
  if (permissionData?.length) {
    ;(permissionData || []).map(selectedPermissionData => {
      const {
        properties,
        role,
        login_access,
        internal_permission_rule_id,
        projects
      } = selectedPermissionData || {}
      ;(properties || []).forEach(prop => {
        ;(projects || []).forEach(proj => {
          ;(allProperties || []).forEach(element => {
            if (
              element?.property_id === prop?.property_id &&
              element?.project_id === proj?.project_id
            ) {
              data.push(
                destructurePermissionObjectForCreation(
                  prop?.property_id,
                  proj?.project_id,
                  role[0]?.role_id,
                  internal_permission_rule_id,
                  login_access
                )
              )
            }
          })
        })
      })
      return selectedPermissionData
    })
  }
  return data || []
}

export const getSelectedProperty = (selectedPermissions, id) => {
  return (selectedPermissions || []).filter(
    perm => perm?.internal_permission_rule_id === id
  )
}
