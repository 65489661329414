import React, { createContext, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { resetScheduleFlow } from '../../modules/schedule'
import WizardStep from '../wizard-step'
// import { icons } from './icons'
import './index.scss'

export let StepperContext = null

const Wizard = ({ steps, mainWizardCurrentStep, setMainWizardCurrentStep, goBackToMain, hasSubflow, handleScheduleAllModal, handleClearAllModal, children }) => {
  const [wizardSteps, setWizardSteps] = useState(steps)
  const [currentStepSubflow, setCurrentStepSubflow] = useState(0)
  const dispatch = useDispatch()

  StepperContext = useCallback(createContext({}), [])

  const backActionHandler = () => {
    if (currentStepSubflow === 0 && mainWizardCurrentStep === 0) {
      goBackToMain()
    } else if (currentStepSubflow === 0 && mainWizardCurrentStep > 0) {
      setCurrentStepSubflow(children.length - 1)
      setMainWizardCurrentStep(mainWizardCurrentStep - 1)
    } else {
      setCurrentStepSubflow(currentStepSubflow - 1)
    }
  }

  const forwardActionHandler = () => {
    if ((mainWizardCurrentStep + 1 === steps.length) && children.length === currentStepSubflow + 1) {
      dispatch(resetScheduleFlow())
      return goBackToMain()
    }

    if ((currentStepSubflow + 1) === (children.length)) {
      setCurrentStepSubflow(0)
      setMainWizardCurrentStep(mainWizardCurrentStep + 1)
    } else {
      setCurrentStepSubflow(currentStepSubflow + 1)
    }
  }

  const innerSteps = useMemo(() => {
    return React.Children.map(children, (el, index) => {
      const indexToCompare = hasSubflow ? currentStepSubflow : mainWizardCurrentStep

      if (index === indexToCompare) {
        return React.cloneElement(el)
      }
    })
  }, [mainWizardCurrentStep, currentStepSubflow])

  return (
    <StepperContext.Provider
      value={{
        backActionHandler,
        currentStepSubflow,
        forwardActionHandler,
        goBackToMain: goBackToMain,
        handleScheduleAllModal: handleScheduleAllModal,
        mainWizardCurrentStep,
        setWizardSteps,
        stepData: wizardSteps[mainWizardCurrentStep],
      }}>

      {/* <ul className="stepper">
        {wizardSteps.map((step, index) => (
          <li
            key={step.label}
            className={`stepper-item ${step.done ? '' : ''} ${
              index === mainWizardCurrentStep ? 'active' : ''
            }`}>
            <span className="status-indicator">
              {step.done && (
                <img
                  src={icons.check}
                  alt="scheduled service"
                  className="check-icon"
                />
              )}
            </span>
            <span className="label">{step.label}</span>
          </li>
        ))}
      </ul> */}

      {innerSteps}

    </StepperContext.Provider>
  )
}

Wizard.WizardStep = WizardStep

Wizard.propTypes = {
  steps: PropTypes.array,
  mainWizardCurrentStep: PropTypes.number,
  setMainWizardCurrentStep: PropTypes.func,
  goBackToMain: PropTypes.func,
  hasSubflow: PropTypes.bool,
}

export default Wizard
