import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import Breadcrumbs from '../breadcrumbs'
import DaysToMoveIn from '../../components/dashboard/days-to-move-in'
import BedsTurned from '../../components/dashboard/beds-turned'
import UnitsTurned from '../../components/dashboard/units-turned'
import HealthRating from '../../components/dashboard/health-rating'
import Congrats from '../../components/dashboard/congrats'
import TodaysChallenge from '../../components/dashboard/todays-challenge'
import Keys from '../../components/dashboard/keys'
import Calendar from '../../components/dashboard/calendar'
import TurnDetails from '../../components/dashboard/turn-details'
import PercentageComplete from '../../components/dashboard/percentage-complete'
import Goals from '../../components/dashboard/goals'
import ServicesCharts from '../../components/dashboard/services-charts'
import TotalTurnCost from '../../components/dashboard/total-turn-cost'
import CostBudget from '../../components/dashboard/cost-budget'
import CostsByService from '../../components/dashboard/costs-by-service'
//import keysMock from './mocks/keys'
//import goalsMock from './mocks/goals'
//import servicesMock from './mocks/services'
//import costsMock from './mocks/costs'

import '../../components/dashboard/index.scss'
import { checkPermission } from '../../utils/helperFunctions'

const DashboardProperty = ({
  location,
  context,
  dashboard,
  getKeysSummary,
  getServicesSummary,
  getChartsSummary,
  getGoalsSummary,
  getAutomaticGoalsSummary,
  getCostSummary,
  getTurnSummary,
  auth,
  user
}) => {
  const { user_metadata } = user || {}
  const { auto_goals } = user_metadata || {}

  useEffect(() => {
    if (
      user &&
      user.user_metadata &&
      auth.isAuthenticated &&
      auth.hasMgmtToken
    ) {
      if (!auto_goals) {
        getAutomaticGoalsSummary(user.user_metadata.pdbid, context)
      } else {
        getGoalsSummary(user.user_metadata.pdbid, context)
      }
      getServicesSummary(user.user_metadata.pdbid, context)
      getKeysSummary(user.user_metadata.pdbid, context)
      getChartsSummary(user.user_metadata.pdbid, context)
      getCostSummary(user.user_metadata.pdbid, context)
      getTurnSummary(user.user_metadata.pdbid, context)
    }
  }, [auth, user, context, auto_goals])

  const chartsSection = _.get(
    dashboard,
    `charts.${context.project}_${context.property}`
  )
  const keysSection = _.get(
    dashboard,
    `services.${context.project}_${context.property}.keysSummary`
  )
  const servicesSection = _.get(
    dashboard,
    `services.${context.project}_${context.property}.servicesSummary`
  )
  const goalsSection = _.get(
    dashboard,
    `goals.${context.project}_${context.property}`
  )
  const costSection = _.get(
    dashboard,
    `cost.${context.project}_${context.property}`
  )
  const turnSection = _.get(
    dashboard,
    `turn.${context.project}_${context.property}`
  )

  const chartsIsLoading = _.get(chartsSection, 'isRequesting', true)
  const chartsIsError = _.get(chartsSection, 'isError', false)
  const chartsData = _.get(chartsSection, 'charts', [])
  const keysIsLoading = _.get(keysSection, 'isRequesting', true)
  const keysIsError = _.get(keysSection, 'isError', false)
  const keysData = _.get(keysSection, 'summaryCards', [])
  const servicesIsLoading = _.get(servicesSection, 'isRequesting', true)
  const servicesIsError = _.get(servicesSection, 'isError', false)
  const servicesData = _.get(servicesSection, 'summaryCards', [])
  const goalsIsLoading = _.get(goalsSection, 'isRequesting', true)
  const goalsIsError = _.get(goalsSection, 'isError', false)
  const turnIsLoading = _.get(turnSection, 'isRequesting', true)
  const turnIsError = _.get(turnSection, 'isError', false)
  const turnData = _.get(turnSection, 'data', [])
  const goalsData = _.get(goalsSection, 'bars', [])
  const costIsLoading = _.get(costSection, 'isRequesting', true)
  const costIsError = _.get(costSection, 'isError', false)
  const costData = _.get(costSection, 'rows', [])

  const keys = (
    <Keys
      loading={keysIsLoading}
      error={keysIsError}
      stats={_.get(_.find(keysData, d => d.id === 'keys'), 'stats', [])}
    />
  )

  const goals = (
    <Goals
      loading={goalsIsLoading}
      error={goalsIsError}
      data={goalsData}
      auto_goals={auto_goals}
    />
  )

  const services = (
    <ServicesCharts
      loading={servicesIsLoading}
      error={servicesIsError}
      summaryCards={servicesData}
    />
  )

  const beds = _.find(chartsData, c => c.group === 'beds') || []
  const units = _.find(chartsData, c => c.group === 'units') || []

  return (
    <div className="container dashboard-main">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns">
          <div className="column is-fourth">
            <DaysToMoveIn
              loading={chartsIsLoading}
              error={chartsIsError}
              days={chartsSection && chartsSection.daysToMoveIn}
            />
          </div>
          <div className="column is-fourth">
            <BedsTurned
              loading={chartsIsLoading}
              error={chartsIsError}
              turned={_.get(
                _.find(_.get(beds, 'data', []), b => b.category === 'Complete'),
                'value',
                0
              )}
              left={_.get(
                _.find(
                  _.get(beds, 'data', []),
                  b => b.category === 'Not Complete'
                ),
                'value',
                0
              )}
              noTurn={_.get(
                _.find(_.get(beds, 'data', []), b => b.category === 'No Turn'),
                'value',
                0
              )}
            />
          </div>
          <div className="column is-fourth">
            <UnitsTurned
              loading={chartsIsLoading}
              error={chartsIsError}
              turned={_.get(
                _.find(
                  _.get(units, 'data', []),
                  b => b.category === 'Complete'
                ),
                'value',
                0
              )}
              left={_.get(
                _.find(
                  _.get(units, 'data', []),
                  b => b.category === 'Not Complete'
                ),
                'value',
                0
              )}
              noTurn={_.get(
                _.find(
                  _.get(units, 'data', []),
                  b => b.category === 'Not Turn'
                ),
                'value',
                0
              )}
            />
          </div>
          <div className="column is-fourth">
            <HealthRating
              loading={false}
              error={false}
              stars={chartsSection && chartsSection.turnHealt}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-three-quarters">
            <div className="columns">
              <div className="column is-one-third">
                <div className="columns">
                  <div className="column is-full">
                    <Congrats
                      loading={false}
                      error={false}
                      percentage={10}
                      context={context}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-full">
                    <TodaysChallenge />
                  </div>
                </div>
              </div>
              <div className="column is-two-thirds">{keys}</div>
            </div>
            <div className="columns">
              <div className="column is-two-thirds">
                <TurnDetails
                  loading={turnIsLoading}
                  error={turnIsError}
                  beds={turnData.totalSpaces}
                  units={turnData.totalUnits}
                  renewals={turnData.totalRenewals}
                  newLeases={turnData.totalNewLeases}
                  unassigned={turnData.vacant}
                  fullyRenewed={turnData.fullyRenewed}
                  renewalTransferOut={turnData.renewalTransferOut}
                  renewalTransferIn={turnData.renewalTransferIn}
                  leapfrogs={turnData.leapfrogs}
                />
              </div>
              <div className="column is-one-third">
                <PercentageComplete
                  loading={chartsIsLoading}
                  error={chartsIsError}
                  percentage={
                    chartsSection &&
                    parseFloat(chartsSection.turnCompletedPercentage)
                  }
                />
              </div>
            </div>
          </div>
          <div className="column is-one-quarter">
            <Calendar
              loading={chartsIsLoading}
              error={chartsIsError}
              moveOut={chartsSection && chartsSection.moveOutDate}
              moveIn={chartsSection && chartsSection.moveInDate}
              transfers={chartsSection && chartsSection.transfersDate}
              totalTurnDays={chartsSection && chartsSection.totalTurnDays}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">{goals}</div>
        </div>
        <div className="columns">
          <div className="column is-three-quarters">{services}</div>
          <div className="column is-one-quarter" style={{ marginTop: '2rem' }}>
            <div className="columns">
              <div className="column is-full">
                <TotalTurnCost
                  loading={costIsLoading}
                  error={costIsError}
                  costs={costData}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-full">
                <CostBudget
                  loading={costIsLoading}
                  error={costIsError}
                  costs={costData}
                />
              </div>
            </div>
            <div
              className="columns"
              style={{ height: 'calc(100% - 21rem - 1.5rem)' }}>
              <div className="column is-full">
                <CostsByService
                  loading={costIsLoading}
                  error={costIsError}
                  costs={costData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardProperty
