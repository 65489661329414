import React, { useCallback, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { camera, close, closeFilled } from 'ui/icons'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import './index.scss'

const InspectionFileUploadModal = props => {
  const {
    inspectionUploadMediaURL,
    onClose,
    context,
    addInspectionMedia,
    onDisplayUploadMedia,
    parentId,
    childId,
    state
  } = props || {}
  // Styles
  const baseStyle = {}

  const focusedStyle = {
    borderColor: '#2196f3'
  }

  const acceptStyle = {
    borderColor: '#00e676'
  }

  const rejectStyle = {
    borderColor: '#ff1744'
  }
  const [files, setFiles] = useState([])
  const [rejectedFiles, setRejectedFiles] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // Added Files
  const onDrop = useCallback((acceptedFiles = [], rejectedFiles = []) => {
    setErrorMessage('')
    const mapRejectedFiles = (rejectedFiles || []).map(file => file.errors)
    setRejectedFiles(mapRejectedFiles)
    const mapAcceptedFiles = (acceptedFiles || []).map(file => ({ file }))
    setFiles(curr => [...curr, ...mapAcceptedFiles])
  }, [])

  // Delete File
  const onDeleteFile = file => {
    setFiles(curr => (curr || []).filter(cFile => cFile.file !== file))
    setErrorMessage('')
  }

  useEffect(() => {
    if ((rejectedFiles || []).length !== 0) {
      setErrorMessage(
        'This file type is not supported. Accepted file types are JPEG, JPG, PNG, MP4.'
      )
      setRejectedFiles([])
    }
  }, [rejectedFiles])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg,image/png,image/jpg,video/mp4'
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const uploadMedia = async e => {
    setSubmitting(true)
    const { property, project } = context || {}
    const allFiles = []
    const generateId = uuidv4()
    const requests = await (files || []).map(currentMedia => {
      const { file } = currentMedia || {}
      const { name, type } = file || {}
      let checkType = (type || '').substring(0, (type || '').indexOf('/'))
      let mediaType = ''
      if (checkType === 'image') {
        mediaType = 'photo'
      }
      if (checkType === 'video') {
        mediaType = 'video'
      }
      const body = file || {}
      let fileName = name
      let random_number = Math.floor(Math.random() * 90000) + 10000
      let uuid_ = generateId
      let file_name = `${uuid_}_${random_number}_${fileName}`
      let path = `Inspections/${project}_${property}`
      let object_name = `${path}/${file_name}`
      let url = `${inspectionUploadMediaURL || ''}${object_name}`
      allFiles.push({
        file,
        fileName: file_name,
        type: mediaType || '',
        orientation: ''
      })
      return addInspectionMedia(url, body)
    })
    await Promise.all(requests)
      .then(() => {
        const updateFilesObject = (allFiles || []).map(currentMedia => {
          const { fileName, type, file } = currentMedia || {}
          return {
            fileName: fileName || '',
            type: type || '',
            preview: URL.createObjectURL(file || {})
          }
        })
        onDisplayUploadMedia(updateFilesObject, parentId, childId, state)
        setSubmitting(false)
        setErrorMessage('')
      })
      .catch(err => {
        setSubmitting(false)
        setErrorMessage('Something went wrong. Please try again later !!!')
      })
  }

  return (
    <div className="inspection-fileupload-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Photo & Video Upload
          </p>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full">
        <section className="container">
          <div className="upload-file-inner" {...getRootProps({ style })}>
            {(files || []).length === 0 ? (
              <div className="upload-file-area">
                <input {...getInputProps()} />
                <img src={camera} alt="Camera" />
                <p>Drag & Drop</p>
                or <a onClick={open}>browse files</a>
                <em>(Only JPEG, JPG, PNG, MP4 will be accepted)</em>
              </div>
            ) : (
              <ul className="list-group is-flex is-flex-wrap-wrap mt-2">
                {(files || []).map((currentFile, i) => {
                  const { file } = currentFile || {}
                  const { type } = file || {}
                  const preview = URL.createObjectURL(file || {})
                  return (
                    <li
                      key={i}
                      className="list-group-item list-group-item-success">
                      <div className="uploaded-file is-flex is-align-items-center">
                        {(type || '').includes('video') ? (
                          <video src={preview} />
                        ) : (
                          <img src={preview} alt="Selected File" />
                        )}
                        <button
                          onClick={e => {
                            e.preventDefault()
                            onDeleteFile(file)
                          }}>
                          <img src={closeFilled} alt="Remove File" />
                        </button>
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </section>
        {errorMessage !== '' && (
          <div className="notification is-danger is-light is-flex">
            <p>{errorMessage}</p>
            <button onClick={() => setErrorMessage('')}>
              <img src={closeFilled} alt="" />
            </button>
          </div>
        )}
        {(files || []).length !== 0 && (
          <div className="is-flex is-align-items-center action-items">
            <a onClick={open}>Add more photos/videos</a>
            <div className="action-items-buttons is-flex">
              <button className="button is-outlined" onClick={() => onClose()}>
                Cancel
              </button>
              <button
                className="button is-primary"
                onClick={e => uploadMedia(e)}>
                {isSubmitting ? 'Uploading' : 'Upload'}
                {isSubmitting && (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

InspectionFileUploadModal.propTypes = {
  onClose: PropTypes.func,
  addInspectionMedia: PropTypes.func,
  onDisplayUploadMedia: PropTypes.func,
  context: PropTypes.object,
  inspectionUploadMediaURL: PropTypes.string,
  parentId: PropTypes.string,
  childId: PropTypes.string,
  state: PropTypes.string
}

export default InspectionFileUploadModal
