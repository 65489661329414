import React, { useEffect } from 'react'
import Breadcrumbs from '../breadcrumbs'
import VendorLoginUserView from '../vendor-login-user-view'
import VendorLoginUserEdit from '../vendor-login-user-edit'
import VendorLoginUserAdd from '../vendor-login-user-add'
import { useContextChanged } from '../../helpers/hooks'

const VendorLoginUserMain = props => {
  const { location, history, context } = props
  const { contextChanged } = useContextChanged(context)

  let inner = <></>

  if (location.pathname?.includes('/setup/vendors/view')) {
    inner = <VendorLoginUserView {...props} />
  }
  if (location.pathname?.includes('/setup/vendors/edit')) {
    inner = <VendorLoginUserEdit {...props} />
  }
  if (location.pathname?.includes('/setup/vendors/add')) {
    inner = <VendorLoginUserAdd {...props} />
  }

  useEffect(() => {
    if (contextChanged) {
      return history.replace('/setup/vendors')
    }
  }, [contextChanged])

  return (
    <div className="container animated fadeIn">
      <div className="section vendor-login-manage-section">
        <Breadcrumbs location={location} />
        <div style={{ marginTop: '-25px' }}>
          <button
            className="go-back-button"
            onClick={e => {
              e.preventDefault()
              history.goBack()
            }}>
            Back to Vendor
          </button>
        </div>
      </div>
      <div className="section">{inner}</div>
    </div>
  )
}

export default VendorLoginUserMain
