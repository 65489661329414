import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  percent,
  useTheme,
  create,
  ColorSet,
  color
} from '@amcharts/amcharts4/core'
import { PieChart, PieSeries } from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import Short from 'short-uuid'
import './index.scss'

/**
 * Component for showing a card with a Pie Chart within it
 *
 * @component
 * @example
 * const chart = {
 *   "group": "beds",
 *   "data": [
 *     {
 *         "category": "Complete",
 *         "value": 151
 *     },
 *     {
 *         "category": "Not Complete",
 *         "value": 339
 *     }
 *   ]
 * }
 * return (
 *   <PieChartCard
 *      showLoading={false}
 *      title={chart.group}
 *      isDonut
 *      centerLabel="151/490"
 *      data={chart.data}
 *      useColors={['#55c2b8', '#d4d8dd']}
 *   />
 * )
 */
const PieChartCard = ({
  useColors = [],
  isDonut = false,
  centerLabel = null,
  data,
  title,
  className = '',
  minHeight = 0
}) => {
  const chart = useRef()
  const uuid = useMemo(() => Short.uuid(), [])

  useEffect(() => {
    makeChart()
    return () => {
      if (chart.current) {
        chart.current.dispose()
      }
    }
  }, [])

  const makeCenterLabel = (newChart, text) => {
    let label = newChart.createChild(Label)
    label.text = text
    label.fontSize = 16
    label.isMeasured = false
    label.x = percent(50)
    label.horizontalCenter = 'middle'
    label.y = percent(50)
    label.verticalCenter = 'middle'
  }

  const makeChart = () => {
    useTheme(am4themes_animated)
    const newChart = create(`pie-chart-${uuid}`, PieChart)
    newChart.data = data

    let innerRadius = 0
    let disableLabels = false
    if (isDonut) {
      innerRadius = 65
      disableLabels = true
      if (typeof centerLabel === 'string')
        makeCenterLabel(newChart, centerLabel)
    } else {
      newChart.height = percent(60)
    }

    newChart.innerRadius = percent(innerRadius)

    // Add and configure Series
    let pieSeries = newChart.series.push(new PieSeries())
    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'category'
    pieSeries.slices.template.strokeWidth = 0
    pieSeries.labels.template.radius = percent(-40)
    pieSeries.labels.template.disabled = disableLabels
    //pieSeries.ticks.template.disabled = true
    pieSeries.alignLabels = true
    //pieSeries.labels.template.fill = color('white')
    pieSeries.labels.template.scale = 0.6
    pieSeries.hoverOnFocus = false
    pieSeries.startAngle = -90
    pieSeries.endAngle = 270
    pieSeries.interactionsEnabled = false

    pieSeries.labels.template.adapter.add('radius', function(radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return percent(-10)
      }
      return radius
    })

    var colorSet = new ColorSet()
    colorSet.list = useColors.map(c => {
      return new color(c)
    })
    pieSeries.colors = colorSet

    // This creates initial animation
    if (isDonut) {
      pieSeries.hiddenState.properties.opacity = 1
      pieSeries.hiddenState.properties.endAngle = -90
      pieSeries.hiddenState.properties.startAngle = -90
    }

    chart.current = newChart
  }

  return (
    <div
      className={`pie-chart-card box ${className || ''} animated flipInX`}
      style={{ minHeight }}>
      <p className="pie-chart-title is-size-5">{title}</p>
      <div className={`pie-chart-${uuid} has-text-centered`}></div>
    </div>
  )
}

PieChartCard.propTypes = {
  /**
   * Label for chart title
   */
  title: PropTypes.string,
  /**
   * Series to be rendered
   */
  data: PropTypes.array,
  /**
   * Determines if the chart should be a donut or a regular pie chart
   */
  isDonut: PropTypes.bool,
  /**
   * Text to show in the middle of the chart
   */
  centerLabel: PropTypes.string,
  /**
   * Additional classes to be included in main DIV
   */
  className: PropTypes.string,
  /**
   * Color array to be used for series
   */
  useColors: PropTypes.array
}

export default PieChartCard
