import React from 'react'
import { capitalize } from '../../utils/helperFunctions'
import ActionColumn from './edition-table/edition-columns/action-column'
import NameColumn from './edition-table/edition-columns/name-column'
import StatusColumn from './edition-table/edition-columns/status-column'
import UsedColumn from './edition-table/edition-columns/used-column'
import UnitSpaceColumn from './edition-table/edition-columns/unit-space-column'
import LinkColumnFilter from './edition-table/edition-filter/link-column-filter'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import { question, questionFilled } from 'ui/icons'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export const headerData = ({
  singularType,
  labelKey,
  idKey,
  removeKey,
  onAddSpaceHandler,
}) => {
  return [
    {
      Header: capitalize(singularType),
      accessor: labelKey,
      // Filter: NameColumnFilter,
      textAlign: 'left',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      flexGrow: 1,
      Cell: row => {
        return <NameColumn row={row} type={singularType} />
      }
    },
    {
      Header: 'Link',
      textAlign: 'center',
      show: singularType === 'space' ? true : false,
      accessor: 'link',
      Filter: LinkColumnFilter,
      width: 90,
      Cell: row => {
        return <UnitSpaceColumn row={row} />
      }
    },
    {
      Header: () => (
        <div className="info-container">
          <span className="m-r-5">Status</span>
          <Tippy
            appendTo={() => document.body}
            content={
              <div className="edition-list__tooltip">
                <p>
                  <strong>Active</strong> means that an element can be added to
                  templates and forms.
                </p>
                <p className="inactive-text">
                  <strong>Inactive</strong> means that an element cannot be
                  added to templates or forms. If an element was added to a
                  template or form before it was marked “Inactive,” it will
                  remain on that template or form.
                </p>
              </div>
            }
            placement="bottom-start"
            allowHTML
            theme="light">
            <img
              src={question}
              alt="help"
              onMouseOver={e => (e.currentTarget.src = questionFilled)}
              onMouseLeave={e => (e.currentTarget.src = question)}
            />
          </Tippy>
        </div>
      ),
      accessor: 'status',
      disableFilters: true,
      textAlign: 'center',
      width: 150,
      Cell: row => {
        return <StatusColumn row={row} />
      }
    },
    {
      Header: 'Used',
      textAlign: 'left',
      accessor: removeKey,
      disableFilters: true,
      width: 60,
      Cell: row => {
        return <UsedColumn row={row} />
      }
    },
    {
      Header: '',
      accessor: idKey,
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        return (
          <ActionColumn
            row={row}
            type={singularType}
            onAddSpaceHandler={onAddSpaceHandler}
          />
        )
      }
    }
  ]
}
