import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import MultiSelectDropdown from '../../../../../components/multi-select-dropdown'
import { selectStyles } from '../../DataTable/pricing-form-table/singleMultiSelectStyles'

const UnitField = ({ row, onHandleAmountChange }) => {
  const { cell } = row || {}
  const {
    row: {
      index,
      original: { unit_id: initialValue, unit }
    }
  } = cell || {}

  const [value, setValue] = useState([{ unit_id: initialValue, unit }])
  const { allUnits } = useSelector(state => state.charge)
  const { data: allUnitsData } = allUnits || {}

  return (
    <div className="cell-item unit-option">
      <MultiSelectDropdown
        displayKey="unit"
        value={(value[0]?.unit_id ?? []) !== '' ? value : []}
        defaultValues={allUnitsData}
        onChange={v => {
          setValue([v])
          onHandleAmountChange(v.unit_id, index, 'dropdown', {
            ...v,
            dropdowntype: 'unit-option'
          })
        }}
        isMulti={false}
        placeholder="Select Unit"
        noOptionsMessage="No Unit Available"
        menuPortalTarget={document.body}
        customStyles={selectStyles}
      />
      {/* <div className="select is-fullwidth select-wrapper">
        <select
          className="input is-fullwidth select-inner"
          value={value}
          onChange={e => {
            setValue(e.target.value)
            onHandleAmountChange(e.target.value, index, 'unit_id')
          }}>
          {!value ? <option value="">Select Unit</option> : null}
          {(allUnitsData || []).map((d, i) => (
            <option key={d.unit_id} value={d.unit_id}>
              {d.unit}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  )
}

export default UnitField
