import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ellipsis } from 'ui/icons'
import './index.scss'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const DataTableMenu = props => {
  const { options = [], row = {}, appendToBody = false } = props || {}

  const [ellipsisMenuOpened, setEllipsisMenuOpened] = useState(false)

  // Ellipsis menu options
  const renderOptions = row => {
    const checkData = Object.keys(row || {}).length !== 0
    if (checkData) {
      return (
        <ul>
          {(options || []).map((option, index) => (
            <li key={`option-trigger-${index}`}>
              <button
                className="options-button"
                disabled={option.disabled}
                onClick={e => {
                  if (!option.disabled) option.action()
                  else e.preventDefault()
                }}>
                <img
                  src={option.icon}
                  alt={option.caption}
                  className="p-r-xs"
                />
                <span className="options-button-label">{option.caption}</span>
              </button>
            </li>
          ))}
        </ul>
      )
    }
    return null
  }

  return (
    <div
      onMouseLeave={() => setEllipsisMenuOpened(false)}
      className="main-tippy">
      <Tippy
        interactive
        visible={ellipsisMenuOpened}
        content={renderOptions(row)}
        placement="bottom-end"
        className="table-action-menu"
        allowHTML
        distance={0}
        arrow={false}
        boundary={() => document.querySelector('.list-container')}
        appendTo={() => {
          if (appendToBody) {
            return document.body
          }
          return document.querySelector('.list-container')
        }}
        popperOptions={{
          modifiers: {
            flip: {
              boundariesElement: 'scrollParent'
            }
          }
        }}
        theme="menu">
        <img
          title="Click to open Menu"
          className="is-pointer"
          onClick={() => setEllipsisMenuOpened(!ellipsisMenuOpened)}
          style={{ marginBottom: '3px' }}
          src={ellipsis}
          alt="Options"
        />
      </Tippy>
    </div>
  )
}

DataTableMenu.propTypes = {
  /**
   * Menu Options
   */
  options: PropTypes.array,
  /**
   * Row data from which menu option is selected
   */
  row: PropTypes.object
}

export default DataTableMenu
