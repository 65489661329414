import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import UpdatePasswordMain from '../../parents/update-password-main'
import { updateContext } from '../../modules/context'
import { updateProfile } from '../../modules/user'
import BigLoading from '../../components/big-loading'

const UpdatePassword = props => {
  const { user } = props
  
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <UpdatePasswordMain {...props} />
  }

  return <BigLoading />
}

UpdatePassword.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
}

const mapStateToProps = ({ auth, user, context }) => ({
  auth,
  user,
  context,
})


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateContext,
      updateProfile
    },
    dispatch
  )



export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdatePassword)
)
