import React from 'react'

const LoginAccessModal = ({ onConfirm, loginAccess }) => {
  return (
    <>
      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6 sub-message">
            Do you want to turn the enable login ON as well or not ?
          </p>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => onConfirm(loginAccess)}>
            No
          </button>
          <button
            className="button main-button is-primary"
            onClick={() => onConfirm(true)}>
            Yes
          </button>
        </div>
      </div>
    </>
  )
}

export default LoginAccessModal
