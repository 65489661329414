import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import MultiSelectDropdown from '../../../../../components/multi-select-dropdown'
import { selectStyles } from '../../DataTable/pricing-form-table/singleMultiSelectStyles'

const ServiceOptionField = ({ row, onHandleAmountChange }) => {
  const { cell } = row || {}
  const {
    row: {
      index,
      original: { item_option_id: initialValue, item_option }
    }
  } = cell || {}
  const { pricingItemOptionList } = useSelector(state => state.setupVendors)

  const [value, setValue] = useState([
    { item_option_id: initialValue, item_option }
  ])

  return (
    <div className="cell-item service-option">
      <MultiSelectDropdown
        displayKey="item_option"
        uniqueKey="item_option_id"
        value={(value[0]?.item_option_id ?? []) !== '' ? value : []}
        defaultValues={pricingItemOptionList}
        onChange={v => {
          setValue([v])
          onHandleAmountChange(v.item_option_id, index, 'dropdown', {
            ...v,
            dropdowntype: 'service-option'
          })
        }}
        isMulti={false}
        placeholder="Select Service Option Type"
        noOptionsMessage="No Service Available"
        menuPortalTarget={document.body}
        customStyles={selectStyles}
      />
      {/* <div className="select is-fullwidth select-wrapper">
        <select
          className="input is-fullwidth select-inner"
          value={value}
          onChange={e => {
            setValue(e.target.value)
            onHandleAmountChange(e.target.value, index, 'item_option_id')
          }}>
          {!value ? <option value="">Select Service Type</option> : null}
          {(pricingItemOptionList || []).map(d => (
            <option key={d.item_option_id} value={d.item_option_id}>
              {d.item_option}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  )
}

export default ServiceOptionField
