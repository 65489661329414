import React from 'react'
import ChargeCellComponent from './components/ChargeCell'

export const headerData = () => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Vendor',
        accessor: 'vendor',
        textAlign: 'left',
        width: 130,
        Cell: row => {
          return <ChargeCellComponent row={row} />
        }
      },
      {
        Header: 'Service',
        accessor: 'servicetype',
        textAlign: 'left',
        width: 170,
        Cell: row => {
          return <ChargeCellComponent row={row} />
        }
      },
      {
        Header: 'Unit',
        accessor: 'full_unit',
        textAlign: 'left',
        width: 80,
        Cell: row => {
          return <ChargeCellComponent row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlign: 'left',
        width: 140,
        Cell: row => {
          return <ChargeCellComponent row={row} />
        }
      }
    ]
  }
]
