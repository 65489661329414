import React, { useState, useEffect, useMemo } from 'react'
import TabContainer from './components/TabContainer'
import _, { cloneDeep, isEmpty } from 'lodash'
import { ExportToExcel } from './components/helper-methods'
import { RouterPrompt } from '../../components/common/Prompt/RouterPrompt'
import usePageReload from '../../hooks/usePageReload'
import Breadcrumbs from '../breadcrumbs'
import BigLoading from '../../components/big-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { closeFilled } from 'ui/icons'
import ReactTooltip from 'react-tooltip'
import { useParams } from 'react-router-dom'
import { capitalize } from '../../utils/helperFunctions'
import DeleteRowModal from './components/DeleteRowModal'
import ConfirmationModal from '../../components/confirmation-modal'
import RestrictedElement from '../../containers/restricted-element'
import PermissionGate from '../../components/permission-gate'
import { useModals } from 'hooks'

import './index.scss'

const PricingMain = props => {
  const {
    allVendorServicePricing,
    user,
    context,
    setupVendors,
    updateBulkVendorServicePricing,
    allVendorPricingItemOption,
    allUnitsGet,
    updateSingleVendorServicePricing,
    openModal,
    setModalContent,
    closeModal,
    getAllPropertyUnitSpace
  } = props || {}
  const { serviceId, vendorId } = useParams()
  const initialState = [
    {
      id: 1,
      title: 'A la carte',
      content: [],
      tooltip:
        'A la Carte pricing is applied when no other pricing option is configured and applied.',
      error: false
    },
    {
      id: 2,
      title: 'Full Unit',
      content: [],
      tooltip:
        'Full Unit pricing is applied when all unit spaces require a service for a specific floorplan.',
      error: false
    },
    {
      id: 3,
      title: 'Full Unit Type',
      content: [],
      tooltip:
        'Full Unit Type pricing is applied when all unit spaces require a service for a specific floorplan and unit type.',
      error: false
    },
    {
      id: 4,
      title: 'Per Space',
      content: [],
      tooltip:
        'Per Space pricing is applied to a specific space within a specific floorplan.',
      error: false
    },
    {
      id: 5,
      title: 'Per Unit & Space',
      content: [],
      tooltip:
        'Per Unit and Space pricing is applied to a specific unit and a specific space within that unit.  This is the most granular pricing model.',
      error: false
    }
  ]
  const [isEdit, setIsEdit] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [filteredRows, setFilteredRows] = useState([])
  const [sortedData, setSortedData] = useState([])
  const [allFilter, clearAllFilter] = useState(false)
  const [tabs, setTabs] = useState(initialState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedTableRows, setSelectedTableRows] = useState([])
  const [showModal, dismissModal] = useModals()

  usePageReload(isUpdated)

  const getServiceValueFromURL = useMemo(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const service = urlParams.get('service')
    const vendorName = urlParams.get('vendor')
    return {
      service,
      vendorName
    }
  }, [window.location.search])

  const { pdbid } = user?.user_metadata

  const { servicePricing } = setupVendors || {}
  const { isRequesting, data: servicePricingData, hasMadeInitialRequest } =
    servicePricing || {}

  useEffect(() => {
    if (!isEmpty(servicePricingData)) {
      setTabs(servicePricingData)
    }
  }, [servicePricingData, setupVendors])

  useEffect(() => {
    const fetchData = async () => {
      await allVendorPricingItemOption(pdbid, context, serviceId)
      await allUnitsGet({
        pdbid,
        context,
        onSuccess: () => {},
        onError: () => {}
      })
      await getAllPropertyUnitSpace(pdbid, context)
      await allVendorServicePricing(pdbid, context, serviceId, vendorId)
    }

    fetchData()
  }, [serviceId, vendorId])

  const onHandleForceReload = () => {
    allVendorServicePricing(pdbid, context, serviceId, vendorId)
  }

  useEffect(() => {
    let cloneData = cloneDeep(tabs)
    const data = (cloneData || []).map(c_data => {
      if (c_data.id == currentTab) {
        c_data.content = filteredRows || []
      }
      return c_data
    })
    setSortedData(data)
  }, [filteredRows, currentTab, tabs])

  const checkErrors = filterData => {
    return (filterData || []).map(d => {
      let error = false
      let emptyRow = false

      if (d.id === 5) {
        const groupedItems = _.groupBy(
          d?.content || [],
          i =>
            `${(i.item_option_id || '').replace(' ', '_').toLowerCase()}-${
              i.unit_id
            }-${i.space}`
        )

        let groupedItemsData = Object.keys(groupedItems || {}) || []

        groupedItemsData.forEach(itemData => {
          if (groupedItems[itemData]?.length) {
            if (groupedItems[itemData]?.length > 1) {
              const { item_option_id, unit_id, space } = groupedItems[
                itemData
              ][0]

              if (
                [item_option_id, unit_id, space].every(item => !isEmpty(item))
              ) {
                error = true
              }
            }
          }
        })

        const foundEmptyRow = (d.content || []).find(
          row =>
            isEmpty(row.item_option_id) ||
            isEmpty((row.price || '').toString()) ||
            isEmpty(row.space) ||
            isEmpty(row.unit_id)
        )

        if (foundEmptyRow) {
          emptyRow = true
        } else {
          emptyRow = false
        }
      }
      return {
        ...d,
        error,
        emptyRow
      }
    })
  }

  const onChangeTabData = (tabid, index, filterValue, type, c_data) => {
    setErrorMessage('')

    setTabs(prevTabs => {
      const updatedTabs = (prevTabs || []).map(tab => {
        if (tab.id === tabid) {
          const updatedTabContent = tab?.content?.map((tContent, i) => {
            if (i === index) {
              if (type === 'dropdown') {
                if (!isEmpty(c_data)) {
                  if (c_data.dropdowntype === 'service-option') {
                    tContent.item_option = c_data.item_option
                    tContent.item_option_id = c_data.item_option_id
                  }
                  if (c_data.dropdowntype === 'unit-option') {
                    tContent.unit = c_data.unit
                    tContent.unit_id = c_data.unit_id
                  }
                  if (c_data.dropdowntype === 'space-option') {
                    tContent.space = c_data.unit_space
                  }
                }
              } else {
                tContent[type] = filterValue
              }
              if (
                tContent?.team_service_pricing_id === null ||
                tContent?.team_service_unit_pricing_id === null
              ) {
                tContent.actionType = 'Create'
              } else {
                tContent.actionType = 'Update'
              }
            }
            return tContent
          })

          return {
            ...tab,
            content: updatedTabContent
          }
        }
        return tab
      })

      return tabid === 5 ? checkErrors(updatedTabs) : updatedTabs
    })
    if (!isUpdated) {
      setIsUpdated(true)
    }
  }

  const onAddRow = tabid => {
    setErrorMessage('')

    setTabs(prevTabs => {
      const updatedTabs = (prevTabs || []).map(tab => {
        const { id } = tab || {}
        if (id === tabid) {
          tab.content.unshift({
            price: '',
            item_option_id: '',
            unit_id: '',
            space: '',
            team_service_unit_pricing_id: null,
            item_option: '',
            unit: ''
          })
        }
        return tab
      })
      return tabid === 5 ? checkErrors(updatedTabs) : updatedTabs
    })
  }

  const onDeleteRow = (tabid, index) => {
    setErrorMessage('')

    setTabs(prevTabs => {
      const updatedTabs = (prevTabs || []).map((tab, i) => {
        const { id } = tab || {}
        if (id === tabid) {
          const data = tab?.content
          data.splice(index, 1)
        }
        return tab
      })

      return tabid === 5 ? checkErrors(updatedTabs) : updatedTabs
    })

    if (!isUpdated) {
      setIsUpdated(true)
    }
  }

  const handleRemoveRows = (indexes = [], tabId) => {
    setErrorMessage('')

    setTabs(prevTabs => {
      const updatedTabs = (prevTabs || []).map((tab, i) => {
        return {
          ...tab,
          content:
            tab.id === tabId
              ? (tab.content || []).filter(
                  (_, index) => !indexes.includes(index)
                )
              : tab.content
        }
      })

      return tabId === 5 ? checkErrors(updatedTabs) : updatedTabs
    })
  }

  const getItemOptionId = data => {
    if (data?.item_option_id) {
      return data?.item_option_id
    }
    if (data?.service_group_id) {
      return data?.service_group_id
    }
    return null
  }

  const constructDataForBulkUpdate = data => {
    const cloneData = cloneDeep(data)
    const filterData = (cloneData || [])
      .filter(d => d.id !== 5)
      .flatMap(c => c.content)
    const contructData = (filterData || [])
      .filter(
        f_data =>
          f_data?.actionType === 'Update' || f_data?.actionType === 'Create'
      )
      .map(data => ({
        team_service_pricing_id: data?.team_service_pricing_id,
        item_option_id: getItemOptionId(data),
        floorplan_id: data?.floorplan_id ? data?.floorplan_id : null,
        unit_type_id: data?.unit_type_id ? data?.unit_type_id : null,
        space_id: data?.space ? data?.space : null,
        price: data?.price ? data?.price : ''
      }))
    const body = {
      items: contructData
    }
    return body
  }

  const constructDataForSingleUpdate = data => {
    const cloneData = cloneDeep(data)
    const filterData = (cloneData || [])
      .filter(d => d.id === 5)
      .flatMap(c => c.content)
    const contructData = (filterData || [])
      .filter(
        f_data =>
          f_data?.actionType === 'Update' || f_data?.actionType === 'Create'
      )
      .map(data => ({
        team_service_unit_pricing_id: data?.team_service_unit_pricing_id
          ? data?.team_service_unit_pricing_id
          : null,
        item_option_id: data?.item_option_id ? data?.item_option_id : null,
        unit_id: data?.unit_id ? data?.unit_id : null,
        space_id: data?.space ? data?.space : null,
        price: data?.price ? data?.price : '',
        action: null
      }))
    const body = {
      items: contructData
    }
    return body
  }

  const resetUserInteractionINForm = () => {
    setIsSubmitting(false)
    setErrorMessage('')
    onHandleForceReload()
    setIsEdit(false)
    setIsUpdated(false)
    clearAllFilter(!allFilter)
  }

  const onSingleUpdateSuccess = () => {
    resetUserInteractionINForm()
  }

  const onSingleUpdateError = () => {
    setIsSubmitting(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onBulkUpdateSuccess = () => {
    const singleUpdate = constructDataForSingleUpdate(tabs)
    if (singleUpdate?.items?.length) {
      return updateSingleVendorServicePricing(
        pdbid,
        context,
        serviceId,
        vendorId,
        singleUpdate,
        onSingleUpdateSuccess,
        onSingleUpdateError
      )
    }
    resetUserInteractionINForm()
  }

  const onBulkUpdateError = () => {
    setIsSubmitting(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onSave = () => {
    setErrorMessage('')
    ;(tabs || []).map(data => {
      const { id } = data || {}
      if (id === 5) {
        const removeEmptyRow = (data.content || []).filter(
          ({ item_option_id, price, space, unit_id }) =>
            item_option_id && price && space && unit_id
        )
        data.content = removeEmptyRow
      }
      return data
    })
    const bulkUpdate = constructDataForBulkUpdate(tabs)
    setIsSubmitting(true)
    return updateBulkVendorServicePricing(
      pdbid,
      context,
      serviceId,
      vendorId,
      bulkUpdate,
      onBulkUpdateSuccess,
      onBulkUpdateError
    )
  }

  const showToolTip = useMemo(() => {
    return (
      <ReactTooltip
        className="customTooltipTheme"
        id="savepricing"
        place="top"
        disable={!tabs[4]?.error && !tabs[4]?.emptyRow}
        effect="solid">
        <div>
          {tabs[4]?.error && (
            <p>
              In <strong>Per Unit & Space</strong> tab, there can be no two rows
              with the same Service Option, Unit and Space.
            </p>
          )}
          {tabs[4]?.emptyRow && (
            <p>
              In <strong>Per Unit & Space</strong> tab, please fill all the
              fields.
            </p>
          )}
        </div>
      </ReactTooltip>
    )
  }, [tabs[4]?.error, !tabs[4]?.emptyRow])

  const onHandleDeleteRowModal = (row, tabId) => {
    const constructRows = (row || []).map(r => r?.original)
    const selectedRowsIndex = (row || []).map(r => r?.index)

    setModalContent(() => (
      <DeleteRowModal
        closeModal={closeModal}
        rows={constructRows}
        indexes={selectedRowsIndex}
        tabId={tabId}
        {...props}
        serviceId={serviceId}
        vendorId={vendorId}
        onRemoveRows={handleRemoveRows}
        onHandleForceReload={onHandleForceReload}
      />
    ))
    openModal({ width: '480px' })
  }

  const resetFormForCancel = () => {
    const deleteEmptyRows = (tabs || []).map(data => {
      const { id } = data || {}
      if (id === 5) {
        const removeEmptyRow = (data.content || []).filter(
          ({ item_option_id, price, space, unit_id }) =>
            item_option_id && price && space && unit_id
        )
        data.content = removeEmptyRow
      }
      return data
    })
    setTabs(deleteEmptyRows)
    setIsSubmitting(false)
    setErrorMessage('')
    setIsEdit(false)
    setIsUpdated(false)
    clearAllFilter(!allFilter)
  }

  const onHandleCancelSave = () => {
    if (!isUpdated) {
      return resetFormForCancel()
    }
    showModal(
      { width: '480px' },
      <ConfirmationModal
        msg="Please save your changes before proceeding. If you do not save, the changes will be discarded."
        dismissLabel="Cancel"
        submitLabel="Discard"
        submit={() => {
          setTabs(servicePricingData)
          dismissModal()
          resetUserInteractionINForm()
        }}
        dismiss={dismissModal}
      />
    )
  }

  if (hasMadeInitialRequest && isRequesting) {
    return <BigLoading />
  }

  return (
    <>
      <RouterPrompt
        title="Please save your changes before proceeding to go back. If you do not save, the changes will be discarded."
        okText="Discard"
        cancelText="Cancel"
        when={isUpdated}
      />
      <div className="container setup-vendors-main animated fadeIn">
        <div className="section pricing-quotation">
          <Breadcrumbs location={location} />
          <button className="go-back-button" onClick={e => history.back()}>
            Back to List View
          </button>
          <div className="pricing-top-header columns">
            <div className="column is-three-quarters">
              <div className="pricing-header-container">
                <h2 className="is-capitalized">{`Service ${capitalize(
                  getServiceValueFromURL?.service || ''
                )}`}</h2>
              </div>
            </div>
          </div>
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>Something went wrong. Please try again later !!!</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
          <div className="pricing-list-container">
            <div className="pricing-table-wrapper">
              <div className="pricing-table-header">
                <div className="header">
                  <h2>{`${capitalize(
                    getServiceValueFromURL?.service || ''
                  )} Pricing`}</h2>
                  <PermissionGate name={'add-pricing'}>
                    <RestrictedElement>
                      <div>
                        {!isEdit ? (
                          <ExportToExcel
                            apiData={sortedData}
                            fileName={`${capitalize(
                              getServiceValueFromURL?.vendorName || 'Vendor'
                            )}_${capitalize(
                              getServiceValueFromURL?.service || 'Service'
                            )}_Pricing_Workbook`}
                          />
                        ) : null}
                        {isEdit ? (
                          <>
                            <button
                              onClick={() => {
                                onHandleCancelSave()
                              }}
                              className="button main-button is-secondary">
                              Cancel
                            </button>
                            <button
                              data-tip
                              data-for="savepricing"
                              onClick={() => {
                                if (!tabs[4]?.error && !tabs[4]?.emptyRow) {
                                  onSave()
                                }
                              }}
                              className={`button main-button is-primary ${
                                tabs[4]?.error || tabs[4]?.emptyRow
                                  ? 'button-disabled'
                                  : ''
                              }`}>
                              Save
                              {isSubmitting && (
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  className="m-l-sm"
                                />
                              )}
                            </button>
                            {showToolTip}
                          </>
                        ) : (
                          <button
                            onClick={() => {
                              setIsEdit(true)
                              clearAllFilter(!allFilter)
                            }}
                            disabled={tabs[4]?.error || tabs[4]?.emptyRow}
                            className="button main-button is-primary">
                            Edit
                          </button>
                        )}
                      </div>
                    </RestrictedElement>
                  </PermissionGate>
                </div>
              </div>
              <TabContainer
                tabData={tabs}
                isEdit={isEdit}
                onChangeTabData={onChangeTabData}
                onAddRow={onAddRow}
                onDeleteRow={onDeleteRow}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                setFilteredRows={setFilteredRows}
                clearAllFilter={allFilter}
                onHandleDeleteRowModal={onHandleDeleteRowModal}
                onRowSelectStateChange={setSelectedTableRows}
                selectedTableRows={selectedTableRows}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PricingMain
