import _ from 'lodash'
import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { serviceLabels } from './labels'
import DropdownTogglerBtn from '../../components/dropdown-toggler-btn'
import { currencyFormatter, numberFormatter } from '../../utils'
import './index.scss'

/**
 * Component for rendering a service summary card
 *
 * @component
 * @example
 * const options = [
 *   {
 *       "option_name": "Full Paint Common Area",
 *       "quantity": 1,
 *       "est_cost": 0
 *   },
 *   {
 *       "option_name": "Partial Paint Bed/Bath",
 *       "quantity": 19,
 *       "est_cost": 0
 *   },
 *   {
 *       "option_name": "Partial Paint Common Area",
 *       "quantity": 1,
 *       "est_cost": 0
 *   },
 *   {
 *       "option_name": "Full Paint Bed/Bath",
 *       "quantity": 305,
 *       "est_cost": 100
 *   }
 * ]
 * return (
 *   <ServiceCard
 *      service="Paint"
 *      value={30000}
 *      quantityTotal={100}
 *      budget={1000}
 *      options={options}
 *      className="p-none"
 *   />
 * )
 */

const getTotal = (total, options) => {
  if (total) return total
  return _.reduce(
    options,
    (acc, option) => acc + parseFloat(_.get(option, 'est_cost', 0)),
    0
  )
}

const getServiceLabel = (service, replaceLabels) =>
  _.get(
    serviceLabels,
    `${service.toLowerCase()}.${replaceLabels ? '1' : '0'}`,
    service
  )

const ServiceCardComponent = (
  {
    className = '',
    isSelected = false,
    showLoading = false,
    service,
    value,
    quantityTotal,
    budget,
    options,
    replaceLabels = false
  },
  ref
) => {
  const [detailOpened, setDetailOpened] = useState(false)

  return (
    <div
      className={`service-card box animated fadeInDown ${className} ${
        isSelected ? 'is-selected' : ''
      }`}
      ref={ref}>
      {showLoading ? (
        <div className="m-sm">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <>
          <div className="m-b-none m-t-sm">
            <div
              className="has-text-centered p-b-none"
              style={{ marginTop: 'auto' }}>
              <div className="service-card-header">
                {/* <img
                  className="m-r-md icon"
                  src={icons[service.toLowerCase()] || icons.flooring}
                  alt={service}
                /> */}
                <span className="p-r-sm heading-5">
                  {getServiceLabel(service, replaceLabels)}:
                </span>
                <span className="heading-5">{numberFormatter(quantityTotal)}</span>
              </div>
              <div className="service-card-subtitle">
                <p>Est. Total: {currencyFormatter(getTotal(value, options))}</p>
                <div className="divider"></div>
                <p>Budget: {currencyFormatter(budget)}</p>
              </div>
            </div>
          </div>
          <div className={`service-card-detail ${detailOpened ? 'open' : ''}`}>
            <div className="m-none is-size-7 table-container">
              <div className="p-t-sm p-b-sm">
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th className="value-column">Option</th>
                      <th className="quantity-column">
                        <span className="is-pulled-right">QTY</span>
                      </th>
                      <th className="cost-column">
                        <span className="is-pulled-right">Est. Cost</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(options, (option, index) => (
                      <tr key={Short.uuid()}>
                        <td
                          className={`value-column p-t-sm p-b-sm ${
                            index === 0
                              ? 'has-tooltip-bottom'
                              : 'has-tooltip-top'
                          }`}
                          data-tooltip={option.option_name}>
                          <span>{option.option_name}</span>
                        </td>
                        <td className="quantity-column">
                          <span className="is-pulled-right">
                            {option.quantity}
                          </span>
                        </td>
                        <td className="cost-column">
                          <span className="is-pulled-right">
                            {currencyFormatter(option.est_cost)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <DropdownTogglerBtn
            className="toggler m-t-sm m-b-sm p-l-none"
            toggle={() => setDetailOpened(!detailOpened)}
            dropwdownOpened={detailOpened}
          />
        </>
      )}
    </div>
  )
}

const ServiceCard = forwardRef(ServiceCardComponent)

ServiceCard.propTypes = {
  /**
   * Additional class for main DIV
   */
  className: PropTypes.string,
  /**
   * Determines if card is currently selected
   */
  isSelected: PropTypes.bool,
  /**
   * Determines if data is currently being loaded
   */
  showLoading: PropTypes.bool,
  /**
   * Name of the service
   */
  service: PropTypes.string,
  /**
   * Current value for the service
   */
  value: PropTypes.number,
  /**
  /**
   * Total of executed services
   */
  quantityTotal: PropTypes.number,
  /**
   * Original budget for the service
   */
  budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Options for the service
   */
  options: PropTypes.array,
  /**
   * Determines if the labels should be replaced with their alternate names -- Temporary fix
   */
  replaceLabels: PropTypes.bool
}

export default ServiceCard
