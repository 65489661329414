import React from 'react'
import PropTypes from 'prop-types'
import PricingFormTable from './pricing-form-table/pricing-table'

const DataTable = ({
  defaultcolumns = [],
  defaultdata = [],
  setFilteredRows,
  clearAllFilter,
  isEdit,
  onRowSelectStateChange
}) => {
  return (
    <PricingFormTable
      data={defaultdata}
      tableColumns={defaultcolumns}
      setFilteredRows={setFilteredRows}
      clearAllFilter={clearAllFilter}
      isEdit={isEdit}
      onRowSelectStateChange={onRowSelectStateChange}
    />
  )
}

DataTable.propTypes = {
  defaultcolumns: PropTypes.array,
  defaultdata: PropTypes.array,
  clearAllFilter: PropTypes.bool,
  isEdit: PropTypes.bool,
  setFilteredRows: PropTypes.func,
  onRowSelectStateChange: PropTypes.func
}

export default DataTable
