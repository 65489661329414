import React from 'react'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { question, questionFilled } from 'ui/icons'
import TabContentContainer from './TabComponent'

const TabContainer = ({
  tabData,
  isEdit,
  onChangeTabData,
  onAddRow,
  onDeleteRow,
  onHandleDataSorting,
  setCurrentTab,
  currentTab,
  setFilteredRows,
  clearAllFilter,
  onHandleDeleteRowModal,
  onRowSelectStateChange,
  selectedTableRows
}) => {
  const onHandleTabClick = (e, id) => {
    setCurrentTab(e?.target?.id)
  }

  return (
    <div className="pricing-tab-container">
      <div className="tab-option-container">
        {(tabData || []).map((tab, i) => {
          return (
            <button
              className={currentTab === `${tab.id}` ? 'active' : ''}
              key={i}
              id={tab.id}
              onClick={e => onHandleTabClick(e)}>
              {tab.title}
              <Tippy
                appendTo={() => document.body}
                content={
                  <div className="content">
                    <p>{tab.tooltip}</p>
                  </div>
                }
                placement="bottom-start"
                allowHTML
                theme="light">
                <img
                  className="tab-info"
                  src={question}
                  alt="help"
                  id={tab.id}
                  onClick={e => onHandleTabClick(e)}
                  onMouseOver={e => (e.currentTarget.src = questionFilled)}
                  onMouseLeave={e => (e.currentTarget.src = question)}
                />
              </Tippy>
            </button>
          )
        })}
      </div>
      <div className="tab-content-container">
        {(tabData || []).map((tab, i) => {
          return (
            <div key={i}>
              {currentTab === `${tab.id}` && (
                <TabContentContainer
                  tabData={tab}
                  isEdit={isEdit}
                  onChangeTabData={onChangeTabData}
                  onAddRow={onAddRow}
                  onDeleteRow={onDeleteRow}
                  onHandleDataSorting={onHandleDataSorting}
                  setFilteredRows={setFilteredRows}
                  clearAllFilter={clearAllFilter}
                  onHandleDeleteRowModal={onHandleDeleteRowModal}
                  onRowSelectStateChange={onRowSelectStateChange}
                  selectedTableRows={selectedTableRows}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TabContainer
