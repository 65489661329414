import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import context from './context'
import user from './user'
import sidebar from './sidebar'
import search from './search'
import keys from './keys'
import requestVendors from './request-vendors'
import requestRFPS from './request-rfp'
import vendorServices from './vendor-services'
import vendorQuotation from './vendor-quotation'
import requestsForProposal from './requests-for-proposal'
import responsive from './responsive'
import alerts from './alerts'
import modal from './modal'
import services from './services'
import tooltip from './tooltip'
import dashboard from './dashboard'
import holdovers from './holdovers'
import schedule from './schedule'
import setupUsers from './setup-users'
import setupVendors from './setup-vendors'
import setupBudgets from './setup-budgets'
import setupProperties from './setup-properties'
import setupServices from './setup-services'
import setupStaff from './setup-staff'
import setupTeams from './setup-teams'
import setupGoals from './setup-goals'
import turnboard from './turnboard'
import inspect from './inspect'
import inspectActivity from './inspect-activity'
import invoices from './invoices'
import companyTemplates from './company-templates'
import propertyForms from './property-forms'
import vendor from './vendor'
import qc from './qc'
import notes from './notes'
import charge from './charge'
import approval from './approval'
import downloadInspections from './download-inspections'
import damage from './damages'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    context,
    user,
    sidebar,
    search,
    keys,
    requestVendors,
    requestRFPS,
    vendorServices,
    vendorQuotation,
    requestsForProposal,
    responsive,
    alerts,
    modal,
    services,
    tooltip,
    dashboard,
    holdovers,
    schedule,
    setupUsers,
    setupVendors,
    setupBudgets,
    setupProperties,
    setupServices,
    setupStaff,
    setupTeams,
    setupGoals,
    turnboard,
    inspect,
    inspectActivity,
    invoices,
    companyTemplates,
    propertyForms,
    vendor,
    qc,
    notes,
    charge,
    approval,
    downloadInspections,
    damage
  })
export default createRootReducer
