import React, { useEffect, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { headerData } from './header'
import { Link } from 'react-router-dom'
import { emptyPage, warning, exportFile, closeFilled } from 'ui/icons'
import { resetPropertyFormList } from '../../modules/property-forms'
import RestrictedElement from '../../containers/restricted-element'
import BigLoading from '../../components/big-loading'
import { formatDateString } from '../../utils'
import PropertyFormTable from './property-form-table/property-form-table'
import './index.scss'
import PermissionGate from '../../components/permission-gate'
import NewPropertyFormModal from '../../parents/new-property-form-modal'
import { useModals } from 'hooks'

const PropertyFormsList = ({ history, user, propertyForms, context }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [showModal, dismissModal] = useModals()

  const tableRef = useRef(null)

  const { list: data, sharedTemplates, listIsError } = propertyForms

  const exportCSV = () => {
    const { properties, projects } = user.user_metadata
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const rows = tableRef.current?.getRows() || []
    const selectedRows = tableRef.current?.getSelectedRows() || []

    const dataToExport = (selectedRows.length ? selectedRows : rows || []).map(
      data => ({
        name: `"${data.name}"` || '',
        created_by: `"${data.created_by_name}"` || '',
        created_at: `"${data.created_at}"` || '',
        template: `"${data.template}"` || '',
        type: `"${data.type}"` || '',
        state: `"${data.state}"` || ''
      })
    )

    const keys = [
      'Form Name',
      'Created By',
      'Created Date',
      'Template Used',
      'Type',
      'State'
    ]

    const csvData = [keys]
      .concat(dataToExport.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')

    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-forms-${formatDateString(
        new Date(),
        'MM_DD_YYYY'
      )}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns = useMemo(() => headerData(), [])
  const memoizedData = useMemo(() => data || [], [data])

  useEffect(() => {
    return () => {
      dispatch(resetPropertyFormList())
    }
  }, [])

  useEffect(() => {
    if (listIsError) setError('Something went wrong, please try again later.')
  }, [listIsError])

  const addForm = () => {
    showModal(
      { width: '480px' },
      <NewPropertyFormModal
        sharedTemplates={sharedTemplates}
        dismiss={dismissModal}
        history={history}
      />
    )
  }

  const getLoadingState = () => <BigLoading />

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">Looks like there are no forms yet!</p>
        <PermissionGate name="create-or-modify-property-forms">
          <p className="has-text-grey is-size-7 m-t-sm">
            Let's get started by adding one new form.
          </p>
          <button className="button is-primary m-t-md" onClick={addForm}>
            Add Form
          </button>
        </PermissionGate>
      </div>
    </div>
  )

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  if (!propertyForms.listHasRequested || propertyForms.listIsRequesting) {
    return getLoadingState()
  }
  if (!error && propertyForms?.list?.length < 1) {
    return getEmptyState()
  }

  return (
    <div className="form-list-container">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">All Property Forms</h2>
          </div>
          <div className="action-button">
            <div className="template-action-buttons ">
              <PermissionGate name="create-or-modify-property-forms">
                <button
                  className="button is-success"
                  type="button"
                  onClick={addForm}>
                  New Form
                </button>
              </PermissionGate>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <RestrictedElement>
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </RestrictedElement>
          </div>
          {error !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{error}</p>
                <button onClick={() => setError('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        <PropertyFormTable
          ref={tableRef}
          data={memoizedData}
          tableColumns={columns}
          onRowSelectStateChange={setSelectedRows}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
    </div>
  )
}

PropertyFormsList.propTypes = {
  propertyForms: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object
}

export default PropertyFormsList
