import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

/**
 * Component for showing a progress bar
 *
 * @component
 * @example
 * return (
 *   <ProgressBar
 *      percentComplete={60}
 *   />
 * )
 */
const ProgressBar = ({ percentComplete }) => (
  <div className="progress-bar is-fullwidth has-background-light">
    <div
      className="progress-bar-inner has-background-success"
      style={{ width: `${percentComplete}%` }}
    />
  </div>
)

ProgressBar.propTypes = {
  percentComplete: PropTypes.number
}

export default ProgressBar
