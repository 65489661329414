import Service from '../service'
import LOGOUT from './auth'
import { getProjectId } from './dashboard-helpers'

/**
 * Dashboard charts data module
 * @redux
 * @reduxActionScope dashboard-charts
 * @module dashboard-charts
 */

/**
 * Request dashboard charts data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_CHARTS_SUMMARY_REQUESTED =
  'dashboard/DASHBOARD_CHARTS_SUMMARY_REQUESTED'
/**
 * Dashboard charts data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_CHARTS_SUMMARY_FAIL =
  'dashboard/DASHBOARD_CHARTS_SUMMARY_FAIL'
/**
 * Update dashboard charts with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_CHARTS_SUMMARY_SUCCESS =
  'dashboard/DASHBOARD_CHARTS_SUMMARY_SUCCESS'

const initialPropertyState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  charts: [],
  turnHealt: 0,
  daysToMoveIn: 0,
  totalTurnDays: 0,
  moveInDate: null,
  moveOutDate: null,
  transfersDate: null,
  turnCompletedPercentage: 0
}

const initialState = {}

/**
 * Dashboard charts reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_CHARTS_SUMMARY_REQUESTED:
      const rData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...rData,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case DASHBOARD_CHARTS_SUMMARY_SUCCESS:
      const sData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...sData,
          isRequesting: false,
          isError: false,
          charts: action.charts,
          turnHealt: action.turnHealt,
          daysToMoveIn: action.daysToMoveIn,
          totalTurnDays: action.totalTurnDays,
          moveInDate: action.moveInDate,
          moveOutDate: action.moveOutDate,
          transfersDate: action.transfersDate,
          turnCompletedPercentage: action.turnCompletedPercentage
        }
      }
    case DASHBOARD_CHARTS_SUMMARY_FAIL:
      const fData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...fData,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Updates dashboard charts data
 * @redux
 * @reduxActionCreator DASHBOARD_CHARTS_SUMMARY_REQUESTED, DASHBOARD_CHARTS_SUMMARY_SUCCESS, DASHBOARD_CHARTS_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getChartsSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_CHARTS_SUMMARY_REQUESTED,
      context
    })
    return Service.getDashboardChartsSummary(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const charts = await res.json()
            dispatch({
              type: DASHBOARD_CHARTS_SUMMARY_SUCCESS,
              charts: summaryChartsAdapter(charts),
              daysToMoveIn: parseInt(charts.daysToMoveIn),
              totalTurnDays: parseInt(charts.totalTurnDays),
              turnHealt: charts.stars,
              moveInDate: dateAdapter(charts.moveIn),
              moveOutDate: dateAdapter(charts.moveOut),
              transfersDate: dateAdapter(charts.transfers),
              turnCompletedPercentage: parseFloat(charts.percent_complete).toFixed(2),
              context
            })
          } catch {
            _chartsSummaryFail(dispatch, context)
          }
        } else {
          _chartsSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _chartsSummaryFail(dispatch, context)
        throw err
      })
  }
}

const _chartsSummaryFail = (dispatch, context) => {
  dispatch({
    type: DASHBOARD_CHARTS_SUMMARY_FAIL,
    context
  })
}

const summaryChartsAdapter = data => {
  const charts = []
  if (data.total_beds) {
    charts.push({
      group: 'beds',
      data: [
        {
          category: 'Complete',
          value: parseInt(data.beds_turned || 0)
        },
        {
          category: 'Not Complete',
          value: parseInt(data.beds_left || 0)
        },
        {
          category: 'No Turn',
          value: parseInt(data.beds_noturn || 0)
        },
      ]
    })
  }
  if (data.total_units) {
    charts.push({
      group: 'units',
      data: [
        {
          category: 'Complete',
          value: parseInt(data.units_turned || 0)
        },
        {
          category: 'Not Complete',
          value: parseInt(data.units_left || 0)
        },
        {
          category: 'Not Turn',
          value: parseInt(data.units_noturn || 0)
        }
      ]
    })
  }
  if (data.total_turn_days) {
    charts.push({
      group: 'days',
      data: [
        {
          category: 'Passed',
          value: parseInt(
            data.total_turn_days - (data.total_remaining_days || 0)
          )
        },
        {
          category: 'Remaining',
          value: parseInt(data.total_remaining_days || 0)
        }
      ]
    })
  }
  return charts
}

const dateAdapter = stringDate => new Date(stringDate.replace('-', ','))
