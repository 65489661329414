import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import PermissionGate from '../../components/permission-gate'
import DataTable from '../../components/data-table'
import SetupUsersModalDelete from '../setup-users-modal-delete'
import { headerData, pivotConfig } from './header'
import { checkPermission } from '../../security'
import { icons } from './icons'
import './index.scss'
import BigLoading from '../../components/big-loading'
import { downloadCSV } from '../../utils'

const formatDate = value =>
  moment(value).isValid() ? moment(value).format('MM/DD/YYYY hh:mm a') : 'N/A'

const SetupUsersList = ({
  user,
  isLoading,
  history,
  openModal,
  setModalContent,
  closeModal,
  openTooltip,
  setTooltipContent,
  closeTooltip,
  tooltip,
  setupUsers,
  setupOneUserDelete,
  setupOneUserBlock,
  context
}) => {
  const tableRef = useRef(null)

  const [filterText, setFilterText] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [selectedUserBlocked, setselectedUserBlocked] = useState(false)
  const [activeRow, setActiveRow] = useState('')

  const { allUsers } = setupUsers || {}
  const { isRequesting, data } = allUsers || {}

  const activeRowUser = useMemo(() => {
    return (filteredData || []).find(user => user.user_id === activeRow)
  }, [activeRow, filteredData])

  const isPropertyManager = useMemo(() => {
    const { roles } = user || {}

    return (roles || []).some(role => role === 'Property Manager')
  }, [user])

  const showActionDropdown = useMemo(() => {
    const { userRole } = activeRowUser || {}

    return !(isPropertyManager && userRole?.name === 'Administrator')
  }, [user, activeRowUser, isPropertyManager])

  const isCurrentUserIsLoggedInUser = useMemo(() => {
    return isPropertyManager && user?.user_id === activeRowUser?.user_id
  }, [user, activeRowUser, isPropertyManager])

  const dropDownOptions = useMemo(() => {
    return [
      {
        available: () => true,
        icon: icons.edit,
        caption: 'Edit User',
        name: 'manage-user',
        action: row =>
          history.push({
            pathname: '/setup/users/detail/edit',
            state: {
              id: row || {}
            }
          })
      },
      {
        available: () => !isCurrentUserIsLoggedInUser,
        icon: icons.lock,
        name: 'manage-user',
        caption: selectedUserBlocked ? 'Reactivate User' : 'Block User',
        action: row => showBlockModal(row)
      }
    ]
  }, [isCurrentUserIsLoggedInUser, selectedUserBlocked])

  useEffect(() => {
    if (!isRequesting) {
      const search = new RegExp(filterText, 'g')
      const sortedData = _.orderBy(
        data,
        [user => user.name.toUpperCase()],
        'asc'
      )

      setFilteredData(
        _.filter(
          sortedData,
          u => u.name.match(search) || u.email.match(search)
        ).map(user => {
          if (!user?.projects?.length && !user?.properties?.length) {
            return {
              ...user,
              cannotExpand: true
            }
          }
          return user
        })
      )
    }
  }, [isRequesting, filterText, data])

  const getNameTooltip = data => {
    const { name, nickname } = data.name_tooltip
    return (
      <div className="p-md">
        <div className="m-b-sm">Name: {name}</div>
        <div className="m-b-sm">Nickname: {nickname}</div>
      </div>
    )
  }

  const showBlockModal = user => {
    setModalContent(() => (
      <SetupUsersModalDelete
        closeModal={closeModal}
        setupOneUserDelete={setupOneUserDelete}
        setupOneUserBlock={setupOneUserBlock}
        userId={user.user_id}
        user={user}
      />
    ))
    openModal({ width: '480px' })
  }

  const showUserDropdownOptions = () => {
    if (checkPermission('manage-user', user)) {
      if (showActionDropdown) {
        return dropDownOptions
      }
      return [{ available: () => false }]
    }

    return []
  }

  const handleFilterText = text => {
    const alphaNumericWithSpecialChars = /^[ A-Za-z0-9_@.+-]*$/g
    const startsWithSpecialChar = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g
    const notHasConsecutiveSpecialChars = /^(?!.*[\%\/\\\&\?\,\'\;\:\!\+\@\_\.\$\#\*\^\-]{2}).*$/

    const shouldSearch =
      alphaNumericWithSpecialChars.test(text) &&
      !startsWithSpecialChar.test(text) &&
      notHasConsecutiveSpecialChars.test(text)

    if (shouldSearch) {
      setFilterText(text)
    }
  }

  const exportCSV = () => {
    const users = tableRef.current?.getRows() || []

    const dataToExport = users.map(data => ({
      name: data.name,
      email: data.email,
      status: data.status,
      last_login: data.last_login || 'N/A',
      roles: data.roles
    }))

    downloadCSV(user, context, dataToExport)
  }

  return (
    <>
      <DataTable
        title="Users"
        ref={tableRef}
        header={headerData}
        additionalClasses={'user-list-table'}
        data={filteredData}
        textFilter={filterText}
        onExport={exportCSV}
        onSetTextFilter={handleFilterText}
        pivotConfig={pivotConfig}
        isLoading={isLoading}
        iconClicked={() => {}}
        setSelectedUserStatus={setselectedUserBlocked}
        resetPage={false}
        hasCheckboxes={false}
        singleSelection
        methods={{
          openNameTooltip: (row, e) => {
            if (e.target.localName === 'a') {
              const height = 100
              const width = 350
              const isLast = false
              const rect = e.target.getBoundingClientRect()
              const onTop = rect.top - height - 10
              const onBottom = rect.top + rect.height + 10
              if (!tooltip.isActive)
                openTooltip(
                  width,
                  height,
                  isLast ? onTop : onBottom,
                  rect.left - width / 2 + rect.width / 2,
                  e.target
                )
              setTooltipContent(getNameTooltip(row))
            }
          },
          onDeleteUser: userId => showBlockModal(userId)
        }}
        tooltip={tooltip}
        openTooltip={openTooltip}
        closeTooltip={closeTooltip}
        setTooltipContent={setTooltipContent}
        tooltipTemplate={tt => {
          if (typeof tt === 'undefined' || tt === null) return null
          switch (tt.field) {
            case 'name':
              return (
                <div className="p-md">
                  <div className="m-b-sm">Name: {tt.name}</div>
                  <div className="m-b-sm">Nickname: {tt.nickname}</div>
                </div>
              )
            case 'email':
              return (
                <div className="p-md">
                  <div className="m-b-sm">
                    {tt.email_verified && (
                      <img src={icons.check} alt="Email verified" />
                    )}{' '}
                    {tt.email}
                  </div>
                  <div className="m-b-sm">
                    Created at: {formatDate(tt.created_at)}
                  </div>
                  <div className="m-b-sm">
                    Last updated: {formatDate(tt.updated_at)}
                  </div>
                </div>
              )
            case 'last_login':
              return (
                <div className="p-md">
                  <div className="m-b-sm">
                    Last login: {formatDate(tt.last)}
                  </div>
                  <div className="m-b-sm">Total logins: {tt.total}</div>
                  <div className="m-b-sm">Last IP: {tt.ip}</div>
                </div>
              )
            default:
              return null
          }
        }}
        buttonsSection={
          <div className="columns is-vcentered">
            <PermissionGate name={'add-member'}>
              <div className="column is-narrow">
                <button
                  className="button is-success"
                  type="button"
                  onClick={() => history.push('/setup/users/new')}>
                  Add User
                </button>
              </div>
            </PermissionGate>
          </div>
        }
        rowKey="user_id"
        rowOptions={showUserDropdownOptions()}
        onActiveRow={setActiveRow}
      />
    </>
  )
}

export default withRouter(SetupUsersList)
