import React from 'react'
import { usePermissionGate } from '../../../../helpers/hooks'
import { stateOptions as dropdownOptions } from '../../../../parents/elements-main/elements-main-config'

const dropdownDefaultColorIndicator = '#E0E66B'

const StatusColumn = ({ row }) => {
  const { cell, onDropdownItemChange } = row || {}
  const {
    value,
    row: { index, original }
  } = cell || {}
  const { new: isNewField } = original || {}

  const { hasPermission } = usePermissionGate('modify-elements')

  const getIndicatorColor = () =>
    (dropdownOptions || []).find(option => option.value === value)?.color ||
    dropdownDefaultColorIndicator

  return (
    <div
      className="select status-select"
      style={{ fontSize: '12px', marginTop: '7px' }}>
      <div
        className="select__color-indicator"
        style={{
          backgroundColor: getIndicatorColor(),
          borderColor: getIndicatorColor()
        }}></div>
      <select
        style={{ marginTop: '0px !important' }}
        className="input is-inline"
        onChange={event => {
          if (hasPermission) {
            onDropdownItemChange(event.target.value, index)
          }
        }}
        value={value}
        disabled={!hasPermission || isNewField}>
        {dropdownOptions.map(item => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default StatusColumn
