import React from 'react'
import { closeOutline } from 'ui/icons'
import PropTypes from 'prop-types'
import './index.scss'

const InvoiceViewPDFModal = props => {
  const { title, close, source } = props || {}

  return (
    <div className="inspection-modal-wrapper">
      <div className="inspection-modal-header">
        <div className="columns">
          <div className="column">
            <h4>{title}</h4>
          </div>
          <div className="column">
            <span onClick={close}>
              <img
                className="action-button action-button-close"
                src={closeOutline}
                alt="close"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="pdf-wrapper">
        <iframe src={source} width="100%" title={title}></iframe>
      </div>
    </div>
  )
}

InvoiceViewPDFModal.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  source: PropTypes.string
}

export default InvoiceViewPDFModal
