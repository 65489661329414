import React from 'react'
import { keysIcons } from '../keys-main/keys-icons'

export const statsCards = [
  {
    id: 'returned',
    icon: keysIcons.statsReturned,
    caption: 'Returned'
  },
  {
    id: 'potential_holdovers',
    icon: keysIcons.statsPotentialHoldover,
    caption: 'Potential Holdovers'
  },
  {
    id: 'holdovers',
    icon: keysIcons.statsHoldover,
    caption: 'Identified Holdovers'
  },
  {
    id: 'rto',
    icon: keysIcons.statsRTO,
    caption: 'Renewal Transfer Out'
  },
  {
    id: 'rti',
    icon: keysIcons.statsRTI,
    caption: 'Renewal Transfer In'
  },
  {
    id: 'leapfrogs',
    icon: keysIcons.statsLeapfrogs,
    caption: 'Leapfrogs',
    helpInfo: () => (
      <div style={{ width: 312, textAlign: 'left' }}>
        <p>
          A leapfrog is when a current resident is transferring into a bedroom another resident currently occupies and is transferring out of.
        </p>
        <p className="m-t-md">
          <span style={{ color: '#E96F33', fontWeight: 'bold' }}>Caution:</span>&nbsp;Best practice is to have no Leapfrogs by adjusting the TI’s room assignment when possible.
          If required, Leapfrogs will need to be monitored closely, as they may delay the new resident's transfer-in date.
        </p>
      </div>
    )
  }
]
