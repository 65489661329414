import React, {
  useEffect,
  memo,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback
} from 'react'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import { icons } from '../icons'
import DefaultColumnFilter from '../../../components/react-table-column-filters/DefaultColumnFilter'
import {
  scrollbarWidth,
  removeExtraWhiteSpaces
} from '../../../utils/helperFunctions'

import '../index.scss'

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      headerCheckbox = false,
      toggleAllRowsSelected,
      title,
      disabled,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            if (!disabled) {
              onChange(e)
            }
          }}
          {...rest}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.2 : 1
          }}
          title={
            showTooltip ? 'This row cannot be selected.' : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const RenderRow = ({ data, index, style }) => {
  const { page, prepareRow } = data || {}
  const row = page[index]
  prepareRow(row)
  return (
    <div
      {...row.getRowProps({
        style
      })}
      className="tr">
      {row.cells.map(cell => {
        const { column } = cell || {}
        const { Header } = column || {}
        let styleBody = {}
        if (cell.column.minWidth !== 0) {
          styleBody.minWidth = cell.column.minWidth
        }
        if (cell.column.flexGrow !== 0) {
          styleBody.flexGrow = cell.column.flexGrow
        }
        return (
          <div
            {...cell.getCellProps({
              style: {
                textAlign: cell.column.textAlign,
                width: cell.column.width,
                paddingLeft:
                  (Header === 'Damage Cost' || Header === 'Damage Code') && 10,
                paddingRight:
                  (Header === 'Damage Cost' || Header === 'Damage Code') && 10,
                ...styleBody
              }
            })}
            className="td">
            {cell.render('Cell')}
          </div>
        )
      })}
    </div>
  )
}

const DamageSetupTable = memo(
  forwardRef(
    (
      {
        columns,
        data,
        onRowSelectStateChange,
        setIsFilterApplied,
        setSelectedPage,
        setSelectedPageRowData,
        rowsPerPage,
        setRowsPerPage,
        onDropdownItemChange,
        skipReset
      },
      ref
    ) => {
      // Use the state and functions returned from useTable to build your UI
      const defaultColumn = React.useMemo(
        () => ({
          width: 100,
          Filter: DefaultColumnFilter
        }),
        []
      )

      const filterTypes = React.useMemo(
        () => ({
          customEquals: (rows, id, filterValue) => {
            if (!filterValue) return rows
            id = id[0]
            return rows.filter(row => filterValue?.[id] === row.values?.[id])
          }
        }),
        []
      )
      const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
      const datePerPage = rowsPerPage
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds, filters, pageIndex, pageSize },
        selectedFlatRows,
        toggleRowSelected,
        setAllFilters,
        toggleAllRowsSelected,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize,
        totalColumnsWidth
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          filterTypes,
          autoResetSelectedRows: false,
          autoResetPage: !skipReset,
          autoResetFilters: !skipReset,
          onDropdownItemChange,
          sortTypes: {
            alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
              const rowOneColumn = row1?.values[columnName]
              const rowTwoColumn = row2?.values[columnName]
              if (columnName === 'damage_cost') {
                const rowOneValue = rowOneColumn ? parseFloat(rowOneColumn) : 0
                const rowTwoValue = rowTwoColumn ? parseFloat(rowTwoColumn) : 0

                return rowOneValue > rowTwoValue ? 1 : -1
              }
              if (columnName === 'ca_split_type') {
                const data = {
                  null: 'Select',
                  1: 'All Residents',
                  2: 'Non-renewed Residents'
                }
                return (data[rowOneColumn] || '')?.toString()?.toLowerCase() >
                  (data[rowTwoColumn] || '')?.toString()?.toLowerCase()
                  ? 1
                  : -1
              }
              const valueOne = removeExtraWhiteSpaces(
                row1?.values[columnName] ?? ''
              )
              const valueTwo = removeExtraWhiteSpaces(
                row2?.values[columnName] ?? ''
              )

              return valueOne.localeCompare(valueTwo, 'en', { numeric: true })
            }
          },
          initialState: { pageIndex: 0, pageSize: 30 }
        },
        useBlockLayout,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [...columns])
        }
      )
      useImperativeHandle(ref, () => ({
        getRows: () => {
          return rows?.map(row => row.original)
        },
        clearFilter: setAllFilters,
        toggleAllRowsSelected: toggleAllRowsSelected,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        rowCount: (rows || []).length,
        currentPage: pageIndex + 1,
        gotoPage,
        setPageSize
      }))

      useEffect(() => {
        onRowSelectStateChange(selectedFlatRows)
      }, [selectedRowIds])

      useEffect(() => {
        setIsFilterApplied(filters)
        gotoPage(0)
      }, [filters])

      useEffect(() => {
        setRowsPerPage(pageSize)
      }, [pageSize])

      useEffect(() => {
        setSelectedPage(pageIndex + 1)
      }, [pageIndex])

      useEffect(() => {
        setSelectedPageRowData((rows || []).length)
      }, [rows])

      const isSort = column => {
        if (column?.isSorted) {
          return (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={!column.isSortedDesc ? icons.caretUpOn : icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{ display: 'block', marginTop: '-3px' }}
                src={
                  column.isSortedDesc ? icons.caretDownOn : icons.caretDownOff
                }
                alt="Sort in descending order"
              />
            </div>
          )
        }
        if (column?.canSort) {
          return (
            <div className="table-sort">
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{ display: 'block', marginTop: '-3px' }}
                src={icons.caretDownOff}
                alt="Sort in descending order"
              />
            </div>
          )
        }
        return ''
      }

      // Render the UI for your table
      return (
        <div {...getTableProps()} className="inspection-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr full-header">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width,
                        minWidth: column.minWidth,
                        flexGrow: column.flexGrow
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      <span {...column.getSortByToggleProps()}>
                        {isSort(column)}
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {(rows || []).length ? (
              <FixedSizeList
                className="full-body list-container"
                height={300}
                itemCount={(page || []).length}
                itemSize={35}
                width={totalColumnsWidth + scrollBarSize - 11}
                itemData={{ page, prepareRow }}>
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      )
    }
  )
)

export default DamageSetupTable
