export const REQUEST_FORM_ITEM = 'REQUEST_FORM_ITEM'
export const REQUEST_FORM_ITEM_SUCCESS = 'REQUEST_FORM_ITEM_SUCCESS'
export const REQUEST_FORM_ITEM_FAIL = 'REQUEST_FORM_ITEM_FAIL'
export const RESET_FORM_ITEM = 'RESET_FORM_ITEM'
export const REQUEST_APPROVED_INSPECTION_LIST =
  'REQUEST_APPROVED_INSPECTION_LIST'
export const REQUEST_APPROVED_INSPECTION_LIST_SUCCESS =
  'REQUEST_APPROVED_INSPECTION_LIST_SUCCESS'
export const REQUEST_APPROVED_INSPECTION_LIST_FAIL =
  'REQUEST_APPROVED_INSPECTION_LIST_FAIL'
export const REQUEST_SINGLE_DAMAGE_INSPECTION =
  'REQUEST_SINGLE_DAMAGE_INSPECTION'
export const REQUEST_SINGLE_DAMAGE_INSPECTION_SUCCESS =
  'REQUEST_SINGLE_DAMAGE_INSPECTION_SUCCESS'
export const REQUEST_SINGLE_DAMAGE_INSPECTION_FAIL =
  'REQUEST_SINGLE_DAMAGE_INSPECTION_FAIL'
export const RESET_DAMAGE_INSPECTION = 'RESET_DAMAGE_INSPECTION'
export const REQUEST_ADHOC_UNIT_SPACE = 'REQUEST_ADHOC_UNIT_SPACE'
export const REQUEST_ADHOC_UNIT_SPACE_SUCCESS =
  'REQUEST_ADHOC_UNIT_SPACE_SUCCESS'
export const REQUEST_ADHOC_UNIT_SPACE_FAIL = 'REQUEST_ADHOC_UNIT_SPACE_FAIL'
