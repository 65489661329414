import React from 'react'
import Select, { components, createFilter } from 'react-select'
import { icons } from './icons'
import PropTypes from 'prop-types'

import './styles.scss'

const formatOptionLabel = (options, displayKey) => {
  const { [displayKey]: value } = options || {}
  return (
    <div className="d-flex align-items-center">
      <div>{value || ''}</div>
    </div>
  )
}

// Icons
const { close } = icons || {}

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <img style={{ cursor: 'pointer' }} alt="Close Modal" src={close} />
  </components.ClearIndicator>
)

const MultiSelectDropdown = ({
  defaultValues = [],
  value,
  onChange,
  placeholder,
  selectionLimit = '',
  displayKey = '',
  customStyles = {},
  isDisabled = false,
  isMulti = true,
  id,
  uniqueKey,
  isClearable = false,
  noOptionsMessage = 'No options available',
  dropdownIcon = false,
  menuPortalTarget,
  deleteOption = false,
  deletedItem = [],
  canMultiItemDeleted = true
}) => {
  let componentBody = {}
  if (!dropdownIcon) {
    componentBody = {
      DropdownIndicator: () => null
    }
  }
  return (
    <Select
      filterOption={createFilter({ ignoreAccents: false })}
      components={{
        ...componentBody,
        IndicatorSeparator: () => null
        // ClearIndicator
      }}
      isSearchable
      isMulti={isMulti}
      id={id}
      menuPortalTarget={menuPortalTarget}
      isDisabled={isDisabled}
      backspaceRemovesValue={false}
      onChange={(options, action) => {
        if (canMultiItemDeleted) {
          if (deleteOption) {
            let newOptionData = options || []
            const {
              action: action_type,
              removedValue = [],
              option: selectedOption,
              removedValues = []
            } = action || {}
            if (action_type === 'remove-value') {
              newOptionData = [
                {
                  ...removedValue,
                  is_deleted: true
                }
              ]
            }
            if (action_type === 'select-option') {
              const filterValue = (deletedItem || []).filter(
                item => item.unit_space === selectedOption?.unit_space
              )
              if ((filterValue || []).length) {
                newOptionData = [
                  {
                    ...selectedOption,
                    already_added: true
                  }
                ]
              } else {
                newOptionData = [
                  {
                    ...selectedOption,
                    is_newley_added: true
                  }
                ]
              }
            }
            if (action_type === 'clear') {
              const updateItems = (removedValues || []).map(data => ({
                ...data,
                is_deleted: true
              }))
              newOptionData = updateItems || []
            }
            onChange(newOptionData, action_type)
          } else {
            onChange(options)
          }
        }
      }}
      options={(value || []).length === selectionLimit ? [] : defaultValues}
      noOptionsMessage={() => {
        return (value || []).length === selectionLimit
          ? 'You have reached the max selection Limit'
          : noOptionsMessage
      }}
      isClearable={isClearable}
      value={value}
      formatOptionLabel={options => formatOptionLabel(options, displayKey)}
      placeholder={placeholder !== '' ? placeholder : 'Select...'}
      getOptionValue={option => option[uniqueKey || displayKey]}
      getOptionLabel={option => option[displayKey]}
      styles={{
        ...customStyles,
        menu: provided => ({ ...provided, zIndex: 9999 })
      }}
    />
  )
}

formatOptionLabel.propTypes = {
  customStyles: PropTypes.object,
  defaultValues: PropTypes.array,
  displayKey: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  selectionLimit: PropTypes.string,
  value: PropTypes.any,
  isDisabled: PropTypes.bool
}

export default React.memo(MultiSelectDropdown)
