import Service from '../service'
import _ from 'lodash'

/**
 * Schedule data module
 * @redux
 * @reduxActionScope schedule
 * @module schedule
 */

/**
 * Request Schedule Main Data
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MAIN_DATA_REQUESTED =
  'schedule/SCHEDULE_MAIN_DATA_REQUESTED'

/**
 * Request Schedule Main Data Failed
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MAIN_DATA_FAILED = 'schedule/SCHEDULE_MAIN_DATA_FAILED'

/**
 * Request Schedule Main Data Success - updates the schedule main data from API
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MAIN_DATA_SUCCESS = 'schedule/SCHEDULE_MAIN_DATA_SUCCESS'

/**
 * Reset Schedule Main Data
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MAIN_DATA_RESET = 'schedule/SCHEDULE_MAIN_DATA_RESET'

/**
 * Request Schedule ManualData Data
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MANUAL_DATA_REQUESTED =
  'schedule/SCHEDULE_MANUAL_DATA_REQUESTED'

/**
 * Request Schedule ManualData Data Failed
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MANUAL_DATA_FAILED =
  'schedule/SCHEDULE_MANUAL_DATA_FAILED'

/**
 * Reset Schedule ManualData Data
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MANUAL_DATA_RESET = 'schedule/SCHEDULE_MANUAL_DATA_RESET'

/**
 * Request Schedule ManualData Data Success - updates the schedule manual data from API
 * @type {Redux.ActionType}
 */
export const SCHEDULE_MANUAL_DATA_SUCCESS =
  'schedule/SCHEDULE_MANUAL_DATA_SUCCESS'

/**
 * Schedule a service
 * @type {Redux.ActionType}
 */
export const SCHEDULE_SERVICE = 'schedule/SCHEDULE_SERVICE'

/**
 * Schedule Service Completed
 * @type {Redux.ActionType}
 */
export const SCHEDULE_SERVICE_SUCCESS = 'schedule/SCHEDULE_SERVICE_SUCCESS'

/**
 * Schedule Service Completed
 * @type {Redux.ActionType}
 */
export const SCHEDULE_SERVICE_FAILED = 'schedule/SCHEDULE_SERVICE_FAILED'

/**
 * Schedule Service reset requested flag
 * @type {Redux.ActionType}
 */
export const SCHEDULE_SERVICE_RESET = 'schedule/SCHEDULE_SERVICE_RESET'

/**
 * Reset Boards Data
 * @type {Redux.ActionType}
 */
export const RESET_SCHEDULE_FLOW = 'boards/RESET_SCHEDULE_FLOW'

/**
 * Request Boards Main Data
 * @type {Redux.ActionType}
 */
export const BOARDS_MAIN_DATA_REQUESTED = 'boards/BOARDS_MAIN_DATA_REQUESTED'

/**
 * Request Boards Main Data Failed
 * @type {Redux.ActionType}
 */
export const BOARDS_MAIN_DATA_FAILED = 'boards/BOARDS_MAIN_DATA_FAILED'

/**
 * Request Boards Main Data Success - updates the boards main data from API
 * @type {Redux.ActionType}
 */
export const BOARDS_MAIN_DATA_SUCCESS = 'boards/BOARDS_MAIN_DATA_SUCCESS'

/**
 * Resets scheduling status
 * @type {Redux.ActionType}
 */
export const BOARDS_MAIN_DATA_RESET = 'boards/BOARDS_MAIN_DATA_RESET'

/**
 * Request scheduling status
 * @type {Redux.ActionType}
 */
export const SCHEDULING_STATUS_REQUESTED = 'boards/SCHEDULING_STATUS_REQUESTED'

/**
 * Request scheduling status Failed
 * @type {Redux.ActionType}
 */
export const SCHEDULING_STATUS_FAILED = 'boards/SCHEDULING_STATUS_FAILED'

/**
 * Request scheduling status Success - updates the scheduling status from API
 * @type {Redux.ActionType}
 */
export const SCHEDULING_STATUS_SUCCESS = 'boards/SCHEDULING_STATUS_SUCCESS'

/**
 * Resets scheduling status
 * @type {Redux.ActionType}
 */
export const SCHEDULING_STATUS_RESET = 'boards/SCHEDULING_STATUS_RESET'

/**
 * Request scheduling status
 * @type {Redux.ActionType}
 */
export const AUTOSCHEDULING_SERVICE_REQUESTED =
  'boards/AUTOSCHEDULING_SERVICE_REQUESTED'

/**
 * Request scheduling status Failed
 * @type {Redux.ActionType}
 */
export const AUTOSCHEDULING_SERVICE_FAILED =
  'boards/AUTOSCHEDULING_SERVICE_FAILED'

/**
 * Request scheduling status Success - updates the scheduling status from API
 * @type {Redux.ActionType}
 */
export const AUTOSCHEDULING_SERVICE_SUCCESS =
  'boards/AUTOSCHEDULING_SERVICE_SUCCESS'

const initialState = {
  mainDataHasRequested: false,
  mainDataIsRequesting: false,
  mainData: [],
  mainDataError: null,
  scheduleStarted: false,
  scheduleManualDataHasRequested: false,
  scheduleManualDataIsRequesting: false,
  scheduleManualData: null,
  scheduleManualDataError: false,
  scheduleServiceIsRequesting: false,
  scheduleServiceIsError: false,
  scheduleServiceRequested: false,
  boardsMainDataHasRequested: false,
  boardsMainDataIsRequesting: false,
  boardsMainData: {},
  boardsMainDataError: null,
  schedulingStatusHasRequested: false,
  schedulingStatusRequesting: false,
  schedulingStatus: {},
  schedulingStatusError: null
}

/**
 * Inspect board reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_MAIN_DATA_REQUESTED:
      return {
        ...state,
        mainDataHasRequested: true,
        mainDataIsRequesting: true
      }

    case SCHEDULE_MAIN_DATA_FAILED:
      return {
        ...state,
        mainDataHasRequested: false,
        mainDataIsRequesting: false,
        mainDataError: true
      }

    case SCHEDULE_MAIN_DATA_SUCCESS:
      return {
        ...state,
        mainDataHasRequested: true,
        mainDataIsRequesting: false,
        mainData: action.scheduleData,
        scheduleStarted: action.scheduleStarted,
        mainDataError: false
      }

    case SCHEDULE_MAIN_DATA_RESET:
      return {
        ...state,
        mainDataHasRequested: false,
        mainDataIsRequesting: false,
        mainData: [],
        scheduleStarted: false,
        mainDataError: null
      }

    case SCHEDULE_MANUAL_DATA_REQUESTED:
      return {
        ...state,
        scheduleManualDataHasRequested: true,
        scheduleManualDataIsRequesting: true
      }

    case SCHEDULE_MANUAL_DATA_FAILED:
      return {
        ...state,
        scheduleManualDataHasRequested: false,
        scheduleManualDataIsRequesting: false,
        scheduleManualDataError: true
      }

    case SCHEDULE_MANUAL_DATA_SUCCESS:
      return {
        ...state,
        scheduleManualDataIsRequesting: false,
        scheduleManualData: action.scheduleManualData,
        scheduleManualDataError: false
      }

    case SCHEDULE_MANUAL_DATA_RESET:
      return {
        ...state,
        scheduleManualDataHasRequested: false,
        scheduleManualDataIsRequesting: false,
        scheduleManualData: null,
        scheduleManualDataError: false
      }

    case SCHEDULE_SERVICE:
      return {
        ...state,
        scheduleServiceIsRequesting: true,
        scheduleServiceIsError: false,
        scheduleServiceRequested: true
      }

    case SCHEDULE_SERVICE_SUCCESS:
      return {
        ...state,
        scheduleServiceIsRequesting: false,
        scheduleServiceIsError: false,
        scheduleServiceRequested: true
      }

    case SCHEDULE_SERVICE_FAILED:
      return {
        ...state,
        scheduleServiceIsRequesting: false,
        scheduleServiceIsError: true,
        scheduleServiceRequested: false
      }

    case SCHEDULE_SERVICE_RESET:
      return {
        ...state,
        scheduleServiceRequested: false
      }

    case RESET_SCHEDULE_FLOW:
      return {
        ...state,
        mainDataHasRequested: false,
        mainDataIsRequesting: false,
        mainData: [],
        mainDataError: null,
        scheduleStarted: false,
        scheduleManualDataHasRequested: false,
        scheduleManualDataIsRequesting: false,
        scheduleManualData: [],
        scheduleManualDataError: false,
        scheduleServiceIsRequesting: false,
        scheduleServiceIsError: false,
        scheduleServiceRequested: false
      }

    case BOARDS_MAIN_DATA_REQUESTED:
      return {
        ...state,
        boardsMainDataHasRequested: false,
        boardsMainDataIsRequesting: true
      }

    case BOARDS_MAIN_DATA_FAILED:
      return {
        ...state,
        boardsMainDataHasRequested: true,
        boardsMainDataIsRequesting: false,
        boardsMainDataError: true
      }

    case BOARDS_MAIN_DATA_SUCCESS:
      return {
        ...state,
        boardsMainDataHasRequested: true,
        boardsMainDataIsRequesting: false,
        boardsMainData: action.scheduleBoardsData,
        boardsMainDataError: false
      }

    case BOARDS_MAIN_DATA_RESET:
      return {
        ...state,
        boardsMainDataHasRequested: false,
        boardsMainDataIsRequesting: false,
        boardsMainData: [],
        boardsMainDataError: false
      }

    case SCHEDULING_STATUS_REQUESTED:
      return {
        ...state,
        schedulingStatusRequesting: true
      }

    case SCHEDULING_STATUS_FAILED:
      return {
        ...state,
        schedulingStatusHasRequested: true,
        schedulingStatusRequesting: false,
        schedulingStatusError: true
      }

    case SCHEDULING_STATUS_SUCCESS:
      return {
        ...state,
        schedulingStatusHasRequested: true,
        schedulingStatusRequesting: false,
        schedulingStatus: { running: action.running },
        schedulingStatusError: null
      }

    case SCHEDULING_STATUS_RESET:
      return {
        ...state,
        schedulingStatusHasRequested: false,
        schedulingStatusRequesting: false,
        schedulingStatus: {},
        schedulingStatusError: null
      }

    case AUTOSCHEDULING_SERVICE_REQUESTED:
      return {
        ...state
      }

    case AUTOSCHEDULING_SERVICE_FAILED:
      return {
        ...state
      }

    case AUTOSCHEDULING_SERVICE_SUCCESS:
      return {
        ...state
      }

    default:
      return state
  }
}

/**
 * Gets schedule main data
 * @redux
 * @reduxActionCreator SCHEDULE_MAIN_DATA_REQUESTED, SCHEDULE_MAIN_DATA_FAILED, SCHEDULE_MAIN_DATA_SUCCESS
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const getScheduleMainData = (pdbid, context) => {
  return async dispatch => {
    dispatch({ type: SCHEDULE_MAIN_DATA_REQUESTED })

    Service.getScheduleMainData(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()

          dispatch({
            type: SCHEDULE_MAIN_DATA_SUCCESS,
            scheduleData: scheduleMainDataAdapter(data),
            scheduleStarted: isScheduleStarted(data)
          })

          return
        }

        dispatch({ type: SCHEDULE_MAIN_DATA_FAILED })
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: SCHEDULE_MAIN_DATA_FAILED })
      })
  }
}

/**
 * Reset schedule main data
 * @redux
 * @reduxActionCreator SCHEDULE_MAIN_DATA_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const resetScheduleMainData = () => {
  return async dispatch => {
    dispatch({ type: SCHEDULE_MAIN_DATA_RESET })
  }
}

/**
 * Reset schedule manual data
 * @redux
 * @reduxActionCreator SCHEDULE_MANUAL_DATA_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const resetScheduleManualData = () => {
  return async dispatch => {
    dispatch({ type: SCHEDULE_MANUAL_DATA_RESET })
  }
}

/**
 * Gets schedule manual data
 * @redux
 * @reduxActionCreator SCHEDULE_MANUAL_DATA_REQUESTED, SCHEDULE_MANUAL_DATA_FAILED, SCHEDULE_MANUAL_DATA_SUCCESS
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const getScheduleManualData = (pdbid, context, service) => {
  return async dispatch => {
    dispatch({ type: SCHEDULE_MANUAL_DATA_REQUESTED })

    try {
      let [scheduleMainData, scheduleCapacityData] = await Promise.all([
        Service.getScheduleTeamsCapacity(pdbid, context, service),
        Service.getScheduleUnitsInformation(pdbid, context, service)
      ])

      if (scheduleMainData.ok && scheduleCapacityData.ok) {
        const scheduleTeamsCapacityRes = await scheduleMainData.json()
        const scheduleUnitsInformationRes = await scheduleCapacityData.json()

        dispatch({
          type: SCHEDULE_MANUAL_DATA_SUCCESS,
          scheduleManualData: scheduleManualDataAdapter(
            scheduleTeamsCapacityRes,
            scheduleUnitsInformationRes
          )
        })
      }
    } catch (err) {
      console.log(err)
      dispatch({ type: SCHEDULE_MANUAL_DATA_FAILED })
    }
  }
}

/**
 * Gets schedule main data
 * @redux
 * @reduxActionCreator SCHEDULE_SERVICE, SCHEDULE_SERVICE_SUCCESS, SCHEDULE_SERVICE_FAILED
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const scheduleService = (pdbid, context, service, scheduleData) => {
  return dispatch => {
    dispatch({ type: SCHEDULE_SERVICE })

    Service.scheduleService(pdbid, context, service, scheduleData)
      .then(async res => {
        // const data = await res.json()
        // TODO check if we receive anything in this service and remove the first timeout

        setTimeout(() => {
          dispatch({
            type: SCHEDULE_SERVICE_SUCCESS
          })
        }, 2000)

        setTimeout(() => {
          dispatch({
            type: SCHEDULE_SERVICE_RESET
          })
        }, 3000)
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: SCHEDULE_SERVICE_FAILED })
      })
  }
}

/**
 * Gets schedule main data
 * @redux
 * @reduxActionCreator RESET_SCHEDULE_FLOW
 */

export const resetScheduleFlow = () => {
  return dispatch => {
    dispatch({ type: RESET_SCHEDULE_FLOW })
  }
}

/**
 * Gets boards main data
 * @redux
 * @reduxActionCreator BOARDS_MAIN_DATA_REQUESTED, BOARDS_MAIN_DATA_FAILED, BOARDS_MAIN_DATA_SUCCESS
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const getScheduleBoardsMainData = (pdbid, context) => {
  return dispatch => {
    dispatch({ type: BOARDS_MAIN_DATA_REQUESTED })

    Service.getScheduleBoardsData(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const jsonRes = await res.json()
          const data = {
            servicesResources: _servicesResourcesAdapter(jsonRes),
            teamsResources: _teamsResourcesAdapter(jsonRes),
            // groupsResources: scheduleGroupsResourcesMock,
            servicesData: _servicesAdapter(jsonRes),
            teamsData: _teamsAdapter(jsonRes),
            // groupsData: [],
            servicesGanttData: _servicesGanttAdapter(jsonRes),
            teamsGanttData: _teamsGanttAdapter(jsonRes)
            // groupsGanttData: scheduleGroupsBoardsDataMock,
          }

          dispatch({
            type: BOARDS_MAIN_DATA_SUCCESS,
            scheduleBoardsData: schedulerBoardsAdapter(data)
          })
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: BOARDS_MAIN_DATA_FAILED })
      })
  }
}

/**
 * Reset boards main data
 * @redux
 * @reduxActionCreator BOARDS_MAIN_DATA_RESET
 */

export const resetScheduleBoardsMainData = () => {
  return dispatch => dispatch({ type: BOARDS_MAIN_DATA_RESET })
}

/**
 * Gets schedule team data
 * @redux
 * @reduxActionCreator SCHEDULING_STATUS_REQUESTED, SCHEDULING_STATUS_SUCCESS, SCHEDULING_STATUS_ERROR
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const getSchedulingStatus = (pdbid, context) => {
  const SCHEDULING_RUNNING_VALUE = 'RUNNING'

  return dispatch => {
    dispatch({ type: SCHEDULING_STATUS_REQUESTED })

    Service.getSchedulingStatus(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const status = data['items'][0]['status']
          dispatch({
            type: SCHEDULING_STATUS_SUCCESS,
            running: status === SCHEDULING_RUNNING_VALUE ? true : false
          })
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: SCHEDULING_STATUS_FAILED })
      })
  }
}

/**
 * reset schedule team data
 * @redux
 * @reduxActionCreator SCHEDULING_STATUS_RESET
 */

export const resetSchedulingStatus = (pdbid, context) => {
  return dispatch => {
    dispatch({ type: SCHEDULING_STATUS_RESET })
  }
}

/**
 * Autoschedule Service
 * @redux
 * @reduxActionCreator AUTOSCHEDULING_SERVICE_REQUESTED, AUTOSCHEDULING_SERVICE_SUCCESS, AUTOSCHEDULING_SERVICE_FAILED
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} context - the service to auto-schedule
 */

export const autoscheduleService = (pdbid, context, service) => {
  return dispatch => {
    dispatch({ type: AUTOSCHEDULING_SERVICE_REQUESTED })

    Service.autoscheduleService(pdbid, context, service || 'All')
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: AUTOSCHEDULING_SERVICE_SUCCESS
          })
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: AUTOSCHEDULING_SERVICE_FAILED })
      })
  }
}

// Schedule Helpers And Adapters

const isScheduleStarted = data =>
  data.filter(scheduleItem => scheduleItem.scheduled).length > 0

const scheduleMainDataAdapter = data => {
  return data.map(item => {
    return {
      ...item,
      goalsDates: item.goalsDates.map(goalDate => goalDate.date),
      scheduled: item.scheduled || false
    }
  })
}

const scheduleManualDataAdapter = (teamsCapacity, unitsData) => {
  const timelessDate = dateTime => {
    const date = new Date(dateTime.getTime())
    date.setHours(24, 0, 0, 0)
    return date
  }

  return {
    dates: teamsCapacity.map(item =>
      timelessDate(new Date(item.date)).getTime()
    ),
    hasTeamCapacity: !!teamsCapacity[0]['team_capacity'][0]['capacity'],
    teamsCapacity: teamsCapacity.map(item => ({
      ...item,
      date: timelessDate(new Date(item.date)).getTime(),
      team_capacity: item.team_capacity.map(team => ({
        ...team,
        availableSpaces: team.capacity,
        units: []
      }))
    })),
    units: unitsData[0]['units'] || []
  }
}

// Schedule Boards Helpers And Adapters

const _getServiceColor = service => {
  const SERVICE_COLORS = {
    paint: '#E0E66B',
    punch: '#55C2B8',
    cleaning: '#8BD3ED',
    carpetclean: '#FF9D52',
    carpetreplace: '#3DB3E2',
    concretevinylreplace: '#AA9CDE',
    sparkle: '#8DC85C',
    holdover: '#8DC85A',
    walk: '#8DC85S'
  }

  const serviceSanitized = service
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .join('')
    .toLowerCase()
  return SERVICE_COLORS[serviceSanitized]
}

const _servicesResourcesAdapter = services => {
  return services.map((service, index) => {
    return {
      id: index,
      name: service.servicetype,
      eventColor: _getServiceColor(service.servicetype)
    }
  })
}

const _servicesAdapter = services => {
  const flattenArray = _.flatten(
    services.map((service, index) => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date)
          .map(unit => {
            return {
              completed: 2,
              duration: 1,
              durationUnit: 'hour',
              endDate: unit.scheduled_date,
              name: service.servicetype,
              percentage: 100,
              resourceId: index,
              spaces: unit.spaces,
              startDate: unit.scheduled_date,
              type: 'service',
              unit: unit.unit
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const nameAndDateGrouped = []

  for (let i = 0; i < flattenArray.length; i++) {
    const existingItemIndex = nameAndDateGrouped.findIndex(
      el =>
        el.startDate === flattenArray[i]['startDate'] &&
        el.name === flattenArray[i]['name']
    )
    if (existingItemIndex < 0) {
      nameAndDateGrouped.push(flattenArray[i])
    } else {
      nameAndDateGrouped[existingItemIndex] = {
        ...nameAndDateGrouped[existingItemIndex],
        spaces: [
          ...nameAndDateGrouped[existingItemIndex]['spaces'],
          ...flattenArray[i]['spaces']
        ]
      }
    }
  }

  return nameAndDateGrouped.map(service => {
    const breakdown = _breakdownAdapter(service.spaces)
    const completed = breakdown.reduce((prev, curr) => {
      return prev + curr.total
    }, 0)

    return {
      ...service,
      breakdown,
      completed
    }
  })
}

const _breakdownAdapter = data => {
  const mappedData = []

  data.forEach(item => {
    const existingItemIndex = mappedData.findIndex(
      el => item.item_option === el.service
    )

    if (existingItemIndex < 0) {
      mappedData.push({
        service: item.item_option,
        total: 1
      })
    } else {
      mappedData[existingItemIndex] = {
        ...mappedData[existingItemIndex],
        total: mappedData[existingItemIndex]['total'] + 1
      }
    }
  })

  return mappedData
}

const _servicesGanttAdapter = services => {
  const flattenArray = _.flatten(
    services.map((service, index) => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date)
          .map(unit => {
            return {
              completed: 2,
              duration: 1,
              durationUnit: 'hour',
              endDate: unit.scheduled_date,
              name: service.servicetype,
              percentage: 100,
              resourceId: index,
              spaces: unit.spaces,
              startDate: unit.scheduled_date,
              type: 'service',
              unit: unit.unit
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const groupedUnitData = _.groupBy(flattenArray, 'unit')
  let currentIndex = 0

  return Object.keys(groupedUnitData).map(unit => {
    const data = {
      id: currentIndex,
      name: `Unit ${unit}`,
      expanded: false,
      style: 'background: #0081C3;',
      type: 'unit',
      children: _childrenAdapter(groupedUnitData[unit], currentIndex)
    }

    currentIndex = currentIndex + groupedUnitData[unit].length + 1

    return data
  })
}

const _childrenAdapter = (data, parentIndex) => {
  return data.map((service, childIndex) => {
    const endDate = new Date(service.endDate).setHours('23', '0', '0')
    return {
      id: parentIndex + childIndex + 1,
      name: service.name,
      startDate: service.startDate,
      endDate: new Date(endDate).toISOString(),
      // endDate: service.endDate,
      percentDone: 100,
      style: `background: ${_getServiceColor(service.name)}`,
      manuallyScheduled: true
      // duration: 23,
      // durationUnit: 'hour',
    }
  })
}

const _teamsResourcesAdapter = services => {
  const flattenArray = _.flatten(
    services.map(service => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date && unit.team_id)
          .map((unit, index) => {
            return {
              id: index,
              name: unit.team_name,
              teamId: unit.team_id,
              service: service.servicetype,
              eventColor: `${_getServiceColor(service.servicetype)}`
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const nameAndDateGrouped = []

  for (let i = 0; i < flattenArray.length; i++) {
    const existingItemIndex = nameAndDateGrouped.findIndex(
      el => el.name === flattenArray[i]['name']
    )
    if (existingItemIndex < 0) {
      nameAndDateGrouped.push(flattenArray[i])
    } else {
      nameAndDateGrouped[existingItemIndex] = {
        ...nameAndDateGrouped[existingItemIndex]
      }
    }
  }

  return nameAndDateGrouped
}

const _teamsAdapter = services => {
  const resources = _.flatten(
    services.map(service => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date && unit.team_id)
          .map((unit, index) => {
            return {
              id: index,
              name: unit.team_name,
              teamId: unit.team_id,
              service: service.servicetype,
              eventColor: `${_getServiceColor(service.servicetype)}`
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const getResourceColor = service => {
    return resources.findIndex(el => el.service === service)
  }

  const flattenArray = _.flatten(
    services.map(service => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date && unit.team_id)
          .map((unit, index) => {
            return {
              id: index,
              type: 'team',
              service: service.servicetype,
              resourceId: getResourceColor(service.servicetype),
              name: unit.team_name,
              duration: 1,
              durationUnit: 'hour',
              startDate: unit.scheduled_date,
              endDate: unit.scheduled_date,
              total: 15,
              percentage: 100,
              spaces: unit.spaces,
              unit: unit.unit
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const nameAndDateGrouped = []

  for (let i = 0; i < flattenArray.length; i++) {
    const existingItemIndex = nameAndDateGrouped.findIndex(
      el =>
        el.startDate === flattenArray[i]['startDate'] &&
        el.name === flattenArray[i]['name']
    )
    if (existingItemIndex < 0) {
      nameAndDateGrouped.push(flattenArray[i])
    } else {
      nameAndDateGrouped[existingItemIndex] = {
        ...nameAndDateGrouped[existingItemIndex],
        spaces: [
          ...nameAndDateGrouped[existingItemIndex]['spaces'],
          ...flattenArray[i]['spaces']
        ]
      }
    }
  }

  return nameAndDateGrouped.map((service, index) => {
    const breakdown = _breakdownAdapter(service.spaces)
    const completed = breakdown.reduce((prev, curr) => {
      return prev + curr.total
    }, 0)

    return {
      ...service,
      id: index,
      breakdown,
      completed
    }
  })
}

const _teamsGanttAdapter = services => {
  const resources = _.flatten(
    services.map(service => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date && unit.team_id)
          .map((unit, index) => {
            return {
              id: index,
              name: unit.team_name,
              teamId: unit.team_id,
              service: service.servicetype,
              eventColor: `${_getServiceColor(service.servicetype)}`
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const getResourceColor = service => {
    return resources.findIndex(el => el.service === service)
  }

  const flattenArray = _.flatten(
    services.map(service => {
      if (service.units) {
        return service.units
          .filter(unit => unit.scheduled_date && unit.team_id)
          .map((unit, index) => {
            return {
              id: index,
              type: 'team',
              service: service.servicetype,
              resourceId: getResourceColor(service.servicetype),
              name: unit.team_name,
              duration: 1,
              durationUnit: 'hour',
              startDate: unit.scheduled_date,
              endDate: unit.scheduled_date,
              total: 15,
              percentage: 100,
              spaces: unit.spaces,
              unit: unit.unit
            }
          })
      } else {
        return []
      }
    })
  ).map((service, index) => ({ ...service, id: index }))

  const groupedUnitData = _.groupBy(flattenArray, 'name')
  let currentIndex = 0

  return Object.keys(groupedUnitData).map(unit => {
    const data = {
      id: currentIndex,
      name: `${unit}`,
      expanded: false,
      style: 'background: #0081C3;',
      type: 'unit',
      children: _teamsChildrenAdapter(groupedUnitData[unit], currentIndex)
    }

    currentIndex = currentIndex + groupedUnitData[unit].length + 1

    return data
  })
}

const _teamsChildrenAdapter = (data, parentIndex) => {
  return data.map((service, childIndex) => {
    const endDate = new Date(service.endDate).setHours('23', '0', '0')
    return {
      id: parentIndex + childIndex + 1,
      service: service.service,
      startDate: service.startDate,
      // endDate: service.endDate,
      endDate: new Date(endDate).toISOString(),
      percentDone: 100,
      style: `background: ${_getServiceColor(service.service)}`,
      manuallyScheduled: true,
      name: `Unit ${service.unit}`
    }
  })
}

const schedulerBoardsAdapter = data => data
