import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle
} from 'react'
import PropTypes from 'prop-types'
import { headerData } from './header'
import { icons } from './icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { updateChargeStatus } from '../../modules/charge'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy
} from 'react-table'
import ToggleIsDamageRequired from './components/ToggleIsDamageRequired'

import './index.scss'

// Table styles
const Styles = styled.div`
  max-width: 100%;
  position: relative;
  input {
    border: 1px solid transparent;
    margin: 0px auto;
  }
  .po-table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #e5e9f2;
    position: relative;

    tr {
      position: relative;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      padding: 0px;
    }

    th,
    td {
      margin: 0;
      position: relative;
    }
  }
`

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={'Search'}
    />
  )
}

const Table = memo(
  forwardRef(({ columns, data }, ref) => {
    // Use the state and functions returned from useTable to build your UI

    const defaultColumn = React.useMemo(
      () => ({
        width: 100,
        Filter: DefaultColumnFilter
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        defaultColumn
      },
      useBlockLayout,
      useFilters,
      useSortBy,
      useRowSelect
    )

    useImperativeHandle(ref, () => ({
      getRows: () => {
        return rows?.map(row => row.original)
      }
    }))

    // Render the UI for your table
    return (
      <table {...getTableProps()} className="po-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          textAlign: cell.column.textAlign,
                          width: cell.column.width
                        }
                      })}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  })
)

const DamagesBulkStatusUpdate = props => {
  const {
    onClose,
    user,
    context,
    selectedDamageInspectionRows,
    updateChargeStatus,
    setUpdateDamageInspectionList,
    status,
    saveDamageSingleInspection
  } = props || {}

  // State
  const [errorMessage, setErrorMessage] = useState('')
  const [damageStatusData, setDamageStatusData] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [isDamageRequired, setIsDamageRequired] = useState(
    status?.damage_required ? true : false
  )
  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  useEffect(() => {
    if ((selectedDamageInspectionRows || []).length) {
      setDamageStatusData(selectedDamageInspectionRows)
    }
  }, [selectedDamageInspectionRows])

  const tableRef = useRef()

  // Icons
  const { close, closeFilled } = icons || {}

  const tableColumns = headerData()

  const columns = useMemo(() => tableColumns)

  const updateStatus = data => {
    if ((data || []).length) {
      let updatedData = []
      ;(data || []).map(d => {
        const {
          line_observation_id,
          original_damages_generate,
          damage_cost,
          damage_code,
          is_adhoc,
          inspection_adhoc_damage_id,
          splits,
          ca_split,
          damages_generate
        } = d || {}
        if (is_adhoc) {
          updatedData.push({
            inspection_adhoc_damage_id,
            original_damages_generate,
            damage_cost: damage_cost || 0,
            damage_code: damage_code || ' ',
            status: isDamageRequired === false ? 'false' : null,
            splits: splits?.length ? splits : null,
            ca_split_type: ca_split !== 'Select' ? ca_split : null,
            damages_generate: damages_generate === true ? true : false
          })
        } else {
          updatedData.push({
            line_observation_id,
            original_damages_generate,
            damage_cost: damage_cost || 0,
            damage_code: damage_code || ' ',
            damage_required: isDamageRequired === false ? 'false' : null,
            splits: splits?.length ? splits : null,
            ca_split_type: ca_split !== 'Select' ? ca_split : null,
            damages_generate: damages_generate === true ? true : false
          })
        }
      })
      const body = {
        items: updatedData || []
      }
      setSubmitting(true)
      saveDamageSingleInspection({
        pdbid,
        context,
        body,
        inspection_id: status?.inspection_id,
        onSuccess: () => {
          setErrorMessage('')
          setSubmitting(false)
          setUpdateDamageInspectionList(true)
          onClose()
        },
        onError: () => {
          setSubmitting(false)
          setErrorMessage('Something went wrong, Please try again later !!!')
        }
      })
    }
  }

  const onSave = () => {
    updateStatus(damageStatusData)
  }

  return (
    <div id="charge-status-container">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <h2 className="is-pulled-left modal-sync-title">Update Status</h2>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div>
        <Styles>
          <Table ref={tableRef} columns={columns} data={damageStatusData} />
        </Styles>
      </div>
      <div className="cs-modal">
        <form>
          <div className="form-group columns">
            <div className="label column is-two-third">
              <label>Required:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <ToggleIsDamageRequired
                isActive={isDamageRequired}
                onChange={state => setIsDamageRequired(state)}
              />
            </div>
          </div>
        </form>
      </div>
      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              onClose()
            }}>
            Cancel
          </button>
          <button
            className="button main-button is-primary"
            onClick={event => {
              event.preventDefault()
              onSave()
            }}>
            {isSubmitting ? 'Updating' : 'Update'}
            {isSubmitting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

DamagesBulkStatusUpdate.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ user, context, charge }) => ({
  user,
  context,
  charge
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateChargeStatus
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DamagesBulkStatusUpdate)
