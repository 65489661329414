import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlagUsa } from '@fortawesome/free-solid-svg-icons'

const PropertySelected = ({ propertySelected, onRemoveSelection }) =>
  propertySelected && (
    <div className="columns">
      <div className="column is-full">
        <a href="#!" onClick={() => onRemoveSelection()}>
          <FontAwesomeIcon icon={faFlagUsa} />
          Return to global view
        </a>
      </div>
    </div>
  )

PropertySelected.propTypes = {
  propertySelected: PropTypes.bool,
  onRemoveSelection: PropTypes.func
}

PropertySelected.defaultProps = {
  onRemoveSelection: () => {}
}

export default PropertySelected
