import React, { useEffect, useState, useCallback } from 'react'
import Accordion from '../../components/accordion'
import InspectionFileUploadModal from '../inspection-fileupload-modal'
import NoteField from './NoteField'
import NeedsWork from './NeedsWork'
import Good from './Good'
import PropTypes from 'prop-types'
import { closeFilled } from 'ui/icons'
import './index.scss'

const inspectionViewContent = props => {
  const {
    setModalContent,
    openModal,
    closeModal,
    user,
    currentInspection,
    setCurrentInspection,
    setPendingSaving,
    inspectionDownloadMediaURL,
    context
  } = props || {}
  // Inpection current button state
  const [activeButtonContent, setActiveButtonContent] = useState({
    parentId: '',
    childId: '',
    state: null
  })
  const [uploadedMedia, setUploadedMedia] = useState([])
  useEffect(() => {
    const { parentId } = activeButtonContent || {}
    if (parentId !== '') {
      updateInspectionState()
    }
  }, [activeButtonContent])

  // Add the selectd media to it's respective inspection state
  useEffect(() => {
    if ((uploadedMedia || []).length !== 0) {
      const { parentId, childId } = activeButtonContent || {}
      if (parentId !== '') {
        let copyInspectionData = [...currentInspection]
        let updateInspectionData = (copyInspectionData || []).map((data, i) => {
          const { items } = data || {}
          if (i === parentId) {
            ;(items || []).map(childData => {
              const { line_id, line_media, observations } = childData || {}
              // For pre-selecting solution if only one exists
              // Start
              const getSelectedObservation = (observations || []).filter(
                observation => observation.selected
              )
              if ((getSelectedObservation || []).length !== 0) {
                const { solutions } = getSelectedObservation[0]
                if ((solutions || []).length === 1) {
                  ;(solutions || [])[0].selected = true
                }
              }
              // End
              if (line_id === childId) {
                if ((line_media || []).length !== 0) {
                  childData.line_media = [...line_media, ...uploadedMedia]
                } else {
                  childData.line_media = [...uploadedMedia]
                }
              }
            })
          }
          return data
        })
        setCurrentInspection(updateInspectionData || [])
        closeModal()
      }
    }
  }, [uploadedMedia])

  // Upload File Modal
  const onUploadFileHandler = useCallback(
    (current_Parent_id, current_Child_id, current_State) => {
      setModalContent(() => (
        <InspectionFileUploadModal
          {...props}
          activeButtonContent={activeButtonContent}
          parentId={current_Parent_id}
          childId={current_Child_id}
          state={current_State}
          onClose={() => {
            closeModal()
          }}
          onDisplayUploadMedia={(uploadedFiles, parentId, childId, state) => {
            setActiveButtonContent({
              parentId,
              childId,
              state
            })
            setUploadedMedia(uploadedFiles)
          }}
        />
      ))
      openModal({
        width: '580px',
        maxWidth: '100%',
        additionalClasses: 'inspection-fileupload-modal'
      })
    },
    [setModalContent, openModal, user]
  )

  // Update Inspection state and re-new the date based on selected state
  const updateInspectionState = () => {
    const { parentId, childId, state } = activeButtonContent || {}
    if (parentId !== '') {
      let copyInspectionData = [...currentInspection]
      let updateInspectionData = (copyInspectionData || []).map((data, i) => {
        const { items } = data || {}
        if (i === parentId) {
          ;(items || []).map(childData => {
            const { line_id, state: savedState, observations } = childData || {}
            if (line_id === childId) {
              if (savedState !== state) {
                childData.state = state
                childData.line_note = null
                ;(observations || []).map(observation => {
                  const { selected, solutions } = observation || {}
                  if (selected !== null) {
                    observation.selected = null
                  }
                  ;(solutions || []).map(solution => {
                    const { selected } = solution || {}
                    if (selected !== null) {
                      solution.selected = null
                    }
                  })
                })
              }
            }
          })
        }
        return data
      })
      setCurrentInspection(updateInspectionData || [])
      setPendingSaving(true)
    }
  }

  // Set current inpection state 'Good/Needs Work'
  const updateActiveButtonContent = value => {
    setActiveButtonContent(value)
  }

  // Update Current state observation and re-new its data
  const updateInspectionObservation = (
    observationName,
    observation_id,
    current_Parent_id,
    current_Child_id,
    currentItems,
    items,
    index
  ) => {
    let copyInspectionData = [...currentInspection]
    const updateCurrentItem = { ...currentItems } || {}
    const { observations, line_media } = updateCurrentItem || {}
    const updateObservation = (observations || []).map(observeData => {
      const { line_observation_id, observation, solutions, media_required } = observeData || {}
      observeData.selected = null
      if (
        observation_id === line_observation_id &&
        observation === observationName
      ) {
        observeData.selected = true
        // For pre-selecting solution if only one exists
        // Start
        if(media_required === null) {
          if (
            (line_media || []).filter(media => !media.deleted).length !== 0 &&
            (solutions || []).length === 1
          ) {
            solutions[0].selected = true
            // End
          } else {
            ;(solutions || []).map(solutionData => (solutionData.selected = null))
          }
        } else {
          if (
            (solutions || []).length === 1
          ) {
            solutions[0].selected = true
            // End
          } else {
            ;(solutions || []).map(solutionData => (solutionData.selected = null))
          }
        }
        
      }
      return observeData
    })
    items[index] = {
      ...items[index],
      line_note: null,
      observations: updateObservation
    }
    copyInspectionData[current_Parent_id] = {
      ...copyInspectionData[current_Parent_id],
      items
    }
    setCurrentInspection(copyInspectionData || [])
    setPendingSaving(true)
  }

  // Update Current state solutions value based on single type
  const onChangeRadioValue = (
    observation_id,
    solution_id,
    current_Parent_id,
    current_Child_id,
    currentItems,
    items,
    index
  ) => {
    let copyInspectionData = [...currentInspection]
    const updateCurrentItem = { ...currentItems } || {}
    const { observations } = updateCurrentItem || {}
    const updateObservation = (observations || []).map(observeData => {
      const { solutions, line_observation_id } = observeData || {}
      if (observation_id === line_observation_id) {
        ;(solutions || []).map(solutionData => {
          const { line_solution_id } = solutionData || {}
          solutionData.selected = null
          if (solution_id === line_solution_id) {
            solutionData.selected = true
          }
        })
      }
      return observeData
    })
    items[index] = {
      ...items[index],
      observations: updateObservation
    }
    copyInspectionData[current_Parent_id] = {
      ...copyInspectionData[current_Parent_id],
      items
    }
    setCurrentInspection(copyInspectionData || [])
    setPendingSaving(true)
  }

  const onChangeCheckboxValue = (
    { target },
    observation_id,
    solution_id,
    current_Parent_id,
    current_Child_id,
    currentItems,
    items,
    index
  ) => {
    const { checked } = target || {}
    let copyInspectionData = [...currentInspection]
    const updateCurrentItem = { ...currentItems } || {}
    const { observations } = updateCurrentItem || {}
    const updateObservation = (observations || []).map(observeData => {
      const { solutions, line_observation_id } = observeData || {}
      if (observation_id === line_observation_id) {
        ;(solutions || []).map(solutionData => {
          const { line_solution_id } = solutionData || {}
          if (solution_id === line_solution_id) {
            solutionData.selected = null
            if (checked) {
              solutionData.selected = true
            }
          }
        })
      }
      return observeData
    })
    items[index] = {
      ...items[index],
      observations: updateObservation
    }
    copyInspectionData[current_Parent_id] = {
      ...copyInspectionData[current_Parent_id],
      items
    }
    setCurrentInspection(copyInspectionData || [])
    setPendingSaving(true)
  }

  // Update notes value
  const onChangeNoteValue = (note, current_Parent_id, current_Child_id) => {
    let copyInspectionData = [...currentInspection]
    let updateInspectionData = (copyInspectionData || []).map((data, i) => {
      const { items } = data || {}
      if (i === current_Parent_id) {
        ;(items || []).map(childData => {
          const { line_id } = childData || {}
          if (line_id === current_Child_id) {
            childData.line_note = note
          }
        })
      }
      return data
    })
    setCurrentInspection(updateInspectionData || [])
    setPendingSaving(true)
  }

  // Get Notes field based on state and conditions
  const getNotesField = (
    isObservationCompleted,
    isSolutionComplete,
    line_note = '',
    current_Parent_id,
    current_Child_id,
    line_media,
    stateType
  ) => {
    if (stateType === 'Needs Work') {
      if (isObservationCompleted && isSolutionComplete) {
        return (
          <NoteField
            key={current_Child_id}
            line_note={line_note}
            current_Parent_id={current_Parent_id}
            current_Child_id={current_Child_id}
            onChangeNoteValue={onChangeNoteValue}
          />
        )
      }
      return
    }
    return (
      <NoteField
        key={current_Child_id}
        line_note={line_note}
        current_Parent_id={current_Parent_id}
        current_Child_id={current_Child_id}
        onChangeNoteValue={onChangeNoteValue}
      />
    )
  }

  // Get full URL to show media
  const getFullMediaPath = file_name => {
    // Meta Data
    const { project, property } = context || {}
    return `${inspectionDownloadMediaURL || ''}Inspections/${project ||
      ''}_${property || ''}/${file_name || ''}`
  }

  // Calculation to set deleted flag for the media which should be removed from selected inspection state
  const onRemoveMedia = (
    current_Parent_id,
    current_Child_id,
    file_name,
    current_State,
    currentItems,
    items,
    index,
    isMediaRequired
  ) => {
    if (current_Parent_id !== '') {
      let copyInspectionData = [...currentInspection]
      const updateCurrentItem = { ...currentItems } || {}
      const { line_media, observations } = updateCurrentItem || {}
      if ((line_media || []).length !== 0) {
        // Set deleted flag for deleted media item
        const updateSelectedMedia = (line_media || []).map(file => {
          const { fileName } = file || {}
          if (fileName === file_name) {
            file.deleted = true
          }
          return file
        })
        // Check if all media have deleted flag 'true' then update selected solutions and note to null
        if (
          isMediaRequired &&
          (updateSelectedMedia || []).filter(media => !media.deleted).length ===
            0
        ) {
          updateCurrentItem.line_note = null
          ;(observations || []).map(observation => {
            const { solutions, selected } = observation || {}
            if (selected) {
              ;(solutions || []).map(
                solutionData => (solutionData.selected = null)
              )
            }
          })
        }
        updateCurrentItem.line_media = [...updateSelectedMedia]
      }
      // Re-arrange arrays with filtered data
      items[index] = updateCurrentItem || {}
      copyInspectionData[current_Parent_id] = {
        ...copyInspectionData[current_Parent_id],
        items
      }
      setActiveButtonContent({
        parentId: current_Parent_id,
        childId: current_Child_id,
        state: current_State
      })
      setCurrentInspection(copyInspectionData || [])
    }
  }

  // Display Media in all states
  const getMediaBody = (
    file_name,
    type,
    line_media_id,
    preview,
    current_Parent_id,
    current_Child_id,
    current_State,
    currentItems,
    items,
    index,
    isMediaRequired
  ) => {
    const toLowerType = (type || '').toString().toLowerCase()
    return (
      <div
        key={line_media_id}
        className="inspection-thumbnail-inner is-flex is-align-items-center">
        {toLowerType === 'photo' && (
          <img src={preview || getFullMediaPath(file_name)} alt="" />
        )}
        {toLowerType === 'video' && (
          <video src={preview || getFullMediaPath(file_name)} />
        )}
        <button
          onClick={() =>
            onRemoveMedia(
              current_Parent_id,
              current_Child_id,
              file_name,
              current_State,
              currentItems,
              items,
              index,
              isMediaRequired
            )
          }>
          <img src={closeFilled} alt="" />
        </button>
      </div>
    )
  }

  const getAccordionContent = (
    current_Parent_id,
    current_Child_id,
    current_State,
    observations,
    line_note,
    line_media,
    currentItems,
    items,
    index
  ) => {
    const { parentId, childId, state } = activeButtonContent || {}
    const needsWorkProps = {
      observations,
      current_Parent_id,
      current_Child_id,
      line_note,
      line_media,
      current_State,
      currentItems,
      items,
      index,
      updateInspectionObservation,
      getMediaBody,
      onUploadFileHandler,
      onChangeRadioValue,
      onChangeCheckboxValue,
      getNotesField
    }
    const goodProps = {
      current_Parent_id,
      current_Child_id,
      line_note,
      line_media,
      current_State,
      currentItems,
      items,
      index,
      getMediaBody,
      onUploadFileHandler,
      getNotesField
    }
    if (parentId === current_Parent_id && current_Child_id === childId) {
      if (state === 'Needs Work') {
        return <NeedsWork {...needsWorkProps} />
      }
      return <Good {...goodProps} />
    } else {
      if (current_State === 'Needs Work') {
        return <NeedsWork {...needsWorkProps} />
      }
      return <Good {...goodProps} />
    }
  }

  return (
    <div>
      {(currentInspection || []).map((inspectLines, i) => {
        const { location, items, status } = inspectLines || {}
        return (
          <Accordion
            spaceState={status === 'Not Required' ? 'Not Required' : ''}
            isDisabled={status === 'Not Required'}
            isOpen={status === 'Not Required' ? false : true}
            key={i}
            title={location || ''}>
            {(items || []).map((currentItems, index) => {
              const {
                state,
                item,
                line_id,
                observations,
                line_note,
                line_media
              } = currentItems || {}
              return (
                <Accordion
                  isDisabled={status === 'Not Required'}
                  additionalClassName="child-accordion"
                  isOpen={
                    status === 'Not Required'
                      ? false
                      : state === 'Needs Work'
                      ? true
                      : false
                  }
                  key={line_id}
                  title={item || ''}
                  parentId={i}
                  childId={line_id}
                  actionButtons={['Good', 'Needs Work']}
                  state={state}
                  updateActiveButtonContent={updateActiveButtonContent}>
                  {getAccordionContent(
                    i,
                    line_id,
                    state,
                    observations,
                    line_note,
                    line_media,
                    currentItems,
                    items,
                    index
                  )}
                </Accordion>
              )
            })}
          </Accordion>
        )
      })}
    </div>
  )
}

inspectionViewContent.propTypes = {
  inspectionData: PropTypes.object,
  user: PropTypes.object,
  isEdit: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  inspectionUploadMediaURL: PropTypes.string,
  context: PropTypes.object
}

export default inspectionViewContent
