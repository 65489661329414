import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'

/**
 * Dashboard map data module
 * @redux
 * @reduxActionScope dashboard-map
 * @module dashboard-map
 */

/**
 * Request dashboard map data
 * @type {Redux.ActionType}
 */
export const CORPORATE_DASHBOARD_MAP_REQUESTED =
  'dashboard/CORPORATE_DASHBOARD_MAP_REQUESTED'
/**
 * Dashboard map data failed
 * @type {Redux.ActionType}
 */
export const CORPORATE_DASHBOARD_MAP_FAIL =
  'dashboard/CORPORATE_DASHBOARD_MAP_FAIL'
/**
 * Update request dashboard map with successful data from API
 * @type {Redux.ActionType}
 */
export const CORPORATE_DASHBOARD_MAP_SUCCESS =
  'dashboard/CORPORATE_DASHBOARD_MAP_SUCCESS'

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: []
}

/**
 * Dashboard map reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case CORPORATE_DASHBOARD_MAP_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case CORPORATE_DASHBOARD_MAP_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case CORPORATE_DASHBOARD_MAP_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true,
        data: []
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Updates dashboard map data
 * @redux
 * @reduxActionCreator DASHBOARD_MAP_REQUESTED, DASHBOARD_MAP_SUCCESS, DASHBOARD_MAP_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getCorporateMap = (pdbid, project) => {
  return dispatch => {
    dispatch({
      type: CORPORATE_DASHBOARD_MAP_REQUESTED
    })
    return Service.getDashboardPropertiesData(pdbid, { project })
      .then(async res => {
        if (res.ok) {
          try {
            const data = await res.json()
            dispatch({
              type: CORPORATE_DASHBOARD_MAP_SUCCESS,
              data: mapAdapter(data.dashboard)
            })
          } catch {
            _corporateMapFail(dispatch)
          }
        } else {
          _corporateMapFail(dispatch)
        }
      })
      .catch(err => {
        _corporateMapFail(dispatch)
        throw err
      })
  }
}

const _corporateMapFail = dispatch => {
  dispatch({
    type: CORPORATE_DASHBOARD_MAP_FAIL
  })
}

const mapAdapter = data =>
  _.map(data.properties, property => ({
    id: property.property_id,
    name: property.property_name,
    latitude: parseFloat(_.get(property.property_location.split(','), '0')),
    longitude: parseFloat(_.get(property.property_location.split(','), '1')),
    size: property.size,
    color: property.color
  }))
