import React from 'react'

const InspectionCellComponent = ({ row }) => {
  const { cell, flatRows } = row || {}
  const { value } = cell || {}

  return (
    <div
      className="cell-item"
      title={value}
      style={{ paddingLeft: flatRows?.length > 8 && '15px' }}>
      <span>{value}</span>
    </div>
  )
}

export default InspectionCellComponent
