import React from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { closeModal } from '../../modules/modal'
import Button from '../button'
import { close } from 'ui/icons'
import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'

const Modal = ({
  cancelText = 'Cancel',
  cancelType = 'secondary',
  children,
  onConfirm = () => {},
  okDisabled = false,
  okTooltip = false,
  renderOkTooltipText = () => {},
  okText = 'Ok',
  okType = 'primary',
  onClose = () => {},
  title = '',
  titleSize = '3',
  autoClose = true,
  loading = false
}) => {
  const dispatch = useDispatch()

  const onCloseButton = () => {
    onClose()
    dispatch(closeModal())
  }

  const onConfirmButton = () => {
    onConfirm()
    if (autoClose) {
      dispatch(closeModal())
    }
  }

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column p-b-none">
          <p
            className={`column is-size-${titleSize} is-four-fifths is-pulled-left p-none invoice-send-modal__title`}>
            {title}
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              onCloseButton()
            }}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      {children}
      <div className="columns">
        <div className="column has-text-centered">
          <Button type={cancelType} onClick={onCloseButton}>
            {cancelText}
          </Button>
          <span data-tip data-for="okButton">
            <Button
              type={okType}
              onClick={onConfirmButton}
              disabled={okDisabled}>
              {loading && (
                <FontAwesomeIcon icon={faSpinner} className="m-r-md" spin />
              )}
              {okText}
            </Button>
          </span>

          {okTooltip && (
            <ReactTooltip
              className="customTooltipTheme"
              id="okButton"
              place="bottom"
              effect="solid">
              <div>{renderOkTooltipText()}</div>
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
