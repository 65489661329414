import expected from './icons/key-expected.svg'
import statsReturned from './icons/stats-returned.svg'
import statsHoldover from './icons/stats-holdovers.svg'
import statsPotentialHoldover from './icons/stats-potential-holdovers.svg'
import statsRTO from './icons/stats-rtos.svg'
import statsRTI from './icons/stats-rtis.svg'
import statsLeapfrogs from './icons/stats-leapfrogs.svg'
import returned from './icons/key-returned.svg'
import holdover from './icons/key-holdovers.svg'
import rTO from './icons/key-rtos.svg'
import rTI from './icons/key-rtis.svg'
import leapfrogs from './icons/key-leapfrogs.svg'
import rTOGreen from './icons/key-rtos-green.svg'
import rTIGreen from './icons/key-rtis-green.svg'
import leapfrogsGreen from './icons/key-leapfrogs-green.svg'
import renewal from './icons/key-renewals.svg'
import vacant from './icons/key-vacant.svg'
import xIcon from './icons/x-icon.png'
import checkIcon from './icons/check-icon.png'
import legend from './icons/key-legend.png'
import solidGreenCheck from './icons/solidgreencheck.svg'
import hollowGreenCheck from './icons/hollowgreencheck.svg'
import xFilled from './icons/xfilled.svg'
import xEmpty from './icons/xzempty.svg'
import note from './icons/note.svg'
import noteAdd from './icons/note-add.svg'

export const keysIcons = {
  statsReturned,
  statsHoldover,
  statsPotentialHoldover,
  statsRTO,
  statsRTI,
  statsLeapfrogs,
  expected,
  returned,
  holdover,
  rTO,
  rTI,
  leapfrogs,
  rTOGreen,
  rTIGreen,
  leapfrogsGreen,
  renewal,
  vacant,
  xIcon,
  checkIcon,
  legend,
  solidGreenCheck,
  hollowGreenCheck,
  xFilled,
  xEmpty,
  note,
  noteAdd
}
