import _ from 'lodash'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { headerData } from './header'
import BigLoading from '../../components/big-loading'
import styled from 'styled-components'
import moment from 'moment'
import { exportFile, closeFilled } from 'ui/icons'
import DamageSetupTable from './components/DamageSetupTable'
import Paginator from '../../components/react-table-pagination'
import { RouterPrompt } from '../../components/common/Prompt/RouterPrompt'
import usePageReload from '../../hooks/usePageReload'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { usePrevious, usePermissionGate } from '../../helpers/hooks'
import { capitalize } from '../../utils/helperFunctions'

import './index.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }
  select {
    width: 100%;
    margin-top: 8px !important;
  }
  .inspection-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;
    .tr {
      position: relative;
      :first-child {
        .th {
          display: none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      :first-child {
        //border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
    }
  }
`

const DamageSetupList = props => {
  const {
    user,
    context,
    getSingleForm,
    damage,
    saveDamageForm,
    resetSelectForm
  } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [selectedFormInformation, setSelectedFormInformation] = useState([])
  const [isFormEdited, setIsFormEdited] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [skipReset, setSkipReset] = useState(false)

  const { hasPermission } = usePermissionGate('damage-action')
  usePageReload(isFormEdited)
  const params = useParams()
  const { formId } = params || {}

  const tableRef = useRef()

  // Redux
  const { singleForm } = damage || {}
  const { hasMadeInitialRequest, isRequesting, data: selectedFormData } =
    singleForm || {}

  useEffect(() => {
    setSelectedFormInformation(selectedFormData)
  }, [damage, singleForm, selectedFormData])

  const onSuccess = () => {
    setErrorMessage('')
  }

  const onError = () => {
    setErrorMessage('Something went wrong. Please try again later !!!')
  }

  const pdbid = user?.user_metadata?.pdbid

  const prevContext = usePrevious(context)

  useEffect(() => {
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged && formId) {
      getSingleForm(pdbid, context, formId, onSuccess, onError)
    }
    return () => resetSelectForm()
  }, [formId])

  const destructCASplitType = value => {
    if (value === 1) {
      return 'All Residents'
    } else if (value === 2) {
      return 'Non-renewed Residents'
    } else {
      return 'N/A'
    }
  }

  const destructCASplitTypeForSave = value => {
    console.log(value)
    if (value === 1) {
      return 1
    } else if (value === 2) {
      return 2
    } else {
      return null
    }
  }

  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = (properties || []).find(p => p?.id === contextProperty)
    const project = (projects || []).find(p => p?.id === contextProject)
    const names = {
      property: property?.name?.replace(/ /g, '_'),
      project: project?.name?.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows
    const filterData = (defaultData || []).map(list => {
      const {
        space,
        item,
        observation,
        damages_generate,
        damage_cost,
        damage_code,
        ca_split_type
      } = list || {}
      return {
        Space: `"${space}"` || '',
        Item: `"${item}"` || '',
        Observation: `"${observation}"` || '',
        'Damage Generate':
          `"${damages_generate === true ? 'Yes' : 'No'}"` || '',
        'Damage Cost': `"${damage_cost !== null ? damage_cost : 0}"` || 0,
        'Damage Code': `"${damage_code !== null ? damage_code : ''}"` || '',
        'Damage Code': `"${damage_code !== null ? damage_code : ''}"` || '',
        'CA Split Type': `"${destructCASplitType(ca_split_type || null)}"`
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-form-damage-setup-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const updateInspectionValue = (index, value, type) => {
    setSkipReset(() => true)
    setSelectedFormInformation(prevData => {
      return prevData.map((data, i) => {
        if (i === index) {
          data[type] = value
        }
        return data
      })
    })
    setIsFormEdited(true)
  }

  const tableColumns = headerData({
    updateInspectionValue,
    hasPermission
  })

  const columns = useMemo(() => tableColumns, [])

  const onResetFilter = () => tableRef.current?.clearFilter([])

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  const onFormSaveSuccess = () => {
    setIsFormEdited(false)
    setIsSaving(false)
    setErrorMessage('')
    getSingleForm(pdbid, context, formId, onSuccess, onError)
  }

  const onFormSaveError = () => {
    setIsSaving(false)
    setErrorMessage('Something went wrong. Please try again later !!!')
  }

  const onSaveForm = async () => {
    setIsSaving(true)
    const body = (selectedFormInformation || []).map((data, i) => {
      //data.damages_generate = data.damages_generate === true ? true : null
      return {
        damage_code: data.damage_code,
        damage_cost: data.damage_cost,
        damages_generate: data.damages_generate === true ? true : null,
        form_observation_id: data.form_observation_id,
        ca_split_type:
          data.ca_split_type == '1' ? 1 : data.ca_split_type == '2' ? 2 : null
      }
    })
    setSelectedPageRowData(0)
    setRowsPerPage(30)
    await saveDamageForm({
      pdbid,
      context,
      body: {
        items: body
      },
      onSuccess: onFormSaveSuccess,
      onError: onFormSaveError
    })
  }

  const handleDropdownChange = (value, itemIndex) => {
    setSkipReset(() => true)
    setSelectedFormInformation(prevInfo =>
      prevInfo.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            ca_split_type: value
          }
        }
        return item
      })
    )
    setIsFormEdited(true)
  }

  const displayTable = () => {
    if (hasMadeInitialRequest && !isRequesting) {
      return (
        <>
          <Styles>
            <DamageSetupTable
              ref={tableRef}
              columns={columns}
              data={selectedFormInformation}
              onRowSelectStateChange={setSelectedRows}
              setIsFilterApplied={setIsFilterApplied}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              onDropdownItemChange={handleDropdownChange}
              skipReset={skipReset}
            />
          </Styles>
          <div className="table-pagination">
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          </div>
        </>
      )
    }
    return <BigLoading />
  }

  return (
    <>
      <RouterPrompt
        title="You are about to exit without saving your changes. If you wish to proceed, all changes will be discarded."
        okText="Discard"
        cancelText="Cancel"
        when={isFormEdited}
      />
      <div className="inspection-list-container">
        <div className="data-table-wrapper" style={{ minHeight: 0 }}>
          <div className="data-table-header">
            <div
              className="header"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <h2 className="heading-5">
                {capitalize(
                  selectedFormInformation?.length
                    ? selectedFormInformation[0].form_name
                    : ''
                )}
              </h2>
              <button
                onClick={() => onSaveForm()}
                disabled={!isFormEdited || isSaving || !hasPermission}
                className="button is-success btn-save heading-6">
                {isSaving && (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
                )}
                Save Setup
              </button>
            </div>
            <div className="action-button" style={{ marginTop: '10px' }}>
              <div className="inspection-action-buttons">
                {(checkIsFilterApplied || []).length !== 0 && (
                  <button
                    onClick={() => {
                      onResetFilter()
                    }}
                    className="button is-secondary">
                    Clear All Filter(s)
                  </button>
                )}
              </div>
              <button
                disabled={isRequesting}
                className="export"
                onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
            {errorMessage !== '' && (
              <div>
                <br />
                <div className="notification is-danger is-light is-flex">
                  <p>{errorMessage}</p>
                  <button onClick={() => setErrorMessage('')}>
                    <img src={closeFilled} alt="" />
                  </button>
                </div>
              </div>
            )}
          </div>
          {displayTable()}
        </div>
      </div>
    </>
  )
}

export default DamageSetupList
