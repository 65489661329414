import { share, shareV2, trash, duplicate } from 'icons'

export const dropdownDefaultColorIndicator = '#E0E66B'

export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
    color: '#3DB3E2'
  },
  {
    label: 'Inactive',
    value: 'inactive',
    color: '#E0E66B'
  }
]

export const getAdditionalActions = (
  shareTemplate,
  duplicateTemplate,
  downloadTemplate,
  deleteTemplate
) => {
  return [
    {
      action: shareTemplate.action,
      disabled: shareTemplate.disabled,
      icon: shareV2,
      iconAlt: 'share template',
      label: 'Share with properties',
      title: shareTemplate.title
    },
    {
      action: duplicateTemplate.action,
      disabled: duplicateTemplate.disabled,
      icon: duplicate,
      iconAlt: 'duplicate template',
      label: 'Duplicate template'
    },
    {
      action: downloadTemplate.action,
      disabled: downloadTemplate.disabled,
      icon: share,
      iconAlt: 'export template',
      label: 'Export'
    },
    {
      action: deleteTemplate.action,
      disabled: deleteTemplate.disabled,
      icon: trash,
      iconAlt: 'delete template',
      label: 'Delete'
    }
  ]
}
