import Service from '../service'
import { formatDateString } from '../utils'
import _ from 'lodash'

/**
 * Charges data module
 * @redux
 * @reduxActionScope charges
 * @module charges
 */

export const GET_ALL_CHARGES_LIST_REQUESTED =
  'charge/GET_ALL_CHARGES_LIST_REQUESTED'
export const GET_ALL_CHARGES_LIST_FAIL = 'charge/GET_ALL_CHARGES_LIST_FAIL'
export const GET_ALL_CHARGES_LIST_SUCCESS =
  'charge/GET_ALL_CHARGES_LIST_SUCCESS'

export const GET_ALL_UNIT_LIST_REQUESTED = 'charge/GET_ALL_UNIT_LIST_REQUESTED'
export const GET_ALL_UNIT_LIST_FAIL = 'charge/GET_ALL_UNIT_LIST_FAIL'
export const GET_ALL_UNIT_LIST_SUCCESS = 'charge/GET_ALL_UNIT_LIST_SUCCESS'
export const GET_ALL_PO_NUMBER_LIST = 'charge/GET_ALL_PO_NUMBER_LIST'

const initialState = {
  allUnits: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  allCharges: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  allPONumber: []
}

/**
 * Request Charges reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CHARGES_LIST_REQUESTED:
      return {
        ...state,
        allCharges: {
          ...state.allCharges,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_ALL_CHARGES_LIST_SUCCESS:
      return {
        ...state,
        allCharges: {
          ...state.allCharges,
          isRequesting: false,
          isError: false,
          data: action?.data || []
        }
      }
    case GET_ALL_CHARGES_LIST_FAIL:
      return {
        ...state,
        allCharges: {
          ...state.allCharges,
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    case GET_ALL_UNIT_LIST_REQUESTED:
      return {
        ...state,
        allUnits: {
          ...state.allUnits,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_ALL_UNIT_LIST_SUCCESS:
      return {
        ...state,
        allUnits: {
          ...state.allUnits,
          isRequesting: false,
          isError: false,
          data: action?.data || []
        }
      }
    case GET_ALL_UNIT_LIST_FAIL:
      return {
        ...state,
        allUnits: {
          ...state.allUnits,
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    case GET_ALL_PO_NUMBER_LIST:
      return {
        ...state,
        allPONumber: action?.data
      }
    default:
      return state
  }
}

export const allUnitsGet = ({ pdbid, context, onSuccess, onError }) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_UNIT_LIST_REQUESTED
    })
    Service.allUnitsGet(pdbid, context)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_ALL_UNIT_LIST_SUCCESS,
            data: data || []
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_ALL_UNIT_LIST_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_UNIT_LIST_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const getChargesList = (
  pdbid,
  context,
  to,
  from,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_CHARGES_LIST_REQUESTED
    })
    Service.getChargesList(pdbid, context, to, from)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_ALL_CHARGES_LIST_SUCCESS,
            data: _allChargesAdapter(data || [])
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_ALL_CHARGES_LIST_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError()
        }
        dispatch({
          type: GET_ALL_CHARGES_LIST_FAIL,
          data: []
        })
      })
  }
}

export const updateAutomaticCharge = ({
  pdbid,
  context,
  body,
  task_id,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.updateAutomaticCharge(pdbid, context, body, task_id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError()
        }
      })
  }
}

export const addToPo = (pdbid, context, body, onSuccess, onError) => {
  return async dispatch => {
    Service.createPurchaseOrder(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        const data = await res.json()
        if (ok) {
          if (onSuccess) {
            onSuccess(data)
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError()
        }
      })
  }
}

export const addChargesToPO = ({
  pdbid,
  context,
  invoiceId,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    return Service.updateInvoice(pdbid, context, invoiceId, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError()
        }
      })
  }
}

export const allPONumbersGet = (
  pdbid,
  context,
  vendor_id,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.allPONumbersGet(pdbid, context, vendor_id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_ALL_PO_NUMBER_LIST,
            data: data || []
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const updateChargeStatus = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return () => {
    return Service.updateChargeStatus(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const DN = ''

const getStatus = data => {
  const { purchase_order_id, added } = data || {}
  if (purchase_order_id) {
    return 'Added'
  }
  if (!added) {
    return 'Not Added'
  }
  return 'Will Not Be Added'
}

const getFullUnit = data => {
  const { unit, unit_space } = data || {}
  if (unit_space) {
    return `${unit || ''}-${unit_space}`
  }
  return unit || ''
}

const getPOStatus = data => {
  const { status } = data || {}
  if (status) {
    const newStatus = status === 'Pending AP Approval' ? 'approved' : status
    return _.startCase(_.toLower(newStatus))
  }
  return ''
}

const _oneAllChargesAdapter = data => {
  const amount = data?.amount?.toString()?.trim()

  return {
    task_id: typeof data?.task_id === 'string' ? data.task_id : DN,
    unit_id: typeof data?.unit_id === 'string' ? data.unit_id : DN,
    price_type: typeof data?.price_type === 'string' ? data.price_type : DN,
    unit: typeof data?.unit === 'string' ? data.unit : DN,
    note: typeof data?.note === 'string' ? data.note : DN,
    unit_space_id:
      typeof data?.unit_space_id === 'string' ? data.unit_space_id : DN,
    unit_space: typeof data?.unit_space === 'string' ? data.unit_space : DN,
    full_unit: getFullUnit(data),
    vendor: typeof data?.vendor === 'string' ? data?.vendor : '-',
    vendor_id: typeof data?.vendor_id === 'string' ? data.vendor_id : DN,
    item: typeof data?.item === 'string' ? data.item : DN,
    item_options:
      typeof data?.item_options === 'string' ? data.item_options : DN,
    cost: data?.cost ? data?.cost : 0,
    qty: data?.qty ? data?.qty : 0,
    amount: !isNaN(amount) ? parseFloat(amount).toFixed(2) : amount,
    complete_date:
      typeof data?.complete_date === 'string' ? data.complete_date : DN,
    custom_complete_date:
      typeof data?.complete_date === 'string'
        ? formatDateString(data?.complete_date, 'MM/DD/YYYY')
        : DN,
    servicetype_id:
      typeof data?.servicetype_id === 'string' ? data.servicetype_id : DN,
    servicetype: typeof data?.servicetype === 'string' ? data.servicetype : DN,
    added: typeof data?.added === 'string' ? data.added : null,
    purchase_order_id:
      typeof data?.purchase_order_id === 'string' ? data.purchase_order_id : DN,
    po_number: typeof data?.po_number === 'string' ? data.po_number : DN,
    type: typeof data?.type === 'string' ? data.type : DN,
    status: getStatus(data),
    po_status: getPOStatus(data)
  }
}

const _allChargesAdapter = data => {
  return Array.isArray(data) ? data.map(d => _oneAllChargesAdapter(d)) : []
}
