import React from 'react'
import PropTypes from 'prop-types'
import ComponentWithStatus from '../component-with-status'

/**
 * Turn Completed complete box
 *
 * @component
 * @example
 * return (
 *   <PercentageComplete
 *     loading={false}
 *     error={false}
 *     percentage={98.5}
 *   />
 * )
 */
const PercentageComplete = ({ loading, error, percentage }) => {
  return (
    <div className="dashboard-card card-semi">
      <ComponentWithStatus loading={loading} error={error}>
        <div className="vertical-center has-text-centered">
          <p className="is-size-2">{percentage}%</p>
          <p>Turn Completed</p>
        </div>
      </ComponentWithStatus>
    </div>
  )
}

PercentageComplete.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Turn Completed complete
   */
  percentage: PropTypes.number
}

export default PercentageComplete
