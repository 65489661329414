import React, { useMemo } from 'react'
import { edit, trash } from 'ui/icons'

import DataTableMenu from '../../../../components/data-table-menu'
import { useHistory } from 'react-router'
import { checkPermission, sectionManageable } from '../../../../security'
import { useAppUser } from '../../../../helpers/hooks'
import { lock, unlock } from 'ui/icons'

const ActionColumn = ({
  row,
  onShowDeleteModal,
  onShowReactivateOrInactivateModal
}) => {
  const history = useHistory()
  const { user } = useAppUser()

  const { cell } = row || {}
  const { row: currentRow } = cell || {}
  const { original } = currentRow || {}
  const {
    vendor_id,
    used,
    Users: vendorUsers,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    email,
    phone,
    taxid,
    currency,
    terms,
    name,
    status,
    integration_id
  } = original || {}

  const isVendorActive = status === 'Active' ? true : false

  const vendorCompanyData = {
    address1,
    address2,
    city,
    state,
    zip,
    country,
    email,
    phone,
    taxid,
    currency,
    terms,
    name,
    status,
    integration_id
  }

  const menuOptions = useMemo(() => {
    const options = []

    if (sectionManageable('/setup/vendor-manage', user)) {
      options.push({
        caption: 'Edit Vendor',
        icon: edit,
        action: () => {
          history.push(`/setup/vendor-manage?v=${vendor_id}&edit=true`)
        }
      })
    }

    if (sectionManageable('/setup/vendor-manage', user) && used) {
      options.push({
        caption: isVendorActive ? 'Inactivate Vendor' : 'Reactivate Vendor',
        icon: isVendorActive ? unlock : lock,
        action: () => {
          onShowReactivateOrInactivateModal &&
            onShowReactivateOrInactivateModal(
              vendor_id,
              vendorCompanyData,
              vendorUsers,
              isVendorActive
            )
        }
      })
    }
    if (sectionManageable('/setup/vendor-manage', user) && !used) {
      options.push({
        icon: trash,
        caption: 'Delete Vendor',
        action: () => onShowDeleteModal && onShowDeleteModal(vendor_id)
      })
    }

    return options
  }, [user, used, vendor_id])

  const showActionMenu = useMemo(() => checkPermission('manage-vendor', user), [
    user
  ])

  return (
    <div className="menu-item">
      {showActionMenu && <DataTableMenu options={menuOptions} row={row} />}
    </div>
  )
}

export default ActionColumn
