import _ from 'lodash'
import React, {useState, useEffect, useMemo} from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import { getFormData, animate } from '../../helpers'

const DEFAULT = 'none'
const SetupTeamManageModalInternal = ({
  modal,
  location,
  defaults,
  context,
  user,
  setupOneTeamUpdate,
  closeModal,
  setupStaff,
  history
}) => {
  const [staff, setStaff] = useState(DEFAULT)
  const [service, setService] = useState([])
  const { teamId } = defaults || {}

  const prevModal = usePrevious(modal)

  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)

    return {
      t: newParams.get('t') || DEFAULT,
    }
  }, [location])

  const { t } = params

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    const {
      isActive,
    } = modal
    const wasActive = _.get(prevModal, 'isActive', false)
    const aChanged = isActive !== wasActive
    if (aChanged && isActive) reset()
  }, [modal])

  const reset = () => {
    const {
      staff = DEFAULT,
      service = [],
    } = defaults

    setStaff(staff)
    setService(service || [])
  }

  const submit = () => {
    const { pdbid } = user.user_metadata
    const form = document.getElementById('setup-teams-modal-internal')
    const body = getFormData(form)
    if (body.staff_uri === 'none') {
      const el = document.getElementById('setup-teams-modal-internal-select')
      el.focus()
      animate(el, 'shake')
      return
    }
    setupOneTeamUpdate(pdbid, context, t, body)
    history.push(`/setup/team-manage?t=${teamId}`)
    closeModal()
  }

  const getDropdowns = () => {
    const {
      allStaff,
    } = setupStaff
    return(
      <div className="select is-fullwidth">
        <select
          className="input is-fullwidth"
          id="setup-teams-modal-internal-select"
          onChange={e => setStaff(e.target.value)}
          value={staff}
        >
          <option value="none">Select</option>
          {allStaff.data.map(s=>(
            <option value={s.staff_uri} key={s.staff_id}>{s.staff_name}</option>
          ))}
        </select>
        <input
          type="hidden"
          name="staff_uri"
          value={staff}
        />
      </div>
    )
  }

  const onSubmit = (e) => e.preventDefault()

  return (
    <form onSubmit={(e) => onSubmit(e)} id="setup-teams-modal-internal">
      <div className="columns is-multiline">
        <div className="column is-half">
          <p className="is-size-4 m-b-md">Team Member</p>
          <div className="form-control">
            <label forhtml="service">Internal Staff:</label>
            {getDropdowns()}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="form-control">
            <label forhtml="option">Associated Service(s):</label>
            {(service || []).map((serv, i) => <p key={i} className="associate-service">{serv.service_name === 'Cleaning' ? 'Clean' : serv.service_name}</p>)}
          </div>
        </div>
      </div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-one-quarter is-offset-one-quarter has-text-right">
          <div className="form-control">
            <button
              type="button"
              className="button is-secondary"
              onClick={()=>{
                history.push(`/setup/team-manage?t=${teamId}`)
                closeModal()
              }}
            >Cancel</button>
          </div>
        </div>
        <div className="column is-one-quarter has-text-left">
          <div className="form-control">
            <button
              type="button"
              className="button is-primary"
              onClick={() => submit()}
            >Submit</button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetupTeamManageModalInternal.propTypes = {
  modal: PropTypes.object,
  defaults: PropTypes.object,
  user: PropTypes.object,
  allInternals: PropTypes.object,
  setupOneTeamUpdate: PropTypes.func,
  closeModal: PropTypes.func,
}

export default SetupTeamManageModalInternal
