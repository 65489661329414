import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import find from 'lodash/find'
import {
  addTax as addTaxIcon,
  share,
  trash,
  undo,
  attachInvoice as attachInvoiceIcon,
  remove as removeIcon
} from 'icons'
import {
  startEditingInvoice,
  detachInvoiceFromPurchaseOrder
} from 'modules/invoices'
import { getInspectionDownloadMediaURL } from 'modules/inspect'
import {
  InvoicesSendModal,
  InvoicesApproveModal,
  InvoicesPaymentModal,
  SendToDraftModal,
  ConfirmDeleteInvoice,
  InvoiceAttachModal,
  InvoicesAddTaxModal,
  InvoicesViewPdfModal
} from 'components/invoice'
import { closeModal } from 'modules/modal'
import { Button, DropdownButton, DropdownMenu } from 'ui'
import { useModals } from 'hooks'
import { formatDateString, formatISODateString } from '../../../utils'
import PermissionGate from '../../permission-gate'
import { checkPermission } from '../../../utils/helperFunctions'
import * as section from '../../../utils/sectionConstants'
import ConfirmationModal from '../../confirmation-modal'
import ReactTooltip from 'react-tooltip'
import '../../../styles/common.scss'

const ReadingActions = ({ setVendorInvoiceNumberCaveat }) => {
  const { details: invoice, detailsIsRequesting, detailsIsError } = useSelector(
    state => state.invoices
  )
  const inspect = useSelector(state => state.inspect)
  const user = useSelector(state => state.user)
  const context = useSelector(state => state.context)
  const { properties, projects, allowedSections } = user.user_metadata
  const approver = checkPermission(allowedSections, section.APPROVER)
  const dispatch = useDispatch()
  const [showModal, dismissModal] = useModals()
  const emptyValue = 'N/A'
  const formattedInvoiceStatus = invoice?.status
    ?.toLowerCase()
    ?.replaceAll(' ', '_')

  const isApproved = ['approved', 'pending_ap_approval'].includes(
    formattedInvoiceStatus
  )

  const [displayInvoicePdf, setDisplayInvoicePdf] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const sendInvoice = () => {
    const { doc } = getPDF()
    const { vendor_email, amount_due, po_number, vendor_name: company } =
      invoice || {}

    showModal(
      { width: '480px' },
      <InvoicesSendModal
        invoice={invoice}
        doc={doc}
        vendor_email={vendor_email}
        amount_due={amount_due}
        po_number={po_number}
        company={company}
      />
    )
  }

  const approvedInvoice = () => {
    if (!invoice?.vendor_po_number?.trim()) {
      setVendorInvoiceNumberCaveat(true)
      return
    }

    showModal({ width: '480px' }, <InvoicesApproveModal invoice={invoice} />)
  }

  const paymentInvoice = () =>
    showModal({ width: '480px' }, <InvoicesPaymentModal invoice={invoice} />)
  const backToDraft = () =>
    showModal({ width: '480px' }, <SendToDraftModal invoice={invoice} />)
  const deleteInvoice = () =>
    showModal({ width: '480px' }, <ConfirmDeleteInvoice invoice={invoice} />)
  const addTax = () =>
    showModal({ width: '480px' }, <InvoicesAddTaxModal invoice={invoice} />)
  const attachInvoice = () =>
    showModal({ width: '480px' }, <InvoiceAttachModal invoice={invoice} />)

  const startEditing = () => {
    dispatch(startEditingInvoice())
  }

  const ButtonStates = {
    draft: (
      <Button type="info" onClick={sendInvoice}>
        Send To...
      </Button>
    ),
    rejected: (
      <Button type="info" onClick={sendInvoice}>
        Send To...
      </Button>
    ),
    sent: (
      <>
        <span data-tip data-for="approve-po">
          <Button
            type="info"
            onClick={approvedInvoice}
            disabled={
              !approver || !invoice?.invoice_file || !invoice?.vendor_po_number
            }>
            Approve
          </Button>
        </span>
        <ReactTooltip
          className="customTooltipTheme"
          id="approve-po"
          place="bottom"
          disable={invoice?.invoice_file && invoice?.vendor_po_number}
          effect="solid">
          <div className="has-text-left">
            <p>
              Please enter{' '}
              <span style={{ fontWeight: 'bold' }}> Vendor Invoice Number</span>
            </p>
            <p>
              and <span style={{ fontWeight: 'bold' }}>Attach Invoice</span>, In
              order to
            </p>
            <p>approve this purchase order</p>
          </div>
        </ReactTooltip>
      </>
    ),
    approved: (
      <Button type="third" onClick={paymentInvoice}>
        Apply Payment
      </Button>
    ),
    pending_ap_approval: (
      <Button type="third" onClick={paymentInvoice}>
        Apply Payment
      </Button>
    )
  }

  const pdbid = user?.user_metadata?.pdbid

  useEffect(() => {
    dispatch(getInspectionDownloadMediaURL(pdbid, context))
  }, [pdbid, context])

  const getPDF = () => {
    const datetime = formatDateString(new Date(), 'MM/DD/YYYY')
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const {
      address,
      postal_code,
      state,
      city,
      country,
      property_name
    } = invoice

    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }

    let doc = new jsPDF()
    let yOffset = 10

    const getPurchaseOrderHeader = () => {
      doc.setFontSize(16)
      doc.text(property_name, 2, yOffset)
      doc.setFontSize(12)
      doc.text(
        `${address} | ${city}, ${state} ${postal_code} | ${country}`,
        100,
        yOffset
      )

      yOffset = yOffset + 15
      doc.setFontSize(11)
      doc.setTextColor(0, 129, 195)
      doc.text('Purchase Order For:', 3, yOffset)
      doc.text('Created Date:', 45, yOffset)
      doc.text('P.O. Number:', 75, yOffset)
      doc.text('Amount Due:', 105, yOffset)

      yOffset = 32
      doc.setFontSize(11)
      doc.setTextColor(18, 32, 72)
      doc.text(`${invoice.vendor_name || emptyValue}`, 3, yOffset)
      doc.text(
        `${
          invoice.po_date ? formatISODateString(invoice.po_date) : emptyValue
        }`,
        45,
        yOffset
      )
      doc.text(`${invoice.po_number || emptyValue}`, 75, yOffset)
      doc.setFontSize(16)
      doc.text(
        invoice.amount_due ? `$${invoice.amount_due}` : emptyValue,
        105,
        yOffset
      )
    }

    const getPurchaseLines = () => {
      yOffset = yOffset + 10

      const head = [['Description', 'Rate', 'QTY', 'Amount']]

      const body = []
      invoice.po_lines.forEach(line => {
        if (line.item) {
          body.push([
            line.item,
            `$${line.rate}`,
            line.quantity,
            `$${line.amount}`
          ])
        }
      })

      doc.autoTable({
        head,
        body,
        margin: { top: yOffset, left: 3, right: 3 },
        theme: 'plain',
        styles: {
          halign: 'center',
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: [229, 233, 242],
          minCellWidth: 22,
          minCellHeight: 12,
          textColor: [18, 32, 72],
          textAlign: 'left'
        },
        columnStyles: {
          0: { halign: 'left' }
        }
      })

      yOffset = yOffset + 12 * invoice.po_lines.length

      if (yOffset >= doc.internal.pageSize.getHeight()) {
        doc.addPage()
        yOffset = 10
      }
    }

    const getPurchaseOrderSummary = () => {
      doc.setFontSize(11)

      yOffset = yOffset + 20
      doc.setFillColor(248, 249, 250)
      doc.rect(3, yOffset, doc.internal.pageSize.getWidth(), 60, 'F')

      doc.text('Subtotal:', 150, yOffset + 10)
      doc.text(`$${invoice.subtotal}`, 188, yOffset + 10)
      doc.text('Tax:', 150, yOffset + 17)
      doc.text(`$${invoice.tax_amount}`, 188, yOffset + 17)
      doc.line(150, yOffset + 24, 190, yOffset + 24, 'F')

      doc.text('Total:', 150, yOffset + 30)
      doc.text(`$${invoice.total_amount}`, 188, yOffset + 30)
      doc.text('Amount Paid:', 150, yOffset + 37)
      doc.text(`$${invoice.amount_paid}`, 188, yOffset + 37)
      doc.line(150, yOffset + 44, 190, yOffset + 44, 'F')

      doc.setTextColor(61, 179, 226)
      doc
        .text('Amount Due (USD):', 150, yOffset + 51)
        .setFont(undefined, 'bold')
      doc.setTextColor(18, 32, 72)
      doc
        .text(`$${invoice.amount_due}`, 188, yOffset + 51)
        .setFont(undefined, 'bold')
    }

    getPurchaseOrderHeader()
    getPurchaseLines()
    getPurchaseOrderSummary()

    const filename = [
      names.project,
      names.property,
      invoice.po_number,
      datetime
    ].join('-')

    return { doc, filename }
  }

  const exportToPDF = () => {
    const { doc, filename } = getPDF()
    doc.save(`${filename}.pdf`)
  }

  const exportToCSV = () => {
    const datetime = formatDateString(new Date(), 'MM/DD/YYYY')

    const invoiceMainInfo = [
      [
        'Purchase Order For',
        'Created Date',
        'Due Date',
        'P.O. Number',
        'Vendor Invoice Number',
        'Subtotal',
        'Tax',
        'Amount Paid',
        'Amount Due'
      ],
      [
        invoice.vendor_name || emptyValue,
        invoice.po_date ? formatISODateString(invoice.po_date) : emptyValue,
        invoice.due_date ? formatISODateString(invoice.due_date) : emptyValue,
        invoice.po_number ? invoice.po_number : emptyValue,
        invoice.vendor_po_number ? invoice.vendor_po_number : emptyValue,
        invoice.subtotal >= 0 ? `$${invoice.subtotal}` : emptyValue,
        invoice.tax_amount >= 0 ? `$${invoice.tax_amount}` : emptyValue,
        invoice.amount_paid >= 0 ? `$${invoice.amount_paid}` : emptyValue,
        invoice.amount_due >= 0 ? `$${invoice.amount_due}` : emptyValue
      ],
      new Array(9).fill('')
    ]

    const linesInfo = invoice.po_lines.map(row => [
      row.unit,
      row.unit_space,
      row.item,
      `$${row.rate}`,
      row.quantity,
      `$${row.amount}`
    ])

    linesInfo.unshift([
      'Unit',
      'Unit Space',
      'Description',
      'Rate',
      'Quantity',
      'Amount'
    ])

    const rows = [...invoiceMainInfo, ...linesInfo]

    const csvContent = [
      'data:text/csv;charset=utf-8,',
      rows.map(e => e.join(',')).join('\n')
    ].join('')

    const encodedUri = encodeURI(csvContent).replace(/#/g, '%23')
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    const filename = [
      names.project,
      names.property,
      invoice.po_number,
      datetime
    ].join('-')
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    if (!detailsIsRequesting && !detailsIsError && submitted) {
      dispatch(closeModal())
      setSubmitted(false)
    }
  }, [detailsIsRequesting, detailsIsError, submitted])

  const getInvoicePdfUrl = () => {
    const { property, project } = context || {}
    return `${inspect?.inspectionDownloadMediaURL ||
      ''}Inspections/${project}_${property}/${invoice?.invoice_file}`
  }

  const removeInvoice = () => {
    setSubmitted(true)
    dispatch(
      detachInvoiceFromPurchaseOrder(
        pdbid,
        context,
        invoice.po_id,
        invoice?.status
      )
    )
  }

  const canRemoveInvoice =
    formattedInvoiceStatus !== 'pending_ap_approval' &&
    formattedInvoiceStatus !== 'approved'

  return (
    <>
      <PermissionGate name="pay-order-actions">
        <DropdownMenu title="More Actions">
          {invoice?.invoice_file ? (
            <DropdownButton
              icon={attachInvoiceIcon}
              disabled={submitted}
              title={
                <>
                  View Invoice
                  {canRemoveInvoice && (
                    <img
                      className="m-l-md"
                      src={removeIcon}
                      alt="Remove Invoice"
                      title="Remove Invoice"
                      style={{
                        borderRadius: 20
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        showModal(
                          { width: '480px' },
                          <ConfirmationModal
                            msg="You’re about to remove invoice. This action cannot be undone. Are you sure you want to remove?"
                            dismissLabel="Cancel"
                            submitLabel="Yes, Delete"
                            submit={() => {
                              removeInvoice()
                              dismissModal()
                            }}
                            dismiss={dismissModal}
                          />
                        )
                      }}
                    />
                  )}
                </>
              }
              onClick={() => setDisplayInvoicePdf(true)}
            />
          ) : (
            <DropdownButton
              icon={attachInvoiceIcon}
              title="Attach Invoice"
              onClick={attachInvoice}
            />
          )}
          <DropdownButton icon={addTaxIcon} title="Add Tax" onClick={addTax} />
          <DropdownButton
            icon={share}
            title="Export PDF"
            onClick={exportToPDF}
          />
          <DropdownButton
            icon={share}
            title="Export CSV"
            onClick={exportToCSV}
          />
          <DropdownButton
            icon={trash}
            title="Delete"
            onClick={deleteInvoice}
            visible={!isApproved}
          />
          <DropdownButton
            icon={undo}
            title="Move to Draft"
            onClick={backToDraft}
            // visible={isApproved && approver}
            visible={false}
          />
        </DropdownMenu>
        {!isApproved && (
          <Button type="primary" onClick={startEditing}>
            Edit
          </Button>
        )}
        {invoice.status && ButtonStates[formattedInvoiceStatus]}
        {displayInvoicePdf && (
          <InvoicesViewPdfModal
            close={() => setDisplayInvoicePdf(false)}
            source={getInvoicePdfUrl()}
            title={`Purchase Order - ${invoice?.po_number || ''}`}
          />
        )}
      </PermissionGate>
    </>
  )
}

export default ReadingActions
