import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import RequestsForProposalMain from '../../parents/requests-for-proposal-main'
import BigLoading from '../../components/big-loading'
import {
  requestsForProposalGet
} from '../../modules/requests-for-proposal'

const RequestForProposal = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <RequestsForProposalMain {...props} />
  }

  return <BigLoading />
}

RequestForProposal.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  context: PropTypes.object,
  requestsForProposal: PropTypes.object,
  requestsForProposalGet: PropTypes.func,
}

const mapStateToProps = ({ user, context, requestsForProposal }) => ({
  user,
  context,
  requestsForProposal
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestsForProposalGet
    },
    dispatch
  )


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestForProposal)
)
