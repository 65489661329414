import React from 'react'
import PropTypes from 'prop-types'
import { ServiceColors } from '../../data'

import './styles.scss'

const VendorWorkCard = ({ order, handleClick }) => {
  const internalHandleClick = () => {
    handleClick && handleClick(order)
  }

  return (
    <div
      className="column is-half"
      key={order.workorder}
      onClick={internalHandleClick}>
      <div className="card is-fullheight vendor-work-card">
        <div className="card-content has-text-centered">
          <span
            className="vendor-work-card-tag is-medium is-uppercase is-dark has-text-weight-medium"
            style={{ backgroundColor: ServiceColors[order.service] || 'grey' }}>
            {order.service}
          </span>
          <div className="vendor-work-card-unit has-text-weight-normal">{`Unit ${order.unit}`}</div>
          <p className="subtitle is-6 is-marginless has-text-grey">
            {order.type}
          </p>
        </div>
      </div>
    </div>
  )
}

VendorWorkCard.propTypes = {
  order: PropTypes.object,
  handleClick: PropTypes.func
}

export default VendorWorkCard
