import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../breadcrumbs'
import DownloadInspectionsList from '../download-inspections-list'

const DownloadInspectionsMain = props => {
  const {
    getDownloadInspectionsList,
    viewAllDownloads,
    getInspectionDownloadMediaURL,
    user,
    context,
    location
  } = props
  const { pdbid } = user.user_metadata
  const searchParams = new URLSearchParams(location.search)

  useEffect(() => {
    const paramsLength = Array.from(searchParams).length

    if (!paramsLength) {
      getDownloadInspectionsList(pdbid, context, () => {
        viewAllDownloads(pdbid, context, { new: 'false' })
      })
      getInspectionDownloadMediaURL(pdbid, context)
    }
  }, [location.search, context])

  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns">
          <div className="column">
            <DownloadInspectionsList {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

DownloadInspectionsMain.propTypes = {
  context: PropTypes.object,
  getPropertyFormsList: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object
}

export default DownloadInspectionsMain
