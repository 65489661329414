import React, { useMemo } from 'react'
import { currencyFormatter } from '../../utils'
import './index.scss'

const InvoiceSummary = ({ current }) => {
  const { amount_due } = current || {}

  const totalValue = useMemo(
    () =>
      (
        parseFloat(current?.subtotal) + parseFloat(current?.tax_amount)
      )?.toFixed(2),
    [current]
  )

  return (
    <div className="invoice-summary">
      <table className="table invoice-summary__table">
        <tbody>
          <tr>
            <td className="has-text-right">Subtotal:</td>
            <td className="is-narrow">
              {currencyFormatter(parseFloat(current?.subtotal)?.toFixed(2), {
                disableRounding: false
              })}
            </td>
          </tr>
          <tr>
            <td className="has-text-right">Tax:</td>
            <td className="is-narrow">
              {currencyFormatter(parseFloat(current?.tax_amount)?.toFixed(2), {
                disableRounding: false
              })}
            </td>
          </tr>
          <tr>
            <td colSpan="2" align="right">
              <span className="invoice-summary__table-line"></span>
            </td>
          </tr>
          <tr>
            <td className="has-text-right">Total:</td>
            <td className="is-narrow">
              {currencyFormatter(totalValue, {
                disableRounding: false
              })}
            </td>
          </tr>
          <tr>
            <td className="has-text-right">Amount Paid:</td>
            <td className="is-narrow">
              {currencyFormatter(parseFloat(current?.amount_paid)?.toFixed(2), {
                disableRounding: false
              })}
            </td>
          </tr>
          <tr>
            <td colSpan="2" align="right">
              <span className="invoice-summary__table-line"></span>
              <span className="invoice-summary__table-line"></span>
            </td>
          </tr>
          <tr className="has-text-weight-bold">
            <td className="has-text-right" style={{ color: '#3DB3E2' }}>
              Amount Due (USD):
            </td>
            <td className="is-narrow">
              {currencyFormatter(parseFloat(amount_due)?.toFixed(2), {
                disableRounding: false
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default InvoiceSummary
