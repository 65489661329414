import React from 'react'
import ActionColumn from './download-inspections-table/download-inspections-table-columns/action-column'
import DefaultColumn from './download-inspections-table/download-inspections-table-columns/default-column'
import NameColumn from './download-inspections-table/download-inspections-table-columns/name-column'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import LinkColumn from './download-inspections-table/download-inspections-table-columns/link-column'
import StatusColumn from './download-inspections-table/download-inspections-table-columns/status-column'
import StatusColumnFilter from './download-inspections-table/dowload-inspections-table-column-filters/status-column-filter'

export const headerData = inspectionDownloadMediaURL => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <NameColumn row={row} />
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      filter: 'equals',
      Filter: StatusColumnFilter,
      textAlign: 'left',
      Cell: row => {
        return <StatusColumn row={row} />
      }
    },
    {
      Header: 'Link',
      accessor: 'file_name',
      textAlign: 'left',
      flexGrow: 1,
      disableFilters: true,
      disableSortBy: true,
      Cell: row => {
        return <LinkColumn row={row} baseUrl={inspectionDownloadMediaURL} />
      }
    }
    // {
    //   Header: '',
    //   accessor: 'template_id',
    //   textAlign: 'center',
    //   disableFilters: true,
    //   disableSortBy: true,
    //   width: 40,
    //   Cell: row => {
    //     return <ActionColumn row={row} />
    //   }
    // }
  ]
}
