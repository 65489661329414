import React from 'react'
import InspectionCell from './components/InspectionCell'
import { Link } from 'react-router-dom'
import DataTableMenu from '../../components/data-table-menu'
import { usePermissionGate } from '../..//helpers/hooks'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import UnitFilter from '../../components/react-table-column-filters/UnitFilter'

export const headerData = ({
  getRowOption,
  SelectColumnFilter,
  resetPurchaseOrderDetail
}) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Vendor',
        accessor: 'vendor',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 170,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Service',
        accessor: 'servicetype',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        textAlign: 'left',
        width: 170,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'equals',
        textAlign: 'left',
        width: 100,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Price Type',
        accessor: 'price_type',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Option',
        accessor: 'item_options',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        textAlign: 'left',
        width: 140,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Unit',
        accessor: 'full_unit',
        Filter: UnitFilter,
        filter: 'customIncludes',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Charge Date',
        accessor: 'custom_complete_date',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlign: 'left',
        Filter: SelectColumnFilter,
        filter: 'equals',
        width: 150,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Amount ($)',
        accessor: 'amount',
        textAlign: 'left',
        disableFilters: true,
        width: 110,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Purchase Order',
        accessor: 'po_number',
        Filter: SelectColumnFilter,
        filter: 'equals',
        textAlign: 'left',
        width: 140,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow, value } = cell || {}
          const { original } = currentRow || {}
          const { status, purchase_order_id, vendor_id } = original || {}
          if (status === 'Will Not Be Added') {
            return (
              <div className="cell-item">
                <span className="disabled-row">N/A</span>
              </div>
            )
          }
          if (status === 'Not Added') {
            return <div className="cell-item"></div>
          }
          if (status === 'Added') {
            return (
              <div
                className="cell-item"
                onClick={() => resetPurchaseOrderDetail()}>
                <Link
                  className="link"
                  to={`/payables/purchase-orders/${purchase_order_id}/${vendor_id}`}>
                  {value || ''}
                </Link>
              </div>
            )
          }
          return ''
        }
      },
      {
        Header: 'P.O Status',
        accessor: 'po_status',
        textAlign: 'left',
        Filter: SelectColumnFilter,
        filter: 'equals',
        width: 150,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: '',
        accessor: 'charge_id1',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 40,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original, index } = currentRow || {}
          const { status, vendor_id } = original || {}
          const currentOptions = getRowOption(original, index)
          const { hasPermission } = usePermissionGate('pay-order-actions')
          return (
            <div className="menu-item">
              {hasPermission && status !== 'Added' && vendor_id && (
                <DataTableMenu options={currentOptions || []} row={original} />
              )}
            </div>
          )
        }
      }
    ]
  }
]
