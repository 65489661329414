import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  circleApproved,
  circleApprovedClicked,
  circleNotApproved,
  circleNotApprovedClicked
} from '../../ui/icons'
import './index.scss'

export const QCJobUnitDetailTitle = ({ unitspace, description, action }) => {
  return (
    <div className="qc-job-unit-detail qc-job-unit-detail-border">
      <div className="qc-job-unit-detail-unit qc-job-unit-detail-bold">
        {unitspace}
      </div>
      <div className="qc-job-unit-detail-description qc-job-unit-detail-bold">
        {description}
      </div>
      <div className="qc-job-unit-detail-status qc-job-unit-detail-bold">
        {action}
      </div>
    </div>
  )
}

const QCJobUnitDetail = ({
  job,
  status,
  handleRejectCheck,
  handleApproveCheck
}) => {
  return (
    <div className="qc-job-unit-detail">
      <div className="qc-job-unit-detail-unit">{job.unitspace}</div>
      <div className="qc-job-unit-detail-description">
        {job.item_option_description}
      </div>
      <div className="qc-job-unit-detail-status">
        {job.is_new ? (
          'New'
        ) : (
          <>
            {job.item_option ? (
              <>
                {status === 'working' ? (
                  <>
                    <button
                      className="qc-job-unit-detail-action"
                      onClick={handleRejectCheck(job)}>
                      <img
                        src={
                          job.qc_result === 'rejected'
                            ? circleNotApprovedClicked
                            : circleNotApproved
                        }
                        alt="reject"
                      />
                    </button>
                    <button
                      className="qc-job-unit-detail-action"
                      onClick={handleApproveCheck(job)}>
                      <img
                        src={
                          job.qc_result === 'approved'
                            ? circleApprovedClicked
                            : circleApproved
                        }
                        alt="approve"
                      />
                    </button>
                  </>
                ) : (
                  <FontAwesomeIcon icon={faSpinner} size="lg" color="grey" />
                )}
              </>
            ) : (
              'Renewal'
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default QCJobUnitDetail
