import React, { useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ScheduleDnD from '../ScheduleDnD'
import { scheduleService, resetScheduleManualData } from '../../../modules/schedule'
import { StepperContext } from '../../../components/wizard'
import TurnableSpinner from '../../../components/turnable-spinner'
import './index.scss'

const ScheduleSecondStep = () => {
  const dispatch = useDispatch()
  const { user, context, schedule } = useSelector(state => state)
  const { scheduleServiceIsRequesting, scheduleServiceIsError, scheduleServiceRequested, scheduleManualDataIsRequesting, scheduleManualData } = schedule
  const { stepData, backActionHandler, mainWizardCurrentStep, setWizardSteps } = useContext(StepperContext)
  const [loadingData, setLoadingData] = useState(true)

  const serviceType = stepData['servicetype'].replace(/[^a-zA-Z ]/g, '').split(' ').join('')

  useEffect(() => {
    if (!scheduleManualDataIsRequesting && scheduleManualData) {
      setLoadingData(false)
    }
  }, [scheduleManualDataIsRequesting])

  useEffect(() => {
    if (!scheduleServiceIsRequesting && !scheduleServiceIsError && scheduleServiceRequested) {
      setWizardSteps( steps => {
        const stepsCopy = [...steps]
        stepsCopy[mainWizardCurrentStep]['done'] = true
        return stepsCopy
      })
    }
  }, [schedule])
  
  const scheduleHandler = data => {
    const { pdbid } = user.user_metadata
    dispatch(scheduleService(pdbid, context, serviceType, { servicetype: serviceType.toLowerCase(), units: data }))
  }

  const backHandler = () => {
    dispatch(resetScheduleManualData())
    backActionHandler()
  }

  return (
    loadingData ? (
      <div className="is-full-width p-b-lg">
        <div className="has-text-centered">
          <TurnableSpinner size="large" className="loading-spinner" />
          <p className="loading-text">Loading...</p>
        </div>
      </div>
    ) : (
      <ScheduleDnD goBackAction={backHandler} scheduleHandler={scheduleHandler} />
    )
  )
}

export default ScheduleSecondStep
