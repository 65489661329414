import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../parents/breadcrumbs'
import RequestVendorsList from '../request-vendors-list'
import RequestRFPsList from '../request-rfp-list'

const RequestVendorsMain = (props) => {
  const { location } = props
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns">
          <div className="column">
            <RequestVendorsList {...props} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <RequestRFPsList {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

RequestVendorsMain.propTypes = {
  modal: PropTypes.object,
  context: PropTypes.object,
  user: PropTypes.object,
  responsive: PropTypes.object,
  inspect: PropTypes.object,
  inspectActivity: PropTypes.object,
  getInspectActivityLog: PropTypes.func,
  location: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

export default RequestVendorsMain
