import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { currencyFormatter } from '../../utils'

/**
 * Total turn cost box
 *
 * @component
 * @example
 * return (
 *   <TotalTurnCost
 *     costs={[
 *       {
 *         service: 'Paint',
 *         budget: 75000,
 *         difference: 25000,
 *         actual: 50000
 *       },
 *       {
 *         service: 'Clean',
 *         budget: 50000,
 *         difference: -10000,
 *         actual: 60000
 *       },
 *       {
 *         service: 'CP Rep',
 *         budget: 20000,
 *         difference: 20000,
 *         actual: 0
 *       }
 *     ]}
 *   />
 * )
 */
const TotalTurnCost = ({ costs }) => {
  const totalCost = _.reduce(costs, (acc, cost) => acc + cost.actual, 0)
  return (
    <div className="dashboard-card card-semi has-text-centered">
      <div className="vertical-center">
        <p className="xl-text main-color">{currencyFormatter(totalCost)}</p>
        <p>Total Turn Cost</p>
      </div>
    </div>
  )
}

TotalTurnCost.defaultProps = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Array with the costs on the current property
   */
  costs: PropTypes.array
}

export default TotalTurnCost
