import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import './index.scss'

const FallbackMethod = () => {
  /* Empty fallback function*/
}

/**
 * Component for showing an editable input with change and focus event handlers
 * and keystroke detection to change value on enter key press
 *
 * @component
 * @example
 * return (
 *   <EditField
 *     value="Test data"
 *     label="Test label"
 *   />
 * )
 */
const EditField = ({
  locked = false,
  active = false,
  isTouched = false,
  value = '',
  label = 'Label',
  error = '',
  className = '',
  customInputContainerStyle = {},
  onChange = FallbackMethod,
  onFocus = FallbackMethod,
  onBlur = FallbackMethod,
  onKeyStroke = FallbackMethod,
  disabled = false,
  // filter should be a string containing regex (without flags)
  filter = '',
  defaultValue = '',
  ...props
}) => {
  const [currentActive, setActive] = useState(locked && active)
  const [currentValue, setValue] = useState(value)
  const [touched, setTouched] = useState(false)

  useEffect(() => setValue(value), [value])
  useEffect(() => setTouched(isTouched), [isTouched])

  const changeValue = event => {
    if (!locked) {
      const newValue = event.target.value

      if (filter && newValue) {
        if (new RegExp(filter, 'g').test(newValue)) {
          setValue(newValue)
          onChange(newValue)
        }
      } else {
        setValue(newValue)
        onChange(newValue)
      }
      onKeyStroke(newValue)
    }
  }

  const fieldClassName = `field ${currentActive && 'active'} ${locked &&
    !currentActive &&
    'locked'}`

  return (
    <div
      className={`${fieldClassName} ${className}`}
      style={customInputContainerStyle}>
      <input
        className={`input edit-field__input ${error && ' is-danger'}`}
        id={Short.uuid()}
        name={Short.uuid()}
        type="text"
        value={currentValue}
        placeholder={label}
        onChange={changeValue}
        onFocus={() => {
          if (!locked) {
            setActive(true)
            onFocus()
          }
        }}
        onBlur={e => {
          if (!locked) {
            setActive(false)
            setTouched(true)
            const val = currentValue || defaultValue
            setValue(val)
            onChange(val)
            onBlur(e)
          }
        }}
        disabled={disabled}
        {...props}
      />
      {error && touched && <p className="help is-danger">{error}</p>}
    </div>
  )
}

EditField.propTypes = {
  /**
   * Determines if the input should be locked
   */
  locked: PropTypes.bool,
  /**
   * Determines if the input should be active
   */
  active: PropTypes.bool,
  /**
   * Any custom style to be applied to the input field
   */
  customInputContainerStyle: PropTypes.object,
  /**
   * Initial value for the input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Input label text
   */
  label: PropTypes.string,
  /**
   * Input error text
   */
  error: PropTypes.string,
  /**
   * Event handler for the change action
   */
  onChange: PropTypes.func,
  /**
   * Event handler for the focus action
   */
  onFocus: PropTypes.func,
  /**
   * Event handler for the keystroke action
   */
  onKeyStroke: PropTypes.func
}

EditField.defaultProps = {
  locked: false,
  active: false,
  value: '',
  label: 'Label',
  onChange: FallbackMethod,
  onFocus: FallbackMethod,
  onKeyStroke: FallbackMethod
}

export default EditField
