import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from 'hooks'
import { close, closeFilled } from 'ui/icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import './index.scss'

const NewPropertyFormModal = ({
  dismiss = () => {},
  data: allSpaceData,
  createElementSpace,
  onRefreshElements,
  isEdit = false,
  row = {}
}) => {
  const { unitSpaceList } = useSelector(state => state.companyTemplates)
  const [loading, setLoading] = useState(false)
  const [useUnitSpace, setUseUnitSpace] = useState(
    row?.unit_space?.length ? true : false
  )
  const [selectedUnitSpace, setSelectedUnitSpace] = useState(
    row?.unit_space?.length ? row?.unit_space : null
  )
  const [spaceName, setSpaceName] = useState(row?.space_name || '')
  const [error, setError] = useState('')

  const getValueReadyToCompare = (value = '') => {
    return value?.trim()?.toLowerCase()
  }

  const filteredSelectedUnitSpace = useMemo(() => {
    return (selectedUnitSpace || []).filter(unit => unit.is_deleted !== true)
  }, [selectedUnitSpace])

  const filteredDeletedUnitSpace = useMemo(() => {
    return (selectedUnitSpace || []).filter(unit => unit.is_deleted === true)
  }, [selectedUnitSpace])

  const {
    user: { pdbid },
    context
  } = useAppContext()

  const onSuccess = () => {
    setLoading(false)
    setError('')
    dismiss()
    onRefreshElements(true)
  }

  const onError = () => {
    setLoading(false)
    setError('Something went wrong. Please try again later !!!')
  }

  const submitHandler = async () => {
    setLoading(true)
    setError('')
    const checkIfSpaceExists = (allSpaceData || []).filter(
      space =>
        getValueReadyToCompare(space?.space_name) ===
        getValueReadyToCompare(spaceName)
    )
    if ((checkIfSpaceExists || []).length) {
      setLoading(false)
      setError('This Space name already exists')
      return
    }

    if (useUnitSpace && (selectedUnitSpace || []).length === 0) {
      setLoading(false)
      setError(
        'Ops! It seems like you have not selected any unit space. Please select at least one to proceed.'
      )
      return
    }

    let data = []
    if (!useUnitSpace) {
      if ((selectedUnitSpace || []).length) {
        ;(selectedUnitSpace || []).map(item => {
          const { unit_space } = item || {}
          data.push({
            space_id: row?.space_id || null,
            space_name: null,
            status: row?.status || null,
            unit_spaces: unit_space
          })
          return data
        })
      } else {
        data.push({
          space_id: row?.space_id || null,
          space_name: spaceName,
          status: row?.status || null,
          unit_spaces: null
        })
      }
    } else {
      ;(selectedUnitSpace || []).map(item => {
        const { unit_space } = item || {}
        if (item.hasOwnProperty('is_deleted')) {
          data.push({
            space_id: row?.space_id || null,
            space_name: null,
            status: row?.status || null,
            unit_spaces: unit_space
          })
        }
        if (item.hasOwnProperty('is_newley_added')) {
          data.push({
            space_id: null,
            space_name: spaceName,
            status: row?.status || null,
            unit_spaces: unit_space
          })
        }
        return data
      })
    }

    if (
      getValueReadyToCompare(row?.space_name || '') !==
      getValueReadyToCompare(spaceName)
    ) {
      data.unshift({
        space_id: row?.space_id || null,
        space_name: spaceName,
        status: row?.status || null,
        unit_spaces: (selectedUnitSpace || []).length
          ? selectedUnitSpace[0]?.unit_space
          : null
      })
    }

    const body = {
      items: data || []
    }

    await createElementSpace({
      pdbid,
      context,
      body,
      onSuccess,
      onError
    })
  }

  const getSubmitButtonText = () => {
    if (isEdit) {
      if (loading) {
        return 'Saving'
      }
      return 'Save'
    } else {
      if (loading) {
        return 'Creating Space'
      }
      return 'Create Space'
    }
  }

  return (
    <div className="propertyFormModal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            {isEdit ? 'Edit Space' : 'Create Space'}
          </p>
          <p className="close is-pointer has-text-grey-light" onClick={dismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="form-group columns">
        <div className="label column is-one-third">
          <label>Name*</label>
        </div>
        <div className="input-field column is-two-thirds">
          <input
            id="spaceName"
            name="spaceName"
            type="text"
            placeholder=""
            value={spaceName}
            onChange={e => {
              setSpaceName(e?.target?.value)
              setError('')
            }}
            className="form-control"></input>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <label className={`checkbox ${!useUnitSpace || 'is-active'}`}>
            <span className={`checkbox-input ${!useUnitSpace || 'is-active'}`}>
              <span className="checkbox-inner" />
              <input
                type="checkbox"
                className="checkbox-original"
                checked={useUnitSpace}
                onChange={() => setUseUnitSpace(prevValue => !prevValue)}
              />
            </span>
            <span className={`checkbox-label ${!useUnitSpace || 'is-active'}`}>
              Link to Unit Space
            </span>
          </label>
        </div>
      </div>

      <div className="form-group columns">
        <div className="label column is-one-third">
          <label className="m-l-lg">Select Unit Spaces*</label>
        </div>
        <div className="input-field column is-two-thirds">
          <MultiSelectDropdown
            isDisabled={!useUnitSpace}
            displayKey="unit_space"
            value={filteredSelectedUnitSpace}
            defaultValues={unitSpaceList}
            deleteOption={true}
            onChange={(newValue, action_type) => {
              const data = []
              if (action_type === 'clear') {
                setSelectedUnitSpace(newValue)
              } else {
                setSelectedUnitSpace(prevState => {
                  ;(prevState || []).map(prev => {
                    const { unit_space } = prev || {}
                    ;(newValue || []).map(new_data => {
                      const { unit_space: new_unit_space } = new_data || {}
                      if (new_unit_space !== unit_space) {
                        data.push(prev)
                      }
                    })
                    return data
                  })
                  if ((newValue || []).length) {
                    ;(newValue || []).map(new_data => {
                      data.push(new_data)
                    })
                  }
                  return data
                })
              }
              console.log(data)
            }}
            deletedItem={filteredDeletedUnitSpace}
            isMulti={true}
            isClearable
            placeholder="Search"
            noOptionsMessage="No Data Found"
            menuPortalTarget={document.body}
            customStyles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (styles, state) => ({
                ...styles,
                border: '1px solid #8d8f94',
                '&:focus': {
                  border: '1px solid #3db3e2'
                }
              }),
              valueContainer: provided => ({
                ...provided,
                minHeight: '45px',
                maxHeight: '100px',
                overflow: 'auto',
                position: 'relative'
              })
            }}
          />
        </div>
      </div>

      {error !== '' && (
        <div className="notification is-danger is-light is-flex">
          <p>{error}</p>
          <button onClick={() => setError('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={dismiss}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={submitHandler}
            disabled={
              (spaceName || '').trim() === '' ||
              (useUnitSpace &&
                isEmpty(
                  (selectedUnitSpace || []).filter(d => d.is_deleted !== true)
                ))
            }>
            {getSubmitButtonText()}
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

NewPropertyFormModal.propTypes = {
  dismiss: PropTypes.func,
  history: PropTypes.object,
  sharedTemplates: PropTypes.array
}

export default NewPropertyFormModal
