import React, { useEffect, useState, useMemo } from 'react'
import { close } from 'ui/icons'
import BasicLoading from '../../../components/basic-loading'
import * as moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { closeFilled } from 'ui/icons'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import dateFnsFormat from 'date-fns/format'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../../utils/calendarInputHelper'

import './schedule.scss'

const schedule = props => {
  const {
    getScheduleByService,
    services,
    user,
    context,
    turnboard,
    onHandleScheduleBox,
    onHandleSelectedScheduleDate,
    selectedRows,
    selectedCapacity,
    updateServiceSchedule,
    onHandleScheduleOrderOfOperation,
    scheduleFollowOrderOperation,
    reCallTurnboardData,
    onHandleShowUnscheduledUnit,
    showUnscheduledUnit,
    onHandleShowScheduledUnit,
    showScheduledUnit,
    onFilterScheduledUnit,
    allRows
  } = props || {}
  const [selectedService, setSelectedService] = useState('')
  const [isServiceLoading, setIsServiceLoading] = useState('')
  const [selectedTeam, setSelectedTeam] = useState([])
  const [selectedTeamId, setSelectedTeamId] = useState('')
  const [selectedTeamType, setSelectedTeamType] = useState('')
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedDateId, setSelectedDateId] = useState('')
  const [isScheduleSaving, setIsScheduleSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [scheduleTeams1, setScheduleTeams1] = useState([])

  const scheduleTeams = useMemo(() => {
    return (
      turnboard?.scheduleData?.schedule?.filter(team => {
        const type = team.team_type?.toLowerCase()
        return type === 'vendor' || type === 'internal staff'
      }) || []
    )
  }, [turnboard])

  const selectedServiceName = useMemo(() => {
    const foundService = (services || []).find(
      service => service?.servicetype_id === selectedService
    )

    return foundService?.servicetype || ''
  }, [selectedService, services])

  const getScheduleData = async () => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    await getScheduleByService({
      pdbid,
      context,
      id: selectedService,
      onSuccess: data => {
        const filterData =
          data.filter(team => {
            const type = team.team_type?.toLowerCase()
            return type === 'vendor' || type === 'internal staff'
          }) || []
        setScheduleTeams1(filterData)
        setIsServiceLoading(false)
      },
      onError: () => {
        setIsServiceLoading(false)
      }
    })
  }
  useEffect(() => {
    if (
      (selectedService || '').length !== 0 &&
      selectedService !== 'Select' &&
      !showScheduledUnit
    ) {
      setIsServiceLoading(true)
      getScheduleData()
    }
  }, [selectedService, showScheduledUnit])

  useEffect(() => {
    if (selectedService && selectedService !== 'Select' && showScheduledUnit) {
      const currentService = (services || []).find(
        service => service.servicetype_id === selectedService
      )
      onFilterScheduledUnit(currentService?.servicetype)
    }
  }, [showScheduledUnit, selectedService, services])

  const onHandleScheduleService = () => {
    setErrorMessage('')
    setIsScheduleSaving(true)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const getCurrentService = (services || []).filter(
      d => d.servicetype_id === selectedService
    )
    const formatDate = moment(selectedDate?.date).format('YYYY-MM-DD') || ''
    const teamId = selectedTeamId || ''
    if ((getCurrentService || []).length !== 0) {
      const serviceName = (getCurrentService[0]?.servicetype || '')
        .toString()
        .replace(' ', '_')
        .toLowerCase()
      let unitData = []
      let data = []
      const rows = (selectedRows || []).map(d => {
        const { original } = d || {}
        const { unit, [`${serviceName}_workorder_id`]: workorder_id } =
          original || {}
        if (!unitData.includes(unit)) {
          unitData.push(unit)
          data.push({
            workorder_id: workorder_id,
            scheduled_date: showScheduledUnit ? null : formatDate,
            team_id: showScheduledUnit ? null : teamId,
            schedule_locked: showScheduledUnit ? 'F' : 'T',
            unscheduled: showScheduledUnit ? 'true' : null
          })
        }
        return data
      })
      const body = {
        items: data || []
      }
      let service_name = getCurrentService[0]?.servicetype || ''
      if (service_name === 'Concrete/Vinyl Replace') {
        service_name = (service_name || '').toString().replace('/', '%20')
      }

      updateServiceSchedule({
        pdbid,
        context,
        body,
        serviceType: service_name,
        onSuccess: () => {
          setErrorMessage('')
          setSuccessMessage('Schedule has been updated!')
          setIsScheduleSaving(false)
          getScheduleData()
          setSelectedService(selectedService)
          setSelectedTeam([])
          setSelectedTeamId('')
          setSelectedTeamType('')
          setSelectedDateId('')
          setSelectedDate([])
          onHandleSelectedScheduleDate([])
          reCallTurnboardData()
        },
        onError: () => {
          setErrorMessage(
            'Sorry! We were not able to proceed with scheduling. Please try again!'
          )
          setIsScheduleSaving(false)
        }
      })
    }
  }

  const showScheduleButton = useMemo(() => {
    return (
      (selectedRows || []).length !== 0 &&
      selectedService !== '' &&
      selectedDateId !== '' &&
      selectedTeamId !== '' &&
      !showScheduledUnit
    )
  }, [selectedRows, selectedService, selectedDateId, selectedTeamId])

  const showUnScheduleButton = useMemo(() => {
    return (
      (selectedRows || []).length !== 0 &&
      selectedService !== '' &&
      showScheduledUnit
    )
  }, [showScheduledUnit, selectedService, selectedRows])

  const handleDayClick = date => {
    const schedule = {
      date: moment(date).format('YYYY-MM-DD'),
      servicetype_name: selectedServiceName
    }

    const formatDate = moment(date).format('ddd, MM/DD/YYYY')

    setSelectedDateId(formatDate)
    setSelectedDate(schedule)
    onHandleSelectedScheduleDate([schedule])
    setSuccessMessage('')
    setErrorMessage('')
  }

  const styles = selectedTeamType !== 'Vendor' ? { position: 'absolute' } : {}

  return (
    <div className="turnboard-schedule-container">
      <h3>Scheduling</h3>
      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex error-section">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      {successMessage !== '' && (
        <div className="notification is-success is-flex error-section">
          <p>{successMessage}</p>
          <button onClick={() => setSuccessMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <button
        type="button"
        className="close-btn"
        onClick={() => {
          onHandleScheduleBox()
          setSelectedService('')
          setSelectedTeam([])
          setSelectedTeamId('')
          setSelectedTeamType('')
          setSelectedDateId('')
          setSelectedDate([])
          onHandleSelectedScheduleDate([])
        }}>
        <img src={close} alt="Close" />
      </button>
      <div className="is-flex turnboard-schedule-scroll">
        <div className="choose-service-div">
          <h4>Choose a Service:</h4>
          <div className="select input-field">
            <select
              className="input"
              onChange={e => {
                setSelectedService(e.target.value)
                setSuccessMessage('')
                setErrorMessage('')
                setSelectedTeam([])
                setSelectedTeamId('')
                setSelectedTeamType('')
                setSelectedDateId('')
                setSelectedDate([])
                onHandleSelectedScheduleDate([])
              }}>
              <option value="">Select</option>
              {(services || []).map((d, i) => (
                <option key={i} value={d.servicetype_id}>
                  {d.servicetype}
                </option>
              ))}
            </select>
          </div>
          <label
            className={`checkbox ${scheduleFollowOrderOperation &&
              'is-active'}`}>
            <span
              className={`checkbox-input ${scheduleFollowOrderOperation &&
                'is-active'}`}>
              <span className="checkbox-inner" />
              <input
                type="checkbox"
                className="checkbox-original"
                checked={scheduleFollowOrderOperation}
                name="all_units"
                onClick={e => {
                  e.preventDefault()
                  onHandleScheduleOrderOfOperation(
                    !scheduleFollowOrderOperation
                  )
                }}
              />
            </span>
            <span
              className={`checkbox-label ${showUnscheduledUnit &&
                'is-active'}`}>
              Follow order of operations
            </span>
          </label>
          <label
            className={`checkbox un-schedule-units ${showUnscheduledUnit &&
              'is-active'}`}>
            <span
              className={`checkbox-input ${showUnscheduledUnit &&
                'is-active'}`}>
              <span className="checkbox-inner" />
              <input
                type="checkbox"
                className="checkbox-original"
                checked={showUnscheduledUnit}
                name="all_units"
                onClick={e => {
                  e.preventDefault()
                  setSelectedDateId('')
                  setSelectedDate([])
                  onHandleSelectedScheduleDate([])
                  onHandleShowUnscheduledUnit(!showUnscheduledUnit)
                }}
              />
            </span>
            <span
              className={`checkbox-label ${showUnscheduledUnit &&
                'is-active'}`}>
              Show Unscheduled Units Only
            </span>
          </label>
          <label
            className={`checkbox un-schedule-units ${showScheduledUnit &&
              'is-active'}`}>
            <span
              className={`checkbox-input ${showScheduledUnit && 'is-active'}`}>
              <span className="checkbox-inner" />
              <input
                type="checkbox"
                className="checkbox-original"
                checked={showScheduledUnit}
                name="all_units"
                onClick={e => {
                  e.preventDefault()
                  setSelectedDateId('')
                  setSelectedDate([])
                  onHandleSelectedScheduleDate([])
                  onHandleShowScheduledUnit(!showScheduledUnit)
                }}
              />
            </span>
            <span
              className={`checkbox-label ${showScheduledUnit && 'is-active'}`}>
              Show Scheduled Units Only
            </span>
          </label>
        </div>

        {!showScheduledUnit && (
          <>
            {/* Pick time section */}
            <div className="pick-time-div">
              <h4>Pick a Team:</h4>
              {isServiceLoading ? (
                <BasicLoading />
              ) : (
                selectedService !== '' &&
                selectedService !== 'Select' && (
                  <div className="pick-time-row is-flex is-align-items-center is-flex-wrap">
                    {scheduleTeams1.length === 0
                      ? 'No Teams Available'
                      : scheduleTeams1.map((d, i) => {
                          const { team_name, team_type, team_id, capacities } =
                            d || {}
                          return (
                            <div
                              onClick={e => {
                                e.preventDefault()
                                setSelectedTeam(capacities)
                                setSelectedTeamId(team_id)
                                setSelectedTeamType(team_type)
                                onHandleSelectedScheduleDate([])
                                setSelectedDateId('')
                                setSuccessMessage('')
                                setErrorMessage('')
                                setSelectedDate([])
                              }}
                              className="solution-button"
                              key={team_id}>
                              <input
                                type="radio"
                                id={team_id}
                                name={team_id}
                                value={team_id}
                              />
                              <label
                                className={`button solution-button-checked ${
                                  selectedTeamId === team_id
                                    ? 'active-check'
                                    : ''
                                }`}
                                htmlFor="#button-radio">
                                {team_name}
                              </label>
                            </div>
                          )
                        })}
                  </div>
                )
              )}
            </div>
            {/* Select Date section */}
            <div className="select-date-div">
              <h4 style={styles}>Select the Date(s):</h4>
              <div className="pick-time-row is-flex is-align-items-center is-flex-wrap">
                {selectedTeamType === 'Vendor' && (
                  <>
                    {(selectedTeam || []).length === 0
                      ? 'No Dates are Available'
                      : (selectedTeam || []).map((d, i) => {
                          const {
                            date,
                            capacity,
                            total_capacity,
                            team_capacity_id
                          } = d || {}
                          const formatDate = moment(date).format(
                            'ddd, MM/DD/YYYY'
                          )
                          const disabled =
                            moment(formatDate).isBefore(
                              moment(new Date()).format('ddd, MM/DD/YYYY')
                            ) || capacity === 0

                          return (
                            <div
                              onClick={e => {
                                e.preventDefault()

                                if (!disabled) {
                                  setSelectedDate(d)
                                  setSelectedDateId(formatDate)
                                  onHandleSelectedScheduleDate([d])
                                  setSuccessMessage('')
                                  setErrorMessage('')
                                }
                              }}
                              className={`solution-button ${disabled &&
                                'isDisabled'}`}
                              key={team_capacity_id}>
                              <input
                                type="radio"
                                id={date}
                                name={date}
                                value={date}
                              />
                              <label
                                className={`
                    button solution-button-checked date-label ${
                      moment(selectedDateId).isSame(formatDate)
                        ? 'active-check'
                        : ''
                    } ${disabled && 'isDisabled'}`}>
                                <p>{formatDate}</p>
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {total_capacity}
                                  </span>{' '}
                                  Max -
                                  <span style={{ fontWeight: 'bold' }}>
                                    {' '}
                                    {moment(selectedDateId).isSame(formatDate)
                                      ? selectedCapacity
                                      : capacity}
                                  </span>{' '}
                                  Available
                                </div>
                              </label>
                            </div>
                          )
                        })}
                  </>
                )}
                {selectedTeamType === 'Internal Staff' && (
                  <>
                    <DayPickerInput
                      inputProps={{ readOnly: true }}
                      onDayChange={handleDayClick}
                      style={{ position: 'absolute', top: '70px' }}
                      dayPickerProps={{
                        modifiers: calendarConfig.modifiers,
                        firstDayOfWeek: calendarConfig.firstDayOfWeek,
                        weekdaysShort: calendarConfig.weekdaysShort,
                        disabledDays: day => {
                          const today = new Date()
                          return moment(day).isBefore(today)
                        }
                      }}
                      value={selectedDate.date}
                      formatDate={formatDate}
                      format={calendarConfig.FORMAT}
                      parseDate={parseDate}
                      placeholder={`${dateFnsFormat(
                        new Date(),
                        calendarConfig.FORMAT
                      )}`}
                    />

                    <label style={{ position: 'absolute', top: '107px' }}>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          {allRows?.length}{' '}
                        </span>
                        total -
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          {selectedRows?.length}{' '}
                        </span>
                        Selected
                      </div>
                    </label>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {/* Schedule submit section */}
        <div className="Schedule-submit-div is-flex is-align-items-flex-end">
          {showScheduleButton && (
            <button
              type="button"
              onClick={() => {
                onHandleScheduleService()
              }}
              className="button is-outlined is-primary">
              {`Schedule (${(selectedRows || []).length})`}
              {isScheduleSaving && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  color="#ffffff"
                  className="m-l-sm"
                />
              )}
            </button>
          )}

          {showUnScheduleButton && (
            <button
              type="button"
              onClick={() => {
                onHandleScheduleService()
              }}
              className="button is-outlined is-primary">
              {`Unschedule (${(selectedRows || []).length})`}
              {isScheduleSaving && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  color="#ffffff"
                  className="m-l-sm"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default schedule
