import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ContextSelector from '../context-selector'
import { withRouter } from 'react-router-dom'
import RequestForProposalMain from '../requests-for-proposal-main'
import Service from '../../service'
import { updateContext, cancelContextChange } from '../../modules/context'
import { updateUser } from '../../modules/user'
import { openModal, closeModal, setModalContent } from '../../modules/modal'
import {
  vendorDetailsByEmailGet
} from '../../modules/requests-for-proposal'
import BigLoading from '../../components/big-loading'


const VendorDashboardMain = (props) => {
  const {
    user,
    context,
    updateContext,
    openModal,
    closeModal,
    setModalContent,
    cancelContextChange,
    contextCookie,
    history,
    location,
    updateUser,
  } = props || {}
  const [isLoading, setLoading] = useState(false)
  const { user_metadata } = user || {}
  const { pdbids, pdbid } = user_metadata || {}
  const tenantCookieValue = Service.getTntCookie()
  // useEffect(() => {
  //   if(!pdbid && (pdbids || []).length === 1) {
  //     setLoading(true)
  //     updateUser({
  //       pdbid: pdbids[0],
  //       onSuccess: () => {
  //         setLoading(false)
  //       }
  //     })
  //   }
  // }, [ user ])
  useEffect(() => {
    if(tenantCookieValue && !pdbid) {
      setLoading(true)
      updateUser({
        pdbid:tenantCookieValue,
        onSuccess: () => {
          setLoading(false)
        }
      })
    }
  }, [user])

  return (
      <>
        {
          (pdbids || []).length > 1 && !tenantCookieValue && !pdbid
            ?
            <ContextSelector
              user={user}
              context={context}
              updateContext={updateContext}
              openModal={openModal}
              closeModal={closeModal}
              setModalContent={setModalContent}
              cancelContextChange={cancelContextChange}
              contextCookie={contextCookie}
              onlyProperties={false}
              history={history}
              showTenantOnly = { true }
              location={location}
              key="context-selector"
              updateUser={updateUser}
            />
            :
            <>
              {
                isLoading
                  ?
                  <BigLoading/>
                  :
                  <RequestForProposalMain { ...props } tenantCookieValue={tenantCookieValue} />

              }
            </>
        }
      </>
  )



  
}

VendorDashboardMain.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  skipContext: PropTypes.bool,
  updateContext: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  cancelContextChange: PropTypes.func,
  requestsForProposal: PropTypes.object,
  vendorDetailsByEmailGet: PropTypes.func
}

const mapStateToProps = ({ auth, user, context, requestsForProposal }) => ({
  auth,
  user,
  context,
  requestsForProposal
})


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateContext,
      openModal,
      closeModal,
      setModalContent,
      cancelContextChange,
      updateUser,
      vendorDetailsByEmailGet
    },
    dispatch
  )


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VendorDashboardMain)
)
