import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import NotesModal from '../../parents/notes-modal'
import {
  unitNotesReset,
  unitNotesGet,
  unitNotesCreate,
  unitNotesUpdate,
  unitNotesDelete,
  getUnitServiceNotes
} from '../../modules/notes'
import { setAlert } from '../../modules/alerts'

const Notes = ({
  unit,
  unitId,
  service,
  serviceTypeId,
  context,
  auth,
  user,
  notes,
  unitNotesReset,
  unitNotesGet,
  unitNotesCreate,
  unitNotesUpdate,
  unitNotesDelete,
  onClose,
  onSuccess,
  setAlert,
  unitServiceNotes = false,
  getUnitServiceNotes
}) => {
  return (
    <div>
      <NotesModal
        user={user}
        auth={auth}
        context={context}
        onClose={onClose}
        onSuccess={onSuccess || onClose}
        unit={unit}
        unitId={unitId}
        service={service}
        serviceTypeId={serviceTypeId}
        notes={notes}
        unitNotesReset={unitNotesReset}
        unitNotesGet={unitNotesGet}
        unitNotesCreate={unitNotesCreate}
        unitNotesUpdate={unitNotesUpdate}
        unitNotesDelete={unitNotesDelete}
        setAlert={setAlert}
        getUnitServiceNotes={getUnitServiceNotes}
        unitServiceNotes={unitServiceNotes}
      />
    </div>
  )
}

Notes.propTypes = {
  unit: PropTypes.string,
  unitId: PropTypes.string,
  service: PropTypes.string,
  context: PropTypes.object,
  auth: PropTypes.object,
  user: PropTypes.object,
  notes: PropTypes.object,
  unitNotesReset: PropTypes.func,
  unitNotesGet: PropTypes.func,
  unitNotesCreate: PropTypes.func,
  unitNotesUpdate: PropTypes.func,
  unitNotesDelete: PropTypes.func,
  setAlert: PropTypes.func
}

const mapStateToProps = ({ context, auth, user, notes }) => ({
  context,
  auth,
  user,
  notes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unitNotesReset,
      unitNotesGet,
      unitNotesCreate,
      unitNotesUpdate,
      unitNotesDelete,
      getUnitServiceNotes,
      setAlert
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notes)
)
