import React from 'react'
import PropTypes from 'prop-types'
import { hamburger, refresh } from '../../ui/icons'
import './index.scss'

function VendorHeader({ title, toggleSidebar, refreshAction }) {
  
  const refreshHandler = () => refreshAction && refreshAction()

  return (
    <div className="vendor-header navbar is-primary">
      <div className="vendor-header-back" onClick={toggleSidebar}>
        <img src={hamburger} alt="menu" />
      </div>

      <div className="vendor-header-title">
        {title}
      </div>

      <div className={`vendor-header-actions ${!refreshAction ? 'vendor-header-actions-hidden' : ''}`} onClick={refreshHandler}>
        <img src={refresh} alt="refresh" />
      </div>
    </div>
  )
}

VendorHeader.propTypes = {
  title: PropTypes.string,
  toggleSidebar: PropTypes.func,
  refreshAction: PropTypes.func,
}

export default VendorHeader
