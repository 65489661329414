import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { closeModal, openModal, setModalContent } from 'modules/modal'
import sectionPermissions from '../security/section-permissions.json'

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useContextChanged = context => {
  const prevContext = usePrevious(context)

  const contextChanged = useMemo(() => {
    return (
      prevContext &&
      context &&
      (context.property !== prevContext.property ||
        context.project !== prevContext.project)
    )
  }, [context, prevContext])

  return { contextChanged }
}

export const useNewInvoice = effect => {
  const invoice = useSelector(state => state.invoices?.details)

  const newInvoice = useMemo(() => {
    if (invoice?.status === 'Draft') {
      return invoice
    }
  }, [invoice])

  useEffect(() => {
    if (newInvoice) {
      effect(newInvoice)
    }
  }, [newInvoice])
}

export const useAppContext = () => {
  const user = useSelector(state => state.user?.user_metadata)
  const context = useSelector(state => state.context)

  return { user, context }
}

export const useAppUser = () => {
  const user = useSelector(state => state.user)
  return { user }
}

export const useModals = () => {
  const dispatch = useDispatch()

  const showModal = (modalConfig, component) => {
    dispatch(setModalContent(() => component))
    dispatch(openModal(modalConfig))
  }

  const dismissModal = () => dispatch(closeModal())

  return [showModal, dismissModal]
}

export const usePermissionGate = name => {
  const user = useSelector(state => state.user)
  const { roles } = user || {}

  const hasPermission = useMemo(() => {
    const sectionPermission = (sectionPermissions || []).find(
      section => section.name === name
    )

    if (!_.isEmpty(sectionPermission)) {
      const { accessRoles } = sectionPermission
      return (roles || []).some(role => accessRoles.indexOf(role) >= 0)
    }

    return false
  }, [name, roles])

  return { hasPermission }
}
