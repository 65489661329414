import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle
} from 'react'
import PropTypes from 'prop-types'
import { headerData } from './header'
import { icons } from './icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { updateChargeStatus } from '../../modules/charge'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy
} from 'react-table'
import ToggleDoNotInvoice from './components/ToggleDoNotInvoice'

import './index.scss'

// Table styles
const Styles = styled.div`
  max-width: 100%;
  position: relative;
  input {
    border: 1px solid transparent;
    margin: 0px auto;
  }
  .po-table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #e5e9f2;
    position: relative;

    tr {
      position: relative;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      padding: 0px;
    }

    th,
    td {
      margin: 0;
      position: relative;
    }
  }
`

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={'Search'}
    />
  )
}

const Table = memo(
  forwardRef(({ columns, data }, ref) => {
    // Use the state and functions returned from useTable to build your UI

    const defaultColumn = React.useMemo(
      () => ({
        width: 100,
        Filter: DefaultColumnFilter
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        defaultColumn
      },
      useBlockLayout,
      useFilters,
      useSortBy,
      useRowSelect
    )

    useImperativeHandle(ref, () => ({
      getRows: () => {
        return rows?.map(row => row.original)
      }
    }))

    // Render the UI for your table
    return (
      <table {...getTableProps()} className="po-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          textAlign: cell.column.textAlign,
                          width: cell.column.width
                        }
                      })}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  })
)

const ChargesBulkStatusUpdate = props => {
  const {
    onClose,
    user,
    context,
    selectedChargeRows,
    updateChargeStatus,
    setUpdateChargesList,
    status
  } = props || {}

  // State
  const [errorMessage, setErrorMessage] = useState('')
  const [chargesStatusData, setChargesStatusData] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [activeDoNotInvoice, setActiveDoNotInvoice] = useState(
    status?.added === 'true' ? true : false
  )
  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  useEffect(() => {
    if ((selectedChargeRows || []).length) {
      setChargesStatusData(selectedChargeRows)
    }
  }, [selectedChargeRows])

  const tableRef = useRef()

  // Icons
  const { close, closeFilled } = icons || {}

  const tableColumns = headerData()

  const columns = useMemo(() => tableColumns)

  const updateStatus = data => {
    if ((data || []).length) {
      let updatedData = []
      let noteId = null
      let taskId = null
      ;(data || []).map(d => {
        const { task_id } = d || {}
        const getChargeType = (task_id || '').toString().toLowerCase()
        if ((getChargeType || '').includes('note')) {
          noteId = task_id
        }
        if ((getChargeType || '').includes('task')) {
          taskId = task_id
        }
        updatedData.push({
          task_id: taskId,
          note_id: noteId,
          do_not_invoice: activeDoNotInvoice === false ? null : 'true'
        })
      })
      const body = {
        items: updatedData || []
      }
      setSubmitting(true)
      updateChargeStatus({
        pdbid,
        context,
        body,
        onSuccess: () => {
          setErrorMessage('')
          setSubmitting(false)
          setUpdateChargesList(true)
          onClose()
        },
        onError: () => {
          setSubmitting(false)
          setErrorMessage('Something went wrong, Please try again later !!!')
        }
      })
    }
  }

  const onSave = () => {
    updateStatus(chargesStatusData)
  }

  return (
    <div id="charge-status-container">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <h2 className="is-pulled-left modal-sync-title">
            Update Charge Status
          </h2>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div>
        <Styles>
          <Table ref={tableRef} columns={columns} data={chargesStatusData} />
        </Styles>
      </div>
      <div className="cs-modal">
        <form>
          <div className="form-group columns">
            <div className="label column is-two-third">
              <label>Do Not Invoice:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <ToggleDoNotInvoice
                isActive={activeDoNotInvoice}
                onChange={state => setActiveDoNotInvoice(state)}
              />
            </div>
          </div>
        </form>
      </div>
      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              onClose()
            }}>
            Cancel
          </button>
          <button
            className="button main-button is-primary"
            onClick={event => {
              event.preventDefault()
              onSave()
            }}>
            {isSubmitting ? 'Updating' : 'Update'}
            {isSubmitting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

ChargesBulkStatusUpdate.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ user, context, charge }) => ({
  user,
  context,
  charge
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateChargeStatus
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChargesBulkStatusUpdate)
