import _ from 'lodash'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import moment from 'moment'
import DataTable from '../../components/data-table'
import { icons } from '../../components/data-table/icons'
import { sectionManageable } from '../../security'
import { getHeaderData } from './header'
import RestrictedElement from '../../containers/restricted-element'
import AddRFPModal from '../add-rfp-modal'
import PermissionGate from '../../components/permission-gate'
import { checkPermission } from '../../security'
import { formatDateString } from '../../utils/index'
import './index.scss'

const RequestRFPList = props => {
  // Destructure Props
  const {
    user,
    allRequestRFPGet,
    setModalContent,
    context,
    requestRFPS,
    openModal,
    closeModal
  } = props
  // use Effect
  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    allRequestRFPGet(pdbid, context)
  }, [])

  const prevContext = usePrevious(context)

  const dropDownOptions = [
    {
      available: () => sectionManageable('/request-vendors', user),
      icon: icons.rescind,
      caption: 'Rescind Request',
      action: row => handleRescindRequest(row)
    }
  ]

  const showUserDropdownOptions = () => {
    if (checkPermission('manage-rfp-request', user)) {
      return dropDownOptions
    }

    return []
  }

  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged) {
      allRequestRFPGet(pdbid, context)
    }
  }, [context])

  const getReadAbleResponseRecieved = (status, submitted_at) => {
    if (status !== null && status !== '') {
      const statusLower = (status || '').toLowerCase()
      if (
        statusLower === 'closed' ||
        statusLower === 'in-review' ||
        statusLower === 'approved' ||
        statusLower === 'rejected' ||
        statusLower === 'rescinded'
      ) {
        return `${
          submitted_at
            ? formatDateString(submitted_at, 'MM/DD/YYYY')
            : 'Pending'
        }`
      }
    }
    return 'Pending'
  }

  const getReadAbleApproval = status => {
    if (status !== null && (status || '').toLowerCase() === 'approved') {
      return 'Approved'
    }
    return status
  }

  // Add RFP Modal
  const onAddRFPHandler = useCallback(() => {
    setModalContent(() => (
      <AddRFPModal
        {...props}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '94%',
      additionalClasses: 'rfp-modal'
    })
  }, [setModalContent, openModal, user])
  // State
  const [checked, setChecked] = useState([])
  const [textFilter, setTextFilter] = useState('')

  const tableRef = useRef()
  // Methods
  const checkboxClicked = checked => setChecked(checked)
  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = properties.find(p => p.id === contextProperty)
    const project = projects.find(p => p.id === contextProject)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const filterData = (tableRef?.current?.getRows() || []).map(list => {
      const {
        simplifiedRFPFormName,
        vendor_name,
        simplifiedServices,
        due_date,
        status,
        submitted_at,
        responseRecievedStatus
      } = list || {}
      return {
        RFP: simplifiedRFPFormName || '',
        'Vendor Requested': vendor_name || '',
        'Services(s)': `"${simplifiedServices}"` || '',
        'Due Date': due_date ? formatDateString(due_date, 'MM/DD/YYYY') : 'N/A',
        'Response Received':
          getReadAbleResponseRecieved(responseRecievedStatus, submitted_at) ||
          '',
        Approval: getReadAbleApproval(status) || ''
      }
    })

    const data = filterData
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(filterData.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-vendors-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const getFilteredRFP = () => {
    const { allRFP } = requestRFPS || {}
    const { data } = allRFP || []

    const sortedData = _.orderBy(data, ['created_at'], 'desc')

    return (sortedData || []).filter(d => {
      const { vendor_name, form_name } = d || {}
      const constructValue = `${form_name || ''} - ${vendor_name || ''}`
      return (
        (constructValue || '')
          .toUpperCase()
          .indexOf(textFilter.toUpperCase()) !== -1
      )
    })
  }

  const handleRescindRequest = row => {
    const { user, context, declineRFPRequest } = props || {}
    const { user_metadata } = user || {}
    const { pdbid, properties } = user_metadata || {}
    const { property } = context || {}
    const { vendor_email, rfp_request_id: requestId } = row || {}

    const selectedProperty = (properties || []).find(p => p.id === property)
    const { name: property_name } = selectedProperty || {}

    declineRFPRequest({
      body: {
        status: 'rescinded',
        vendor_email,
        property_name
      },
      context,
      pdbid,
      requestId,
      onSuccess: () => {
        console.log('success handler')
      },
      onError: error => {
        console.log('error handler', {
          error
        })
      }
    })
  }
  const filteredRFP = getFilteredRFP()

  return (
    <DataTable
      title="RFP Requests"
      header={getHeaderData()}
      data={filteredRFP}
      onExport={exportCSV}
      ref={tableRef}
      hasCheckboxes={false}
      additionalClasses={'request-rfp-table'}
      customResultsPerPage={5}
      singleSelectionColumn={'rfp_request_id'}
      textFilter={textFilter}
      onSetTextFilter={setTextFilter}
      onSortChanged={() => {}}
      checkboxClicked={row => checkboxClicked(row)}
      buttonsSection={
        <PermissionGate name={'add-rfp-request'}>
          <div className="columns is-vcentered">
            <div className="column is-narrow">
              <RestrictedElement>
                <button
                  className="button add-vendor-btn is-success"
                  type="button"
                  onClick={() => onAddRFPHandler()}>
                  New Request
                </button>
              </RestrictedElement>
            </div>
          </div>
        </PermissionGate>
      }
      rowKey={'rfp_request_id'}
      rowOptions={showUserDropdownOptions()}
    />
  )
}

RequestRFPList.propTypes = {
  user: PropTypes.object,
  allRequestRFPGet: PropTypes.func,
  setModalContent: PropTypes.func,
  context: PropTypes.object,
  requestRFPS: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  declineRFPRequest: PropTypes.func
}

export default RequestRFPList
