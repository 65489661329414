import React from 'react'
import { Link } from 'react-router-dom'

import '../../styles/common.scss'
import './index.scss'

const ManageOptions = () => {
  return (
    <div className="toolbar-container">
      <div className="columns">
        <div className="column">
          <ul>
            <li className="has-text-weight-semibold is-size-6">Your Company</li>
            {/* <li>Account and settings</li> */}
            <li>
              <Link
                onClick={event => {
                  event.target.blur()
                }}
                to="/setup/users">
                Manage Users
              </Link>
            </li>
          </ul>
        </div>
        {/* <div className="column is-one-quarter">
          <ul>
            <li className="has-text-weight-semibold is-size-6">Lists</li>
            <li>All Lists</li>
            <li>Projects</li>
            <li>Properties</li>
            <li>Services</li>
          </ul>
        </div>
        <div className="column is-two-quarter">
          <ul>
            <li className="has-text-weight-semibold is-size-6">Tools</li>
            <li>Import data</li>
            <li>Audit log</li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}

export default ManageOptions
