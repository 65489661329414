import Service from '../../service'
import {
  PROPERTY_FORMS_LIST_REQUESTED,
  PROPERTY_FORMS_LIST_SUCCESS,
  PROPERTY_FORMS_LIST_FAIL,
  PROPERTY_FORMS_ELEMENTS_REQUESTED,
  PROPERTY_FORMS_ELEMENTS_SUCCESS,
  PROPERTY_FORMS_ELEMENTS_FAIL,
  PROPERTY_FORMS_ELEMENTS_RESET,
  PROPERTY_FORMS_DETAILS_REQUESTED,
  PROPERTY_FORMS_DETAILS_SUCCESS,
  PROPERTY_FORMS_DETAILS_FAIL,
  PROPERTY_FORMS_DETAILS_RESET,
  PROPERTY_FORM_DELETE_REQUESTED,
  PROPERTY_FORM_DELETE_SUCCESS,
  PROPERTY_FORM_DELETE_FAIL,
  PROPERTY_FORM_DELETE_RESET,
  PROPERTY_FORM_DUPLICATE_REQUESTED,
  PROPERTY_FORM_DUPLICATE_SUCCESS,
  PROPERTY_FORM_DUPLICATE_FAIL,
  PROPERTY_FORM_DUPLICATE_RESET,
  CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED,
  CREATE_OR_UPDATE_PROPERTY_FORM_FAIL,
  CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS,
  PROPERTY_FORM_CREATE_RESET,
  PROPERTY_FORMS_LIST_RESET,
  PROPERTY_FORM_ERROR_RESET
} from './types'

// TODO remove this mock
import propertyFormsListMock from '../../mocks/property-forms-list.mock'
import sharedTemplatesMock from '../../mocks/company-template-list.mock.json'
import propertyFormsElementsMock from '../../mocks/property-forms-elements.mock'
import propertyFormsDetailsMock from '../../mocks/property-forms-details.mock'
import moment from 'moment'
import { formatDateString } from '../../utils'
import { uuid } from 'short-uuid'
import { isEmpty, omit } from 'lodash'

const _propertyFormsListFail = dispatch => {
  dispatch({
    type: PROPERTY_FORMS_LIST_FAIL
  })
}

const _propertyFormsDetailsFail = dispatch => {
  dispatch({
    type: PROPERTY_FORMS_DETAILS_FAIL
  })
}

/**
 * Gets property forms list
 * @redux
 * @reduxActionCreator PROPERTY_FORMS_LIST_REQUESTED, PROPERTY_FORMS_LIST_SUCCESS, PROPERTY_FORMS_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} propertyId - the property ID to use in the request
 */

export const getPropertyFormsList = (pdbid, context, propertyId) => {
  return async dispatch => {
    dispatch({
      type: PROPERTY_FORMS_LIST_REQUESTED
    })

    try {
      let [propertyFormsList, sharedTemplates] = await Promise.all([
        Service.getPropertyFormsList(pdbid, context),
        Service.getSharedTemplates(pdbid, context)
      ])
      if (propertyFormsList.ok && sharedTemplates.ok) {
        const propertyFormsListRes = await propertyFormsList.json()
        const sharedTemplatesRes = await sharedTemplates.json()
        // const propertyFormsListRes = propertyFormsListMock
        // const sharedTemplatesRes = sharedTemplatesMock

        return dispatch({
          type: PROPERTY_FORMS_LIST_SUCCESS,
          list: _listAdapter(propertyFormsListRes),
          sharedTemplates: _sharedTemplatesAdapter(sharedTemplatesRes)
        })
      }

      _propertyFormsListFail(dispatch)
    } catch (err) {
      _propertyFormsListFail(dispatch)
    }
  }
}

export const resetPropertyFormList = () => {
  return {
    type: PROPERTY_FORMS_LIST_RESET
  }
}

/**
 * Gets property forms elements
 * @redux
 * @reduxActionCreator PROPERTY_FORMS_ELEMENTS_REQUESTED, PROPERTY_FORMS_ELEMENTS_SUCCESS, PROPERTY_FORMS_ELEMENTS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getFormElements = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: PROPERTY_FORMS_ELEMENTS_REQUESTED
    })
    Service.getTemplateElements(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const { elements } = await res.json()

          return dispatch({
            type: PROPERTY_FORMS_ELEMENTS_SUCCESS,
            elements: _elementsAdapter(null, elements)
          })
        }

        return dispatch({
          type: PROPERTY_FORMS_ELEMENTS_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: PROPERTY_FORMS_ELEMENTS_FAIL
        })
      })
  }
}

/**
 * reset Templates elements
 * @redux
 * @reduxActionCreator PROPERTY_FORMS_ELEMENTS_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const resetTemplateElements = () => {
  return dispatch => {
    dispatch({
      type: PROPERTY_FORMS_ELEMENTS_RESET
    })
  }
}

/**
 * Create property form from parent template
 * @redux
 * @reduxActionCreator CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED, CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS, CREATE_OR_UPDATE_PROPERTY_FORM_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the body object payload for the request
 * @param {string} formId - form id to be requested
 */
export const createOrUpdatePropertyForm = (
  pdbid,
  context,
  body,
  formId,
  onSuccess,
  onError
) => {
  return async dispatch => {
    dispatch({
      type: CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED
    })

    try {
      const propertyFormRes = await Service.createOrUpdateOrDuplicatePropertyForm(
        pdbid,
        context,
        body,
        formId
      )

      if (propertyFormRes.ok) {
        const propertyFormData = await propertyFormRes.json()

        dispatch({
          type: CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS
        })

        onSuccess && onSuccess(propertyFormData[0])

        return
      }

      dispatch({
        type: CREATE_OR_UPDATE_PROPERTY_FORM_FAIL
      })

      onError && onError()
    } catch (err) {
      dispatch({
        type: CREATE_OR_UPDATE_PROPERTY_FORM_FAIL
      })

      onError && onError()
    }
  }
}

export const duplicatePropertyForm = (
  pdbid,
  context,
  body,
  formId,
  onSuccess,
  onError
) => {
  return async dispatch => {
    dispatch({
      type: PROPERTY_FORM_DUPLICATE_REQUESTED
    })

    try {
      const propertyFormRes = await Service.createOrUpdateOrDuplicatePropertyForm(
        pdbid,
        context,
        body,
        formId
      )

      if (propertyFormRes.ok) {
        const propertyFormData = await propertyFormRes.json()

        dispatch({
          type: PROPERTY_FORM_DUPLICATE_SUCCESS
        })

        onSuccess && onSuccess(propertyFormData[0])

        return
      }

      dispatch({
        type: PROPERTY_FORM_DUPLICATE_FAIL
      })

      onError && onError()
    } catch (err) {
      dispatch({
        type: PROPERTY_FORM_DUPLICATE_FAIL
      })

      onError && onError()
    }
  }
}

/**
 * reset Proerty forms create
 * @redux
 * @reduxActionCreator PROPERTY_FORM_CREATE_RESET
 */
export const resetPropertyFormCreate = () => {
  return dispatch => {
    dispatch({
      type: PROPERTY_FORM_CREATE_RESET
    })
  }
}

/**
 * Gets property forms details
 * @redux
 * @reduxActionCreator PROPERTY_FORMS_DETAILS_REQUESTED, PROPERTY_FORMS_DETAILS_SUCCESS, PROPERTY_FORMS_DETAILS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} formId - form id to be requested
 */
export const getPropertyFormDetails = (pdbid, context, formId) => {
  return async dispatch => {
    dispatch({
      type: PROPERTY_FORMS_DETAILS_REQUESTED
    })

    try {
      let [
        propertyFormDetails,
        templateElements,
        propertyFormList
      ] = await Promise.all([
        Service.getPropertyFormDetails(pdbid, context, formId),
        Service.getTemplateElements(pdbid, context),
        Service.getPropertyFormsList(pdbid, context)
      ])

      if (
        propertyFormDetails.ok &&
        templateElements.ok &&
        propertyFormList.ok
      ) {
        const propertyFormDetailsRes = await propertyFormDetails.json()
        const templateElementsRes = await templateElements.json()
        const propertyFormListRes = await propertyFormList.json()

        const singleForm =
          Array.isArray(propertyFormDetailsRes) &&
          propertyFormDetailsRes.length > 0
            ? propertyFormDetailsRes[0]
            : {}

        const formData = _detailsAdapter(
          singleForm,
          templateElementsRes?.elements
        )
        const elements = _elementsAdapter(
          formData,
          templateElementsRes?.elements
        )

        dispatch({
          type: PROPERTY_FORMS_DETAILS_SUCCESS,
          details: omit(formData, ['usedSpaces']),
          list: _listAdapter(propertyFormListRes),
          elements
        })
      }
    } catch (err) {
      _propertyFormsDetailsFail(dispatch)
    }
  }
}

/**
 * Reset Property Form details
 * @redux
 * @reduxActionCreator PROPERTY_FORMS_DETAILS_RESET, PROPERTY_FORM_DELETE_RESET
 */
export const resetPropertyFormDetails = () => {
  return dispatch => {
    dispatch({
      type: PROPERTY_FORMS_DETAILS_RESET
    })
    dispatch({
      type: PROPERTY_FORM_DELETE_RESET
    })
    dispatch({
      type: PROPERTY_FORM_ERROR_RESET
    })
  }
}

/**
 * Delete Property Form
 * @redux
 * @reduxActionCreator PROPERTY_FORM_DELETE_REQUESTED, PROPERTY_FORM_DELETE_SUCCESS, PROPERTY_FORM_DELETE_FAIL, PROPERTY_FORM_DELETE_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} templateId - template id to be requested
 */
export const deletePropertyForm = (pdbid, context, formId) => {
  return dispatch => {
    dispatch({
      type: PROPERTY_FORM_DELETE_REQUESTED
    })

    Service.deletePropertyForm(pdbid, context, formId)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: PROPERTY_FORM_DELETE_SUCCESS,
            removedFormId: formId
          })
          dispatch({
            type: PROPERTY_FORM_DELETE_RESET
          })

          return
        }
        dispatch({
          type: PROPERTY_FORM_DELETE_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: PROPERTY_FORM_DELETE_FAIL
        })
      })
  }
}

/**
 * Duplicate Property Form
 * @redux
 * @reduxActionCreator PROPERTY_FORM_DUPLICATE_REQUESTED, PROPERTY_FORM_DUPLICATE_SUCCESS, PROPERTY_FORM_DUPLICATE_FAIL, PROPERTY_FORM_DUPLICATE_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} templateId - template id to be requested
 */
export const duplicatePropertyFormTemplate = (pdbid, context, templateId) => {
  return dispatch => {
    dispatch({
      type: PROPERTY_FORM_DUPLICATE_REQUESTED
    })

    Service.duplicatePropertyFormTemplate(pdbid, context, templateId)
      .then(async res => {
        dispatch({
          type: PROPERTY_FORM_DUPLICATE_SUCCESS,
          duplicatedTemplateId: templateId
        })
        dispatch({
          type: PROPERTY_FORM_DUPLICATE_RESET
        })
      })
      .catch(() => {
        dispatch({
          type: PROPERTY_FORM_DUPLICATE_FAIL
        })
      })
  }
}

const sortTemplateElements = sortKey => {
  return (current, next) => {
    return current[sortKey] - next[sortKey]
  }
}

/**
 * Property form(s) adapters
 */

const _detailsAdapter = (form = {}, elements = {}) => {
  const spaces = (form?.spaces || [])
    .sort(sortTemplateElements('sorting_order'))
    .map((space, space_index) => ({
      ...space,
      type: 'space',
      collapsed: true,
      do_delete_space: !space.do_delete_space ? 'false' : space.do_delete_space,
      space_name: space?.name,
      space_index,
      status:
        elements['spaces']?.find(element => element.space_id === space.space_id)
          ?.status || 'active',
      space_uuid: uuid(),
      items: (space?.items || [])
        .sort(sortTemplateElements('sorting_order_item'))
        .map((item, item_index) => ({
          ...item,
          type: 'item',
          collapsed: true,
          do_delete_item: !item.do_delete_item ? 'false' : item.do_delete_item,
          item_name: item?.name,
          space_index,
          item_index,
          item_uuid: uuid(),
          observations: (item?.observations || [])
            .sort(sortTemplateElements('sorting_order_observation'))
            .map((observation, observation_index) => ({
              ...observation,
              type: 'observation',
              do_delete_observation: !observation.do_delete_observation
                ? 'false'
                : observation.do_delete_observation,
              observation_name: observation?.name,
              space_index,
              item_index,
              observation_index,
              observation_uuid: uuid(),
              solution_type: !observation.solution_type
                ? 'single'
                : observation.solution_type,
              solutions: (observation?.solutions || [])
                .sort(sortTemplateElements('sorting_order_solution'))
                .map((solution, solution_index) => ({
                  ...solution,
                  type: 'solution',
                  do_delete_solution: !solution.do_delete_solution
                    ? 'false'
                    : solution.do_delete_solution,
                  solution_name: solution?.name,
                  space_index,
                  item_index,
                  observation_index,
                  solution_index,
                  solution_uuid: uuid()
                }))
            }))
        }))
    }))

  const usedSpaces = (form?.spaces || []).map(space => space.space_id)

  return {
    ...form,
    name: form.name ? form.name : '',
    type: form.type ? form.type : '',
    spaces,
    usedSpaces
  }
}

const sortElement = sortKey => {
  return (current, next) => {
    if (
      (current[sortKey] || '').toLowerCase() <
      (next[sortKey] || '').toLowerCase()
    )
      return -1
    if (
      (current[sortKey] || '').toLowerCase() >
      (next[sortKey] || '').toLowerCase()
    )
      return 1
    return 0
  }
}

/**
 * Elements adapater
 * @param {object} elements - elements data obtained from the endpoint
 * @param {object} context - elements being used, when getting the property form detail
 */

const _elementsAdapter = (formDetails, elements) => {
  // if there is detailsData, the adapter would filter
  // the elements that are not already in use
  if (!isEmpty(elements)) {
    elements.spaces = (elements.spaces || [])
      .filter(space => space.status === 'active')
      .sort(sortElement('space_name'))
    elements.items = (elements.items || [])
      .filter(item => item.status === 'active')
      .sort(sortElement('item_name'))
    elements.observations = (elements.observations || [])
      .filter(observation => observation.status === 'active')
      .sort(sortElement('observation_name'))
    elements.solutions = (elements.solutions || [])
      .filter(solution => solution.status === 'active')
      .sort(sortElement('solution_name'))
  }

  if (formDetails) {
    const { usedSpaces } = formDetails
    const { spaces } = elements

    return {
      ...elements,
      spaces: spaces.filter(space => !usedSpaces.includes(space.space_id))
    }
  }

  return elements
}

const _listAdapter = propertyFormList => {
  return (propertyFormList || []).map(propertyForm => ({
    ...propertyForm,
    created_at: moment(propertyForm.created_at).isValid()
      ? formatDateString(propertyForm.created_at, 'MM/DD/YYYY')
      : 'N/A',
    template: propertyForm.template || '-',
    state: propertyForm.state === null ? 'Complete' : 'Incomplete',
    name: (propertyForm?.name || '').trim(),
    type: (propertyForm?.type || '').trim()
  }))
}

const _sharedTemplatesAdapter = data => data
