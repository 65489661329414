import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ComponentWithStatus from '../component-with-status'

/**
 * Component for showing the congrats box
 *
 * @component
 * @example
 * return (
 *   <Congrats
 *     loading={false}
 *     error={false}
 *     percentage={75}
 *     context={{ project: 'uidev', property: 'syracuse'}}
 *   />
 * )
 */
const Congrats = ({ loading, error, percentage, context }) => {
  const showPercentageMessage = () => percentage === 25 || percentage === 50 || percentage === 75 || percentage === 100
  const { name, user_metadata: { projects } } = useSelector(state => state.user)

  const display = {
    project: projects.find(p => p.id === context.project) || { name: '' },
  }

  return (
    <div className="dashboard-card card-sm-semi light-background">
      <ComponentWithStatus loading={loading} error={error}>
        { showPercentageMessage() ? (
          <div className="vertical-center has-text-centered standard-padding">
            <p className="m-b-sm larger-text has-text-weight-bold">Congrats!</p>
            <p>
              You've officially made it through{' '}
              <span className="has-text-weight-bold">{percentage}%</span> of{' '}
              {display.project.name}
            </p>
          </div>
        ) : (
          <div className="vertical-center has-text-centered standard-padding">
            <p className="m-b-sm larger-text has-text-weight-bold" style={{ lineHeight: '24px' }}>Hi, {name}</p>
            <p>Welcome back to {display.project.name}</p>
          </div>
        )}
      </ComponentWithStatus>
    </div>
  )
}

Congrats.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Turn percentage complete
   */
  percentage: PropTypes.number,
  /**
   * Currently selected context
   */
  context: PropTypes.object
}

export default Congrats
