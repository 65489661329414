import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import { capitalize } from '../../../utils/helperFunctions'

const BlockUserModal = ({
  user,
  closeModal,
  updateUserAccountStatus,
  onHandleForceReload,
  isMultiple = false
}) => {
  const { user_metadata } = useSelector(state => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { pdbid } = user_metadata || {}

  const onUserAccountStatusError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onUserAccountStatusSuccess = () => {
    setIsLoading(false)
    setErrorMessage('')
    onHandleForceReload(true)
    closeModal()
  }

  const getUserText = useMemo(() => {
    if (Array.isArray(user)) {
      if ((user || []).length > 1) return 'users'
      return 'user'
    }
    return 'user'
  }, [user])

  const block = useMemo(() => {
    if (Array.isArray(user)) {
      if ((user || []).length)
        return user[0].blocked === 'Active' ? 'block' : 'reactivate'
    }
    return user?.blocked === 'Active' ? 'block' : 'reactivate'
  }, [user])

  return (
    <div className="confirmation-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            {`${capitalize(block || '')} ${capitalize(getUserText || '')}`}
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModal}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6">
            {`Are you sure you want to ${block} ${
              getUserText === 'users' ? 'these' : 'this'
            } ${getUserText}?`}
          </p>
        </div>
      </div>
      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <div>
            <p>Something went wrong. Please try again later !!!</p>
          </div>
          <button className="error-button" onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-primary m-r-md"
            onClick={closeModal}>
            Cancel
          </button>
          <button
            className="button main-button is-danger"
            onClick={() => {
              setIsLoading(true)
              setErrorMessage('')
              let body = {}
              if (isMultiple) {
                const createData = (user || []).map(data => {
                  const { user_id, blocked, auth_id } = data || {}
                  return {
                    user_id,
                    auth_id,
                    is_inactive: blocked === 'Blocked' ? 'false' : null
                  }
                })
                body = {
                  items: createData
                }
              } else {
                body = {
                  items: [
                    {
                      user_id: user?.user_id,
                      auth_id: user?.auth_id,
                      is_inactive: block === 'reactivate' ? 'false' : null
                    }
                  ]
                }
              }
              updateUserAccountStatus(
                pdbid,
                body,
                onUserAccountStatusSuccess,
                onUserAccountStatusError
              )
            }}>
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default BlockUserModal
