import { useEffect, useCallback, useState } from 'react'
import { debounce } from 'lodash'

const useWindowResize = (callback) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  const optimizedHandleResize = useCallback(
    debounce(
      () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      },
      [200]
    ),
    []
  )
  
  useEffect(() => {
    window.addEventListener('resize', optimizedHandleResize)
    return () => window.removeEventListener('resize', optimizedHandleResize)
  }, [])

  return { width: windowSize.width, height: windowSize.height }
}

export default useWindowResize
