import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import moment from 'moment'
import 'react-day-picker/lib/style.css'
import ComponentWithStatus from '../component-with-status'
import './calendar.scss'

const formatDate = date =>
  moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : 'N/A'

const weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

const firstDayOfWeek = 1

const modifiersStyles = {
  inRange: {
    backgroundColor: '#f8f9fa',
    borderRadius: '0'
  }
}

const getDates = (startDate, stopDate) => {
  const dateArray = []
  let currentDate = new Date(startDate.getTime())
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dateArray
}

/**
 * Component for showing the turn calendar box
 *
 * @component
 * @example
 * return (
 *   <Calendar
 *     loading={false}
 *     error={false}
 *     moveOut={new Date(2020, 9, 20)}
 *     moveIn={new Date(2020, 9, 3)}
 *     transfers={new Date(2020, 9, 10)}
 *   />
 * )
 */
const Calendar = ({ loading, error, moveOut, moveIn, transfers, totalTurnDays }) => {
  const [modifiers, setModifiers] = useState({})
  // const [totalNumberTurnDays, setTotalNumberTurnDays] = useState(totalTurnDays)

  const [moveOutDate, setMoveOutDate] = useState(moveOut)
  const [moveInDate, setMoveInDate] = useState(moveIn)
  const [transfersDate, setTransfersDate] = useState(transfers)

  useEffect(() => {
    if (!loading && moveOut) {
      moveOut.setHours(0)
      moveOut.setMinutes(0)
      moveOut.setSeconds(0)
      moveOut.setMilliseconds(0)
      setMoveOutDate(moveOut)
  
      moveIn.setHours(0)
      moveIn.setMinutes(0)
      moveIn.setSeconds(0)
      moveIn.setMilliseconds(0)
      setMoveInDate(moveIn)
  
      transfers.setHours(0)
      transfers.setMinutes(0)
      transfers.setSeconds(0)
      transfers.setMilliseconds(0)
      setTransfersDate(transfers)
  
      setModifiers({
        inRange: getDates(moveOut, moveIn)
      })
  
      // setTotalNumberTurnDays(Math.abs(moveIn - moveOut) / (1000 * 60 * 60 * 24))
    }
  }, [moveOut, moveIn, transfers, loading])

  return (
    <div className="dashboard-card card-fill">
      <ComponentWithStatus loading={loading} error={error}>
        <div>
          <p className="p-l-md p-t-md p-r-md heading-5">Calendar</p>
        </div>
        <div className="center-container">
          <DayPicker
            initialMonth={new Date()}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            canChangeMonth={false}
            showOutsideDays
            captionElement={() => null}
            firstDayOfWeek={firstDayOfWeek}
            weekdaysShort={weekdaysShort}
            renderDay={e => {
              const classes = ['day']
              e.setHours(0)
              e.setMinutes(0)
              e.setSeconds(0)
              e.setMilliseconds(0)

              if (e.getTime() === (moveOutDate && moveOutDate.getTime())) {
                classes.push('move-out')
              } else if (e.getTime() === (moveInDate && moveInDate.getTime())) {
                classes.push('move-in')
              } else if (e.getTime() === (transfersDate && transfersDate.getTime())) {
                classes.push('transfers')
              }

              return <span className={classes.join(' ')}>{e.getDate()}</span>
            }}
          />
        </div>
        <div>
          <p className="p-l-md p-r-md">Milestones</p>
          <ul className="m-l-lg">
            <li className="m-t-md">
              <div className="columns is-vcentered">
                <div className="column is-narrow">
                  <div
                    style={{
                      backgroundColor: '#122149',
                      borderRadius: '50%',
                      width: '1.4rem',
                      height: '1.4rem'
                    }}></div>
                </div>
                <div className="column" style={{ paddingLeft: '0' }}>
                  Move Out: {formatDate(moveOut)}
                </div>
              </div>
            </li>
            <li>
              <div className="columns is-vcentered">
                <div className="column is-narrow">
                  <div
                    style={{
                      backgroundColor: '#3db3e3',
                      borderRadius: '50%',
                      width: '1.4rem',
                      height: '1.4rem'
                    }}></div>
                </div>
                <div className="column" style={{ paddingLeft: '0' }}>
                  Move In: {formatDate(moveIn)}
                </div>
              </div>
            </li>
            <li>
              <div className="columns is-vcentered">
                <div className="column is-narrow">
                  <div
                    style={{
                      border: '1px dashed #122149',
                      borderRadius: '50%',
                      width: '1.4rem',
                      height: '1.4rem'
                    }}></div>
                </div>
                <div className="column" style={{ paddingLeft: '0' }}>
                  Transfers: {formatDate(transfers)}
                </div>
              </div>
            </li>
          </ul>
          <div className="bottom-section m-l-md m-r-md">
            Total Turn Days: <span className="semistrong">{totalTurnDays}</span>
          </div>
        </div>
      </ComponentWithStatus>
    </div>
  )
}

Calendar.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Move out date
   */
  moveOut: PropTypes.instanceOf(Date),
  /**
   * Move in date
   */
  moveIn: PropTypes.instanceOf(Date),
  /**
   * Transfers date
   */
  transfers: PropTypes.instanceOf(Date),
  /**
   * Total Turn Days
   */
  totalTurnDays: PropTypes.number
}

export default Calendar
