import React from 'react'
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../containers/private-route'
import QcWork from '../qc-work'
import QcQueue from '../qc-queue'
import QcApproved from '../qc-approved'
import QcJobDetail from '../qc-job-detail'
import QcSearch from '../qc-search'
import PropertyMap from '../property-map'
import MobileTabs from '../../components/mobile-tabs'
import {
  home,
  homeClicked,
  pending,
  pendingClicked,
  approved,
  approvedClicked,
  tabSearch,
  tabSearchClicked
} from '../../ui/icons'
import './index.scss'

export const qcTabs = [
  {
    pathname: '/qc/queue',
    title: 'Queue',
    icon: pending,
    clicked: pendingClicked
  },
  {
    pathname: '/qc/work',
    title: 'My Work',
    icon: home,
    clicked: homeClicked
  },
  {
    pathname: '/qc/approved',
    title: 'Approved',
    icon: approved,
    clicked: approvedClicked
  }
  // {
  //   pathname: '/qc/search',
  //   title: 'Search',
  //   icon: tabSearch,
  //   clicked: tabSearchClicked
  // }
]

const QcMain = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  return (
    <div className="qc-main">
      <div className="qc-main-content">
        <Switch>
          <PrivateRoute exact path={`${path}/work`} component={QcWork} />
          <PrivateRoute exact path={`${path}/queue`} component={QcQueue} />
          <PrivateRoute
            exact
            path={`${path}/approved`}
            component={QcApproved}
          />
          <PrivateRoute
            exact
            path={`${path}/job/:id`}
            component={QcJobDetail}
          />
          <PrivateRoute
            exact
            path={`${path}/property-map`}
            component={PropertyMap}
          />
          {/* <PrivateRoute exact path={`${path}/search`} component={QcSearch} /> */}
          <Redirect from="/qc" to="/qc/queue" />
        </Switch>
      </div>
      <div className="qc-main-footer">
        <MobileTabs pathname={pathname} tabs={qcTabs} />
      </div>
    </div>
  )
}

export default QcMain
