import React from 'react'
import './index.scss'

const Button = ({ type, children, size = 'normal', className, ...rest }) => {
  return (
    <button className={`button is-${type} main-button width--${size} ${className}`} {...rest}>
      {children}
    </button>
  )
}

export default Button
