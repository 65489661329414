import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'

const ReactivateOrInactivateModal = ({
  onConfirm,
  onCancel,
  status,
  error,
  loading
}) => {
  const modalMessage = status
    ? 'Do you want to Inactivate the vendor?'
    : 'Do you want to Reactivate the vendor?'

  return (
    <>
      <div className="columns">
        <div className="column is-full">
          {error && (
            <p className="is-size-6">
              <ErrorMessage>{error}</ErrorMessage>
            </p>
          )}
          <p className="is-size-6 sub-message">{modalMessage}</p>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={onCancel}>
            Cancel
          </button>
          <button className="button main-button is-primary" onClick={onConfirm}>
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}

export default ReactivateOrInactivateModal
