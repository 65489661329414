import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { getScheduleManualData, resetScheduleManualData } from '../../../modules/schedule'
import { StepperContext } from '../../../components/wizard'
import TurnableSpinner from '../../../components/turnable-spinner'
import ScheduleTable from '../schedule-table'
import './index.scss'

// const loadingItems = ['Checking dates…', 'Verifying teams...', 'Organizing units...']

const ScheduleFirstStep = () => {
  const dispatch = useDispatch()
  const { user, context, schedule } = useSelector(state => state)
  const { scheduleManualDataIsRequesting, scheduleManualData } = schedule
  const { stepData, backActionHandler, forwardActionHandler } = useContext(StepperContext)
  const [loadingData, setLoadingData] = useState(true)
  const goalsSetted = stepData['goalsDates'][0]
  const serviceType = stepData['servicetype'].replace(/[^a-zA-Z ]/g, '').split(' ').join('')

  useEffect(() => {
    const { pdbid } = user.user_metadata    
    dispatch(getScheduleManualData(pdbid, context, serviceType))
  }, [])

  useEffect(() => {
    if (!scheduleManualDataIsRequesting && scheduleManualData) {
      setLoadingData(false)
    }
  }, [scheduleManualDataIsRequesting])

  const handleBackAction = () => {
    backActionHandler()
    dispatch(resetScheduleManualData())
  }

  const continueHandler = () => {
    forwardActionHandler()
  }

  const selectionScreen = (
    <div className="schedule-first-step">
      <p className="title m-b-md">
        Let’s review your <span className="highlighted">capacity setup</span>
      </p>
      {goalsSetted && scheduleManualData ? (
        <>
          <p className="large-text m-b-lg">
            Here’s what you’ve determined each team can handle per day:
          </p>
          <ScheduleTable
            dates={scheduleManualData.dates}
            teamsCapacity={scheduleManualData.teamsCapacity}
          />
        </>
      ) : (
        <p className="large-text m-b-lg m-t-lg">
          There is no team capacity setup for this service.
        </p>
      )}

      <Link to="/setup/teams">
        Click here if you need to update a team’s capacity
      </Link>

      <div className="navigation-container">
        <button onClick={handleBackAction} className="text-button">
          <FontAwesomeIcon icon={faChevronLeft} />
          Back
        </button>
        {goalsSetted && (
          <button className="button is-primary" onClick={continueHandler}>
            Continue
          </button>
        )}
      </div>
    </div>
  )

  // const loadingScreen = (
  //   <div className="schedule-first-step">
  //     <p className="m-b-md large-text">Now, the fun begins!</p>
  //     <p className="title m-b-md">
  //       One moment while we <span className="highlighted">tidy up the details</span>
  //     </p>
  //     <div className="loaders-container">
  //       {loadingItems.map((item, index) =>
  //         index <= loaderItemsRendered ? (
  //           <div className="loader-item" key={item}>
  //             <svg height="100" width="100">
  //               <circle cx="50" cy="50" r="40" stroke="#3DB3E2" strokeWidth="4" fill="#FFF"/>
  //             </svg>
  //             <p>{item}</p>
  //           </div>
  //         ) : (
  //           <div className="loader-item placeholder" key={item}></div>
  //         )
  //       )}
  //     </div>
  //   </div>
  // )

  return loadingData ? (
    <div className="is-full-width p-b-lg">
      <div className="has-text-centered">
        <TurnableSpinner size="large" className="loading-spinner" />
        <p className="loading-text">Loading...</p>
      </div>
    </div>
  ) : selectionScreen
}

export default ScheduleFirstStep
