import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ApprovalsDashboardMain from '../../parents/approvals-dashboard-main'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { updateContext, requestContextChange } from '../../modules/context'
import {
  getApprovalsList,
  bulkApprovalPurchaseOrder
} from '../../modules/approval'
import { resetPurchaseOrderDetail } from '../../modules/purchase-orders'
import { getInspectionDownloadMediaURL } from '../../modules/inspect'
import { updateUserAllowedSection } from '../../modules/user'

const Approvals = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <ApprovalsDashboardMain {...props} />
  }

  return <BigLoading />
}

Approvals.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  getApprovalsList: PropTypes.func,
  resetPurchaseOrderDetail: PropTypes.func,
  bulkApprovalPurchaseOrder: PropTypes.func,
  getInspectionDownloadMediaURL: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, approval, inspect }) => ({
  user,
  context,
  modal,
  approval,
  inspect
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      getApprovalsList,
      resetPurchaseOrderDetail,
      bulkApprovalPurchaseOrder,
      getInspectionDownloadMediaURL,
      updateContext,
      requestContextChange,
      updateUserAllowedSection
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Approvals)
)
