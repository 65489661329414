import React from 'react'
import PropTypes from 'prop-types'
import DashboardSetup from '../dashboard-setup'

const HomeMain = props => <DashboardSetup {...props} />

HomeMain.propTypes = {
  user: PropTypes.object,
  updateContext: PropTypes.func,
  requestContextChange: PropTypes.func
}

export default HomeMain
