import check from './icons/solidgreencheck.svg'
import add from './icons/setup-add.svg'
import addBlack from './icons/add-black.svg'
import edit from './icons/edit@2x.svg'
import trash from './icons/trash@2x.svg'
import lock from './icons/lock.svg'

export const icons = {
  check,
  add,
  addBlack,
  edit,
  trash,
  lock
}
