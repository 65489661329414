import React from 'react'
import DefaultColumn from './in-house-table/in-house-table-columns/default-column'
import ServiceStatusColumn from './in-house-table/in-house-table-columns/service-status-column'
import MultiSelectSearchDropdownFilter from '../../components/react-table-column-filters/MultiSelectSearchDropdownFilter'

export const headerData = ({ services, hasPermission }) => {
  const serviceStatusColumns = (services || []).map(service => {
    const serviceKey = (service?.servicetype || '')
      .toLowerCase()
      .replaceAll(' ', '_')

    return {
      Header: service?.servicetype,
      // disableSortBy: true,
      accessor: `${serviceKey}_inhouse_status`,
      Filter: MultiSelectSearchDropdownFilter,
      filter: 'multiEquals',
      backgroundColor: '#122048',
      color: '#fff',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return (
          <ServiceStatusColumn
            row={row}
            serviceName={serviceKey}
            hasPermission={hasPermission}
          />
        )
      },
      width: 190
    }
  })

  return [
    {
      Header: 'Unit',
      accessor: 'unit',
      Filter: MultiSelectSearchDropdownFilter,
      filter: 'multiEquals',
      backgroundColor: '#8BD3ED',
      color: '#fff',
      flexGrow: 1,
      textAlign: 'left',
      sticky: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      },
      width: 100
    },
    {
      Header: 'Space',
      accessor: 'unit_space',
      Filter: MultiSelectSearchDropdownFilter,
      filter: 'multiEquals',
      backgroundColor: '#8BD3ED',
      color: '#fff',
      flexGrow: 1,
      textAlign: 'left',
      sticky: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      },
      width: 100
    },
    ...serviceStatusColumns
  ]
}
