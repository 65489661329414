import React, {
  memo,
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback
} from 'react'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy
} from 'react-table'
import * as _ from 'lodash'
import * as moment from 'moment'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import styled from 'styled-components'
import { useSticky } from 'react-table-sticky'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList } from 'react-window'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import $ from 'jquery'

import ToggleColumns from './components/ToggleColumns'
import ToggleColumnFilters from './components/ToggleColumnFilters'
import ShowMoreItemsButton from './components/ShowMoreItemsButton'
import PermissionGate from '../../components/permission-gate'
import BasicLoading from '../../components/basic-loading'
import TurnboardSchedule from './components/Schedule'
import { customFilters, headerData, columnKeys } from './header'
import TurnboardFilters from '../../components/turnboard-filters'
import AppliedFilters from '../../components/data-table/applied-filters'
import TurnboardViewAllNotesModal from '../turnboard-2-view-notes-modal'
import TurnboardMarkAsIsModal from '../turnboard-2-mark-as-is-modal'
import TurnboardGroupUnitsModal from '../turnboard-2-group-units-modal'
import TurnboardLeaseOverrideModal from '../turnboard-2-lease-override-modal'
import TurnboardTagsModal from '../turnboard-2-tags-modal'
import TurnboardSingleNoteDetails from '../turnboard-2-view-notes-modal/turnboard-single-note-details'
import TurnboardManageNote from '../turnboard-2-view-notes-modal/turnboard-manage-note'
import TurnboardChangeServiceOptionsModal from '../turnboard-2-change-service-options-modal'
import SearchDropdownFilter from './components/SearchDropdownFilter'
import { icons } from './icons'
import './index.scss'
import { debounce } from 'lodash'
import { capitalize, checkPermission } from '../../utils/helperFunctions'
import TurnboardEnableServicesModal from '../turnboard-2-enable-services-modal'
import TurnboardInHouseModal from '../turnboard-2-in-house-modal/TurnboardInHouseModal'
import TurnboardInHouseInterfaceModal from '../turnboard-2-in-house-modal/TurnboardInhouseInterfaceModal'
import ToggleButton from './components/ToggleButton'

const Styles = styled.div`
  padding: 1rem;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  input {
    max-width: 90%;
    background: #e5e9f2;
    border: 1px solid transparent;
    margin: 0px auto;
  }
  select {
    max-width: 90%;
  }
  .table {
    display: inline-block;
    border-spacing: 0;
    border-right: 1px solid #e5e9f2;
    border-left: 1px solid #e5e9f2;
    position: relative;

    .header {
      width: 100% !important;
      overflow: hidden;
      margin-bottom: 0px;

      &.scroll {
        overflow-x: scroll;
      }
    }

    .tr {
      position: relative;

      .td:first-child {
        position: sticky;
        left: 0px;
        z-index: 3;
      }

      .th:first-child {
        position: sticky;
        left: 0px;
        z-index: 3;
      }

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      :first-child {
        .th {
          border-top: 1px solid #e5e9f2;
        }
      }
      .th {
        background: #ffffff;
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      background: #ffffff;
      :first-child {
        border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      border-right: 1px solid #e5e9f2;
      position: relative;
      background: inherit;
      overflow: hidden;
      :last-child {
        border-right: 1px solid #e5e9f2;
      }
      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }
    &.sticky {
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        z-index: 4;
        top: 0;
      }

      .body {
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const handleChange = () => {
      const filteredRows = (rows || []).filter(
        ({ original }) =>
          original?.unit_id === currentRow?.original?.unit_id &&
          original?.canSchedule
      )

      return (filteredRows || []).forEach(({ id, isSelected }) => {
        toggleRowSelected(id, !isSelected)
      })
    }

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            enableManualSelection ? handleChange() : onChange(e)
          }}
          {...rest}
          title={
            showTooltip
              ? 'The capacity for the selected date has been reached.'
              : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const renderCustomStyling = row => {
  const {
    unitIndex,
    lease_status,
    ca_needed,
    canSchedule,
    unit_space,
    is_asis,
    isWalkComplete,
    isUnitSpaceCompleted,
    is_inhouse,
    isInHouseCompleted,
    areAllOverviewStatusesGreen
  } = row || {}

  const whiteCellStatuses = ['In Progress', 'Not Started', 'Pending Walk']
  const overviewStatusKeys = (Object.keys(row || {}) || []).filter(key =>
    (key || '').includes('overview_status')
  )

  const isCaNeededIsNA = ca_needed === 'N/A' && unit_space === 'CA'

  const isComplete =
    isUnitSpaceCompleted ||
    isInHouseCompleted ||
    (areAllOverviewStatusesGreen && !is_inhouse) ||
    (is_asis && !is_inhouse) ||
    (isCaNeededIsNA && !is_inhouse) ||
    lease_status === 'Renewal'

  if (!isWalkComplete) whiteCellStatuses.push('N/A')

  // const whiteCells = (overviewStatusKeys || []).filter(key =>
  //   whiteCellStatuses.includes(row[key])
  // )

  if (canSchedule) {
    return 'rgba(255,255,255,0.5)'
  }

  if (isComplete) return '#8DC85C'

  // Need to discuss with team

  // if (!whiteCells.length) {
  //   return '#8DC85C'
  // }

  if (canSchedule === false) {
    return '#EAEFF4'
  }

  return unitIndex % 2 === 0 ? '#E5E9F2' : '#ffffff'
}

const TableHeader = ({ column, canFilter }) => {
  return (
    <div {...column.getHeaderProps()} className="th">
      <div className={`${column.parent && 'table-sub-header'}`}>
        {column.render('Header')}
        <div className="sort-icon" {...column.getSortByToggleProps()}>
          {column.isSorted ? (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={!column.isSortedDesc ? icons.caretUpOn : icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{ display: 'block', marginTop: '-3px' }}
                src={
                  column.isSortedDesc ? icons.caretDownOn : icons.caretDownOff
                }
                alt="Sort in descending order"
              />
            </div>
          ) : column.canSort ? (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{ display: 'block', marginTop: '-3px' }}
                src={icons.caretDownOff}
                alt="Sort in descending order"
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        {column.canFilter && canFilter ? column.render('Filter') : null}
      </div>
    </div>
  )
}

const Table = memo(
  forwardRef(
    (
      {
        columns,
        data,
        onRowSelectStateChange,
        capacity,
        enableManualSelection,
        isAllColumnCollapsed,
        hiddenColumns,
        canFilter,
        isScheduleOpen,
        setIsFilterApplied
      },
      ref
    ) => {
      // Use the state and functions returned from useTable to build your UI
      const [tableHeight, setTableHeight] = useState(0)
      const [scrollPosition, setScrollPosition] = useState(0)
      const [isHeaderScrollable, setIsHeaderScrollable] = useState(false)

      const defaultColumn = React.useMemo(
        () => ({
          width: 40,
          Filter: SearchDropdownFilter,
          filter: 'multiEquals'
        }),
        []
      )

      const filterTypes = React.useMemo(
        () => ({
          multiEquals: (rows, id, filterValue) => {
            if (filterValue.length === 0) return rows

            id = id[0]
            const isTagLike = id === 'simplifiedTags' || id?.endsWith('option')

            return rows.filter(row => {
              if (isTagLike) {
                const rowVals = row.values?.[id]
                  ?.split(',')
                  .map(x => x.trim())
                  .filter(x => x)

                return filterValue?.some(filterVal =>
                  rowVals.includes(filterVal?.[id])
                )
              } else {
                return filterValue?.some(
                  filterVal => filterVal?.[id] === row.values?.[id]
                )
              }
            })
          }
        }),
        []
      )
      // const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        setHiddenColumns,
        setAllFilters,
        state: { selectedRowIds, filters },
        selectedFlatRows,
        toggleRowSelected,
        toggleAllRowsSelected
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          filterTypes
        },
        useBlockLayout,
        useSticky,
        useFilters,
        useSortBy,
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return (
                  <div className="checkbox-wrapper">
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                      disabled={capacity || capacity === 0}
                    />
                  </div>
                )
              },
              Cell: ({ row }) => {
                const rowId = +row?.id
                const { unit_count, canSchedule } = row.original || {}

                const disabled = useMemo(() => {
                  if (capacity !== '') {
                    return unit_count > +capacity && !(rowId in selectedRowIds)
                  }
                  return false
                }, [rowId, selectedRowIds, capacity])

                const showTooltip = useMemo(() => {
                  return disabled && canSchedule
                }, [disabled, canSchedule])

                return (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      currentRow={row}
                      rows={rows}
                      toggleRowSelected={toggleRowSelected}
                      enableManualSelection={enableManualSelection}
                      disabled={disabled || canSchedule === false}
                      showTooltip={showTooltip}
                    />
                  </div>
                )
              }
            },
            ...columns
          ])
        }
      )

      useImperativeHandle(ref, () => ({
        getRows: () => {
          return rows?.map(row => row.original)
        },
        clearFilter: setAllFilters,
        toggleAllRowsSelected: toggleAllRowsSelected
      }))

      useEffect(() => {
        onRowSelectStateChange(selectedFlatRows)
      }, [selectedRowIds])

      useEffect(() => {
        setAllFilters([])
      }, [canFilter])

      useEffect(() => {
        setIsFilterApplied(filters)
      }, [filters])

      useEffect(() => {
        const columnsToHide = hiddenColumns

        setHiddenColumns(columnsToHide)
      }, [isAllColumnCollapsed, canFilter, hiddenColumns, rows])

      const RenderRow = React.useCallback(
        ({ index, style }) => {
          const row = rows[index]
          prepareRow(row)
          const { original } = row || {}
          return (
            <div
              {...row.getRowProps({
                style: {
                  ...style,
                  background: renderCustomStyling(original),
                  opacity: original?.canSchedule === false ? '0.5' : '1',
                  border: original?.canSchedule ? '1px solid #55C2B8' : 'none',
                  width: totalColumnsWidth
                }
              })}
              className="tr">
              {row.cells.map(cell => {
                return (
                  <div
                    {...cell.getCellProps({
                      style: {
                        backgroundColor: isScheduleOpen ? '#ffffff' : 'inherit'
                      }
                    })}
                    className="td">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows, selectedRowIds]
      )

      const defaultTableHeight =
        document.getElementById('turnboard-container')?.offsetHeight -
        document.getElementById('headerSection')?.offsetHeight -
        100
      const itemSize = 35

      const listElement = $('.body').parent()
      const listHeight = $(listElement).height()

      const optimizedScroll = useCallback(
        debounce(scrollLeft => {
          setScrollPosition(() => scrollLeft)
        }, 200)
      )

      useEffect(() => {
        const element = $('.body').parent()

        $(element).on('scroll', function() {
          const scrollLeft = $(this).scrollLeft()
          const height = $(this).height()

          if (scrollLeft >= 0) {
            $('.header').scrollLeft(scrollLeft)

            if (height > itemSize) {
              optimizedScroll(scrollLeft)
            } else {
              setIsHeaderScrollable(() => true)
            }
          }
        })
      }, [])

      useEffect(() => {
        const header = $('.header')

        if (listHeight <= itemSize && isHeaderScrollable) {
          $(header).on('scroll', function() {
            const scrollLeft = $(this).scrollLeft()
            optimizedScroll(scrollLeft)
          })
        }
      }, [listElement, listHeight, isHeaderScrollable])

      // Render the UI for your table

      useEffect(() => {
        const header = $('.header')

        if (isHeaderScrollable) {
          $(header).scrollLeft(scrollPosition)
        }
      }, [isHeaderScrollable])

      useEffect(() => {
        if (listHeight > itemSize && isHeaderScrollable) {
          $(listElement).scrollLeft(scrollPosition)
          setIsHeaderScrollable(() => false)
        }
      }, [isHeaderScrollable, scrollPosition, listHeight, listElement])

      useEffect(() => {
        const updatedListSize = itemSize * (rows || []).length

        if (updatedListSize < defaultTableHeight) {
          setTableHeight(updatedListSize + itemSize)
        } else {
          setTableHeight(defaultTableHeight)
        }
      }, [rows, defaultTableHeight])

      return (
        <div
          {...getTableProps()}
          className="table sticky"
          style={{ width: '100%' }}>
          <div
            style={{ position: 'relative', width: '100%', flex: 1, zIndex: 0 }}>
            <div
              className={`header ${!rows.length ? 'scroll' : ''} `}
              id="headerSection">
              <div>
                {headerGroups.map(headerGroup => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map(column => (
                      <TableHeader column={column} canFilter={canFilter} />
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <InfiniteLoader
              isItemLoaded={index => data[index] !== undefined}
              loadMoreItems={async () => {
                console.log('here')
              }}
              itemCount={data.length + 1}>
              {({ onItemsRendered, ref }) => {
                return (
                  <FixedSizeList
                    className="list-container"
                    height={tableHeight}
                    itemCount={(rows || []).length}
                    itemSize={itemSize}
                    width={'100%'}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    innerElementType={({ children, style, ...rest }) => {
                      return (
                        <>
                          <div className="body">
                            <div
                              {...getTableBodyProps()}
                              {...rest}
                              style={style}>
                              {children}
                            </div>
                          </div>
                        </>
                      )
                    }}>
                    {RenderRow}
                  </FixedSizeList>
                )
              }}
            </InfiniteLoader>
          </div>
        </div>
      )
    }
  )
)

const SymbolToolTip = () => {
  return (
    <>
      <div className="symbol-heading">Turnboard Symbols & Colors</div>
      <div className="symbol-container">
        <div className="symbol-box bg-white">
          <img src={icons.circleFilledIcon} alt="In Progress" />
        </div>
        <div className="symbol-text"> In Progress </div>
      </div>
      <div className="symbol-container">
        <div className="symbol-box bg-white">
          <img src={icons.circleEmptyIcon} alt="Not Started" />
        </div>
        <div className="symbol-text"> Not Started </div>
      </div>
      <div className="symbol-container">
        <div className="symbol-box bg-green">
          <img src={icons.circleFilledGreenIcon} alt="Completed" />
        </div>
        <div className="symbol-text"> Completed </div>
      </div>
      <div className="symbol-container">
        <div className="symbol-box bg-green">
          <img src={icons.dashIcon} alt="Not Needed" />
        </div>
        <div className="symbol-text"> Not Needed </div>
      </div>
      <div className="symbol-container">
        <div className="symbol-box bg-white">
          <img src={icons.dashIcon} alt="Pending Walk" />
        </div>
        <div className="symbol-text"> Pending Walk </div>
      </div>
    </>
  )
}

const Turnboard2Component = ({
  turnboard,
  user,
  context,
  getTurnboard2,
  setModalContent,
  openModal,
  closeModal,
  unitNotesUpdate,
  updateTurnboardDataByState,
  setRemoveAllActiveFilter,
  unitNotesCreate,
  updateServiceOptions,
  getScheduleByService,
  updateServiceSchedule,
  getTurnboardDataFilter,
  updateAsIsStatus,
  getTurnboardAllTags,
  getAllGroups,
  groupOrUngroupUnits,
  getTurnboardServiceStatus,
  updateLeaseOverride,
  setupAllVendorsGet,
  setupVendors,
  updateEnableServices,
  markInHouse,
  unMarkInHouse,
  markAndUnMarkInHouse
}) => {
  const [activeFilters, setActiveFilters] = useState([])
  const [selectedRows, setSelectedRows] = React.useState([])
  const [isScheduleOpen, setIsScheduleOpen] = useState(false)
  const [selectedScheduleDate, setSelectedSchedulteDate] = useState([])
  const [capacity, setCapacity] = useState('')
  const [isAllColumnCollapsed, setAllColumnCollapsed] = useState(false)
  const [canFilter, setFilter] = useState(true)
  const [showCompleted, setShowCompleted] = useState(true)
  const [isTurnboardOverview, setIsTurnboardOverview] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const [showUnscheduledUnit, setShowUnscheduledUnit] = useState(true)
  const [showScheduledUnit, setShowScheduledUnit] = useState(false)
  const [service, setService] = useState('')

  const [hiddenColumns, setHiddenColumns] = useState([])

  const tableRef = useRef(null)

  // const [serviceStatusLoading, setServiceStatusLoading] = useState(false)

  const { user_metadata } = user || {}
  const { pdbid, in_house } = user_metadata || {}
  const { project, property } = context || {}

  useEffect(() => {
    if (project && property && pdbid) {
      getTurnboard2Data()
    }
  }, [project, property, pdbid])

  useEffect(() => {
    if ((selectedScheduleDate || []).length !== 0)
      setCapacity(() => selectedScheduleDate[0]?.capacity)
    else setCapacity(() => '')
  }, [selectedScheduleDate])

  useEffect(() => {
    if ((selectedScheduleDate || []).length !== 0) {
      setCapacity(() =>
        Math.abs(selectedScheduleDate[0]?.capacity - selectedRows.length)
      )
    }
  }, [selectedRows, selectedScheduleDate])

  const { data: turnboardData, turnboardLoader } = turnboard || {}
  const isLoading = turnboardLoader
  const { units, services } = turnboardData || {}

  const getTurnboard2Data = async () => {
    await getTurnboard2({
      pdbid,
      context,
      onSuccess: async () => {
        getTurnboardDataFilter({
          filters: activeFilters || []
        })
      }
    })
    await getTurnboardAllTags({
      pdbid,
      context
    })
    await setupAllVendorsGet(pdbid, context)
  }

  // const getTurnboardServiceStatusData = async () => {
  //   setServiceStatusLoading(true)

  //   await getTurnboardServiceStatus({
  //     pdbid,
  //     context,
  //     onSuccess: () => {
  //       setServiceStatusLoading(false)
  //     },
  //     onError: () => {
  //       setServiceStatusLoading(false)
  //     }
  //   })
  // }

  const notes = row => {
    const { cell } = row || {}
    const { row: data } = cell || {}
    const { original } = data || {}
    const { unit, unit_id } = original || {}

    let unitIds = []
    const reStructureUnits = (units || []).map(u => {
      const { unit, unit_id } = u || {}
      return {
        unit,
        unit_id
      }
    })
    if ((reStructureUnits || []).length !== 0) {
      unitIds = [
        ...new Map(
          (reStructureUnits || []).map(item => [item['unit'], item])
        ).values()
      ]
    }
    const singleNoteDetailsProps = {
      unit,
      unit_id,
      setRemoveAllActiveFilter
    }
    setModalContent(() => (
      <TurnboardSingleNoteDetails
        {...singleNoteDetailsProps}
        AllUnitIds={unitIds}
        unitsData={units}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-add-note-modal'
    })
  }

  const addNote = row => {
    const { cell } = row || {}
    const { row: data } = cell || {}
    const { original } = data || {}

    const {
      charge = '0',
      type = '',
      unit_id = '',
      note = '',
      id = '',
      isNew = true,
      unit = ''
    } = original || {}

    const addNoteProps = {
      unitNotesCreate,
      user,
      context,
      noteCharge: charge,
      noteType: type,
      noteUnit: {
        unit_id,
        unit
      },
      noteMessage: note,
      isNoteEdit: unit_id !== '' && !isNew,
      isNew,
      unitNotesUpdate,
      note_id: id,
      unitIds: [{ unit, unit_id }],
      updateTurnboardDataByState,
      turnboard,
      setRemoveAllActiveFilter,
      setupVendors
    }
    setModalContent(() => (
      <TurnboardManageNote
        {...addNoteProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-manage-note-modal'
    })
  }

  const viewAllNotes = () => {
    setIsScheduleOpen(false)
    setSelectedSchedulteDate([])

    let getAllUnitIds = (units || []).map(item => {
      const { unit, unit_id } = item || {}
      return {
        unit,
        unit_id
      }
    })
    if ((getAllUnitIds || []).length !== 0) {
      getAllUnitIds = [
        ...new Map((data || []).map(item => [item['unit'], item])).values()
      ]
    }
    let reConstructTableRow = []
    const rows = tableRef.current?.getRows() || []
    if ((rows || []).length !== 0) {
      reConstructTableRow = (rows || []).map(row => {
        const { unit_id } = row || {}
        return unit_id
      })
    }
    if ((selectedRows || []).length !== 0) {
      reConstructTableRow = []
      reConstructTableRow = (selectedRows || []).map(row => {
        const { original: u } = row || {}
        const { unit_id } = u || {}
        return unit_id
      })
    }

    setModalContent(() => (
      <TurnboardViewAllNotesModal
        AllUnitIds={getAllUnitIds}
        selectedUnitIds={reConstructTableRow}
        setRemoveAllActiveFilter={setRemoveAllActiveFilter}
        isFilterApplied={
          (reConstructTableRow || []).length !== 0 ||
          (selectedRows || []).length !== 0 ||
          (rows || []).length !== 0
        }
        onClose={() => {
          closeModal()
        }}
      />
    ))

    openModal({
      width: '649px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-all-notes-modal'
    })
  }

  const manageTags = row => {
    const { unit, unit_id } = row || {}
    const defaultProps = {
      unit,
      unit_id,
      reCallTurnboardData
    }
    setModalContent(() => (
      <TurnboardTagsModal
        {...defaultProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-all-tags-modal'
    })
  }

  const reCallTurnboardData = () => {
    getTurnboard2Data()
    //this.getHeader()
    // window.location.reload(false)
  }

  const getServicesArr = () =>
    (services || []).map(s => ({
      key: s.servicetype,
      display: s.label
    }))

  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }

    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const rows = tableRef.current?.getRows() || []
    let defaultData = selectedRows.length
      ? selectedRows.map(row => row?.original)
      : rows

    const services = getServicesArr()

    const newData = (defaultData || []).map(e => {
      const {
        floorplan,
        building,
        floor,
        unit,
        unit_space,
        current_lease_status,
        lease_status,
        isFirstSpaceRow,
        ca_needed,
        early_move_in,
        actual_move_out,
        scheduled_move_out,
        has_note,
        grouping,
        current_resident,
        future_resident
      } = e || {}

      const serviceItems = (services || []).map(item => {
        const { display: service, key } = item || {}
        const serviceName = (key || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        const status = e[`${serviceName}_status`]

        if (isTurnboardOverview) {
          return {
            [key]: e[`${serviceName}_overview_status`]
          }
        } else {
          if (service === 'Maintenance' || service === 'Walk') {
            return {
              [`${serviceName} Asigned`]: e[`${serviceName}_assigned`]
                ? e[`${serviceName}_assigned`]
                : 'N/A',
              [`${serviceName} Complete`]: e[`${serviceName}_complete`]
                ? e[`${serviceName}_complete`]
                : 'N/A',
              [`${serviceName} Status`]: status
            }
          }

          if (service === 'Sparkle' || service === 'Punch') {
            return {
              [`${serviceName} Team`]: e[`${serviceName}_team`] || '-',
              [`${serviceName} Option`]: e[`${serviceName}_option`]
                ? e[`${serviceName}_option`].replaceAll(',', ' -')
                : '-',
              [`${serviceName} Status`]: status,
              [`${serviceName} Asigned`]: e[`${serviceName}_assigned`]
                ? e[`${serviceName}_assigned`]
                : 'N/A',
              [`${serviceName} Complete`]: e[`${serviceName}_complete`]
                ? e[`${serviceName}_complete`]
                : 'N/A'
            }
          }

          return {
            [`${serviceName} Team`]: e[`${serviceName}_team`] || '-',
            [`${serviceName} Option`]: e[`${serviceName}_option`]
              ? e[`${serviceName}_option`].replaceAll(',', ' -')
              : '-',
            [`${serviceName} Status`]: status,
            [`${serviceName} Asigned`]: e[`${serviceName}_assigned`]
              ? e[`${serviceName}_assigned`]
              : 'N/A',
            [`${serviceName} Complete`]: e[`${serviceName}_complete`]
              ? e[`${serviceName}_complete`]
              : 'N/A',
            [`${serviceName} Estimated Cost`]: e[
              `${serviceName}_estimated_cost`
            ],
            [`${serviceName} Additional Cost`]: e[
              `${serviceName}_additional_cost`
            ],
            [`${serviceName} Final Cost`]: e[`${serviceName}_final_cost`]
          }
        }
      })

      const items = Object.assign({}, ...serviceItems)

      return {
        Building: building,
        Floor: floor,
        Unit: unit,
        Space: unit_space,
        Group: grouping,
        'Current Lease Status': current_lease_status,
        'Future Lease Status': lease_status,
        'Current Resident': current_resident,
        'Future Resident': future_resident,
        'CA Needed': isFirstSpaceRow ? ca_needed : '-',
        Floorplan: floorplan.replaceAll(',', ' -'),
        Notes: has_note ? 'Yes' : 'No',
        'Early Move In': early_move_in ? early_move_in : 'N/A',
        'Scheduled Move Out': scheduled_move_out ? scheduled_move_out : 'N/A',
        'Actual Move Out': actual_move_out ? actual_move_out : 'N/A',
        ...items
      }
    })

    const csvData = [Object.keys(newData[0] || []).map(key => capitalize(key))]
      .concat((newData || []).map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-inspect-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onHandleChangeServiceOptions = (
    units,
    selectedUnitRows,
    activeFilters,
    activeItems,
    services,
    user,
    context
  ) => {
    let reConstructTableRow = []
    const rows = tableRef.current?.getRows() || []

    if ((rows || []).length !== 0) {
      reConstructTableRow = (rows || [])
        .filter(row => {
          const isCaNeededIsNA =
            row.ca_needed === 'N/A' && row.unit_space === 'CA'
          const isRenewal = row?.lease_status === 'Renewal'
          const shouldFilter =
            !row.is_asis &&
            !isRenewal &&
            !isCaNeededIsNA &&
            !row?.isUnitSpaceCompleted &&
            !row?.areAllOverviewStatusesGreen

          return shouldFilter
        })
        .map(row => {
          const { unit, unit_id, unit_space } = row || {}
          return {
            unit,
            unit_id,
            unit_space
          }
        })
    }

    if ((selectedRows || []).length !== 0) {
      reConstructTableRow = []
      reConstructTableRow = (selectedRows || [])
        .filter(filterData => {
          const { original } = filterData || {}
          const {
            lease_status,
            is_asis,
            ca_needed,
            unit_space,
            isUnitSpaceCompleted,
            areAllOverviewStatusesGreen
          } = original || {}

          const isCaNeededIsNA = ca_needed === 'N/A' && unit_space === 'CA'
          const isRenewal = lease_status === 'Renewal'
          const shouldFilter =
            !is_asis &&
            !isRenewal &&
            !isCaNeededIsNA &&
            !isUnitSpaceCompleted &&
            !areAllOverviewStatusesGreen

          return shouldFilter
        })
        .map(row => {
          const { original } = row || {}
          const { unit, unit_space, unit_id } = original || {}
          return {
            unit,
            unit_id,
            unit_space
          }
        })
    }

    setModalContent(() => (
      <TurnboardChangeServiceOptionsModal
        units={units}
        allService={(services || []).filter(d => d?.servicetype !== 'Walk')}
        selectedUnitIds={reConstructTableRow}
        user={user}
        context={context}
        reCallTurnboardData={reCallTurnboardData}
        updateServiceOptions={updateServiceOptions}
        isFilterApplied={
          (reConstructTableRow || []).length !== 0 ||
          (selectedRows || []).length !== 0 ||
          (rows || []).length !== 0
        }
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '1200px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-change-service-options-modal'
    })
  }

  const { shouldMarkAsIs, shouldUnmarkAsIs, hasRenewalSpace } = useMemo(() => {
    const rows = (selectedRows || []).map(row => row?.original)
    const hasRenewalSpace = rows.length
      ? rows.some(unit => unit.lease_status === 'Renewal')
      : false

    return {
      shouldMarkAsIs: rows.length
        ? rows.every(unit => !unit.is_asis) && !hasRenewalSpace
        : false,
      shouldUnmarkAsIs: rows.length ? rows.every(unit => unit.is_asis) : false,
      hasRenewalSpace
    }
  }, [selectedRows])

  const handleOpenMarkAsIsModal = () => {
    const shouldMarkOrUnmark = shouldMarkAsIs || shouldUnmarkAsIs

    if (shouldMarkOrUnmark) {
      const rows = (selectedRows || []).map(row => row?.original)

      const dataToSubmit = rows.map(unit => ({
        unit_space_id: unit.unit_space_id,
        as_is: shouldMarkAsIs ? 'True' : null
      }))

      setModalContent(() => (
        <TurnboardMarkAsIsModal
          shouldMarkAsIs={shouldMarkAsIs}
          pdbid={pdbid}
          context={context}
          updateAsIsStatus={updateAsIsStatus}
          dataToSubmit={dataToSubmit}
          onCancel={closeModal}
          onConfirm={() => {
            closeModal()
            getTurnboard2Data()
          }}
        />
      ))
      openModal({
        width: '500px',
        maxWidth: '100%',
        additionalClasses: 'mark-as-is-modal'
      })
    }
  }

  const { shouldGroupUnits, shouldUngroupUnits } = useMemo(() => {
    const rows = (selectedRows || []).map(row => row?.original)

    return {
      shouldGroupUnits: rows.length
        ? rows.every(unit => !unit.grouping)
        : false,
      shouldUngroupUnits: rows.length
        ? rows.every(unit => unit.grouping)
        : false
    }
  }, [selectedRows])

  const handleOpenGroupUnitsModal = () => {
    const shouldGroupOrUngroupUnits = shouldGroupUnits || shouldUngroupUnits

    if (shouldGroupOrUngroupUnits) {
      const rows = (selectedRows || []).map(row => row?.original)
      const unitIds = (rows || []).map(row => row.unit_id)

      const filteredUnitIds = (unitIds || []).filter(
        (id, index) => (unitIds || []).indexOf(id) === index
      )

      setModalContent(() => (
        <TurnboardGroupUnitsModal
          shouldGroupUnits={shouldGroupUnits}
          getAllGroups={getAllGroups}
          groupOrUngroupUnits={groupOrUngroupUnits}
          pdbid={pdbid}
          context={context}
          unitIds={filteredUnitIds}
          onCancel={closeModal}
          onConfirm={() => {
            closeModal()
            getTurnboard2Data()
          }}
        />
      ))
      openModal({
        width: '550px',
        maxWidth: '100%',
        additionalClasses: 'group-units-modal'
      })
    }
  }

  const {
    shouldOverrideLease,
    shouldUndoOverrideLease,
    isMarkedAsIsOrIsCommonArea,
    singleRow
  } = useMemo(() => {
    const rows = (selectedRows || []).map(row => row?.original)
    const { is_asis, unit_space, is_override, override_status } = rows[0] || {}
    const isOverrideLeaseStatus =
      (is_override || '').toLowerCase() === 'true' && override_status

    const isSingleRow = rows.length && rows.length === 1
    const isMarkedAsIs = isSingleRow && is_asis ? true : false
    const isCommonArea = isSingleRow && unit_space === 'CA' ? true : false
    const isMarkedAsIsOrIsCommonArea = isMarkedAsIs || isCommonArea

    return {
      shouldOverrideLease:
        isSingleRow && !isMarkedAsIsOrIsCommonArea && !isOverrideLeaseStatus
          ? true
          : false,
      shouldUndoOverrideLease:
        isSingleRow && !isMarkedAsIsOrIsCommonArea && isOverrideLeaseStatus
          ? true
          : false,
      isMarkedAsIsOrIsCommonArea,
      singleRow: isSingleRow ? rows[0] : {}
    }
  }, [selectedRows])

  const handleOpenLeaseOverrideModal = () => {
    const shoulOverrideOrUndoLeaseStatus =
      shouldOverrideLease || shouldUndoOverrideLease
    const selectedUnit = singleRow

    if (shoulOverrideOrUndoLeaseStatus) {
      setModalContent(() => (
        <TurnboardLeaseOverrideModal
          pdbid={pdbid}
          context={context}
          onCancel={closeModal}
          shouldOverrideLease={shouldOverrideLease}
          shouldUndoOverrideLease={shouldUndoOverrideLease}
          selectedUnit={selectedUnit}
          updateLeaseOverride={updateLeaseOverride}
          onConfirm={() => {
            closeModal()
            getTurnboard2Data()
          }}
        />
      ))

      openModal({
        width: '550px',
        maxWidth: '100%',
        additionalClasses: 'lease-override-modal'
      })
    }
  }

  const {
    markEnableServices,
    unMarkEnableServices,
    anyServiceStatusIsApproved
  } = useMemo(() => {
    const rows = (selectedRows || []).map(row => row?.original)

    const markEnableServices =
      rows.length &&
      rows.every(
        ({ enable_service, unit_space, ca_needed, lease_status }) =>
          !enable_service &&
          (lease_status === 'Renewal' ||
            (ca_needed === 'N/A' && unit_space === 'CA'))
      )

    const anyServiceStatusIsApproved = (rows || []).some(row => {
      return (services || []).some(({ servicetype }) => {
        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        return row[`${serviceName}_status`] === 'Approved'
      })
    })

    const unMarkEnableServices =
      rows.length && rows.every(({ enable_service }) => enable_service)

    return {
      markEnableServices,
      unMarkEnableServices,
      anyServiceStatusIsApproved
    }
  }, [selectedRows])

  const handleOpenEnableServicesModal = () => {
    const shouldMarkOrUnmarkEnableServices =
      markEnableServices ||
      (unMarkEnableServices && !anyServiceStatusIsApproved)

    if (shouldMarkOrUnmarkEnableServices) {
      const rows = (selectedRows || []).map(row => row?.original)

      const dataToSubmit = rows.map(unit => ({
        unit_space_id: unit.unit_space_id,
        enable_service: markEnableServices ? 'True' : null
      }))

      setModalContent(() => (
        <TurnboardEnableServicesModal
          pdbid={pdbid}
          context={context}
          onCancel={closeModal}
          updateEnableServices={updateEnableServices}
          shouldMarkEnableServices={markEnableServices}
          dataToSubmit={dataToSubmit}
          onConfirm={() => {
            closeModal()
            getTurnboard2Data()
          }}
        />
      ))

      openModal({
        width: '550px',
        maxWidth: '100%',
        additionalClasses: 'enable-services-modal'
      })
    }
  }

  const { shouldMarkInHouse, shouldUnmarkInHouse } = useMemo(() => {
    const rows = (selectedRows || []).map(row => row?.original)
    const isAnySpaceIsCompleted = rows.length
      ? rows.some(row => row.isUnitSpaceCompleted)
      : false

    return {
      shouldMarkInHouse: rows.length
        ? rows.every(unit => !unit.is_inhouse) &&
          !hasRenewalSpace &&
          !isAnySpaceIsCompleted
        : false,
      shouldUnmarkInHouse: rows.length
        ? rows.every(unit => unit.is_inhouse)
        : false
    }
  }, [selectedRows, hasRenewalSpace])

  const handleOpenInHouseModal = () => {
    const rows = (selectedRows || []).map(row => row?.original)

    setModalContent(() => (
      <TurnboardInHouseModal
        services={services}
        rows={rows}
        pdbid={pdbid}
        context={context}
        shouldMarkInHouse={shouldMarkInHouse}
        markInHouse={markInHouse}
        unMarkInHouse={unMarkInHouse}
        onCancel={closeModal}
        onConfirm={() => {
          closeModal()
          getTurnboard2Data()
        }}
      />
    ))

    openModal({
      width: shouldMarkInHouse ? '900px' : '550px',
      maxWidth: '100%',
      additionalClasses: 'in-house-modal'
    })
  }

  const handleOpenInHouseInterfaceModal = () => {
    const rows = (tableRef.current?.getRows() || []).filter(
      row => row.is_inhouse
    )

    setModalContent(() => (
      <TurnboardInHouseInterfaceModal
        services={services}
        rows={rows}
        pdbid={pdbid}
        context={context}
        user={user}
        onCancel={closeModal}
        markInHouse={markInHouse}
        unMarkInHouse={unMarkInHouse}
        markAndUnMarkInHouse={markAndUnMarkInHouse}
        onConfirm={() => {
          closeModal()
          getTurnboard2Data()
        }}
      />
    ))

    openModal({
      width: '900px',
      maxWidth: '100%',
      additionalClasses: 'in-house-modal'
    })
  }

  useEffect(() => {
    getTurnboardDataFilter({
      filters: activeFilters || []
    })
  }, [activeFilters])

  const onFilterRemove = ({ key, value } = {}) => {
    const newFilters = { ...activeFilters }
    if (newFilters[key] && Array.isArray(newFilters[key])) {
      newFilters[key] = _.without(
        newFilters[key],
        value.indexOf(': ') > 1 ? value.split(': ')[1] : value
      )
      if (!newFilters[key].length) {
        delete newFilters[key]
      }
    } else {
      delete newFilters[key]
    }

    setActiveFilters(newFilters)
  }

  const handleHideColumns = (columns, collapsed) => {
    if (collapsed) {
      const filteredColumns = (columns || []).filter(
        column => !(hiddenColumns || []).includes(column)
      )
      const finalColumns = [...hiddenColumns, ...filteredColumns]
      setHiddenColumns(finalColumns)
    } else {
      const filteredColumns = (hiddenColumns || []).filter(
        column => !(columns || []).includes(column)
      )
      setHiddenColumns(filteredColumns)
    }
  }

  const tableColumns = useMemo(() => {
    return headerData({
      services,
      notes,
      addNote,
      isAllColumnCollapsed: isAllColumnCollapsed,
      manageTags,
      isTurnboardOverview,
      onHideColumns: handleHideColumns,
      hiddenColumns
    })
  }, [
    services,
    notes,
    addNote,
    isAllColumnCollapsed,
    manageTags,
    isTurnboardOverview,
    hiddenColumns
  ])

  const columns = useMemo(() => tableColumns)

  const data = useMemo(() => units || [], [units])

  const [
    scheduleFollowOrderOperation,
    setScheduleFollowOrderOperation
  ] = useState(true)

  const prevService = useMemo(() => {
    if (!services) return null
    if ((selectedScheduleDate || []).length !== 0) {
      return (services || []).find(
        (service, index) =>
          services[index + 1] &&
          selectedScheduleDate[0]?.servicetype_name ===
            services[index + 1].servicetype
      )
    }
  }, [services, selectedScheduleDate])

  const enableManualSelection = useMemo(() => {
    return (selectedScheduleDate || []).length !== 0
  }, [selectedScheduleDate])

  const prevServiceName = useMemo(() => {
    return !_.isEmpty(prevService) ? prevService?.servicetype : ''
  }, [prevService])

  const filteredServices = useMemo(() => {
    return (services || []).filter(
      service =>
        service?.servicetype !== selectedScheduleDate[0]?.servicetype_name
    )
  }, [services, selectedScheduleDate])

  const unitData = useMemo(() => {
    if ((selectedScheduleDate || []).length !== 0) {
      const scheduleUnitData = (data || []).map(unit => {
        const selected_service_name = (
          selectedScheduleDate[0]?.servicetype_name || ''
        )
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        const selectedServiceCompletedDate =
          unit[`${selected_service_name}_complete`]
        const prev_service_name = (prevServiceName || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        const prevServiceAssignedDate = unit[`${prev_service_name}_assigned`]
        const leaseStatus = unit['lease_status']
        const {
          is_asis,
          ca_needed,
          unit_space,
          isUnitSpaceCompleted,
          areAllOverviewStatusesGreen
        } = unit

        const service_name = (selectedScheduleDate[0]?.servicetype_name || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        const currentServiceExists = unit[`${service_name}_task_id`]
        const date = moment(selectedScheduleDate[0].date).format('MM/DD/YYYY')

        const isRenewal = leaseStatus === 'Renewal'
        const caNeededIsNA = ca_needed === 'N/A' && unit_space === 'CA'

        const isGreen =
          isRenewal ||
          caNeededIsNA ||
          is_asis ||
          isUnitSpaceCompleted ||
          areAllOverviewStatusesGreen

        if (!scheduleFollowOrderOperation) {
          const canSchedule = currentServiceExists
            ? !moment(selectedServiceCompletedDate).isValid()
            : false

          return {
            ...unit,
            canSchedule: isGreen ? false : canSchedule
          }
        }

        const canSchedule = currentServiceExists
          ? !moment(selectedServiceCompletedDate).isValid() ||
            moment(prevServiceAssignedDate).isBefore(date)
          : false

        return {
          ...unit,
          canSchedule: isGreen ? false : canSchedule
        }
      })

      const scheduleUnitDataWithUnitCount = (scheduleUnitData || []).map(
        unitData => {
          const unit_count = (scheduleUnitData || []).reduce(
            (acc, curr) =>
              curr?.unit_id === unitData?.unit_id && curr?.canSchedule
                ? acc + 1
                : acc,
            0
          )
          return {
            ...unitData,
            unit_count
          }
        }
      )
      return scheduleUnitDataWithUnitCount
    }
    return data
  }, [
    data,
    selectedScheduleDate,
    scheduleFollowOrderOperation,
    filteredServices
  ])

  const filteredUnitData = useMemo(() => {
    const result = unitData || []

    if (showUnscheduledUnit && (selectedScheduleDate || []).length !== 0) {
      const selected_service_name = (
        selectedScheduleDate[0]?.servicetype_name || ''
      )
        .toString()
        .replace(' ', '_')
        .toLowerCase()

      return result.filter(unit => {
        const selectedServiceAssignedDate =
          unit[`${selected_service_name}_assigned`]

        const isInHouseService = !_.isEmpty(
          unit[`${selected_service_name}_inhouse_id`]
        )

        return (
          unit?.canSchedule &&
          !isInHouseService &&
          !moment(selectedServiceAssignedDate).isValid()
        )
      })
    } else if (showScheduledUnit && service) {
      const selected_service_name = (service || '')
        .toString()
        .replace(' ', '_')
        .toLowerCase()

      return result.filter(unit => {
        const selectedServiceAssignedDate =
          unit[`${selected_service_name}_assigned`]
        const selectedServiceStatus = (
          unit[`${selected_service_name}_status`] || ''
        )
          .toLowerCase()
          .trim()

        const isScheduled = moment(selectedServiceAssignedDate).isValid()

        return (
          isScheduled &&
          (selectedServiceStatus === 'not assigned' ||
            selectedServiceStatus === 'assigned' ||
            selectedServiceStatus === 'n/a' ||
            !selectedServiceStatus)
        )
      })
    }

    if (!showCompleted) {
      return result.filter(({ unit_id }) => {
        const fullUnit = (unitData || []).filter(
          data => data.unit_id === unit_id
        )

        const isCompleted = (fullUnit || []).every(
          ({
            lease_status,
            unit_space,
            ca_needed,
            is_asis,
            isUnitSpaceCompleted,
            isInHouseCompleted,
            areAllOverviewStatusesGreen,
            is_inhouse
          }) =>
            lease_status === 'Renewal' ||
            isUnitSpaceCompleted ||
            isInHouseCompleted ||
            (areAllOverviewStatusesGreen && !is_inhouse) ||
            (is_asis && !is_inhouse) ||
            (unit_space === 'CA' && ca_needed === 'N/A' && !is_inhouse)
        )

        return !isCompleted
      })
    }
    return result
  }, [
    unitData,
    selectedScheduleDate,
    showUnscheduledUnit,
    showScheduledUnit,
    service,
    showCompleted
  ])

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => (o.value || []).length)
  }, [isFilterApplied])

  const allColumnKeys = useMemo(() => {
    if (isAllColumnCollapsed) {
      const serviceColumns = (services || []).map(service => {
        const { label, servicetype } = service || {}
        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        if (
          label === 'Sparkle' ||
          label === 'Maintenance' ||
          label === 'Punch' ||
          label === 'Walk'
        ) {
          return [
            `${serviceName}_option`,
            `${serviceName}_team`,
            `${serviceName}_complete`,
            `${serviceName}_status`
          ]
        }
        return [
          `${serviceName}_option`,
          `${serviceName}_team`,
          `${serviceName}_complete`,
          `${serviceName}_final_cost`,
          `${serviceName}_additional_cost`,
          `${serviceName}_estimated_cost`,
          `${serviceName}_status`
        ]
      })

      return [
        'paint_overview_status',
        'punch_overview_status',
        'cleaning_overview_status',
        'carpet_clean_overview_status',
        'carpet_replace_overview_status',
        'concrete/vinyl_replace_overview_status',
        'sparkle_overview_status',
        'lease_status',
        'ca_needed',
        'floorplan',
        'has_note',
        'simplifiedTags',
        'scheduled_move_out',
        'actual_move_out',
        ...(serviceColumns || []).flatMap(service => service)
      ]
    }

    return columnKeys
  }, [isAllColumnCollapsed, columnKeys])
  const { exportIcon, note, showMore, questionMarkIcon } = icons || {}

  useEffect(() => {
    if (isAllColumnCollapsed) {
      setHiddenColumns(allColumnKeys)
    } else {
      setHiddenColumns([])
    }
  }, [isAllColumnCollapsed, allColumnKeys])

  const onHandleScheduleBox = () => {
    setSelectedSchedulteDate([])
    setIsScheduleOpen(false)
  }

  const onHandleSelectedScheduleDate = data => {
    setSelectedSchedulteDate(data)
    setService('')
  }

  const onHandleScheduleOrderOfOperation = data => {
    setScheduleFollowOrderOperation(data)
  }

  const onHandleShowUnscheduledUnit = value => {
    if (value) {
      setShowScheduledUnit(false)
    }
    setShowUnscheduledUnit(value)
  }

  const onHandleShowScheduledUnit = value => {
    if (value) {
      setShowUnscheduledUnit(false)
    }
    setShowScheduledUnit(value)
  }

  const onResetFilter = () => tableRef.current?.clearFilter([])

  const onFilterScheduledUnit = service => setService(service)

  const scheduleProps = {
    services: (services || []).filter(d => d?.servicetype !== 'Walk'),
    getScheduleByService,
    user,
    context,
    turnboard,
    onHandleScheduleBox,
    onHandleSelectedScheduleDate,
    selectedRows,
    allRows: tableRef.current?.getRows(),
    selectedCapacity: capacity,
    updateServiceSchedule,
    onHandleScheduleOrderOfOperation,
    scheduleFollowOrderOperation,
    reCallTurnboardData,
    onHandleShowUnscheduledUnit,
    showUnscheduledUnit,
    onHandleShowScheduledUnit,
    showScheduledUnit,
    onFilterScheduledUnit
  }

  return (
    <>
      {isScheduleOpen ? <TurnboardSchedule {...scheduleProps} /> : null}
      <div
        id="turnboard-container"
        className={`turnboard-container ${
          isScheduleOpen ? 'turnboard-container-spacing' : ''
        }`}>
        <div className="turnboard-header-wrapper">
          <div className="left">
            <h2>
              {isTurnboardOverview ? 'Turnboard Overview' : 'Turnboard Details'}
            </h2>
            <a
              href="#"
              className={`toggle-link ${
                isTurnboardOverview ? 'less-margin' : ''
              }`}
              onClick={e => {
                e.preventDefault()
                setIsTurnboardOverview(!isTurnboardOverview)
              }}>
              {isTurnboardOverview ? 'Switch to Details' : 'Switch to Overview'}
            </a>
            {!isTurnboardOverview && (
              <>
                <PermissionGate name={'turnboard-actions'}>
                  <button
                    className="button"
                    style={{ backgroundColor: isScheduleOpen && '#E5E9F2' }}
                    onClick={e => {
                      e.preventDefault()
                      setIsScheduleOpen(!isScheduleOpen)
                      setSelectedSchedulteDate([])
                    }}>
                    Open Scheduling Tool
                  </button>

                  <button
                    className="button"
                    onClick={e => {
                      e.preventDefault()
                      setIsScheduleOpen(false)
                      setSelectedSchedulteDate([])
                      onHandleChangeServiceOptions(
                        data,
                        selectedRows,
                        activeFilters,
                        units,
                        services,
                        user,
                        context
                      )
                    }}>
                    Change Service Options
                  </button>
                </PermissionGate>
                {!in_house && (
                  <button
                    className="button"
                    onClick={handleOpenInHouseInterfaceModal}>
                    In-House Services
                  </button>
                )}
              </>
            )}
            <div
              className={`center ${
                isTurnboardOverview ? 'remove-margin' : ''
              }`}>
              <TurnboardFilters
                customFilters={customFilters}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
              <button className="button" onClick={exportCSV}>
                <img src={exportIcon} alt="export-icon" />
                Export
              </button>

              {isTurnboardOverview && (
                <Tippy
                  className="symbols"
                  content={<SymbolToolTip />}
                  placement="bottom-start"
                  allowHTML
                  theme="light">
                  <img
                    src={questionMarkIcon}
                    className="question-icon"
                    alt="Symbol Names"
                  />
                </Tippy>
              )}

              {!isTurnboardOverview && (
                <>
                  <button className="button" onClick={viewAllNotes}>
                    <img className="custom-icon" src={note} alt="note-icon" />
                    View Notes
                  </button>
                  <PermissionGate name={'turnboard-actions'}>
                    <div className="dropdown-action-button">
                      <ShowMoreItemsButton
                        icon={showMore}
                        shouldMarkAsIs={shouldMarkAsIs}
                        shouldUnmarkAsIs={shouldUnmarkAsIs}
                        hasRenewalSpace={hasRenewalSpace}
                        shouldGroupUnits={shouldGroupUnits}
                        shouldUngroupUnits={shouldUngroupUnits}
                        shouldOverrideLease={shouldOverrideLease}
                        shouldUndoOverrideLease={shouldUndoOverrideLease}
                        isMarkedAsIsOrIsCommonArea={isMarkedAsIsOrIsCommonArea}
                        markEnableServices={markEnableServices}
                        unMarkEnableServices={unMarkEnableServices}
                        anyServiceStatusIsApproved={anyServiceStatusIsApproved}
                        shouldMarkInHouse={shouldMarkInHouse}
                        shouldUnmarkInHouse={shouldUnmarkInHouse}
                        showInHouse={!in_house}
                        onMarkOrUnmarkAsIs={handleOpenMarkAsIsModal}
                        onGroupOrUngroupUnits={handleOpenGroupUnitsModal}
                        onLeaseOverrideOrUndo={handleOpenLeaseOverrideModal}
                        onMarkOrUnMarkEnableServices={
                          handleOpenEnableServicesModal
                        }
                        onMarkOrUnMarkInHouse={handleOpenInHouseModal}
                      />
                    </div>
                  </PermissionGate>
                </>
              )}
            </div>
          </div>
          <div className="right">
            {(checkIsFilterApplied || []).length !== 0 && (
              <div className="clearBtnWrapper">
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="applied-filters-wrapper">
          <div>
            {(Object.keys(activeFilters) || {}).length > 0 && (
              <AppliedFilters
                className="pill-container is-mobile"
                activeFilters={activeFilters}
                customFilters={customFilters}
                definitions={'header'}
                onFilterRemove={onFilterRemove}
              />
            )}
          </div>
          <div className="toggle-section">
            <ToggleButton
              label="Show Completed"
              onChange={state => {
                setShowCompleted(state)
              }}
              isActive={showCompleted}
            />
            <ToggleColumnFilters
              onChange={state => {
                setFilter(state)
              }}
              isActive={canFilter}
            />
            <ToggleColumns
              onChange={state => {
                setAllColumnCollapsed(state)
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <BasicLoading />
        ) : (
          <div>
            <Styles>
              <Table
                ref={tableRef}
                columns={columns}
                data={filteredUnitData}
                onRowSelectStateChange={setSelectedRows}
                capacity={capacity}
                totalCapacity={selectedScheduleDate[0]?.total_capacity}
                enableManualSelection={enableManualSelection}
                isAllColumnCollapsed={isAllColumnCollapsed}
                canFilter={canFilter}
                isScheduleOpen={isScheduleOpen}
                setIsFilterApplied={setIsFilterApplied}
                hiddenColumns={hiddenColumns}
                context
              />
            </Styles>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(Turnboard2Component)
