import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import QcHeader from '../qc-header'
import BigLoading from '../../components/big-loading'
import MobileEmptyState from '../../components/mobile-empty-state'
import VendorUserHeader from '../../components/vendor-user-header'
import MobileWorkTypeTitle from '../../components/mobile-work-type-title'
import MobileWorkCard from '../../components/mobile-work-card'
import { noWork, refresh } from '../../ui/icons'
import { getMyWork } from '../../modules/qc'
import { updateContext } from '../../modules/context'
import { toggleSidebar } from '../../modules/sidebar'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import ColumnSorting from '../../components/app-sorting'

import './index.scss'

const selectStyles = {
  input: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  placeholder: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  control: (styles, state) => ({
    ...styles,
    minHeight: 23,
    maxHeight: '2.5em',
    cursor: 'pointer',
    height: '2.5em',
    border: '1px solid #e5e9f2',
    borderRadius: 2,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #e5e9f2'
    },
    backgroundColor: state.isDisabled ? 'ghostwhite' : styles.backgroundColor
  }),
  container: styles => ({
    ...styles,
    marginTop: '-3px'
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 20,
    width: '100%',
    padding: 0,
    position: 'relative'
  }),
  singleValue: styles => ({
    ...styles,
    marginLeft: '10px',
    marginBottom: '3px'
  }),
  indicatorContainer: styles => ({
    ...styles,
    paddingTop: '4px'
  })
}

function QcWork({
  qc,
  user,
  context,
  getMyWork,
  toggleSidebar,
  updateContext
}) {
  const history = useHistory()
  const [selectedUnit, setSelectedUnit] = useState('')
  const [selectedService, setSelectedService] = useState('')
  const [newJobs, setNewJobs] = useState([])
  const [sorting, setSorting] = useState({ key: '', ascending: true })

  const { jobs, isRequesting } = qc
  const { staffid, pdbid } = user.user_metadata
  const [selected, setSelected] = React.useState({
    project: 'select-one',
    property: 'select-one'
  })

  useEffect(() => {
    const { project, property } = context

    if (project && property) {
      setSelected({
        ...selected,
        project: project || 'select-one',
        property: property || 'select-one'
      })
    }

    if (project && property && pdbid && staffid && !isRequesting) {
      getMyWork(pdbid, { project, property }, staffid)
    }
  }, [context, user])

  const backJobs = jobs.filter(
    o => o.UnitSpaces_goback && o.UnitSpaces_goback.length
  )

  useEffect(() => {
    const newJobs = jobs.filter(
      o => !o.UnitSpaces_goback || !o.UnitSpaces_goback.length
    )
    setNewJobs(newJobs)
  }, [jobs])

  const hasJobs = !!backJobs.length || !!newJobs.length

  const handleClick = job => {
    history.push(`/qc/job/${job.workorder}`, { job })
  }

  useEffect(() => {
    let defaultNewJobs = jobs || []
    if (selectedUnit?.unit && selectedService?.service) {
      defaultNewJobs = (defaultNewJobs || []).filter(
        o =>
          (!o.UnitSpaces_goback || !o.UnitSpaces_goback.length) &&
          (o.unit === selectedUnit?.unit &&
            o.service === selectedService?.service)
      )
    } else if (selectedUnit?.unit || selectedService?.service) {
      defaultNewJobs = (defaultNewJobs || []).filter(
        o =>
          (!o.UnitSpaces_goback || !o.UnitSpaces_goback.length) &&
          (o.unit === selectedUnit?.unit ||
            o.service === selectedService?.service)
      )
    } else {
      defaultNewJobs = (defaultNewJobs || []).filter(
        o => !o.UnitSpaces_goback || !o.UnitSpaces_goback.length
      )
    }
    setNewJobs(defaultNewJobs)
  }, [selectedUnit, selectedService])

  const getDefaultJobs = (jobs || []).filter(
    o => !o.UnitSpaces_goback || !o.UnitSpaces_goback.length
  )

  const handleSelectChange = key => e => {
    const newSelected = {
      ...selected,
      [key]: e.target.value === 'select-one' ? '' : e.target.value
    }
    updateContext(newSelected.project, newSelected.property)
  }

  const getSelector = (key = 'projects') => {
    const isDisabled = selected.project === 'select-one'
    let options = user.user_metadata[key]

    const singular = {
      projects: 'project',
      properties: 'property'
    }

    if (key === 'properties') {
      const { projects } = user.user_metadata
      const selectedProject = projects.find(p => p.id === selected.project)

      if (typeof selectedProject !== 'undefined') {
        options = options.filter(o => {
          const propertyInProject = selectedProject.properties.find(
            p => p === o.id
          )
          return typeof propertyInProject !== 'undefined'
        })
      }
    }

    let template = []
    for (let i = 0; i < options.length; i += 1) {
      const o = options[i]
      template.push(
        <option key={`${key}-${i}`} value={o.id}>
          {o.name}
        </option>
      )
    }

    let value = selected[singular[key]]
    if (value === null) value = 'select-one'
    return (
      <div className="select is-fullwidth">
        <select
          className="is-fullwidth input"
          disabled={isDisabled ? 'disabled' : ''}
          onChange={handleSelectChange(singular[key])}
          value={value}>
          <option value="select-one">Select one...</option>
          {template}
        </select>
      </div>
    )
  }

  const renderHeaderAction = () => {
    return (
      <div
        onClick={() => {
          getMyWork(pdbid, selected, staffid)
          setSelectedUnit('')
          setSelectedService('')
          setSorting({ key: '', ascending: false })
        }}>
        <img src={refresh} alt="refresh" />
      </div>
    )
  }

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending })
  }

  useEffect(() => {
    if (sorting?.key) {
      if (sorting?.ascending) {
        const myData = []
          .concat(newJobs || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() <
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setNewJobs(myData)
      } else {
        const myData = []
          .concat(newJobs || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() >
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setNewJobs(myData)
      }
    }
  }, [sorting])

  return (
    <div className="qc-work is-fullheight">
      <QcHeader
        title="My Work"
        toggleSidebar={toggleSidebar}
        renderActions={renderHeaderAction}
      />

      <div className="qc-work-content">
        <div className="columns is-mobile p-t-sm">
          <div className="column is-half">
            <label htmlFor="project" className="is-size-5">
              Project
            </label>
            {getSelector('projects')}
          </div>
          <div className="column is-half">
            <label htmlFor="property" className="is-size-5">
              Property
            </label>
            {getSelector('properties')}
          </div>
        </div>
        {isRequesting ? (
          <BigLoading />
        ) : (
          <>
            <div className="qc-work-header">
              {hasJobs ? (
                <VendorUserHeader
                  title="Hey, QC Team!"
                  message="Here are your current work orders."
                />
              ) : (
                <MobileEmptyState
                  image={noWork}
                  title="You’re all caught up."
                  subtitle="All work orders assigned to you will be listed here."
                />
              )}
            </div>

            {backJobs.length ? (
              <>
                <MobileWorkTypeTitle title="GO-BACKS" />
                <div className="columns is-mobile is-multiline qc-work-cards">
                  {backJobs.map(order => (
                    <MobileWorkCard
                      order={order}
                      handleClick={handleClick}
                      key={order.workorder}
                    />
                  ))}
                </div>
              </>
            ) : null}

            {newJobs.length ? (
              <div className="columns is-mobile p-t-sm">
                <div className="column is-half">
                  <div className="columns">
                    <div className="column is-half">
                      <label htmlFor="unit" className="is-size-5">
                        Unit
                        <ColumnSorting
                          data={sorting}
                          applySorting={applySorting}
                          type="unit"
                        />
                      </label>
                      <MultiSelectDropdown
                        displayKey="unit"
                        value={selectedUnit}
                        defaultValues={newJobs}
                        onChange={v => setSelectedUnit(v)}
                        isClearable={true}
                        isMulti={false}
                        placeholder="Select Unit"
                        noOptionsMessage="No Unit Available"
                        customStyles={selectStyles}
                      />
                    </div>
                    <div className="column is-half">
                      <label htmlFor="unit" className="is-size-5">
                        Service
                        <ColumnSorting
                          data={sorting}
                          applySorting={applySorting}
                          type="service"
                        />
                      </label>
                      <MultiSelectDropdown
                        displayKey="service"
                        value={selectedService}
                        isClearable={true}
                        defaultValues={_.uniqBy(
                          newJobs || [],
                          obj => obj?.service
                        )}
                        onChange={v => setSelectedService(v)}
                        isMulti={false}
                        placeholder="Select Service"
                        noOptionsMessage="No Service Available"
                        customStyles={selectStyles}
                      />
                    </div>
                  </div>
                </div>
                {(selectedService?.service ||
                  selectedUnit?.unit ||
                  sorting?.key) && (
                  <div className="column is-half">
                    <label
                      htmlFor="clear"
                      style={{ color: 'white' }}
                      className="is-size-5">
                      Clear
                    </label>
                    <div>
                      <button
                        style={{ marginTop: '-3px' }}
                        onClick={() => {
                          setSelectedUnit('')
                          setSelectedService('')
                          setSorting({ key: '', ascending: false })
                          setNewJobs(jobs)
                        }}
                        className="button is-primary">
                        Clear All Filter(s)
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {newJobs.length ? (
              <>
                <MobileWorkTypeTitle title="NEW" />
                <div className="columns is-mobile is-multiline qc-work-cards">
                  {newJobs.map(order => (
                    <MobileWorkCard
                      order={order}
                      handleClick={handleClick}
                      key={order.workorder}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

QcWork.propTypes = {
  qc: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  updateContext: PropTypes.func,
  toggleSidebar: PropTypes.func
}

const mapStateToProps = ({ user, context, qc }) => ({
  qc,
  user,
  context
})

const mapDispatchToProps = {
  getMyWork,
  toggleSidebar,
  updateContext
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcWork)
