import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { setup, inspections, analytics } from 'ui/icons'
import Breadcrumbs from 'parents/breadcrumbs'
import DashboardBox from 'components/dashboard-box'
import { boxValues } from './box-values.js'
import * as section from '../../utils/sectionConstants'
import './index.scss'
import ErrorMessage from '../../components/forms-helper/ErrorMessage.js'
import { checkPermission, isMobile } from '../../utils/helperFunctions.js'
import useAppRoutes from '../../containers/app/hooks/useAppRoutes.js'

const DashboardSetup = ({
  location,
  user,
  updateContext,
  requestContextChange
}) => {
  const history = useHistory()
  const { routes } = useAppRoutes()
  const { isError, user_metadata } = user || {}
  const { allowedSections, properties } = user_metadata || {}

  const {
    inspectionSetupDefaultPath,
    canAccessInspectionSetup
  } = useMemo(() => {
    const filteredRoutes = (routes || []).filter(route =>
      [section.ELEMENTS, section.TEMPLATES, section.FORMS].includes(
        route.sectionKey
      )
    )

    const canAccessInspectionSetup = filteredRoutes.length
    const inspectionSetupDefaultPath = canAccessInspectionSetup
      ? filteredRoutes[0].path
      : ''

    return {
      canAccessInspectionSetup,
      inspectionSetupDefaultPath
    }
  }, [routes])

  const getBoxes = () => {
    let template = []
    let { projects } = user.user_metadata
    const boxes = (projects || [])
      ?.map(p => ({
        ...boxValues.projects,
        ...p,
        isPrimary: true
      }))
      ?.sort((a, b) =>
        b?.name?.localeCompare(a?.name, undefined, {
          sensitivity: 'base'
        })
      )
    for (let i = 0; i < boxes.length; i += 1) {
      const b = boxes[i]
      template.push(
        <div className="column is-one-third" key={`project-box-${i}`}>
          <DashboardBox
            icon={b.icon || boxValues.projects.icon}
            header={b.name || boxValues.projects.name}
            caption={b.caption || boxValues.projects.caption}
            isPrimary={b.isPrimary || false}
            onButtonClick={() => {
              b.onButtonClick(
                b.id,
                (project, property) => updateContext(project, property),
                () => requestContextChange()
              )
            }}
          />
        </div>
      )
    }
    return template
  }

  const getInspectionsBoxes = () => {
    let template = []

    if (
      checkPermission(allowedSections, section.INSPECTION_SETUP) &&
      canAccessInspectionSetup &&
      !isMobile()
    ) {
      template.push(
        <div
          className="column is-one-third"
          key={'project-box-inspectionSetup'}>
          <DashboardBox
            icon={setup}
            header={'Inspection Setup'}
            caption={'Create and update inspection templates.'}
            isPrimary={true}
            onButtonClick={() => {
              history.push(inspectionSetupDefaultPath)
            }}
          />
        </div>
      )
    }

    if (
      checkPermission(allowedSections, section.WEB_INSPECTIONS) &&
      !isMobile()
    ) {
      template.push(
        <div className="column is-one-third" key={'project-box-inspections'}>
          <DashboardBox
            icon={inspections}
            header={'Inspections'}
            caption={'View and manage all inspections.'}
            isPrimary={true}
            onButtonClick={() => {
              history.push('/inspections/inspectboard')
            }}
          />
        </div>
      )
    }
    // TODO 002
    // The reports is something we should remove and add back when we launch a reporting platform
    // if (checkPermission('reports')) {
    //   template.push(
    //     <div className="column is-one-third" key={'project-box-reports'}>
    //       <DashboardBox
    //         icon={analytics}
    //         header={'Reports'}
    //         caption={'Create and view reports.'}
    //         isPrimary={true}
    //         onButtonClick={() => {}}
    //       />
    //     </div>
    //   )
    // }

    return template
  }

  const { name } = user
  return (
    <div className="container dashboard-setup animated fadeIn">
      <div className="section" style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Breadcrumbs location={location} />
        {isError ? (
          <ErrorMessage>
            Something went wrong. Please try again later or contact your
            administrator
          </ErrorMessage>
        ) : (
          <>
            <p className="welcome animated fadeInDown">Welcome back, {name}.</p>
            {(properties || []).length ? (
              <>
                {' '}
                <p className="header m-b-lg animated fadeInDown">
                  What would you like to work on today?
                </p>
                {((checkPermission(allowedSections, section.INSPECTION_SETUP) &&
                  canAccessInspectionSetup) ||
                  checkPermission(
                    allowedSections,
                    section.WEB_INSPECTIONS
                  )) && <p className="header-title">Tools and Analytics</p>}
                <div className="columns is-multiline m-b-xl">
                  {getInspectionsBoxes()}
                </div>
                <p className="header-title">Turn Projects</p>
                <div className="columns is-multiline m-b-xl">{getBoxes()}</div>
              </>
            ) : (
              <>
                <br />
                <ErrorMessage>
                  You don't have access to any properties. Please try again
                  later or contact your administrator
                </ErrorMessage>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

DashboardSetup.propTypes = {
  user: PropTypes.object,
  updateContext: PropTypes.func,
  requestContextChange: PropTypes.func
}

export default DashboardSetup
