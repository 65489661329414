import moment from 'moment'

const getProjectName = (user, context, fileName = false) => {
  const { user_metadata } = user || {}
  const { properties, projects } = user_metadata || {}
  const { property: contextProperty, project: contextProject } = context || {}

  const property = (properties || []).find(p => p?.id === contextProperty)
  const project = (projects || []).find(p => p?.id === contextProject)
  const names = {
    property: fileName
      ? property?.name?.toString()?.replace(/ /g, '_')
      : property?.name?.toString(),
    project: project?.name?.toString()?.replace(/ /g, '_')
  }
  return names
}

const exportSingleInspectionCSV = (row, context, user, isMultiple = false) => {
  let inspectionData = row || []
  let allUnit = []
  const names = getProjectName(user, context)
  const propertyName = names?.property || ''
  const getinspection = (inspectionData || []).flatMap(row => {
    const {
      inspection_lines,
      unit,
      inspection_date: due_date,
      status: inspection_status,
      inspection_id
    } = row || {}
    // For File Name
    allUnit.push(unit)
    const constructInspectionDataArray = (inspection_lines || []).flatMap(
      singleInspection => {
        const { location, items, status } = singleInspection || {}
        let data = []
        ;(items || []).map(sItem => {
          const { item, line_note, state, observations } = sItem || {}
          if (status === 'Not Required') {
            data.push({
              Property: propertyName || '',
              'Inspection ID': inspection_id
                ? (inspection_id || '').toString().replace('Header', '')
                : '',
              'Inspection Status': inspection_status || '',
              'Inspection Date': due_date || '',
              Unit: unit || '',
              'Unit Space': location || '',
              Item: item || '',
              'Line Status': 'Not Required',
              Observation: '',
              Solutions: '',
              Notes: ''
            })
          }
          if (state !== null && status !== 'Not Required') {
            if (state === 'Good') {
              data.push({
                Property: propertyName || '',
                'Inspection ID': inspection_id
                  ? (inspection_id || '').toString().replace('Header', '')
                  : '',
                'Inspection Status': inspection_status || '',
                'Inspection Date': due_date || '',
                Unit: unit || '',
                'Unit Space': location || '',
                Item: item || '',
                'Line Status': state || '',
                Observation: '',
                Solutions: '',
                Notes: `"${line_note || ''}"` || ''
              })
            }
            if (state === 'Needs Work') {
              const getObservation = (observations || []).filter(
                observation => observation.selected
              )
              const getSolution = (getObservation || []).map(observation => {
                const { solutions } = observation || {}
                return (
                  (solutions || []).filter(solution => solution.selected) || []
                ).map(sol => sol.solution)
              })
              data.push({
                Property: propertyName || '',
                'Inspection ID': inspection_id
                  ? (inspection_id || '').toString().replace('Header', '')
                  : '',
                'Inspection Status': inspection_status || '',
                'Inspection Date': due_date || '',
                Unit: unit || '',
                'Unit Space': location || '',
                Item: item || '',
                'Line Status': state || '',
                Observation:
                  (getObservation || []).length !== 0
                    ? getObservation[0]?.observation
                    : '',
                Solutions: `"${(getSolution || [])?.join(',')}"` || '',
                Notes: `"${line_note || ''}"` || ''
              })
            }
          }
          if (!state && status !== 'Not Required') {
            data.push({
              Property: propertyName || '',
              'Inspection ID': inspection_id
                ? (inspection_id || '').toString().replace('Header', '')
                : '',
              'Inspection Status': inspection_status || '',
              'Inspection Date': due_date || '',
              Unit: unit || '',
              'Unit Space': location || '',
              Item: item || '',
              'Line Status': '',
              Observation: '',
              Solutions: '',
              Notes: ''
            })
          }
        })
        return data || []
      }
    )
    // To add extra line at end
    constructInspectionDataArray.push({})
    // Header
    constructInspectionDataArray.unshift([
      'Property',
      'Inspection ID',
      'Inspection Status',
      'Inspection Date',
      'Unit',
      'Unit Space',
      'Item',
      'Line Status',
      'Observation',
      'Solutions',
      'Notes'
    ])

    const rows = constructInspectionDataArray || []
    return rows
  })
  const csvData = (getinspection || [])
    .map(d => Object.values(d))
    .map(e => e.join(','))
    .join('\n')
  const encodedUri =
    'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
  let link = document.createElement('a')
  const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
  // File Name
  if (isMultiple) {
    const names = getProjectName(user, context, true)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-selected-inspections-${datetime}.csv`
    )
  } else {
    link.setAttribute(
      'download',
      `Unit ${(allUnit || []).join('-')} Inspection-${datetime}.csv`
    )
  }

  link.setAttribute('href', encodedUri)

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export { exportSingleInspectionCSV }
