import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import { usePrevious } from 'hooks'
import BigLoading from 'components/big-loading'
import SetupBox from 'components/setup-box'
import SetupVendorManageModalServices from '../setup-vendor-manage-modal-services'
import SetupVendorManageModalDelete from '../setup-vendor-manage-modal-delete'
import CustomDeleteVendorModal from '../delete-vendor-modal'
import AddressField from 'components/address-field'
import AddressDisplay from 'components/address-display'
import { Link } from 'react-router-dom'
import RestrictedElement from 'containers/restricted-element'
import { addButton, trash, edit as editIcon } from 'icons'
import SetupVendorService from 'components/setup-vendor-service'
import PermissionGate from '../../components/permission-gate'
import VendorToggle from './components/vendor-toggle'
import { animate } from '../../helpers'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { question, questionFilled } from 'ui/icons'
import VendorLoginsList from '../vendor-logins-list'
import AddVendorLoginValidatorModal from '../add-vendor-login-validator-modal'
import { useSelector } from 'react-redux'
import { emailPolicy } from '../../components/forms-helper/ValidationPolicies'
import { lock, unlock } from 'ui/icons'
import './index.scss'
import '../../styles/common.scss'
import SetupVendorReactivateOrInactivateStepperModal from '../setup-vendor-inactivate-or-reactivate-stepper-modal'
import { omit } from 'lodash'

const errorMessages = {
  email:
    'The email address you entered is invalid. Please enter your email address using standard format.'
}

const DEFAULT_VENDOR = 'new-vendor'

const SetupVendorManage = props => {
  const {
    location,
    history,
    openModal,
    setModalContent,
    setupVendors,
    setupOneVendorGet,
    setupOneVendorCreate,
    setupOneVendorUpdate,
    user,
    context,
    openTooltip,
    setTooltipContent,
    closeTooltip,
    setAlert,
    closeModal,
    allVendorAssociatedUsers,
    toggleVendorAvailablitity,
    deleteVendorServices
  } = props || {}
  const [isEditingMain, setIsEditingMain] = useState(false)
  const [, setIsEditingBilling] = useState(false)
  const [term, setTerm] = useState('none')
  const [newVendorCreated, setNewVendorCreated] = useState(false)
  const [vendorNameError, setVendorNameError] = useState({
    title: null,
    email: null
  })
  const [isVendorActive, setIsVendorActive] = useState(true)
  const [isVendorUsed, setIsVendorUsed] = useState(false)
  const [forceUpdateVendor, setForceUpdateVendor] = useState(false)
  const [isSubmittingLoading, setIsSubmittingLoading] = useState(false)
  const [isEnabledAllLogins, setIsEnabledAllLogins] = useState({
    value: false,
    isUserSubmitted: false
  })

  const [email, setEmail] = useState('')

  const { associatedVendorUserList } = useSelector(state => state.setupUsers)
  const { data: vendorAssociatedUsers } = associatedVendorUserList || {}

  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)

    return {
      v: newParams.get('v') || DEFAULT_VENDOR,
      edit: newParams.get('edit') === 'true' || false,
      modal: newParams.get('modal') === 'true' || false
    }
  }, [location])

  const hasAssociatedVendors = useMemo(() => {
    if (!Array.isArray(vendorAssociatedUsers)) return false
    if (
      Array.isArray(vendorAssociatedUsers) &&
      (vendorAssociatedUsers || []).length === 0
    )
      return false
    return true
  }, [associatedVendorUserList])

  const { v, edit, modal } = params

  const prevContext = usePrevious(context)
  // const prevSetupVendors = usePrevious(setupVendors)
  const prevParams = usePrevious(params)

  const { pdbid } = user.user_metadata
  const { hasMadeInitialRequest, isRequesting, data } = setupVendors.oneVendor

  const getValidEmail = value => value.match(emailPolicy) !== null

  useEffect(() => {
    if (v === 'new-vendor' || edit) {
      setIsEditingMain(true)
    }
    if (v !== 'new-vendor') {
      if (modal !== false) openServicesModal()
      // setupOneVendorGet(pdbid, context, v)
      // allVendorAssociatedUsers(pdbid, context, v)
    }
  }, [])

  useEffect(() => {
    const vendorId = setupVendors?.oneVendor?.data?.id
    if (newVendorCreated && vendorId !== 'new-vendor') {
      history.push(`/setup/vendor-manage?v=${vendorId}`)
    }
  }, [setupVendors])

  const checkIfVendorActive = useMemo(() => {
    const { status } = setupVendors?.oneVendor.data
    return status === 'Active' ? true : false
  }, [setupVendors])

  useEffect(() => {
    const { terms } = setupVendors?.oneVendor.data.billing
    // const newId = setupVendors?.oneVendor.data.id'
    // const oldId = prevSetupVendors?.oneVendor.data.id'
    // const idChanged = newId && newId !== oldId && newId !== 'new-vendor' && newId !== ''
    const propertyChanged = context?.property !== prevContext?.property
    const projectChanged = context?.project !== prevContext?.project
    const contextChanged = propertyChanged || projectChanged
    const vChanged = v !== prevParams?.v

    if (contextChanged || vChanged) {
      let shouldEditingMain = v === 'new-vendor'
      if (isEditingMain !== shouldEditingMain)
        setIsEditingMain(shouldEditingMain)
      setupOneVendorGet(pdbid, context, v)
      allVendorAssociatedUsers(pdbid, context, v)
    }

    const { used, status } = setupVendors?.oneVendor.data
    if (v === 'new-vendor') {
      setIsVendorActive(true)
    } else {
      const isVendorActive = status === 'Active' ? true : false
      setIsVendorActive(isVendorActive)
    }
    setIsVendorUsed(used)

    // if (idChanged) history.push(`/setup/vendor-manage?v=${newId}&edit=${edit}`)

    if (term !== terms) setTerm(terms)
  }, [context, params, setupVendors, pdbid])

  useEffect(() => {
    if (forceUpdateVendor) {
      setupOneVendorGet(pdbid, context, v)
      allVendorAssociatedUsers(pdbid, context, v)
      setForceUpdateVendor(false)
    }
  }, [forceUpdateVendor])

  const onHandleForceUpdateVendor = () => {
    setForceUpdateVendor(true)
  }

  // Add Vendor login Validator Modal
  const onAddVendorLoginValidatorModalHandler = () => {
    setModalContent(() => (
      <AddVendorLoginValidatorModal
        {...props}
        vendorId={v}
        onHandleForceUpdateVendor={onHandleForceUpdateVendor}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '500px',
      maxWidth: '94%',
      additionalClasses: 'add-vendor-login-modal'
    })
  }

  const { isError } = setupVendors.oneVendor

  useEffect(() => {
    if (isError) {
      setAlert(
        'An error occurred while submitting the request. Please try again later.',
        'danger'
      )
    }
  }, [isError])

  const openServicesModal = (hook = {}) => {
    setModalContent(() => {
      return getServicesModal(hook)
    })
    openModal({ width: '480px' })
  }

  useEffect(() => {
    if (data?.id && data?.id !== 'new-vendor') {
      setEmail(data?.main?.email)

      if (data?.main?.email) {
        const isValid = getValidEmail(data?.main?.email)
        setVendorNameError(prevState => ({
          ...prevState,
          email: isValid ? null : errorMessages.email
        }))
      }
    } else {
      setEmail('')
      setVendorNameError(prevState => ({
        ...prevState,
        email: null
      }))
    }
  }, [data, isEditingMain])

  const getLoadingState = () => <BigLoading />

  const onSuccessToggleAvailablitity = () => {
    setIsSubmittingLoading(false)
    setForceUpdateVendor(true)
  }

  const onErrorToggleAvailablitity = () => {
    setIsSubmittingLoading(false)
  }

  const isCheckedEnable = value => {
    if (isEnabledAllLogins?.isUserSubmitted) {
      if (isEnabledAllLogins?.value && value) {
        return null
      }
      return 'false'
    }
    return 'false'
  }

  const handleShowInactivateOrReactivateVendorModal = useCallback(
    (vendorCompanyId, vendorCompanyData, vendorUsers, status) => {
      setModalContent(() => (
        <SetupVendorReactivateOrInactivateStepperModal
          {...props}
          user={user}
          context={context}
          vendorCompanyId={vendorCompanyId}
          vendorCompanyData={vendorCompanyData}
          vendorUsers={vendorUsers}
          status={status}
          onCancel={closeModal}
          onConfirm={() => {
            setForceUpdateVendor(true)
            closeModal()
          }}
        />
      ))
      openModal({ width: '500px' })
    },
    [context]
  )

  const onSuccess = () => {
    setIsSubmittingLoading(false)
  }

  const onError = () => {
    setIsSubmittingLoading(false)
  }

  const submitVendor = vendor => {
    vendor.status = isVendorActive === true ? null : 'false'
    if (v === 'new-vendor') {
      setupOneVendorCreate(pdbid, context, vendor)
      setNewVendorCreated(true)
    } else {
      setIsSubmittingLoading(true)
      setupOneVendorUpdate(pdbid, context, v, vendor, onSuccess, onError)
      setIsEditingMain(false)
    }
  }

  const getVendorServiceOptions = useMemo(() => {
    if (v === 'new-vendor' && isEditingMain === true) return null
    const {
      floorplan,
      falttenFloorplans,
      services,
      name
    } = setupVendors.oneVendor.data
    const data = (services || []).filter(d => d?.uri)
    const template = (data || []).map((service, i) => (
      <SetupVendorService
        key={i}
        service={service}
        floorplan={floorplan}
        falttenFloorplans={falttenFloorplans}
        openServicesModal={openServicesModal}
        showTooltip={showTooltip}
        vendorId={v}
        vendorName={name}
        history={history}
        location={location}
        showDeleteVendorModal={showDeleteVendorModal}
        setupOneVendorGet={() => setupOneVendorGet(pdbid, context, v)}
        deleteVendorServices={deleteVendorServices}
        setAlert={setAlert}
        user={user}
        context={context}
        onHandleForceUpdateVendor={onHandleForceUpdateVendor}
      />
    ))

    template.push(
      <PermissionGate name={'add-vendor-service'}>
        <RestrictedElement key={`vendor-service-add-${Short.uuid()}`}>
          <div className="box is-shadowless is-unselectable">
            <button
              className="is-button-link"
              onClick={() => {
                openServicesModal()
              }}>
              <img
                src={addButton}
                className="is-inline"
                style={{ marginTop: '-1px' }}
                alt="Add Vendor Services"
              />
              <p className="is-inline m-l-sm">Add Service</p>
            </button>
          </div>
        </RestrictedElement>
      </PermissionGate>
    )
    return template
  }, [location, context, params, setupVendors])

  const getServicesModal = (hook = {}) => {
    let disableServices = false
    let disableOptions = false
    let defaultService = ''
    let defaultOption = ''
    let defaultCost = 0
    let defaultUnit = 'space'
    if (typeof hook.key === 'string') {
      if (hook.value[0] === '$') hook.value = hook.value.substr(1)
      disableServices = true
      disableOptions = true
      defaultOption = hook.key
      defaultService = getServiceFromOption(hook.key)
      defaultCost = parseFloat(hook.value)
    } else if (typeof hook.serviceKey === 'string') {
      disableServices = true
      defaultService = getServiceByUri(hook.serviceKey)
    }
    return (
      <SetupVendorManageModalServices
        {...props}
        disableServices={disableServices}
        disableOptions={disableOptions}
        defaultService={defaultService}
        defaultOption={defaultOption}
        defaultCost={defaultCost}
        defaultUnit={defaultUnit}
      />
    )
  }

  const getServiceByUri = uri => {
    const { data } = setupVendors.allServices
    for (let i = 0; i < data.length; i += 1) {
      const service = data[i]
      if (service.servicetype_uri === uri) return service.servicetype_id
    }
    return null
  }

  const getServiceFromOption = key => {
    const { data } = setupVendors.allServices
    for (let i = 0; i < data.length; i += 1) {
      const service = data[i]
      for (let ii = 0; ii < service.items.length; ii += 1) {
        const item = service.items[ii]
        for (let iii = 0; iii < item.item_options.length; iii += 1) {
          const itemOption = item.item_options[iii]
          const { item_option_uri } = itemOption
          if (item_option_uri === key) return service.servicetype_id
        }
      }
    }
    return null
  }

  const showDeleteVendorModal = (item_option_uri = null) => {
    const { data } = setupVendors.oneVendor
    setModalContent(() => (
      <SetupVendorManageModalDelete
        vendorId={v === 'new-vendor' ? data.id : v}
        itemOptionUri={item_option_uri}
        {...props}
      />
    ))
    openModal({ width: '480px' })
  }

  const showCustomDeleteVendorModal = () => {
    const { data } = setupVendors.oneVendor
    setModalContent(() => (
      <CustomDeleteVendorModal
        vendorId={v === 'new-vendor' ? data.id : v}
        {...props}
      />
    ))
    openModal({ width: '480px' })
  }

  const showTooltip = (id, e) => {
    let { target } = e
    if (e.target.tagName === 'path') target = e.target.parentNode
    let template = {}
    template.services = (
      <div className="p-l-md is-unselectable p-t-xs p-l-md p-r-md htooltip">
        <p onClick={() => {}} className="is-pointer">
          Remove Service
        </p>
      </div>
    )
    template.main = (
      <div>
        <div>
          <button
            className="options-button"
            onClick={() => {
              setIsEditingMain(true)
              if (id === 'billing') setIsEditingBilling(true)
              closeTooltip()
            }}>
            <img src={editIcon} alt="edit information" className="p-r-sm" />
            Edit Information
          </button>
        </div>
        <div>
          {isVendorUsed && (
            <button
              className="options-button"
              onClick={() => {
                const billing = omit(data.billing, [
                  'billing_address',
                  'legal_name'
                ])
                const address = omit(data?.main?.address, ['address3'])
                const main = omit(data?.main, ['address', 'cell_phone'])
                const name = data?.name
                const status = isVendorActive === true ? null : 'false'
                const integration_id = data?.integration_id

                const vendorCompanyData = {
                  ...address,
                  ...main,
                  ...billing,
                  name,
                  status,
                  integration_id
                }

                handleShowInactivateOrReactivateVendorModal(
                  v,
                  vendorCompanyData,
                  vendorAssociatedUsers,
                  isVendorActive
                )
              }}>
              <div
                className="d-flex align-items-center"
                style={{ width: 'max-content' }}>
                <div>
                  <img
                    src={isVendorActive ? unlock : lock}
                    alt="edit information"
                    style={{ display: 'block' }}
                    className="p-r-sm"
                  />
                </div>
                <div>
                  {isVendorActive ? 'Inactivate Vendor' : 'Reactivate Vendor'}
                </div>
              </div>
            </button>
          )}
        </div>
        <div>
          {!isVendorUsed && (
            <button
              className="options-button"
              onClick={() => {
                closeTooltip()
                showCustomDeleteVendorModal()
              }}>
              <img src={trash} alt="delete vendor" className="p-r-sm" />
              Delete Vendor
            </button>
          )}
        </div>
      </div>
    )
    template.billing = (
      <div>
        <div>
          <button
            className="options-button"
            onClick={() => {
              if (id === 'main') setIsEditingMain(true)
              if (id === 'billing') setIsEditingBilling(true)
              closeTooltip()
            }}>
            <img src={editIcon} alt="edit information" className="p-r-sm" />
            Edit Information
          </button>
        </div>
      </div>
    )
    setTooltipContent(template[id])
    const pos = target.getBoundingClientRect()
    const width = id !== 'services' ? 160 : 150
    const height = 'auto'
    openTooltip(
      `${width}px`,
      height,
      `${pos.y + pos.height / 2 + pos.height}px`,
      `${pos.x + pos.width / 2 - width / 2}px`,
      e.target
    )
  }

  if ((!hasMadeInitialRequest && v !== 'new-vendor') || isRequesting) {
    return getLoadingState()
  }

  const shouldBeEmpty = v === 'new-vendor' && isEditingMain === true
  const emptyMain = {
    email: '',
    address: {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    business_phone: '',
    status: ''
    //cell_phone: '',
  }

  const handleEmailChange = ({ currentTarget: input }) => {
    input.value = input?.value?.toLowerCase()?.trim()
    setEmail(input.value)

    if (!input.value) {
      setVendorNameError({
        ...vendorNameError,
        email: null
      })
    } else {
      const isValid = getValidEmail(input.value)
      setVendorNameError({
        ...vendorNameError,
        email: isValid ? '' : errorMessages.email
      })
    }
  }

  const displayFields = {
    email: 'Billing Email',
    address: 'Billing Address',
    phone: 'Phone',
    taxid: 'Tax ID',
    currency: 'Currency',
    terms: 'Term',
    integration_id: 'Integration ID'
  }

  if (!isEditingMain) {
    displayFields.status = (
      <div className="info-container">
        <span className="m-r-5">Status</span>
        <Tippy
          appendTo={() => document.body}
          content={
            <div className="content">
              <p>
                {isVendorActive
                  ? 'This vendor can be assigned to teams and its vendor users be provided login access to Turnable.'
                  : 'This vendor cannot be assigned to teams and its vendor users will no longer be able to login to Turnable. If a vendor has been assigned work, the vendor cannot be deleted.'}
              </p>
            </div>
          }
          placement="bottom-start"
          allowHTML
          theme="light">
          <img
            src={question}
            alt="help"
            onMouseOver={e => (e.currentTarget.src = questionFilled)}
            onMouseLeave={e => (e.currentTarget.src = question)}
          />
        </Tippy>
      </div>
    )
  }

  return (
    <div className="columns is-multiline setup-vendor-manage animated fadeIn">
      <div className="column is-half">
        <SetupBox
          isValid={{
            title: vendorNameError.title ? false : true,
            email: vendorNameError.email ? false : true
          }}
          errorMessages={vendorNameError}
          hasCircle={true}
          title={shouldBeEmpty ? '' : data.name}
          titleCanEdit={true}
          name="edit-vendor"
          submitButtonLabel="Save"
          data={
            shouldBeEmpty
              ? emptyMain
              : {
                  ...data.main,
                  ...data.billing,
                  integration_id: data.integration_id,
                  status: data.status
                }
          }
          display={displayFields}
          customFields={{
            email: () => {
              return (
                <>
                  <input
                    className={`input ${
                      vendorNameError.email ? 'invalid' : ''
                    }`}
                    id="email"
                    name="email"
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {vendorNameError.email && (
                    <>
                      <br />
                      <span className="invalid-text">
                        {vendorNameError.email}
                      </span>
                    </>
                  )}
                </>
              )
            },
            address: (value, key, id) => {
              return <AddressField defaults={value} />
            },
            currency: () => (
              <div>
                <select className="select is-fullwidth">
                  <option>USD</option>
                </select>
                <input type="hidden" name="currency" value="USD" />
              </div>
            ),
            terms: () => (
              <div>
                <select
                  className="select is-fullwidth"
                  value={term}
                  onChange={e => {
                    const { value } = e.target
                    setTerm(value)
                  }}>
                  <option value="none">Select</option>
                  <option>On Receipt</option>
                  <option>Net 10</option>
                  <option>Net 30</option>
                  <option>Net 60</option>
                </select>
                <input type="hidden" name="terms" value={term} />
              </div>
            )
          }}
          customDisplays={{
            address: (value, key, id) => {
              return <AddressDisplay defaults={value} />
            }
          }}
          isEditing={isEditingMain}
          toggleEditing={bool => {
            if (typeof bool !== 'boolean') bool = !isEditingMain
            setIsEditingMain(bool)
          }}
          emptyTemplate={<div>This is empty.</div>}
          cancel={() => {
            setIsEditingMain(false)
            setVendorNameError({ ...vendorNameError, title: null, email: null })
            const { status } = setupVendors?.oneVendor.data
            const isVendorActive = status === 'Active' ? true : false
            setIsVendorActive(isVendorActive)
            if (v === 'new-vendor') history.push('/setup/vendors')
          }}
          titleChange={() =>
            setVendorNameError({ ...vendorNameError, title: null })
          }
          submit={(vendor, id) => {
            const { allVendors } = setupVendors

            if (data.name !== vendor.name || v === 'new-vendor') {
              if (
                allVendors.data.length > 0 &&
                allVendors.data.filter(
                  existingVendor => vendor.name === existingVendor.name
                ).length > 0
              ) {
                setVendorNameError({
                  ...vendorNameError,
                  title: 'This vendor name is already taken'
                })
                return
              }
            }
            if (!vendor.name || vendor?.name?.trim() === '') {
              const el = document.getElementById(`setup-box-title-${id}`)
              el.focus()
              return animate(el, 'shake')
            }
            if (vendorNameError.email) {
              const el = document.getElementById('email')
              el.focus()
              return animate(el, 'shake')
            }

            submitVendor({
              ...vendor
            })
          }}
          ellipsisClick={e => showTooltip('main', e)}
          closeTooltip={closeTooltip}
        />
        {/* <PermissionGate name={'add-vendor'}>
          <RestrictedElement>
            <div className="box is-shadowless has-transition-opacity">
              <Link
                to="/setup/vendor-manage?v=new-vendor"
                className={`${v === 'new-vendor' ? 'is-hidden' : ''}`}
                onClick={() => setNewVendorCreated(false)}>
                <img
                  src={addButton}
                  className="is-inline"
                  style={{ marginTop: '-1px' }}
                  alt="Add New Vendor"
                />
                <p className="is-inline m-l-sm">Add New Vendor</p>
              </Link>
            </div>
          </RestrictedElement>
        </PermissionGate> */}
        {v !== 'new-vendor' && checkIfVendorActive ? (
          <PermissionGate name={'add-vendor'}>
            <RestrictedElement>
              <div
                className="box is-shadowless has-transition-opacity"
                style={{ marginTop: '10px' }}>
                <button
                  className="is-button-link"
                  onClick={() => onAddVendorLoginValidatorModalHandler()}>
                  <img
                    src={addButton}
                    className="is-inline"
                    style={{ marginTop: '-1px' }}
                    alt="Add Vendor Login"
                  />
                  <p className="is-inline m-l-sm">Add Vendor Login</p>
                </button>
              </div>
            </RestrictedElement>
          </PermissionGate>
        ) : null}
        {v !== 'new-vendor' && hasAssociatedVendors && data?.name !== '' ? (
          <VendorLoginsList {...props} vendor_id={v} />
        ) : null}
      </div>
      <div className="column is-half">{getVendorServiceOptions}</div>
    </div>
  )
}

SetupVendorManage.propTypes = {
  setupVendors: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object,
  setupOneVendorGet: PropTypes.func,
  setupOneVendorCreate: PropTypes.func,
  setupOneVendorUpdate: PropTypes.func,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func
}

export default SetupVendorManage
