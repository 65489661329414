import Service from '../service'
import LOGOUT from './auth'

/**
 * Request Vendor Services data module
 * @redux
 * @reduxActionScope vendor-services
 * @module vendor-services
 */

/**
 * Request all Vendor Service list
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_VENDOR_SERVICE_GET_REQUESTED =
  'request-vendor/REQUEST_ALL_VENDOR_SERVICE_GET_REQUESTED'
/**
 * All Vendor Service request failed
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_VENDOR_SERVICE_GET_FAIL = 'request-vendor/REQUEST_ALL_VENDOR_SERVICE_GET_FAIL'
/**
 * Update Vendor Service list with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_VENDOR_SERVICE_GET_SUCCESS =
  'request-vendor/REQUEST_ALL_VENDOR_SERVICE_GET_SUCCESS'

const initialState = {
  allVendorService: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
}

/**
 * Request Vendor reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_VENDOR_SERVICE_GET_REQUESTED:
      return {
        ...state,
        allVendorService: {
          ...state.allVendorService,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_ALL_VENDOR_SERVICE_GET_SUCCESS:
      return {
        ...state,
        allVendorService: {
          ...state.allVendorService,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      } 
    case REQUEST_ALL_VENDOR_SERVICE_GET_FAIL:
      return {
        ...state,
        allVendorService: {
          ...state.allVendorService,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all Vendor Services
 * @redux
 * @reduxActionCreator REQUEST_ALL_VENDOR_SERVICE_GET_REQUESTED, REQUEST_ALL_VENDOR_SERVICE_GET_SUCCESS, REQUEST_ALL_VENDOR_SERVICE_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const allVendorServiceGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: REQUEST_ALL_VENDOR_SERVICE_GET_REQUESTED
    })
    Service.allVendorServiceGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: REQUEST_ALL_VENDOR_SERVICE_GET_SUCCESS,
            data: _allVendorServiceAdapter(data)
          })
        } else {
          _allRequestVendorServiceFail(dispatch)
        }
      })
      .catch(() => {
        _allRequestVendorServiceFail(dispatch)
      })
  }
}

const _allRequestVendorServiceFail = dispatch => {
  dispatch({
    type: REQUEST_ALL_VENDOR_SERVICE_GET_FAIL
  })
}

const DEFAULT = ''
const _vendorServiceAdapter = data => {
  return {
    servicetype_uri: typeof data.servicetype_uri === 'string' ? data.servicetype_uri : DEFAULT,
    servicetype_id: typeof data.servicetype_id === 'string' ? data.servicetype_id : DEFAULT,
    servicetype: typeof data.servicetype === 'string' ? data.servicetype : DEFAULT,
  }
}

const _allVendorServiceAdapter = data => {
  const uniqueData = [...new Map((data || []).map(item =>
    [item['servicetype_id'], item])).values()]
  return (data || []).map(d => _vendorServiceAdapter(d))
}
