import React, { useImperativeHandle, useRef, useState } from 'react'
import './styles.scss'

export const useBottomSheet = () => {
  const bottomsheet = useRef(null)

  const show = () => bottomsheet.current?.show()
  const dismiss = () => bottomsheet.current?.dismiss()

  return { ref: bottomsheet, show, dismiss }
}

const BottomSheet = React.forwardRef(({ children }, ref) => {
  const [modalVisible, setModalVisible] = useState(false)

  const show = () => setModalVisible(true)
  const dismiss = () => setModalVisible(false)

  useImperativeHandle(ref, () => ({
    show,
    dismiss
  }))

  return (
    <>
      <div 
        className={`component-bottomsheet-overlay ${modalVisible? 'component-bottomsheet-overlay-show' : 'component-bottomsheet-overlay-hidden'}`}
        onClick={() => setModalVisible(false)}
      />
      <div className={`component-bottomsheet ${modalVisible? 'component-bottomsheet-open' : 'component-bottomsheet-close'}`}>
        <div className="component-bottomsheet-header">
          <div className="component-bottomsheet-header-title">Checklist</div>
          <button className="delete" aria-label="close" onClick={() => setModalVisible(false)} />
        </div>

        <div className="component-bottomsheet-content">
          As you’re working in this unit, don’t forget to check the following items:
        </div>

        <div className="component-bottomsheet-footer">
          <button className="button is-primary is-medium is-fullwidth" onClick={() => setModalVisible(false)}>Okay</button>
        </div>
      </div>
    </>
  )
})

export default BottomSheet
