import React, { useMemo } from 'react'
import { edit, trash, lock, unlock } from 'ui/icons'

import DataTableMenu from '../../../../components/data-table-menu'
import { useHistory } from 'react-router'
import { checkPermission, sectionManageable } from '../../../../security'
import { useAppUser } from '../../../../helpers/hooks'

const ActionColumn = ({
  row,
  onShowDeleteModal,
  onShowReactivateOrInactivateModal
}) => {
  const history = useHistory()
  const { user } = useAppUser()

  const { cell } = row || {}
  const { row: currentRow } = cell || {}
  const { original } = currentRow || {}
  const { user_id, email, used, permission_rule_id, perm_status } =
    original || {}

  const isPropertyManager = useMemo(() => {
    const { roles } = user || {}
    return (roles || []).some(role => role === 'Property Manager')
  }, [user])

  const isPMAndCurrentUserIsLoggedInUser = useMemo(() => {
    return isPropertyManager && user?.email === email
  }, [user, email, isPropertyManager])

  const menuOptions = useMemo(() => {
    const options = [
      {
        caption: 'Edit User',
        icon: edit,
        action: () => {
          return history.push(`/setup/staff/user/edit/${user_id}`)
        }
      }
    ]

    if (
      sectionManageable('manage-staff-member', user) &&
      used === 'T' &&
      !isPMAndCurrentUserIsLoggedInUser
    ) {
      options.push({
        caption: !perm_status ? 'Inactivate User' : 'Reactivate User',
        icon: !perm_status ? unlock : lock,
        action: () => {
          onShowReactivateOrInactivateModal &&
            onShowReactivateOrInactivateModal(permission_rule_id, perm_status)
        }
      })
    }

    if (
      sectionManageable('manage-staff-member', user) &&
      used === 'F' &&
      !isPMAndCurrentUserIsLoggedInUser
    ) {
      options.push({
        icon: trash,
        caption: 'Delete Staff Member',
        action: () =>
          onShowDeleteModal && onShowDeleteModal(user_id, permission_rule_id)
      })
    }

    return options
  }, [user, used, isPMAndCurrentUserIsLoggedInUser])

  const showActionMenu = useMemo(
    () => checkPermission('manage-staff-member', user),
    [user]
  )

  return (
    <div className="menu-item">
      {showActionMenu && <DataTableMenu options={menuOptions} row={row} />}
    </div>
  )
}

export default ActionColumn
