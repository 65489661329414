import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import { getProjectId } from './dashboard-helpers'

/**
 * Dashboard costs chart data module
 * @redux
 * @reduxActionScope dashboard-cost
 * @module dashboard-costs
 */

/**
 * Request dashboard costs chart data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_COST_SUMMARY_REQUESTED =
  'dashboard/DASHBOARD_COST_SUMMARY_REQUESTED'
/**
 * Dashboard costs chart data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_COST_SUMMARY_FAIL =
  'dashboard/DASHBOARD_COST_SUMMARY_FAIL'
/**
 * Update request dashboard costs chart with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_COST_SUMMARY_SUCCESS =
  'dashboard/DASHBOARD_COST_SUMMARY_SUCCESS'

const initialPropertyState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  rows: []
}

const initialState = {}

/**
 * Dashboard costs table reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_COST_SUMMARY_REQUESTED:
      const crData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...crData,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case DASHBOARD_COST_SUMMARY_SUCCESS:
      const csData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...csData,
          isRequesting: false,
          isError: false,
          rows: action.rows
        }
      }
    case DASHBOARD_COST_SUMMARY_FAIL:
      const cfData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...cfData,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Updates dashboard costs chart data
 * @redux
 * @reduxActionCreator DASHBOARD_COST_SUMMARY_REQUESTED, DASHBOARD_COST_SUMMARY_SUCCESS, DASHBOARD_COST_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getCostSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_COST_SUMMARY_REQUESTED,
      context
    })
    return Service.getDashboardCostSummary(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const rows = await res.json()
            dispatch({
              type: DASHBOARD_COST_SUMMARY_SUCCESS,
              rows: costCardAdapter(rows.dashboard),
              context
            })
          } catch {
            _costSummaryFail(dispatch, context)
          }
        } else {
          _costSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _costSummaryFail(dispatch, context)
        throw err
      })
  }
}

const _costSummaryFail = (dispatch, context) => {
  dispatch({
    type: DASHBOARD_COST_SUMMARY_FAIL,
    context
  })
}

const costCardAdapter = data => {
  const { services, costs } = data
  return _.map(services, service => {
    const cost = _.find(
      _.get(costs, '0.budget_lines'),
      c => c.servicetype_id === service.servicetype_id
    )
    return {
      service: service.label,
      budget: parseFloat(_.get(cost, 'budget', 0)),
      estimate: parseFloat(_.get(cost, 'budget_estimate', 0)),
      actual: parseFloat(_.get(cost, 'budget_actual', 0)),
      difference: parseFloat(_.get(cost, 'budget_difference', 0)),
      projected: parseFloat(_.get(cost, 'budget_projected', 0))
    }
  })
}
