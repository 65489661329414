import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import { getFormData } from '../../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

/**
 * Component for showing a form for a simple object
 * with optional custom rules to render specific fields
 *
 * @component
 * @example
 * const data = {
 *   "email": "paintvendor@test.com",
 *   "address": {
 *       "address1": "1234 PV1",
 *       "address2": "",
 *       "address3": "",
 *       "city": "Austin",
 *       "state": "Texas",
 *       "zip": "78739",
 *       "country": "United States of America"
 *   },
 *   "phone": "512-555-5555",
 *   "cell_phone": ""
 * }
 * return (
 *   <KeyValueForm
 *      data={data}
 *      display={{"email":"Email","address":"Address","phone":"Phone"}}
 *      customFields={{
 *        address: (value, key, id) => {
 *          return <AddressField defaults={value} />
 *        }
 *      }}
 *   />
 * )
 */
const KeyValueForm = ({
  data,
  submitButtonLabel,
  cancelButtonLabel,
  display,
  customFields = {},
  cancel = () => {},
  submit = () => {},
  change = () => {},
  isValid = {},
  errorMessages = {},
  isSubmitting
}) => {
  const id = useMemo(() => Short.uuid(), [])

  const onSubmit = e => e.preventDefault()

  const makeForm = () => {
    let template = []
    for (let key in display) {
      let value = data[key]
      let field = (
        <>
          <input
            type="text"
            defaultValue={value}
            name={key}
            className={`input ${isValid[key] === false ? 'invalid' : ''}`}
            onChange={e => change(key, e.target.value)}
          />
          {isValid[key] === false && (
            <>
              <br />
              <span className="invalid-text">{errorMessages[key]}</span>
            </>
          )}
        </>
      )
      if (typeof customFields[key] === 'function') {
        field = customFields[key](value, key, id)
      }
      if (!value) value = ''
      template.push(
        <div
          className="columns is-desktop is-mobile m-b-none"
          key={`vendor-manage-${key}`}>
          <div className="column is-one-third">
            <label forhtml={key} style={{ lineHeight: '40px' }}>
              {display[key]}:
            </label>
          </div>
          <div className="column is-two-thirds has-text-left">{field}</div>
        </div>
      )
    }
    template.push(
      <div className="columns m-b-none" key="vendor-manage-form-buttons">
        <div className="column is-offset-one-quarter is-one-quarter">
          <button
            type="button"
            className="button is-secondary is-fullwidth"
            onClick={() => {
              if (typeof cancel === 'function') cancel()
            }}>
            {cancelButtonLabel}
          </button>
        </div>
        <div className="column is-one-quarter">
          <button
            className="button is-primary is-fullwidth"
            type="button"
            onClick={() => {
              const form = document.querySelector(`#kv-form-${id}`)
              const data = getFormData(form)
              if (typeof submit === 'function') submit(data)
            }}>
            {isSubmitting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            {submitButtonLabel}
          </button>
        </div>
      </div>
    )
    return template
  }

  return (
    <form id={`kv-form-${id}`} onSubmit={e => onSubmit(e)}>
      {makeForm()}
    </form>
  )
}

KeyValueForm.propTypes = {
  /**
   * Data object to be used as initial values
   */
  data: PropTypes.object.isRequired,
  /**
   * Hashmap with the field labels
   */
  display: PropTypes.object.isRequired,
  /**
   * Hashmap with optional field rendering rules and elements
   */
  customFields: PropTypes.object,
  /**
   * Event handler for cancel action
   */
  cancel: PropTypes.func,
  /**
   * Event handler for submit action
   */
  submit: PropTypes.func,
  /**
   * Event handler for value changed action
   */
  change: PropTypes.func,
  /**
   * Hashmap for valid/invalid fields
   */
  isValid: PropTypes.object,
  /**
   * Hashmap for field error messages
   */
  errorMessages: PropTypes.object
}

KeyValueForm.defaultProps = {
  customFields: {},
  cancel: () => {},
  submit: () => {},
  change: () => {},
  isValid: {},
  errorMessages: {}
}

export default KeyValueForm
