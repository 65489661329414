import Service from '../service'

/**
 * Authentication module
 * @redux
 * @reduxActionScope auth
 * @module auth
 */

/**
 * Give access to a user
 * @type {Redux.ActionType}
 */
export const GIVE_ACCESS = 'auth/GIVE_ACCESS'
/**
 * Logs out the current user
 * @type {Redux.ActionType}
 */
export const LOGOUT = 'auth/LOGOUT'

/**
 * Request user token
 * @type {Redux.ActionType}
 */
export const MGMT_TOKEN_REQUESTED = 'auth/MGMT_TOKEN_REQUESTED'
/**
 * User token failed
 * @type {Redux.ActionType}
 */
export const MGMT_TOKEN_FAIL = 'auth/MGMT_TOKEN_FAIL'
/**
 * User token successful
 * @type {Redux.ActionType}
 */
export const MGMT_TOKEN_SUCCESS = 'auth/MGMT_TOKEN_SUCCESS'

const initialState = {
  isAuthenticated: false,
  hasMgmtToken: true,
  isRequesting: false,
  isError: false
}

/**
 * Authentication reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GIVE_ACCESS:
      return {
        ...state,
        isAuthenticated: true
      }

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      }

    case MGMT_TOKEN_REQUESTED:
      return {
        ...state,
        hasMgmtToken: false,
        isRequesting: true,
        isError: false
      }
    case MGMT_TOKEN_SUCCESS:
      return {
        ...state,
        hasMgmtToken: true,
        isRequesting: false,
        isError: false
      }

    case MGMT_TOKEN_FAIL:
      return {
        ...state,
        hasMgmtToken: false,
        isRequesting: false,
        isError: true
      }

    default:
      return state
  }
}

/**
 * Gives access to a given token, based on a cookie
 * @redux
 * @reduxActionCreator GIVE_ACCESS, MGMT_TOKEN_REQUESTED, MGMT_TOKEN_SUCCESS, MGMT_TOKEN_FAIL
 * @param {string} accessToken - the token for the current user
 */
export const giveAccess = accessToken => {
  Service.setAuthAccessToken(accessToken)

  return dispatch => {
    dispatch({
      type: GIVE_ACCESS
    })
  }
}

/**
 * Logs the current user out
 * @redux
 * @reduxActionCreator LOGOUT
 */
export const logout = () => {
  Service.logout()
  return dispatch => {
    dispatch({
      type: LOGOUT
    })
  }
}
