import _ from 'lodash'
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import { headerData } from './header'
import DeleteVendorModal from '../delete-vendor-modal'
import { icons } from '../setup-staff-main/icons'
import RestrictedElement from '../../containers/restricted-element'
import PermissionGate from '../../components/permission-gate'
import { capitalize } from '../../utils/helperFunctions'
import VendorListTable from './vendor-table/vendor-list-table'
import { closeFilled, exportFile } from 'ui/icons'
import { usePrevious } from '../../helpers/hooks'
import './index.scss'
import SetupVendorReactivateOrInactivateStepperModal from '../setup-vendor-inactivate-or-reactivate-stepper-modal'

const VendorList = props => {
  const {
    history,
    user,
    setupVendors,
    allVendorListGet,
    context,
    openModal,
    closeModal,
    setModalContent
  } = props
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [error, setError] = useState('')
  const [forceUpdateVendorList, setForceUpdateVendorList] = useState(false)
  const tableRef = useRef()

  const onHandleForceReload = state => {
    setForceUpdateVendorList(state)
  }

  const handleShowDeleteVendorModal = vendor_id => {
    setModalContent(() => (
      <DeleteVendorModal
        {...props}
        vendorId={vendor_id}
        onHandleForceReload={onHandleForceReload}
      />
    ))
    openModal({ width: '500px' })
  }

  const handleShowInactivateOrReactivateVendorModal = useCallback(
    (vendorCompanyId, vendorCompanyData, vendorUsers, status) => {
      setModalContent(() => (
        <SetupVendorReactivateOrInactivateStepperModal
          {...props}
          user={user}
          context={context}
          vendorCompanyId={vendorCompanyId}
          vendorCompanyData={vendorCompanyData}
          vendorUsers={vendorUsers}
          status={status}
          onCancel={closeModal}
          onConfirm={() => {
            setForceUpdateVendorList(true)
            closeModal()
          }}
        />
      ))
      openModal({ width: '500px' })
    },
    [context]
  )

  const columns = useMemo(
    () =>
      headerData({
        onShowDeleteModal: handleShowDeleteVendorModal,
        onShowReactivateOrInactivateModal: handleShowInactivateOrReactivateVendorModal
      }),
    [handleShowDeleteVendorModal, handleShowInactivateOrReactivateVendorModal]
  )

  const { pdbid } = user.user_metadata

  const {
    vendorList,
    listError,
    isDeleteError,
    allVendorHasMadeInitialRequest,
    allVendorIsRequesting
  } = useMemo(() => {
    const {
      data,
      isError,
      hasMadeInitialRequest: allVendorHasMadeInitialRequest,
      isRequesting: allVendorIsRequesting
    } = setupVendors.allVendors || {}
    const { isError: isDeleteError } = setupVendors.oneVendor || {}
    return {
      vendorList: data || [],
      listError: isError,
      isDeleteError,
      allVendorHasMadeInitialRequest,
      allVendorIsRequesting
    }
  }, [setupVendors])

  const prevContext = usePrevious(context)
  const prevSetupVendors = usePrevious(setupVendors)

  // useEffect(() => {
  //   allVendorListGet(pdbid, context)
  // }, [])

  useEffect(() => {
    const { isRequesting } = setupVendors.oneVendor
    const wasRequesting = _.get(prevSetupVendors, 'oneVendor.isRequesting')
    const updateComplete = !isRequesting && isRequesting !== wasRequesting

    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged || updateComplete) allVendorListGet(pdbid, context)
  }, [context, setupVendors, pdbid])

  useEffect(() => {
    if (forceUpdateVendorList) {
      allVendorListGet(pdbid, context)
      setForceUpdateVendorList(false)
    }
  }, [forceUpdateVendorList])

  const checkIsFilterApplied = useMemo(() => {
    const checkSingleFilter = (isFilterApplied || []).filter(
      o => (o.value || []).length !== 0
    )
    return checkSingleFilter
  }, [isFilterApplied])

  const handleResetFilter = () => tableRef.current?.clearFilter([])

  useEffect(() => {
    if (listError || isDeleteError)
      return setError('Something went wrong, please try again later.')
    setError('')
  }, [listError, isDeleteError])

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={icons.emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">
          Looks like you're the first one here!
        </p>
        <PermissionGate name={'add-vendor'}>
          <RestrictedElement>
            <p className="has-text-grey is-size-7 m-t-sm">
              Let's get started by adding one of your vendors.
            </p>
            <Link to="/setup/vendor-manage?v=new-vendor">
              <button className="button is-primary m-t-md">Add Vendor</button>
            </Link>
          </RestrictedElement>
        </PermissionGate>
      </div>
    </div>
  )

  const getLoadingState = () => <BigLoading />

  const exportCSV = () => {
    const { properties, projects } = user.user_metadata
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const rows = tableRef.current?.getRows() || []
    const selectedRows = tableRef.current?.getSelectedRows() || []

    const filterData = (selectedRows.length ? selectedRows : rows || []).map(
      list => {
        const {
          name,
          simplifiedServices,
          email,
          phone,
          status,
          integration_id
        } = list || {}
        return {
          'Vendor Name': `"${capitalize(name || '')}"`,
          'Services(s)': `"${simplifiedServices || ''}"` || '',
          Email: `"${email || ''}"`,
          'Phone Number': `"${phone || ''}"`,
          'Integration ID': `"${integration_id || ''}"`,
          Status: status || ''
        }
      }
    )

    const data = filterData
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-vendor-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const { allVendors } = setupVendors

  if (!allVendors.hasMadeInitialRequest || allVendors.isRequesting) {
    return getLoadingState()
  }
  if (allVendors.data.length < 1 && !listError) {
    return getEmptyState()
  }

  return (
    <div className="vendor-company-list-container">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">Vendors</h2>
          </div>
          <div className="action-button">
            <div className="vendor-action-buttons">
              <PermissionGate name={'add-vendor'}>
                <RestrictedElement>
                  <button
                    className="button is-success"
                    type="button"
                    onClick={() =>
                      history.push('/setup/vendor-manage?v=new-vendor')
                    }>
                    Add Vendor
                  </button>
                </RestrictedElement>
              </PermissionGate>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={handleResetFilter}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <div>
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
          </div>

          {error !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{error}</p>
                <button onClick={() => setError('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        {allVendorHasMadeInitialRequest && !allVendorIsRequesting ? (
          <VendorListTable
            data={vendorList}
            ref={tableRef}
            tableColumns={columns}
            setIsFilterApplied={setIsFilterApplied}
          />
        ) : (
          <BigLoading />
        )}
      </div>
    </div>
  )
}

VendorList.propTypes = {
  user: PropTypes.object,
  setupVendors: PropTypes.object,
  allVendorListGet: PropTypes.func
}

export default VendorList
