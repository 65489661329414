import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'
import { openSidebar, closeSidebar, toggleSidebar } from '../../modules/sidebar'
import { updateResponsive } from '../../modules/responsive'
import { requestContextChange } from '../../modules/context'
import {
  openTooltip,
  closeTooltip,
  setTooltipContent
} from '../../modules/tooltip'
import { logout, giveAccess } from '../../modules/auth'
import PrivateRoute from '../private-route'
import Alerts from '../alerts'

import Home from '../home'
import Sidebar from '../../parents/sidebar'
import Topbar from '../../parents/topbar'
import { LOGOS } from '../../components/logo/logo-mapping'
import Logo from '../../components/logo'
import BasicModal from '../../components/basic-modal'
import Tooltip from '../../components/tooltip'
import { Auth0Provider } from '../../components/lock/auth0-spa'
import Service from '../../service'
import AuthInit from './AuthInit'
import AppRoutes from './AppRoutes'
import { ProductFruits } from 'react-product-fruits'
import { Crisp } from 'crisp-sdk-web'
import { PRODUCT_FRUIT_ID } from '../../service/config'

import './base.scss'
import 'animate.css'
import './index.scss'
import Logout from './Logout'
import CrispChat from './crisp'

const App = ({
  user,
  history,
  context,
  tooltip,
  closeTooltip,
  openSidebar,
  closeSidebar,
  updateResponsive,
  sidebar,
  responsive,
  toggleSidebar,
  modal,
  requestContextChange,
  openTooltip,
  setTooltipContent,
  logout,
  auth,
  giveAccess
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let fakeEvent = {
      target: {
        innerWidth: window.innerWidth
      }
    }
    bodyResized(fakeEvent)
    window.addEventListener('resize', e => bodyResized(e))
    document.body.addEventListener('mousemove', e => onMouseMove(e))
  }, [])

  const onMouseMove = e => {
    const tt = document.getElementById('tooltip')
    if (tt === null) return
    const ttRect = tt.getBoundingClientRect()
    if (!tooltip.isActive || tooltip.target === null) return
    let isInBounds = false

    const hoverRect = tooltip.target.getBoundingClientRect()
    if (
      e.clientX > hoverRect.left - tooltip.moveDelta &&
      e.clientX < hoverRect.right + tooltip.moveDelta &&
      e.clientY < hoverRect.bottom + tooltip.moveDelta &&
      e.clientY > hoverRect.top - tooltip.moveDelta
    )
      isInBounds = true
    if (
      e.clientX > ttRect.left - tooltip.moveDelta &&
      e.clientX < ttRect.right + tooltip.moveDelta &&
      e.clientY < ttRect.bottom + tooltip.moveDelta &&
      e.clientY > ttRect.top - tooltip.moveDelta
    )
      isInBounds = true
    if (!isInBounds) {
      closeTooltip()
    }
  }

  const bodyResized = e => {
    const { innerWidth } = e.target
    let r = {}
    if (innerWidth >= 1408) {
      //Full HD
      r = {
        isMobile: false,
        isTouch: false,
        isDesktop: true,
        isWideScreen: true,
        isFullHD: true
      }
      if (!sidebar.isOpen) openSidebar()
    } else if (innerWidth >= 1216) {
      //Wide Screen
      r = {
        isMobile: false,
        isTouch: false,
        isDesktop: true,
        isWideScreen: true,
        isFullHD: false
      }
      if (!sidebar.isOpen) openSidebar()
    } else if (innerWidth >= 1024) {
      //Desktop
      r = {
        isMobile: false,
        isTouch: false,
        isDesktop: true,
        isWideScreen: false,
        isFullHD: false
      }
      if (!sidebar.isOpen) openSidebar()
    } else if (innerWidth >= 769) {
      //Touch
      r = {
        isMobile: false,
        isTouch: true,
        isDesktop: false,
        isWideScreen: false,
        isFullHD: false
      }
      if (sidebar.isOpen) closeSidebar()
    } else {
      //Mobile
      r = {
        isMobile: true,
        isTouch: true,
        isDesktop: false,
        isWideScreen: false,
        isFullHD: false
      }
      if (sidebar.isOpen) closeSidebar()
    }
    updateResponsive(r)
  }

  const { isTouch, isMobile } = responsive
  const { isOpen } = sidebar
  const { pathname, search } = history.location
  const fullPath = `${pathname}${search}`
  const { custom_logo } = user.user_metadata
  const logo =
    typeof custom_logo === 'undefined' ||
    custom_logo === null ||
    typeof LOGOS[custom_logo] === 'undefined'
      ? Service.LOGO
      : LOGOS[custom_logo]
  const isVendor = pathname.startsWith('/vendor')
  const isQc = pathname.startsWith('/qc')
  const isMainSite =
    auth.isAuthenticated &&
    !isVendor &&
    !isQc &&
    pathname !== '/onboarding/vendor/update-password'
  const sidebarVisibility =
    auth.isAuthenticated && pathname !== '/onboarding/vendor/update-password'
      ? true
      : false

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = appState => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }

  const handleStopLoading = value => setLoading(value)

  // Product fruit
  const userInfo = {
    username: user?.email // REQUIRED - any unique user identifier
  }

  useEffect(() => {
    if (user?.email) {
      const { user_metadata } = user || {}
      const { pdbid, pdbids } = user_metadata || {}
      Crisp.user.setEmail(user.email)
      Crisp.session.setData({
        email: user.email,
        property: context?.property || '',
        project: context?.project || '',
        pdbid: pdbid || pdbids[0]
      })
    }
  }, [user, context])

  return (
    <div className="is-fullheight">
      {user?.email !== null && (
        <ProductFruits
          workspaceCode={PRODUCT_FRUIT_ID}
          language="en"
          user={userInfo}
        />
      )}
      <Auth0Provider
        auth={auth}
        giveAccess={giveAccess}
        onRedirectCallback={onRedirectCallback}>
        <main className="is-fullheight">
          <div
            className={[
              'view-port',
              isTouch ? 'is-touch' : '',
              isMainSite ? 'is-main-site' : '',
              !loading &&
              isOpen &&
              pathname !== '/onboarding/vendor/update-password'
                ? 'has-open-sidebar'
                : ''
            ].join(' ')}>
            <Alerts />
            {isMainSite &&
              pathname !== '/onboarding/vendor/update-password' && (
                <Topbar
                  isLoading={loading}
                  toggleSidebar={toggleSidebar}
                  scootOver={!isTouch}
                  auth={auth}
                  context={context}
                  user={user}
                  requestContextChange={requestContextChange}
                  openTooltip={openTooltip}
                  closeTooltip={closeTooltip}
                  setTooltipContent={setTooltipContent}
                  tooltip={tooltip}
                  logout={logout}
                  sidebar={sidebar}
                />
              )}

            <AuthInit onStopLoading={handleStopLoading}>
              <AppRoutes />
            </AuthInit>

            <Switch>
              <PrivateRoute
                path="/"
                exact
                component={Home}
                skipContext={true}
              />
              <PrivateRoute
                skipContext={true}
                exact
                path="/logout"
                component={Logout}
              />
            </Switch>
          </div>
          {sidebarVisibility && !loading ? (
            <div
              className={`fixed-items ${
                isOpen && !loading ? 'open' : 'closed'
              }`}>
              <Logo
                isOpen={isOpen}
                logo={logo}
                isLoading={auth.isRequesting || user.isRequesting}
                closeSidebar={closeSidebar}
                sidebar={sidebar}
              />
              <Sidebar
                isOpen={isOpen}
                stayOpen={!isTouch}
                closeSidebar={closeSidebar}
                logout={logout}
                context={context}
                user={user}
                requestContextChange={requestContextChange}
                currentPath={fullPath}
              />
            </div>
          ) : null}
        </main>
      </Auth0Provider>

      {modal.isActive && (
        <BasicModal
          isActive={modal.isActive}
          modalContent={modal.content}
          width={modal.width}
          maxWidth={modal.maxWidth}
          overflow={modal.overflow}
          backgroundColor={modal.backgroundColor}
          shouldBeFullWidth={isMobile}
          additionalClasses={modal.additionalClasses}
          additionalBodyClasses={modal.additionalBodyClasses}
        />
      )}
      <Tooltip
        isActive={tooltip.isActive}
        isLast={tooltip.isLast}
        size={{
          height: tooltip.height,
          width: tooltip.width
        }}
        position={{
          top: tooltip.top,
          left: tooltip.left
        }}
        content={tooltip.content}
        className={tooltip.className}
        onMouseOut={tooltip.onMouseOut}
      />
    </div>
  )
}

App.propTypes = {
  history: PropTypes.object,
  sidebar: PropTypes.object,
  responsive: PropTypes.object,
  modal: PropTypes.object,
  tooltip: PropTypes.object,
  context: PropTypes.object,
  user: PropTypes.object,
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleSidebar: PropTypes.func,
  updateResponsive: PropTypes.func,
  requestContextChange: PropTypes.func,
  openTooltip: PropTypes.func,
  closeTooltip: PropTypes.func,
  setTooltipContent: PropTypes.func,
  logout: PropTypes.func,
  giveAccess: PropTypes.func
}

const mapStateToProps = ({
  sidebar,
  responsive,
  modal,
  tooltip,
  context,
  user,
  auth
}) => ({
  sidebar,
  responsive,
  modal,
  tooltip,
  context,
  user,
  auth
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openSidebar,
      closeSidebar,
      toggleSidebar,
      updateResponsive,
      requestContextChange,
      openTooltip,
      closeTooltip,
      setTooltipContent,
      logout,
      giveAccess
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
