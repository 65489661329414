import React from 'react'
import PropTypes from 'prop-types'
// import TurnableSpinner from '../turnable-spinner'
import TurnableLoader from '../turnable-loader'
import './index.scss'

/**
 * Component for showing the loading spinner
 * for any component in loading state
 *
 * @component
 * @example
 * return (
 *   <BigLoading msg="Now Loading..." />
 * )
 */
const BigLoading = ({ msg, style }) => (
  <div className="big-loading columns m-l-none m-r-none" style={style}>
    <div className="column is-one-fifth is-offset-two-fifths animated fadeIn has-text-centered p-t-lg">
      {/* <TurnableSpinner size="large" className="loading-spinner" /> */}
      <TurnableLoader />
      <p className="loading-text">{msg || 'Loading...'}</p>
    </div>
  </div>
)

BigLoading.propTypes = {
  /**
  * Custom message for the loading status
  */
  msg: PropTypes.string
}

export default BigLoading
