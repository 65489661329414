import approved from './icons/services-approved.svg'
import assigned from './icons/services-assigned.svg'
import inProgress from './icons/services-inprogress.svg'
import notAssigned from './icons/services-notassigned.svg'
import pendingApproval from './icons/services-pendingapproval.svg'
import partiallyOccupied from './icons/partially-occupied.svg'
import fullyVacant from './icons/fully-vacant.svg'
import readyForQC from './icons/services-readyforqc.svg'
import exportFile from './icons/services-export.svg'
import note from './icons/note.svg'
import noteSmall from './icons/note-small.svg'
import noteAdd from './icons/note-add.svg'
import noteAddSmall from './icons/note-add-small.svg'
import transfers from './icons/transfers.svg'
import transfersSmall from './icons/transfers-small.svg'
import goBack from './icons/goback.svg'
import goBackSmall from './icons/goback-small.svg'
import asIs from './icons/asis.svg'
import asIsSmall from './icons/asis-small.svg'
import priority from './icons/priority.svg'
import prioritySmall from './icons/priority.svg'
import carpet from './icons/carpet.svg'
import carpetSmall from './icons/carpet-small.svg'
import check from './icons/check.svg'
import history from './icons/history.svg'
import unblock from './icons/unblock.svg'
import NoReturn from './icons/no-return.svg'
import Invoice from './icons/invoice.svg'

export const servicesIcons = {
  approved,
  assigned,
  inProgress,
  notAssigned,
  pendingApproval,
  partiallyOccupied,
  fullyVacant,
  readyForQC,
  exportFile,
  note,
  noteSmall,
  noteAdd,
  noteAddSmall,
  transfers,
  transfersSmall,
  goBack,
  goBackSmall,
  asIs,
  asIsSmall,
  priority,
  prioritySmall,
  carpet,
  carpetSmall,
  check,
  history,
  unblock,
  NoReturn,
  Invoice
}
