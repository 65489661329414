import React from 'react'

const ErrorTemplateNoPadding = ({ children }) => {
  return (
    <div className="columns is-vcentered mb-0" style={{ marginTop: '0px' }}>
      <div className="column is-full  error-top-spacing">
        <div className="columns is-desktop is-mobile">
          <div className="column">
            <div className="notification is-danger is-light is-flex">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorTemplateNoPadding
