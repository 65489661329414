import React from 'react'
import PropTypes from 'prop-types'
import { icons } from './icons'
import SetupGoalsServicesModalCreate from '../setup-goals-services-modal-create'
import moment from 'moment'
import RestrictedElement from '../../containers/restricted-element'
import PermissionGate from '../../components/permission-gate'

const SetupGoalsServices = ({
  context,
  user,
  openModal,
  setModalContent,
  closeModal,
  setupGoals,
  setupGoalLineCreate,
  setupGoalLineUpdate,
  setupAllGoalsGet,
  isFaded
}) => {
  const getServiceGoalModal = (goalLine = {}) => {
    return (
      <SetupGoalsServicesModalCreate
        context={context}
        user={user}
        closeModal={closeModal}
        setupGoals={setupGoals}
        setupGoalLineCreate={setupGoalLineCreate}
        setupGoalLineUpdate={setupGoalLineUpdate}
        setupAllGoalsGet={setupAllGoalsGet}
        {...goalLine}
      />
    )
  }

  const getServiceCards = () => {
    const { data } = setupGoals.goal
    const { goal_lines = [] } = data
    let template = []
    for (let i = 0; i < goal_lines.length; i += 1) {
      const gl = goal_lines[i]
      const opacity = isFaded ? '0.5' : '1'

      const goalCard = (
        <div
          className="box is-shadowless has-transition-opacity"
          key={`goal-service-${i}`}
          style={{ opacity }}>
          <PermissionGate name={'edit-service-goal'}>
            <RestrictedElement>
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '0', right: '0' }}>
                  <img
                    alt="Edit Service"
                    src={icons.edit}
                    className="is-inline is-pointer"
                    onClick={() => {
                      setModalContent(() => getServiceGoalModal(gl))
                      openModal({ width: '480px', overflow: 'visible' })
                    }}
                  />
                </div>
              </div>
            </RestrictedElement>
          </PermissionGate>

          <div className="columns is-desktop is-mobile">
            <div className="column is-narrow p-b-none">
              <img alt="Service Icon" src={icons.services} />
            </div>
            <div className="column is-one-third p-b-none">
              <p
                className="is-size-4 m-b-sm has-text-weight-normal"
                style={{
                  overflow: 'hidden',
                  height: '36px'
                }}>
                {gl.servicetype === 'Cleaning' ? 'Clean' : gl.servicetype}
              </p>
              {gl.goal_rooms && (
                <p
                  className="is-size-6 has-text-link m-b-sm"
                  style={{
                    height: '24px',
                    overflow: 'hidden'
                  }}>
                  Spaces Per Day
                </p>
              )}
              <p className="is-size-6 m-b-sm">Start Date</p>
              <p className="is-size-6 m-b-sm">End Date</p>
              {gl.goal_rooms && (
                <p className="is-size-6 m-b-sm">Number of Spaces</p>
              )}
            </div>
            <div className="column is-half has-text-right p-b-none">
              <p
                className="is-size-4 m-b-sm"
                style={{
                  height: '36px',
                  overflow: 'hidden'
                }}>
                &nbsp;
              </p>
              {gl.goal_rooms && (
                <p
                  className="is-size-6 has-text-link m-b-sm"
                  style={{
                    height: '24px',
                    overflow: 'hidden'
                  }}>
                  {String(gl.rooms_per_day)}
                </p>
              )}
              <p
                className="is-size-6 m-b-sm"
                style={{
                  height: '24px',
                  overflow: 'hidden'
                }}>
                {moment(gl.start_date, 'MM-DD-YYYY').format('MM/DD/YYYY')}
              </p>
              <p
                className="is-size-6 m-b-sm"
                style={{
                  height: '24px',
                  overflow: 'hidden'
                }}>
                {moment(gl.end_date, 'MM-DD-YYYY').format('MM/DD/YYYY')}
              </p>
              {gl.goal_rooms && (
                <p
                  className="is-size-6 m-b-sm"
                  style={{
                    height: '24px',
                    overflow: 'hidden'
                  }}>
                  {gl.goal_rooms}
                </p>
              )}
            </div>
          </div>
        </div>
      )

      template.push(goalCard)
    }

    const groupedCards = []

    for (let i = 0; i < template.length; i++) {
      if (i % 2 === 0) {
        groupedCards.push(
          <div className="columns m-b-xs" key={i}>
            <div className="column is-half m-t-xs m-b-xs p-l-none p-r-none">
              {template[i]}
            </div>
            <div className="column is-half m-t-xs m-b-xs m-l-md p-l-none p-r-none">
              {template[i + 1]}
            </div>
          </div>
        )
      }
    }

    return groupedCards
  }

  const opacity = isFaded ? '0.5' : '1'
  return (
    <>
      {getServiceCards()}
      <PermissionGate name={'add-service-goal'}>
        <RestrictedElement>
          <div className="columns m-t-xs">
            <div
              className={`box is-shadowless column is-half ${
                isFaded ? '' : 'is-pointer'
              } has-transition-opacity`}
              onClick={() => {
                if (!isFaded) {
                  setModalContent(() => getServiceGoalModal())
                  openModal({ width: '480px', overflow: 'visible' })
                }
              }}
              style={{ opacity }}>
              <div className="columns is-vcentered">
                <div
                  className="column is-size-6 is-unselectable"
                  style={{ lineHeight: '36px' }}>
                  <img
                    alt="Add Service Goal"
                    src={icons.setupAdd}
                    className="m-r-sm"
                    style={{ marginTop: '-2px' }}
                  />
                  Add Service Goal
                </div>
              </div>
            </div>
          </div>
        </RestrictedElement>
      </PermissionGate>
    </>
  )
}

SetupGoalsServices.propTypes = {
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  isFaded: PropTypes.bool
}

export default SetupGoalsServices
