import _ from 'lodash'
import Short from 'short-uuid'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { usePrevious } from '../../helpers/hooks'
import TurnableSpinner from '../../components/turnable-spinner'
import NotesModalEdit from '../notes-modal-edit'
import { icons } from './icons'
import RestrictedElement from '../../containers/restricted-element'
import './index.scss'
import PermissionGate from '../../components/permission-gate'

const NotesModal = ({
  user,
  auth,
  context,
  onClose,
  onSuccess,
  unit,
  unitId,
  service,
  serviceTypeId,
  notes,
  unitNotesGet,
  unitNotesCreate,
  unitNotesUpdate,
  unitNotesDelete,
  setAlert,
  getUnitServiceNotes,
  unitServiceNotes
}) => {
  const [filteredNotes, setFilteredNotes] = useState(_.get(notes, 'data'))
  const [note, setNote] = useState('')
  const [type, setType] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState({ charge: '0' })
  const [noteId, setNoteId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [adding, setAdding] = useState(filteredNotes.length === 0)
  const prevUnitId = usePrevious(unitId)
  const [saveRequested, setSaveRequested] = useState(false)
  const [deleteRequested, setDeleteRequested] = useState(false)
  const [isNoteLoading, setIsNoteLoading] = useState(true)

  const onNoteGetSuccess = () => {
    setIsNoteLoading(false)
  }
  const onNoteGetError = () => {
    setIsNoteLoading(false)
  }

  useEffect(() => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { hasMadeInitialRequest, isRequesting } = notes
    const { pdbid } = user.user_metadata
    if (
      (isAuthenticated &&
        hasMgmtToken &&
        pdbid !== null &&
        (!hasMadeInitialRequest && !isRequesting)) ||
      prevUnitId !== unitId
    ) {
      setIsNoteLoading(true)

      if (unitServiceNotes) {
        getUnitServiceNotes(
          pdbid,
          context,
          unitId,
          serviceTypeId,
          onNoteGetSuccess,
          onNoteGetError
        )
      } else {
        unitNotesGet(pdbid, context, unitId, onNoteGetSuccess, onNoteGetError)
      }
    }
  }, [
    user.user_metadata.pdbid,
    context.project,
    context.property,
    unitId,
    auth.isAuthenticated,
    auth.hasMgmtToken,
    unitNotesGet,
    getUnitServiceNotes,
    unitServiceNotes,
    notes.hasMadeInitialRequest,
    notes.isRequesting
  ])

  useEffect(() => {
    if (noteId) {
      const selectedNote = _.find(filteredNotes, n => n.id === noteId)
      setNote(_.get(selectedNote, 'note', ''))
      setType(_.get(selectedNote, 'type', ''))
      const charge = _.get(selectedNote, 'charge', '') || 0
      setAdditionalInfo({ charge })
    } else {
      setNote('')
      setType('')
    }
  }, [noteId, filteredNotes])

  useEffect(() => {
    if (unitId && !notes.isRequesting && !notes.isRequestingUpdate)
      setFilteredNotes(_.get(notes, 'data'))
  }, [unitId, notes.isRequesting, notes.isRequestingUpdate])

  useEffect(() => {
    setAdding(filteredNotes.length === 0)
  }, [filteredNotes])

  useEffect(() => {
    if (saveRequested && !notes.isRequestingUpdate && notes.isErrorUpdate) {
      setSaveRequested(false)
      setAlert(
        'An error occurred while trying to update the note. Please try again later.',
        'danger'
      )
      onClose()
      resetModal()
    } else if (
      saveRequested &&
      !notes.isRequestingUpdate &&
      !notes.isErrorUpdate
    ) {
      setSaveRequested(false)
      setAlert('Note updated successfully.', 'success')
      onSuccess()
      resetModal()
    }
  }, [saveRequested, notes.isRequestingUpdate, notes.isErrorUpdate])

  useEffect(() => {
    if (deleteRequested && !notes.isRequestingUpdate && notes.isErrorUpdate) {
      setDeleteRequested(false)
      setAlert(
        'An error occurred while trying to delete the note. Please try again later.',
        'danger'
      )
    } else if (
      deleteRequested &&
      !notes.isRequestingUpdate &&
      !notes.isErrorUpdate
    ) {
      setDeleteRequested(false)
      setAlert('Note deleted successfully.', 'success')
      onSuccess()
      resetModal()
    }
  }, [deleteRequested, notes.isRequestingUpdate, notes.isErrorUpdate])

  const resetModal = () => {
    setNote('')
    setType('')
    setNoteId(null)
    setAdding(false)
  }

  const handleAdditonalInfo = (key, value) => {
    setAdditionalInfo(prevInfo => {
      return {
        ...prevInfo,
        [key]: value
      }
    })
  }

  if (notes.isRequesting) {
    return (
      <div className="columns">
        <div className="column fadeIn has-text-centered p-t-md">
          <TurnableSpinner size="large" className="loading-spinner" />
          <p className="loading-text">Loading...</p>
        </div>
      </div>
    )
  }

  if (saveRequested && notes.isRequestingUpdate) {
    return (
      <div className="columns">
        <div className="column fadeIn has-text-centered p-t-md">
          <TurnableSpinner size="large" className="loading-spinner" />
          <p className="loading-text">Updating...</p>
        </div>
      </div>
    )
  } else if (saveRequested && !notes.isRequestingUpdate) {
    return null
  }

  if (deleteRequested && notes.isRequestingUpdate) {
    return (
      <div className="columns">
        <div className="column fadeIn has-text-centered p-t-md">
          <TurnableSpinner size="large" className="loading-spinner" />
          <p className="loading-text">Deleting...</p>
        </div>
      </div>
    )
  } else if (deleteRequested && !notes.isRequestingUpdate) {
    return null
  }

  return (
    <div>
      {isNoteLoading ? (
        <div className="columns">
          <div className="column fadeIn has-text-centered p-t-md">
            <TurnableSpinner size="large" className="loading-spinner" />
            <p className="loading-text">Loading...</p>
          </div>
        </div>
      ) : (
        <>
          <div className="columns is-desktop is-mobile">
            <div className="column">
              <p className="heading-3 is-pulled-left">Notes</p>
              <p
                className="close is-pointer has-text-grey-light"
                onClick={() => {
                  onClose()
                  resetModal()
                }}>
                <img alt="Delete Note" src={icons.close} />
              </p>
            </div>
          </div>
          <div className="columns is-desktop is-mobile wrapping-container">
            <div className="column p-t-none is-full modal-subtitle">
              Unit {unit}
            </div>
          </div>
          {filteredNotes.length === 0 ? (
            <RestrictedElement>
              <div className="columns is-desktop is-mobile wrapping-container">
                <div className="column is-full">
                  <i>
                    Looks like you’re the first one to add a note to this unit…
                  </i>
                </div>
              </div>
            </RestrictedElement>
          ) : (
            <div className="notes-container p-b-md m-b-md">
              {_.map(filteredNotes, n => {
                if (n.id !== noteId) {
                  return (
                    <div className="columns" key={Short.uuid()}>
                      <div className="column note-item">
                        <div className="columns p-b-xs m-b-none">
                          <RestrictedElement>
                            <PermissionGate name="manage-notes">
                              <div className="column is-full p-b-none">
                                <span className="note-title">
                                  {n.creation_date} - {n.type}
                                </span>
                                {n.id !== deleteId && (
                                  <>
                                    <a
                                      href="#!"
                                      className={`is-pulled-right ${noteId &&
                                        'disabled-events'}`}>
                                      <img
                                        alt="Delete Note"
                                        src={
                                          !noteId
                                            ? icons.trash
                                            : icons.trashDisabled
                                        }
                                        className={`${!note.id &&
                                          'is-pointer'}`}
                                        onClick={() =>
                                          !noteId ? setDeleteId(n.id) : null
                                        }
                                      />
                                    </a>
                                    {n?.type?.toLowerCase() !==
                                      'additional charge' && (
                                      <a
                                        href="#!"
                                        className={`is-pulled-right m-r-sm ${noteId &&
                                          'disabled-events'}`}>
                                        <img
                                          alt="Edit Note"
                                          className={`${!note.id &&
                                            'is-pointer'}`}
                                          src={
                                            !noteId
                                              ? icons.pencil
                                              : icons.pencilDisabled
                                          }
                                          onClick={() =>
                                            !noteId ? setNoteId(n.id) : null
                                          }
                                        />
                                      </a>
                                    )}
                                  </>
                                )}
                              </div>
                            </PermissionGate>
                          </RestrictedElement>
                        </div>
                        <span className="note-text">
                          {n.charge && (
                            <span className="m-r-sm">${n.charge}.</span>
                          )}
                          {n.note}
                        </span>
                        {n.id === deleteId && (
                          <div className="columns">
                            <div className="column">
                              Do you really want to delete this note?
                            </div>
                            <div className="column is-narrow is-pulled-right">
                              <button
                                className={'button is-danger m-r-sm'}
                                onClick={() => {
                                  setDeleteRequested(true)
                                  unitNotesDelete(
                                    _.get(user, 'user_metadata.pdbid'),
                                    context,
                                    unitId,
                                    { note_id: n.id }
                                  )
                                }}>
                                Yes
                              </button>
                              <button
                                className={'button is-default'}
                                onClick={() => {
                                  setDeleteId(null)
                                }}>
                                No
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="columns" key={Short.uuid()}>
                      <div className="column edit-note-container">
                        <NotesModalEdit
                          adding={false}
                          onAdditionalInfoChanged={handleAdditonalInfo}
                          onNoteChanged={nc => setNote(nc)}
                          onTypeChanged={tc => setType(tc)}
                          additionalInfo={additionalInfo}
                          note={note}
                          type={type}
                          allowCancel
                          onCancel={() => setNoteId(null)}
                        />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          )}
          {adding ? (
            <div className="columns">
              <div className="column">
                <NotesModalEdit
                  adding
                  onAdditionalInfoChanged={handleAdditonalInfo}
                  onNoteChanged={n => setNote(n)}
                  onTypeChanged={t => setType(t)}
                  additionalInfo={additionalInfo}
                  note={note}
                  type={type}
                  allowCancel={filteredNotes.length > 0}
                  onCancel={() => setAdding(false)}
                />
              </div>
            </div>
          ) : (
            <RestrictedElement>
              <PermissionGate name="manage-notes">
                <div className="columns is-vcentered m-b-none">
                  <div
                    className="column is-size-6 is-unselectable"
                    style={{ lineHeight: '36px' }}>
                    <a
                      href="#!"
                      onClick={() => setAdding(true)}
                      className="add-note">
                      <img
                        alt="Add Note"
                        src={icons.setupAdd}
                        className="m-r-sm"
                        style={{ marginTop: '-2px', height: 24, width: 24 }}
                      />
                      Add a Note
                    </a>
                  </div>
                </div>
              </PermissionGate>
            </RestrictedElement>
          )}

          {(noteId || adding) && (
            <div className="columns is-vcentered m-b-sm m-t-sm">
              <div className="center-container">
                <button
                  className="button main-button is-secondary m-r-md"
                  onClick={() => {
                    onClose()
                    resetModal()
                  }}>
                  Close
                </button>
                <RestrictedElement>
                  <button
                    className="button main-button is-primary"
                    disabled={
                      type === '' ||
                      (type.toLowerCase() === 'additional charge' &&
                        !additionalInfo.charge)
                    }
                    onClick={() => {
                      const body = {
                        servicetype: service !== 'all' ? service : '',
                        servicetype_id: service !== 'all' ? serviceTypeId : '',
                        note,
                        type,
                        creation_date: moment().format('DD-MMM-YY'),
                        created_by: user.name,
                        ...additionalInfo
                      }
                      if (adding)
                        unitNotesCreate(
                          _.get(user, 'user_metadata.pdbid'),
                          context,
                          unitId,
                          body
                        )
                      else
                        unitNotesUpdate(
                          _.get(user, 'user_metadata.pdbid'),
                          context,
                          unitId,
                          { ...body, note_id: noteId }
                        )
                      setSaveRequested(true)
                    }}>
                    Save
                  </button>
                </RestrictedElement>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default NotesModal
