import _ from 'lodash'
import permissions from './permissions'
import sectionPermissions from './section-permissions.json'

const filteredKeys = url =>
  _.filter(permissions, p => new RegExp(p.pattern).test(url))

export const sectionAllowed = (url, search, user) => {
  const composedUrl = search ? `${url}?${search}` : url
  const filtered = filteredKeys(composedUrl)

  if (filtered && filtered.length) {
    return (
      _.every(
        filtered,
        f =>
          !f.accessRoles || _.intersection(f.accessRoles, user.roles).length > 0
      ) &&
      _.every(
        filtered,
        f =>
          !f.accessTags ||
          _.intersection(f.accessTags, Object.keys(user.user_metadata)).length >
            0
      )
    )
  }
  return true
}

export const sectionManageable = (url, user) => {
  const filtered = filteredKeys(url)
  if (filtered && filtered.length) {
    return (
      _.every(
        filtered,
        f =>
          !f.manageRoles || _.intersection(f.manageRoles, user.roles).length > 0
      ) &&
      _.every(
        filtered,
        f =>
          !f.manageTags ||
          _.intersection(f.manageTags, Object.keys(user.user_metadata)).length >
            0
      )
    )
  }
  return true
}

export const checkPermission = (name, user) => {
  const { roles } = user || {}
  const sectionPermission = (sectionPermissions || []).find(
    section => section.name === name
  )

  if (!_.isEmpty(sectionPermission)) {
    const { accessRoles } = sectionPermission
    return (roles || []).some(role => accessRoles.indexOf(role) >= 0)
  }

  return false
}
