import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import RequestsVendorsMain from '../../parents/requests-vendors-main'
import BigLoading from '../../components/big-loading'
import {
  allRequestVendorGet,
  addVendor,
  checkVendorExists,
  deleteVendor,
  checkVendorRequest
} from '../../modules/request-vendors'
import {
  allRequestRFPGet,
  addRFPRequest,
  checkRFPVendorExists,
  declineRFPRequest
} from '../../modules/request-rfp'
import { setModalContent, openModal, closeModal } from '../../modules/modal'

const RequestVendor = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <RequestsVendorsMain {...props} />
  }

  return <BigLoading />
}

RequestVendor.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  requestVendors: PropTypes.object,
  requestRFPS: PropTypes.object,
  allRequestVendorGet: PropTypes.func,
  allRequestRFPGet: PropTypes.func,
  addVendor: PropTypes.func,
  checkVendorExists: PropTypes.func,
  addRFPRequest: PropTypes.func,
  checkRFPVendorExists: PropTypes.func,
}

const mapStateToProps = ({ user, context, modal, requestVendors, requestRFPS }) => ({
  user,
  context,
  modal,
  requestVendors,
  requestRFPS
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      allRequestVendorGet,
      allRequestRFPGet,
      addVendor,
      checkVendorExists,
      addRFPRequest,
      deleteVendor,
      checkRFPVendorExists,
      declineRFPRequest,
      checkVendorRequest
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestVendor)
)
