import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { edit } from '../../ui/icons'

const ReviewAndSubmit = ({ vendorQuote, activeProgressItem, vendorQuotationBusinessInfo, updateVendorQuoteStatus, updateActiveProgressItem, onChangeCurrentActiveTab, onChangeReviewAndSubmit, id, user, context, updateVendorBusinessInformation }) => {
  // Get parent services names
  const data = Object.keys(vendorQuote || {}) || []
  // Business Information
  const { data: businessData } = vendorQuotationBusinessInfo || {}
  const { address_line_1, address_line_2, currency, phone, tax_id, term, city, state, zip } = businessData || {}
  // Get completed list from Progress Item
  const { completed } = activeProgressItem || {}

  // Update Review and Avtive tab status
  const onSuccess = (status) => {
    if(status === 'success') {
      const getFirstService = data[0] || ''
      onChangeReviewAndSubmit(false,getFirstService)
      onChangeCurrentActiveTab(getFirstService)
      updateVendorQuoteStatus('in-review')
    }
  }

  // Submit Quote
  const onSubmitQuote = () => {
    const { user_metadata } = user || {}
    const {  pdbid, pdbids } = user_metadata || {}
    const date = moment().format('YYYY-MM-DD')
    const body = {
      id: id || '',
      data: {
        submitted_at : date
      }
    }
    updateVendorBusinessInformation({
      pdbid: (pdbid || pdbids[0]),
      context,
      body,
      onSuccess,
      oldData: businessData || {},
    })
  }
  return (
    <div className="review-submit-wrapper">
      <div className="review-submit-heading">
        <h2>
            Review Your Responses
        </h2>
        <div className="is-flex is-align-items-center">
          <p>
                Please review all the responses provided. If you need to make changes, click the edit icon next to that section. 
                When you are ready to submit this RFP, click “Submit.”
          </p>
          <button
            className="button is-success"
            type="button"
            onClick={(e) => {
              e.preventDefault()
              onSubmitQuote()
              // Update progress item list to set Review and Submit completed
              updateActiveProgressItem([...completed, 'review_submit'], 'completed')
            }}
          >
                Submit
          </button>
        </div>
      </div>
      {(data || []).map((pService, i) => {
        const data = (vendorQuote || {})
        const { services } = data[pService] || {}
        const getSubServices = Object.keys(services || {}) || []
        return <div className="service-tabs-body" key={i}>
          <h3>
            {pService || ''}
          </h3>
          <button
            type="button"
            className="edit-service-button"
            onClick={(e) => {
              e.preventDefault()
              // To change Review and submit view and goes to  default tab view
              onChangeReviewAndSubmit(false,pService)
              // Change selected tab to currently edit tab
              onChangeCurrentActiveTab(pService)
            }}
          >
            <img
              src={edit}
              alt="Edit Service"
            />
          </button>
          { pService === 'Business Information' ? <div className="business-information-list">
            <h4>Address</h4>
            <p className="m-0">{`${address_line_1 || 'N/A'} ${address_line_2 || ''}`}</p>
            <p>{`${city || ''}, ${state || ''} ${zip || ''}`}</p>
            <h4>Phone</h4>
            <p>{phone || 'N/A'}</p>
            <h4>Tax ID</h4>
            <p>{tax_id || 'N/A'}</p>
            <h4>Currency</h4>
            <p>{currency || 'N/A'}</p>
            <h4>Term</h4>
            <p>{term || 'N/A'}</p>
          </div> :  <div>
            {(getSubServices || []).map((sServices) => {
              const subData = services[sServices || ''] || []
              if((subData || []).length !== 0) {
                return <div key={sServices} className="sub-services-list">
                  <h4>
                    {sServices || ''}
                  </h4>
                  <div>
                    {subData.map((fieldData,i) => {
                      const {inputType, type} = fieldData || {}
                      const { capacity, cost} = inputType || {}
                      const { value:capacityValue  } = capacity || {}
                      const { value:costValue  } = cost || {}
                      return (sServices || '') === 'Capacity By Date' ? <p key={i} >{`${type} - ${capacityValue || 0}`}</p> : <p key={i}>{`${type} - $${costValue || 0}`}</p>
                    })}
                  </div>
                </div>
              }
            })}
          </div>}
        </div>
      })}
    </div>
  )
}

ReviewAndSubmit.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  vendorQuote: PropTypes.object,
  activeProgressItem: PropTypes.object,
  vendorQuotationBusinessInfo: PropTypes.object,
  updateActiveProgressItem: PropTypes.func,
  updateVendorQuoteStatus: PropTypes.func,
  onChangeCurrentActiveTab: PropTypes.func,
  onChangeReviewAndSubmit: PropTypes.func,
  id: PropTypes.string,
  updateVendorBusinessInformation: PropTypes.func,
}

export default ReviewAndSubmit

