import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

/**
 * Component for showing complete address
 *
 * @component
 * @example
 * const defaults = {
 *  toggle: () => setOpened(true),
 *  dropwdownOpened: true,
 * }
 * return (
 *   <DropdownTogglerBtn toggle={defaults.toggle} dropwdownOpened={defaults.dropwdownOpened} />
 * )
 */
const DropdownTogglerBtn = ({ toggle, dropwdownOpened, ...rest }) => {
  return (
    <button {...rest} onClick={() => toggle()}>
      <FontAwesomeIcon
        icon={faChevronDown}
        className={`down-arrow m-r-sm ${dropwdownOpened ? 'is-upsidedown' : ''}`}
      />
      {dropwdownOpened ? 'Hide Details' : 'Show Details'}
    </button>
  )
}

DropdownTogglerBtn.propTypes = {
  /**
   * Toggle function to execute when clicking the button
   */
  toggle: PropTypes.func,
  /**
   * Whether the dropdown is opened or not
   */
  dropwdownOpened: PropTypes.bool
}

DropdownTogglerBtn.defaultProps = {
  toggle: () => null,
  dropwdownOpened: false,
}

export default DropdownTogglerBtn
