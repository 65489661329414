import React from 'react'
import BigLoading from '../../components/big-loading'
import Breadcrumbs from '../../parents/breadcrumbs'

const Page = ({ children, loading, isBreadcrumbClassApplied = true }) => {
  if (loading) return <BigLoading />

  return (
    <div className="container animated fadeIn">
      <div className="section">
        <div className="columns">
          <div
            className={`column ${isBreadcrumbClassApplied &&
              'is-full p-none'}`}>
            <Breadcrumbs />
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Page
