import React, { useEffect, useState } from 'react'

const ToggleActiveUser = ({ onChange, isActive, prevIsActive }) => {
  const [isChecked, setChecked] = useState(isActive || false)

  const handleChange = () => {
    setChecked(!isChecked)
    onChange(!isChecked)
  }

  useEffect(() => {
    if (prevIsActive !== undefined && prevIsActive !== isChecked) {
      setChecked(isActive)
    }
  }, [isActive])

  return (
    <div className="toggle-container">
      <span className="block-text is-size-5">Block User</span>
      <label className="toggle-control">
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <span className="control"></span>
      </label>
      <span className="active-text is-size-5">Active User</span>
    </div>
  )
}

export default ToggleActiveUser
