import _, { isEmpty } from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import moment from 'moment'
import { setAlert } from './alerts'
import {
  capitalize,
  getUTCToLocalDateTime,
  removeExtraWhiteSpaces
} from '../utils/helperFunctions'

export const SETUP_ALL_USERS_GET_REQUESTED =
  'setup-users/SETUP_ALL_USERS_GET_REQUESTED'
export const SETUP_ALL_USERS_GET_FAIL = 'setup-users/SETUP_ALL_USERS_GET_FAIL'
export const SETUP_ALL_USERS_GET_SUCCESS =
  'setup-users/SETUP_ALL_USERS_GET_SUCCESS'

export const SETUP_ALL_ROLES_GET_REQUESTED =
  'setup-users/SETUP_ALL_ROLES_GET_REQUESTED'
export const SETUP_ALL_ROLES_GET_FAIL = 'setup-users/SETUP_ALL_ROLES_GET_FAIL'
export const SETUP_ALL_ROLES_GET_SUCCESS =
  'setup-users/SETUP_ALL_ROLES_GET_SUCCESS'

export const SETUP_ONE_USER_GET_REQUESTED =
  'setup-users/SETUP_ONE_USER_GET_REQUESTED'
export const SETUP_ONE_USER_GET_FAIL = 'setup-users/SETUP_ONE_USER_GET_FAIL'
export const SETUP_ONE_USER_GET_SUCCESS =
  'setup-users/SETUP_ONE_USER_GET_SUCCESS'

export const CLEAR_ONE_USER = 'setup-users/CLEAR_ONE_USER'

export const SETUP_ONE_USER_CREATE_REQUESTED =
  'setup-users/SETUP_ONE_USER_CREATE_REQUESTED'
export const SETUP_ONE_USER_CREATE_FAIL =
  'setup-users/SETUP_ONE_USER_CREATE_FAIL'
export const SETUP_ONE_USER_CREATE_SUCCESS =
  'setup-users/SETUP_ONE_USER_CREATE_SUCCESS'

export const SETUP_ONE_USER_UPDATE_REQUESTED =
  'setup-users/SETUP_ONE_USER_UPDATE_REQUESTED'
export const SETUP_ONE_USER_UPDATE_FAIL =
  'setup-users/SETUP_ONE_USER_UPDATE_FAIL'
export const SETUP_ONE_USER_UPDATE_SUCCESS =
  'setup-users/SETUP_ONE_USER_UPDATE_SUCCESS'

export const SETUP_ONE_USER_DELETE_REQUESTED =
  'setup-users/SETUP_ONE_USER_DELETE_REQUESTED'
export const SETUP_ONE_USER_DELETE_FAIL =
  'setup-users/SETUP_ONE_USER_DELETE_FAIL'
export const SETUP_ONE_USER_DELETE_SUCCESS =
  'setup-users/SETUP_ONE_USER_DELETE_SUCCESS'

export const SETUP_ONE_USER_TOGGLE_PROJECT =
  'setup-users/SETUP_ONE_USER_TOGGLE_PROJECT'
export const SETUP_ONE_USER_TOGGLE_PROPERTY =
  'setup-users/SETUP_ONE_USER_TOGGLE_PROPERTY'

export const SETUP_ONE_USER_SET_UPDATING =
  'setup-users/SETUP_ONE_USER_SET_UPDATING'

export const SETUP_ONE_USER_RESET = 'setup-users/SETUP_ONE_USER_RESET'

export const SETUP_ONE_USER_BLOCK_REQUESTED =
  'setup-users/SETUP_ONE_USER_BLOCK_REQUESTED'
export const SETUP_ONE_USER_BLOCK_SUCCESS =
  'setup-users/SETUP_ONE_USER_BLOCK_SUCCESS'
export const SETUP_ONE_USER_BLOCK_FAIL = 'setup-users/SETUP_ONE_USER_BLOCK_FAIL'

export const SETUP_SINGLE_USER_UPDATE_REQUESTED =
  'setup-users/SETUP_SINGLE_USER_UPDATE_REQUESTED'
export const SETUP_SINGLE_USER_UPDATE_SUCCESS =
  'setup-users/SETUP_SINGLE_USER_UPDATE_SUCCESS'

export const SETUP_SINGLE_USER_CREATE_REQUESTED =
  'setup-users/SETUP_SINGLE_USER_CREATE_REQUESTED'
export const SETUP_SINGLE_USER_CREATE_SUCCESS =
  'setup-users/SETUP_SINGLE_USER_CREATE_SUCCESS'

export const SETUP_SINGLE_USER_RESET_PASSWORD_REQUESTED =
  'setup-users/SETUP_SINGLE_USER_RESET_PASSWORD_REQUESTED'
export const SETUP_SINGLE_USER_RESET_PASSWORD_SUCCESS =
  'setup-users/SETUP_SINGLE_USER_RESET_PASSWORD_SUCCESS'

export const GET_USER_DETAILS_REQUESTED = 'GET_USER_DETAILS_REQUESTED'
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_RESET = 'GET_USER_DETAILS_RESET'

export const GET_ALL_PROPERTIES_LIST_REQUESTED =
  'GET_ALL_PROPERTIES_LIST_REQUESTED'
export const GET_ALL_PROPERTIES_LIST_SUCCESS = 'GET_ALL_PROPERTIES_LIST_SUCCESS'
export const GET_ALL_PROPERTIES_LIST_FAIL = 'GET_ALL_PROPERTIES_LIST_FAIL'

export const GET_ALL_ROLES_LIST = 'GET_ALL_ROLES_LIST'
export const GET_ALL_USER_REQUESTED = 'GET_ALL_USER_REQUESTED'
export const GET_ALL_USER_FAIL = 'GET_ALL_USER_FAIL'
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS'
export const GET_EMAIL_EXIST_REQUESTED = 'GET_EMAIL_EXIST_REQUESTED'
export const GET_EMAIL_EXIST_FAIL = 'GET_EMAIL_EXIST_FAIL'
export const GET_EMAIL_EXIST_SUCCESS = 'GET_EMAIL_EXIST_SUCCESS'
export const RESET_EMAIL_EXIST = 'RESET_EMAIL_EXIST'
export const GET_EMAIL_EXIST_SERVER_FAIL = 'GET_EMAIL_EXIST_SERVER_FAIL'
export const ALL_ASSOCIATED_VENDOR_USERS_REQUESTED =
  'ALL_ASSOCIATED_VENDOR_USERS_REQUESTED'
export const ALL_ASSOCIATED_VENDOR_USERS_FAIL =
  'ALL_ASSOCIATED_VENDOR_USERS_FAIL'
export const ALL_ASSOCIATED_VENDOR_USERS_SUCCESS =
  'ALL_ASSOCIATED_VENDOR_USERS_SUCCESS'

const initialState = {
  createUserLoader: false,
  updateUserLoader: false,
  blockUserLoader: false,

  allRoles: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  allUsers: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  oneUser: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    isUpdating: false,
    data: {}
  },
  userDetail: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: {}
  },
  tempUser: {},
  propertiesList: [],
  propertiesListLoading: false,
  rolesList: [],
  verifiedEmail: {
    emailExists: false,
    emailVerified: false,
    email: '',
    properties: null,
    loading: false,
    error: false,
    data: {},
    serverError: false
  },
  associatedVendorUserList: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    isUpdating: false,
    data: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_SINGLE_USER_CREATE_REQUESTED:
      return {
        ...state,
        createUserLoader: true
      }
    case SETUP_SINGLE_USER_UPDATE_REQUESTED:
      return {
        ...state,
        updateUserLoader: true
      }
    case SETUP_ALL_ROLES_GET_REQUESTED:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_EMAIL_EXIST_REQUESTED:
      return {
        ...state,
        verifiedEmail: {
          ...state.verifiedEmail,
          emailExists: false,
          emailVerified: false,
          email: '',
          properties: null,
          loading: true,
          error: false,
          serverError: false,
          data: {}
        }
      }
    case GET_EMAIL_EXIST_SUCCESS: {
      const { data, currentProperty } = action || {}

      const { permission_rules } = data || {}
      const getProperties = (permission_rules || []).map(
        item => item?.property_id
      )

      return {
        ...state,
        verifiedEmail: {
          ...state.verifiedEmail,
          emailExists: true,
          emailVerified: true,
          email: data?.email,
          properties: getProperties,
          loading: false,
          error: true,
          data,
          serverError: false
        }
      }
    }
    case GET_EMAIL_EXIST_FAIL: {
      const { data } = action || {}
      return {
        ...state,
        verifiedEmail: {
          ...state.verifiedEmail,
          emailExists: false,
          emailVerified: true,
          email: data,
          properties: null,
          loading: false,
          error: false,
          data: {},
          serverError: false
        }
      }
    }
    case GET_EMAIL_EXIST_SERVER_FAIL: {
      const { data } = action || {}
      return {
        ...state,
        verifiedEmail: {
          ...state.verifiedEmail,
          emailExists: false,
          emailVerified: false,
          email: data,
          properties: null,
          loading: false,
          error: false,
          data: {},
          serverError: true
        }
      }
    }
    case RESET_EMAIL_EXIST: {
      return {
        ...state,
        verifiedEmail: {
          ...state.verifiedEmail,
          emailExists: false,
          emailVerified: false,
          email: '',
          properties: null,
          loading: false,
          error: false,
          data: {}
        }
      }
    }
    case SETUP_ALL_ROLES_GET_SUCCESS:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_ROLES_GET_FAIL:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ALL_USERS_GET_REQUESTED:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_USERS_GET_SUCCESS:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_USERS_GET_FAIL:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          isRequesting: false,
          isError: true
        }
      }
    case ALL_ASSOCIATED_VENDOR_USERS_REQUESTED:
      return {
        ...state,
        associatedVendorUserList: {
          ...state.associatedVendorUserList,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case ALL_ASSOCIATED_VENDOR_USERS_SUCCESS:
      return {
        ...state,
        associatedVendorUserList: {
          ...state.associatedVendorUserList,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case ALL_ASSOCIATED_VENDOR_USERS_FAIL:
      return {
        ...state,
        associatedVendorUserList: {
          ...state.associatedVendorUserList,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ONE_USER_GET_REQUESTED:
      return {
        ...state,
        oneUser: {
          ...state.oneUser,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_USER_DETAILS_REQUESTED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          isRequesting: false,
          hasMadeInitialRequest: false,
          isError: false,
          data: action.data
        }
      }
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          isRequesting: false,
          hasMadeInitialRequest: false,
          isError: true,
          data: {}
        }
      }
    case GET_ALL_PROPERTIES_LIST_REQUESTED:
      return {
        ...state,
        propertiesListLoading: true
      }
    case GET_ALL_PROPERTIES_LIST_SUCCESS:
      return {
        ...state,
        propertiesList: action.data,
        propertiesListLoading: false
      }
    case GET_ALL_PROPERTIES_LIST_FAIL:
      return {
        ...state,
        propertiesList: [],
        propertiesListLoading: false
      }
    case GET_ALL_ROLES_LIST:
      return {
        ...state,
        rolesList: action.data
      }
    case GET_USER_DETAILS_RESET:
      return {
        ...state,
        userDetail: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          isUpdating: false,
          data: {}
        }
      }
    case SETUP_ONE_USER_CREATE_REQUESTED:
    case SETUP_ONE_USER_UPDATE_REQUESTED:
    case SETUP_ONE_USER_DELETE_REQUESTED:
      return {
        ...state,
        oneUser: {
          ...state.oneUser,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false,
          isUpdating: true
        },
        tempUser: action.user
      }
    case SETUP_ONE_USER_GET_SUCCESS:
      const newData = action.data.user_id
      const a = state.allUsers.data.filter(a => a.user_id === newData)
      let data = action.data
      if (a.length !== 0) {
        data = a[0]
      }

      return {
        ...state,
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data
        }
      }
    case CLEAR_ONE_USER:
      return {
        ...state,
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: {}
        }
      }
    case SETUP_ONE_USER_UPDATE_SUCCESS:
      const userIndex = _.findIndex(state.allUsers.data, {
        user_id: action.data.user_id
      })
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data:
            userIndex >= 0
              ? state.allUsers.data
                  .slice(0, userIndex)
                  .concat([action.data])
                  .concat(state.allUsers.data.slice(userIndex + 1))
              : [...state.allUsers.data]
        },
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_USER_CREATE_SUCCESS:
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: [...state.allUsers.data, action.data]
        },
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_USER_DELETE_SUCCESS:
      const deleteIndex = _.findIndex(state.allUsers.data, {
        user_id: action.id
      })
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data:
            deleteIndex >= 0
              ? state.allUsers.data
                  .slice(0, deleteIndex)
                  .concat(state.allUsers.data.slice(deleteIndex + 1))
              : [...state.allUsers.data]
        },
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_USER_GET_FAIL:
    case SETUP_ONE_USER_CREATE_FAIL:
    case SETUP_ONE_USER_UPDATE_FAIL:
    case SETUP_ONE_USER_DELETE_FAIL:
      return {
        ...state,
        createUserLoader: false,
        updateUserLoader: false,
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ONE_USER_TOGGLE_PROJECT: {
      const { project_id } = action || {}
      const getSelectedProjectList = project_id || []
      const constructMultipleProjects = (getSelectedProjectList || []).map(
        data => {
          const { id, name } = data || {}
          return generateProject(id, name)
        }
      )
      return {
        ...state,
        oneUser: {
          ...state.oneUser,
          data: {
            ...state.oneUser.data,
            projects: [
              ...state.oneUser.data.projects,
              ...constructMultipleProjects
            ]
          }
        }
      }
    }

    case SETUP_ONE_USER_TOGGLE_PROPERTY: {
      return {
        ...state,
        allUsers: {
          ...state.allUsers,
          data: state.allUsers?.data?.map(user => {
            return user.user_id === action.user_id
              ? {
                  ...user,
                  projects: action.project_id,
                  properties: action.property_id
                }
              : user
          })
        },
        oneUser: {
          ...state.oneUser,
          data: {
            ...state.oneUser.data,
            projects: action.project_id,
            properties: action.property_id
          }
        }
      }
    }
    case SETUP_ONE_USER_SET_UPDATING:
      return {
        ...state,
        oneUser: {
          ...state.oneUser,
          isUpdating: action.updating
        }
      }
    case SETUP_ONE_USER_RESET:
      return {
        ...state,
        oneUser: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          isUpdating: false,
          data: {}
        }
      }
    case SETUP_ONE_USER_BLOCK_REQUESTED:
      return {
        ...state,
        blockUserLoader: true
      }

    case SETUP_ONE_USER_BLOCK_SUCCESS:
      const { id } = action || {}
      const { allUsers } = state || {}
      const { data: userData } = allUsers || {}
      const selectedUser = state.oneUser.data

      const oneUser = _.isEmpty(selectedUser)
        ? {}
        : selectedUser.user_id === id
        ? {
            ...selectedUser,
            blocked: !selectedUser.blocked
          }
        : selectedUser

      const newUsers = (userData || []).map(user => {
        const { user_id: currentUserId, blocked, status } = user || {}
        if (id === currentUserId) {
          return {
            ...user,
            status: status?.toLowerCase() === 'blocked' ? 'Active' : 'Blocked',
            blocked: !blocked
          }
        }
        return user
      })

      return {
        ...state,
        blockUserLoader: false,
        allUsers: {
          ...allUsers,
          data: newUsers
        },
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: oneUser
        }
      }
    case SETUP_ONE_USER_BLOCK_FAIL:
      return {
        ...state,
        blockUserLoader: false
      }
    case SETUP_SINGLE_USER_UPDATE_SUCCESS:
      const user_index = _.findIndex(state.allUsers.data, {
        user_id: action.data.user_id
      })
      return {
        ...state,
        updateUserLoader: false,
        allUsers: {
          ...state.allUsers,
          data:
            user_index >= 0
              ? state.allUsers.data
                  .slice(0, user_index)
                  .concat([action.data])
                  .concat(state.allUsers.data.slice(user_index + 1))
              : [...state.allUsers.data]
        },
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_SINGLE_USER_CREATE_SUCCESS:
      return {
        ...state,
        createUserLoader: false,
        allUsers: {
          ...state.allUsers,
          data: [...state.allUsers.data, action.data]
        },
        oneUser: {
          ...state.oneUser,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case GET_ALL_USER_SUCCESS: {
      const { data } = action || {}
      return {
        ...state
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

const generateProject = (id, name, properties) => ({
  id,
  name,
  properties: properties || []
})

const generateProperty = (id, name) => ({
  id,
  name
})

export const setupAllRolesGet = () => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_ROLES_GET_REQUESTED
    })
    Service.allRolesGet().then(async res => {
      const { ok } = res || {}
      if (ok) {
        const data = await res.json()
        dispatch({
          type: SETUP_ALL_ROLES_GET_SUCCESS,
          data: _allRolesAdapter(data)
        })
      } else {
        _setupAllRolesGetFail(dispatch)
      }
    })
  }
}

const _setupAllRolesGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_ROLES_GET_FAIL
  })
}

export const setupAllUsersGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_USERS_GET_REQUESTED
    })
    Service.allUsersGet(pdbid, context).then(async res => {
      const { ok } = res || {}
      if (ok) {
        const data = await res.json()
        dispatch({
          type: SETUP_ALL_USERS_GET_SUCCESS,
          data: _allUsersRequestAdapter(data)
        })
      } else {
        _setupAllUsersGetFail(dispatch)
      }
    })
  }
}

// Helper function to get data ready to group
const constructUserListData = ruleData => {
  const constructData = (ruleData || []).map(c_data => {
    const {
      user_id,
      name,
      role_id,
      role_name,
      project_name,
      project_id,
      property_name,
      property_id,
      status,
      internal_permission_rule_id,
      email,
      auth_id,
      used,
      last_login
    } = c_data || {}

    return {
      user_id,
      name,
      role_id,
      properties: [{ id: property_id, name: property_name }],
      projects: [{ id: project_id, name: project_name }],
      role: role_name,
      status: status === null ? 'Blocked' : 'Active',
      internal_permission_rule_id,
      email: email ? email : '',
      auth_id,
      used,
      last_login
    }
  })
  return groupByAndContructData(constructData)
}

const groupByAndContructData = ruleData => {
  const groupedItems = _.groupBy(
    ruleData || [],
    item => `${item.role_id}-${item.user_id}`
  )
  let data = []
  let groupedItemsData = Object.keys(groupedItems || {}) || []
  groupedItemsData.forEach(itemData => {
    if (groupedItems[itemData]?.length) {
      // For single group by array data
      if (groupedItems[itemData]?.length === 1) {
        data.push(...groupedItems[itemData])
      }
      // For multiple group by array data
      if (groupedItems[itemData]?.length > 1) {
        // For adding same id data in single attb
        let properties = []
        let projects = []
        let remainingData = {}
        ;(groupedItems[itemData] || []).forEach(item => {
          remainingData = {
            ...item
          }
          properties.push(...item?.properties)
          projects.push(...item?.projects)
        })
        const newData = {
          ...remainingData,
          properties,
          projects
        }
        data.push(newData)
      }
    }
  })
  return data
}

export const allUsersGet = (pdbid, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_USERS_GET_REQUESTED
    })
    Service.allUsersListGet(pdbid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          const filterData = constructUserListData(data)
          dispatch({
            type: SETUP_ALL_USERS_GET_SUCCESS,
            data: _allUsersGetAdapter(filterData)
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: SETUP_ALL_USERS_GET_FAIL
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: SETUP_ALL_USERS_GET_FAIL
        })
        if (onError) {
          onError()
        }
      })
  }
}

const _allUsersGetAdapter = data =>
  (data || []).map(d => _singleUserGetAdapter(d))

const getUniqueArrayData = data => {
  return [
    ...new Map(
      (data || []).map(item => [item['id'] || item['name'], item])
    ).values()
  ]
}

const getUserDataInSimplifiedForm = (data, childObject, key) => {
  const getOuterData = getUniqueArrayData(data?.[childObject] || [])
  return (getOuterData || []).map(d => capitalize(d?.[key]))?.join(' , ')
}

const _singleUserGetAdapter = data => {
  return {
    user_id: _getUserStringValue(data.user_id),
    auth_id: _getUserStringValue(data.auth_id),
    name: _getUserStringValue(data.name),
    email: _getUserStringValue(data.email),
    internal_permission_rule_id: _getUserStringValue(
      data.internal_permission_rule_id
    ),
    projects: getUniqueArrayData(data.projects),
    properties: getUniqueArrayData(data.properties),
    roles: data.role,
    status: data.status,
    blocked: data.status,
    simplified_project: getUserDataInSimplifiedForm(data, 'projects', 'name'),
    simplified_properties: getUserDataInSimplifiedForm(
      data,
      'properties',
      'name'
    ),
    used: data.used === 'T' ? true : false,
    last_login: getUTCToLocalDateTime(data?.last_login)
  }
}

export const allVendorAssociatedUsers = (
  pdbid,
  context,
  vendor_id,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: ALL_ASSOCIATED_VENDOR_USERS_REQUESTED
    })
    Service.allVendorAssociatedUsers(pdbid, context, vendor_id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: ALL_ASSOCIATED_VENDOR_USERS_SUCCESS,
            data: _allVendorAssociatedUsersAdapter(data)
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: ALL_ASSOCIATED_VENDOR_USERS_FAIL
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: ALL_ASSOCIATED_VENDOR_USERS_FAIL
        })
        if (onError) {
          onError()
        }
      })
  }
}

const _allVendorAssociatedUsersAdapter = data =>
  (data || []).map(d => _singleVendorAssociatedUserAdapter(d))

const _singleVendorAssociatedUserAdapter = data => {
  return {
    user_id: _getUserStringValue(data?.user_id),
    name: _getUserStringValue(data?.name),
    email: _getUserStringValue(data?.email),
    status: data?.status === null ? 'Blocked' : 'Active',
    login_access: data?.login_access === null ? 'Enabled' : 'Disabled',
    internal_permission_rule_id: _getUserStringValue(
      data?.internal_permission_rule_id
    ),
    permission_rule_id: _getUserStringValue(data?.permission_rule_id),
    vendor_user_id: _getUserStringValue(data?.vendor_user_id),
    vendor_id: _getUserStringValue(data?.vendor_id),
    perm_status: data.perm_status
  }
}

const _setupAllUsersGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_USERS_GET_FAIL
  })
}

export const setupOneUserGet = data => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_USER_GET_REQUESTED
    })
    if (data) {
      dispatch({
        type: SETUP_ONE_USER_GET_SUCCESS,
        data
      })
    } else {
      dispatch({
        type: SETUP_ONE_USER_GET_FAIL
      })
    }
  }
}

export const clearOneUser = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_ONE_USER
    })
  }
}

const NS = ''
const _getUserStringValue = value => (typeof value === 'string' ? value : NS)

const _allUsersRequestAdapter = data => {
  const arrayUniqueByKey = [
    ...new Map((data || []).map(item => [item['user_id'], item])).values()
  ]
  return arrayUniqueByKey.map(d => _singleUserAdapter(d))
}

const getAllDataInSimplifiedForm = (data, parentObject, childObject, key) => {
  const getOuterData = data?.[parentObject]?.[childObject] || []
  return (getOuterData || []).map(d => d?.[key])?.join(', ')
}

const _singleUserAdapter = data => {
  return {
    user_id: _getUserStringValue(data.user_id),
    name: _getUserStringValue(data.name),
    nickname: _getUserStringValue(data.nickname),
    email: _getUserStringValue(data.email),
    email_verified: data.email_verified || false,
    blocked: data.blocked,
    picture: _getUserStringValue(data.picture),
    created_at: _getUserStringValue(data.created_at),
    updated_at: _getUserStringValue(data.upated_at),
    last_ip: _getUserStringValue(data.last_ip),
    last_login: moment(data.last_login).isValid()
      ? moment(data.last_login).format('DD/MM/YYYY hh:mm A')
      : 'N/A',
    status: data.blocked ? 'Blocked' : 'Active',
    logins_count: typeof data.staff_uri === 'number' ? data.logins_count : 0,
    roles: data.role.name || '',
    userRole: data.role || '',
    projects: _.get(data, 'user_metadata.projects', []),
    simplified_project: getAllDataInSimplifiedForm(
      data,
      'user_metadata',
      'projects',
      'name'
    ),
    simplified_properties: getAllDataInSimplifiedForm(
      data,
      'user_metadata',
      'properties',
      'name'
    ),
    properties: _.get(data, 'user_metadata.properties', []),
    allowedSections: _.get(data, 'user_metadata.allowedSections', []),
    app_access: _.get(data, 'user_metadata.app_access', false),
    staffId: _.get(data, 'user_metadata.staffid', ''),
    vendorId: _.get(data, 'user_metadata.vendorid', ''),
    login_tooltip: {
      field: 'last_login',
      last: _getUserStringValue(data.last_login),
      total: typeof data.staff_uri === 'number' ? data.logins_count : 0,
      ip: _getUserStringValue(data.last_ip)
    },
    email_tooltip: {
      field: 'email',
      email: _getUserStringValue(data.email),
      email_verified: data.email_verified || false,
      created_at: _getUserStringValue(data.created_at),
      updated_at: _getUserStringValue(data.updated_at)
    },
    name_tooltip: {
      field: 'name',
      name: _getUserStringValue(data.name),
      nickname: _getUserStringValue(data.nickname)
    },
    user_metadata: data.user_metadata
  }
}

const _oneUserAdapter = data => {
  return {
    user_id: _getUserStringValue(data.user_id),
    name: _getUserStringValue(data.name),
    nickname: _getUserStringValue(data.nickname),
    email: _getUserStringValue(data.email),
    email_verified: data.email_verified || false,
    picture: _getUserStringValue(data.picture),
    created_at: _getUserStringValue(data.created_at),
    updated_at: _getUserStringValue(data.upated_at),
    last_ip: _getUserStringValue(data.last_ip),
    last_login: moment(data.last_login).isValid()
      ? moment(data.last_login).format('DD/MM/YYYY hh:mm A')
      : 'N/A',
    logins_count: typeof data.staff_uri === 'number' ? data.logins_count : 0,
    roles: data?.roles?.name || '',
    userRole: data.roles || {},
    projects: _.get(data, 'user_metadata.projects', []),
    properties: _.get(data, 'user_metadata.properties', []),
    allowedSections: _.get(data, 'user_metadata.allowedSections', []),
    app_access: _.get(data, 'user_metadata.app_access', false),
    staffId: data?.staffId || _.get(data, 'user_metadata.staffid', '') || '',
    vendorId: data?.vendorId || _.get(data, 'user_metadata.vendorid', '') || '',
    login_tooltip: {
      field: 'last_login',
      last: _getUserStringValue(data.last_login),
      total: typeof data.staff_uri === 'number' ? data.logins_count : 0,
      ip: _getUserStringValue(data.last_ip)
    },
    email_tooltip: {
      field: 'email',
      email: _getUserStringValue(data.email),
      email_verified: data.email_verified || false,
      created_at: _getUserStringValue(data.created_at),
      updated_at: _getUserStringValue(data.updated_at)
    },
    name_tooltip: {
      field: 'name',
      name: _getUserStringValue(data.name),
      nickname: _getUserStringValue(data.nickname)
    }
  }
}

const _getRoleIndices = (user, roles) => {
  const indices = _.map(roles, (role, index) =>
    _.find(role, u => u.user_id === user.user_id) ? index : -1
  )
  return _.filter(indices, index => index !== -1)
}
const _allRolesAdapter = roles => roles

const _allUsersAdapter = (data, allRoles) => {
  let allUsers = data[data.length - 1]
  allUsers = _.filter(allUsers, cf => !cf.blocked)
  const usersWithRoles = data.slice(0, data.length - 1)
  const users = _.map(allUsers, user => {
    const indices = _getRoleIndices(user, usersWithRoles)
    const roles = _.map(indices, i => allRoles[i])
    return { ...user, roles }
  })
  return users.map(u => _oneUserAdapter(u))
}

export const setupOneUserCreate = (body, user, onSuccess) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_USER_CREATE_REQUESTED,
      user
    })
    Service.setupUserCreate(body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          if (onSuccess) {
            onSuccess()
          }
          dispatch({
            type: SETUP_ONE_USER_CREATE_SUCCESS,
            data: _oneUserAdapter(data)
          })
        } else {
          _setupOneUserCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneUserCreateFail(dispatch)
      })
  }
}

export const createOrEditUser = (pdbid, body, onSuccess, onError) => {
  return dispatch => {
    Service.createOrEditUser(pdbid, body)
      .then(async res => {
        const { ok } = res || {}
        const data = await res.json()
        if (ok) {
          if (data?.hasOwnProperty('message')) {
            if (onError) {
              if (data?.message === 'The user already exists.') {
                onError(
                  'This email address is already associated with an account. Please try a different email address.'
                )
              } else {
                onError('Something went wrong. Please try again later')
              }
            }
            return
          }
          if (onSuccess) {
            onSuccess(data)
          }
          return
        }
        if (onError) {
          if (data?.hasOwnProperty('message')) {
            if (onError) {
              if (data?.message === 'The user already exists.') {
                onError(
                  'This email address is already associated with an account. Please try a different email address.'
                )
              } else {
                onError('Something went wrong. Please try again later')
              }
            }
            return
          }
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const reactivateOrInactivateUser = (
  pdbid,
  context,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.reactivateOrInactivateUser(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const newUserDetail = (pdbid, user_id, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: GET_USER_DETAILS_REQUESTED
    })
    Service.newUserDetail(pdbid, user_id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          let updateData = {}
          if (data?.length) {
            updateData = data[0]
            updateData.app_access =
              data[0]?.app_access === null || data[0]?.app_access === true
                ? true
                : false
          }
          dispatch({
            type: GET_USER_DETAILS_SUCCESS,
            data: updateData
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_USER_DETAILS_FAIL,
          data: {}
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GET_USER_DETAILS_FAIL,
          data: {}
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const allPropertiesList = (pdbid, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_PROPERTIES_LIST_REQUESTED
    })

    Service.allPropertiesList(pdbid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          const sortedList = (data || []).sort((a, b) => {
            const valueOne = removeExtraWhiteSpaces(a?.property_name || '')
            const valueTwo = removeExtraWhiteSpaces(b?.property_name || '')
            return valueOne.localeCompare(valueTwo, 'en', { numeric: true })
          })
          const unique = _allPropertiesRequestAdapter(sortedList || [])
          dispatch({
            type: GET_ALL_PROPERTIES_LIST_SUCCESS,
            data: unique
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_ALL_PROPERTIES_LIST_FAIL
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_PROPERTIES_LIST_FAIL
        })
        if (onError) {
          onError()
        }
      })
  }
}

const _allPropertiesRequestAdapter = data => {
  const unique = [
    ...new Map(
      (data || []).map(item => [
        item['property_id'] || item['project_id'],
        item
      ])
    ).values()
  ]
  return (unique || []).map(d => _singlePropertyAdapter(d))
}

const _singlePropertyAdapter = data => {
  return {
    property_id: _getUserStringValue(data?.property_id),
    property_name: _getUserStringValue(data?.property_name),
    project_id: _getUserStringValue(data?.project_id),
    project_name: _getUserStringValue(data?.project_name),
    project_code: _getUserStringValue(data?.project_code),
    property_code: _getUserStringValue(data?.property_code)
  }
}

export const allRolesList = (pdbid, onSuccess, onError) => {
  return dispatch => {
    Service.allRolesList(pdbid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_ALL_ROLES_LIST,
            data
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_ALL_ROLES_LIST,
          data: []
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_ROLES_LIST,
          data: []
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const checkEmailExists = (pdbid, email, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: GET_EMAIL_EXIST_REQUESTED
    })
    Service.checkEmailExists(pdbid, email)
      .then(async res => {
        const { ok, status } = res || {}
        if (status === 500) {
          dispatch({
            type: GET_EMAIL_EXIST_SERVER_FAIL,
            data: email
          })
          return
        }
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_EMAIL_EXIST_SUCCESS,
            data: data?.length ? data[0] : {}
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_EMAIL_EXIST_FAIL,
          data: email
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GET_EMAIL_EXIST_SERVER_FAIL,
          data: email
        })
      })
  }
}

export const resetCheckEmail = () => {
  return dispatch => {
    dispatch({
      type: RESET_EMAIL_EXIST
    })
  }
}

export const updateUserAccountStatus = (pdbid, body, onSuccess, onError) => {
  return dispatch => {
    Service.updateUserAccountStatus(pdbid, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const associateUserToVendor = (
  pdbid,
  context,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.associateUserToVendor(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        const data = res.json()
        if (ok) {
          if (onSuccess) {
            onSuccess(data)
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const userAccountDelete = (pdbid, id, onSuccess, onError) => {
  return dispatch => {
    Service.userAccountDelete(pdbid, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError()
        }
      })
  }
}

export const updateUserPassword = (body, onSuccess, onError) => {
  return dispatch => {
    Service.updateUserPassword(body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const _setupOneUserCreateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_USER_CREATE_FAIL
  })
}

export const setupOneUserUpdate = (id, body, user) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_USER_UPDATE_REQUESTED,
      user
    })
    Service.setupUserUpdate(id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_USER_UPDATE_SUCCESS,
            data: _oneUserAdapter(data)
          })
        } else {
          _setupOneUserUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneUserUpdateFail(dispatch)
      })
  }
}

const _setupOneUserUpdateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_USER_UPDATE_FAIL
  })
}

export const setupOneUserRole = (body, user, roles, onSuccess) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_USER_UPDATE_REQUESTED
    })
    Service.updateUserRole(body)
      .then(async res => {
        const { ok, data } = res || {}
        if (ok) {
          const { name } = roles || {}

          dispatch({
            type: SETUP_ONE_USER_UPDATE_SUCCESS,
            data: {
              ...user,
              roles: name || '',
              userRole: roles || {}
            }
          })
          onSuccess && onSuccess()
        } else {
          _setupOneUserUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneUserUpdateFail(dispatch)
      })
  }
}

export const setupOneUserDelete = (id, user) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_USER_DELETE_REQUESTED,
      user
    })
    Service.setupUserDelete(id)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: SETUP_ONE_USER_DELETE_SUCCESS,
            id
          })
        } else {
          _setupOneUserDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneUserDeleteFail(dispatch)
      })
  }
}

const _setupOneUserDeleteFail = dispatch => {
  dispatch({
    type: SETUP_ONE_USER_DELETE_FAIL
  })
}

export const toggleProject = (
  project_id,
  project_name = 'Undefined'
) => dispatch => {
  dispatch({
    type: SETUP_ONE_USER_TOGGLE_PROJECT,
    project_id,
    project_name
  })
}

export const toggleProperty = (
  project_id,
  project_name = 'Undefined',
  property_id,
  property_name = 'Undefined',
  user_id
) => dispatch => {
  dispatch({
    type: SETUP_ONE_USER_TOGGLE_PROPERTY,
    project_id,
    project_name,
    property_id,
    property_name,
    user_id
  })
}

export const setUpdating = updating => dispatch => {
  dispatch({
    type: SETUP_ONE_USER_SET_UPDATING,
    updating
  })
}

export const resetUser = () => dispatch => {
  dispatch({
    type: SETUP_ONE_USER_RESET
  })
}

export const clearUser = () => dispatch => {
  dispatch({
    type: GET_USER_DETAILS_RESET
  })
}

export const setupOneUserBlock = (user, onSuccess, onFail) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_USER_BLOCK_REQUESTED
    })

    let body = { blocked: user.blocked ? false : true }

    Service.setupUserBlock(user.user_id, body)
      .then(async res => {
        if (res.ok) {
          const response = await res.json()
          const { blocked } = response || {}
          const message = blocked ? 'Blocked' : 'Reactivated'

          dispatch(setAlert(`User is ${message}!`, 'success'))
          dispatch({
            type: SETUP_ONE_USER_BLOCK_SUCCESS,
            id: user.user_id
          })

          onSuccess && onSuccess()

          return
        }
        dispatch({
          type: SETUP_ONE_USER_BLOCK_FAIL
        })

        onFail && onFail()
        return
      })
      .catch(() => {
        dispatch({
          type: SETUP_ONE_USER_BLOCK_FAIL
        })
        onFail && onFail()
      })
  }
}

export const setupSingleUserUpdate = (
  id,
  body,
  user,
  roles,
  userType,
  onSuccess
) => {
  return dispatch => {
    dispatch({
      type: SETUP_SINGLE_USER_UPDATE_REQUESTED,
      user
    })

    Service.setupSingleUserUpdate(id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()

          if (!_.isEmpty(roles)) {
            if (!_.isEmpty(userType)) {
              if (roles.name === 'Vendor') {
                data.vendorId = userType.vendorid
              } else if (roles.name === 'Staff') {
                data.staffId = userType.staffid
              }

              if (
                !_.get(body, 'user_metadata.staffid') &&
                !_.get(body, 'user_metadata.vendorid')
              ) {
                data.staffId = ''
                data.vendorId = ''
              }
            }

            if (!_.isEmpty(data)) {
              data.roles = roles
            }
          }

          const modifiedData = _oneUserAdapter(data)

          dispatch({
            type: SETUP_SINGLE_USER_UPDATE_SUCCESS,
            data: modifiedData
          })

          if (onSuccess) {
            onSuccess(modifiedData)
          }
        } else {
          _setupOneUserUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneUserUpdateFail(dispatch)
      })
  }
}

export const setupSingleUserCreate = ({
  body,
  user,
  selectedRole,
  selectedProjects,
  selectedProperties,
  onSuccess,
  onError,
  userType,
  context
}) => {
  const { name: roleName } = selectedRole || {}
  const { pdbid } = body || {}

  const { user_metadata } = user || {}
  const { allowedSections } = user_metadata || {}

  return dispatch => {
    dispatch({
      type: SETUP_SINGLE_USER_CREATE_REQUESTED,
      user
    })
    // return
    Service.setupCreateUser(body, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const { message } = data || {}
          if (message) {
            onError(message)
            _setupOneUserCreateFail(dispatch)
            return
          }

          dispatch({
            type: SETUP_SINGLE_USER_CREATE_SUCCESS,
            data: _oneUserAdapter({
              ...data,
              roles: selectedRole
            })
          })

          const { user_metadata } = data || {}
          const { role } = user_metadata || {}

          let userMeta = {
            pdbid,
            projects: selectedProjects,
            properties: selectedProperties,
            role,
            allowedSections
          }

          if (roleName === 'Vendor' || roleName === 'Staff') {
            userMeta = {
              ...userMeta,
              ...userType,
              allowedSections
            }
          }

          dispatch(
            setupSingleUserUpdate(
              data.user_id,
              {
                user_metadata: userMeta
              },
              data,
              selectedRole,
              userType,
              onSuccess
            )
          )
        } else {
          _setupOneUserCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneUserCreateFail(dispatch)
      })
  }
}

export const setupSingleUserResetPassword = ({ id, body, user, onSuccess }) => {
  return dispatch => {
    dispatch({
      type: SETUP_SINGLE_USER_RESET_PASSWORD_REQUESTED,
      user
    })
    Service.setupUserResetPassword(id, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
        }
      })
      .catch(() => {})
  }
}
