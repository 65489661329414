import _ from 'lodash'
import Cookie from 'browser-cookies'
import {
  AUTH_DOMAIN,
  AUTH_CLIENT_ID,
  MGMT_CLIENT_ID,
  API_BASE_URL
} from './config'
import { trimObject } from '../helpers'
import { history } from '../store'

// Auth0 Universal Login doesn't allow a custom domain but allow localhost.
export const DEV_HOST = 'http://localhost:3000'
export const DEV_HOSTNAME = 'localhost'

const internalPath = '/api/internal'
//Vars to export
const DOMAIN = {
  auth0: AUTH_DOMAIN,
  service: API_BASE_URL,
  turnable: `${API_BASE_URL}${internalPath}`
}
const LOGO =
  'https://img1.wsimg.com/isteam/ip/370b4cbe-a544-4cba-954b-e87e54bc4709/logo/2fabdccd-9195-4f79-9d50-728742dee84f.png/:/rs=h:270/qt=q:95'

const constants = {
  CLIENT_ID: AUTH_CLIENT_ID,
  DOMAIN,
  LOGO
}

const DEFAULT_CONTEXT = {
  property: '',
  project: ''
}

//Methods to export
const methods = {
  setAuthAccessToken: (at = '') => {
    Auth.accessToken = at
    _setCookie('at', at)
  },
  setCtxCookie: (project, property) => {
    const str = `${project}|${property}`
    _setCookie('ctx', str)
  },
  setTntCookie: tenant => {
    const str = tenant
    _setCookie('tnt', str)
  },
  setIdTokenCookie: token => {
    localStorage.setItem('idToken', token)
  },
  getAuthCookie: () => {
    Auth.accessToken = _getCookie('at')
    return Auth.accessToken
  },
  getCtxCookie: () => {
    const ctxCookie = _getCookie('ctx')
    return ctxCookie
  },
  getTntCookie: () => {
    const ctCookie = _getCookie('tnt')
    return ctCookie
  },
  getIdTokenCookie: () => localStorage.getItem('idToken'),
  getMgmtAccessToken: async () => {
    const uri = uri_mgmtAccessToken.replace(/:id/, MGMT_CLIENT_ID)
    Uidt.idToken = methods.getIdTokenCookie()
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `${Uidt.tokenType} ${Uidt.idToken || ''}`
    })
    const params = {
      headers
    }
    try {
      const res = await fetch(uri, params)
      if (!res.ok) {
        return false
      }
      const data = await res.json()
      Mgmt.accessToken = data.access_token
      return true
    } catch (error) {
      return false
    }
  },
  setupUserUpdate: async (userid, body) => {
    const uri = uri_userDetail.replace(/:id/, userid)
    return _superFetch(uri, Mgmt, 'PATCH', body, false)
  },
  setupUserCreate: async body =>
    _superFetch(uri_user, Mgmt, 'POST', body, false),
  setupUserDelete: async userid => {
    const uri = uri_userDetail.replace(/:id/, userid)
    return _superFetch(uri, Mgmt, 'DELETE', {}, false)
  },
  getRoles: async () => _superFetch(uri_roles, Mgmt),
  getUsers: pdbid =>
    _superFetch(
      pdbid ? `${uri_user}?q=user_metadata.pdbid="${pdbid}"` : uri_user,
      Mgmt
    ),
  getUsersByRole: roleid => {
    const uri = uri_usersByRole.replace(/:id/, roleid)
    return _superFetch(uri, Mgmt)
  },
  getUser: () => _superFetch(uri_userInfo, Auth),
  getUserRoles: id => {
    const uri = uri_userRoles
    return _turnableFetch(uri)
  },
  setupUserRole: (id, roleId) => {
    const uri = uri_userRoles.replace(/:id/, id)
    return _superFetch(uri, Mgmt, 'POST', { roles: [roleId] }, false)
  },
  removeUserRole: (id, roleId) => {
    const uri = uri_userRoles.replace(/:id/, id)
    return _superFetch(uri, Mgmt, 'DELETE', { roles: [roleId] }, false)
  },
  getUserDetail: id => {
    const uri = uri_new_userDetail
    Uidt.idToken = methods.getIdTokenCookie()
    return _turnableFetch(uri)
  },
  changePassword: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_change_password
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  getGlobalSearch: (pdbid, context, q) => {
    const uri = uri_globalSearch.replace(/:pdbid/, pdbid).replace(/:q/, q)
    return _turnableFetch(uri, context)
  },
  getDashboardServicesSummary: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = getDashboardBaseUri(context)
      .replace(/:pdbid/, pdbid)
      .replace(/:category/, 'stats')
    return _turnableFetch(uri, context)
  },
  getDashboardChartsSummary: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = getDashboardBaseUri(
      context,
      _.get(context, 'properties', '').length > 0
    )
      .replace(/:pdbid/, pdbid)
      .replace(/:category/, 'metrics')
    return _turnableFetch(uri, context, 'GET', null)
  },
  getDashboardGoalsSummary: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = getDashboardBaseUri(
      context,
      _.get(context, 'properties', '').length > 0
    )
      .replace(/:pdbid/, pdbid)
      .replace(/:category/, 'goals')
    return _turnableFetch(uri, context, 'GET', null)
  },
  getDashboardAutomaticGoalsSummary: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = getDashboardBaseUri(
      context,
      _.get(context, 'properties', '').length > 0
    )
      .replace(/:pdbid/, pdbid)
      .replace(/:category/, 'auto_goals')
    return _turnableFetch(uri, context, 'GET', null)
  },
  getDashboardCostSummary: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = getDashboardBaseUri(context)
      .replace(/:pdbid/, pdbid)
      .replace(/:category/, 'costs')
    return _turnableFetch(uri, context)
  },
  getDashboardPropertiesData: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_corporateDashboard
      .replace(/:pdbid/, pdbid)
      .replace(/:category/, 'map')
    return _turnableFetch(uri, context)
  },
  setupAllVendorsGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllVendors.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  allVendorListGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allVendorListGet.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  allVendorServicePricing: (
    pdbid,
    context = DEFAULT_CONTEXT,
    serviceid,
    vendorid
  ) => {
    const uri = uri_allVendorServicePricing
      .replace(/:pdbid/, pdbid)
      .replace(/:serviceid/, serviceid)
      .replace(/:vendorid/, vendorid)
    return _turnableFetch(uri, context)
  },
  allVendorPricingItemOption: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_allVendorPricingItemOption
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
    return _turnableFetch(uri, context)
  },
  setupOneVendorGet: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOneVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context)
  },
  setupOneVendorFloorplanGet: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOneVendorFloorplan
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context)
  },
  setupOneVendorFloorplanUpdate: (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    body
  ) => {
    const uri = uri_setupOneVendorFloorplan
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  setupOneVendorDelete: (pdbid, context = DEFAULT_CONTEXT, id, item = null) => {
    let body = {}
    if (item)
      body = {
        item_option_uri: item
      }
    const uri = uri_setupOneVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context, 'DELETE', body)
  },
  deleteVendorCompany: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_deleteVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context, 'DELETE')
  },
  toggleVendorAvailablitity: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_toggleVendorAvailablitity
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  deleteVendorServices: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const { vendorid, serviceid } = body || {}
    const uri = uri_deleteVendorServices
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, vendorid)
      .replace(/:serviceid/, serviceid)
    return _turnableFetch(uri, context, 'DELETE')
  },
  setupOneVendorUpdate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    body,
    _item = null
  ) => {
    const uri = uri_setupOneVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  updateBulkVendorServicePricing: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    serviceid,
    vendorid,
    body
  ) => {
    const uri = uri_allVendorServicePricing
      .replace(/:pdbid/, pdbid)
      .replace(/:serviceid/, serviceid)
      .replace(/:vendorid/, vendorid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  updateSingleVendorServicePricing: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    serviceid,
    vendorid,
    body
  ) => {
    const uri = uri_updateSingleVendorServicePricing
      .replace(/:pdbid/, pdbid)
      .replace(/:serviceid/, serviceid)
      .replace(/:vendorid/, vendorid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  deleteSingleVendorServiceUnitSpacePricing: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    serviceid,
    vendorid,
    body
  ) => {
    const uri = uri_updateSingleVendorServicePricing
      .replace(/:pdbid/, pdbid)
      .replace(/:serviceid/, serviceid)
      .replace(/:vendorid/, vendorid)
    return _turnableFetch(uri, context, 'DELETE', body, false)
  },
  setupOneVendorCreate: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_setupOneVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, '1')
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  setupAllPropertiesGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllProperties.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  setupOnePropertyGet: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    scope = 'details'
  ) => {
    const uri = uri_setupOneProperty
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
      .replace(/:scope/, scope)
    return _turnableFetch(uri, context)
  },
  setupOnePropertyUpdate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    scope = 'details',
    body
  ) => {
    const uri = uri_setupOnePropertyBunch
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
      .replace(/:scope/, scope)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  setupOnePropertyCreate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    scope = 'details',
    body
  ) => {
    const uri = uri_setupOnePropertyBunch
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
      .replace(/:scope/, scope)
    return _turnableFetch(uri, context, 'POST', body)
  },
  setupOnePropertyDelete: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    scope = 'details',
    body
  ) => {
    const uri = uri_setupOnePropertyBunch
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
      .replace(/:scope/, scope)
    return _turnableFetch(uri, context, 'DELETE', body)
  },
  setupOnePropertyGetBunch: async (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOnePropertyBunch
      .replace(/:pdbid/, pdbid)
      .replace(/:propertyid/, id)
    return _turnableFetch(uri, context)
  },
  setupOnePropertyUpdateBunch: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    body
  ) => {
    const uri = uri_setupOnePropertyBunch
      .replace(/:pdbid/, pdbid)
      .replace(/:propertyid/, id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  setupOnePropertyCreateBunch: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_setupOnePropertyBunch
      .replace(/:pdbid/, pdbid)
      .replace(/:propertyid/, '1')
    return _turnableFetch(uri, context, 'POST', body)
  },
  setupAllStaffGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllStaff.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  allStaffListGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allStaffList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  deleteStaff: (pdbid, context = DEFAULT_CONTEXT, user_id, body) => {
    const uri = uri_delete_staff.replace(/:user_id/, user_id)
    return _turnableFetch(uri, context, 'DELETE', body)
  },

  setupOneStaffGet: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOneStaff
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, id)
    return _turnableFetch(uri, context)
  },
  setupOneStaffUpdate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupOneStaff
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  setupOneStaffCreate: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_setupOneStaff
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, 1)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  setupOneStaffDelete: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOneStaff
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, id)
    return _turnableFetch(uri, context, 'DELETE')
  },
  createOrEditUser: (pdbid, body) => {
    const { user_id } = body || {}
    const uri = user_id
      ? update_user_uri.replace(/:user_id/, user_id)
      : new_user_uri
    return _turnableFetch(uri, {}, user_id ? 'PATCH' : 'POST', body, false)
  },
  reactivateOrInactivateUser: (pdbid, context, body) => {
    const uri = uri_reactivate_or_inactivate_user.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  newUserDetail: (pdbid, user_id) => {
    const uri = uri_addNewUser
      .replace(/:pdbid/, pdbid)
      .replace(/:user_id/, user_id)
    return _turnableFetch(uri, {}, 'GET')
  },
  allPropertiesList: pdbid => {
    const uri = allPropertiesList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, {}, 'GET')
  },
  allRolesList: pdbid => {
    const uri = allRolesList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, {}, 'GET')
  },
  allUsersListGet: pdbid => {
    const uri = allUsersListGet.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, {}, 'GET')
  },
  allVendorAssociatedUsers: (pdbid, context, vendor_id) => {
    const uri = allVendorAssociatedUsers
      .replace(/:pdbid/, pdbid)
      .replace(/:vendor_id/, vendor_id)
    return _turnableFetch(uri, context, 'GET')
  },
  updateUserAccountStatus: (pdbid, body) => {
    const uri = updateUserAccountStatus.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, {}, 'PUT', body, false)
  },
  updateUserPassword: body => {
    const { user_id, password } = body || {}
    const uri = updateUserPassword.replace(/:user_id/, user_id)
    delete body.user_id
    return _turnableFetch(uri, {}, 'PATCH', password ? body : {}, false)
  },
  associateUserToVendor: (pdbid, context, body) => {
    const { vendor_id } = body || {}
    const uri = associateUserToVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendor_id/, vendor_id)
    delete body.vendor_id
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  userAccountDelete: (pdbid, id) => {
    const uri = userAccountDelete
      .replace(/:pdbid/, pdbid)
      .replace(/:user_id/, id)
    return _turnableFetch(uri, {}, 'DELETE')
  },
  checkEmailExists: (pdbid, email) => {
    const uri = checkEmailExists
      .replace(/:pdbid/, pdbid)
      .replace(/:email/, email)
    return _turnableFetch(uri, {}, 'GET')
  },
  verifyStaffUserWithEmail: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_verifyStaffUserWithEmail.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  addStaffUserToExistingProperty: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_verifyStaffUserWithEmail.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  setupAllTeamsGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllTeams.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  allTeamListGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allTeamListGet.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  setupOneTeamGet: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOneTeam.replace(/:pdbid/, pdbid).replace(/:teamid/, id)
    return _turnableFetch(uri, context)
  },
  setupOneTeamUpdate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupOneTeam.replace(/:pdbid/, pdbid).replace(/:teamid/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  setupOneTeamCreate: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_setupOneTeam.replace(/:pdbid/, pdbid).replace(/:teamid/, 1)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  setupOneTeamDelete: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupOneTeam.replace(/:pdbid/, pdbid).replace(/:teamid/, id)
    return _turnableFetch(uri, context, 'DELETE', body)
  },
  setupOneTeamCapacityUpdate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupOneTeamCapacity
      .replace(/:pdbid/, pdbid)
      .replace(/:teamid/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  setupOneTeamCapacityCreate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupOneTeamCapacity
      .replace(/:pdbid/, pdbid)
      .replace(/:teamid/, id)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  setupServicesGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupServices.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  setupAllBudgetsGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllBudgets.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  setupOneBudgetGet: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupOneBudget
      .replace(/:pdbid/, pdbid)
      .replace(/:budgetid/, id)
    return _turnableFetch(uri, context)
  },
  setupOneBudgetCreate: (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_setupOneBudget
      .replace(/:pdbid/, pdbid)
      .replace(/:budgetid/, '1')
    return _turnableFetch(uri, context, 'POST', body)
  },
  setupOneBudgetUpdate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupOneBudget
      .replace(/:pdbid/, pdbid)
      .replace(/:budgetid/, id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  setupBudgetLineGet: (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_setupBudgetLine
      .replace(/:pdbid/, pdbid)
      .replace(/:budgetid/, id)
    return _turnableFetch(uri, context)
  },
  setupBudgetLineCreate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupBudgetLine
      .replace(/:pdbid/, pdbid)
      .replace(/:budgetid/, id)
    return _turnableFetch(uri, context, 'POST', body)
  },
  setupBudgetLineUpdate: (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupBudgetLine
      .replace(/:pdbid/, pdbid)
      .replace(/:budgetid/, id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  setupAllServicesGet: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllServices.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getTurnboard: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_turnboard.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getTurnboardDetails: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_turnboardDetail.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getTurnboardServiceStatus: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_turnboardServiceStatus.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getTurnboardServiceCost: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_turnboardServiceCost.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getTurnboardInHouseServices: (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_turnboardInHouseServices.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  updateAsIsStatus: async (data, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_updateAsIsStatus.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', data, false)
  },
  groupOrUngroupUnits: async (data, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_groupOrUngroupUnits.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', data, false)
  },
  updateEnableServices: async (data, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_updateEnableServices.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', data, false)
  },
  markInHouse: async (data, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_markOrUnMarkInHouse
      .replace(/:pdbid/, pdbid)
      .replace(/:inhouse_id/, 0)
    return _turnableFetch(uri, context, 'POST', data, false)
  },
  unMarkInHouse: async (data, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_markOrUnMarkInHouse
      .replace(/:pdbid/, pdbid)
      .replace(/:inhouse_id/, 0)
    return _turnableFetch(uri, context, 'DELETE', data, false)
  },
  getAllGroups: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_getAllGroups.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  updateLeaseOverride: async (data, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_leaseOverride.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', data, false)
  },
  getTurnboard2ServiceOptions: (
    pdbid,
    context = DEFAULT_CONTEXT,
    unitId,
    serviceId
  ) => {
    const uri = uri_turnboard2ServiceOptions
      .replace(/:pdbid/, pdbid)
      .replace(/:unitspaceid/, unitId)
      .replace(/:serviceid/, serviceId)
    return _turnableFetch(uri, context)
  },
  setTurnboard2ServiceOptions: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    unitId,
    serviceId,
    body
  ) => {
    const uri = uri_setTurnboard2ServiceOptions
      .replace(/:pdbid/, pdbid)
      .replace(/:unitspaceid/, unitId)
      .replace(/:serviceid/, serviceId)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  unitDetailsGet: async (pdbid, context = DEFAULT_CONTEXT, usid) => {
    const uri = uri_getServicesAndTasts
      .replace(/:pdbid/, pdbid)
      .replace(/:usid/, usid)
    return _turnableFetch(uri, context)
  },
  getScheduleByService: async (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_getScheduleByService
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
    return _turnableFetch(uri, context)
  },
  getTurnboardAllTags: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_getTurnboardAllTags.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getTurnboardUnitTags: async (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_getTurnboardUnitTags
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
    return _turnableFetch(uri, context)
  },
  addTurnboardUnitTag: async (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_addTurnboardUnitTag
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  deleteTurnboardUnitTag: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    id,
    body
  ) => {
    const uri = uri_deleteTurnboardUnitTag
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
    return _turnableFetch(uri, context, 'DELETE', body, false)
  },
  addTurnboardAllTags: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_addTurnboardAllTags.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  deleteTurnboardAllTags: async (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_deleteTurnboardAllTags
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, id)
    return _turnableFetch(uri, context, 'DELETE')
  },
  getKeysList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_keysList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getKeysStats: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_keysStats.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getKeysSyncData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_syncKeys.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  doSyncKeysData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_syncKeys.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', {})
  },
  updateKey: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    unit,
    roomkey,
    status
  ) => {
    roomkey = roomkey.toUpperCase()
    const uri = uri_updateKey.replace(/:pdbid/, pdbid).replace(/:unit/, unit)
    const body = {
      status,
      roomkey
    }
    return _turnableFetch(uri, context, 'PUT', body)
  },
  getInspectList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  getInspectionList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectionList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  getDownloadInspectionsList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_downloadInspectionsList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  getDownloadState: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_downloadState.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  viewAllDownloads: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_downloadState.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  downloadInspectionMedia: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_downloadInspectionMedia
    return _customFetch(uri, 'POST', null, JSON.stringify(body), context, pdbid)
  },

  getFilteredInspectionList: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    to,
    from
  ) => {
    const uri = uri_getFilteredInspectionList
      .replace(/:pdbid/, pdbid)
      .replace(/:from/, from)
      .replace(/:to/, to)
    return _turnableFetch(uri, context)
  },

  getApprovedInspectionList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_getApprovedInspectionList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  changeDamageInspectionStatus: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body
  ) => {
    const uri = uri_changeDamageInspectionStatus.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  getInspectionMedia: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    inspectionId
  ) => {
    const uri = uri_inspectionMediaById
      .replace(/:pdbid/, pdbid)
      .replace(/:inspectionId/, inspectionId)
    return _turnableFetch(uri, context)
  },

  getInspectionFormList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectionFormList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getSingleForm: async (pdbid, context = DEFAULT_CONTEXT, form_id) => {
    const uri = uri_getSingleForm
      .replace(/:pdbid/, pdbid)
      .replace(/:form_id/, form_id)
    return _turnableFetch(uri, context)
  },

  getSingleDamageInspection: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    inspection_id
  ) => {
    const uri = uri_getSingleDamageInspection
      .replace(/:pdbid/, pdbid)
      .replace(/:inspection_id/, inspection_id)
    return _turnableFetch(uri, context)
  },

  getInspectionUnitList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectionUnitList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  getInspectionDownloadMediaURL: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectionDownloadMediaURL
    return _customFetch(uri, 'GET', null, null, context, pdbid)
  },

  getInspectionPDFURL: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_inspectionPDFURL
    const method = 'POST'
    return _customFetch(uri, method, null, JSON.stringify(body), context, pdbid)
  },

  getInspectionUploadMediaURL: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectionUploadMediaURL
    const method = 'GET'
    const res = await _customFetch(uri, method, null, null, context, pdbid)
    return res
  },

  addInspectionMedia: async (url, element, contentType) => {
    const uri = url
    const headers = new Headers({
      'Content-Type': contentType || 'multipart/form-data'
    })
    const res = await _customFetch(uri, 'put', headers, element)
    return res
  },

  getInspectionItem: async (pdbid, context = DEFAULT_CONTEXT, inspectionId) => {
    const uri = uri_inspectionById
      .replace(/:pdbid/, pdbid)
      .replace(/:inspectionId/, inspectionId)
    return _turnableFetch(uri, context)
  },

  addNewInspection: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_addNewInspection.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },

  saveDamageForm: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_saveDamageForm.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  saveDamageSingleInspection: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    inspection_id
  ) => {
    const uri = uri_saveDamageSingleInspection
      .replace(/:inspection_id/, inspection_id)
      .replace(/:pdbid/, pdbid)
    console.log(uri)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  updateInspectionItem: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    inspectionId
  ) => {
    const uri = uri_updateInspectionItem
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, inspectionId)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  getInspectionAssignToList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectionAssignToList.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },

  editNewInspection: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    inspectionId
  ) => {
    const uri = uri_editNewInspection
      .replace(/:pdbid/, pdbid)
      .replace(/:id/, inspectionId)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  bulkUpdateInspectionsProfile: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body
  ) => {
    const uri = uri_bulkUpdateInspectionsProfile.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  bulkApprovalPurchaseOrder: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_bulkApprovalPurchaseOrder.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, {}, 'PUT', body, false)
  },

  getInspectStats: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectStats.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getInspectActivityLog: async (unitId, pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_inspectActivityLog
      .replace(/:pdbid/, pdbid)
      .replace(/:unitid/, unitId)
    return _turnableFetch(uri, context)
  },
  syncInspect: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_syncInspect.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', {})
  },
  checkSyncInspect: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_syncInspect.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  requestInspectReport: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_requestInspectReport.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PATCH', body)
  },
  checkInspectReport: async (pdbid, context = DEFAULT_CONTEXT, reportid) => {
    const uri = uri_checkInspectReport
      .replace(/:pdbid/, pdbid)
      .replace(/:reportid/, reportid)
    return _turnableFetch(uri, context)
  },
  getInvoicesList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allInvoices.replace(/:pdbid/, pdbid)
    console.log(`calling ${uri} with context=${JSON.stringify(context)}`)
    return _turnableFetch(uri, context)
  },
  getInvoiceDetails: async (pdbid, context = DEFAULT_CONTEXT, invoiceId) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    console.log(`calling ${uri} with context=${JSON.stringify(context)}`)
    return _turnableFetch(uri, context)
  },
  createInvoice: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_allInvoices.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body)
  },
  updateInvoice: async (pdbid, context = DEFAULT_CONTEXT, invoiceId, body) => {
    const uri = uri_updateOneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  updateChargeStatus: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_updateChargeStatus.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  deleteInvoice: async (pdbid, context = DEFAULT_CONTEXT, invoiceId) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(uri, context, 'DELETE')
  },
  generateInvoiceLines: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    body
  ) => {
    const uri = uri_createInvoiceLines
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(uri, context, 'POST', body)
  },
  createInvoiceLine: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    body
  ) => {
    const uri = uri_invoiceLines
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
      .replace(/:lineid/, '1')
    return _turnableFetch(uri, context, 'POST', body)
  },
  updateInvoiceLine: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    lineId,
    body
  ) => {
    const uri = uri_invoiceLines
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
      .replace(/:lineid/, lineId)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  deleteInvoiceLine: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    lineId
  ) => {
    const uri = uri_invoiceLines
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
      .replace(/:lineid/, lineId)
    return _turnableFetch(uri, context, 'DELETE')
  },
  changePurchaseOrderStatus: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    body
  ) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  addPurchaseOrderTax: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    taxAmount
  ) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(uri, context, 'PUT', {
      tax_amount: taxAmount
    })
  },
  addPurchaseOrderAmountPaid: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    amountPaid
  ) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(uri, context, 'PUT', {
      amount_paid: amountPaid
    })
  },
  attachInvoiceToPurchaseOrder: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    invoiceFilename,
    approvedBy,
    approvedAt,
    status
  ) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)
    return _turnableFetch(
      uri,
      context,
      'PUT',
      {
        invoice_file: invoiceFilename,
        approved_by: approvedBy,
        approved_at: approvedAt,
        status
      },
      false
    )
  },
  detachInvoiceFromPurchaseOrder: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    invoiceId,
    status
  ) => {
    const uri = uri_oneInvoice
      .replace(/:pdbid/, pdbid)
      .replace(/:invoiceid/, invoiceId)

    const body = { invoice_file: null, status }
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  getServicesByProperty: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_servicesByProperty.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getServicesList: async (pdbid, context = DEFAULT_CONTEXT, service, phase) => {
    const uri = uri_servicesList
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
      .replace(/:phase/, phase)
    return _turnableFetch(uri, context)
  },

  getServiceColumns: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_servicesColumns.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getServicesStats: async (pdbid, context = DEFAULT_CONTEXT, service) => {
    const uri = uri_servicesStats
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context)
  },
  getServicesTeams: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service,
    _phase
  ) => {
    const uri = uri_servicesTeams
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context)
  },
  getHoldoversOccupied: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_holdoversOccupied.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  updateServices: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service,
    action,
    body = {},
    trim
  ) => {
    const uri = uri_servicesUpdate
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
      .replace(/:action/, action)
    return _turnableFetch(uri, context, 'PUT', body, trim)
  },
  markCompleteService: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service,
    body = {}
  ) => {
    const uri = uri_serviceMarkComplete
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  unblockService: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service,
    body = {}
  ) => {
    const uri = uri_serviceUnblock
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  getUnitHistory: async (pdbid, context = DEFAULT_CONTEXT, service) => {
    const uri = uri_unitHistory
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'GET', null)
  },
  setupAllGoalsGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupAllGoals.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  setupGoalStatsGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_setupGoalsStats.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  setupGoalLineCreate: async (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupGoalLine
      .replace(/:pdbid/, pdbid)
      .replace(/:goalid/, id)
    return _turnableFetch(uri, context, 'POST', body)
  },
  setupGoalLineUpdate: async (pdbid, context = DEFAULT_CONTEXT, id, body) => {
    const uri = uri_setupGoalLine
      .replace(/:pdbid/, pdbid)
      .replace(/:goalid/, id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  overrideAsIs: async (pdbid, context = DEFAULT_CONTEXT, unit_id, body) => {
    const uri = uri_asIsOverride
      .replace(/:pdbid/, pdbid)
      .replace(/:unit_id/, unit_id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  switchResident: async (pdbid, context = DEFAULT_CONTEXT, unit_id, body) => {
    const uri = uri_residentSwitch
      .replace(/:pdbid/, pdbid)
      .replace(/:unit_id/, unit_id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  overrideTask: async (pdbid, context = DEFAULT_CONTEXT, unit_id, body) => {
    const uri = uri_taskOverride
      .replace(/:pdbid/, pdbid)
      .replace(/:unit_id/, unit_id)
    return _turnableFetch(uri, context, 'PUT', body)
  },
  vendorMyWorkGet: async (pdbid, context = DEFAULT_CONTEXT, vendorId) => {
    const uri = uri_vendorMyWork
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, vendorId)
    return _turnableFetch(uri, context)
  },
  vendorPendingGet: async (pdbid, context = DEFAULT_CONTEXT, vendorId) => {
    const uri = uri_vendorPending
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, vendorId)
    return _turnableFetch(uri, context)
  },
  vendorApprovedGet: async (pdbid, context = DEFAULT_CONTEXT, vendorId) => {
    const uri = uri_vendorApproved
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, vendorId)
    return _turnableFetch(uri, context)
  },
  vendorInspectionPut: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service,
    body
  ) => {
    const uri = uri_servicesUpdate
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
      .replace(/:action/, 'InProgress')

    return _turnableFetch(uri, context, 'PUT', body)
  },
  qcMyWorkGet: async (pdbid, context = DEFAULT_CONTEXT, staffId) => {
    const uri = uri_qcMyWork
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, staffId)
    return _turnableFetch(uri, context)
  },
  qcQueueGet: async (pdbid, context = DEFAULT_CONTEXT, staffId) => {
    const uri = uri_qcQueue
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, staffId)
    return _turnableFetch(uri, context)
  },
  qcApprovedGet: async (pdbid, context = DEFAULT_CONTEXT, staffId) => {
    const uri = uri_qcApproved
      .replace(/:pdbid/, pdbid)
      .replace(/:staffid/, staffId)
    return _turnableFetch(uri, context)
  },
  qcCompletePut: async (pdbid, context = DEFAULT_CONTEXT, job, body) => {
    const uri = uri_servicesUpdate
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, job.service)
      .replace(/:action/, 'Approve')

    return _turnableFetch(uri, context, 'PUT', body)
  },
  qcAssignPut: async (pdbid, context = DEFAULT_CONTEXT, service, body) => {
    const uri = uri_servicesUpdate
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
      .replace(/:action/, 'AssignQC')

    return _turnableFetch(uri, context, 'PUT', body)
  },
  allNotesGet: async (pdbid, context = DEFAULT_CONTEXT, unitid) => {
    const uri = uri_notes_all.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  notesGet: async (pdbid, context = DEFAULT_CONTEXT, unitid) => {
    const uri = uri_notes.replace(/:pdbid/, pdbid).replace(/:unitid/, unitid)
    return _turnableFetch(uri, context)
  },
  getUnitServiceNotes: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    unitid,
    serviceTypeId
  ) => {
    const uri = uri_unit_service_notes
      .replace(/:pdbid/, pdbid)
      .replace(/:servicetype_id/, serviceTypeId)
      .replace(/:unit_id/, unitid)
    return _turnableFetch(uri, context)
  },
  getQCVendorUnitServiceNotes: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    unitid,
    serviceTypeId
  ) => {
    const uri = uri_getQCVendorUnitServiceNotes
      .replace(/:pdbid/, pdbid)
      .replace(/:servicetype_id/, serviceTypeId)
      .replace(/:unit_id/, unitid)
    return _turnableFetch(uri, context)
  },
  notesCreate: async (pdbid, context = DEFAULT_CONTEXT, unitid, body) => {
    const uri = uri_notes.replace(/:pdbid/, pdbid).replace(/:unitid/, unitid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  notesUpdate: async (pdbid, context = DEFAULT_CONTEXT, unitid, body) => {
    const uri = uri_notes.replace(/:pdbid/, pdbid).replace(/:unitid/, unitid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  notesDelete: async (pdbid, context = DEFAULT_CONTEXT, unitid, body) => {
    const uri = uri_notes.replace(/:pdbid/, pdbid).replace(/:unitid/, unitid)
    return _turnableFetch(uri, context, 'DELETE', body)
  },
  getScheduleMainData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_scheduleMainData.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getScheduleCapacityData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_scheduleCapacity.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getScheduleUnitData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_scheduleUnitData.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getScheduleTeamData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_scheduleTeamData.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getScheduleTeamsCapacity: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service
  ) => {
    const uri = uri_scheduleTeamsCapacity
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'GET')
  },
  getScheduleUnitsInformation: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service
  ) => {
    const uri = uri_scheduleUnitsInformation
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'GET')
  },
  scheduleService: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    service,
    scheduleData
  ) => {
    const uri = uri_scheduleService
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'PUT', scheduleData, false)
  },
  getScheduleBoardsData: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_scheduleBoardsData.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getSchedulingStatus: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_schedulingStatus.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  autoscheduleService: async (pdbid, context = DEFAULT_CONTEXT, service) => {
    const uri = uri_autoscheduleService
      .replace(/:pdbid/, pdbid)
      .replace(/:service/, service)
    return _turnableFetch(uri, context, 'PUT')
  },
  createPurchaseOrder: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_purchaseOrderDetail
      .replace(/:pdbid/, pdbid)
      .replace(/:orderId/, 1)
    return _turnableFetch(uri, context, 'POST', body)
  },
  getPurchaseOrders: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_purchaseOrders.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getPurchaseDetails: async (pdbid, context = DEFAULT_CONTEXT, id) => {
    const uri = uri_purchaseOrderDetail
      .replace(/:pdbid/, pdbid)
      .replace(/:orderId/, id)
    return _turnableFetch(uri, context, 'GET')
  },
  getPurchaseTasks: async (pdbid, context = DEFAULT_CONTEXT, vendorId) => {
    const uri = uri_purchaseOrderTasks
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, vendorId)
    return _turnableFetch(uri, context, 'GET')
  },
  getTemplateElements: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allCompanyTemplatesElements.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  updateTemplateElements: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    type,
    elementsToUpdate
  ) => {
    const uri = uri_updateTemplateElements
      .replace(/:pdbid/, pdbid)
      .replace(/:type/, type)
    return _turnableFetch(uri, context, 'POST', elementsToUpdate, false)
  },
  deleteTemplateElement: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    type,
    elementToDelete
  ) => {
    const uri = uri_deleteTemplateElement
      .replace(/:pdbid/, pdbid)
      .replace(/:type/, type)
    return _turnableFetch(uri, context, 'DELETE', elementToDelete, false)
  },
  createOrUpdateOrDuplicateCompanyTemplate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    templateId
  ) => {
    const uri = uri_createOrUpdateOrDuplicateCompanyTemplate
      .replace(/:pdbid/, pdbid)
      .replace(/:template_id/, templateId)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  getCompanyTemplateList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allCompanyTemplates.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getCompanyTemplateDetails: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    templateId
  ) => {
    const uri = uri_companyTemplateDetails
      .replace(/:pdbid/, pdbid)
      .replace(/:templateid/, templateId)
    return _turnableFetch(uri, context)
  },
  getCompanyTemplateProperties: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    templateId
  ) => {
    const uri = uri_companyTemplateProperties
      .replace(/:pdbid/, pdbid)
      .replace(/:templateid/, templateId)
    return _turnableFetch(uri, context)
  },
  deleteCompanyTemplate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    templateId
  ) => {
    const uri = uri_deleteCompanyTemplate
      .replace(/:pdbid/, pdbid)
      .replace(/:template_id/, templateId)
    return _turnableFetch(uri, context, 'DELETE')
  },
  duplicateCompanyTemplate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    templateId
  ) => {
    const uri = uri_companyTemplate
      .replace(/:pdbid/, pdbid)
      .replace(/:templateid/, templateId)
    return _turnableFetch(uri, context, 'DELETE')
  },
  shareCompanyTemplate: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_companyTemplateShare.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  allRequestVendorGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allRequestVendor.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  addVendor: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_setupAddVendor.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },

  deleteVendor: async (pdbid, context = DEFAULT_CONTEXT, body, id) => {
    const uri = uri_deleteRequestVendor
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorId/, id)
    return _turnableFetch(uri, context, 'DELETE', body, false)
  },
  allVendorServiceGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allVendorService.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  checkVendorExists: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_checkVendorExists.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  allRequestRFPGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allRequestRFP.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  allRFPTitleGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allRFPTitle.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  addRFPRequest: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_addRFPRequest.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  checkRFPVendorExists: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_checkRFPVendorExists.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  declineVendorRequest: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    requestId
  ) => {
    const uri = uri_declineVendorRequest
      .replace(/:pdbid/, pdbid)
      .replace(/:requestId/, requestId)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  vendorQuotationGet: async (pdbid, context = DEFAULT_CONTEXT, quoteid) => {
    const uri = uri_vendorQuotation
      .replace(/:pdbid/, pdbid)
      .replace(/:quoteid/, quoteid)
    return _turnableFetch(uri, context, 'GET')
  },
  vendorQuotationBusinessInformationGet: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    quoteid
  ) => {
    const uri = uri_vendorQuotationBusinessInformation
      .replace(/:pdbid/, pdbid)
      .replace(/:quoteid/, quoteid)
    return _turnableFetch(uri, context, 'GET')
  },
  updateVendorQuotation: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    quoteid
  ) => {
    const uri = uri_updateVendorQuotation
      .replace(/:pdbid/, pdbid)
      .replace(/:quoteid/, quoteid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  updateVendorBusinessInformation: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    quoteid
  ) => {
    const uri = uri_updateVendorBusinessInformation
      .replace(/:pdbid/, pdbid)
      .replace(/:quoteid/, quoteid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },

  updateVendorProfile: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_updateVendorProfile
    return _turnableFetch(uri, Mgmt, 'POST', body, false)
  },

  requestsForProposalGet: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    vendor_id
  ) => {
    const uri = uri_requestsForProposal
      .replace(/:pdbid/, pdbid)
      .replace(/:vendorid/, vendor_id)
    return _turnableFetch(uri, context, 'GET')
  },
  vendorDetailsByEmailGet: async (pdbid, context = DEFAULT_CONTEXT, email) => {
    const uri = uri_vendorDetailsByEmail.replace(/:pdbid/, pdbid)
    const currentContext = {
      ...context,
      email
    }
    return _turnableFetch(uri, currentContext, 'GET')
  },

  getSharedTemplates: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allSharedTemplates.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getPropertyFormsList: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allPropertyForms.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  getPropertyFormDetails: async (pdbid, context = DEFAULT_CONTEXT, formId) => {
    const uri = uri_propertyFormDetails
      .replace(/:pdbid/, pdbid)
      .replace(/:formid/, formId)
    return _turnableFetch(uri, context)
  },
  deletePropertyForm: async (pdbid, context = DEFAULT_CONTEXT, formId) => {
    const uri = ui_deletePropertyForm
      .replace(/:pdbid/, pdbid)
      .replace(/:form_id/, formId)
    return _turnableFetch(uri, context, 'DELETE')
  },
  duplicatePropertyFormTemplate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    templateId
  ) => {
    const uri = uri_companyTemplate
      .replace(/:pdbid/, pdbid)
      .replace(/:templateid/, templateId)
    return _turnableFetch(uri, context, 'DELETE')
  },
  createOrUpdateOrDuplicatePropertyForm: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    formId
  ) => {
    const uri = ui_createOrUpdateOrDuplicatePropertyForm
      .replace(/:pdbid/, pdbid)
      .replace(/:form_id/, formId)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  vendorQuotationCapacityByDateGet: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    quoteid
  ) => {
    const uri = uri_vendorQuotationCapacityByDate
      .replace(/:pdbid/, pdbid)
      .replace(/:quoteid/, quoteid)
    return _turnableFetch(uri, context, 'GET')
  },
  updateVendorQuotationCapacityByDate: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    quoteid
  ) => {
    const uri = uri_updateVendorQuotationCapacityByDate
      .replace(/:pdbid/, pdbid)
      .replace(/:quoteid/, quoteid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  allUsersGet: async (pdbid, context) => {
    const uri = uri_allUsers
    return _turnableFetch(uri, context, 'GET')
  },
  allRolesGet: async (context = DEFAULT_CONTEXT) => {
    const uri = uri_allRoles
    return _turnableFetch(uri, context, 'GET')
  },
  updateUserRole: async (body, context = DEFAULT_CONTEXT) => {
    const uri = uri_updateUserRole
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  setupUserBlock: async (userid, body, context = DEFAULT_CONTEXT) => {
    const uri = uri_block_unblock_user.replace(/:id/, userid)
    return _turnableFetch(uri, context, 'PATCH', body, false)
  },
  setupSingleUserUpdate: async (userid, body) => {
    const uri = userDetail_uri.replace(/:id/, userid)
    return _turnableFetch(uri, Mgmt, 'PATCH', body, false)
  },
  setupCreateUser: async (body, context) => {
    return _turnableFetch(user_uri, context, 'POST', body, false)
  },
  setupUserResetPassword: async (userid, body) => {
    const uri = uri_resetPassword.replace(/:id/, userid)
    return _turnableFetch(uri, Mgmt, 'PATCH', body, false)
  },
  updateServiceOptions: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_updateServiceOptios.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  updateServiceSchedule: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    serviceType
  ) => {
    const uri = uri_updateServiceSchedule
      .replace(/:pdbid/, pdbid)
      .replace(/:serviceType/, serviceType)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  allUnitsGet: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_allUnits.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getChargesList: async (pdbid, context = DEFAULT_CONTEXT, to, from) => {
    const uri = uri_chargesList
      .replace(/:pdbid/, pdbid)
      .replace(/:from/, from)
      .replace(/:to/, to)
    return _turnableFetch(uri, context)
  },
  updateAutomaticCharge: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    body,
    task_id
  ) => {
    const uri = uri_updateAutomaticCharge
      .replace(/:pdbid/, pdbid)
      .replace(/:task_id/, task_id)
    return _turnableFetch(uri, context, 'PUT', body, false)
  },
  allPONumbersGet: async (pdbid, context = DEFAULT_CONTEXT, vendor_id) => {
    const uri = uri_allPONumbers
      .replace(/:pdbid/, pdbid)
      .replace(/:vendor_id/, vendor_id)
    return _turnableFetch(uri, context, 'GET')
  },
  getApprovalsList: async (
    pdbid,
    context = DEFAULT_CONTEXT,
    to,
    from,
    user_id
  ) => {
    const uri = uri_approvalsList
      .replace(/:pdbid/, pdbid)
      .replace(/:from/, from)
      .replace(/:to/, to)
      .replace(/:user_id/, user_id)

    return _turnableFetch(uri, {}, 'GET', null, true)
  },
  getAllUnitSpace: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_getAllUnitSpace.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  getAllPropertyUnitSpace: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_getAllPropertyUnitSpace.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'GET')
  },
  createElementSpace: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_createElementSpace.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  getAdhocUnitSpaces: async (pdbid, context = DEFAULT_CONTEXT) => {
    const uri = uri_getAdhocUnitSpaces.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context)
  },
  saveAdhocDamage: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_saveAdhocDamage.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'POST', body, false)
  },
  deleteAdhocDamage: async (pdbid, context = DEFAULT_CONTEXT, body) => {
    const uri = uri_deleteAdhocDamage.replace(/:pdbid/, pdbid)
    return _turnableFetch(uri, context, 'DELETE', body, false)
  },
  logout: () => {
    Auth.accessToken = null
    _eraseCookie('at')
    _eraseCookie('ctx')
    _eraseCookie('tnt')
    _eraseCookie('uidt')
    window.productFruits.services.destroy()
  }
}

//Export combined object
export default { ...constants, ...methods }

//Local vars
const TOKEN_TYPE = 'Bearer'
let Auth = {
  accessToken: null,
  tokenType: TOKEN_TYPE
}

let Mgmt = {
  accessToken: null,
  tokenType: TOKEN_TYPE
}

let Uidt = {
  idToken: null,
  tokenType: TOKEN_TYPE
}

const cookieDays = 2

//Local methods
const _setCookie = (name, data) =>
  Cookie.set(name, data, { expires: cookieDays })

const _eraseCookie = name => Cookie.erase(name)

const _getCookie = name => Cookie.get(name)

const _getAuthHeaders = h => ({
  Authorization: `${h.tokenType} ${h.accessToken}`
})

const _getFullPath = (domain, uri, prefix = 'https') => {
  if ((domain || '').indexOf('localhost') !== -1) prefix = 'http'
  return `${prefix}://${domain}${uri}`
}

const _turnableFetch = async (
  uri,
  context = DEFAULT_CONTEXT,
  method = 'GET',
  body = null,
  trim = true
) => {
  const { email, project, properties, property } = context || {}
  let defaultHeaders = {
    Property: property || '',
    Project: project || '',
    Properties: properties || '',
    'Content-Type': 'application/json',
    Authorization: `${Uidt.tokenType} ${Uidt.idToken || ''}`
  }
  if (email) {
    defaultHeaders = {
      ...defaultHeaders,
      email
    }
  }

  const headers = new Headers(defaultHeaders)

  const params = {
    method,
    headers
  }
  if (body !== null)
    params.body = JSON.stringify(trim ? trimObject(body) : body)
  const res = await fetch(uri, params)
  const { status } = res || {}
  switch (status) {
    case 403:
    case 401:
      history.push('/logout')
      break
    default:
      break
  }
  return res
}

const _superFetch = async (
  uri,
  headers,
  method = 'GET',
  body = null,
  trim = true
) => {
  const params = {
    method,
    headers: _getAuthHeaders(headers)
  }
  const trimmed = trim ? trimObject(body) : body
  if (!trim) {
    params.headers['Content-Type'] = 'application/json'
  }
  if (body !== null) params.body = JSON.stringify(trimmed)
  const res = await fetch(uri, params)
  switch (res.status) {
    case 403:
    case 401:
      history.push('/logout')
      break
    default:
      break
  }
  return res
}

// Fetch with different headers specification for API
const _customFetch = async (
  uri,
  method = 'GET',
  headers = null,
  body = null,
  context = {},
  pdbid = ''
) => {
  const { property, project } = context || {}
  const defaultHeaders = new Headers({
    Property: property || '',
    Project: project || '',
    pdbid: pdbid || '',
    'Content-Type': 'application/json',
    Authorization: `${Uidt.tokenType} ${Uidt.idToken || ''}`
  })
  const params = {
    method,
    headers: defaultHeaders
  }
  if (body !== null) params.body = body
  if (headers !== null) params.headers = headers
  const res = await fetch(uri, params)
  switch (res.status) {
    case 403:
    case 401:
      history.push('/logout')
      break
    default:
      break
  }
  return res
}

//URI's
const uri_userInfo = _getFullPath(DOMAIN.auth0, '/userinfo')
const uri_mgmtAccessToken = _getFullPath(
  DOMAIN.service,
  '/api/access-token?id=:id'
)
const uri_user = _getFullPath(DOMAIN.auth0, '/api/v2/users')
const uri_roles = _getFullPath(DOMAIN.auth0, '/api/v2/roles')
const uri_usersByRole = _getFullPath(DOMAIN.auth0, '/api/v2/roles/:id/users')
const uri_userDetail = _getFullPath(DOMAIN.service, '/api/user')
const uri_new_userDetail = _getFullPath(DOMAIN.service, '/api/v2/user')
const uri_change_password = _getFullPath(
  DOMAIN.service,
  '/api/v2/users/change-password'
)
const uri_userRoles = _getFullPath(DOMAIN.service, '/api/userrole')
const uri_block_unblock_user = _getFullPath(DOMAIN.service, '/api/users/:id')
const uri_globalSearch = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/search/:q'
)
const uri_keysList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/keys'
)
const uri_keysStats = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/keys/stats'
)
const uri_syncKeys = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/lease/sync'
)
const uri_updateKey = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/keys/:unit'
)
const uri_inspectList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inspections'
)

const uri_inspectionList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/list_inspections'
)

const uri_downloadInspectionsList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/jobs'
)

const uri_downloadState = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/jobs/state'
)

const uri_downloadInspectionMedia = _getFullPath(
  DOMAIN.service,
  '/api/storage/zip/createzip'
)

const uri_getFilteredInspectionList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/filter_inspections_list/:to/:from'
)
const uri_getApprovedInspectionList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/approved_inspections'
)
const uri_changeDamageInspectionStatus = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/approved_inspections'
)
const uri_inspectionMediaById = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/media/:inspectionId'
)
const uri_inspectionFormList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/filtered_form'
)
const uri_getSingleForm = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages_setup/form/:form_id'
)
const uri_getSingleDamageInspection = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/:inspection_id'
)
const uri_getAdhocUnitSpaces = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/unitspace'
)
const uri_inspectionUnitList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/properties'
)
const uri_inspectionDownloadMediaURL = _getFullPath(
  DOMAIN.service,
  '/api/storage/download/PARURL'
)
const uri_inspectionPDFURL = _getFullPath(
  DOMAIN.service,
  '/api/pdfGenrator/generateSinglePdf'
)
const uri_inspectionUploadMediaURL = _getFullPath(
  DOMAIN.service,
  '/api/storage/upload/PARURL'
)
const uri_inspectionById = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/inspection/:inspectionId'
)
const uri_addNewInspection = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/create_inspection'
)
const uri_saveDamageForm = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages_setup/form'
)
const uri_saveDamageSingleInspection = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/:inspection_id'
)
const uri_updateInspectionItem = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/inspection/:id'
)
const uri_inspectionAssignToList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/get_filtered_users'
)
const uri_editNewInspection = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/editInspection/:id'
)
const uri_bulkUpdateInspectionsProfile = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/ins/inspection/bulkapprove'
)
const uri_bulkApprovalPurchaseOrder = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/bulk_approve'
)
const uri_inspectStats = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inspections/stats'
)
// TODO HIT THE ACTUAL ENDPOINT
const uri_inspectActivityLog = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/:unitid/turnable/turn/inspections/stats'
)
const uri_syncInspect = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inspections/sync'
)
const uri_requestInspectReport = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inspections/report'
)
const uri_checkInspectReport = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inspections/report/:reportid'
)
const uri_allInvoices = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/invoice'
)
const uri_oneInvoice = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/purchase_order/:invoiceid'
)
const uri_updateOneInvoice = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/purchase_orderline/:invoiceid'
)
const uri_updateChargeStatus = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/charges/bulk_do_not_invoice'
)
const uri_createInvoiceLines = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/purchase_orderline/:invoiceid'
)
const uri_invoiceLines = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/invoice-lines/:invoiceid/:lineid'
)
const uri_servicesList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/:service/:phase'
)
const uri_servicesColumns = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/dynamic_unitspaces'
)
const uri_servicesStats = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/:service/stats'
)
const uri_servicesTeams = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/teams/:service'
)
const uri_servicesUpdate = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/:service/:action'
)
const uri_serviceMarkComplete = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/:service/Complete'
)
const uri_serviceUnblock = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/:service/unblock'
)
const uri_unitHistory = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/:service/history'
)
const uri_holdoversOccupied = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/holdovers'
)
/*const uri_holdoversUnoccupied = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/holdovers'
)*/
const uri_dashboard = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/dashboard/:category'
)
const uri_regionalDashboard = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/dashboard/regional/:category'
)
const uri_corporateDashboard = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/dashboard/corporate/:category'
)
const uri_setupAllVendors = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/vendors'
)
const uri_allVendorListGet = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/all_vendors'
)
const uri_allVendorServicePricing = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/workbook/:serviceid/:vendorid'
)
const uri_updateSingleVendorServicePricing = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/workbook/unit/:serviceid/:vendorid'
)
const uri_allVendorPricingItemOption = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/unique_item_options/:id'
)
const uri_setupOneVendor = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/vendor/:vendorid'
)
const uri_deleteVendor = _getFullPath(
  DOMAIN.service,
  '/api/v2/vendor/:vendorid'
)
const uri_toggleVendorAvailablitity = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/vendor/:vendorid'
)
const uri_deleteVendorServices = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/workbook/:serviceid/:vendorid'
)
const uri_setupOneVendorFloorplan = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/vendor/floorplan/:vendorid'
)
const uri_setupServices = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/services'
)
const uri_setupAllBudgets = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/budgets'
)
const uri_setupOneBudget = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/budget/:budgetid'
)
const uri_setupBudgetLine = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/budgetline/:budgetid'
)
const uri_setupAllProperties = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/properties'
)
const uri_setupOnePropertyBunch = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/property/:propertyid'
)
const uri_setupOneProperty = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/property/:id/:scope'
)
const uri_setupOneStaff = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/staff/:staffid'
)
const uri_addNewUser = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/users/:user_id'
)

const uri_reactivate_or_inactivate_user = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/staff/active'
)

const allPropertiesList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/users/property'
)
const allRolesList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/users/roles'
)
const allUsersListGet = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/users_list'
)
const allVendorAssociatedUsers = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/vendor/:vendor_id'
)
const updateUserAccountStatus = _getFullPath(
  DOMAIN.service,
  '/api/v2/users/bulk-block'
)
const updateUserPassword = _getFullPath(
  DOMAIN.service,
  '/api/v2/users/resetPassword/:user_id'
)
const associateUserToVendor = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/vendor/:vendor_id'
)
const userAccountDelete = _getFullPath(DOMAIN.service, '/api/v2/users/:user_id')
const checkEmailExists = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/get_users/:email'
)
const uri_verifyStaffUserWithEmail = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/staff'
)
const uri_setupAllStaff = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/staff'
)
const uri_allStaffList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/get_staff'
)
const uri_delete_staff = _getFullPath(DOMAIN.service, '/api/v2/staff/:user_id')
const uri_setupAllTeams = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/teams'
)
const uri_allTeamListGet = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/all_teams'
)
const uri_setupOneTeam = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/team/:teamid'
)
const uri_setupOneTeamCapacity = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/team/capacity/:teamid'
)
const uri_setupAllServices = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/all_services'
)
const uri_turnboard = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/turnboard'
)
const uri_turnboardDetail = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/turnboard_detail'
)
const uri_turnboardServiceStatus = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/status'
)
const uri_turnboardServiceCost = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/cost'
)

const uri_turnboardInHouseServices = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inhouse'
)

const uri_updateAsIsStatus = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/override/sc/asis'
)
const uri_groupOrUngroupUnits = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/grouping/2'
)

const uri_updateEnableServices = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/override/sc/enable_service'
)

const uri_markOrUnMarkInHouse = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/inhouse/:inhouse_id'
)

const uri_getAllGroups = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/grouping'
)
const uri_leaseOverride = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/override/lease'
)
const uri_turnboard2ServiceOptions = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/turnboard2/task/:unitspaceid/:serviceid'
)
const uri_setTurnboard2ServiceOptions = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/turnboard2/task/:unitspaceid/:serviceid'
)
const uri_getServicesAndTasts = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/turnboard/tasks/:usid'
)
const uri_getScheduleByService = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/capacity/sc/:id'
)
const uri_getTurnboardAllTags = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/tags'
)
const uri_getTurnboardUnitTags = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/tags/unit/:id'
)
const uri_addTurnboardUnitTag = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/tags/:id'
)
const uri_deleteTurnboardUnitTag = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/tags/unit/:id'
)
const uri_addTurnboardAllTags = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/tags/1'
)
const uri_deleteTurnboardAllTags = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/tags/:id'
)
const uri_asIsOverride = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/override/asis/:unit_id'
)
const uri_residentSwitch = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/override/swap/:unit_id'
)
const uri_taskOverride = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/override/task/:unit_id'
)
const uri_setupAllGoals = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/goals'
)
const uri_setupGoalsStats = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/goals/stats'
)
const uri_setupGoalLine = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/goalline/:goalid'
)
const uri_vendorMyWork = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/vendor/:vendorid/MyWork'
)
const uri_vendorPending = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/vendor/:vendorid/Pending'
)
const uri_vendorApproved = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/vendor/:vendorid/Approved'
)
const uri_notes = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/notes/:unitid'
)
const uri_unit_service_notes = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service/notes/:servicetype_id/:unit_id'
)
const uri_getQCVendorUnitServiceNotes = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/QC/notes/:servicetype_id/:unit_id'
)
const uri_notes_all = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/notes'
)
const uri_qcMyWork = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/qc/:staffid/MyWork'
)
const uri_qcQueue = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/qc/:staffid/Queue'
)
const uri_qcApproved = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/mobile/qc/:staffid/Approved'
)
const uri_scheduleMainData = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/services'
)
const uri_scheduleCapacity = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/capacity'
)
const uri_scheduleUnitData = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/scheduleunitdata'
)
const uri_scheduleTeamData = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/scheduleteamdata'
)
const uri_scheduleBoardsData = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/units'
)
const uri_scheduleService = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/units/:service'
)
const uri_scheduleTeamsCapacity = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/capacity/:service'
)
const uri_scheduleUnitsInformation = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/units/:service'
)
const uri_schedulingStatus = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/autoschedule/All'
)
const uri_autoscheduleService = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/autoschedule/:service'
)
const uri_purchaseOrders = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/purchase_order'
)
const uri_purchaseOrderDetail = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/purchase_order/:orderId'
)
const uri_purchaseOrderTasks = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/charges_po/:vendorid'
)
const uri_servicesByProperty = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/dynamic_services'
)

const uri_allCompanyTemplates = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/templates'
)

const uri_createOrUpdateOrDuplicateCompanyTemplate = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/template/:template_id'
)

const uri_allPropertyForms = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/forms'
)

const uri_allSharedTemplates = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/form/template_list'
)

const uri_allCompanyTemplatesElements = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/elements'
)
const uri_updateTemplateElements = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/element/:type'
)
const uri_deleteTemplateElement = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/element/:type'
)
const uri_companyTemplateDetails = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/template/:templateid'
)
const uri_companyTemplateProperties = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/template/property'
)
const uri_deleteCompanyTemplate = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/template/:template_id'
)
const uri_companyTemplate = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/:templateid'
)
const uri_companyTemplateShare = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/template/property'
)

const uri_propertyFormDetails = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/form/:formid'
)

const ui_createOrUpdateOrDuplicatePropertyForm = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/form/:form_id'
)

const ui_deletePropertyForm = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/form/:form_id'
)

const uri_setupAddVendor = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/vendors/1'
)
const uri_allVendorService = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/setup/services'
)
const uri_checkVendorExists = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/vendor/exists'
)

const uri_allRequestVendor = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/vendors'
)
const uri_deleteRequestVendor = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/vendors/:vendorId'
)
const uri_allRequestRFP = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request'
)
const uri_allRFPTitle = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/form'
)
const uri_addRFPRequest = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/1'
)
const uri_checkRFPVendorExists = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/exists'
)
const uri_declineVendorRequest = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/:requestId'
)
const uri_vendorQuotation = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/items/detail/:quoteid'
)
const uri_updateVendorQuotation = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/item/:quoteid'
)
const uri_vendorQuotationBusinessInformation = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/:quoteid'
)
const uri_updateVendorBusinessInformation = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/request/:quoteid'
)
const uri_updateVendorProfile = _getFullPath(
  DOMAIN.service,
  '/api/vendors/update'
)
const uri_requestsForProposal = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/vendorRequest/:vendorid'
)
const uri_vendorDetailsByEmail = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/vendorEmail'
)
const uri_vendorQuotationCapacityByDate = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/capacity/detail/:quoteid'
)
const uri_updateVendorQuotationCapacityByDate = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/rfp/capacity/item/:quoteid'
)
const uri_allUsers = _getFullPath(DOMAIN.service, '/api/users')
const uri_allRoles = _getFullPath(DOMAIN.service, '/api/roles')
const uri_updateUserRole = _getFullPath(DOMAIN.service, '/api/users/roles')
const uri_updateServiceOptios = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/service_options/Unit19037'
)

const uri_updateServiceSchedule = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/schedule/units/:serviceType'
)
const uri_allUnits = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/units'
)
const uri_chargesList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/charges/:to/:from'
)
const uri_updateAutomaticCharge = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/charges/:task_id'
)
const uri_allPONumbers = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/purchase_order_filter/:vendor_id'
)
const uri_approvalsList = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/po/approvals/:to/:from/:user_id'
)
const uri_getAllUnitSpace = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/element/unitspace'
)
const uri_getAllPropertyUnitSpace = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/property/unit_spaces'
)
const uri_createElementSpace = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/insp/element/space_unitspace'
)

const userDetail_uri = _getFullPath(DOMAIN.service, '/api/users/:id')
const new_user_uri = _getFullPath(DOMAIN.service, '/api/v2/users')
const update_user_uri = _getFullPath(DOMAIN.service, '/api/v2/users/:user_id')
const user_uri = _getFullPath(DOMAIN.service, '/api/users')
const uri_resetPassword = _getFullPath(
  DOMAIN.service,
  '/api/users/resetPassword/:id'
)
const uri_saveAdhocDamage = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/adhoc_damage'
)
const uri_deleteAdhocDamage = _getFullPath(
  DOMAIN.turnable,
  '/ords/:pdbid/turnable/turn/damages/adhoc_damage'
)

export const getServiceUpdateUri = (pdbid, service, action) => {
  return uri_servicesUpdate
    .replace(/:pdbid/, pdbid)
    .replace(/:service/, service)
    .replace(/:action/, action)
}

const getDashboardBaseUri = (context, isRegional) =>
  isRegional
    ? uri_regionalDashboard
    : context.property
    ? uri_dashboard
    : uri_corporateDashboard
