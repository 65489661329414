import React, { useRef, useState } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import ReactTooltip from 'react-tooltip'
import '../../../styles/common.scss'

const ShowMoreItemsButton = ({
  icon,
  shouldMarkAsIs,
  shouldUnmarkAsIs,
  hasRenewalSpace,
  shouldGroupUnits,
  shouldUngroupUnits,
  shouldOverrideLease,
  shouldUndoOverrideLease,
  isMarkedAsIsOrIsCommonArea,
  onMarkOrUnmarkAsIs,
  markEnableServices,
  unMarkEnableServices,
  anyServiceStatusIsApproved,
  shouldMarkInHouse,
  shouldUnmarkInHouse,
  showInHouse,
  onGroupOrUngroupUnits,
  onLeaseOverrideOrUndo,
  onMarkOrUnMarkEnableServices,
  onMarkOrUnMarkInHouse
}) => {
  const [isActive, setActive] = useState(false)
  const menuRef = useRef(null)

  const shouldMarkOrUnmark = shouldMarkAsIs || shouldUnmarkAsIs
  const shouldGroupOrUngroupUnits = shouldGroupUnits || shouldUngroupUnits
  const shouldOverrideOrUndoLease =
    shouldOverrideLease || shouldUndoOverrideLease
  const shouldMarkOrUnMarkInHouse = shouldMarkInHouse || shouldUnmarkInHouse

  const shouldMarkOrUnMarkEnableServices =
    markEnableServices || (unMarkEnableServices && !anyServiceStatusIsApproved)

  const markAsIsText = shouldUnmarkAsIs ? 'Remove As-Is' : 'Mark As-Is'
  const groupUnitsText = shouldUngroupUnits ? 'Ungroup Units' : 'Group Units'

  const overrideLeaseText = shouldUndoOverrideLease
    ? 'Undo Lease Override'
    : 'Override Lease Status'

  const markOrUnMarkEnableServicesText = markEnableServices
    ? 'Mark Enable Services'
    : unMarkEnableServices
    ? 'Unmark Enable Services'
    : 'Mark Enable Services'

  const markOrUnMarkInHouseText = shouldUnmarkInHouse
    ? 'Unmark In-House'
    : 'Mark In-House'

  useClickOutside(menuRef, () => {
    setActive(false)
  })

  const handleToggle = () => {
    setActive(!isActive)
  }

  return (
    <div ref={menuRef} className={`dropdown ${isActive ? ' is-active' : ''}`}>
      <div className="dropdown-trigger" title="Show More">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu">
          <img src={icon} alt="icon" onClick={handleToggle} />
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <a
            data-tip
            data-for="markAsIs"
            href="#"
            className={`dropdown-item ${
              !shouldMarkOrUnmark ? 'disable-tooltip' : ''
            }`}
            onClick={e => {
              e.preventDefault()
              if (shouldMarkOrUnmark) {
                onMarkOrUnmarkAsIs()
                setActive(false)
              }
            }}>
            {markAsIsText}
          </a>
          <a
            class={`dropdown-item ${
              !shouldOverrideOrUndoLease ? 'disable-tooltip' : ''
            }`}
            href="#"
            data-tip
            data-for="leaseOverride"
            onClick={e => {
              e.preventDefault()

              if (shouldOverrideOrUndoLease) {
                onLeaseOverrideOrUndo()
                setActive(false)
              }
            }}>
            {overrideLeaseText}
          </a>
          <a
            href="#"
            data-tip
            data-for="groupUnits"
            className={`dropdown-item ${
              !shouldGroupOrUngroupUnits ? 'disable-tooltip' : ''
            }`}
            onClick={e => {
              e.preventDefault()
              if (shouldGroupOrUngroupUnits) {
                onGroupOrUngroupUnits()
                setActive(false)
              }
            }}>
            {groupUnitsText}
          </a>
          <a
            href="#"
            data-tip
            data-for="enableServices"
            className={`dropdown-item ${
              !shouldMarkOrUnMarkEnableServices ? 'disable-tooltip' : ''
            }`}
            onClick={e => {
              e.preventDefault()
              if (shouldMarkOrUnMarkEnableServices) {
                onMarkOrUnMarkEnableServices()
                setActive(false)
              }
            }}>
            {markOrUnMarkEnableServicesText}
          </a>
          {showInHouse && (
            <a
              href="#"
              data-tip
              data-for="inHouse"
              className={`dropdown-item ${
                !shouldMarkOrUnMarkInHouse ? 'disable-tooltip' : ''
              }`}
              onClick={e => {
                e.preventDefault()
                if (shouldMarkOrUnMarkInHouse) {
                  onMarkOrUnMarkInHouse()
                  setActive(false)
                }
              }}>
              {markOrUnMarkInHouseText}
            </a>
          )}
        </div>
      </div>
      <ReactTooltip
        className="customTooltipTheme"
        textColor="black"
        backgroundColor="#fff"
        arrowColor="#fff"
        id="markAsIs"
        place="bottom"
        effect="solid"
        disable={shouldMarkOrUnmark}>
        {!hasRenewalSpace && !shouldMarkOrUnmark && (
          <>
            <p>This option is not available either</p>
            <p>because no space is selected or when spaces</p>
            <p>belonging to different statuses are selected.</p>
          </>
        )}
        {hasRenewalSpace && !shouldMarkOrUnmark && (
          <>
            <p>You cannot change the status of a Renewal to As-Is.</p>
            <p>If you had manually changed it previously to Renewal,</p>
            <p>then please first Undo the lease override and to</p>
            <p>proceed with Mark As-Is.</p>
          </>
        )}
      </ReactTooltip>
      <ReactTooltip
        className="customTooltipTheme"
        textColor="black"
        backgroundColor="#fff"
        arrowColor="#fff"
        id="leaseOverride"
        place="bottom"
        effect="solid"
        disable={shouldOverrideOrUndoLease}>
        {isMarkedAsIsOrIsCommonArea && !shouldOverrideOrUndoLease && (
          <>
            <p>This option is disabled either because</p>
            <p>the selected space is marked As-Is, in which case </p>
            <p>please first Remove As-Is to proceed with Lease Override,</p>
            <p>or the selected space is a Common Area (CA)</p>
            <p>in which case the Lease Override is not applicable.</p>
          </>
        )}
        {!isMarkedAsIsOrIsCommonArea && !shouldOverrideOrUndoLease && (
          <>
            <p>This option is not available when</p>
            <p>no space is selected or</p>
            <p>more than one space is selected.</p>
          </>
        )}
      </ReactTooltip>
      <ReactTooltip
        className="customTooltipTheme"
        textColor="black"
        backgroundColor="#fff"
        arrowColor="#fff"
        id="groupUnits"
        place="bottom"
        effect="solid"
        disable={shouldGroupOrUngroupUnits}>
        <p>This option is not available either</p>
        <p>because no space is selected or when spaces</p>
        <p>belonging to different categories are selected</p>
        <p>To enable this option, please select spaces</p>
        <p>that belong either to Group or Ungrouped category</p>
      </ReactTooltip>
      <ReactTooltip
        className="customTooltipTheme"
        textColor="black"
        backgroundColor="#fff"
        arrowColor="#fff"
        id="enableServices"
        place="bottom"
        effect="solid"
        disable={shouldMarkOrUnMarkEnableServices}>
        {unMarkEnableServices && anyServiceStatusIsApproved ? (
          <p>Approved space cannot be Unmark Enable Services.</p>
        ) : (
          <>
            <p>This option is not available either</p>
            <p>because no space is selected or when spaces</p>
            <p>belonging to different categories are selected</p>
          </>
        )}
      </ReactTooltip>
      <ReactTooltip
        className="customTooltipTheme"
        textColor="black"
        backgroundColor="#fff"
        arrowColor="#fff"
        id="inHouse"
        place="bottom"
        effect="solid"
        disable={shouldMarkOrUnMarkInHouse}>
        <>
          <p>This option is not available either</p>
          <p>because no space is selected or when spaces</p>
          <p>belonging to different statuses are selected</p>
        </>
      </ReactTooltip>
    </div>
  )
}

export default ShowMoreItemsButton
