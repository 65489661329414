import carpet from './icons/carpet.svg'
import carpetReplace from './icons/carpet-replace.svg'
import clean from './icons/clean.svg'
import flooring from './icons/flooring.svg'
import paint from './icons/paint-2.svg'
import emptyPage from './icons/emptypage@2x.svg'
import edit from './icons/edit.svg'
import trash from './icons/trash.svg'
import addBlack from './icons/add-black.svg'

export const icons = {
  carpet,
  carpetReplace,
  clean,
  flooring,
  paint,
  emptyPage,
  edit,
  trash,
  addBlack
}
