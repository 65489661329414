import React from 'react'
import { icons } from './icons'

const ascStyles = {
  display: 'block',
  marginTop: '3px',
  cursor: 'pointer'
}

const descStyles = {
  display: 'block',
  marginTop: '-3px',
  cursor: 'pointer'
}

const Sorting = ({ data, applySorting, type }) => {
  return (
    <div
      style={{
        verticalAlign: 'middle',
        display: 'inline-block',
        marginLeft: 5,
        marginTop: -7
      }}>
      {data?.key === type ? (
        <>
          <img
            style={ascStyles}
            src={!data.ascending ? icons.caretUpOn : icons.caretUpOff}
            onClick={() => applySorting(type, !data.ascending)}
            alt="Sort in ascending order"
          />
          <img
            style={descStyles}
            src={data.ascending ? icons.caretDownOn : icons.caretDownOff}
            onClick={() => applySorting(type, !data.ascending)}
            alt="Sort in descending order"
          />
        </>
      ) : (
        <>
          <img
            style={ascStyles}
            src={icons.caretUpOff}
            onClick={() => applySorting(type, !data.ascending)}
            alt="Sort in ascending order"
          />
          <img
            style={descStyles}
            src={icons.caretDownOff}
            onClick={() => applySorting(type, !data.ascending)}
            alt="Sort in descending order"
          />
        </>
      )}
    </div>
  )
}

export default Sorting
