import moment from 'moment'
import Service from '../../service'
import { formatDateString } from '../../utils'
import { capitalize, getUTCToLocalDateTime } from '../../utils/helperFunctions'
import {
  DOWNLOAD_INSPECTIONS_LIST_REQUESTED,
  DOWNLOAD_INSPECTIONS_LIST_SUCCESS,
  DOWNLOAD_INSPECTIONS_LIST_FAIL,
  DOWNLOAD_INSPECTIONS_LIST_RESET,
  GET_DOWNLOAD_STATE_REQUESTED,
  GET_DOWNLOAD_STATE_SUCCESS,
  GET_DOWNLOAD_STATE_FAIL,
  VIEW_ALL_DOWNLOADS_REQUESTED,
  VIEW_ALL_DOWNLOADS_SUCCESS,
  VIEW_ALL_DOWNLOADS_FAIL
} from './types'

const downloadInspectionsListFail = dispatch => {
  dispatch({
    type: DOWNLOAD_INSPECTIONS_LIST_FAIL
  })
}

/**
 * Gets Download Inspections list
 * @redux
 * @reduxActionCreator DOWNLOAD_INSPECTIONS_LIST_REQUESTED, DOWNLOAD_INSPECTIONS_LIST_SUCCESS, DOWNLOAD_INSPECTIONS_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} onSuccess - success callback
 * @param {Object} onFail - Fail callback
 */

export const getDownloadInspectionsList = (
  pdbid,
  context,
  onSuccess,
  onFail
) => {
  return dispatch => {
    dispatch({
      type: DOWNLOAD_INSPECTIONS_LIST_REQUESTED
    })
    Service.getDownloadInspectionsList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const list = await res.json()
          const filterDeletedList = (list || []).filter(
            l_item => l_item?.status !== 'deleted'
          )

          dispatch({
            type: DOWNLOAD_INSPECTIONS_LIST_SUCCESS,
            list: _listAdapter(filterDeletedList)
          })

          onSuccess && onSuccess()

          return
        }

        downloadInspectionsListFail(dispatch)
        onFail && onFail()

        return
      })
      .catch(() => {
        downloadInspectionsListFail(dispatch)
        onFail && onFail()
      })
  }
}

/**
 * Gets Download Inspections list
 * @redux
 * @reduxActionCreator GET_DOWNLOAD_STATE_REQUESTED, GET_DOWNLOAD_STATE_SUCCESS, GET_DOWNLOAD_STATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */

export const getDownloadState = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: GET_DOWNLOAD_STATE_REQUESTED
    })
    Service.getDownloadState(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const list = await res.json()
          const state =
            Array.isArray(list) && list.length ? list[0].new_job : 'F'

          return dispatch({
            type: GET_DOWNLOAD_STATE_SUCCESS,
            downloadState: state === 'T'
          })
        }

        return dispatch({
          type: GET_DOWNLOAD_STATE_FAIL
        })
      })
      .catch(() => {
        return dispatch({
          type: GET_DOWNLOAD_STATE_FAIL
        })
      })
  }
}

/**
 * Gets Download Inspections list
 * @redux
 * @reduxActionCreator VIEW_ALL_DOWNLOADS_REQUESTED, VIEW_ALL_DOWNLOADS_SUCCESS, VIEW_ALL_DOWNLOADS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the payload object for the request
 */

export const viewAllDownloads = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: VIEW_ALL_DOWNLOADS_REQUESTED
    })
    Service.viewAllDownloads(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          return dispatch({
            type: VIEW_ALL_DOWNLOADS_SUCCESS,
            downloadState: false
          })
        }

        return dispatch({
          type: VIEW_ALL_DOWNLOADS_FAIL
        })
      })
      .catch(() => {
        return dispatch({
          type: VIEW_ALL_DOWNLOADS_FAIL
        })
      })
  }
}

export const resetDownloadInspectionsList = () => {
  return {
    type: DOWNLOAD_INSPECTIONS_LIST_RESET
  }
}

const _listAdapter = list => {
  return (list || []).map(download => ({
    ...download,
    name: getUTCToLocalDateTime(download.name),
    status: !download.status ? 'In Progress' : capitalize(download.status)
  }))
}
