import React, { useEffect, useState } from 'react'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import HeadingTemplate from '../../components/forms-helper/HeadingTemplate'
import PermissionRuleFieldTemplateViewOnly from '../../components/forms-helper/PermissionRuleFieldTemplateViewOnly'
import ActiveUserToggle from './components/ActiveUserToggle'
import ErrorTemplateNoPadding from '../../components/forms-helper/ErrorTemplateNoPadding'
import GeneralFieldNoPadding from '../../components/forms-helper/GeneralFieldNoPadding'
import EditIcon from './icons/edit.svg'
import AddIcon from './icons/add.svg'
import { closeFilled } from 'ui/icons'
import { Link, useParams } from 'react-router-dom'
import DataTableMenu from '../../components/data-table-menu'
import {
  getProject,
  getProperty,
  getRole,
  groupByAndContructData
} from './helper-methods'
import './index.scss'
import DeleteUserModal from './components/DeleteUserModal'
import BigLoading from '../../components/big-loading'
import { capitalize } from '../../utils/helperFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { usePrevious } from '../../helpers/hooks'
import AppAccessToggle from './components/AppAccessToggle'
import PasswordReset from '../../components/forms-helper/PasswordReset'
import '../../styles/common.scss'

const ViewUser = props => {
  const {
    history,
    newUserDetail,
    user,
    setupUsers,
    clearUser,
    updateUserAccountStatus,
    openModal,
    setModalContent,
    closeModal,
    allPropertiesList,
    allRolesList,
    createOrEditUser
  } = props || {}

  const { email: currentUserLoginEmail, user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [loginAccess, setLoginAccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isUserStatusLoading, setIsUserStatusLoading] = useState(false)
  const [
    errorMessageUserAccountStatus,
    setErrorMessageUserAccountStatus
  ] = useState('')
  const [errorMessageUserAppAccess, setErrorMessageUserAppAccess] = useState('')
  const [isUserAppAccessLoading, setIsUserAppAccessLoading] = useState(false)
  const [appAccess, setAppAccess] = useState(false)

  const { id } = useParams()

  // Redux
  const { userDetail, propertiesList: ALLPROPERTIES, rolesList: ALLROLES } =
    setupUsers || {}
  const { hasMadeInitialRequest, isRequesting, data: userDetailData } =
    userDetail || {}

  const getPermissionDataFromId = ruleData => {
    const fillDataById = (ruleData || []).map(p_data => {
      const {
        property_id,
        role_id,
        permission_rule_id,
        login_access,
        action,
        status,
        internal_permission_rule_id
      } = p_data || {}

      const uniqueRuleCardId = `${internal_permission_rule_id}-${login_access}-${role_id}`

      return {
        properties: getProperty(property_id, ALLPROPERTIES),
        projects: getProject(property_id, ALLPROPERTIES),
        role: getRole(role_id, ALLROLES),
        permission_rule_id,
        login_access,
        action,
        status,
        uniqueRuleCardId,
        internal_permission_rule_id
      }
    })
    return groupByAndContructData(fillDataById)
  }

  const onError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onSuccess = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    allPropertiesList(pdbid)
    allRolesList(pdbid)
    newUserDetail(pdbid, id, onSuccess, onError)
    return () => {
      clearUser()
    }
  }, [])

  const {
    name,
    email,
    title,
    type,
    phone,
    alt_phone,
    notes,
    is_inactive,
    permission_rules,
    app_access,
    email_verified,
    auth_id
  } = userDetailData || {}

  const prevLoginAccess = usePrevious(is_inactive === null ? false : true)
  const prevAppAccess = usePrevious(app_access ? true : false)

  useEffect(() => {
    if ((permission_rules || []).length) {
      const data = getPermissionDataFromId(permission_rules)
      setSelectedPermissions(data)
    }
  }, [permission_rules, ALLPROPERTIES, ALLROLES])

  useEffect(() => {
    setLoginAccess(is_inactive === null ? false : true)
  }, [is_inactive])

  useEffect(() => {
    setAppAccess(app_access ? true : false)
  }, [app_access])

  if (
    (hasMadeInitialRequest && !isRequesting && (ALLPROPERTIES || []).length) ||
    isLoading
  ) {
    return <BigLoading />
  }

  const onUserAccountStatusError = () => {
    setIsUserStatusLoading(false)
    setErrorMessageUserAccountStatus(
      'Something went wrong. Please try again later!!!'
    )
    setLoginAccess(prevLoginAccess)
    setAppAccess(prevAppAccess)
  }

  const onUserAccountStatusSuccess = () => {
    setIsUserStatusLoading(false)
    setErrorMessageUserAccountStatus('')
  }

  const onHandleUserAccountStatus = state => {
    setIsUserStatusLoading(true)
    setLoginAccess(state)
    if (!state) {
      setAppAccess(false)
    }
    // if (!state) {
    //   setAppAccess(false)
    // }
    const body = {
      items: [
        {
          user_id: id,
          auth_id,
          is_inactive: state ? 'false' : null
        }
      ]
    }
    updateUserAccountStatus(
      pdbid,
      body,
      onUserAccountStatusSuccess,
      onUserAccountStatusError
    )

    // const getAppAccessValue = appAccess ? null : false
    // const body = {
    //   ...userDetailData,
    //   is_inactive: state ? 'false' : null,
    //   app_access: state ? getAppAccessValue : false
    // }
    // createOrEditUser(
    //   pdbid,
    //   body,
    //   onUserAccountStatusSuccess,
    //   onUserAccountStatusError
    // )
  }

  const onSuccessAppAccess = () => {
    setIsUserAppAccessLoading(false)
    setErrorMessageUserAppAccess('')
  }

  const onErrorAppAccess = () => {
    setIsUserAppAccessLoading(false)
    setErrorMessageUserAppAccess(
      'Something went wrong. Please try again later!!!'
    )
    setAppAccess(prevAppAccess)
  }

  const onHandleUserAppStatus = state => {
    setIsUserAppAccessLoading(true)
    setAppAccess(state)
    const body = {
      ...userDetailData,
      app_access: state ? null : false,
      is_inactive: loginAccess ? 'false' : null
    }
    createOrEditUser(pdbid, body, onSuccessAppAccess, onErrorAppAccess)
  }

  if (errorMessage) {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>{errorMessage}</p>
      </div>
    )
  }

  const onHandleDeleteUserModal = () => {
    setModalContent(() => (
      <DeleteUserModal closeModal={closeModal} userId={id} {...props} />
    ))
    openModal({ width: '480px' })
  }

  return (
    <div className="columns is-multiline view-user">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <HeadingTemplate>
            <div className="label-heading column mb-8">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong className="is-size-5">Personal Information</strong>
                {type !== 'Vendor' ? (
                  <div className="list-container">
                    <DataTableMenu
                      options={[
                        {
                          caption: 'Edit User',
                          icon: EditIcon,
                          action: () => history.push(`/setup/users/edit/${id}`)
                        }
                      ]}
                      row={{
                        id
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </HeadingTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Name">
              <label>{capitalize(name || '')}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Email Address">
              <label>{email}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Title">
              <label>{capitalize(title || '')}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Type">
              <label>{capitalize(type || '')}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Phone">
              <label>{phone}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Alt Phone">
              <label>{alt_phone}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate isDisabledVcentered>
            <GeneralFieldNoPadding fieldLabel="Notes">
              <div className="note-container">
                <label>{notes}</label>
              </div>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <div className="label-heading column pb-0 mb-8 mt-8">
              <strong className="is-size-5">Permission Rules</strong>
            </div>
          </FieldTemplate>
          <div className="columns is-vcentered mb-0 ">
            <div className="column is-full is-10-fullhd is-offset-1-fullhd">
              <div className="permission-rule-container">
                {(selectedPermissions || []).map((userPerm, i) => {
                  return (
                    <div
                      className="user-permission-container"
                      key={`${userPerm?.id} - ${i}`}>
                      <div className="columns is-desktop is-mobile mb-0">
                        <div className="column permission-label m-0">
                          <strong>{`Permission Rule ${i + 1}:`}</strong>
                        </div>
                      </div>
                      <PermissionRuleFieldTemplateViewOnly>
                        <GeneralFieldNoPadding
                          fieldLabel="Properties"
                          labelClassName="permission-label-text">
                          {(userPerm?.properties || []).map((d, i) => (
                            <button className="pill" key={i}>
                              {d?.property_name}
                            </button>
                          ))}
                        </GeneralFieldNoPadding>
                      </PermissionRuleFieldTemplateViewOnly>
                      <PermissionRuleFieldTemplateViewOnly>
                        <GeneralFieldNoPadding
                          fieldLabel="Projects"
                          labelClassName="permission-label-text">
                          {(userPerm?.projects || []).map((d, i) => (
                            <button className="pill" key={i}>
                              {d.project_name}
                            </button>
                          ))}
                        </GeneralFieldNoPadding>
                      </PermissionRuleFieldTemplateViewOnly>
                      <PermissionRuleFieldTemplateViewOnly>
                        <GeneralFieldNoPadding
                          fieldLabel="Enable Login"
                          labelClassName="permission-label-text">
                          <label
                            className="permission-label-text"
                            style={{ paddingLeft: '0.5em' }}>
                            {userPerm?.login_access === null ? 'ON' : 'OFF'}
                          </label>
                        </GeneralFieldNoPadding>
                      </PermissionRuleFieldTemplateViewOnly>
                      <PermissionRuleFieldTemplateViewOnly>
                        <GeneralFieldNoPadding
                          fieldLabel="Role"
                          labelClassName="permission-label-text">
                          <label
                            className="permission-label-text"
                            style={{ paddingLeft: '0.5em' }}>
                            {(userPerm?.role || [])
                              .map(d => d?.name)
                              .join(', ')}
                          </label>
                        </GeneralFieldNoPadding>
                      </PermissionRuleFieldTemplateViewOnly>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div>
            {type !== 'Vendor' ? (
              <div className="button-wrapper">
                <button
                  className="button main-button is-primary"
                  onClick={() => history.push(`/setup/users/edit/${id}`)}>
                  Edit
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className="box is-shadowless has-transition-opacity">
          <Link to="/setup/users/new">
            <img
              src={AddIcon}
              className="is-inline"
              style={{ marginTop: '-1px' }}
              alt="Add New User"
            />
            <p className="is-inline m-l-sm">Add User</p>
          </Link>
        </div> */}
      </div>
      <div className="column is-half animated">
        {email !== currentUserLoginEmail && (
          <>
            <div className="box is-shadowless has-transition-opacity">
              {errorMessageUserAccountStatus !== '' ? (
                <ErrorTemplateNoPadding>
                  <p>Something went wrong. Please try again later!!!</p>
                  <button
                    className="error-button"
                    onClick={() => setErrorMessageUserAccountStatus('')}>
                    <img src={closeFilled} alt="" />
                  </button>
                </ErrorTemplateNoPadding>
              ) : null}
              <div className="columns is-vcentered mb-0 secondary-card-space">
                <div className="column is-full">
                  <div
                    className="columns is-desktop is-mobile"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      marginTop: '-21px'
                    }}>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <ActiveUserToggle
                        isActive={loginAccess}
                        prevIsActive={prevLoginAccess}
                        onChange={state => {
                          setErrorMessageUserAccountStatus('')
                          onHandleUserAccountStatus(state)
                        }}
                      />
                      {isUserStatusLoading ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="m-l-sm loading-icon"
                          size="lg"
                        />
                      ) : null}
                    </div>
                    {userDetailData?.used !== 'T' && (
                      <button
                        className="button main-button is-danger"
                        disabled={isUserStatusLoading}
                        onClick={() => onHandleDeleteUserModal()}>
                        Delete User
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* {type !== 'Vendor' ? (
              <div className="box is-shadowless has-transition-opacity">
                {errorMessageUserAppAccess !== '' ? (
                  <ErrorTemplateNoPadding>
                    <p>Something went wrong. Please try again later!!!</p>
                    <button
                      className="error-button"
                      onClick={() => setErrorMessageUserAppAccess('')}>
                      <img src={closeFilled} alt="" />
                    </button>
                  </ErrorTemplateNoPadding>
                ) : null}
                <div className="columns is-vcentered mb-0 secondary-card-space">
                  <div className="column is-full">
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                        marginLeft: '-9px'
                      }}>
                      <AppAccessToggle
                        isActive={appAccess}
                        prevIsActive={prevAppAccess}
                        isDisabled={!loginAccess}
                        onChange={state => {
                          setErrorMessageUserAppAccess('')
                          onHandleUserAppStatus(state)
                        }}
                      />
                      {isUserAppAccessLoading ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="m-l-sm loading-icon"
                          size="lg"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
          </>
        )}

        {email_verified ? <PasswordReset /> : null}
      </div>
    </div>
  )
}

export default ViewUser
