import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { closeModal } from 'modules/modal'
import { Modal } from 'ui'
// import { deleteInvoiceLine } from 'modules/invoices'
// import { useAppContext } from 'hooks'

const ConfirmDeleteLine = ({ deleteAction }) => {
  const dispatch = useDispatch()
  // const { user: { pdbid }, context } = useAppContext()
  // const invoice = useSelector(state => state.invoices?.details)
  const deleteLineHandler = () => {
    dispatch(closeModal())
    deleteAction()
  }

  return (
    <Modal
      title="Are you sure you want to delete this line item?"
      okText="Yes, Delete"
      okType="danger"
      titleSize="4"
      cancelText="No, Cancel"
      cancelType="third"
      // onConfirm={() => dispatch(deleteInvoiceLine(pdbid, context, invoice, item))}
      onConfirm={deleteLineHandler}
    >
      {/* <div className="columns">
        <div className="column has-text-centered is-italic">
          Note: This action cannot be undone.
        </div>
      </div> */}
    </Modal>
  )
}

export default ConfirmDeleteLine
