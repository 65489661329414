import React, { useLayoutEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import RestrictedElement from '../../containers/restricted-element'
import InvoiceItem from './invoice-item'
import { addButton } from 'icons'
import './index.scss'

const InvoiceItems = ({
  invoice,
  addItem,
  deleteItem,
  updateItem,
  current,
  setCurrent
}) => {
  const { po_lines: items } = current
  const isApproved = invoice.status?.toLowerCase() === 'approved'
  const simpleBarRef = useRef()
  const [itemAdded, setItemAdded] = useState(false)

  useLayoutEffect(() => {
    if (simpleBarRef.current && itemAdded) {
      const rowHeight = 60
      simpleBarRef.current.getScrollElement().scrollTop =
        simpleBarRef.current.getScrollElement().scrollHeight + rowHeight
      setItemAdded(false)
    }
  }, [current])

  const addItemHandler = () => {
    setItemAdded(true)
    addItem()
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: 500, width: '100%' }} ref={simpleBarRef}>
        <table className="table is-fullwidth invoice-items__table">
          <thead>
            <tr>
              <th>Description</th>
              <th className="invoice-items__short-column has-text-centered">
                Rate
              </th>
              <th className="invoice-items__short-column has-text-centered">
                QTY
              </th>
              <th className="invoice-items__short-column has-text-right">
                Amount
              </th>
              <th className="invoice-items__shorter-column has-text-centered" />
            </tr>
          </thead>
          <tbody>
            {items &&
              items
                .filter(item => !item?.deleted && !!item.po_line_id)
                .map((item, i) => (
                  <InvoiceItem
                    key={i}
                    item={item}
                    isNew={item.isNew}
                    onDescriptionChanged={data =>
                      updateItem(item.po_line_id, 'item', data)
                    }
                    onRateChanged={data =>
                      updateItem(item.po_line_id, 'rate', data)
                    }
                    onQuantityChanged={data => {
                      updateItem(item.po_line_id, 'quantity', data)
                    }}
                    onDelete={() => deleteItem(item.po_line_id)}
                    editing={isApproved ? false : invoice.editing}
                    currentInvoice={current}
                    setCurrentInvoice={setCurrent}
                  />
                ))}
          </tbody>
        </table>
      </SimpleBar>
      {invoice.editing && !isApproved && (
        <RestrictedElement>
          <div className="invoice-items__add-panel m-t-md">
            <a
              href="#!"
              onClick={addItemHandler}
              className="invoice-items__add">
              <img
                src={addButton}
                className="setup-team-add-button"
                alt="Add Invoice Line"
              />
              Add Line Item
            </a>
          </div>
        </RestrictedElement>
      )}
    </>
  )
}

export default InvoiceItems
