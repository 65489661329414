import React, { useState, useRef, useMemo } from 'react'
import { headerData } from './header'
import styled from 'styled-components'
import moment from 'moment'
import { formatISODateString } from '../../utils'
import { exportFile } from 'ui/icons'
import { useHistory } from 'react-router'
import { useNewInvoice, useAppContext } from 'hooks'
import { useSelector, useDispatch } from 'react-redux'
import InvoiceTable from './components/InvoiceTable'
import { Page } from 'ui'
import { getPurchaseDetails } from '../../modules/purchase-orders'
import Paginator from '../../components/react-table-pagination'

import './index.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;

  input {
    margin-top:8px !important;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E9F2;
    margin: 0px auto;
    outline: none;
    font-size: 10px
  }
  select {
    width: 100%
    margin-top:8px !important;
  }
  .purchase-orders-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;

    .tr {
      position: relative;
     
      :first-child {
        .th {
          display:none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px
      :first-child {
        border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      // :last-child {
      //   border-right: 1px solid #e5e9f2;
      // }
    }
    
  }
`
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // To remove empty values and - from filter
  options = (options || []).filter(e => e && e !== '-')

  return (
    <select
      value={filterValue || 'All'}
      onChange={e => {
        if (e.target.value === 'all') {
          setFilter(undefined)
        } else {
          setFilter(e.target.value || '')
        }
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="all">All</option>
      {(options || []).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const InvoicesList = ({ vendors }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const { user, context } = useAppContext()

  const { list, listHasRequested, listIsRequesting } = useSelector(
    state => state.invoices
  )

  const tableRef = useRef()

  useMemo(() => {
    list.forEach(item => {
      item.po_date = formatISODateString(item.po_date)
      item.due_date = formatISODateString(item.due_date)
      item.status = item.status.charAt(0).toUpperCase() + item.status.slice(1)
    })
  }, [list])

  const editInvoice = (invoice_id, vendorId) => {
    dispatch(getPurchaseDetails(user?.pdbid, context, invoice_id, vendorId))
  }

  const tableColumns = useMemo(
    () => headerData({ SelectColumnFilter, editInvoice }),
    []
  )

  useNewInvoice(newInvoice =>
    history.push(
      `purchase-orders/${newInvoice?.po_id || ''}/${newInvoice?.vendor_id ||
        ''}`
    )
  )

  const exportCSV = () => {
    const { properties, projects } = user || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = (properties || []).find(p => p?.id === contextProperty)
    const project = (projects || []).find(p => p?.id === contextProject)
    const names = {
      property: property?.name?.replace(/ /g, '_'),
      project: project?.name?.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const displayedRows = tableRef.current?.getRows() || []
    let rows = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : displayedRows
    const data = rows.map(invoice => {
      return {
        Vendor: `"${invoice.vendor_name}"`,
        'Invoice Number': invoice.vendor_po_number,
        'Invoice Date': invoice.po_date,
        'Due Date': invoice.due_date,
        'P.O. Number': invoice.po_number,
        'Total Amount ($)': invoice.total_amount,
        'Amount Paid ($)': invoice.amount_paid,
        Status: invoice.status
      }
    })

    const keys = Object.keys(data[0] || {})
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    const filename = [names.project, names.property, 'invoices', datetime].join(
      '-'
    )
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  return (
    <Page
      loading={!listHasRequested || listIsRequesting}
      isBreadcrumbClassApplied={false}>
      <div className="purchase-orders-list-container">
        <div className="data-table-wrapper">
          <div className="data-table-header">
            <div className="header">
              <h2 className="heading-5">Purchase Orders</h2>
            </div>
            <div className="action-button">
              <span>
                {(checkIsFilterApplied || []).length !== 0 && (
                  <button
                    onClick={() => {
                      onResetFilter()
                    }}
                    className="button is-secondary">
                    Clear All Filter(s)
                  </button>
                )}
              </span>

              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Data" />
                Export
              </button>
            </div>
          </div>

          <Styles>
            <InvoiceTable
              ref={tableRef}
              columns={tableColumns}
              data={list}
              onRowSelectStateChange={setSelectedRows}
              setIsFilterApplied={setIsFilterApplied}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Styles>
          <div className="table-pagination">
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default InvoicesList
