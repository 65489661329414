import React from 'react'
import _ from 'lodash'

const TableCell = ({
  row,
  fieldKey,
  tooltipData = null,
  isLast = false,
  pivot = null,
  pivotConfig,
  methods,
  data,
  header,
  emptyValue,
  prefix = '',
  keyValue = '',
  iconClicked,
  tooltip,
  tooltipSizeAuto,
  openTooltip,
  setTooltipContent,
  tooltipTemplate,
  closeTooltip
}) => {
  const getTooltip = (width, height, value, target, isLast = false) => {
    const rect = target.getBoundingClientRect()
    const onTop = rect.top - height - 10
    const onBottom = rect.top + rect.height + 10
    if (!tooltip.isActive)
      openTooltip(
        width,
        height,
        isLast ? onTop : onBottom,
        tooltipSizeAuto ? rect.left : rect.left - width / 2 + rect.width / 2,
        target,
        0,
        null,
        isLast
      )
    setTooltipContent(tooltipTemplate(value))
  }

  const digUpValue = (d, arr) => {
    for (let i = 0; i < arr.length; i += 1) {
      d = d[arr[i]]
      if (d === null || typeof d === 'undefined') return ''
    }
    return d
  }

  const detectIsImage = str => {
    if (typeof str !== 'string') return str
    return str.match(/\.(jpeg|jpg|png|svg)$/) !== null
  }

  const getValue = (row, key) => {
    if (!key) return ''
    let value = row?.key ?? ''
    if (key.search('.') !== -1) {
      const split = key.split('.')
      value = digUpValue(row, split)
      if (value === '' && emptyValue) value = emptyValue
      const definition = header.find(h => h.key === key)
      if (definition && definition.format) {
        value = definition.format(value)
      }
    }
    return value
  }

  const columns = pivot && prefix !== '' ? pivot?.columns ?? [] : header
  const definition = columns.find(h => h.key === fieldKey)

  if (definition && definition.template) {
    if (!pivot || prefix === '') {
      return definition.template(row, methods, isLast)
    } else if (pivotConfig) {
      const mainRow = data.find(d => d[pivotConfig.idField] === keyValue)
      return definition.template(mainRow, prefix, methods, isLast)
    }
  }

  const value = getValue(row, fieldKey)
  const isImage = detectIsImage(value)

  const renderContentCell = () => {
    const dataItem = _.get(row, definition.root)

    if (dataItem?.multiple) {
      return dataItem?.data?.map(item => {
        return (
          <img
            key={item.unitspace}
            src={item.icon}
            alt="status icon"
            className="is-pointer"
            onClick={() => {
              iconClicked(row, fieldKey, item)
            }}
            onMouseOver={e => {
              getTooltip(tooltipSizeAuto ? '' : 350, tooltipSizeAuto ? '' : 120, item.tooltip, e.target, isLast)
            }}
          />
        )
      })
    }

    if (typeof tooltipData !== 'undefined' && tooltipData !== null) {
      if (
        typeof tooltip === 'object' &&
        typeof openTooltip === 'function' &&
        typeof closeTooltip === 'function' &&
        typeof setTooltipContent === 'function' &&
        typeof tooltipTemplate === 'function'
      ) {
        const ttValue = getValue(row, tooltipData)
  
        if (isImage) {
          return (
            <img
              src={value}
              alt="status icon"
              className="is-pointer"
              onClick={() => {
                iconClicked(row, fieldKey)
              }}
              onMouseOver={e => {
                getTooltip(tooltipSizeAuto ? '' : 350, tooltipSizeAuto ? '' : 120, ttValue, e.target, isLast)
              }}
            />
          )
        }
  
        return (
          <span
            onMouseOver={e => {
              getTooltip(tooltipSizeAuto ? '' : 350, tooltipSizeAuto ? '' : 120, ttValue, e.target, isLast)
            }}>
            {value}
          </span>
        )
      } else if (typeof tooltipData === 'string') {
        const tt = getValue(row, tooltipData)
        
        if (isImage) {
          return (
            <img
              src={value}
              alt="status icon"
              className={[
                `has-tooltip-${isLast ? 'left' : 'bottom'}`,
                'is-pointer'
              ].join()}
              onClick={() => {
                iconClicked(row, fieldKey)
              }}
              data-tooltip={tt}
            />
          )
        }
  
        return (
          <span
            className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
            data-tooltip={tt}>
            {value}
          </span>
        )
      }
    }
  
    return <span>{value}</span>
  }

  return renderContentCell()
}

export default TableCell
