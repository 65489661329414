import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import RestrictedElement from '../../containers/restricted-element'
import { animate } from '../../helpers'
import { icons } from './icons'
import PermissionGate from '../../components/permission-gate'

const SetupBudgetsTitle = ({
  context,
  user,
  isEditing,
  setupBudgets,
  setupOneBudgetCreate,
  setupOneBudgetUpdate,
  toggleEditing,
  checkIfActionAllowed
}) => {
  useEffect(() => {
    focus()
  }, [])

  const focus = () => {
    if (isEditing) document.getElementById('title').focus()
  }

  const getTitleEditState = () => {
    const { data } = setupBudgets.budget
    const { pdbid } = user.user_metadata
    const isNew = Object.keys(data) > 0
    return (
      <div className="columns is-multiline">
        <div className="column is-half is-offset-one-quarter">
          <input
            type="text"
            defaultValue={data.budget_name}
            className="input has-text-centered is-size-4 is-paddingless has-background-light"
            style={{ height: '2.25rem' }}
            id="title"
            placeholder="Name goes here..."
          />
        </div>
        <div className="column is-full has-text-centered">
          <button
            type="button"
            className="button is-secondary m-r-md"
            onClick={() => {
              toggleEditing(false)
            }}
            style={{
              width: '127px'
            }}
            disabled={isNew}>
            Cancel
          </button>
          <button
            type="button"
            className="button is-primary"
            style={{
              width: '127px'
            }}
            onClick={e => {
              const { value } = document.getElementById('title')
              const body = { budget_name: value }
              if (typeof value === 'string' && value.length > 0) {
                if (Object.keys(data).length < 1)
                  setupOneBudgetCreate(pdbid, context, body)
                else setupOneBudgetUpdate(pdbid, context, data.budget_id, body)
                toggleEditing(false)
              } else {
                const container = document.getElementById(
                  'budget-title-container'
                )
                animate(container, 'pulse')
                focus()
              }
            }}>
            Submit
          </button>
        </div>
      </div>
    )
  }

  const getTitleViewState = () => {
    const { budget_name } = setupBudgets.budget.data
    return <p className="is-size-4 has-text-centered">{budget_name}</p>
  }

  return (
    <div
      className="box is-shadowless"
      id="budget-title-container"
      style={{ minHeight: '76px' }}>
      {checkIfActionAllowed ? (
        <div className="pencil-icon-container">
          <img
            src={icons.edit}
            className={`is-pointer pencil-icon ${isEditing ? 'is-hidden' : ''}`}
            onClick={() => {
              toggleEditing(true)
            }}
            alt="Edit Title"
          />
        </div>
      ) : null}

      <div className="columns is-vcentered">
        <div className="column">
          {!isEditing ? getTitleViewState() : getTitleEditState()}
        </div>
      </div>
    </div>
  )
}

SetupBudgetsTitle.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupBudgets: PropTypes.object,
  isEditing: PropTypes.bool,
  toggleEditing: PropTypes.func,
  setupOneBudgetCreate: PropTypes.func,
  setupOneBudgetUpdate: PropTypes.func
}

export default SetupBudgetsTitle
