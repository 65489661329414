import React from 'react'
import InspectionCell from './components/InspectionCell'
import { Link } from 'react-router-dom'
import DataTableMenu from '../../components/data-table-menu'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import { capitalize } from '../../utils/helperFunctions'

export const headerData = ({ getRowOption, hasPermission }) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Building',
        accessor: 'building',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 90,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        textAlign: 'center',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 80,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: 'Inspection Name',
        accessor: 'type',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        flexGrow: 1,
        //minWidth: 180,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { inspection_id, type } = original || {}
          return (
            <div className="cell-item">
              <Link
                className="link"
                to={{
                  pathname: `/inspections/damageinspection/${inspection_id}`
                }}>
                {`${capitalize(type || '')} Inspection`}
              </Link>
            </div>
          )
        }
      },
      {
        Header: 'Inspection Date',
        accessor: 'inspection_date',
        textAlign: 'left',
        width: 140,
        Cell: row => {
          const { cell, flatRows } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { inspection_date } = original || {}
          return (
            <div
              className="cell-item"
              style={{ paddingLeft: flatRows?.length > 8 && '15px' }}>
              {inspection_date}
            </div>
          )
        }
      },
      {
        Header: 'Damage Status',
        accessor: 'status',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 140,
        Cell: row => {
          return <InspectionCell row={row} />
        }
      },
      {
        Header: '',
        accessor: 'inspection_id1',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 40,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original, index } = currentRow || {}
          const currentOptions = getRowOption(original, index)
          return (
            <div className="menu-item">
              {hasPermission && (
                <DataTableMenu options={currentOptions || []} row={original} />
              )}
            </div>
          )
        }
      }
    ]
  }
]
