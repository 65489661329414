import React from 'react'
import { Link } from 'react-router-dom'

const NameColumn = ({ row }) => {
  const { cell } = row || {}
  const { value, row: currentRow } = cell || {}
  const { original } = currentRow || {}
  const { template_id } = original || {}

  return (
    <div className="cell-item" title={value}>
      <Link
        className="link"
        to={`/inspections-setup/company-templates/manage?template=${template_id}`}>
        {value}
      </Link>
    </div>
  )
}

export default NameColumn
