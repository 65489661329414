import { icons } from './icons'

export const serviceIcon = [
  {
    id: 'Punch',
    icon: icons.flooring,
    caption: 'Punch'
  },
  {
    id: 'Paint',
    icon: icons.paint,
    caption: 'Paint'
  },
  {
    id: 'Cleaning',
    icon: icons.clean,
    caption: 'Cleaning'
  },
  {
    id: 'Carpet Clean',
    icon: icons.carpet,
    caption: 'Carpet Clean'
  },
  {
    id: 'Carpet Replace',
    icon: icons.carpetReplace,
    caption: 'Carpet Replace'
  },
  {
    id: 'Concrete/Vinyl Replace',
    icon: icons.flooring,
    caption: 'Concrete/Vinyl Replace'
  }
]
