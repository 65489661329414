import moment from 'moment'
import platform from 'platform'
import { isEmpty } from 'lodash'

export const checkPermission = (allowedSections, ...permissions) => {
  const allowedSectionKeys = (allowedSections || []).map(
    ({ section }) => section
  )

  return (permissions || []).some(permission =>
    (allowedSectionKeys || []).includes(permission)
  )
}

export const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  )
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

export const capitalize = textString => {
  const splittedText = (textString || '').split(/[\s,_]+/)
  return (splittedText || [])
    .map(text => text?.charAt(0)?.toUpperCase() + text?.toLowerCase()?.slice(1))
    .join(' ')
}

export const isIOS = () => {
  const agent = navigator.userAgent
  return /iPad|iPhone|iPod/.test(agent)
}

export const isAndroid = () => {
  const agent = navigator.userAgent
  return /android/i.test(agent)
}

export const isMobile = () => {
  return isIOS() || isAndroid()
}

// const isAndroid = platform.os.family === 'Android'
// const isIOS = platform.os.family === 'iOS'
// const isIPad = platform.os.family === 'iOS' && /iPad/.test(platform.name)
// const isSafariOnIPad = isIPad && platform.name === 'Safari'

// const isMobileDevice = isAndroid || isIOS

// // Check if the device is a tablet device (excluding iPads)
// const isTablet =
//   isMobileDevice &&
//   !/Mobile/.test(platform.ua) &&
//   (isIPad || (!isSafariOnIPad && /Tablet/.test(platform.ua)))

// export const isMobile = () => {
//   return isMobileDevice || isTablet || isIPad
// }

export const removeExtraWhiteSpaces = (text = '') => {
  // matches more than once space between words in the given string
  const whiteSpaceRegex = / {2,}/g

  return typeof text === 'string'
    ? text.trim().replace(whiteSpaceRegex, ' ')
    : text
}

export const getFormattedDateTime = date =>
  moment(date).isValid()
    ? moment(date).format('MM/DD/YYYY [at] hh:mm A')
    : 'N/A'

export const getFormattedDateTimeInCST = dateTime => {
  if (!moment(dateTime).isValid()) return 'N/A'

  const currentDateTimeCST = moment(dateTime)
    .tz('America/Chicago')
    .format('MM/DD/YYYY [at] hh:mm A')

  return currentDateTimeCST
}

export const getUTCToLocalDateTime = dateTimeString => {
  if (isEmpty(dateTimeString) || !moment(dateTimeString).isValid()) return 'N/A'

  const utcDate = moment.utc(dateTimeString)
  const utcISO = utcDate.toISOString()

  return moment(new Date(utcISO)).format('MM/DD/YYYY hh:mm A')
}
