import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { formatDateString } from '../../utils/index'
import './index.scss'

const defaultStatusList = {
  'not-started': 'Not Started',
  started: 'Started',
  'in-review': 'In Review',
  closed: 'Closed',
  approved: 'Approved',
  rejected: 'Rejected'
}

const getReadAbleStatus = status => {
  if (status !== null && status !== '') {
    return defaultStatusList[(status || 'not-started').toLowerCase()]
  }
  return 'Not Started'
}

export const getHeaderData = () => [
  {
    key: 'rfp_request_id',
    display: 'RFP Id',
    textAlign: 'left',
    notSortable: true,
    hidden: true
  },
  {
    key: 'form_name',
    display: 'Request Title',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
    template: row => {
      const { rfp_request_id, vendor_name, form_name, due_date } = row || {}
      const title = `${form_name || ''} - ${vendor_name || ''}`
      return (
        <Link
          className="request-title-link is-capitalized"
          to={{
            pathname: `/request/${rfp_request_id || ''}/quotation`,
            state: {
              id: rfp_request_id || '',
              title,
              dueDate: due_date || ''
            }
          }}>
          {title}
        </Link>
      )
    }
  },
  {
    key: 'property',
    display: 'Property Name',
    textAlign: 'left',
    filterable: true,
    notSortable: false
  },
  {
    key: 'due_date',
    display: 'Due Date',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
    template: row => {
      const { due_date = '' } = row || {}
      return (
        <span>
          {due_date ? formatDateString(due_date, 'MM/DD/YYYY') : 'N/A'}
        </span>
      )
    }
  },
  {
    key: 'status',
    display: 'Status',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
    template: row => {
      const { status } = row || {}
      return <span>{getReadAbleStatus(status)}</span>
    },
    getValues: data =>
      _.uniq(_.map(data || [], appr => getReadAbleStatus(appr.status))),
    evaluateMatch: (filter, element = {}) =>
      (filter || []).indexOf(getReadAbleStatus(element.status)) >= 0
  },
  {
    key: 'status_label',
    textAlign: 'left',
    notSortable: true,
    template: row => {
      const { status } = row || {}
      if (status === 'not-started' || status === null) {
        return (
          <div className="label-item">
            <p className="label-item-text">NEW</p>
          </div>
        )
      }
      return ''
    }
  }
]
