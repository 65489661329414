export const WEB_INSPECTIONS = 'WebInspections'
export const INSPECTION_SETUP = 'InspectionSetup'
export const RFP = 'RFP'
export const ELEMENTS = 'Elements'
export const TEMPLATES = 'Templates'
export const FORMS = 'Forms'
export const TURNBOARD = 'Turnboard'
export const KEYS = 'Keys'
export const WALK = 'Walk'
export const SERVICES = 'Services'
export const CHARGES = 'Charges'
export const PURCHASE_ORDERS = 'PurchaseOrders'
export const APPROVALS = 'Approvals'
export const TURN_SETUP = 'TurnSetup'
export const INSPECT = 'Inspect'
export const DASHBOARD = 'Dashboard'
export const USERS = 'Users'
export const SCHEDULER = 'Scheduler'
export const APPROVER = 'Approver'
export const PRICING = 'Pricing'
export const DAMAGES = 'Damages'
