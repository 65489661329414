import _ from 'lodash'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import moment from 'moment'
import { icons } from '../../components/data-table/icons'
import DataTable from '../../components/data-table'
import { sectionManageable } from '../../security'
import { getHeaderData } from './header'
import RestrictedElement from '../../containers/restricted-element'
import AddVendorModal from '../add-vendor-modal'
import ConfirmationModal from '../../components/confirmation-modal'
import PermissionGate from '../../components/permission-gate'
import { checkPermission } from '../../security'
import { useModals } from 'hooks'
import './index.scss'

const RequestVendorsList = props => {
  // Destructure Props
  const {
    user,
    allRequestVendorGet,
    setModalContent,
    context,
    requestVendors,
    openModal,
    closeModal,
    deleteVendor,
    checkVendorRequest
  } = props

  const dropDownOptions = [
    {
      available: () => sectionManageable('/request-vendors', user),
      icon: icons.trash,
      caption: 'Delete',
      action: row => onHandleDeleteVendor(row)
    }
  ]

  const showUserDropdownOptions = () => {
    if (checkPermission('manage-rfp-vendor', user)) {
      return dropDownOptions
    }

    return []
  }

  // use Effect
  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    allRequestVendorGet(pdbid, context)
  }, [])

  const prevContext = usePrevious(context)
  const [showModal, dismissModal] = useModals()

  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged) {
      allRequestVendorGet(pdbid, context)
    }
  }, [context])

  const onAddVendorHandler = useCallback(() => {
    setModalContent(() => (
      <AddVendorModal
        {...props}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '94%',
      additionalClasses: 'vendor-modal'
    })
  }, [setModalContent, openModal, user])

  // State

  const [textFilter, setTextFilter] = useState('')

  const tableRef = useRef()
  // Methods

  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = properties.find(p => p.id === contextProperty)
    const project = projects.find(p => p.id === contextProject)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const filterData = (tableRef?.current?.getRows() || []).map(list => {
      const { name, contact_name, email, status, simplifiedServices } =
        list || {}
      return {
        'Business Name': name || '',
        'Contact Name': contact_name || '',
        Email: `"${email}"` || '',
        'Services(s)': `"${simplifiedServices}"` || '',
        Status: status || ''
      }
    })

    const data = filterData

    const keys = Object.keys(data[0] || {})
    const csvData = [keys]
      .concat(filterData.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-vendors-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getFilteredVendor = () => {
    const { allVendor } = requestVendors || {}
    const { data } = allVendor || []
    const sortData = _.orderBy(
      data,
      [vendor => vendor.name.toUpperCase()],
      'asc'
    )
    return (sortData || []).filter(
      d => (d.name || '').toUpperCase().indexOf(textFilter.toUpperCase()) !== -1
    )
  }

  const onHandleDeleteVendor = row => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const { email, vender_id: id } = row || {}

    // Check if Vendor has any RFP request Created
    checkVendorRequest({
      pdbid,
      context,
      id,
      onSuccess: rfpRequest => {
        // If no RFP request created then delete vendor
        if ((rfpRequest || []).length === 0) {
          return onDeleteVendor(pdbid, context, id, email)
        }
        return showModal(
          { width: '480px' },
          <ConfirmationModal
            msg="Unfortunately, we are unable to delete the vendor for you because RFP request(s) are already shared with them."
            dismissLabel="Ok"
            modalTitle="Unable to Delete Vendor"
            dismiss={dismissModal}
            showSubmitButton={false}
          />
        )
      },
      onError: () => {
        console.log('error')
      }
    })
  }

  // Delete Vendor
  const onDeleteVendor = (pdbid, context, id, email) => {
    deleteVendor({
      pdbid,
      context,
      body: {
        email
      },
      id,
      onSuccess: () => {
        console.log('delete')
      },
      onError: () => {
        console.log('error')
      }
    })
  }

  const filteredVendor = getFilteredVendor()

  return (
    <DataTable
      title="Vendors"
      header={getHeaderData()}
      data={filteredVendor}
      ref={tableRef}
      onExport={exportCSV}
      hasCheckboxes={false}
      additionalClasses={'request-vendor-table'}
      customResultsPerPage={5}
      singleSelectionColumn={'vender_id'}
      textFilter={textFilter}
      onSortChanged={data => {
        console.log({
          data
        })
      }}
      onSetTextFilter={setTextFilter}
      buttonsSection={
        <PermissionGate name={'add-rfp-vendor'}>
          <div className="columns is-vcentered">
            <div className="column is-narrow">
              <RestrictedElement>
                <button
                  className="button add-vendor-btn is-success"
                  type="button"
                  onClick={() => onAddVendorHandler()}>
                  Add Vendor
                </button>
              </RestrictedElement>
            </div>
          </div>
        </PermissionGate>
      }
      rowKey={'vender_id'}
      rowOptions={showUserDropdownOptions()}
    />
  )
}

RequestVendorsList.propTypes = {
  user: PropTypes.object,
  requestVendors: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  allRequestVendorGet: PropTypes.func,
  checkVendorRequest: PropTypes.func,
  context: PropTypes.object
}

export default RequestVendorsList
