import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePurchaseOrderStatus } from 'modules/invoices'
import { Modal } from 'ui'
import './index.scss'

const toBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

const getPDFBase64 = async doc => {
  const blob = doc.output('blob')
  const base64 = await toBase64(blob)
  return base64
}

const InvoicesApproveModal = ({
  invoice,
  doc,
  vendor_email,
  amount_due,
  po_number,
  company,
  ...restProps
}) => {
  const dispatch = useDispatch()
  const {
    user_metadata: { pdbid }
  } = useSelector(state => state.user)
  const context = useSelector(state => state.context)

  return (
    <Modal
      title="Send by Email"
      okText="Send"
      okDisabled={!vendor_email}
      okTooltip={!vendor_email}
      renderOkTooltipText={() => (
        <>
          <p>You are unable to send the email</p>
          <p>
            because there is no{' '}
            <span style={{ fontWeight: 'bold' }}>vendor email address</span>{' '}
            available.
          </p>
          <p>Please provide the email in the relevant vendor.</p>
        </>
      )}
      onConfirm={async () => {
        const pdf = await getPDFBase64(doc)

        if (vendor_email) {
          dispatch(
            changePurchaseOrderStatus({
              pdbid,
              context,
              invoiceId: invoice.po_id,
              status: 'sent',
              pdf,
              vendor_email,
              amount_due,
              po_number,
              company,
              invoice_file: invoice?.invoice_file
            })
          )
        }
      }}
      {...restProps}>
      <div className="columns">
        <div className="column has-text-centered">
          <p className="text-size-16">
            Confirm you have sent the PO to the vendor?
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default InvoicesApproveModal
