import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import BigLoading from '../../components/big-loading'
import Breadcrumbs from '../breadcrumbs'
import SetupBudgetsTitle from '../setup-budgets-title'
import SetupBudgetsServices from '../setup-budgets-services'
import { icons } from './icons'
import { useAppContext, usePermissionGate } from '../../helpers/hooks'
//import { getMoneyNumber } from '../../helpers'
import './index.scss'

const SetupBudgetsMain = ({
  location,
  user,
  context,
  openModal,
  setModalContent,
  closeModal,
  setupVendors,
  setupAllVendorsGet,
  setupBudgets,
  setupAllBudgetsGet,
  setupServicesGet,
  setupOneBudgetCreate,
  setupOneBudgetUpdate,
  setupBudgetLineCreate,
  setupBudgetLineUpdate
}) => {
  const {
    user: { properties }
  } = useAppContext()
  const [isEditingMain, setIsEditingMain] = useState(false)
  const [lastUpdated, setLastUpdated] = useState(new Date())

  const prevContext = usePrevious(context)

  useEffect(() => {
    const { pdbid } = user.user_metadata
    setupAllBudgetsGet(pdbid, context)
    setupServicesGet(pdbid, context)
    setupAllVendorsGet(pdbid, context)
  }, [])

  useEffect(() => {
    const { pdbid } = user.user_metadata
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged) {
      setupServicesGet(pdbid, context)
      setupAllBudgetsGet(pdbid, context)
      setupAllVendorsGet(pdbid, context)
    }
  }, [context, user])

  useEffect(() => {
    const { pdbid } = user.user_metadata
    setupAllBudgetsGet(pdbid, context)
  }, [lastUpdated, user])

  const isAdminInAnyProperty = useMemo(() => {
    return (properties || []).some(({ role }) => role?.name === 'Administrator')
  }, [properties])

  const isBudgetEnableInAnyProperty = useMemo(() => {
    //return (properties || []).some(({ budget }) => budget === null)
    const getCurrentProperty = (properties || []).filter(
      p => p?.id === context?.property
    )
    if (getCurrentProperty?.length) {
      return getCurrentProperty[0]?.budget === null
    }
    return false
  }, [properties])

  const isCustomBudgetAccessAllowed = useMemo(() => {
    return isAdminInAnyProperty && isBudgetEnableInAnyProperty
  }, [isAdminInAnyProperty, isBudgetEnableInAnyProperty])

  const { hasPermission } = usePermissionGate('edit-service-budget')
  const { hasPermission: hasPermissionAdmin } = usePermissionGate(
    'admin-edit-service-budget'
  )

  const checkIfActionAllowed = useMemo(() => {
    if (isBudgetEnableInAnyProperty) {
      return hasPermissionAdmin
    }
    return hasPermission
  }, [isBudgetEnableInAnyProperty, hasPermission, hasPermissionAdmin])

  const getLoadingState = () => <BigLoading />

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={icons.emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">No budget has been set up yet!</p>

        {checkIfActionAllowed ? (
          <>
            <p className="has-text-grey is-size-7 m-t-sm">
              Let's get started by adding the budget for your property.
            </p>
            <button
              className="button is-primary m-t-md"
              onClick={() => setIsEditingMain(true)}>
              Add Budget
            </button>
          </>
        ) : null}
      </div>
    </div>
  )

  /*const formatMoneyBucket = value => {
    if (!value) return <span className="has-text-grey">Pending...</span>
    return getMoneyNumber(value, 'USD')
  }*/

  const getManageState = () => (
    <div>
      <SetupBudgetsTitle
        context={context}
        user={user}
        setupBudgets={setupBudgets}
        setupOneBudgetCreate={setupOneBudgetCreate}
        setupOneBudgetUpdate={setupOneBudgetUpdate}
        isEditing={isEditingMain}
        toggleEditing={isEditing => setIsEditingMain(isEditing)}
        checkIfActionAllowed={checkIfActionAllowed}
      />
    </div>
  )

  const { budget } = setupBudgets
  const { allVendors, allServices } = setupVendors
  const { data } = budget
  if (
    !budget.hasMadeInitialRequest ||
    budget.isRequesting ||
    !allVendors.hasMadeInitialRequest ||
    allVendors.isRequesting ||
    !allServices.hasMadeInitialRequest ||
    allServices.isRequesting
  ) {
    return getLoadingState()
  }
  let leftColumn = getEmptyState()
  let format = Object.keys(data).length > 0 || isEditingMain ? 'two' : 'one'
  if (format === 'two') leftColumn = getManageState()
  return (
    <div className="container setup-budgets-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns setup-budget-main">
          <div className="column is-full">{leftColumn}</div>
        </div>
        <div className="columns">
          <div
            className={`column ${format === 'two' ? 'is-full' : 'is-hidden'}`}>
            <SetupBudgetsServices
              context={context}
              openModal={openModal}
              setModalContent={setModalContent}
              closeModal={closeModal}
              setupBudgets={setupBudgets}
              setupVendors={setupVendors}
              setupBudgetLineCreate={setupBudgetLineCreate}
              setupBudgetLineUpdate={setupBudgetLineUpdate}
              isFaded={isEditingMain}
              user={user}
              updateData={() => setLastUpdated(new Date())}
              checkIfActionAllowed={checkIfActionAllowed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SetupBudgetsMain.propTypes = {
  location: PropTypes.object,
  setupBudgets: PropTypes.object
}

export default SetupBudgetsMain
