import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'

/**
 * Component for showing a confirmation modal, for unsaved changes
 *
 * @component
 * @example
 * return (
 *   <UnsavedChangesModal
 *     message="Your unsaved changes will be lost. Are you sure you wan to leave?"
 *   />
 * )
 */
const UnsavedChangesModal = ({
  message = 'Your unsaved changes will be lost. Are you sure you wan to leave?',
  onStay = () => {},
  onLeave = () => {},
  leaveButtonText = 'Leave Page',
  stayButtonText = 'Stay on Page',
  subMessage = '',
  additionalClassesForMessage = '',
  showLoading=false,
  onSave = () => {},
  isSaveDisable=false
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // Configure Stay Button based on state
  const onHandleStay = () => {
    if(showLoading && onSave) {
      return onSave(setIsLoading, setErrorMessage)
    }
    return onStay()
  }
  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Unsaved Changes
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onStay}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>
  
      <div className="columns">
        <div className="column is-full">
          <p className={additionalClassesForMessage ? additionalClassesForMessage : 'is-size-6'}>{message}</p>
          {subMessage && <p className={`has-text-centered m-t-lg ${additionalClassesForMessage}`}>
            {subMessage}
          </p>}
        </div>
      </div>
      {
        errorMessage !== '' && <div className="notification is-danger is-light is-flex">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}><img src={closeFilled} alt="" /></button>
        </div>
      }
      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button className="button main-button is-secondary m-r-md" onClick={onLeave}>
            {leaveButtonText}
          </button>
  
          <button className="button main-button is-primary" disabled={isSaveDisable} onClick={() => onHandleStay()}>
            {stayButtonText}
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

UnsavedChangesModal.propTypes = {
  message: PropTypes.string,
  onLeave: PropTypes.func,
  onStay: PropTypes.func,
  /**
   * Text for Leave Button
  */
  leaveButtonText: PropTypes.string,
  /**
   * Text for Stay Button
  */
  stayButtonText: PropTypes.string,
  /**
   * Sub Message for Modal
  */
  subMessage: PropTypes.string,
  /**
   * Classes for Modal Message and Sub-Message
  */
  additionalClassesForMessage: PropTypes.string,
  /**
   * To show loading spinner in button
  */
  showLoading: PropTypes.bool,
  /**
   * Function for saving data 
  */
  onSave: PropTypes.func,
  /**
   * Check if save button should be disabled
  */
  isSaveDisable: PropTypes.func,
}

export default UnsavedChangesModal
