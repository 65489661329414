import moment from 'moment'

// Trims string type properties looping through every property of given object and returns a new object
export function trimObject(obj) {
  if (typeof obj !== 'object') {
    return obj
  }
  const newObj = { ...obj }
  for (const key in newObj) {
    const element = newObj[key]
    if (typeof element === 'string') {
      newObj[key] = element.trim()
    } else if (typeof element === 'object') {
      newObj[key] = trimObject(element)
    }
  }
  return newObj
}

export const intersectsContainer = (x, y, objects) => {
  let intersects = false
  let i = 0
  while (i < objects.length && !intersects) {
    if (objects[i]) {
      const rect = objects[i].getBoundingClientRect()
      intersects =
        intersects ||
        (x >= rect.x &&
          x <= rect.x + rect.width &&
          y >= rect.y &&
          y <= rect.y + rect.height)
    }
    i += 1
  }
  return intersects
}

export const animate = (element, animation = 'pulse') => {
  if (element.className.indexOf('animated') !== -1) {
    let arr = element.className.split(' ')
    let index = arr.indexOf('animated')
    arr.splice(index, 2)
    element.className = arr.join(' ')
  }
  const anim = ` animated ${animation}`
  element.className += anim
  setTimeout(() => {
    element.className = element.className.split(anim)[0]
  }, 500)
}

export const getFormData = (form, options = { forceNullOnEmpty: false }) => {
  let inputElements = form.getElementsByTagName('input')
  let obj = {}
  for (let i = 0; i < inputElements.length; i += 1) {
    let inputElement = inputElements[i]
    obj[inputElement.name] = inputElement.value
  }
  return obj
}

/*!
 * Convert a number to string with commas to separate by the 1000 (for money) and a $ prefix
 * @param  {number}   form The form to serialize
 * @return {String}     The comma-separated string
 */
export const getMoneyNumber = (
  x,
  currency = 'USD',
  suffix = '',
  minimumFractionDigits = 0
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits
  })
  return `${formatter.format(x)}${suffix}`
}

// Format Date to MM/DD/YYYY
export const formatDate = date =>
  moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : 'N/A'

// Check and restrict decimal places of values
export const hasDecimalPlace = (value, decimalPlaces) => {
  let pointIndex = (value || '').toString().indexOf('.')
  return pointIndex >= 0 && pointIndex < (value || '').length - decimalPlaces
}
