import React from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../modules/modal'
import { close } from 'ui/icons'

const SaveBeforeAutoscheduleModal = () => {
  const dispatch = useDispatch()

  const closeModalHandler = () => dispatch(closeModal())
  
  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column">
          <p className="heading-3 is-pulled-left">Save before Scheduling</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModalHandler}>
            <img alt="close modal" src={close} />
          </p>
        </div>
      </div>
      <div className="div columns">
        <div className="column">
          <p className="schedule-all-modal__text">
            It looks like you made some changes! Please save<br/>
            your work before auto-scheduling this service.
          </p>
        </div>
      </div>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-primary"
            onClick={closeModalHandler}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default SaveBeforeAutoscheduleModal
