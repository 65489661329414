import _ from 'lodash'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../breadcrumbs'
import DashboardHeader from '../../containers/dashboard-header'
import CostTable from '../../containers/cost-table'
import HealthMap from '../../containers/health-map'
import PropertySelected from '../../components/property-selected'

const DashboardCorporate = ({ location, context }) => {
  const [selectedProperty, setSelectedProperty] = useState(null)
  const project = _.get(context, 'project')
  return (
    <div className="container dashboard-corporate">
      <div className="section">
        <Breadcrumbs location={location} />
        <PropertySelected
          propertySelected={selectedProperty !== null}
          onRemoveSelection={() => setSelectedProperty(null)}
        />
        {selectedProperty === null && (
          <DashboardHeader
            onSelectionChanged={property => setSelectedProperty(property)}
          />
        )}
        <div className="columns">
          <div className="column">
            <div className="box animated flipInX">
              <p className="is-size-4">Cost by Service</p>
              <CostTable project={project} property={selectedProperty} />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="box animated flipInX">
              <p className="is-size-4">Overall Health - Map</p>
              <HealthMap
                property={selectedProperty}
                onPropertySelected={property => setSelectedProperty(property)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DashboardCorporate.propTypes = {
  location: PropTypes.object
}

export default DashboardCorporate
