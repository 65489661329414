import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InspectionManageMain from '../../parents/inspection-manage-main'
import {
  getInspectionItem,
  resetGetInspectionItem,
  getInspectionDownloadMediaURL,
  updateInspectionItem,
  getInspectionUploadMediaURL,
  addInspectionMedia,
  getInspectionPDFURL,
  downloadInspectionMedia
} from '../../modules/inspect'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'

const InspectionsManage = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <InspectionManageMain {...props} />
  }

  return <BigLoading />
}

InspectionsManage.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, inspect }) => ({
  user,
  context,
  modal,
  inspect
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      getInspectionItem,
      resetGetInspectionItem,
      getInspectionDownloadMediaURL,
      updateInspectionItem,
      getInspectionUploadMediaURL,
      addInspectionMedia,
      getInspectionPDFURL,
      downloadInspectionMedia
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InspectionsManage)
)
