import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { isBlacklisted, getIsActive } from '../sidebarUtilities'
import { resetInvoiceDetails } from 'modules/invoices'

const DropdownItem = ({
  menuItem,
  currentPath,
  onClick,
  user,
  property,
  loading
}) => {
  const dispatch = useDispatch()
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  const filteredChildren = menuItem.children
  // .filter(
  //   item => !isBlacklisted(user, item, property)
  // )

  if (filteredChildren.length === 0) {
    return null
  }

  return (
    <li>
      <div
        onClick={() => {
          if (!loading) {
            setIsCollapsed(!isCollapsed)
          }
        }}
        className={`collapser has-text-white is-unselectable is-pointer is-block ${
          isCollapsed && menuItem.shouldHighlight(currentPath)
            ? 'has-background-primary'
            : ''
        }`}>
        <div className="columns is-desktop is-mobile is-vcentered is-gapless">
          <div className="column is-narrow menu-icon">
            <img
              src={menuItem.iconSrc}
              className="is-inline"
              alt={menuItem.altText}
            />
          </div>
          <div className="column has-text-weight-bold">
            {menuItem.name}
            <FontAwesomeIcon
              icon={loading ? faSpinner : faAngleDown}
              spin={loading}
              className={`is-pulled-right down-arrow ${
                isCollapsed && !loading ? '' : 'is-upsidedown'
              }`}
            />
          </div>
        </div>
      </div>
      <ul
        className={`is-borderless is-paddingless is-marginless ${
          isCollapsed ? 'is-hidden' : ''
        }`}>
        {filteredChildren.map((childItem, i) => {
          return (
            <li key={i} style={{ marginLeft: '0.5rem' }}>
              <Link
                to={childItem.path}
                onClick={() => {
                  onClick()
                  if (childItem?.name?.toLowerCase() === 'purchase orders') {
                    dispatch(resetInvoiceDetails())
                  }
                }}
                className={`has-text-white ${getIsActive(
                  currentPath,
                  childItem.path
                )}`}>
                <span className="option">{childItem.name}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export default DropdownItem
