import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const MobileWorkTypeTitle = ({ title }) => {
  return (
    <div className="is-flex columns is-centered">
      <div className="has-divider">
        <div className="has-divider-text">{title}</div>
      </div>
    </div>
  )
}

MobileWorkTypeTitle.propTypes = {
  title: PropTypes.string,
}

export default MobileWorkTypeTitle
