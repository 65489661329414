import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './index.scss'

const Alerts = ({ alerts }) => {
  const hasAlerts = () => alerts !== null && alerts.length > 0
  return (
    <div id="alertbar" className={hasAlerts() ? 'show' : ''}>
      {hasAlerts() &&
        alerts.map(alert => (
          <div key={alert.id} className={`alert alert-${alert.type}`}>
            {alert.msg}
          </div>
        ))}
    </div>
  )
}

Alerts.propTypes = {
  alerts: PropTypes.array.isRequired
}

const mapStateToProps = ({ alerts }) => ({
  alerts
})

export default connect(mapStateToProps)(Alerts)
