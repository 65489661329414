import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

/**
 * Component for showing a tooltip overlapping any other elements
 *
 * @component
 * @example
 * return (
 *   <Tooltip
 *      content={<div>Tooltip</div>}
 *      isActive
 *      size: {{ height: '100px', width: '200px' }}
 *      position: {{ top: '200', left: '300' }}
 *   />
 * )
 */
const Tooltip = ({
  content,
  isActive,
  isLast,
  className,
  size,
  position,
  onMouseOut = () => {}
}) => {
  const { height, width } = size
  const { top, left } = position
  return (
    <div
      className={className || `tooltip ${width === '' && height === '' ? 'auto' : ''} ${isLast ? 'last' : ''}`}
      id="tooltip"
      style={{
        height,
        width,
        top,
        left,
        display: isActive ? 'block' : 'none'
      }}
      onMouseOut={e => onMouseOut && onMouseOut(e)}>
      {content}
    </div>
  )
}

Tooltip.propTypes = {
  /**
   * Tooltip content
   */
  content: PropTypes.element,
  /**
   * Determines if tooltip is currently showing
   */
  isActive: PropTypes.bool,
  /**
   * Determines if its the lastItem which calls the tooltip
   */
  isLast: PropTypes.bool,
  /**
   * Tooltip dimensions, with width and height in CSS units
   */
  size: PropTypes.object,
  /**
   * Tooltip absolute position, with top and left in CSS units
   */
  position: PropTypes.object,
  /**
   * Additional class to be used in main DIV
   */
  className: PropTypes.string,
  /**
   * Event handler for mouse out action
   */
  onMouseOut: PropTypes.func
}

export default Tooltip
