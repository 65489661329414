import React from 'react'
import { Link } from 'react-router-dom'

const NameColumn = ({ row }) => {
  const { cell } = row || {}
  const { row: originalRow, value } = cell || {}
  const { original: currentRow } = originalRow || {}
  const { user_id } = currentRow || {}

  return (
    <div className="cell-item" title={value}>
      <Link to={`/setup/vendors/view/${user_id}`}>{value}</Link>
    </div>
  )
}

export default NameColumn
