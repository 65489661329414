import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import StatsCard from '../../components/stats-card'
import ProgressCard from '../../components/progress-card'
import { statsCards } from './stats-cards'
import './index.scss'

const ServicesStats = ({ clicked, services, location }) => {
  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)
    return {
      s: newParams.get('s') || 'Paint',
      p: newParams.get('p') || 'Assign'
    }
  }, [location])

  const cardClicked = id => clicked(id)

  const { stats, statsIsRequesting, statsHasData } = services

  let template = []
  for (let i = 0; i < statsCards.length; i += 1) {
    const sc = {
      ...statsCards[i],
      stat: stats[statsCards[i]['id']],
      beds: {
        not_assigned: stats['total_not_assigned_beds'],
        assigned: stats['total_assigned_beds'],
        in_progress: stats['total_in_progress_beds'],
        ready_for_qc: stats['total_ready_for_qc_beds'],
        pending_approval: stats['total_pending_approval_beds'],
        approved: stats['total_approved_beds'],
      }
    }
    template.push(
      <div
        className="column is-full-mobile is-half-tablet is-one-third-desktop"
        key={`stats-card-${i}`}
        onClick={() => cardClicked(sc.to)}>
        <StatsCard
          icon={sc.icon}
          caption={sc.caption}
          stat={sc.stat}
          totalBeds={sc['beds'][statsCards[i]['id']]}
          showLoading={statsHasData === false || statsIsRequesting === true}
          isSelected={
            params.p === sc.to || sc.alsoSelectedWhen.indexOf(params.p) !== -1
          }
          showUnits={true}
        />
      </div>
    )
  }
  const cards = (
    <div className="column is-full is-three-quarters-widescreen">
      <p className="m-b-sm" style={{ fontSize: 12 }}>Turn Flow</p>
      <div className="columns is-mobile is-multiline">{template}</div>
    </div>
  )
  const summary = (
    <div className="column is-full is-one-quarter-widescreen" style={{ position: 'relative', zIndex: 1}}>
      <p className="m-b-sm" style={{ fontSize: 12 }}>Summary</p>
      <ProgressCard
        stats={stats}
        caption={'Days Until Move-In'}
        showLoading={statsHasData === false || statsIsRequesting === true}
        showProgress={false}
      />
    </div>
  )

  return (
    <div className="columns is-multiline">
      {document.body.clientWidth > 1024 ? (
        <>
          {cards}
          {summary}
        </>
      ) : (
        <>
          {summary}
          {cards}
        </>
      )}
    </div>
  )
}

ServicesStats.propTypes = {
  services: PropTypes.object,
  responsive: PropTypes.object,
  click: PropTypes.func
}

export default ServicesStats
