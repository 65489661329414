import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import Stars from '../stars'
import ComponentWithStatus from '../component-with-status'

const COLUMNS = 4

const OtherProperty = ({ loading, error, property }) => (
  <div className="dashboard-card card-md">
    <div className="inner-content">
      <ComponentWithStatus loading={loading} error={error}>
        <div className="heading-5 p-b-md">{_.get(property, 'name')}</div>
        <div className="has-text-centered p-b-sm">
          Move Out: {_.get(property, 'moveOut')}
        </div>
        <div className="has-text-centered p-b-sm">
          Move In: {_.get(property, 'moveIn')}
        </div>
        <div className="has-text-centered p-b-md">
          <Stars
            numActive={_.get(property, 'health', 0)}
            className="dashboard-stars"
          />
        </div>
      </ComponentWithStatus>
    </div>
  </div>
)

const generateRows = (data, loading, error) => {
  const template = []
  const maxRows =
    Math.floor(data.length / COLUMNS) + (data.length % COLUMNS === 0 ? 0 : 1)
  for (let currentRow = 0; currentRow < maxRows; currentRow++) {
    const columns = []
    let currentColumn = 0
    while (
      currentColumn < COLUMNS &&
      data[currentRow * COLUMNS + currentColumn]
    ) {
      columns.push(
        <div className="column is-one-quarter" key={Short.uuid()}>
          <OtherProperty
            key={Short.uuid()}
            loading={loading}
            error={error}
            property={data[currentRow * COLUMNS + currentColumn]}
          />
        </div>
      )
      currentColumn++
    }
    template.push(
      <div className="columns is-vcentered" key={Short.uuid()}>
        {columns}
      </div>
    )
  }
  return template
}

/**
 * Other properties in the same project summary boxes
 *
 * @component
 * @example
 * const data = [
 *   {
 *     "property":"auburn",
 *     "moveOut":"08/05/2020",
 *     "moveIn":"08/20/2020",
 *     "health":3,
 *     "name":"Auburn"
 *   },
 *   {
 *     "property":"springfield",
 *     "moveOut":"07/27/2020",
 *     "moveIn":"08/31/2020",
 *     "health":5,
 *     "name":"Springfield"
 *   }
 * ]
 * return (
 *   <YourOtherProperties
 *     loading={false}
 *     error={false}
 *     data={data}
 *   />
 * )
 */
const YourOtherProperties = ({ loading, error, data }) => {
  return (
    <div style={{ minHeight: '2rem' }}>
      <ComponentWithStatus loading={loading} error={error}>
        {generateRows(data, loading, error)}
      </ComponentWithStatus>
    </div>
  )
}

YourOtherProperties.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Other properties data
   */
  data: PropTypes.array
}

export default YourOtherProperties
