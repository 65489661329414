import _ from 'lodash'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DashboardProperty from '../dashboard-property'
import DashboardRegional from '../dashboard-regional'
import DashboardCorporate from '../dashboard-corporate'

const DashboardMain = props => {
  const { location } = props
  if (location.pathname.indexOf('dashboard/rm') !== -1) {
    return <DashboardRegional {...props} />
  } else if (location.pathname.indexOf('dashboard/corporate') !== -1) {
    return <DashboardCorporate {...props} />
  }
  return <DashboardProperty {...props} />
}

DashboardMain.propTypes = {
  user: PropTypes.object,
  updateContext: PropTypes.func,
  requestContextChange: PropTypes.func,
  getDashboard: PropTypes.func
}

const compareDashboard = (prevProps, nextProps) => {
  const { context, user, location, dashboard } = prevProps
  return (
    _.isEqual(context, nextProps.context) &&
    _.isEqual(user, nextProps.user) &&
    _.isEqual(location, nextProps.location) &&
    _.isEqual(dashboard, nextProps.dashboard)
  )
}

export default memo(DashboardMain, compareDashboard)
