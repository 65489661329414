import React from 'react'
import DamageSetupCell from './components/DamageSetupCell'
import InputField from './components/InputField'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import CostDropdownField from './components/CostDropdownField'
import CASplitFilter from './components/filters/splitFilter'

const GenerateDamageCheckbox = ({
  value,
  updateInspectionValue,
  index,
  hasPermission
}) => {
  return (
    <div>
      <input
        type="checkbox"
        name="damages_generate"
        disabled={!hasPermission}
        style={{ width: '14px', cursor: 'pointer' }}
        onChange={e => {
          updateInspectionValue(index, e.target.checked, 'damages_generate')
        }}
        checked={value}
      />
    </div>
  )
}

export const headerData = ({ updateInspectionValue, hasPermission }) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Space',
        accessor: 'space',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 180,
        Cell: row => {
          return <DamageSetupCell row={row} />
        }
      },
      {
        Header: 'Item',
        accessor: 'item',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 200,
        Cell: row => {
          return <DamageSetupCell row={row} />
        }
      },
      {
        Header: 'Observation',
        accessor: 'observation',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        flexGrow: 1,
        //minWidth: 180,
        Cell: row => {
          return <DamageSetupCell row={row} />
        }
      },
      {
        Header: 'Generate Damage?',
        accessor: 'damages_generate',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 100,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original, index } = currentRow || {}
          const { damages_generate } = original || {}
          return (
            <div style={{ marginTop: '2px' }}>
              <GenerateDamageCheckbox
                value={damages_generate}
                updateInspectionValue={updateInspectionValue}
                index={index}
                hasPermission={hasPermission}
              />
            </div>
          )
        }
      },
      {
        Header: 'Damage Cost',
        accessor: 'damage_cost',
        textAlign: 'left',
        width: 150,
        Cell: row => {
          return (
            <InputField
              row={row}
              updateInspectionValue={updateInspectionValue}
              type="damage_cost"
              hasPermission={hasPermission}
            />
          )
        }
      },
      {
        Header: 'Damage Code',
        accessor: 'damage_code',
        textAlign: 'left',
        width: 150,
        Cell: row => (
          <InputField
            row={row}
            updateInspectionValue={updateInspectionValue}
            type="damage_code"
            hasPermission={hasPermission}
          />
        )
      },
      {
        Header: 'CA Split Type',
        accessor: 'ca_split_type',
        Filter: CASplitFilter,
        textAlign: 'left',
        width: 150,
        Cell: row => {
          return (
            <div className="damage-setup">
              <CostDropdownField row={row} hasPermission={hasPermission} />
            </div>
          )
        }
      }
    ]
  }
]
