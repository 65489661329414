import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../breadcrumbs'
import DaysToMoveIn from '../../components/dashboard/days-to-move-in'
import BedsTurned from '../../components/dashboard/beds-turned'
import UnitsTurned from '../../components/dashboard/units-turned'
import HealthRating from '../../components/dashboard/health-rating'
import Congrats from '../../components/dashboard/congrats'
import TodaysChallenge from '../../components/dashboard/todays-challenge'
import CostsByServiceChart from '../../components/dashboard/costs-by-service-chart'
import Calendar from '../../components/dashboard/calendar'
import PropertyContact from '../../components/dashboard/property-contact'
import TotalTurnCost from '../../components/dashboard/total-turn-cost'
import CostBudget from '../../components/dashboard/cost-budget'
import Goals from '../../components/dashboard/goals'
import YourOtherProperties from '../../components/dashboard/your-other-properties'
import ChangePropertyModal from './change-property-modal'

import '../../components/dashboard/index.scss'

import propertyMock from './mocks/property.json'
import propertiesMock from './mocks/allProperties.json'

const MODAL_WIDTH = '500px'

const DashboardRegional = ({
  location,
  context,
  dashboard,
  getServicesSummary,
  getChartsSummary,
  getGoalsSummary,
  getCostSummary,
  auth,
  user,
  openModal,
  setModalContent,
  closeModal
}) => {
  const [property, setProperty] = useState(_.get(context, 'property'))
  const [properties, setProperties] = useState([])

  useEffect(() => {
    const availablePropertyIds = _.get(
      _.find(
        _.get(user, 'user_metadata.projects', []),
        p => p.id === _.get(context, 'project')
      ),
      'properties',
      []
    )
    setProperties(
      _.filter(
        _.get(user, 'user_metadata.properties', []),
        p => availablePropertyIds.indexOf(p.id) >= 0
      )
    )
  }, [user])

  // TODO: Check if everything should be this way
  useEffect(() => {
    if (
      user &&
      user.user_metadata &&
      auth.isAuthenticated &&
      auth.hasMgmtToken &&
      property
    ) {
      getChartsSummary(user.user_metadata.pdbid, { ...context, property })
      getGoalsSummary(user.user_metadata.pdbid, { ...context, property })
      getCostSummary(user.user_metadata.pdbid, { ...context, property })
    }
  }, [property, auth, user])

  const openPropertyModal = () => {
    setModalContent(() => (
      <ChangePropertyModal
        current={property}
        properties={properties}
        onPropertyChange={p => {
          closeModal()
          setProperty(p)
        }}
        onClose={() => closeModal()}
      />
    ))
    openModal(MODAL_WIDTH)
  }

  const chartsSection = _.get(
    dashboard,
    `charts.${context.project}_${property}`
  )
  const goalsSection = _.get(dashboard, `goals.${context.project}_${property}`)
  const costSection = _.get(dashboard, `cost.${context.project}_${property}`)

  const chartsIsLoading = _.get(chartsSection, 'isRequesting', true)
  const chartsIsError = _.get(chartsSection, 'isError', false)
  const chartsData = _.get(chartsSection, 'charts', [])
  const goalsIsLoading = _.get(goalsSection, 'isRequesting', true)
  const goalsIsError = _.get(goalsSection, 'isError', false)
  const goalsData = _.get(goalsSection, 'bars', [])
  const costIsLoading = _.get(costSection, 'isRequesting', true)
  const costIsError = _.get(costSection, 'isError', false)
  const costData = _.get(costSection, 'rows', [])

  const goals = (
    <Goals loading={goalsIsLoading} error={goalsIsError} data={goalsData} />
  )

  const beds = _.find(chartsData, c => c.group === 'beds') || []
  const units = _.find(chartsData, c => c.group === 'units') || []

  const propertyName = _.get(
    _.find(_.get(user, 'user_metadata.properties', []), p => p.id === property),
    'name',
    'Unidentified'
  )

  const otherProperties = []
  _.forEach(properties, p => {
    if (p.id !== property) {
      const data = _.find(propertiesMock, pr => pr.property === p.id)
      if (data) {
        otherProperties.push({
          ...data,
          name: p.name
        })
      }
    }
  })

  return (
    <div className="container dashboard-main">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns">
          <div className="column is-full">
            <div className="large-text">
              You are viewing the information for{' '}
              <span className="has-text-weight-bold">{propertyName}</span>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-fourth">
            <DaysToMoveIn
              loading={chartsIsLoading}
              error={chartsIsError}
              days={4}
            />
          </div>
          <div className="column is-fourth">
            <BedsTurned
              loading={chartsIsLoading}
              error={chartsIsError}
              turned={_.get(
                _.find(_.get(beds, 'data', []), b => b.category === 'Complete'),
                'value',
                0
              )}
              left={_.get(
                _.find(
                  _.get(beds, 'data', []),
                  b => b.category === 'Not Complete'
                ),
                'value',
                0
              )}
              noTurn={
                _.get(
                  _.find(
                    _.get(beds, 'data', []),
                    b => b.category === 'Complete'
                  ),
                  'value',
                  0
                ) +
                _.get(
                  _.find(
                    _.get(beds, 'data', []),
                    b => b.category === 'Not Complete'
                  ),
                  'value',
                  0
                )
              }
            />
          </div>
          <div className="column is-fourth">
            <UnitsTurned
              loading={chartsIsLoading}
              error={chartsIsError}
              turned={_.get(
                _.find(
                  _.get(units, 'data', []),
                  b => b.category === 'Complete'
                ),
                'value',
                0
              )}
              left={_.get(
                _.find(
                  _.get(units, 'data', []),
                  b => b.category === 'Not Complete'
                ),
                'value',
                0
              )}
              noTurn={
                _.get(
                  _.find(
                    _.get(units, 'data', []),
                    b => b.category === 'Not Turn'
                  ),
                  'value',
                  0
                )
              }
            />
          </div>
          <div className="column is-fourth">
            <HealthRating loading={false} error={false} stars={4} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-three-quarters">
            <div className="columns">
              <div className="column is-one-third">
                <div className="columns">
                  <div className="column is-full">
                    <Congrats
                      loading={false}
                      error={false}
                      percentage={75}
                      context={{ project: 'Turn 2020' }}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-full">
                    <TodaysChallenge />
                  </div>
                </div>
              </div>
              <div className="column is-two-thirds">
                <CostsByServiceChart
                  loading={costIsLoading}
                  error={costIsLoading}
                  costs={costData}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-one-third">
                <PropertyContact
                  loading={false}
                  error={false}
                  property={propertyMock}
                />
              </div>
              <div className="column is-one-third">
                <TotalTurnCost
                  loading={costIsLoading}
                  error={costIsError}
                  costs={costData}
                />
              </div>
              <div className="column is-one-third">
                <CostBudget
                  loading={costIsLoading}
                  error={costIsError}
                  costs={costData}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-quarter">
            <Calendar
              loading={false}
              error={false}
              moveOut={new Date(2020, 8, 30)}
              moveIn={new Date(2020, 9, 17)}
              transfers={new Date(2020, 9, 6)}
              totalTurnDays={18}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">{goals}</div>
        </div>
        <div className="columns">
          <div className="column is-full large-text">
            <span className="p-r-lg">Your Other Properties</span>
            <a href="#!" onClick={() => openPropertyModal()}>
              Change Property
            </a>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <YourOtherProperties
              loading={false}
              error={false}
              data={otherProperties}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardRegional
