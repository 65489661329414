import _ from 'lodash'
import React, { useMemo, useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import {
  useTheme,
  create,
  ColorSet,
  color,
  Container,
  registry
} from '@amcharts/amcharts4/core'
import { PieChart, PieSeries, Legend } from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import ComponentWithStatus from '../component-with-status'
import { SUMMARY_CARDS } from './constants'
import { currencyFormatter } from '../../utils'

const COLORS = [
  '#55c2b8',
  '#122048',
  '#0081c3',
  '#8bd3ed',
  '#ced4da',
  '#e93f33'
]

/**
 * Costs by service with pie charts, for use in Regional Dashboard
 *
 * @component
 * @example
 * return (
 *   <CostsByServiceChart
 *     loading={false}
 *     error={false}
 *     costs={[
 *       {
 *         service: 'Paint',
 *         budget: 75000,
 *         difference: 25000,
 *         actual: 50000
 *       },
 *       {
 *         service: 'Clean',
 *         budget: 50000,
 *         difference: -10000,
 *         actual: 60000
 *       },
 *       {
 *         service: 'CP Rep',
 *         budget: 20000,
 *         difference: 20000,
 *         actual: 0
 *       }
 *     ]}
 *   />
 * )
 */
const CostsByServiceChart = ({ loading, error, costs }) => {
  const uuid = useMemo(() => Short.uuid(), [])
  const ref = useRef()

  const findChart = () =>
    registry.baseSprites.find(
      chartObj => chartObj.htmlContainer.id === `pie-chart-${uuid}`
    ) || null

  const findLegend = () =>
    registry.baseSprites.find(
      chartObj => chartObj.htmlContainer.id === `legend-container-${uuid}`
    ) || null

  const data = _.map(costs, cost => ({
    ...cost,
    name: _.get(
      _.find(SUMMARY_CARDS, c => c.short === cost.service),
      'label',
      cost.service
    )
  }))

  const getChart = () => {
    if (!ref.current || findChart()) return

    useTheme(am4themes_animated)

    const legendContainer = create(`legend-container-${uuid}`, Container)
    const updated = create(`pie-chart-${uuid}`, PieChart)
    updated.data = data

    const pieSeries = updated.series.push(new PieSeries())
    pieSeries.dataFields.value = 'budget'
    pieSeries.dataFields.category = 'name'
    pieSeries.labels.template.disabled = true

    const colorSet = new ColorSet()
    colorSet.list = COLORS.map(c => {
      return new color(c)
    })
    pieSeries.colors = colorSet

    updated.legend = new Legend()
    updated.legend.position = 'right'
    updated.legend.itemContainers.template.paddingTop = 2
    updated.legend.itemContainers.template.paddingBottom = 2
    updated.legend.useDefaultMarker = true
    const marker = updated.legend.markers.template.children.getIndex(0)
    marker.dy = 3
    marker.cornerRadius(12, 12, 12, 12)
    marker.height = 16
    marker.width = 16
    //'{categoryY}: {value} days'
    updated.legend.labels.template.adapter.add('text', (text, target) => {
      const name = _.get(target, 'parent.dataItem.name')
      return `${name} = ${currencyFormatter(
        _.get(_.find(data, cost => cost.name === name), 'budget', 0)
      )}`
    })
    updated.legend.valueLabels.template.disabled = true
    updated.legend.fontSize = 14
    updated.legend.marginLeft = 20
    updated.legend.marginRight = 20
    updated.legend.divId = 'keys_legend'
    updated.legend.parent = legendContainer

    return updated
  }

  const disposeChart = () => {
    const foundChart = findChart()
    if (foundChart) {
      foundChart.dispose()
    }
    const foundLegend = findLegend()
    if (foundLegend) {
      foundLegend.dispose()
    }
  }

  useEffect(() => {
    return () => {
      disposeChart()
    }
  }, [])

  useEffect(() => {
    disposeChart()
    getChart()
  }, [ref.current, costs])

  return (
    <div className="dashboard-card card-fill">
      <div className="inner-content">
        <ComponentWithStatus loading={loading} error={error}>
          <>
            <p className="heading-5">Breakdown: Costs by Services</p>
            <div className="columns">
              <div className="column is-half">
                <div
                  id={`pie-chart-${uuid}`}
                  className={`pie-chart-${uuid} has-text-centered`}
                  ref={ref}
                  style={{
                    top: '3em',
                    bottom: '1em',
                    left: '1em',
                    position: 'absolute',
                    width: '50%'
                  }}></div>
              </div>
              <div
                className="column is-half"
                style={{
                  height: '100%',
                  bottom: '1em',
                  position: 'absolute',
                  right: '1em'
                }}>
                <div className="columns">
                  <div
                    className="column is-full semistrong"
                    style={{
                      top: '3rem',
                      left: '10px',
                      position: 'absolute'
                    }}>
                    Total ={' '}
                    {currencyFormatter(
                      _.reduce(
                        Object.values(costs),
                        (acc, current) => acc + current.budget,
                        0
                      )
                    )}
                  </div>
                </div>
                <div
                  className="columns"
                  style={{ position: 'relative', height: '100%' }}>
                  <div
                    className={`legend-container-${uuid} column is-full`}
                    style={{
                      marginTop: '4.5rem',
                      height: '100%',
                      paddingBottom: '3rem'
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </ComponentWithStatus>
      </div>
    </div>
  )
}

CostsByServiceChart.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Array with the costs on the current property
   */
  costs: PropTypes.array
}

export default memo(CostsByServiceChart)
