import React, { forwardRef, memo, useMemo, useCallback, useEffect } from 'react'
import {
  useBlockLayout,
  useFilters,
  useSortBy,
  useTable,
  useRowSelect,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import './pricing-table.scss'

import SortIcon from '../../../../../components/common/SortIcon/SortIcon'

import DefaultColumnFilter from '../../../../../components/react-table-column-filters/DefaultColumnFilter'
import { PricingTableStyles } from './pricing-table-styles'

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const handleChange = () => {
      const filteredRows = (rows || []).filter(
        ({ original }) =>
          original?.unit_id === currentRow?.original?.unit_id &&
          original?.canSchedule
      )

      return (filteredRows || []).forEach(({ id, isSelected }) => {
        toggleRowSelected(id, !isSelected)
      })
    }

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            enableManualSelection ? handleChange() : onChange(e)
          }}
          {...rest}
          title={
            showTooltip
              ? 'The capacity for the selected date has been reached.'
              : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const RenderRow = ({ data, index, style }) => {
  const { rows, prepareRow } = data || {}
  const row = rows[index]

  prepareRow(row)

  return (
    <div
      {...row.getRowProps({
        style
      })}
      className="tr">
      {row.cells.map(cell => {
        let styleBody = {}
        if (cell.column.minWidth !== 0) {
          styleBody.minWidth = cell.column.minWidth
        }
        if (cell.column.flexGrow !== 0) {
          styleBody.flexGrow = cell.column.flexGrow
        }
        return (
          <div
            {...cell.getCellProps({
              style: {
                textAlign: cell.column.textAlign,
                width: cell.column.width,
                ...styleBody
              }
            })}
            className="td">
            {cell.render('Cell')}
          </div>
        )
      })}
    </div>
  )
}

const PricingTable = (
  {
    data,
    tableColumns,
    setFilteredRows,
    clearAllFilter,
    isEdit,
    onRowSelectStateChange
  },
  ref
) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterTypes = React.useMemo(
    () => ({
      customEquals: (rows, id, filterValue) => {
        if (!filterValue) return rows
        id = id[0]
        return rows.filter(row => filterValue?.[id] === row.values?.[id])
      },
      multiEquals: (rows, id, filterValue) => {
        if ((filterValue || []).length === 0) return rows

        id = id[0]

        return (rows || []).filter(row => {
          return filterValue?.some(
            filterVal => filterVal?.[id] === row.values?.[id]
          )
        })
      }
    }),
    []
  )

  const checkToShowSelection = useMemo(() => {
    return (tableColumns || []).filter(t => t.accessor === 'unit').length !== 0
  }, [tableColumns])

  const {
    getTableProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setAllFilters,
    toggleRowSelected,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable(
    {
      columns: tableColumns,
      data,
      defaultColumn,
      filterTypes,
      sortTypes: {
        alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
          const rowOneColumn = row1?.values[columnName]
          const rowTwoColumn = row2?.values[columnName]
          return (rowOneColumn || '').toString().toLowerCase() >
            (rowTwoColumn || '').toString().toLowerCase()
            ? 1
            : -1
        }
      }
    },
    useBlockLayout,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks =>
      checkToShowSelection && isEdit
        ? hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              width: 45,
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return (
                  <div className="checkbox-wrapper">
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                )
              },
              Cell: ({ row }) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      currentRow={row}
                      rows={rows}
                      toggleRowSelected={toggleRowSelected}
                    />
                  </div>
                )
              }
            },
            ...columns
          ])
        : null
  )

  useEffect(() => {
    setFilteredRows(rows?.map(row => row.original))
  }, [rows])

  useEffect(() => {
    setAllFilters([])
  }, [clearAllFilter])

  useEffect(() => {
    onRowSelectStateChange(selectedFlatRows)
  }, [selectedRowIds])

  return (
    <div>
      <PricingTableStyles>
        <div {...getTableProps()} className="pricing-form-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr full-header">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width,
                        minWidth: column.minWidth,
                        flexGrow: column.flexGrow
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      <span {...column.getSortByToggleProps()}>
                        <SortIcon column={column} />
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            {(rows || []).length ? (
              <FixedSizeList
                key={(data || []).length}
                className="full-body list-container"
                height={345}
                itemCount={(rows || []).length}
                itemSize={35}
                itemData={{ rows, prepareRow }}>
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      </PricingTableStyles>
    </div>
  )
}

export default memo(forwardRef(PricingTable))
