import React from 'react'

const StatusColumn = ({ row }) => {
  const { cell } = row || {}
  const { value } = cell || {}

  return (
    <div className="cell-item" title={value}>
      <span style={{ fontSize: '14px', lineHeight: 'unset', display: 'unset' }}>
        {value}
      </span>
    </div>
  )
}

export default StatusColumn
