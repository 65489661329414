export const defaultServices = [
  {
    name: 'Punch',
    path: '/services?s=Punch'
  },
  {
    name: 'Paint',
    path: '/services?s=Paint'
  },
  {
    name: 'Clean',
    path: '/services?s=Cleaning'
  },
  {
    name: 'Carpet Clean',
    path: '/services?s=CarpetClean'
  },
  {
    name: 'Carpet Replace',
    path: '/services?s=CarpetReplace'
  },
  {
    name: 'Concrete Vinyl Replace',
    path: '/services?s=ConcreteVinylReplace'
  },
  {
    name: 'Sparkle',
    path: '/services?s=Sparkle'
  }
]
