import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateContext } from '../../modules/context'
import BigLoading from '../../components/big-loading'
import VendorDashboardMain from '../../parents/vendor-dashboard-main'

const vendorDashboard = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <VendorDashboardMain {...props} />
  }

  return <BigLoading />
}

vendorDashboard.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
}

const mapStateToProps = ({ auth, user, context }) => ({
  auth,
  user,
  context,
})


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateContext
    },
    dispatch
  )



export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(vendorDashboard)
)
