import React from 'react'

const DamageSetupCell = ({ row }) => {
  const { cell } = row || {}
  const { row: currentRow, value } = cell || {}
  const { original } = currentRow || {}

  return (
    <div className="cell-item" title={value}>
      <span>{value}</span>
    </div>
  )
}

export default DamageSetupCell
