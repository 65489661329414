import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateContext, requestContextChange } from '../../modules/context'
import {
  getKeysSummary,
  getServicesSummary
} from '../../modules/dashboard-services'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { getChartsSummary } from '../../modules/dashboard-charts'
import {
  getGoalsSummary,
  getAutomaticGoalsSummary
} from '../../modules/dashboard-goals'
import { getCostSummary } from '../../modules/dashboard-cost'
import { getTurnSummary } from '../../modules/dashboard-turn'
import DashboardMain from '../../parents/dashboard-main'
import BigLoading from '../../components/big-loading'

const Dashboard = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <DashboardMain {...props} />
  }

  return <BigLoading />
}

Dashboard.propTypes = {
  user: PropTypes.object,
  dashboard: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ auth, user, dashboard, context }) => ({
  auth,
  user,
  dashboard,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateContext,
      requestContextChange,
      getKeysSummary,
      getServicesSummary,
      getChartsSummary,
      getGoalsSummary,
      getAutomaticGoalsSummary,
      getCostSummary,
      getTurnSummary,
      openModal,
      setModalContent,
      closeModal
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
)
