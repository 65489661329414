import React, { useRef, useState, useEffect } from 'react'
import { Gantt } from '@bryntum/gantt'
import './index.scss'

const GanttChart = ({ project }) => {
  const ganttRef = useRef()

  const [ganttTabs, setGanttTabs] = useState('services')
  const [activeProject, setActiveProject] = useState('servicesConfig')

  const [activeMonth, setActiveMonth] = useState( () => {
    const date = new Date()
    return {
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
    }
  })

  const [ganttColumns, setGanttColumns] = useState([
    {
      type: 'name',
      field: 'name',
      text: '',
      editor: null,
      width: 100,
      // renderer: ({ size, value }) => {
      //   size.height = 35
      //   return value
      // },
    },
  ])

  const tabHandler = tab => {
    setGanttTabs(tab)

    switch (tab) {
      case 'services':
        setGanttColumns([
          {
            type: 'name',
            field: 'name',
            text: '',
            editor: null,
            width: 100
          },
        ])
        setActiveProject('servicesConfig')
        break
      
      case 'teams':
        setGanttColumns([
          {
            type: 'name',
            field: 'name',
            text: 'Teams',
            editor: null,
            width: 100,
          },
        ])
        setActiveProject('teamsConfig')
        break
      
      case 'groups':
        setGanttColumns([
          {
            type: 'name',
            field: 'name',
            text: 'Groups',
            editor: null,
            width: 100,
          },
        ])
        setActiveProject('groupsConfig')
        break
    
      default:
        return
    }
  }

  const monthHandler = newDate => {
    setActiveMonth(() => {
      const date = new Date(newDate.value)
      
      return {
        startDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
      }
    })
  }
  
  useEffect(() => {
    ganttRef.current = new Gantt({
      project: project[activeProject],
      appendTo: 'gantt-chart',
      startDate: activeMonth['startDate'],
      endDate: activeMonth['endDate'],
      columns: ganttColumns,
      columnLines: false,
      viewPreset: {
        base: 'weekAndDay',
        id: 'dateAndMonthPreset',
        tickWidth: 40,
        headers: [
          {
            unit: 'month',
            dateFormat: 'MMMM'
          },
          {
            unit: 'day',
            increment: 1,
            renderer: startDate => {
              const date = new Date(startDate)

              return `
                <div class="day-cell">
                  <span class="day-cell-item">${new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date)}</span>
                  <span class="day-cell-item dimmed">${new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date)}</span>
                </div>
                `
            }
          }
        ]
      },
      features: {
        taskEdit: { disabled: true },
        taskDrag: { disabled: true },
        taskMenu: { disabled: true },
        taskResize: { disabled: true },
        dependencies: { disabled: true },
        projectLines: { disabled: true },
        taskTooltip : {
          contentElementCls: 'gantt-tooltip',
          allowOver: true
        }
      },
      tbar: {
        items: [
          {
            type: 'ButtonGroup',
            toggleGroup: true,
            items: [
              {
                text: 'Services',
                pressed: ganttTabs === 'services',
                onClick: () => tabHandler('services'),
                toggleable: true
              },
              {
                text: 'Teams',
                pressed: ganttTabs === 'teams',
                onClick: () => tabHandler('teams'),
                toggleable: true
              },
              // TODO uncomment when this section is ready
              // {
              //   text: 'Groups',
              //   pressed: ganttTabs === 'groups',
              //   onClick: () => tabHandler('groups'),
              //   toggleable: true
              // }
            ],
            cls: 'data-selector',
            weight: 100
          },
          {
            type: 'DateField',
            cls: 'month-selector',
            editable: false,
            format: 'MMMM - YYYY',
            value: activeMonth.startDate,
            step: '1 month',
            onChange: date => monthHandler(date)
          },
        ]
      },
      taskRenderer: ({ taskRecord }) => `<span class="percent-label">${Math.round(taskRecord.percentDone)}%</span>`
    })

    return () => {
      if (ganttRef.current) {
        ganttRef.current.destroy()
      }
    }
  }, [ganttColumns, activeMonth, activeProject])

  return <div id="gantt-chart" className="gantt-container"></div>
}

export default GanttChart
