import React from 'react'
import { useSelector } from 'react-redux'
import { numberFormatter } from '../../utils'
import './index.scss'

const ProgressCardItems = ({ itemsInfo }) => {
  const { isOpen } = useSelector(state => state.sidebar)

  return (itemsInfo.length
    ? itemsInfo.map(item => (
      <div className="info-section" key={item.title}>
        <div className="section-header">{item.title}</div>
        <div className={`section-body ${ isOpen ? 'open-sidebar' : ''}`}>
          <div className="item">
            <div className="labels">
              <span className="item-label">Completed:</span>
              <span className="item-value">{numberFormatter(item.completed)}</span>
            </div>
            <div className="labels">
              <span className="item-label dimmed has-extra-info">
                Not Needed:
                <div className="extra-info">
                  <span className="extra-info-title">{item.extraInfo.title}</span>
                  {item.extraInfo.items.map( extraInfoItem => (
                    <div className="extra-info-item" key={extraInfoItem.label}>
                      <span className="extra-info-item-label m-r-sm">{extraInfoItem.label}:</span>
                      <span>{numberFormatter(extraInfoItem.value)}</span>
                    </div>
                  ))}
                </div>
              </span>
              <span className="item-value dimmed">{numberFormatter(item.notNeeded)}</span>
            </div>
          </div>
          <div className="item">
            <div className="labels">
              <span className="item-label">Remaining:</span>
              <span className="item-value">{numberFormatter(item.remaining)}</span>
            </div>
            <div className="labels">
              <span className="item-label">Total:</span>
              <span className="item-value">{numberFormatter(item.total)}</span>
            </div>
          </div>
        </div>
      </div>
    ))
    : null)
}

export default ProgressCardItems
