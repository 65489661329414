import React from 'react'
import DamageCellComponent from './components/DamageCell'

export const headerData = () => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Unit',
        accessor: 'unit',
        textAlign: 'left',
        width: 80,
        Cell: row => {
          return <DamageCellComponent row={row} />
        }
      },
      {
        Header: 'Space',
        accessor: 'space',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <DamageCellComponent row={row} />
        }
      },
      {
        Header: 'Observation',
        accessor: 'observation',
        textAlign: 'left',
        width: 180,
        Cell: row => {
          return <DamageCellComponent row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'simplified_damage_required',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <DamageCellComponent row={row} />
        }
      }
    ]
  }
]
