import React from 'react'
import PropTypes from 'prop-types'
import { icons } from './icons'
import './index.scss'

const SyncModal = ({ onSave, onClose, inspectionSource }) => {
  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            You’re about to sync the inspection data from {inspectionSource}.
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              onClose()
            }}>
            <img alt="Delete Note" src={icons.close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="modal-sync-subtitle">Once a sync is kicked off, it cannot be undone and the data in Turnable will be updated.</p>
        </div>
      </div>
      
      <div className="columns">
        <div className="column is-full">
          <p className="modal-sync-message">Are you sure you want to pull the most<br/>current inspection data?</p>
          <p className="modal-sync-note">Note: This might take a few minutes to complete.</p>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              onClose()
            }}>
            No, Cancel
          </button>

          <button className="button main-button is-primary" onClick={onSave}>
            Yes, Sync
          </button>
        </div>
      </div>
    </div>
  )
}

SyncModal.propTypes = {
  inspectionSource: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default SyncModal
