import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import ComponentWithStatus from '../component-with-status'

/**
 * Property contact information box
 *
 * @component
 * @example
 * const property = {
 *   "name": "Syracuse",
 *   "id": "syracuse",
 *   "general_manager": "General Manager",
 *   "assistant_gm": "Assistant GM",
 *   "regional_manager": "Regional Manager",
 *   "address": {
 *     "address1": "Some Street 123",
 *     "address2": "",
 *     "address3": "",
 *     "city": "Chicago",
 *     "state": "IL",
 *     "zip": "12345",
 *     "country": "United States of America"
 *   },
 *   "phone": "(123) 456 7890",
 *   "email": "someone@here.com"
 * }
 * return (
 *   <PropertyContact
 *     loading={false}
 *     error={false}
 *     property={property}
 *   />
 * )
 */
const PropertyContact = ({ loading, error, property }) => {
  return (
    <div className="dashboard-card card-semi">
      <div className="inner-content">
        <ComponentWithStatus loading={loading} error={error}>
          <div className="columns">
            <div className="column is-full has-text-centered heading-5">
              Property Contacts
            </div>
          </div>
          <table className="small-text center-container">
            <tbody>
              <tr>
                <td className="is-narrow p-r-md">
                  {_.get(property, 'general_manager')} (M)
                </td>
                <td className="is-narrow">{_.get(property, 'phone')}</td>
              </tr>
              <tr>
                <td className="is-narrow p-r-md">
                  {_.get(property, 'assistant_gm')} (AM)
                </td>
                <td className="is-narrow">{_.get(property, 'phone')}</td>
              </tr>
              <tr>
                <td className="is-narrow p-r-md">
                  {_.get(property, 'regional_manager')} (GM)
                </td>
                <td className="is-narrow">{_.get(property, 'phone')}</td>
              </tr>
            </tbody>
          </table>
        </ComponentWithStatus>
      </div>
    </div>
  )
}

PropertyContact.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Property details
   */
  property: PropTypes.object
}

export default PropertyContact
