import moment from 'moment-timezone/moment-timezone'
import 'moment-timezone/builds/moment-timezone-with-data-2012-2022'
import Service from '../service'
import LOGOUT from './auth'

/**
 * Holdovers data module
 * @redux
 * @reduxActionScope holdovers
 * @module holdovers
 */

/**
 * Reset occupied state
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_OCCUPIED_RESET = 'holdovers/HOLDOVERS_OCCUPIED_RESET'

/**
 * Request occupied holdovers data
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_OCCUPIED_GET_REQUESTED =
  'holdovers/HOLDOVERS_OCCUPIED_GET_REQUESTED'
/**
 * Occupied holdovers data failed
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_OCCUPIED_GET_FAIL =
  'holdovers/HOLDOVERS_OCCUPIED_GET_FAIL'
/**
 * Update request occupied holdovers with successful data from API
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_OCCUPIED_GET_SUCCESS =
  'holdovers/HOLDOVERS_OCCUPIED_GET_SUCCESS'

/**
 * Request unoccupied holdovers data
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_UNOCCUPIED_GET_REQUESTED =
  'holdovers/HOLDOVERS_UNOCCUPIED_GET_REQUESTED'
/**
 * Unoccupied holdovers data failed
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_UNOCCUPIED_GET_FAIL =
  'holdovers/HOLDOVERS_UNOCCUPIED_GET_FAIL'
/**
 * Update request unoccupied holdovers with successful data from API
 * @type {Redux.ActionType}
 */
export const HOLDOVERS_UNOCCUPIED_GET_SUCCESS =
  'holdovers/HOLDOVERS_UNOCCUPIED_GET_SUCCESS'

const initialState = {
  occupied: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    list: []
  },
  unoccupied: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    list: []
  }
}

/**
 * Holdovers reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case HOLDOVERS_OCCUPIED_RESET:
      return initialState
    case HOLDOVERS_OCCUPIED_GET_REQUESTED:
      return {
        ...state,
        occupied: {
          ...state.occupied,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case HOLDOVERS_UNOCCUPIED_GET_REQUESTED:
      return {
        ...state,
        unoccupied: {
          ...state.unoccupied,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case HOLDOVERS_OCCUPIED_GET_SUCCESS:
      return {
        ...state,
        occupied: {
          ...state.occupied,
          isRequesting: false,
          isError: false,
          list: action.list
        }
      }
    case HOLDOVERS_UNOCCUPIED_GET_SUCCESS:
      return {
        ...state,
        unoccupied: {
          ...state.unoccupied,
          isRequesting: false,
          isError: false,
          list: action.list
        }
      }
    case HOLDOVERS_OCCUPIED_GET_FAIL:
      return {
        ...state,
        occupied: {
          ...state.occupied,
          isRequesting: false,
          isError: true
        }
      }
    case HOLDOVERS_UNOCCUPIED_GET_FAIL:
      return {
        ...state,
        unoccupied: {
          ...state.unoccupied,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export const resetHoldoversList = () => {
  return dispatch => {
    dispatch({
      type: HOLDOVERS_OCCUPIED_RESET
    })
  }
}

/**
 * Updates holdovers list data
 * @redux
 * @reduxActionCreator HOLDOVERS_OCCUPIED_GET_REQUESTED, HOLDOVERS_OCCUPIED_GET_SUCCESS, HOLDOVERS_OCCUPIED_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getHoldoversList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: HOLDOVERS_OCCUPIED_GET_REQUESTED
    })
    Service.getHoldoversOccupied(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const list = await res.json()
          dispatch({
            type: HOLDOVERS_OCCUPIED_GET_SUCCESS,
            list: _listAdapter(list)
          })
        } else {
          dispatch({
            type: HOLDOVERS_OCCUPIED_GET_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: HOLDOVERS_OCCUPIED_GET_FAIL
        })
      })
  }
}

/*export const getUnoccupiedList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: HOLDOVERS_UNOCCUPIED_GET_REQUESTED,
    })
    Service.getHoldoversUnoccupied(pdbid, context).then(async res => {
      if (res.ok) {
        const list = await res.json()
        dispatch({
          type: HOLDOVERS_UNOCCUPIED_GET_SUCCESS,
          list: _listAdapter(list),
        })
      } else {
        dispatch({
          type: HOLDOVERS_UNOCCUPIED_GET_FAIL,
        })
      }
    }).catch(() => {
      dispatch({
        type: HOLDOVERS_UNOCCUPIED_GET_FAIL,
      })
    })
  }
}*/

export const updateKey = (pdbid, context, unit, roomKey, status) => {
  // TODO
}

const _transformDate = date => {
  if (typeof date !== 'string') return ''
  if (date === 'Not Returned' || date === '') return date
  const mDate = moment.utc(date)
  const formatted = mDate.tz('America/Chicago').format('MM/DD/YYYY hh:mm:ss A')
  return formatted
}

// TODO: Additional needed actions
const _oneEntryAdapter = data => {
  return {
    ...data,
    holdover_date: _transformDate(data.holdover_date),
    returned_date: _transformDate(data.returned_date)
  }
}

const _listAdapter = data => {
  return Array.isArray(data) ? data.map(d => _oneEntryAdapter(d)) : []
}
