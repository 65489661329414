import React, { useMemo } from 'react'

const options = [
  { value: 'Not Started', text: 'Not Started' },
  { value: 'In Progress', text: 'In Progress' },
  { value: 'Completed', text: 'Completed' },
  { value: 'Not Needed', text: 'Not Needed' },
  { value: 'Pending Walk', text: 'Pending Walk' },
  { value: 'In House', text: 'In House' }
]

const OverviewColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column || {}

  const selectedFilterValue = useMemo(() => {
    if (filterValue && Array.isArray(filterValue)) {
      if (filterValue.includes('Not Needed')) {
        return 'Not Needed'
      }
      return filterValue[0]
    }
    return ''
  }, [filterValue])

  const handleFilter = ({ currentTarget: input }) => {
    if (input.value === 'Not Needed') {
      setFilter([input.value, 'As Is', 'Renewal', 'N/A'])
    } else {
      setFilter([input.value])
    }
  }

  return (
    <div className="select is-fullwidth">
      <select
        className="input"
        name="selectedGroup"
        value={selectedFilterValue}
        onChange={handleFilter}>
        <option value="">Choose Option</option>
        {options.map(({ text, value }) => (
          <option value={value} key={value}>
            {text}
          </option>
        ))}
      </select>
    </div>
  )
}

export default OverviewColumnFilter
