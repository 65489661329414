import React from 'react'
import PropTypes from 'prop-types'
import ComponentWithStatus from '../component-with-status'

/**
 * Component for showing the beds turned box
 *
 * @component
 * @example
 * return (
 *   <BedsTurned
 *     loading={false}
 *     error={false}
 *     turned={150}
 *     left={50}
 *     noTurn={100}
 *   />
 * )
 */
const BedsTurned = ({ loading, error, turned, left, noTurn }) => {
  return (
    <div className="dashboard-card card-sm">
      <ComponentWithStatus loading={loading} error={error}>
        <div className="inner-content has-text-centered">
          <div className="columns">
            <div className="column is-half with-divider">
              <p className="heading-3">{turned}</p>
              <p className="small-text">Spaces Turned</p>
            </div>
            <div className="column is-half">
              <p className="heading-3">{left}</p>
              <p className="small-text">Spaces Left</p>
            </div>
          </div>
          <div className="columns">
            <div className="column dashboard-message">
              <p className="small-text">{noTurn} Spaces - No Turn Needed</p>
            </div>
          </div>
        </div>
      </ComponentWithStatus>
    </div>
  )
}

BedsTurned.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Number of beds turned
   */
  turned: PropTypes.number,
  /**
   * Number of beds left to turn
   */
  left: PropTypes.number,
  /**
   * Number of beds that need no turn
   */
  noTurn: PropTypes.number
}

export default BedsTurned
