import React from 'react'
import { Link } from 'react-router-dom'

const NameColumn = ({ row }) => {
  const { cell } = row || {}
  const { value } = cell || {}

  return (
    <div className="cell-item" title={value}>
      {value}
    </div>
  )
}

export default NameColumn
