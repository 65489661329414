import _, { cloneDeep } from 'lodash'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import cn from 'classnames'
import Short from 'short-uuid'
import { DateUtils } from 'react-day-picker/DayPicker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { usePrevious } from '../../helpers/hooks'
import Breadcrumbs from '../../parents/breadcrumbs'
import ServicesStats from '../services-stats'
import ServicesModalAssign from '../services-modal-assign'
import ServicesModalReassign from '../services-modal-reassign'
import ServicesModalReturn from '../services-modal-return'
import ServicesModalManage from '../services-modal-manage'
import ToggleBlockUnits from './ToggleBlockUnits'
import { closeFilled } from 'ui/icons'
// TODO 007:
// Mark complete - commented, but would like to finish this for Turn 2022
// import ServicesModalMarkComplete from '../services-modal-mark-complete'
import ServicesUnblock from '../services-modal-unblock'
// TODO 006:
// View History - Nice to have feature in the future
// import ServicesModalHistory from '../services-modal-history'
import DataTable from '../../components/data-table'
import { getHeaderData, getOutsideFilters } from './header-data'
import { customFilters } from './custom-filters'
import { PHASES, SERVICES } from './constants'
import Notes from '../../containers/notes'
import { makeQueryString } from '../../extendables/utility-component'
import RestrictedElement from '../../containers/restricted-element'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { servicesIcons } from './services-icons'
import { calendar, print } from 'ui/icons'
import { numberOfDays } from '../../utils'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../utils/calendarInputHelper'

import './index.scss'
import PermissionGate from '../../components/permission-gate'
import ConfirmationModal from '../../components/confirmation-modal'
import { useModals } from 'hooks'
import ServicesModalApproveAll from '../services-modal-approve-all'
import { usePermissionGate } from '../../helpers/hooks'

const MODAL_WIDTH = '500px'
const darkBlue = [17, 32, 72]
const lightGrey = [193, 197, 201, 0.3]

const ServicesMain = ({
  location,
  services,
  setCurrentService,
  setCurrentPhase,
  history,
  user,
  markCompleteService,
  unblockService,
  updateServices,
  getUnitHistory,
  resetUnitHistory,
  openModal,
  setModalContent,
  closeModal,
  modal,
  updateNotes,
  context,
  responsive,
  openTooltip,
  setTooltipContent,
  closeTooltip,
  tooltip
}) => {
  const property = _.find(
    _.get(user.user_metadata, 'properties', []),
    p => p.id === _.get(context, 'property')
  )
  const [showConfirmationModal, dismissConfirmationModal] = useModals()
  const { hasPermission } = usePermissionGate('walk-assign-button')

  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)
    return {
      s: newParams.get('s') || SERVICES[0].key,
      p: newParams.get('p') || PHASES[0].key
    }
  }, [location])

  const showReprintOption = () => {
    const { currentPhase } = services
    return (
      currentPhase !== 'Assign' &&
      currentPhase !== 'Start' &&
      currentPhase !== 'AssignQC' &&
      currentPhase !== 'Approved' &&
      !currentPhase?.toLowerCase()?.includes('blocked')
    )
  }

  const showUnBlockToggle = () => {
    const { currentPhase } = services
    const isUnitsBlocked = (filteredList || []).filter(unit => unit.is_blocked)
    if (!isUnitsBlocked.length) return false
    return currentPhase === 'Assign' || currentPhase === 'Start'
  }

  const uuid = useMemo(() => Short.uuid(), [params])
  const [checked, setChecked] = useState([])
  const [resetChecked, setResetChecked] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [currentSort, setCurrentSort] = useState(['unit'])
  const [currentAction, setCurrentAction] = useState(null)
  const [tableRows, setTableRows] = useState([])
  const [teamURI, setTeamURI] = useState(null)
  const [isBlockUnitEnabled, setIsBlockUnitEnabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const tableRef = useRef()
  const [
    scheduleFiltersDropdownOpen,
    setScheduleFiltersDropdownOpen
  ] = useState(false)
  const [dates, setDates] = useState({ from: new Date(), to: new Date() })
  const [outsideFilters, setOutsideFilters] = useState({})
  const modifiers = {
    start: dates.from,
    end: dates.to,
    past: { before: new Date(dates.from) },
    pastSelection: { before: dates.from },
    weekS: { daysOfWeek: [1] },
    weekE: { daysOfWeek: [0] },
    firstOfMonth: day => day.getDate() === 1,
    lastOfMonth: day => day.getDate() === numberOfDays(null, day.getMonth() + 1)
  }

  const prevServices = usePrevious(services)
  const prevLocation = usePrevious(location)
  const outsideFiltersRef = useDetectClickOutside({
    onTriggered: () => setScheduleFiltersDropdownOpen(false)
  })
  let datePickerRef = null

  useEffect(() => resetService(), [])

  useEffect(() => setIsBlockUnitEnabled(isBlockUnitEnabled), [
    isBlockUnitEnabled
  ])

  useEffect(() => {
    setOutsideFilters(
      getOutsideFilters({ from: new Date(), to: new Date() }, true)
    )
  }, [property])

  useEffect(() => {
    if (scheduleFiltersDropdownOpen)
      datePickerRef && datePickerRef.hideDayPicker()
  }, [scheduleFiltersDropdownOpen])

  useEffect(() => {
    if (isBlockUnitEnabled) {
      setIsBlockUnitEnabled(false)
    }
    const {
      updateIsRequesting,
      updateIsError,
      currentPhase,
      currentService
    } = services
    const phaseChanged = _.get(prevServices, 'currentPhase') !== currentPhase
    const serviceChanged =
      _.get(prevServices, 'currentService') !== currentService
    const { search } = location
    const searchChanged = _.get(prevLocation, 'search') !== search
    if (searchChanged) resetService()
    const madeSuccessfulUpdate =
      updateIsRequesting === false &&
      updateIsRequesting !== _.get(prevServices, 'updateIsRequesting') &&
      !updateIsError
    if (madeSuccessfulUpdate || phaseChanged || serviceChanged) {
      setCurrentAction(null)
      setResetChecked(true)
    }
    if (resetChecked === true) {
      setChecked([])
      setResetChecked(false)
    }

    if (!services.updateIsRequesting && services.updateIsError) {
      setCurrentAction(null)
    }
  }, [services, location])

  const handleDayClick = day => {
    day.setHours(0, 0, 0)
    const range = DateUtils.addDayToRange(day, dates)

    setOutsideFilters(getOutsideFilters({ from: range.from, to: range.to }))
    setOutsideFilters(outsideFilters => {
      return {
        ...outsideFilters,
        custom: {
          ...outsideFilters['custom'],
          active: true
        }
      }
    })

    if (!range.from && !range.to) {
      const timeDates = services.list
        .filter(item => item.scheduled_date)
        .map(item => new Date(item.scheduled_date).getTime())

      const fromDate = timeDates.length ? Math.min(...timeDates) : null
      const toDate = timeDates.length ? Math.max(...timeDates) : null
      setDates({ from: new Date(fromDate), to: new Date(toDate) })

      return
    }

    setDates(range)
  }

  const resetService = () => {
    setCurrentService(params.s)
    setCurrentPhase(params.p)
  }

  const getTopButton = (text, action) => {
    const isDisabled = checked.length > 0 ? '' : 'disabled'
    return (
      <button
        className="button is-secondary m-r-none main-button"
        onClick={e => {
          setCurrentAction(text)
          action(e)
        }}
        disabled={isDisabled}>
        {getButtonContent(text, text)}
      </button>
    )
  }

  const getTableTitle = () => {
    const { currentPhase } = services
    const phase = PHASES.find(p => p.key === currentPhase)
    const makeBorder =
      typeof phase.display.table === 'string' ? 'is-borderless' : ''
    return (
      <div className="columns">
        <div className="column tabs is-boxed">
          <ul className={['heading-5', 'm-b-sm', makeBorder].join(' ')}>
            {getDisplayTable(phase.display.table)}
          </ul>
        </div>
      </div>
    )
  }

  const getButtonContent = (name, text) => {
    if (name === currentAction) {
      return <FontAwesomeIcon icon={faSpinner} spin />
    }
    return text
  }

  const getButtonsSection = () => {
    const { currentPhase, updateIsRequesting, currentService } = services
    const phase = PHASES.find(p => p.key === currentPhase)

    const hideManageButton =
      currentService === 'Walk' ||
      currentService === 'Punch' ||
      currentService === 'Sparkle' ||
      currentService === 'Holdover'

    let buttonIsDisabled = false

    if (updateIsRequesting || !checked.length) {
      buttonIsDisabled = true
    } else if (checked.length > 1 && currentPhase === 'Approve') {
      buttonIsDisabled = true
    }

    const buttonIsHidden =
      (hideManageButton && currentPhase === 'Approve') ||
      currentPhase === 'Approved' ||
      currentPhase === 'Blocked' ||
      currentPhase === 'notAssignedBlocked'
        ? 'is-hidden'
        : ''
    // const reprintButton =
    //   currentPhase === 'InProgress' || currentPhase === 'Approve'
    //     ? getTopButton('Re-print', () => exportToPDF())
    //     : null
    const approveAllButton =
      currentPhase === 'Approve' ? (
        <button
          className="button is-primary m-r-none main-button"
          onClick={() => bulkUpdate('bulk_approve')}
          disabled={!checked.length}>
          {checked.length > 1 ? 'Approve All' : 'Approve'}
        </button>
      ) : null

    const returnButton =
      currentPhase !== 'Assign'
        ? getTopButton('Return', () => bulkUpdate('bulk_return'))
        : null
    const reassignButton =
      currentPhase === 'Start'
        ? getTopButton('Reassign', () => bulkUpdate('Reassign'))
        : null

    const customPickerInput = React.forwardRef((props, ref) => {
      const value =
        dates.from && dates.to
          ? `${dateFnsFormat(dates.from, 'MM/dd')} - ${dateFnsFormat(
              dates.to,
              'MM/dd'
            )}`
          : props.value
      return (
        <input
          {...props}
          value={value}
          ref={ref}
          id="datepicker-input"
          readOnly
        />
      )
    })

    const getActiveScheduledFilterLabel = () => {
      const activeFilter = Object.keys(outsideFilters).find(
        filter => outsideFilters[filter]?.active
      )
      return outsideFilters[activeFilter]?.label
    }

    return (
      services.listHasData &&
      !services.listIsRequesting && (
        <div className="columns is-vcentered">
          <div
            className="column p-r-xs is-flex is-narrow action-container"
            style={{ alignItems: 'center' }}>
            <RestrictedElement>
              <PermissionGate name={'walk-assign-button'}>
                {approveAllButton !== null && (
                  <div style={{ marginRight: '10px' }}>{approveAllButton}</div>
                )}
                <button
                  className={[
                    'button',
                    currentPhase === 'Approve' ? 'is-secondary' : 'is-primary',
                    'main-button',
                    // 'm-r-none',
                    buttonIsHidden
                  ].join(' ')}
                  style={{ marginRight: '10px' }}
                  disabled={buttonIsDisabled}
                  onClick={() => {
                    setCurrentAction('main')
                    if (phase?.display?.button === 'Start') {
                      const {
                        user_metadata: { pdbid }
                      } = user
                      const rows = currentList
                        .filter(row => checked.indexOf(row.unit) !== -1)
                        .filter(c_unit => c_unit.is_blocked === true)
                      if ((rows || []).length) {
                        const operationUris = rows
                          .map(row => row.operation_uri)
                          .join(',')
                        const body = {
                          operation_uri: operationUris
                        }
                        unblockService(
                          pdbid,
                          context,
                          currentService,
                          body,
                          status => {
                            if (status) {
                              bulkUpdate()
                            }
                          },
                          status => {
                            setErrorMessage(
                              'Something went wrong. Please try again later !!!'
                            )
                          }
                        )
                      } else {
                        bulkUpdate()
                      }
                    } else {
                      bulkUpdate()
                    }
                  }}>
                  {getButtonContent('main', phase.display.button)}
                </button>
                {returnButton != null && (
                  <div style={{ marginRight: '10px' }}>{returnButton}</div>
                )}
                {reassignButton !== null && (
                  <div style={{ marginRight: '10px' }}>{reassignButton}</div>
                )}
              </PermissionGate>
            </RestrictedElement>
            {/* {reprintButton != null && (
              <div className="column is-narrow p-r-xs">{reprintButton}</div>
            )} */}

            <div className="calendar-container">
              <div
                className={`dropdown filter-dropdown ${
                  scheduleFiltersDropdownOpen ? 'is-active' : ''
                }`}
                id="filter-dropdown"
                ref={outsideFiltersRef}>
                <div className="dropdown-trigger">
                  <button
                    className="button m-r-md is-secondary"
                    type="button"
                    aria-haspopup={true}
                    aria-controls="filter-options"
                    onClick={() =>
                      setScheduleFiltersDropdownOpen(
                        !scheduleFiltersDropdownOpen
                      )
                    }>
                    <img src={calendar} alt="Filter data" className="m-r-xs" />:{' '}
                    {Object.keys(outsideFilters).length &&
                      getActiveScheduledFilterLabel()}
                  </button>
                </div>
                <div className="dropdown-menu" role="menu" id="filter-options">
                  <div className="dropdown-content has-text-left">
                    {Object.keys(outsideFilters).map((filter, index) => (
                      <button
                        className={cn(
                          'button filter-button',
                          { 'is-primary': outsideFilters?.[filter]?.active },
                          {
                            'is-secondary': !outsideFilters?.[filter]?.active
                          }
                        )}
                        onClick={() => {
                          const currentActiveValue =
                            outsideFilters[filter]['active']
                          setOutsideFilters(
                            getOutsideFilters({
                              from: dates.from,
                              to: dates.to
                            })
                          )
                          setOutsideFilters(outsideFilters => {
                            return {
                              ...outsideFilters,
                              [filter]: {
                                ...outsideFilters[filter],
                                active: !currentActiveValue
                              }
                            }
                          })
                          setScheduleFiltersDropdownOpen(false)
                        }}
                        key={`custom-filter-${index}`}>
                        {outsideFilters[filter]['icon'] && (
                          <img
                            src={outsideFilters[filter]['icon']}
                            alt={outsideFilters[filter]['label']}
                            className="p-r-md"
                            style={{ width: 34 }}
                          />
                        )}
                        {outsideFilters[filter]['label']}
                      </button>
                    ))}
                    <div
                      style={{
                        borderRadius: 4,
                        paddingLeft: '1em',
                        paddingRight: '1em',
                        paddingBottom: 'calc(0.5em - 1px)',
                        background:
                          Object.keys(outsideFilters).length &&
                          outsideFilters['custom']['active']
                            ? '#3cb3e2'
                            : 'inherit'
                      }}>
                      <DayPickerInput
                        onDayChange={handleDayClick}
                        dayPickerProps={{
                          modifiers: modifiers,
                          firstDayOfWeek: calendarConfig.firstDayOfWeek,
                          weekdaysShort: calendarConfig.weekdaysShort,
                          selectedDays: [
                            dates.from,
                            { from: dates.from, to: dates.to }
                          ],
                          month: new Date(dates.from)
                        }}
                        hideOnDayClick={false}
                        formatDate={formatDate}
                        format={'MM/dd'}
                        parseDate={parseDate}
                        placeholder={`${dateFnsFormat(
                          new Date(),
                          'MM/dd'
                        )} - ${dateFnsFormat(new Date(), 'MM/dd')}`}
                        component={customPickerInput}
                        ref={ref => (datePickerRef = ref)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }

  const bulkUpdate = (action = 'primary') => {
    setErrorMessage('')
    const {
      user_metadata: { pdbid }
    } = user
    const { currentPhase, list } = services || {}
    const currentService = (services || {})?.currentService?.replace('/', '%20')
    if (action === 'primary') {
      const phase = PHASES.find(p => p.key === currentPhase)
      action = phase.actions[0].key
    }
    const rows = list.filter(row => checked.indexOf(row.unit) !== -1)
    const operationUris = rows.map(row => row.operation_uri).join(',')
    let body = {}
    switch (action) {
      case 'Start':
        setTableRows(rows)
      case 'InProgress':
        body = {
          ...body,
          operation_uri: operationUris
        }
        updateServices(
          pdbid,
          context,
          currentService,
          action,
          body,
          () => setErrorMessage(''),
          () =>
            setErrorMessage('Something went wrong. Please try again later !!!')
        )
        break
      case 'Assign':
      case 'AssignQC':
      case 'Reassign':
      case 'bulk_return':
      case 'Approve':
      case 'bulk_approve':
        showModal(action, rows)
        break
      default:
        break
    }
  }

  const exportToCSV = () => {
    const { list, currentService } = services
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const columns = _.map(currentSort, s => s.replace(/^-/, ''))
    const orders = _.map(currentSort, s => (s[0] === '-' ? 'desc' : 'asc'))

    const {
      user_metadata: { properties, projects }
    } = user
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }

    const allDynamicServices = [...(serviceColumns || [])]

    if (
      (serviceColumns || []).find(
        ({ unitspace }) => unitspace?.toLowerCase() !== 'common'
      )
    ) {
      allDynamicServices.unshift({ unitspace: 'Common' })
    }

    const getFilteredData =
      (tableRef?.current?.getCheckRows() || []).length === 0
        ? tableRef?.current?.getRows() || []
        : tableRef?.current?.getCheckRows() || []

    const rowsByTeam = _.orderBy(getFilteredData, columns, orders)

    const rows = rowsByTeam.map(row => {
      const mappedRow = [
        row.unit || '',
        row.grouping || 'N/A',
        row.scheduled_date || 'N/A',
        row.type || '',
        ...(allDynamicServices || []).map(
          ({ unitspace }) => row[unitspace || '']?.display
        ),
        row?.team_member?.replace(/,/g, '')
      ]

      return mappedRow
    })

    rows.unshift([
      'Unit',
      'Group',
      'Type',
      ...(allDynamicServices || []).map(({ unitspace }) => unitspace),
      'Vendor/Team'
    ])

    rows[0].splice(2, 0, phase.key !== 'Approved' ? 'Scheduled' : 'Completed')

    const csvContent = [
      'data:text/csv;charset=utf-8,',
      rows.map(e => e.join(',')).join('\n')
    ].join('')
    const encodedUri = encodeURI(csvContent).replace(/#/g, '%23')
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    const filename = [
      names.project,
      names.property,
      currentService,
      datetime
    ].join('-')
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const addDetailedBedroomToPDF = (pivot, unit, bedroom, bedroomLabel) => {
    if (bedroom.display !== '') {
      pivot.push([
        unit,
        bedroomLabel.toUpperCase(),
        bedroom.name || '',
        bedroom.phone || '',
        bedroom.email || ''
      ])
    }
  }

  const exportToPDF = (rows = [], team_uri) => {
    const { user_metadata } = user
    const {
      list,
      currentService,
      currentPhase,
      teams,
      serviceColumns
    } = services
    if (
      currentPhase !== 'Start' &&
      currentPhase !== 'InProgress' &&
      currentPhase !== 'Approve' &&
      currentPhase !== 'AssignQC'
    ) {
      return
    }

    const serviceWithoutSpaces = currentService?.replaceAll(' ', '')
    const service = SERVICES.find(s => s.key === serviceWithoutSpaces)
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const {
      user_metadata: { properties, projects }
    } = user
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    let serviceLabel = service?.display

    if (
      currentService === 'CarpetReplace' &&
      _.get(context, 'property', null)
    ) {
      const userProperty = _.find(
        _.get(user_metadata, 'properties', []),
        p => p.id === _.get(context, 'property')
      )
      if (userProperty) {
        serviceLabel = _.get(userProperty, 'cr_label', serviceLabel)
      }
    }
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    if (currentPhase === 'InProgress' || currentPhase === 'Approve') {
      rows = checked.map(c => list.find(l => l.unit === c))
    }
    const teamsSelected =
      currentPhase !== 'AssignQC'
        ? teams.filter(t => rows.find(c => c.team_uri === t.uri))
        : teams.filter(t => t.uri === team_uri)

    // const sortedTeamsSelected = []

    // rows.forEach(row => {
    //   if (!sortedTeamsSelected.find(t => t.uri === row.team_uri)) {
    //     const team = teamsSelected.find(t => row.team_uri === t.uri)
    //     if (team) {
    //       sortedTeamsSelected.push(team)
    //     }
    //   }
    // })

    const spaceCount = (serviceColumns || []).length + 1

    let doc = new jsPDF({ orientation: spaceCount <= 6 ? 'p' : 'l' })

    for (let i = 0; i < teamsSelected.length; i += 1) {
      let yOffset = 0
      if (i > 0) doc.addPage()
      const ts = teamsSelected[i]
      //doc.addImage('scion-with-turnable.png', 'PNG', 15, 40, 180, 180)
      doc.setTextColor(darkBlue[0], darkBlue[1], darkBlue[2])
      doc.setFontSize(26)
      yOffset += 20
      doc.text(`Work Order - ${project.name}`, 20, yOffset)

      yOffset += 13
      doc.setFontSize(16)
      doc.text(`Team: ${ts.member}`, 20, yOffset)

      const columns = _.map(currentSort, s => s.replace(/^-/, ''))
      const orders = _.map(currentSort, s => (s[0] === '-' ? 'desc' : 'asc'))

      const rowsByTeam = _.orderBy(
        currentPhase !== 'AssignQC'
          ? rows.filter(row => row.team_uri === ts.uri)
          : rows,
        columns,
        orders
      )

      doc.setFontSize(12)
      doc.text(property.name, spaceCount > 6 ? 283 : 200, yOffset, {
        align: 'right'
      })

      yOffset += 7
      doc.setFontSize(12)
      doc.text(`Service: ${serviceLabel}`, 20, yOffset)

      doc.setFontSize(12)
      doc.text(
        moment().format('MM/DD/YYYY'),
        spaceCount > 6 ? 283 : 200,
        yOffset,
        { align: 'right' }
      )

      const notesRows = []
      const unitRows = []

      const getSpaceRowLabel = row => {
        const { is_goback, tooltip } = row || {}
        if (is_goback) {
          return `${tooltip}\nGO-BACK`
        }

        return tooltip || ''
      }

      // let newPageOffset = 20
      const shouldShowVendor =
        currentPhase === 'Approve' || currentPhase === 'AssignQC'

      let head = [
        [
          'Unit #',
          'Common Area',
          ...(serviceColumns || []).map(column => {
            const { unitspace } = column || {}
            return `Bed ${unitspace}`
          })
        ]
      ]

      if (shouldShowVendor) {
        head[0].splice(1, 0, 'Team')
      }

      const body = []

      _.forEach(rowsByTeam, (row, i) => {
        const spaceLabels = (serviceColumns || []).map(column => {
          const { unitspace } = column || {}
          return getSpaceRowLabel(row[unitspace])
        })

        const unit = `${row.unit}\n${row?.type}`

        const bodyRow = [unit, getSpaceRowLabel(row.Common), ...spaceLabels]

        if (shouldShowVendor) {
          bodyRow.splice(1, 0, row?.qc_team_member)
        }

        body.push(bodyRow)

        _.set(unitRows, notesRows.length + i, i)

        if (
          (row.vendor_notes && row.vendor_notes !== '') ||
          (row.service_notes && row.service_notes !== '')
        ) {
          const lines = []
          if (row.vendor_notes && row.vendor_notes !== '') {
            lines.push(row.vendor_notes)
          }
          if (row.service_notes && row.service_notes !== '') {
            lines.push(row.service_notes)
          }
          body.push([
            {
              content: lines.join('\n'),
              colSpan: head[0].length,
              styles: { halign: 'left' }
            }
          ])
          notesRows.push(notesRows.length + i + 1)
        }
      })

      yOffset += 8

      if (spaceCount > 8) {
        doc.autoTable({
          html: `.table-pdf${i}`,
          margin: { top: yOffset, left: 20 },
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          theme: 'plain',

          styles: {
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.2,
            lineColor: darkBlue,
            minCellWidth: 22,
            minCellHeight: 12,
            textColor: darkBlue
          },

          didParseCell: data => {
            const notesData = data?.cell?.raw?.classList?.contains('notes')

            if (!notesData) {
              let color = [255, 255, 255]
              const textColor = darkBlue
              const isHeader = data?.cell?.raw?.classList?.contains('th')
              const hasNoSpace = data?.cell?.raw?.classList?.contains(
                'no-space'
              )

              if (isHeader) {
                data.cell.styles.fontStyle = 'bold'
              }

              if (data.cell.colSpan === 2) {
                data.cell.styles.lineWidth = 0
              }

              if (
                (data.cell.raw?.innerText === 'Occupied' ||
                  data.cell.raw?.innerText === 'Renewal' ||
                  data.cell.raw?.innerText === 'N/A') &&
                data.row.section !== 'head'
              ) {
                color = [225, 230, 239]
              }

              data.cell.styles.fillColor = color
              data.cell.styles.textColor = textColor

              if (hasNoSpace) {
                data.cell.styles.fillColor = darkBlue
              }

              if (data?.cell?.text[0]?.includes('GO-BACK')) {
                data.cell.styles.textColor = [222, 101, 95]
              }
            } else {
              data.cell.styles.halign = 'left'
            }
          }
        })
      } else {
        doc.autoTable({
          head,
          body,
          margin: { top: yOffset, left: 20 },
          theme: 'plain',
          styles: {
            halign: 'center',
            valign: 'middle',
            lineWidth: 0.2,
            lineColor: darkBlue,
            minCellWidth: 22,
            minCellHeight: 12,
            textColor: darkBlue
          },
          didParseCell: data => {
            const notIsNotesRow = notesRows.indexOf(data?.row?.index) === -1

            if (notIsNotesRow) {
              let color = [255, 255, 255]
              const textColor = darkBlue

              if (
                (data.cell.raw === 'Occupied' ||
                  data.cell.raw === 'Renewal' ||
                  data.cell.raw === 'N/A') &&
                data.row.section !== 'head'
              ) {
                color = [225, 230, 239]
              }

              data.cell.styles.fillColor = color
              data.cell.styles.textColor = textColor

              if (data.row.section !== 'head' && !data.cell.raw) {
                data.cell.styles.fillColor = darkBlue
              }

              if (data.cell.text[1] === 'GO-BACK') {
                data.cell.styles.textColor = [222, 101, 95]
              }
            }
          }
        })
      }

      yOffset = doc.lastAutoTable.finalY + 8

      // if (service?.detailBedrooms) {
      //   const detailsHead = [['Unit #', 'Bed', 'Name', 'Phone', 'Email']]

      //   const detailsBody = []
      //   rowsByTeam.forEach(row => {
      //     addDetailedBedroomToPDF(detailsBody, row.unit, row.A1, 'A1')
      //     addDetailedBedroomToPDF(detailsBody, row.unit, row.B, 'B')
      //     addDetailedBedroomToPDF(detailsBody, row.unit, row.C, 'C')
      //     addDetailedBedroomToPDF(detailsBody, row.unit, row.D, 'D')
      //     addDetailedBedroomToPDF(detailsBody, row.unit, row.E, 'E')
      //   })

      //   doc.autoTable({
      //     head: detailsHead,
      //     body: detailsBody,
      //     startY: yOffset,
      //     // margin: { top: yOffset, left: 20 },
      //     theme: 'plain',
      //     styles: {
      //       halign: 'center',
      //       valign: 'middle',
      //       lineWidth: 0.2,
      //       lineColor: darkBlue,
      //       minCellWidth: 22,
      //       minCellHeight: 12,
      //       textColor: darkBlue
      //     },
      //     didParseCell: data => {
      //       const color = data.cell.raw === '' ? darkBlue : [255, 255, 255]
      //       data.cell.styles.fillColor = color
      //     }
      //   })

      //   yOffset = doc.lastAutoTable.finalY + 8
      // }
      yOffset += 7
      // newPageOffset = 20

      const remainingSpace = Math.abs(
        doc?.internal?.pageSize?.height - doc?.lastAutoTable?.finalY
      )

      if (remainingSpace < 73) {
        yOffset = 10
        doc.addPage()
      }

      doc.setFontSize(12)
      doc.setDrawColor(darkBlue[0], darkBlue[1], darkBlue[2])
      doc.text('Notes:', 20, yOffset)
      doc.setLineWidth(0.3)
      doc.line(20, yOffset + 4, spaceCount > 6 ? 283 : 195, yOffset + 4)
      doc.line(
        spaceCount > 6 ? 283 : 195,
        yOffset + 4,
        spaceCount > 6 ? 283 : 195,
        yOffset + 33
      )
      doc.line(spaceCount > 6 ? 283 : 195, yOffset + 33, 20, yOffset + 33)
      doc.line(20, yOffset + 33, 20, yOffset + 4)

      doc.text('Crew Name:', 20, yOffset + 40)
      doc.line(44, yOffset + 40, 95, yOffset + 40)
      doc.text('Signature:', spaceCount > 6 ? 213 : 120, yOffset + 40)
      doc.line(
        spaceCount > 6 ? 233 : 140,
        yOffset + 40,
        spaceCount > 6 ? 283 : 195,
        yOffset + 40
      )
    }

    const filename = [
      names.project,
      names.property,
      currentService,
      datetime
    ].join('-')
    doc.save(`${filename}.pdf`)
  }

  useEffect(() => {
    if (!_.isEmpty(tableRows) || !_.isEmpty(teamURI)) {
      exportToPDF(tableRows, teamURI)
    }
  }, [tableRows, teamURI])

  const onCloseSecondaryModal = persist => {
    if (!persist) {
      setCurrentAction(null)
    }
    closeModal()
  }

  const showModal = (action, rows) => {
    let template = null
    let width = action === 'bulk_return' ? '600px' : '500px'

    const blockedRows = (currentList || [])
      .filter(row => checked.indexOf(row.unit) !== -1)
      .filter(c_unit => c_unit.is_blocked === true)
    const operationUris = (blockedRows || [])
      .map(row => row.operation_uri)
      .join(',')
    const blockedUnitBody = {
      operation_uri: operationUris
    }
    switch (action) {
      case 'Assign':
      case 'AssignQC':
        template = (
          <ServicesModalAssign
            user={user}
            services={services}
            updateServices={updateServices}
            closeModal={onCloseSecondaryModal}
            setChecked={setChecked}
            setIsBlockUnitEnabled={setIsBlockUnitEnabled}
            action={action}
            rows={rows}
            modal={modal}
            unblockService={unblockService}
            context={context}
            blockedUnitBody={blockedUnitBody}
            onAccepted={team_uri => {
              setTableRows(rows)
              setTeamURI(team_uri)
            }}
          />
        )
        break
      case 'Reassign':
        template = (
          <ServicesModalReassign
            user={user}
            services={services}
            updateServices={updateServices}
            closeModal={onCloseSecondaryModal}
            action={action}
            rows={rows}
            modal={modal}
            context={context}
            unblockService={unblockService}
            blockedUnitBody={blockedUnitBody}
            setChecked={setChecked}
            setIsBlockUnitEnabled={setIsBlockUnitEnabled}
          />
        )
        break
      case 'bulk_return':
        template = (
          <ServicesModalReturn
            user={user}
            services={services}
            updateServices={updateServices}
            closeModal={onCloseSecondaryModal}
            currentPhase={currentPhase}
            action={action}
            rows={rows}
            modal={modal}
            context={context}
          />
        )
        break
      case 'Approve':
        template = (
          <ServicesModalManage
            user={user}
            services={services}
            updateServices={updateServices}
            closeModal={onCloseSecondaryModal}
            action={action}
            rows={rows}
            modal={modal}
            context={context}
          />
        )
        break
      case 'bulk_approve':
        template = (
          <ServicesModalApproveAll
            user={user}
            servicesData={services}
            updateServices={updateServices}
            closeModal={onCloseSecondaryModal}
            action={action}
            rows={rows}
            modal={modal}
            context={context}
          />
        )
      default:
        break
    }
    openModal({ width, additionalBodyClasses: 'dsds' })
    setModalContent(() => template)
  }

  const checkboxClicked = checked => setChecked(checked)

  const getDisplayTable = display => {
    const { currentService } = services
    let template = null
    if (typeof display === 'string') {
      template = (
        <div className="columns">
          <div className="column is-full">{display}</div>
        </div>
      )
    } else if (Array.isArray(display)) {
      template = []
      for (let i = 0; i < display.length; i += 1) {
        const d = display[i]
        let style = {
          borderTop: 0
        }
        if (i === 0) style.borderLeft = 0
        const isActive = d.selected === true ? 'is-active' : ''
        template.push(
          <div className={isActive} key={`table-display-${i}`}>
            <Link
              to={`/services?s=${currentService}&p=${d.goTo}`}
              style={style}>
              <div
                className={[
                  'table-head-tab',
                  'is-inline',
                  'has-text-centered',
                  isActive
                ].join(' ')}>
                {d.display}
              </div>
            </Link>
          </div>
        )
      }
    }
    return template
  }

  const openNotes = row => {
    const { currentService } = services
    setModalContent(() => (
      <Notes
        unitServiceNotes={true}
        unit={row.unit}
        unitId={row.unit_id}
        service={currentService}
        serviceTypeId={row.servicetype_id}
        onClose={() => closeModal()}
        onSuccess={() => {
          closeModal()
          updateNotes()
        }}
      />
    ))
    openModal({ width: MODAL_WIDTH })
  }

  // TODO 007:
  // Mark complete - commented, but would like to finish this for Turn 2022
  // const markCompleteModal = row => {
  //   setModalContent(() => (
  //     <ServicesModalMarkComplete
  //       context={context}
  //       services={services}
  //       unit={row}
  //       markCompleteService={markCompleteService}
  //       user={user}
  //       onClose={() => {
  //         setModalContent(null)
  //         closeModal()
  //       }}
  //       onSave={() => {
  //         setModalContent(null)
  //         closeModal()
  //       }}
  //     />
  //   ))
  //   openModal({ width: MODAL_WIDTH })
  // }

  const unblockModal = row => {
    setModalContent(() => (
      <ServicesUnblock
        context={context}
        services={services}
        unit={row}
        unblockService={unblockService}
        user={user}
        onClose={() => {
          setModalContent(null)
          closeModal()
        }}
        onSave={() => {
          setModalContent(null)
          closeModal()
        }}
      />
    ))
    openModal({ width: MODAL_WIDTH })
  }

  const handleOpenReturnModal = (rows, action) => {
    setModalContent(() => (
      <ServicesModalReturn
        user={user}
        services={services}
        updateServices={updateServices}
        closeModal={onCloseSecondaryModal}
        currentPhase={currentPhase}
        action={action}
        rows={rows}
        modal={modal}
        context={context}
      />
    ))
    openModal({ width: MODAL_WIDTH })
  }

  // TODO 006:
  // View History - Nice to have feature in the future
  // const historyModal = row => {
  //   setModalContent(() => (
  //     <ServicesModalHistory
  //       getUnitHistory={getUnitHistory}
  //       resetUnitHistory={resetUnitHistory}
  //       services={services}
  //       context={context}
  //       unit={row}
  //       user={user}
  //       onClose={() => {
  //         setModalContent(null)
  //         closeModal()
  //       }}
  //     />
  //   ))
  //   openModal({ width: '950px' })
  // }

  const overrideTTClicked = (row, e) => {
    const {
      user_metadata: { pdbid }
    } = user || {}
    const currentService = (services || {})?.currentService?.replace('/', '%20')
    const { operation_uri } = row
    const buttonWidth = '130px'
    openModal({ width: '425px' })
    setModalContent(() => (
      <div className="container">
        <div className="columns">
          <div className="column">
            <p className="is-size-4 m-b-md">
              You're about to override the standard workflow.
            </p>
            <p className="is-size-6 m-b-md">
              This action will allow this service to start at anytime.
            </p>
            <p
              className={[
                'is-size-4',
                'm-b-md',
                'has-text-link',
                'has-text-centered',
                'p-l-md',
                'p-r-md'
              ].join(' ')}>
              Are you sure you want to override this unit's workflow?
            </p>
            <div className="has-text-centered p-t-sm">
              <button
                className="button is-primary m-r-xs"
                style={{ width: buttonWidth }}
                onClick={() => {
                  closeModal()
                }}>
                No, Cancel
              </button>
              <button
                className="button is-danger m-l-xs"
                style={{ width: buttonWidth }}
                onClick={() => {
                  closeModal()
                  updateServices(pdbid, context, currentService, 'unblock', {
                    operation_uri
                  })
                  history.push(`/services?s=${params.s}&p=Start`)
                }}>
                Yes, Override
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  const cardClicked = id => {
    params.p = id
    let qs = makeQueryString(params)
    qs = (qs || '').toString().replace('/', '%20')
    history.push(`${location.pathname}${qs}`)
  }

  const { currentPhase } = services
  const allow_unblock = false
  const phase = PHASES.find(p => p.key === currentPhase)
  const checkboxDisabled =
    services.updateIsRequesting ||
    !services.listHasData ||
    services.listIsRequesting ||
    services.list.length < 1
  // currentPhase === 'Approved'
  let filteredList = _.sortBy(
    services?.list?.filter(
      row =>
        row?.unit?.toUpperCase().indexOf(textFilter.toUpperCase()) !== -1 ||
        row?.team_member?.toUpperCase().indexOf(textFilter.toUpperCase()) !== -1
    ),
    ['unit']
  )

  const { serviceColumns, list: currentList, currentService } = services || {}
  const { user_metadata } = user
  const { allowedSections } = user_metadata || {}

  // Confirmation Modal for action buttons
  const onHandleConfirmationModal = ({
    msg,
    submitAction,
    dismissLabel,
    modalTitle,
    submitLabel,
    subMsg,
    additionalSubmitButtonClasses
  }) => {
    showConfirmationModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        dismiss={dismissConfirmationModal}
        showSubmitButton={false}
      />
    )
  }

  const {
    user_metadata: { inspection }
  } = user

  let hd = getHeaderData(
    currentPhase,
    property?.scheduler,
    serviceColumns,
    currentService,
    allowedSections,
    onHandleConfirmationModal,
    inspection
  )

  // To show all dynamic columns in approve status

  // if (currentPhase === 'Approved') {
  //   hd = getHeaderData(currentPhase, property?.scheduler).filter(
  //     h => h.key !== 'actions'
  //   )
  // }

  const getSpaceRowLabel = row => {
    const { is_goback, tooltip } = row || {}
    if (is_goback) {
      return `${tooltip}\nGO-BACK`
    }

    return tooltip || ''
  }

  const { list, teams } = services

  const serviceWithoutSpaces = currentService?.replaceAll(' ', '')
  const service = SERVICES.find(s => s.key === serviceWithoutSpaces)
  const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
  const {
    user_metadata: { properties, projects }
  } = user
  const project = projects.find(p => p.id === context.project)
  let serviceLabel = service?.display

  if (currentService === 'CarpetReplace' && _.get(context, 'property', null)) {
    const userProperty = _.find(
      _.get(user_metadata, 'properties', []),
      p => p.id === _.get(context, 'property')
    )
    if (userProperty) {
      serviceLabel = _.get(userProperty, 'cr_label', serviceLabel)
    }
  }
  const names = {
    property: (property || {})?.name?.replace(/ /g, '_'),
    project: (project || {})?.name?.replace(/ /g, '_')
  }

  let rows = cloneDeep(tableRows)

  if (currentPhase === 'InProgress' || currentPhase === 'Approve') {
    rows = checked.map(c => list.find(l => l.unit === c))
  }

  const teamsSelected =
    currentPhase !== 'AssignQC'
      ? teams.filter(t => rows.find(c => c?.team_uri === t?.uri))
      : teams.filter(t => t?.uri === teamURI)

  const getSubArrays = (array = [], limit = 6) => {
    const subArrays = []
    let i = 0
    let computedLimit = limit

    while (i < array.length) {
      if (subArrays.length) {
        computedLimit = limit - 2
      }
      if (i < array.length) {
        subArrays.push(array.slice(i, computedLimit + i))
      } else {
        subArrays.push(array.slice(i, computedLimit + i - array.length))
      }
      i += computedLimit
    }

    return subArrays
  }

  const getPDFData = rowsByTeam => {
    let headers = [
      { key: 'unit', label: 'Unit #' },
      { key: 'Common', label: 'Common Area' },

      ...(serviceColumns || []).map(column => {
        const { unitspace } = column || {}
        return { key: unitspace, label: `Bed ${unitspace}` }
      })
    ]

    const shouldShowVendor =
      currentPhase === 'Approve' || currentPhase === 'AssignQC'

    if (shouldShowVendor) {
      headers.splice(1, 0, { key: 'qc_team_member', label: 'Team' })
    }

    const subHeaders = getSubArrays(headers, 8)

    const data = (rowsByTeam || []).map(row => {
      const spaceLabels = (serviceColumns || []).map(column => {
        const { unitspace } = column || {}
        return getSpaceRowLabel(row[unitspace])
      })

      const notes = []

      if (
        (row.vendor_notes && row.vendor_notes !== '') ||
        (row.service_notes && row.service_notes !== '')
      ) {
        if (row.vendor_notes && row.vendor_notes !== '') {
          notes.push(row.vendor_notes)
        }
        if (row.service_notes && row.service_notes !== '') {
          notes.push(row.service_notes)
        }
      }

      const unit = `${row.unit}<br/>${row?.type}`

      const body = [unit, getSpaceRowLabel(row.Common), ...spaceLabels]

      if (shouldShowVendor) {
        body.splice(1, 0, row?.qc_team_member)
      }

      const subArrays = getSubArrays(body, 8)

      return {
        listChunks: { chunks: subArrays, notes: notes.join('\n') }
      }
    })

    const pdfTableData = []

    for (let i = 0; i < data.length; i++) {
      const subRows = data[i].listChunks
      for (let j = 0; j < subHeaders.length; j++) {
        pdfTableData.push({
          headers: subHeaders[j],
          rows: subRows.chunks[j],
          colSpan: j === 0 ? 0 : 2
        })
      }
      pdfTableData.push({
        vendor: subRows.vendor
      })
    }

    return { pdfTableData }
  }

  const spaceCount = (serviceColumns || []).length + 1

  return (
    <div
      className="container animated fadeIn services-main"
      key={`services-${uuid}`}
      id="services-page">
      <div className="section">
        <div className="columns is-marginless">
          <div className="column is-one-half is-paddingless">
            <Breadcrumbs location={location} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <ServicesStats
              services={services}
              responsive={responsive}
              clicked={phase => cardClicked(phase)}
              location={location}
            />
          </div>
        </div>
        {errorMessage !== '' && (
          <div className="notification is-danger is-light is-flex">
            <p>{errorMessage}</p>
            <button onClick={() => setErrorMessage('')}>
              <img src={closeFilled} alt="" />
            </button>
          </div>
        )}
        <DataTable
          header={hd}
          ref={tableRef}
          customFilters={customFilters}
          singleSelection={phase.single || false}
          emptyColor={lightGrey}
          data={filteredList}
          isLoading={!services.listHasData || services.listIsRequesting}
          isUpdating={services.updateIsRequesting}
          resetPage={false}
          filterKey={null}
          filterValue={null}
          hasCheckboxes={true}
          rowKey={'unit'}
          checkboxClicked={row => checkboxClicked(row)}
          checkboxDisabled={checkboxDisabled}
          customCheckboxDisabledAllowed={() => {
            switch (currentPhase) {
              case 'Assign':
              case 'Start':
                return !isBlockUnitEnabled
              case 'Approved':
                return true
              default:
                return false
            }
          }}
          customCheckboxDisabledKey={() => {
            switch (currentPhase) {
              case 'Assign':
              case 'Start':
                return 'is_blocked'
              case 'Approved':
                return 'has_charge'
              default:
                return ''
            }
          }}
          resetChecked={resetChecked}
          hasEllipsis={allow_unblock && currentPhase === 'Blocked'}
          ellipsisMouseOver={(row, e) => {
            setTooltipContent(
              <div
                className={[
                  'is-fullheight',
                  'p-t-sm',
                  'p-b-sm',
                  'p-l-md',
                  'is-pointer',
                  'is-unselectable',
                  'htooltip'
                ].join(' ')}
                onClick={() => overrideTTClicked(row)}>
                Unblock
              </div>
            )
            const pos = e.target.getBoundingClientRect()
            const width = 150
            openTooltip(
              `${width}px`,
              '',
              `${pos.y + pos.height}px`,
              `${pos.x - width + pos.width}px`,
              e.target
            )
          }}
          additionalOptions={
            <>
              {showReprintOption() ? (
                <button
                  className="button tool-button"
                  type="button"
                  disabled={checked.length <= 0}
                  onClick={() => exportToPDF()}>
                  <img src={print} alt="Re-print" className="m-r-xs" />
                  Reprint Work Order
                </button>
              ) : null}
              {showUnBlockToggle() && hasPermission ? (
                <>
                  <ToggleBlockUnits
                    isActive={isBlockUnitEnabled}
                    onChange={state => {
                      setIsBlockUnitEnabled(state)
                      setChecked([])
                    }}
                  />
                  <span className="toggle-text">Enable Blocked Units</span>
                </>
              ) : null}
            </>
          }
          calcTableMaxValue={true}
          tooltip={tooltip}
          openTooltip={openTooltip}
          closeTooltip={closeTooltip}
          setTooltipContent={setTooltipContent}
          getMenuDimensions={() => {
            let width = 80
            return [width, 40, -25, 10]
          }}
          methods={{ openNotes: row => openNotes(row) }}
          onSortChanged={values => setCurrentSort(values)}
          textFilter={textFilter}
          outsideFilters={outsideFilters}
          onSetTextFilter={setTextFilter}
          title={getTableTitle()}
          buttonsSection={getButtonsSection()}
          onExport={() => exportToCSV()}
          rowOptions={[
            {
              available: row => hasPermission && !row.has_note,
              icon: servicesIcons.noteAdd,
              caption: 'Add Note',
              action: row => openNotes(row)
            },
            {
              available: row => hasPermission && row.has_note,
              icon: servicesIcons.note,
              caption: 'Edit notes',
              action: row => openNotes(row)
            },
            // TODO 006:
            // View History - Nice to have feature in the future
            // {
            //   available: () => true,
            //   icon: servicesIcons.history,
            //   caption: 'View History',
            //   action: row => historyModal(row)
            // },
            // TODO 007:
            // Mark complete - commented, but would like to finish this for Turn 2022
            // {
            //   available: () => true,
            //   icon: servicesIcons.check,
            //   caption: 'Mark Complete',
            //   action: row => markCompleteModal(row)
            // },
            {
              available: row => hasPermission && row.is_blocked,
              icon: servicesIcons.unblock,
              caption: 'Unblock',
              action: row => unblockModal(row)
            }
            // {
            //   available: row => !row.has_charge && currentPhase !== 'Assign',
            //   icon: servicesIcons.goBackSmall,
            //   caption: 'Return',
            //   action: row =>
            //     handleOpenReturnModal([row], 'bulk_return', currentPhase)
            // }
          ]}
        />

        {spaceCount > 8 && (
          <div>
            {(teamsSelected || []).map((ts, index) => {
              const columns = _.map(currentSort, s => s.replace(/^-/, ''))
              const orders = _.map(currentSort, s =>
                s[0] === '-' ? 'desc' : 'asc'
              )

              const rowsByTeam = _.orderBy(
                currentPhase !== 'AssignQC'
                  ? rows.filter(row => row.team_uri === ts.uri)
                  : rows,
                columns,
                orders
              )

              const { pdfTableData } = getPDFData(rowsByTeam)

              return (
                <table
                  className={`table table-pdf${index} with-border`}
                  style={{
                    width: '100%',
                    display: 'none'
                  }}>
                  {(pdfTableData || []).map(
                    ({ headers, rows, notes, colSpan }, index) => (
                      <>
                        {headers && (
                          <tr>
                            {colSpan > 0 && (
                              <th className="th" colSpan={colSpan}></th>
                            )}
                            {(headers || []).map(header => (
                              <th className="th">{header.label}</th>
                            ))}
                          </tr>
                        )}
                        <tbody>
                          {rows && (
                            <tr>
                              {colSpan > 0 && (
                                <td
                                  className="empty-column"
                                  colSpan={colSpan}></td>
                              )}
                              {(rows || []).map(row => {
                                return (
                                  <td
                                    className={!row ? 'no-space' : 'td'}
                                    dangerouslySetInnerHTML={{
                                      __html: row
                                    }}></td>
                                )
                              })}
                            </tr>
                          )}
                          {notes && (
                            <tr>
                              <td className="notes" colSpan={8}>
                                {notes}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    )
                  )}
                </table>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

ServicesMain.propTypes = {
  services: PropTypes.object,
  user: PropTypes.object,
  responsive: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  modal: PropTypes.object,
  hasRequested: PropTypes.bool,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setCurrentPhase: PropTypes.func,
  setCurrentService: PropTypes.func,
  markCompleteService: PropTypes.func,
  unblockService: PropTypes.func,
  updateServices: PropTypes.func,
  getUnitHistory: PropTypes.func,
  resetUnitHistory: PropTypes.func
}

export default ServicesMain
