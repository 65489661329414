import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withScrolling from 'react-dnd-scrolling'
import {
  faChevronDown,
  faChevronUp,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
import { move, doubleArrow } from 'ui/icons'
import { useAppContext } from 'hooks'
import { isEmpty, isEqual } from 'lodash'
import { uuid } from 'short-uuid'
import update from 'immutability-helper'

import {
  newForm,
  formConfigSubmenu,
  sortAndMapTemplateElements
} from './formConfig'
import {
  getPropertyFormDetails,
  resetPropertyFormDetails,
  getFormElements,
  resetTemplateElements,
  createOrUpdatePropertyForm
} from '../../modules/property-forms'
import EditField from '../../components/edit-field'
import Draggable from '../../components/draggable-form-template'
import Droppable from '../../components/droppable-form-template'
import PropertyFormsElements from './property-forms-elements'
import PropertyFormsDetails from './property-forms-details'
import { closeFilled, warning } from 'ui/icons'

import './index.scss'
import {
  useAppUser,
  useContextChanged,
  usePermissionGate
} from '../../helpers/hooks'
import moment from 'moment'
import BigLoading from '../../components/big-loading'
import usePageReload from '../../hooks/usePageReload'
import { RouterPrompt } from '../../components/common/Prompt/RouterPrompt'

const ScrollingComponent = withScrolling('div')

const removeCollapsedProperty = form => {
  const formClone = cloneDeep(form)

  formClone.spaces = (formClone.spaces || []).map(space => {
    delete space.collapsed
    return {
      ...space,
      items: (space?.items || []).map(item => {
        delete item.collapsed
        return item
      })
    }
  })

  return formClone
}

const filterSpace = space => space.do_delete_space === 'false'

const filterItem = item => item.do_delete_item === 'false'

const filterObservation = observation =>
  observation.do_delete_observation === 'false'

const filterSolution = solution => solution.do_delete_solution === 'false'

const PropertyFormsManage = ({ history, formId }) => {
  const dispatch = useDispatch()
  const {
    user: { pdbid },
    context
  } = useAppContext()
  const {
    elementsIsRequesting,
    elementsIsError,
    elements,
    detailsIsRequesting,
    detailsIsError,
    detailsHasRequested,
    details,
    deleteIsError,
    duplicateIsError,
    createOrUpdateFormRequested,
    createOrUpdateFormError,
    list
  } = useSelector(state => state.propertyForms)

  const { hasPermission } = usePermissionGate('create-or-modify-property-forms')
  const { user } = useAppUser()
  const { contextChanged } = useContextChanged(context)

  const [error, setError] = useState(false)
  const [submenuActiveIndex, setSubmenuActiveIndex] = useState(0)
  const [elementsCustomData, setElementsCustomData] = useState({})
  const [formCustomData, setFormCustomData] = useState(newForm)
  const formEditMode = !!formId
  const [loading, setLoading] = useState(formEditMode)
  const [activeSpace, setActiveSpace] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSectionOpened, setIsSectionOpened] = useState(false)

  const formRef = useRef(null)

  const originalForm = useMemo(() => {
    return removeCollapsedProperty(details)
  }, [details])

  const canEditForm = useMemo(() => {
    return !formCustomData.inspection_id && hasPermission
  }, [formCustomData.inspection_id, hasPermission])

  const formToCompare = useMemo(() => {
    return removeCollapsedProperty(formCustomData)
  }, [formCustomData])

  const formToRender = useMemo(() => {
    const form = cloneDeep(formCustomData)

    form.spaces = (form.spaces || [])
      .filter(space => filterSpace(space))
      .map(space => ({
        ...space,
        items: (space.items || [])
          .filter(item => filterItem(item))
          .map(item => ({
            ...item,
            observations: (item.observations || [])
              .filter(observation => filterObservation(observation))
              .map(observation => ({
                ...observation,
                solutions: (observation.solutions || []).filter(solution =>
                  filterSolution(solution)
                )
              }))
          }))
      }))

    return form
  }, [formCustomData])

  const hasUnsavedChanges = useMemo(() => {
    if (isSubmitted) return false
    return !isEqual(originalForm, formToCompare)
  }, [originalForm, formToCompare, isSubmitted])

  const spacesLeft = useMemo(() => {
    return (elementsCustomData.spaces || []).filter(
      space => space.status !== 'inactive'
    )
  }, [elementsCustomData.spaces])

  const isComplete = useCallback((form = {}) => {
    const { spaces } = form

    if (!spaces.length) return false

    for (let space of spaces || []) {
      if (!space?.items?.length) return false

      for (let item of space.items || []) {
        if (!item?.observations?.length) return false

        for (let observation of item.observations || []) {
          if (!observation?.solutions?.length) return false
        }
      }
    }

    return true
  }, [])

  const checkIfFormComplete = useMemo(() => {
    const spaces = formToRender.spaces.filter(space => space.space_id)

    if (!spaces?.length) return false

    for (let space of spaces || []) {
      if (!space?.items?.length) return false

      for (let item of space.items || []) {
        if (!item?.observations?.length) return false

        for (let observation of item.observations || []) {
          if (!observation?.solutions?.length) return false
        }
      }
    }

    return true
  }, [formToRender])

  const isFormComplete = useMemo(() => {
    return isComplete(formToRender)
  }, [formToRender])

  const validateForm = useCallback(() => {
    const errors = {}

    const isNameExist = (list || [])
      .filter(form => form.name !== originalForm.name)
      .some(form => form.name === formCustomData.name.trim())

    if (!formCustomData.name?.trim()) {
      errors.name = 'Please enter Form name.'
    }

    if (isNameExist) {
      errors.name = 'Form with this name already exist.'
    }

    if (!formCustomData.type?.trim()) {
      errors.type = 'Please enter Form type.'
    }

    if (formCustomData.type.length > 50) {
      errors.type = 'Form type should be 50 characters long.'
    }

    return errors
  }, [formCustomData.name, formCustomData.type])

  const disableSaveButton = useMemo(() => {
    const errors = validateForm()
    return !isEmpty(errors) || isEqual(originalForm, formToCompare)
  }, [validateForm, originalForm, formToCompare])

  usePageReload(hasUnsavedChanges)

  // toggle collapsible cards
  const toggleCollapsible = useCallback(spaceIndex => {
    setFormCustomData(prevData => {
      const newData = { ...prevData }
      newData['spaces'] = newData['spaces'].map((space, index) => ({
        ...space,
        collapsed: spaceIndex === index ? !space['collapsed'] : space.collapsed
      }))

      return newData
    })
  }, [])

  // Template name handler
  const setFormName = useCallback(
    name => {
      if (canEditForm) {
        setFormCustomData(prevData => {
          const newData = cloneDeep(prevData)
          newData['name'] = name
          return newData
        })
      }
    },
    [EditField, canEditForm]
  )

  // Template type handler
  const setFormType = useCallback(
    name => {
      if (canEditForm) {
        setFormCustomData(prevData => {
          const newData = cloneDeep(prevData)
          newData['type'] = name
          return newData
        })
      }
    },
    [EditField, canEditForm]
  )

  const getFinalFormData = useCallback(() => {
    const propertyForm = {}

    propertyForm.form_id = formCustomData.form_id || null
    propertyForm.template_id = formCustomData.template_id || null
    propertyForm.name = formCustomData.name.trim()
    propertyForm.type = formCustomData.type.trim()
    propertyForm.created_by = formCustomData.created_by || user?.user_id
    propertyForm.created_at = formCustomData.created_at
      ? moment(formCustomData.created_at).format('DD-MMM-YY')
      : moment(new Date()).format('DD-MMM-YY')
    propertyForm.status = formCustomData.status === null ? null : 'inactive'

    propertyForm.spaces = formCustomData?.spaces?.length
      ? formCustomData.spaces.map((space, spaceIndex) => ({
          space_id: space.space_id,
          form_space_id: space.form_space_id || null,
          do_delete_space: space.do_delete_space,
          required_on_property_forms: space.required_on_property_forms || null,
          sorting_order: `${spaceIndex + 1}`,
          template_space: space.template_space,
          items: space?.items?.length
            ? space.items.map((item, itemIndex) => ({
                item_id: item.item_id,
                form_item_id: item.form_item_id || null,
                do_delete_item: item.do_delete_item,
                sorting_order_item: `${itemIndex + 1}`,
                template_item: item.template_item,
                observations: item?.observations?.length
                  ? item.observations.map((observation, observationIndex) => ({
                      observation_id: observation.observation_id,
                      form_observation_id:
                        observation.form_observation_id || null,
                      do_delete_observation: observation.do_delete_observation,
                      solution_type: observation.solution_type,
                      media_required: observation.media_required || null,
                      media_minimum: observation.media_minimum || '1',
                      sorting_order_observation: `${observationIndex + 1}`,
                      template_observation: observation.template_observation,
                      solutions: observation?.solutions?.length
                        ? observation.solutions.map(
                            (solution, solutionIndex) => ({
                              solution_id: solution.solution_id,
                              form_solution_id:
                                solution.form_solution_id || null,
                              do_delete_solution: solution.do_delete_solution,
                              sorting_order_solution: `${solutionIndex + 1}`,
                              template_solution: solution.template_solution
                            })
                          )
                        : [{ solution_id: null }]
                    }))
                  : [{ observation_id: null }]
              }))
            : [{ item_id: null }]
        }))
      : [{ space_id: null }]
    propertyForm.state = checkIfFormComplete ? null : 'incomplete'

    return propertyForm
  }, [formCustomData, isFormComplete])

  const saveFormHandler = useCallback(() => {
    if (!disableSaveButton) {
      const formData = getFinalFormData()

      setIsSubmitted(true)

      const form_id = !formData.form_id ? 0 : formData.form_id

      dispatch(
        createOrUpdatePropertyForm(
          pdbid,
          context,
          formData,
          form_id,
          form => {
            if (formId) {
              dispatch(getPropertyFormDetails(pdbid, context, formId))
            } else {
              history.replace(
                `/inspections-setup/property-forms/manage?form=${form.form_id}`
              )
            }
            setIsSubmitted(false)
          },
          () => setIsSubmitted(false)
        )
      )
    }
  }, [formCustomData])

  const elementsListUpdate = (elementType, elementId) => {
    setElementsCustomData(prevData => {
      const indexToRemove = prevData[elementType].findIndex(
        element =>
          element[`${elementType.substring(0, elementType.length - 1)}_id`] ===
          elementId
      )
      const newElementData = [...prevData[elementType]]
      newElementData.splice(indexToRemove, 1)

      return {
        ...prevData,
        [elementType]: newElementData
      }
    })
  }

  const removeFormElement = element => {
    const {
      type,
      space_index,
      item_index,
      observation_index,
      solution_index,
      form_space_id,
      form_item_id,
      form_observation_id,
      form_solution_id
    } = element

    setElementsCustomData(prevData => {
      return {
        ...prevData,
        spaces:
          type === 'space' && element.status === 'active'
            ? sortAndMapTemplateElements(
                [...prevData['spaces'], element],
                'space'
              )
            : prevData['spaces']
      }
    })

    setFormCustomData(prevData => {
      let newData = cloneDeep(prevData)

      switch (type) {
        case 'space':
          if (form_space_id) {
            newData['spaces'][space_index]['do_delete_space'] = null
            newData['spaces'][space_index]['collapsed'] = true
            newData['spaces'][space_index]['items'] = (
              newData['spaces'][space_index]['items'] || []
            )
              .filter(item => item.form_item_id !== null)
              .map(item => ({
                ...item,
                do_delete_item: null,
                collapsed: true,
                observations: (item?.observations || [])
                  .filter(
                    observation => observation.form_observation_id !== null
                  )
                  .map(observation => ({
                    ...observation,
                    do_delete_observation: null,
                    solutions: (observation?.solutions || [])
                      .filter(solution => solution.form_solution_id !== null)
                      .map(solution => ({
                        ...solution,
                        do_delete_solution: null
                      }))
                  }))
              }))
          } else {
            newData['spaces'][space_index]['items'] = []
            newData['spaces'].splice(space_index, 1)
            newData['spaces'] = newData['spaces'].map((space, index) => ({
              ...space,
              space_index: index,
              items: space['items'].map(item => ({
                ...item,
                space_index: index,
                observations: item['observations'].map(observation => ({
                  ...observation,
                  space_index: index,
                  solutions: observation['solutions'].map(solution => ({
                    ...solution,
                    space_index: index
                  }))
                }))
              }))
            }))
          }

          if (activeSpace === space_index) {
            setIsSectionOpened(false)
            setActiveSpace(null)
          }
          return newData

        case 'item':
          if (form_item_id) {
            newData['spaces'][space_index]['items'][item_index][
              'do_delete_item'
            ] = null
            newData['spaces'][space_index]['items'][item_index][
              'collapsed'
            ] = true

            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ] = (
              newData['spaces'][space_index]['items'][item_index][
                'observations'
              ] || []
            )
              .filter(observation => observation.form_observation_id !== null)
              .map(observation => ({
                ...observation,
                do_delete_observation: null,
                solutions: (observation?.solutions || [])
                  .filter(solution => solution.form_solution_id !== null)
                  .map(solution => ({
                    ...solution,
                    do_delete_solution: null
                  }))
              }))
          } else {
            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ] = []
            newData['spaces'][space_index]['items'].splice(item_index, 1)
            newData['spaces'][space_index]['items'] = newData['spaces'][
              space_index
            ]['items'].map((item, index) => ({
              ...item,
              item_index: index,
              observations: item['observations'].map(observation => ({
                ...observation,
                item_index: index,
                solutions: observation['solutions'].map(solution => ({
                  ...solution,
                  item_index: index
                }))
              }))
            }))
          }
          return newData

        case 'observation':
          if (form_observation_id) {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['do_delete_observation'] = null

            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'] = (
              newData['spaces'][space_index]['items'][item_index][
                'observations'
              ][observation_index]['solutions'] || []
            )
              .filter(solution => solution.form_solution_id !== null)
              .map(solution => ({
                ...solution,
                do_delete_solution: null
              }))
          } else {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'] = []
            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ].splice(observation_index, 1)
            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ] = newData['spaces'][space_index]['items'][item_index][
              'observations'
            ].map((observation, index) => ({
              ...observation,
              observation_index: index,
              solutions: observation['solutions'].map(solution => ({
                ...solution,
                observation_index: index
              }))
            }))
          }
          return newData

        case 'solution':
          if (form_solution_id) {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'][solution_index]['do_delete_solution'] = null
          } else {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'].splice(solution_index, 1)
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'] = newData['spaces'][space_index]['items'][
              item_index
            ]['observations'][observation_index]['solutions'].map(
              (item, index) => ({
                ...item,
                solution_index: index
              })
            )
          }
          return newData

        default:
          return newData
      }
    })
  }

  const addSpaceHandler = useCallback(() => {
    setFormCustomData(prevData => {
      const newData = cloneDeep(prevData)

      newData['spaces'] = newData['spaces'].map(space => ({
        ...space
      }))

      newData.spaces.push({
        space_name: '',
        space_index: newData.spaces.length,
        collapsed: false,
        required_on_property_forms: 'false',
        do_delete_space: 'false',
        space_uuid: uuid(),
        template_space: 'false',
        items: []
      })

      return newData
    })
  }, [])

  const spaceDropHandler = (dropData, space) => {
    const canDrop = !('space_index' in space) && !dropData.space_id

    if (canDrop) {
      setFormCustomData(prevData => {
        const newData = cloneDeep(prevData)

        newData.spaces[dropData['space_index']] = {
          ...dropData,
          ...space
        }

        return newData
      })

      elementsListUpdate('spaces', space.space_id)
    }
  }

  const itemDropHandler = (dropData, item) => {
    if ('template_item' in item && !item.template_item) return

    const parentSpace = dropData.parent_space

    const itemChangingParent =
      item.space_index !== undefined && parentSpace !== item.space_index

    const foundItems = formCustomData.spaces[parentSpace]['items'].filter(
      element => element.item_id === item.item_id
    )

    if (
      formCustomData.spaces[parentSpace]['items'].length === 0 ||
      !foundItems.length ||
      (foundItems.length === 1 && !foundItems[0].do_delete_item)
    ) {
      setFormCustomData(prevData => {
        const newData = { ...prevData }

        if (itemChangingParent) {
          if (item.form_item_id) {
            newData['spaces'][item.space_index]['items'][item.item_index][
              'do_delete_item'
            ] = null

            newData['spaces'][item.space_index]['items'][item.item_index][
              'observations'
            ] = newData['spaces'][item.space_index]['items'][item.item_index][
              'observations'
            ]
              .filter(observation => observation.form_observation_id !== null)
              .map(observation => ({
                ...observation,
                do_delete_observation: null,
                solutions: (observation?.solutions || [])
                  .filter(solution => solution.form_solution_id !== null)
                  .map(solution => ({
                    ...solution,
                    do_delete_solution: null
                  }))
              }))
          } else {
            newData['spaces'][item.space_index]['items'].splice(
              item.item_index,
              1
            )

            newData['spaces'][item.space_index]['items'] = newData['spaces'][
              item.space_index
            ]['items'].map((item, itemIndex) => ({
              ...item,
              item_index: itemIndex,
              observations: (item?.observations || []).map(observation => ({
                ...observation,
                item_index: itemIndex,
                solutions: (observation?.solutions || []).map(solution => ({
                  ...solution,
                  item_index: itemIndex
                }))
              }))
            }))
          }
        }

        const itemsData = newData.spaces[parentSpace]['items']
        itemsData.push({
          ...item,
          space_index: parentSpace,
          item_index: itemsData.length,
          collapsed: true,
          do_delete_item: 'false',
          form_item_id: null,
          item_uuid: uuid(),
          template_item: 'false',
          observations: itemChangingParent
            ? (item?.observations || []).map(observation => ({
                ...observation,
                space_index: parentSpace,
                item_index: itemsData.length,
                form_observation_id: null,
                solutions: (observation?.solutions || []).map(solution => ({
                  ...solution,
                  space_index: parentSpace,
                  item_index: itemsData.length,
                  form_solution_id: null
                }))
              }))
            : [],
          multiple: false,
          totalInSpace: 0
        })
        return newData
      })
    }
  }

  const observationDropHandler = (dropData, observation) => {
    if (
      'template_observation' in observation &&
      !observation.template_observation
    )
      return

    const parentSpace = dropData.parent_space
    const parentItem = dropData.parent_item

    const observationChangingParentItem =
      observation.item_index !== undefined &&
      (parentItem !== observation.item_index ||
        parentSpace !== observation.space_index)

    const foundObservations = formCustomData.spaces[parentSpace]['items'][
      parentItem
    ]['observations'].filter(
      obs => obs.observation_id === observation.observation_id
    )

    if (
      formCustomData.spaces[parentSpace]['items'][parentItem]['observations']
        .length === 0 ||
      !foundObservations.length ||
      (foundObservations.length === 1 &&
        !foundObservations[0].do_delete_observation)
    ) {
      setFormCustomData(prevData => {
        const newData = { ...prevData }

        if (observationChangingParentItem) {
          if (observation.form_observation_id) {
            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'][observation.observation_index][
              'do_delete_observation'
            ] = null

            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'][observation.observation_index][
              'solutions'
            ] = newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'][observation.observation_index]['solutions']
              .filter(solution => solution.form_solution_id !== null)
              .map(solution => ({
                ...solution,
                do_delete_solution: null
              }))
          } else {
            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'].splice(observation.observation_index, 1)
            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'] = newData['spaces'][observation.space_index][
              'items'
            ][observation.item_index]['observations'].map(
              (observation, index) => ({
                ...observation,
                observation_index: index,
                solutions: observation['solutions'].map(solution => ({
                  ...solution,
                  observation_index: index
                }))
              })
            )
          }
        }

        const observationsData =
          newData.spaces[parentSpace]['items'][parentItem]['observations']
        observationsData.push({
          ...observation,
          space_index: parentSpace,
          item_index: parentItem,
          currentIndex: observationsData.length,
          observation_index: observationsData.length,
          form_observation_id: null,
          do_delete_observation: 'false',
          solution_type: 'single',
          media_required: null,
          media_minimum: '1',
          observation_uuid: uuid(),
          template_observation: 'false',
          solutions: observationChangingParentItem
            ? observation['solutions'].map(solution => ({
                ...solution,
                item_index: parentItem,
                observation_index: observationsData.length,
                space_index: parentSpace,
                form_solution_id: null
              }))
            : []
        })

        return newData
      })
    }
  }

  const solutionDropHandler = (dropData, solution) => {
    if ('template_solution' in solution && !solution.template_solution) return

    const parentSpace = dropData.parent_space
    const parentItem = dropData.parent_item
    const parentObservation = dropData.parent_observation

    const solutionChangingParentItem =
      solution.observation_index !== undefined &&
      (parentObservation !== solution.observation_index ||
        parentItem !== solution.item_index ||
        parentSpace !== solution.space_index)

    const foundSolutions = formCustomData.spaces[parentSpace]['items'][
      parentItem
    ]['observations'][parentObservation]['solutions'].filter(
      sol => sol.solution_id === solution.solution_id
    )

    if (
      formCustomData.spaces[parentSpace]['items'][parentItem]['observations'][
        parentObservation
      ]['solutions'].length === 0 ||
      !foundSolutions.length ||
      (foundSolutions.length === 1 && !foundSolutions[0].do_delete_solution)
    ) {
      setFormCustomData(prevData => {
        const newData = { ...prevData }
        const solutionsData =
          newData.spaces[parentSpace]['items'][parentItem]['observations'][
            parentObservation
          ]['solutions']

        if (solutionChangingParentItem) {
          if (solution.form_solution_id) {
            newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index]['solutions'][
              solution.solution_index
            ]['do_delete_solution'] = null
          } else {
            newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index]['solutions'].splice(
              solution.solution_index,
              1
            )
            newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index][
              'solutions'
            ] = newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index]['solutions'].map(
              (solution, index) => ({
                ...solution,
                solution_index: index
              })
            )
          }
        }

        solutionsData.push({
          ...solution,
          space_index: parentSpace,
          item_index: parentItem,
          observation_index: parentObservation,
          currentIndex: solutionsData.length,
          solution_index: solutionsData.length,
          form_solution_id: null,
          do_delete_solution: 'false',
          solution_uuid: uuid(),
          template_solution: 'false'
        })
        return newData
      })
    }
  }

  const handleSort = useCallback(
    (
      dragIndex,
      hoverIndex,
      type,
      space_index,
      item_index,
      observation_index
    ) => {
      setFormCustomData(prev => {
        const newData = cloneDeep(prev)

        switch (type) {
          case 'space':
            newData.spaces = update(newData.spaces, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, newData.spaces[dragIndex]]
              ]
            }).map((space, index) => ({
              ...space,
              space_index: index,
              items: (space?.items || []).map(item => ({
                ...item,
                space_index: index,
                observations: (item?.observations || []).map(observation => ({
                  ...observation,
                  space_index: index,
                  solutions: (observation?.solutions || []).map(solution => ({
                    ...solution,
                    space_index: index
                  }))
                }))
              }))
            }))

            break
          case 'item':
            if (space_index !== undefined) {
              newData.spaces[space_index]['items'] = update(
                newData.spaces[space_index]['items'],
                {
                  $splice: [
                    [dragIndex, 1],
                    [
                      hoverIndex,
                      0,
                      newData.spaces[space_index]['items'][dragIndex]
                    ]
                  ]
                }
              ).map((item, index) => ({
                ...item,
                item_index: index,
                observations: (item?.observations || []).map(observation => ({
                  ...observation,
                  item_index: index,
                  solutions: (observation?.solutions || []).map(solution => ({
                    ...solution,
                    item_index: index
                  }))
                }))
              }))
            }
            break
          case 'observation':
            if (space_index !== undefined && item_index !== undefined) {
              newData.spaces[space_index]['items'][item_index][
                'observations'
              ] = update(
                newData.spaces[space_index]['items'][item_index][
                  'observations'
                ],
                {
                  $splice: [
                    [dragIndex, 1],
                    [
                      hoverIndex,
                      0,
                      newData.spaces[space_index]['items'][item_index][
                        'observations'
                      ][dragIndex]
                    ]
                  ]
                }
              ).map((observation, index) => ({
                ...observation,
                observation_index: index,
                solutions: (observation?.solutions || []).map(solution => ({
                  ...solution,
                  observation_index: index
                }))
              }))
            }
            break
          case 'solution':
            if (
              space_index !== undefined &&
              item_index !== undefined &&
              observation_index !== undefined
            ) {
              newData.spaces[space_index]['items'][item_index]['observations'][
                observation_index
              ]['solutions'] = update(
                newData.spaces[space_index]['items'][item_index][
                  'observations'
                ][observation_index]['solutions'],
                {
                  $splice: [
                    [dragIndex, 1],
                    [
                      hoverIndex,
                      0,
                      newData.spaces[space_index]['items'][item_index][
                        'observations'
                      ][observation_index]['solutions'][dragIndex]
                    ]
                  ]
                }
              ).map((solution, index) => ({
                ...solution,
                solution_index: index
              }))
            }
            break
          default:
            break
        }
        return newData
      })
    },
    []
  )

  const getTemplateBody = () => {
    return (
      <ScrollingComponent className="space-container__wrapper">
        {(formToRender?.spaces || []).map((space, index) => (
          <Draggable
            dragType="space"
            dragData={{ currentIndex: index, ...space }}
            previewClass={['space-container__draggable']}
            key={space.space_uuid}
            index={space.space_index}
            onSort={handleSort}
            canDrag={canEditForm}>
            <div
              className={`space-container ${
                activeSpace === space.space_index && isSectionOpened
                  ? 'active-space'
                  : ''
              }`}>
              <Droppable
                dropType="space"
                dropAction={spaceDropHandler}
                dropData={space}
                onClick={e => {
                  e.stopPropagation()
                  setActiveSpace(space.space_index)
                }}
                dropClass={['space-container__draggable']}>
                <div className="space-container__title">
                  <img
                    src={move}
                    alt="move-icon"
                    className="space-container__icon"
                  />
                  {space.space_id && space.space_name ? (
                    <div className="draggable-item" style={{ left: 0 }}>
                      <span>{space.space_name}</span>
                      {space.template_space === 'false' && canEditForm && (
                        <img
                          src={closeFilled}
                          alt="remove item"
                          className="draggable-item__close"
                          onClick={() => removeFormElement(space)}
                        />
                      )}
                    </div>
                  ) : (
                    <span className="draggable-item__instructions">
                      Drop a space here to get started.
                    </span>
                  )}
                  <FontAwesomeIcon
                    icon={!space.collapsed ? faChevronUp : faChevronDown}
                    className="collapsible-toggler"
                    onClick={e => {
                      e.stopPropagation()
                      toggleCollapsible(space.space_index)
                    }}
                  />
                </div>
              </Droppable>
              {!space.collapsed && space.space_id && (
                <Droppable
                  dropType="item"
                  dropAction={itemDropHandler}
                  dropData={{ parent_space: space.space_index }}
                  dropClass={['space-container__body']}>
                  {space?.items.length === 0 && space.space_id ? (
                    <span className="draggable-item__instructions">
                      Add an item to this space.
                    </span>
                  ) : (
                    space?.items.map((item, index) => (
                      <div
                        className="item-container__body"
                        key={item.item_uuid}>
                        <span className="title">Item {index + 1}</span>
                        <div className="content">
                          <Draggable
                            dragType="item"
                            dragData={{
                              currentIndex: index,
                              ...item,
                              listId: space?.space_uuid
                            }}
                            previewClass={['draggable-item__preview']}
                            index={item.item_index}
                            listId={space.space_uuid}
                            key={item.item_uuid}
                            onSort={handleSort}
                            canDrag={canEditForm}>
                            <div className="draggable-item">
                              <img
                                src={move}
                                alt="move-icon"
                                className="draggable-item__icon"
                              />
                              <span>{item.item_name}</span>
                              {item.template_item === 'false' &&
                                canEditForm && (
                                  <img
                                    src={closeFilled}
                                    alt="remove item"
                                    className="draggable-item__close"
                                    onClick={() => removeFormElement(item)}
                                  />
                                )}
                            </div>
                            <Droppable
                              dropType="observation"
                              dropAction={observationDropHandler}
                              dropData={{
                                parent_space: space.space_index,
                                parent_item: item.item_index
                              }}
                              dropClass={['observation-container__body']}>
                              {(item?.observations || []).length === 0 ? (
                                <span className="draggable-item__instructions">
                                  Add one or more observations to this item.
                                </span>
                              ) : (
                                (item?.observations || []).map(
                                  (observation, index) => (
                                    <div key={observation.observation_uuid}>
                                      <span className="title">
                                        Observation {index + 1}
                                      </span>
                                      <div className="content">
                                        <Draggable
                                          dragType="observation"
                                          dragData={{
                                            currentIndex: index,
                                            ...observation,
                                            listId: item.item_uuid
                                          }}
                                          previewClass={[
                                            'draggable-item__preview'
                                          ]}
                                          listId={item.item_uuid}
                                          index={observation.observation_index}
                                          key={observation.observation_uuid}
                                          onSort={handleSort}
                                          canDrag={canEditForm}>
                                          <div className="draggable-item">
                                            <img
                                              src={move}
                                              alt="move-icon"
                                              className="draggable-item__icon"
                                            />
                                            <span>
                                              {observation.observation_name}
                                            </span>
                                            {observation.template_observation ===
                                              'false' &&
                                              canEditForm && (
                                                <img
                                                  src={closeFilled}
                                                  alt="remove item"
                                                  className="draggable-item__close"
                                                  onClick={() =>
                                                    removeFormElement(
                                                      observation
                                                    )
                                                  }
                                                />
                                              )}
                                          </div>
                                          {(observation?.solutions || [])
                                            .length === 0 ? (
                                            <Droppable
                                              dropType="solution"
                                              dropAction={solutionDropHandler}
                                              dropData={{
                                                parent_space: space.space_index,
                                                parent_item: item.item_index,
                                                parent_observation:
                                                  observation.observation_index
                                              }}
                                              dropClass={[
                                                'solution-container__body'
                                              ]}>
                                              <span className="draggable-item__instructions">
                                                Add one or more solutions to
                                                this observation.
                                              </span>
                                            </Droppable>
                                          ) : (
                                            <Droppable
                                              dropType="solution"
                                              dropAction={solutionDropHandler}
                                              dropData={{
                                                parent_space: space.space_index,
                                                parent_item: item.item_index,
                                                parent_observation:
                                                  observation.observation_index
                                              }}
                                              dropClass={[
                                                'solution-container__body'
                                              ]}>
                                              <img
                                                src={doubleArrow}
                                                alt="solutions-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: 38,
                                                  top: 25
                                                }}
                                              />
                                              {(
                                                observation?.solutions || []
                                              ).map(solution => (
                                                <Draggable
                                                  dragType="solution"
                                                  dragData={{
                                                    currentIndex: index,
                                                    ...solution,
                                                    listId:
                                                      observation.observation_uuid
                                                  }}
                                                  previewClass={[
                                                    'draggable-item__preview'
                                                  ]}
                                                  key={solution.solution_uuid}
                                                  index={
                                                    solution.solution_index
                                                  }
                                                  listId={
                                                    observation.observation_uuid
                                                  }
                                                  onSort={handleSort}
                                                  canDrag={canEditForm}>
                                                  <div className="draggable-item">
                                                    <span>
                                                      {solution.solution_name}
                                                    </span>
                                                    {solution.template_solution ===
                                                      'false' &&
                                                      canEditForm && (
                                                        <img
                                                          src={closeFilled}
                                                          alt="remove item"
                                                          className="draggable-item__close"
                                                          onClick={() =>
                                                            removeFormElement(
                                                              solution
                                                            )
                                                          }
                                                        />
                                                      )}
                                                  </div>
                                                </Draggable>
                                              ))}
                                            </Droppable>
                                          )}
                                        </Draggable>
                                      </div>
                                    </div>
                                  )
                                )
                              )}
                            </Droppable>
                          </Draggable>
                        </div>
                        <div className="item-container__border"></div>
                      </div>
                    ))
                  )}
                </Droppable>
              )}
            </div>
          </Draggable>
        ))}
        <div style={{ float: 'left', clear: 'both' }} ref={formRef}></div>
      </ScrollingComponent>
    )
  }

  useEffect(() => {
    if (contextChanged) return history.push('/inspections-setup/property-forms')
  }, [contextChanged])

  useEffect(() => {
    const errors = validateForm()
    setFormErrors(() => errors)
  }, [validateForm])

  useEffect(() => {
    if (formEditMode) {
      dispatch(getPropertyFormDetails(pdbid, context, formId))
    } else {
      dispatch(getFormElements(pdbid, context))
    }

    return () => {
      dispatch(resetPropertyFormDetails())
      dispatch(resetTemplateElements())
    }
  }, [formEditMode, formId])

  useEffect(() => {
    setFormCustomData(details)
  }, [details])

  useEffect(() => {
    setElementsCustomData(elements)
  }, [elements])

  useEffect(() => {
    if (
      elementsIsError ||
      deleteIsError ||
      detailsIsError ||
      createOrUpdateFormError ||
      duplicateIsError
    )
      setError(true)
  }, [
    detailsIsError,
    elementsIsError,
    deleteIsError,
    createOrUpdateFormError,
    duplicateIsError
  ])

  useEffect(() => {
    if (formEditMode && !detailsIsRequesting && detailsHasRequested) {
      return setLoading(false)
    }
  }, [detailsIsRequesting, elementsIsRequesting])

  useEffect(() => {
    if (detailsIsRequesting) {
      return setLoading(true)
    }
  }, [detailsIsRequesting])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'auto' })
    }
  }, [formCustomData.spaces.length])

  if (loading) {
    return <BigLoading />
  }

  return (
    <div className="propertyFormManage">
      <RouterPrompt
        key={location}
        title="Please save your changes before proceeding to go back. If you do not save, the changes will be discarded."
        okText="Discard"
        cancelText="Cancel"
        when={hasUnsavedChanges}
      />
      {error && (
        <div className="columns" style={{ marginLeft: '0' }}>
          <div className="column is-full">
            <div className={`${error ? 'error' : ''}`}>
              <div className="error-container">
                <img src={warning} alt="warning" className="m-r-sm" />
                <span className="error-description">Error.</span> Something went
                wrong. Please try again.
              </div>
            </div>
          </div>
        </div>
      )}

      {!canEditForm && (
        <div className="columns" style={{ marginLeft: '0' }}>
          <div className="column is-full p-l-none p-r-none">
            <div className="caveat-container">
              <span className="caveat-description">
                <strong>Note:</strong>&nbsp; This form cannot be updated because
                an inspection has been created from it. To create an updated
                version, you can duplicate this form and update the new version
              </span>
            </div>
          </div>
        </div>
      )}

      <div
        className="columns is-desktop"
        style={{ alignItems: 'flex-start', marginLeft: '0' }}>
        <div className="column is-two-thirds-desktop template-manage-dnd__spaces">
          <div className="title m-b-md">
            <div className="propertyFormManage__input-fields">
              <EditField
                id="name"
                label="Enter form name..."
                locked={!canEditForm}
                active={false}
                error={formErrors.name}
                value={formCustomData.name}
                onChange={value => {
                  setFormName(value)
                }}
              />
              <EditField
                id="type"
                label="Enter form type..."
                locked={!canEditForm}
                active={false}
                error={formErrors.type}
                value={formCustomData.type}
                onChange={value => {
                  setFormType(value)
                }}
              />
            </div>
          </div>
          {formCustomData.spaces.length === 0 && (
            <p className="m-t-lg has-text-centered">
              There are no spaces added yet
            </p>
          )}
          {getTemplateBody()}
          {canEditForm && (
            <button
              className="add-space"
              onClick={addSpaceHandler}
              disabled={!spacesLeft.length}>
              + Add a space
            </button>
          )}
        </div>

        {/* details / elements section */}
        <div className="column template-manage-dnd__details">
          <div className="template-manage-dnd__details__header">
            <ul className="submenu m-b-md">
              {formConfigSubmenu.map((item, index) => (
                <li
                  key={item.label}
                  className={cn({
                    active: index === submenuActiveIndex
                  })}
                  onClick={() => setSubmenuActiveIndex(index)}>
                  {item.label}
                </li>
              ))}
            </ul>
            <button
              className="button is-success btn-save m-b-md"
              disabled={disableSaveButton}
              onClick={saveFormHandler}>
              {createOrUpdateFormRequested && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
              )}
              Save
            </button>
          </div>
          <div className="template-manage-dnd__details__body">
            {submenuActiveIndex === 0 && (
              <PropertyFormsElements
                elements={elementsCustomData}
                isLoading={elementsIsRequesting}
                canDrag={canEditForm}
              />
            )}
            {submenuActiveIndex === 1 && (
              <PropertyFormsDetails
                formData={formCustomData}
                formToExport={formToRender}
                canEditForm={canEditForm}
                setFormData={setFormCustomData}
                history={history}
                activeSpace={activeSpace}
                onRemoveFormElement={removeFormElement}
                onOpenSection={value => setIsSectionOpened(value)}
                onSubmit={setIsSubmitted}
                originalForm={originalForm}
                hasPermission={hasPermission}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PropertyFormsManage.propTypes = {
  history: PropTypes.object,
  formId: PropTypes.string
}

export default PropertyFormsManage
