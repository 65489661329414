import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../breadcrumbs'
import PropTypes from 'prop-types'
import { withRouter, useParams } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import InspectionContent from './InspectionContent'

const InspectionManageMain = props => {
  const {
    location,
    user,
    context,
    getInspectionItem,
    inspect,
    resetGetInspectionItem,
    getInspectionDownloadMediaURL,
    getInspectionUploadMediaURL,
    history
  } = props || {}
  const params = useParams()
  const { id } = params || {}
  // Redux Data
  const {
    singleInspection,
    inspectionUploadMediaURL,
    inspectionDownloadMediaURL
  } = inspect || {}
  const { isRequesting, hasMadeInitialRequest, data: inspectionData } =
    singleInspection || {}
  const [isInspectionStatusUpdated, setIsInspectionStatusUpdated] = useState(
    false
  )

  // Request for getting Initial Data from API
  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    getInspectionItem(pdbid, context, id)
    getInspectionDownloadMediaURL(pdbid, context)
    getInspectionUploadMediaURL(pdbid, context)
    return () => {
      resetGetInspectionItem()
    }
  }, [])

  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    if (isInspectionStatusUpdated) {
      getInspectionItem(pdbid, context, id)
      setIsInspectionStatusUpdated(false)
    }
  }, [isInspectionStatusUpdated])

  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
      </div>
      <div className="section pt-0">
        <button
          className="go-back-button"
          onClick={e => {
            e.preventDefault()
            history.push('/inspections/inspectboard')
            resetGetInspectionItem()
          }}>
          Back to List View
        </button>
      </div>
      {hasMadeInitialRequest && !isRequesting ? (
        <div className="section">
          {(inspectionData || []).length !== 0 ? (
            <InspectionContent
              {...props}
              inspectionData={inspectionData[0]}
              setIsInspectionStatusUpdated={setIsInspectionStatusUpdated}
              unFilterInspectionData={inspectionData}
              inspectionUploadMediaURL={inspectionUploadMediaURL}
              inspectionDownloadMediaURL={inspectionDownloadMediaURL}
            />
          ) : (
            <div class="notification is-danger is-light mt-30">
              No Data found in this Inspection
            </div>
          )}
        </div>
      ) : (
        <BigLoading />
      )}
    </div>
  )
}

InspectionManageMain.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  getInspectionItem: PropTypes.func,
  getInspectionDownloadMediaURL: PropTypes.func,
  context: PropTypes.object,
  inspect: PropTypes.object
}

export default withRouter(InspectionManageMain)
