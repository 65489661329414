import Service from '../service'
import LOGOUT from './auth'

/**
 * Context change module
 * @redux
 * @reduxActionScope context
 * @module context
 */

/**
 * Update the context object
 * @type {Redux.ActionType}
 */
export const CONTEXT_UPDATE = 'context/CONTEXT_UPDATE'
/**
 * Request a context change
 * @type {Redux.ActionType}
 */
export const CONTEXT_REQUEST_CHANGE = 'context/CONTEXT_REQUEST_CHANGE'
/**
 * Cancel a context change
 * @type {Redux.ActionType}
 */
export const CONTEXT_CANCEL_CHANGE = 'context/CONTEXT_CANCEL_CHANGE'
/**
 * Change Skip context
 * @type {Redux.ActionType}
 */
export const UPDATE_SKIP_CONTEXT = 'context/UPDATE_SKIP_CONTEXT'

const initialState = {
  property: null,
  project: null,
  changeRequested: false,
  skipContext: false
}

/**
 * Authentication reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case CONTEXT_UPDATE:
      return {
        ...state,
        project: action.project,
        property: action.property,
        changeRequested: false
      }
    case UPDATE_SKIP_CONTEXT:
      return {
        ...state,
        skipContext: action.payload
      }

    case CONTEXT_REQUEST_CHANGE:
      return {
        ...state,
        changeRequested: true
      }

    case CONTEXT_CANCEL_CHANGE:
      return {
        ...state,
        changeRequested: false
      }

    case LOGOUT:
      return initialState

    default:
      return state
  }
}

/**
 * Updates the current context
 * @redux
 * @reduxActionCreator CONTEXT_UPDATE
 * @param {string} project - the project for the new context
 * @param {string} property - the property for the new context
 */
export const updateContext = (project, property, onSuccess = () => {}) => {
  Service.setCtxCookie(project, property)
  if (onSuccess) {
    onSuccess()
  }
  return dispatch => {
    dispatch({
      type: CONTEXT_UPDATE,
      project,
      property
    })
  }
}

/**
 * Requests a skip context change
 * @redux
 * @reduxActionCreator UPDATE_SKIP_CONTEXT
 * @param {string} skipContext
 */
export const updateSkipContext = skipContext => {
  return dispatch => {
    dispatch({
      type: UPDATE_SKIP_CONTEXT,
      payload: skipContext
    })
  }
}

/**
 * Requests a context change
 * @redux
 * @reduxActionCreator CONTEXT_REQUEST_CHANGE
 */
export const requestContextChange = () => {
  return dispatch => {
    dispatch({
      type: CONTEXT_REQUEST_CHANGE
    })
  }
}

/**
 * Cancels a context change
 * @redux
 * @reduxActionCreator CONTEXT_CANCEL_CHANGE
 */
export const cancelContextChange = () => {
  return dispatch => {
    dispatch({
      type: CONTEXT_CANCEL_CHANGE
    })
  }
}
