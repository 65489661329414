import Service from '../service'
const SIDEBAR_OPEN = 'sidebar/SIDEBAR_OPEN'
const SIDEBAR_CLOSE = 'sidebar/SIDEBAR_CLOSE'
const SIDEBAR_TOGGLE = 'sidebar/SIDEBAR_TOGGLE'
const SERVICES_REQUESTED = 'sidebar/SERVICES_REQUESTED'
const SERVICES_FAIL = 'sidebar/SERVICES_FAIL'
const SERVICES_SUCCESS = 'sidebar/SERVICES_SUCCESS'

const initialState = {
  isOpen: true,
  services: [],
  servicesRequested: false,
  servicesError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        isOpen: true
      }
    case SIDEBAR_CLOSE:
      return {
        ...state,
        isOpen: false
      }
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    case SERVICES_REQUESTED:
      return {
        ...state,
        servicesRequested: true,
        servicesError: false
      }
    case SERVICES_FAIL:
      return {
        ...state,
        servicesRequested: false,
        servicesError: true,
        services: []
      }
    case SERVICES_SUCCESS:
      return {
        ...state,
        servicesRequested: false,
        servicesError: false,
        services: action.services
      }
    default:
      return state
  }
}

export const openSidebar = () => {
  return dispatch => {
    dispatch({
      type: SIDEBAR_OPEN
    })
  }
}

export const closeSidebar = () => {
  return dispatch => {
    dispatch({
      type: SIDEBAR_CLOSE
    })
  }
}

export const toggleSidebar = () => {
  return dispatch => {
    dispatch({
      type: SIDEBAR_TOGGLE
    })
  }
}

/**
 * Gets all services list
 * @redux
 * @reduxActionCreator SERVICES_REQUESTED, SERVICES_FAIL, SERVICES_SUCCESS
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getServicesByProperty = (pdbid, context) => {
  return async dispatch => {
    dispatch({
      type: SERVICES_REQUESTED
    })

    try {
      const data = await (await Service.getServicesByProperty(
        pdbid,
        context
      )).json()
      dispatch({
        type: SERVICES_SUCCESS,
        services: _servicesAdapter(data)
      })
    } catch (err) {
      dispatch({
        type: SERVICES_FAIL
      })
    }
  }
}

const _servicesAdapter = services => {
  return services.map(service => ({
    ...service,
    name: service.servicetype,
    path: `/services?s=${service.servicetype}`
  }))
}
