import React from 'react'
import Breadcrumbs from '../breadcrumbs'
import DamagesSingleInspectionTable from '../damages-single-inspection-table'
import { Link } from 'react-router-dom'

const DamagesSingleInspectionMain = props => {
  const { location } = props
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <Link to="/inspections/damageinspection" className="p-none">
          Back to List View
        </Link>
      </div>
      <div className="section">
        <DamagesSingleInspectionTable {...props} />
      </div>
    </div>
  )
}

export default DamagesSingleInspectionMain
