import Service from '../service'
import { formatDateString } from '../utils'
import moment from 'moment'

/**
 * Approvals data module
 * @redux
 * @reduxActionScope approvals
 * @module approvals
 */

export const GET_ALL_APPROVALS_LIST_REQUESTED =
  'charge/GET_ALL_APPROVALS_LIST_REQUESTED'
export const GET_ALL_APPROVALS_LIST_FAIL = 'charge/GET_ALL_APPROVALS_LIST_FAIL'
export const GET_ALL_APPROVALS_LIST_SUCCESS =
  'charge/GET_ALL_APPROVALS_LIST_SUCCESS'

const initialState = {
  allApprovals: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  }
}

/**
 * Request Approval reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_APPROVALS_LIST_REQUESTED:
      return {
        ...state,
        allApprovals: {
          ...state.allApprovals,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case GET_ALL_APPROVALS_LIST_SUCCESS:
      return {
        ...state,
        allApprovals: {
          ...state.allApprovals,
          isRequesting: false,
          isError: false,
          data: action?.data || []
        }
      }
    case GET_ALL_APPROVALS_LIST_FAIL:
      return {
        ...state,
        allApprovals: {
          ...state.allApprovals,
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    default:
      return state
  }
}

export const getApprovalsList = (
  pdbid,
  context,
  to,
  from,
  user_id,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_APPROVALS_LIST_REQUESTED
    })
    Service.getApprovalsList(pdbid, context, to, from, user_id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_ALL_APPROVALS_LIST_SUCCESS,
            data: _allApprovalsAdapter(data || [])
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: GET_ALL_APPROVALS_LIST_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
        dispatch({
          type: GET_ALL_APPROVALS_LIST_FAIL,
          data: []
        })
      })
  }
}

export const bulkApprovalPurchaseOrder = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return () => {
    Service.bulkApprovalPurchaseOrder(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const DV = ''

const getStatus = data => {
  const { status } = data || {}
  const comparableStatus = (status || '').toString().toLowerCase()
  if (comparableStatus === 'draft' || comparableStatus === 'sent') {
    return 'Pending'
  }
  if (comparableStatus === 'approved') {
    return 'Approved'
  }

  if (comparableStatus === 'pending ap approval') {
    return 'Pending AP Approval'
  }

  if (comparableStatus === 'rejected') {
    return 'Rejected'
  }
  return ''
}

const _oneAllApprovalsAdapter = data => {
  const total_amount = data?.total_amount?.toString()?.trim()
  const amount_paid = data?.amount_paid?.toString()?.trim()

  return {
    total_amount: !isNaN(total_amount)
      ? parseFloat(total_amount).toFixed(2)
      : total_amount,
    amount_paid: !isNaN(amount_paid)
      ? parseFloat(amount_paid).toFixed(2)
      : amount_paid,
    complete_date:
      typeof data?.complete_date === 'string' ? data.complete_date : DV,
    custom_complete_date:
      typeof data?.complete_date === 'string'
        ? formatDateString(data?.complete_date, 'MM/DD/YYYY')
        : DV,
    purchase_order_id:
      typeof data?.purchase_order_id === 'string' ? data.purchase_order_id : DV,
    po_number: typeof data?.po_number === 'string' ? data.po_number : DV,
    status: getStatus(data),
    invoice_file:
      typeof data?.invoice_file === 'string' ? data.invoice_file : DV,
    property: typeof data?.property === 'string' ? data.property : DV,
    vendor_id: typeof data?.vendor_id === 'string' ? data.vendor_id : DV,
    vendor_name: typeof data?.vendor_name === 'string' ? data?.vendor_name : DV,
    vendor_purchase_order_number:
      typeof data?.vendor_purchase_order_number === 'string'
        ? data.vendor_purchase_order_number
        : DV,
    model: typeof data?.model === 'string' ? data.model : DV,
    po_date:
      typeof data?.po_date === 'string'
        ? moment(data?.po_date).format('MM/DD/YYYY')
        : DV,
    due_date:
      typeof data?.due_date === 'string'
        ? moment(data?.due_date).format('MM/DD/YYYY')
        : DV,
    approved_at:
      typeof data?.approved_at === 'string'
        ? moment(data?.approved_at).format('MM/DD/YYYY HH:mm:ss')
        : DV,
    approved_by: typeof data?.approved_by === 'string' ? data.approved_by : DV,
    approved_by_name:
      typeof data?.approved_by_name === 'string' ? data.approved_by_name : DV,
    do_not_sync: data?.do_not_sync
  }
}

const _allApprovalsAdapter = data => {
  return Array.isArray(data) ? data.map(d => _oneAllApprovalsAdapter(d)) : []
}
