import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ProgressCardItems from '../progress-card-items'
import './index.scss'

/**
 * Component for showing a progress bar card
 *
 * @component
 * @example
 * const stats= {
 *   "not_assigned": 0,
 *   "assigned": 0,
 *   "in_progress": 0,
 *   "ready_for_qc": 0,
 *   "pending_approval": 0,
 *   "approved": 0,
 *   "days_to_move_in": 0,
 *   "total_beds": 0,
 *   "total_renewals": 0,
 *   "total_to_turn": 0,
 *   "percent_complete": 0,
 *   "days_remaining": 0
 * }
 * return (
 *   <ProgressCard
 *      stats={stats}
 *      caption="Days Until Move-In"
 *      showLoading={false}
 *      showProgress
 *   />
 * )
 */
const ProgressCard = ({
  caption,
  stats,
  className,
  showLoading,
  showProgress = true
}) => {
  const showSpinner = () => <FontAwesomeIcon icon={faSpinner} spin />
  const [progresstemsInfo, setProgressItemsInfo] = useState([])
  const { isOpen } = useSelector(state => state.sidebar)

  useEffect(() => {
    setProgressItemsInfo([
      {
        title: 'Units',
        completed: stats.completed_units,
        notNeeded: stats.not_needed_units,
        remaining: stats.remaining_units,
        total: stats.total_units,
        extraInfo: {
          title: 'Not Needed - Breakdown',
          items: [
            { label: 'As-Is', value: stats.as_is_beds },
            { label: 'Renewals', value: stats.renewal_beds },
            { label: 'No Service Needed', value: stats.no_service_beds },
          ]
        }
      },
      {
        title: 'Spaces',
        completed: stats.completed_beds,
        notNeeded: stats.not_needed_beds,
        remaining: stats.remaining_beds,
        total: stats.total_beds,
        extraInfo: {
          title: 'Not Needed - Breakdown',
          items: [
            { label: 'As-Is', value: stats.as_is_units },
            { label: 'Renewals', value: stats.renewal_units },
            { label: 'No Service Needed', value: stats.no_service_units },
          ]
        }
      },
    ])
  }, [stats])

  return showLoading ? (
    <div
      className="progress-card box animated fadeInRight p-t-none">
      <div className="column has-text-centered">
        {showSpinner()}
      </div>
    </div>
  ) : (
    <SimpleBar className={`simplebar-custom ${isOpen ? 'open-sidebar' : ''}`}>
      <div className={`progress-card box p-t-none p-b-none p-l-none p-r-none ${className ||''} ${isOpen ? 'open-sidebar' : ''}`}>
        <ProgressCardItems itemsInfo={progresstemsInfo} />
      </div>
    </SimpleBar>
  )
}

ProgressCard.propTypes = {
  /**
   * Title caption for the card
   */
  caption: PropTypes.string,
  /**
   * Stats rws to show
   */
  stats: PropTypes.object,
  /**
   * Additional class to be included in main DIV
   */
  className: PropTypes.string,
  /**
   * Determine if it is currently loading data
   */
  showLoading: PropTypes.bool,
  /**
   * Determine if progress bars should be shown
   */
  showProgress: PropTypes.bool
}

export default ProgressCard
