import React,{ useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  allVendorServiceGet
} from '../../modules/vendor-services'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import './index.scss'

const AddVendorModal = ({ onClose, user, context, addVendor, allVendorServiceGet, vendorServices, checkVendorExists }) => {
    
  useEffect(() => {
    const {user_metadata} = user
    const { pdbid } = user_metadata 
    allVendorServiceGet(pdbid, context)
  }, [])
    
  const { allVendorService } = vendorServices || {}
  const { data } = allVendorService || {}
  
  const [selectedServices, setSelectedServices] = useState([])
  const [businessName, setBusinessName] = useState('')
  const [contactName, setContactName] = useState('')
  const [message, setMessage] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errors, setErrors] = useState({})

  const saveVendor = (event) => {
    event.preventDefault()
    const convertServicesToAcceptableValue = (selectedServices.map(sServices => sServices.servicetype_id) || []).join(',')
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}

    if(businessName === '' || contactName === '' || emailAddress === '') {
      setErrorMessage('You need to fill all the required fields')
      return
    }

    setSubmitting(true)

    const body = {
      'vendor_name': businessName,
      'contact_name': contactName,
      'email': emailAddress,
      'services':convertServicesToAcceptableValue
    }
    
    addVendor({
      pdbid, 
      context, 
      body,
      onSuccess: () => {
        setMessage('Vendor added successfully.')
        setSubmitting(false)
        onClose()
        setErrors({})
      },
      onError: (error) => {
        setSubmitting(false)
        setErrorMessage(error)
      }
    })
  }

  const checkErrors = ({ target }, fieldName) => {
    const { value, name, type } = target || {}
    
    if(!value) {
      setErrors(
        {
          ...errors,
          [name]: `${fieldName || name} is required.`
        }
      )

      return
    }

    if(type === 'email' && !value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      setErrors(
        {
          ...errors,
          [name]: 'Invalid Email address.'
        }
      )

      return
    }
      
    const getVal = {
      attbrName : [name]
    }
    delete errors[getVal.attbrName]
    setErrors(errors)
    
  }

  const checkIfVendorExists = ({ target }, displayText) => {
    const { value, name } = target || {}
    const checkErrorExists =  (errors[name] || '')
    if(value && !checkErrorExists) {
      const body = {
        [name]: value,
      }
      const { user_metadata } = user || {}
      const { pdbid } = user_metadata || {}

      console.log('before submit', {
        context
      })

      checkVendorExists({
        pdbid, 
        context, 
        body,
        name,
        onSuccess: (response) => {
          const { exists } = response || {}
          if(exists === '1') {
            setErrors(
              {
                ...errors,
                [name]: `${displayText || name} Already Exists`
              }
            )
          }    
        },
        onError: (error) => {
          setErrorMessage(error)
        }
      })
    }
  }

  const checkCanSubmit = (isSubmitting || (selectedServices || []).length === 0 || (Object.keys(errors) || []).length > 0)

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            New Vendor
          </p>
        </div>
      </div>
      <div className="is-full">
        <form>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label htmlFor="name">
                  Business Name:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <input id="name" name="name" required type="text" placeholder="" className="form-control " onBlur = { (event) => {
                checkErrors(event, 'Name')
                checkIfVendorExists(event)
              }} onChange={ (event) => {
                const { target } = event
                const { value } = target || {}
                setBusinessName(value)
                checkErrors(event)
              }}></input>
              { errors['name'] && 
                <div className="help-block text-danger">
                  {
                    errors['name']
                  }
                </div>
              }
            </div>
            
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label htmlFor="contactName">
                  Contact Name:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <input id="contactName" name="contactName" type="text" required placeholder="" className="form-control "
                onChange={ (event) => {
                  const { target } = event
                  const { value } = target || {}
                  setContactName(value)
                  checkErrors(event)
                }} onBlur = { (event) => { 
                  checkErrors(event, 'Contact Name')
                }}></input>
              { errors['contactName'] && 
                  <div className="help-block text-danger">
                    {
                      errors['contactName']
                    }
                  </div>
              }
            </div>
            
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label htmlFor="email">
                  Email Address:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <input id="email" type="email" name="email" required placeholder="" className="form-control "
                onChange={ (event) => {
                  const { target } = event
                  const { value } = target || {}
                  setEmailAddress(value)
                  checkErrors(event)
                }} onBlur = { (event) => {
                  checkErrors(event, 'Email')
                  checkIfVendorExists(event)
                }}></input>
              { errors['email'] && 
                  <div className="help-block text-danger">
                    {
                      errors['email']
                    }
                  </div>
              }
            </div>
            
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>
                  Services:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <MultiSelectDropdown displayKey="servicetype" selectionLimit={10} value={selectedServices} defaultValues={data} onChange={(v) => setSelectedServices(v)} />
            </div>
            
          </div>
          {
            message !== '' && <div className="alert alert-success">
              { message }
            </div>
          }
          {
            errorMessage !== '' && <div className="alert alert-danger">
              { errorMessage }
            </div>
          }
          <div className="columns is-vcentered m-b-sm m-t-sm">
            <div className="center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => {
                  onClose()
                  setErrors({})
                }}>
                  Cancel
              </button>

              <button disabled={checkCanSubmit} className="button main-button is-primary" onClick={ (event) => saveVendor(event) }>
                  Create
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  )
}

AddVendorModal.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object,
  addVendor: PropTypes.func,
  allVendorServiceGet: PropTypes.func,
  vendorServices: PropTypes.object,
  checkVendorExists: PropTypes.func,
}


const mapStateToProps = ({ context, vendorServices }) => ({
  context,
  vendorServices
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      allVendorServiceGet
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVendorModal)
