import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './index.scss'

const InlineTabs = ({ tabs, style }) => {
  const [tabOptionsCopy, setTabOptionsCopy] = useState([...tabs])

  const handleTabOptionsClick = useCallback(tab => {
    tab.onClick(tab)
    setTabOptionsCopy(currentTabs => currentTabs.map(item => {
      item === tab ? item.active = true : item.active = false
      return item
    }))
  }, [tabs])

  return (
    <div className="inline-tab-options-container" style={{...style}}>
      <ul className="inline-tab-options">
        {tabOptionsCopy.map(tab => (
          <li
            key={tab.label}
            className={cn({'active': tab.active}, {'disabled': tab.disabled})}
            onClick={() => handleTabOptionsClick(tab)}>
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

InlineTabs.propTypes = {
  tabs: PropTypes.array,
}

export default InlineTabs
