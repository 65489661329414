import React from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'

const Droppable = ({ dropAction, dropData, dropType, dropClass, children, canDrop }) => {
  const dragHandler = (item) => {
    dropAction(dropData, item)
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: dropType,
    canDrop,
    drop: (item, monitor) => {
      dragHandler(item)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  }), [canDrop])

  return (
    <div
      ref={drop}
      className={dropClass ? dropClass.join(' ') : ''}
      style={isOver ? { border: '2px dotted #3DB3E2' } : {}}>
      {children}
    </div>
  )
}

Droppable.propTypes = {
  canDrop: PropTypes.func,
  dropAction: PropTypes.func,
  dropClass: PropTypes.array,
  dropData: PropTypes.object,
  dropType: PropTypes.string,
}

export default Droppable
