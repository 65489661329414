import React, {
  forwardRef,
  memo,
  useImperativeHandle,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import {
  useBlockLayout,
  useFilters,
  useSortBy,
  useTable,
  useRowSelect,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import './vendor-table.scss'

import SortIcon from '../../../components/common/SortIcon/SortIcon'
import Paginator from '../../../components/react-table-pagination'

import VendorTableCheckbox from './vendor-table-checkbox'
import DefaultColumnFilter from '../../../components/react-table-column-filters/DefaultColumnFilter'

import { VendorTableStyles } from './vendor-table-styles'

const VendorTable = ({ data, tableColumns, setIsFilterApplied }, ref) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  )

  const filterTypes = React.useMemo(
    () => ({
      customEquals: (rows, id, filterValue) => {
        if (!filterValue) return rows
        id = id[0]
        return rows.filter(row => filterValue?.[id] === row.values?.[id])
      },
      multiEquals: (rows, id, filterValue) => {
        if ((filterValue || []).length === 0) return rows

        id = id[0]
        const commaSeperatedColumns = id === 'simplifiedServices'

        return (rows || []).filter(row => {
          if (commaSeperatedColumns) {
            const rowVals = row?.values?.[id]
              ?.split(',')
              .map(x => x.trim())
              .filter(x => x)

            return filterValue?.some(filterVal =>
              rowVals.includes(filterVal?.[id])
            )
          } else {
            return filterValue?.some(
              filterVal => filterVal?.[id] === row.values?.[id]
            )
          }
        })
      }
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    previousPage,
    page,
    rows,
    gotoPage,
    setPageSize,
    prepareRow,
    state: { pageSize, pageIndex, filters, selectedRowIds },
    selectedFlatRows,
    setAllFilters,
    toggleAllRowsSelected
  } = useTable(
    {
      columns: tableColumns,
      data,
      defaultColumn,
      filterTypes,
      sortTypes: {
        alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
          const rowOneColumn = row1?.values[columnName]
          const rowTwoColumn = row2?.values[columnName]
          return (rowOneColumn || '').toString().toLowerCase() >
            (rowTwoColumn || '').toString().toLowerCase()
            ? 1
            : -1
        }
      },
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        sortBy: [{ id: 'name', desc: false }]
      }
    },
    useBlockLayout,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 45,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <VendorTableCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div style={{ marginTop: '3px' }}>
              <VendorTableCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ])
    }
  )

  useImperativeHandle(ref, () => ({
    getRows: () => {
      return (rows || []).map(row => row.original)
    },

    getSelectedRows: () => {
      return (selectedFlatRows || []).map(row => row.original)
    },
    clearFilter: setAllFilters,
    toggleAllRowsSelected: toggleAllRowsSelected
  }))

  useEffect(() => {
    setIsFilterApplied(filters)
  }, [filters])

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = page[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style
          })}
          className="tr">
          {row.cells.map(cell => {
            let styleBody = {}
            if (cell.column.minWidth !== 0) {
              styleBody.minWidth = cell.column.minWidth
            }
            if (cell.column.flexGrow !== 0) {
              styleBody.flexGrow = cell.column.flexGrow
            }
            return (
              <div
                {...cell.getCellProps({
                  style: {
                    textAlign: cell.column.textAlign,
                    width: cell.column.width,
                    ...styleBody
                  }
                })}
                className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows, page]
  )

  return (
    <div>
      <VendorTableStyles>
        <div {...getTableProps()} className="vendor-company-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr full-header">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width,
                        minWidth: column.minWidth,
                        flexGrow: column.flexGrow
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      <span {...column.getSortByToggleProps()}>
                        <SortIcon column={column} />
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            {(rows || []).length ? (
              <FixedSizeList
                className="full-body list-container"
                height={300}
                itemCount={(page || []).length}
                itemSize={35}
                itemData={{ page, prepareRow }}>
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      </VendorTableStyles>

      <Paginator
        previousPage={previousPage}
        nextPage={nextPage}
        rowsPerPage={pageSize}
        rowCount={rows.length}
        currentPage={pageIndex + 1}
        onChangePage={page => gotoPage(page - 1)}
        setRowsPerPage={pageSize => {
          setPageSize(pageSize)
        }}
        limit={[30, 50, 70, 100]}
      />
    </div>
  )
}

export default memo(forwardRef(VendorTable))
