const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const headerData = [
  {
    key: 'service',
    sortDisplay: 'Service',
    display: ''
  },
  {
    key: 'budget',
    display: 'Budget',
    textAlign: 'right',
    format: value => formatter.format(value)
  },
  {
    key: 'estimate',
    display: 'Estimate',
    textAlign: 'right',
    format: value => formatter.format(value)
  },
  {
    key: 'actual',
    display: 'Actual',
    textAlign: 'right',
    format: value => formatter.format(value)
  },
  {
    key: 'difference',
    display: 'Difference',
    textAlign: 'right',
    format: value => formatter.format(value)
  }
]
