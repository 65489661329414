import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle
} from 'react'
import PropTypes from 'prop-types'
import { headerData } from './header'
import { icons } from './icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { addToPo, addChargesToPO, allPONumbersGet } from '../../modules/charge'
import { resetPurchaseOrderDetail } from '../../modules/purchase-orders'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import BasicLoading from '../../components/basic-loading'
import { useHistory } from 'react-router-dom'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy
} from 'react-table'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { getLocalTimeISOString } from '../../utils'

import './index.scss'

// Table styles
const Styles = styled.div`
  max-width: 100%;
  position: relative;
  input {
    border: 1px solid transparent;
    margin: 0px auto;
  }
  .po-table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid #e5e9f2;
    position: relative;

    tr {
      position: relative;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      padding: 0px;
    }

    th,
    td {
      margin: 0;
      position: relative;
    }
  }
`

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={'Search'}
    />
  )
}

const Table = memo(
  forwardRef(({ columns, data }, ref) => {
    // Use the state and functions returned from useTable to build your UI

    const defaultColumn = React.useMemo(
      () => ({
        width: 100,
        Filter: DefaultColumnFilter
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data,
        defaultColumn
      },
      useBlockLayout,
      useFilters,
      useSortBy,
      useRowSelect
    )

    useImperativeHandle(ref, () => ({
      getRows: () => {
        return rows?.map(row => row.original)
      }
    }))

    // Render the UI for your table
    return (
      <table {...getTableProps()} className="po-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          textAlign: cell.column.textAlign,
                          width: cell.column.width
                        }
                      })}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  })
)

const TurnboardViewAllNotes = props => {
  const {
    onClose,
    user,
    context,
    selectedPORows,
    addToPo,
    setUpdateChargesList,
    addChargesToPO,
    allPONumbersGet,
    charge
  } = props || {}

  // State
  const [errorMessage, setErrorMessage] = useState('')
  const [poData, setPOData] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState('')
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState('')
  const [isPONumbersLoading, setIsPONumbersLoading] = useState(true)

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}
  const history = useHistory()

  // Redux
  const { allPONumber } = charge || {}

  const amendAllPONumber = useMemo(() => {
    if ((allPONumber || []).length) {
      return [
        {
          po_id: 'new-purchase-order',
          po_number: 'New Purchase Order'
        },
        ...allPONumber
      ]
    }
    return [
      {
        po_id: 'new-purchase-order',
        po_number: 'New Purchase Order'
      }
    ]
  }, [allPONumber])

  useEffect(() => {
    if ((selectedPORows || []).length) {
      setPOData(selectedPORows)
    }
  }, [selectedPORows])

  useEffect(() => {
    if ((selectedPORows || []).length) {
      setIsPONumbersLoading(true)
      allPONumbersGet(
        pdbid,
        context,
        selectedPORows[0]?.vendor_id,
        () => {
          setIsPONumbersLoading(false)
        },
        () => {
          setIsPONumbersLoading(false)
        }
      )
    }
  }, [context, pdbid, selectedPORows])

  const tableRef = useRef()

  // Icons
  const { close, closeFilled } = icons || {}

  const tableColumns = headerData()

  const columns = useMemo(() => tableColumns)

  const addNewChargesToPO = data => {
    if ((data || []).length) {
      const invoiceId = data[0]?.po_id || ''
      const getLineData = (poData || []).map(line => {
        const { task_id, po_line_id, qty, amount, unit_space } = line || {}
        const getEditType = (task_id || '').toString().toLowerCase()
        if ((getEditType || '').includes('note')) {
          line.note_id = task_id || ''
          line.task_id = null
        }
        if ((getEditType || '').includes('task')) {
          line.task_id = task_id || ''
          line.note_id = null
        }
        line.po_line_id = po_line_id || null
        line.do_delete = null
        line.quantity = qty || 0
        line.rate = amount || 0
        line.unit_space = unit_space || null
        return line
      })
      const body = {
        ...data[0],
        po_lines: getLineData
      }
      addChargesToPO({
        pdbid,
        context,
        invoiceId,
        body,
        onSuccess: () => {
          setErrorMessage('')
          setSubmitting(false)
          resetPurchaseOrderDetail()
          history.push(
            `/payables/purchase-orders/${data[0]?.po_id ||
              ''}/${selectedPORows[0]?.vendor_id || ''}`
          )
          setUpdateChargesList(true)
          onClose()
        },
        onError: () => {
          setSubmitting(false)
          setErrorMessage('Something went wrong, Please try again later !!!')
        }
      })
    }
  }

  const onSuccess = data => {
    addNewChargesToPO(data)
  }

  const onError = () => {
    setSubmitting(false)
    setErrorMessage('Something went wrong, Please try again later !!!')
  }

  const amendChargesToPO = () => {
    setSubmitting(true)
    addNewChargesToPO(selectedPurchaseOrder)
  }

  const onSave = () => {
    if (selectedPurchaseOrderId?.po_id === 'new-purchase-order') {
      if ((selectedPORows || []).length) {
        setSubmitting(true)
        const body = {
          vendor_id: selectedPORows[0]?.vendor_id,
          status: 'Draft',
          po_date: getLocalTimeISOString()
        }
        return addToPo(pdbid, context, body, onSuccess, onError)
      }
      return
    }
    return amendChargesToPO()
  }

  return (
    <div id="po-container">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <h2 className="is-pulled-left modal-sync-title">Add to PO</h2>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      {isPONumbersLoading ? (
        <BasicLoading />
      ) : (
        <>
          <div>
            <Styles>
              <Table ref={tableRef} columns={columns} data={poData} />
            </Styles>
          </div>
          <div className="op-modal">
            <form>
              <div className="form-group columns">
                <div className="label column is-two-third">
                  <label>Purchase Order:</label>
                </div>
                <div className="input-field column is-two-thirds">
                  <MultiSelectDropdown
                    displayKey="po_number"
                    value={selectedPurchaseOrderId}
                    defaultValues={amendAllPONumber}
                    onChange={v => {
                      setErrorMessage('')
                      setSelectedPurchaseOrderId(v)
                      if (v?.po_id !== 'new-purchase-order') {
                        const poNumber = (allPONumber || []).filter(
                          po => po?.po_id === v?.po_id
                        )
                        if ((poNumber || []).length) {
                          setSelectedPurchaseOrder(poNumber)
                        }
                      }
                    }}
                    isMulti={false}
                    placeholder="Select"
                    noOptionsMessage="No PO Available"
                    customStyles={{
                      valueContainer: provided => ({
                        ...provided,
                        minHeight: '35px',
                        maxHeight: '35px',
                        overflow: 'auto',
                        position: 'relative',
                        borderRadius: '3px'
                      }),
                      control: (base, state) => ({
                        ...base,
                        border: '1px solid #e5e9f2',
                        boxShadow: 'none',
                        '&:hover': {
                          border: '1px solid #b5b5b5'
                        },
                        backgroundColor: state.isDisabled
                          ? 'ghostwhite'
                          : base.backgroundColor
                      }),
                      container: styles => ({
                        ...styles,
                        pointerEvents: 'all'
                      }),
                      clearIndicator: provided => {
                        return {
                          ...provided,
                          padding: '3px'
                        }
                      },
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#122048',
                        cursor: 'pointer',
                        '&:hover': {
                          color: '#122048'
                        }
                      }),
                      menuList: base => ({
                        ...base,
                        maxHeight: '250px'
                      })
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
          <div className="columns is-vcentered m-b-sm m-t-sm">
            <div className="center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => {
                  onClose()
                }}>
                Cancel
              </button>
              <button
                className="button main-button is-primary"
                disabled={!Object.keys(selectedPurchaseOrderId || []).length}
                onClick={event => {
                  event.preventDefault()
                  onSave()
                }}>
                {isSubmitting ? 'Adding' : 'Add'}
                {isSubmitting && (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

TurnboardViewAllNotes.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ user, context, charge }) => ({
  user,
  context,
  charge
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToPo,
      addChargesToPO,
      allPONumbersGet
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnboardViewAllNotes)
