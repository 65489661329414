import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

const Accordion = ({ title, children, isEditMode=true, isOpen=true, actionButtons=[], state=null, updateActiveButtonContent, parentId='',childId='', additionalClassName='', spaceState='', isDisabled = false }) => {
  const [setOpen, setOpenState] = useState(isOpen)
  const [currentButtonState, setCurrentButtonState] = useState(state)

  useEffect(() => {
    setCurrentButtonState(state)
  },[state])

  const toggleAccordion = () => {
    if(isEditMode) {
      setOpenState(!setOpen)
    }
  }

  const updateCurrentButtonState = (state) => {
    setCurrentButtonState(state)
    if(updateActiveButtonContent) {
      updateActiveButtonContent({
        parentId,
        childId,
        state
      })
    }
  }

  const getActionButton = (state) => {
    let className = ''
    if(state === 'Good') {
      className = 'is-good'
    } if(state === 'Needs Work') {
      className = 'is-needs-work'
    }
    return <div className="child-accordion-btn is-flex is-flex-wrap">
      {(actionButtons || []).map((e, i) => <button key={i} className={`button ${state === e ? className : ''}`} onClick={() => {
        updateCurrentButtonState(e)
        if(isEditMode && e !=='Good') {
          setOpenState(true)
        }
        if(isEditMode && e ==='Good') {
          setOpenState(false)
        }
      }}>{e}</button>)}
      {(actionButtons || []).length !== 0 && <div className="is-flex-row"><p>Good = no visible damage; only minor imperfections</p></div>}
    
    </div>
  }

  return (
    <div className={`accordion-section ${additionalClassName}`}>
      <button className="accordion" style={{cursor: isDisabled ? 'not-allowed' : 'default'}}>
        <div className="is-flex">
          <p className={`accordion-title  ${state !== null && 'accordion-title-active'}`}>
            {title}
            {spaceState && <span>{` (${spaceState})`}</span>}
          </p>
          {getActionButton(currentButtonState)}
        </div>
        {!isDisabled && <FontAwesomeIcon
          icon={setOpen === true ? faChevronUp : faChevronDown}
          onClick={toggleAccordion}
        />}
      </button>
      <div
        className="accordion-content"
      >
        <div
          className={`accordion-text ${setOpen === true ? 'accordion-content-active' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
