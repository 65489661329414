import moment from 'moment-timezone/moment-timezone'
import _ from 'lodash'

// Date utils

export const formatDateString = (
  dateString,
  format = 'MM/DD/YYYY hh:mm:ss A'
) => {
  const mDate = moment.utc(new Date(dateString))
  return mDate.format(format)
}

// Returns a Date string in ISO format but formatted to local time instead of UTC
export const getLocalTimeISOString = () => {
  const tzoffset = new Date().getTimezoneOffset() * 60000
  return new Date(Date.now() - tzoffset).toISOString().slice(0, -1) + 'Z'
}

// Formats ISO time strings (2022-09-16T00:00:00) as they are to MM/DD/YYYY
export const formatISODateString = dateString => {
  if (!dateString) return 'N/A'
  if (!dateString?.includes('T')) return dateString
  const arr = dateString.split('-')
  return `${arr?.[1]}/${arr?.[2]?.split('T')?.[0]}/${arr[0]}`
}

export const getInnerDates = (startDate, endDate) => {
  const dateArray = []
  let currentDate = new Date(startDate)

  while (currentDate <= new Date(endDate)) {
    dateArray.push(new Date(currentDate))
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
  }

  return dateArray
}

export const numberOfDays = (year = null, month = null) =>
  new Date(year, month, 0).getDate()

// Number utils

export const numberFormatter = (number, options) => {
  const formatter = new Intl.NumberFormat('en', { ...options })
  return formatter.format(number)
}

export const currencyFormatter = (
  value,
  { disableRounding } = { disableRounding: true }
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: disableRounding ? 0 : 2,
    maximumFractionDigits: disableRounding ? 20 : 2
  })
  return formatter.format(value)
}

// traversing dom utilities

export const isDescendant = (el, parentId) => {
  let isChild = false

  if (el.id === parentId) {
    isChild = true
  }

  // eslint-disable-next-line
  while ((el = el.parentNode)) {
    if (el.id === parentId) {
      isChild = true
    }
  }

  return isChild
}

// color utilities

export const hexToRGB = (hex, a = 1) => {
  hex = hex.replace('#', '')

  if (hex.length !== 6) {
    throw new Error('Only six-digit hex colors are allowed')
  }

  const rgbHex = hex.match(/.{1,2}/g)
  const rgb = [
    parseInt(rgbHex[0], 16),
    parseInt(rgbHex[1], 16),
    parseInt(rgbHex[2], 16)
  ]

  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`
}

// Array utilities

// Remove duplicates
export const removeArrayDuplicates = items =>
  items.filter((c, index) => items.indexOf(c) === index)

// Others

export const isNewInvoice = invoice => invoice.status === 'new'

export const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length

const getValuesAsListsFromData = dataList => {
  return (dataList || []).map(item => {
    return (Object.keys(item) || []).map(key => {
      if (typeof item[key] === 'object') {
        return (_.map(item[key], 'name') || []).join(';')
      }
      return item[key]
    })
  })
}

const getObjectKeys = object => Object.keys(object || {})

const getCsvData = (keys, objectValuesLists) =>
  [...[keys], ...objectValuesLists].map(e => e.join(',')).join('\n')

const createDownloadableLink = (names, dateTime, csvData) => {
  const csvContent = 'data:text/csv;charset=utf-8,' + csvData
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')

  link.setAttribute('href', encodedUri)
  link.setAttribute(
    'download',
    names
      ? `${names.project}-${names.property}-${dateTime}.csv`
      : `${dateTime}.csv`
  )

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadCSV = (user, context, dataToExport) => {
  const { user_metadata } = user || {}
  const { properties, projects } = user_metadata || {}
  const { property: contextProperty, project: contextProject } = context || {}
  const property = properties.find(p => p.id === contextProperty)
  const project = projects.find(p => p.id === contextProject)

  let names = null
  if (!_.isEmpty(property) && !_.isEmpty(project)) {
    names = {
      property: property.name?.replace(/ /g, '_'),
      project: project.name?.replace(/ /g, '_')
    }
  }

  const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
  const valuesLists = getValuesAsListsFromData(dataToExport)
  const keys = getObjectKeys(dataToExport[0])
  const csvData = getCsvData(keys, valuesLists)

  createDownloadableLink(names, datetime, csvData)
}
