import React from 'react'
import DefaultColumn from '../../DataTable/pricing-form-table/pricing-table-columns/default-column'
import MultiSearchDropdownFilter from '../../DataTable/pricing-form-table/MultiSearchDropdownFilter'
import PriceField from '../EditFields/PriceField'

export const headerData = ({ isEdit, onHandleAmountChange }) => {
  return [
    {
      Header: 'Service Group',
      accessor: 'name',
      Filter: MultiSearchDropdownFilter,
      filter: 'multiEquals',
      width: 300,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Floorplan',
      accessor: 'floor_plan',
      Filter: MultiSearchDropdownFilter,
      filter: 'multiEquals',
      width: 300,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Price',
      accessor: 'price',
      width: 150,
      textAlign: 'left',
      disableFilters: true,
      disableSortBy: true,
      Cell: row => {
        if (isEdit) {
          return (
            <PriceField row={row} onHandleAmountChange={onHandleAmountChange} />
          )
        }
        return <DefaultColumn row={row} />
      }
    }
  ]
}
