import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import DatePickerInput from '../../components/date-picker'
import { formatDateString } from '../../utils/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import _ from 'lodash'
import './index.scss'

const AddInspectionModal = props => {
  const {
    onClose,
    inspect,
    user,
    context,
    getInspectionUnitList,
    getInspectionList,
    getInspectionDownloadMediaURL,
    editInspectionData,
    getInspectionAssignToList,
    selectedInspectionUnits,
    selectedInspectionsData,
    bulkUpdateInspectionsProfile,
    setUpdateInspectionList
  } = props || {}
  // Redux
  const { inspectionUnitList, inspectionAssignToList } = inspect || {}
  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    getInspectionUnitList(pdbid, context)
    getInspectionAssignToList(pdbid, context)
  }, [])

  // States
  const [selectedUnits, setSelectedUnits] = useState([])
  const [allUnits, setAllUnits] = useState([])
  const [assignTo, setAssignTo] = useState('')
  const [dueDate, setDueDate] = useState(new Date())
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [enableDueDate, setEnableDueDate] = useState(false)

  useEffect(() => {
    if ((inspectionUnitList || []).length !== 0) {
      setAllUnits([])
      const getBuilding = (inspectionUnitList || []).flatMap(
        buildingList => buildingList.buildings
      )
      const getUnits = (getBuilding || []).flatMap(unitList => unitList.units)
      setAllUnits(getUnits || [])
    }
  }, [inspectionUnitList])

  // Re-fill inspection data into fields for update
  useEffect(() => {
    if ((selectedInspectionUnits || []).length !== 0) {
      if ((allUnits || []).length !== 0) {
        const getSelectedUnit = (allUnits || []).filter(item =>
          (selectedInspectionUnits || []).includes(item?.unit_name)
        )
        if ((getSelectedUnit || []).length !== 0) {
          setSelectedUnits(getSelectedUnit || [])
        }
      }
    }
  }, [editInspectionData, allUnits, inspectionUnitList])

  const onHandleAssignInspection = event => {
    event.preventDefault()
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    let data = []
    // Filter data
    ;(selectedInspectionsData || []).map(insp => {
      const {
        inspection_id,
        approved_by,
        approved_date,
        status,
        submitted_date,
        rawInspectionDate
      } = insp || {}
      if (enableDueDate) {
        data.push({
          inspection_id,
          approved_by,
          assigned_to: assignTo,
          approved_date,
          inspection_date: formatDateString(dueDate, 'DD-MMM-YY'),
          status,
          submitted_date
        })
      } else {
        data.push({
          inspection_id,
          approved_by,
          assigned_to: assignTo,
          approved_date,
          inspection_date: rawInspectionDate,
          status,
          submitted_date
        })
      }
    })
    bulkUpdateInspectionsProfile({
      pdbid,
      context,
      body: {
        items: data || []
      },
      onSuccess: () => {
        setUpdateInspectionList(true)
        onClose()
        setErrorMessage('')
      },
      onError: error => {
        setSubmitting(false)
        setErrorMessage('Something went wrong. Please try again later !!')
      }
    })
  }

  return (
    <div className="inspection-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Assign Inspections</p>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full">
        <form>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Unit:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <div className="unit-select">
                <MultiSelectDropdown
                  displayKey="unit_name"
                  isDisabled={true}
                  value={selectedUnits}
                  defaultValues={allUnits || []}
                  placeholder="Select"
                  customStyles={{
                    valueContainer: provided => ({
                      ...provided,
                      minHeight: '45px',
                      maxHeight: '100px',
                      overflow: 'auto',
                      position: 'relative'
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Assign To:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <select
                className="input is-inline"
                onChange={e => setAssignTo(e.target.value)}
                value={assignTo}>
                <option value="">Select</option>
                {(inspectionAssignToList || []).map((formVal, i) => {
                  const { name, auth_id } = formVal || {}
                  return (
                    <option value={auth_id || ''} key={i}>
                      {name || ''}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label>Due Date:</label>
            </div>
            <div className="input-field column is-two-thirds">
              <DatePickerInput
                isDisabled={!enableDueDate}
                value={dueDate}
                minDate={new Date()}
                onChange={v => setDueDate(v)}
              />
              <label
                style={{ marginTop: '5px' }}
                className={`checkbox ${enableDueDate && 'is-active'}`}>
                <span
                  className={`checkbox-input ${enableDueDate && 'is-active'}`}>
                  <span className="checkbox-inner" />
                  <input
                    type="checkbox"
                    className="checkbox-original"
                    checked={enableDueDate}
                    name="all_units"
                    value={enableDueDate}
                    onChange={() => setEnableDueDate(!enableDueDate)}
                  />
                </span>
                <span
                  className={`checkbox-label ${enableDueDate && 'is-active'}`}>
                  Enable Due Date
                </span>
              </label>
            </div>
          </div>
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
          <div className="columns is-vcentered m-b-sm m-t-sm">
            <div className="center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => {
                  onClose()
                }}>
                Cancel
              </button>
              <button
                className="button main-button is-primary"
                onClick={event => {
                  onHandleAssignInspection(event)
                }}>
                Assign
                {isSubmitting && (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AddInspectionModal.propTypes = {
  onClose: PropTypes.func,
  getInspectionUnitList: PropTypes.func,
  getInspectionFormList: PropTypes.func,
  addNewInspection: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object,
  inspect: PropTypes.object,
  editInspectionData: PropTypes.object,
  isEditInspection: PropTypes.bool,
  getInspectionAssignToList: PropTypes.func
}

const mapStateToProps = ({ inspect }) => ({
  inspect
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInspectionModal)
