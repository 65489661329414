export const ALLPROPERTIES = [
  {
    project_id: 'proj_11',
    project_name: 'Demo11',
    property_id: 'prop_1',
    property_name: 'Development Property'
  },
  {
    project_id: 'proj_12',
    project_name: 'Demo12',
    property_id: 'prop_1',
    property_name: 'Development Property'
  },
  {
    project_id: 'proj_2',
    project_name: 'New Turn 2023',
    property_id: 'prop_2',
    property_name: 'Development Property 2'
  },
  {
    project_id: 'proj_3',
    project_name: 'Twin Towers',
    property_id: 'prop_3',
    property_name: 'Trump Tower'
  },
  {
    project_id: 'proj_3',
    project_name: 'Twin Towers',
    property_id: 'prop_4',
    property_name: 'Real State 2023'
  },
  {
    project_id: 'proj_5',
    project_name: 'Real Madrid',
    property_id: 'prop_5',
    property_name: 'Team Of Wonders'
  }
]

export const ALLROLES = [
  {
    role_id: 'rol_Odkqku2YkXmrbdpB',
    name: 'Accounting',
    role_type: null
  },
  {
    role_id: 'rol_DdMv0wYdixv6Ex9N',
    name: 'Administrator',
    role_type: null
  },
  {
    role_id: 'rol_Yrd560BfSx67FlHj',
    name: 'Property Manager',
    role_type: null
  },
  {
    role_id: 'rol_n2Up75TZYNkXJ0jA',
    name: 'Staff',
    role_type: null
  },
  {
    role_id: 'rol_Y8fh2aEa2wPBGkaK',
    name: 'Vendor',
    role_type: 'Vendor'
  },
  {
    role_id: 'rol_dlx6f8ffXMjmoZch',
    name: 'View Only',
    role_type: null
  }
]

export const ALLPROJECTS = [
  {
    id: 'backend',
    name: 'Demo',
    properties: ['dev']
  },
  {
    id: 'backend 2',
    name: 'Demo 2',
    properties: ['dev3', 'dev2']
  }
]

export const ALLALLOWEDSECTION = [
  {
    name: 'inspectionSetup'
  },
  {
    name: 'inspectSideBarLink'
  },
  {
    name: 'inspections'
  },
  {
    name: 'reports'
  },
  {
    name: 'payableSideBarLink'
  },
  {
    name: 'payableApproval'
  },
  {
    name: 'payableApprovalSideBarLink'
  },
  {
    name: 'rfp'
  },
  {
    name: 'isAutomaticScheduling'
  }
]

export const ALLVENDOR = [
  {
    id: 'Vendor1212',
    name: 'Asif and Co 101'
  },
  {
    id: 'Vendor1224',
    name: 'Cleaning Vendor Ltd.'
  },
  {
    id: 'Vendor1222',
    name: 'Demo Vendor'
  },
  {
    id: 'Vendor1215',
    name: 'JAmes'
  },
  {
    id: 'Vendor1231',
    name: 'Jack Jack'
  },
  {
    id: 'Vendor1226',
    name: 'Jack Ma'
  },
  {
    id: 'Vendor1223',
    name: 'Jon Devv'
  },
  {
    id: 'Vendor1225',
    name: 'Kevin Hart'
  },
  {
    id: 'Vendor1227',
    name: 'Latest Vendor'
  },
  {
    id: 'Vendor1229',
    name: 'Malbow Jack'
  },
  {
    id: 'Vendor1244',
    name: 'Maula Jatt'
  },
  {
    id: 'Vendor1211',
    name: 'Munir and Coo'
  },
  {
    id: 'Vendor1228',
    name: 'Noor1'
  },
  {
    id: 'Vendor1220',
    name: 'QA Test vendor'
  },
  {
    id: 'Vendor1213',
    name: 'Shahbaz Sons'
  },
  {
    id: 'Vendor1230',
    name: 'Taha Vendor'
  },
  {
    id: 'Vendor1216',
    name: 'Test Paint Vendor'
  },
  {
    id: 'Vendor1245',
    name: 'Test Vendor'
  },
  {
    id: 'Vendor1219',
    name: 'Test Vendor 2'
  },
  {
    id: 'Vendor1217',
    name: 'Testing 1'
  },
  {
    id: 'Vendor1248',
    name: 'Tom'
  },
  {
    id: 'Vendor1214',
    name: 'abc'
  },
  {
    id: 'Vendor1221',
    name: 'abcabc'
  },
  {
    id: 'Vendor1239',
    name: 'chair '
  },
  {
    id: 'Vendor1207',
    name: 'hammasas'
  },
  {
    id: 'Vendor1237',
    name: 'new testing vendor 101'
  },
  {
    id: 'Vendor1233',
    name: 'new vendor testing'
  },
  {
    id: 'Vendor1208',
    name: 'saad'
  }
]

export const ALLSTAFF = [
  {
    id: 'Staff1028',
    name: 'Noori Nath'
  },
  {
    id: 'Staff1031',
    name: 'temp check staff'
  }
]

export const ALLUSERTYPE = [
  { name: 'Temporary' },
  { name: 'Part Time' },
  { name: 'Full Time' }
]

export const SAMPLEDATA = {
  name: 'Hammad',
  email: 'Hammad@gmail.com',
  permission_rules: [
    {
      permission_rule_id: '9089e65b-7dc7-4f01-920c-20d06878yu4gh454',
      role_id: 'rol_DdMv0wYdixv6Ex9N',
      property_id: 'dev',
      login_access: null,
      action: null,
      status: null,
      internal_permission_rule_id: '9089e65b-7dc7-4f01-920c-20d065660415'
    },
    {
      permission_rule_id: '9089e65b-7dc7-4f01-920c-20d06878yu4gh45',
      role_id: 'rol_DdMv0wYdixv6Ex9N',
      property_id: 'dev2',
      login_access: null,
      action: null,
      status: null,
      internal_permission_rule_id: '9089e65b-7dc7-4f01-920c-20d065660415'
    }
  ]
}
