import React from 'react'
import '../../../../styles/common.scss'

const LinkColumn = ({ row, baseUrl }) => {
  const { cell } = row || {}
  const {
    value,
    row: { original }
  } = cell || {}

  const url = `https://objectstorage.us-ashburn-1.oraclecloud.com/n/idpc9jwvu3b7/b/zip_testing/o/${value}`
  const shouldValueShow = original?.status === 'Completed'

  return shouldValueShow ? (
    <div className="cell-item" title={value}>
      <a className="link" download href={url}>
        Link
      </a>
    </div>
  ) : null
}

export default LinkColumn
