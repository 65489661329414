import Service from '../service'
import LOGOUT from './auth'
import activityLogMock from '../mocks/activity_log.mock'

/**
 * Global search data module
 * @redux
 * @reduxActionScope search
 * @module inspectActivityLog
 */

/**
 * Request activity log
 * @type {Redux.ActionType}
 */
export const ACTIVITY_LOG_REQUESTED = 'inspect/ACTIVITY_LOG_REQUESTED'
/**
 * Activity logs failed
 * @type {Redux.ActionType}
 */
export const ACTIVITY_LOG_FAIL = 'inspect/ACTIVITY_LOG_FAIL'
/**
 * Update inspect activity log with successful data from API
 * @type {Redux.ActionType}
 */
export const ACTIVITY_LOG_SUCCESS = 'inspect/ACTIVITY_LOG_SUCCESS'

const initialState = {
  activityLogIsRequesting: false,
  activityLogIsError: false,
  logItems: []
}

/**
 * Search data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_REQUESTED:
      return {
        ...state,
        activityLogIsRequesting: true,
        activityLogIsError: false,
        logItems: []
      }
    case ACTIVITY_LOG_FAIL:
      return {
        ...state,
        activityLogIsRequesting: false,
        activityLogIsError: true
      }
    case ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLogIsRequesting: false,
        activityLogIsError: false,
        logItems: action.logItems
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets inspection unit's activy log
 * @redux
 * @reduxActionCreator ACTIVITY_LOG_REQUESTED, ACTIVITY_LOG_SUCCESS, ACTIVITY_LOG_FAIL
 * @param {string} unitId - the unit ID for the request
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getInspectActivityLog = (unitId, pdbid, context) => {
  return dispatch => {
    dispatch({
      type: ACTIVITY_LOG_REQUESTED
    })
    Service.getInspectActivityLog(unitId, pdbid, context)
      .then(async res => {
        // TODO remove the mock and use the actual data
        // if (res.ok) {
        //   const json = await res.json()
        const logItems = activityLogMock
        dispatch({
          type: ACTIVITY_LOG_SUCCESS,
          logItems: _logItemsAdapter(logItems),
        })
        // } else {
        //   dispatch({
        //     type: ACTIVITY_LOG_FAIL
        //   })
        // }
      })
      .catch(e => {
        console.log(e)
        dispatch({
          type: ACTIVITY_LOG_FAIL
        })
      })
  }
}

const _logItemsAdapter = data => {
  return data
}
