import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import TurnToggle from '../../components/turn-toggle'

export const enterEmailScreen = ({
  email,
  setEmail,
  optimizedEmailHandleValidation,
  emailError,
  setErrorMessage,
  onHandleCheckEmailExists,
  checkEmailLoading,
  setExistsInCurrentPropertyError,
  setIsServerError,
  setNonVendorError,
  resetCheckEmail
}) => {
  return {
    tagline: 'Enter Email',
    content: (
      <div className="field-container">
        <div className="field-text">
          <p className="label has-text-weight-medium">Email Address</p>
        </div>
        <div className="field-input">
          <input
            id="email"
            name="email"
            value={email}
            autoFocus
            type="text"
            autoComplete="off"
            onChange={e => {
              const value = e?.target?.value?.trim()?.toLowerCase()
              setEmail(value)
              optimizedEmailHandleValidation(value)
              setExistsInCurrentPropertyError(false)
              setIsServerError(false)
              setNonVendorError(false)
            }}
            placeholder="Enter Email Address"
            className="input form-control"
          />
        </div>
      </div>
    ),
    actionButton: (
      <button
        disabled={(email || '').trim() === '' || emailError}
        onClick={() => {
          resetCheckEmail()
          setErrorMessage('')
          setExistsInCurrentPropertyError(false)
          setIsServerError(false)
          setNonVendorError(false)
          onHandleCheckEmailExists()
        }}
        className="button main-button is-primary">
        Next
        {checkEmailLoading && (
          <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
        )}
      </button>
    )
  }
}

export const emailAlreadyExistsScreen = ({
  displayPropertiesAsText,
  getCurrentProperty,
  setAddToExistingUser,
  onClose,
  resetCheckEmail
}) => {
  return {
    tagline: 'Email Already Exists',
    content: (
      <div className="email-exists-container">
        <div className="email-exists-message">
          <p>
            This user already exists in{' '}
            <strong>{displayPropertiesAsText}</strong>
          </p>
        </div>
        <div className="email-exists-sub-message">
          <p>
            Do you want to add this to <strong>{getCurrentProperty} </strong> as
            well?
          </p>
        </div>
      </div>
    ),
    actionButton: (
      <>
        <button
          className="button main-button is-secondary m-r-md"
          onClick={() => {
            setAddToExistingUser(false)
            onClose()
            resetCheckEmail()
          }}>
          No
        </button>
        <button
          className="button main-button is-primary"
          onClick={() => setAddToExistingUser(true)}>
          Yes
        </button>
      </>
    )
  }
}

export const addPropertyToUserScreen = ({
  enableVendorLogin,
  onHandleToggleLogin,
  setErrorMessage,
  onHandleAddCurrentPropertyToExitingUser,
  isSubmitting
}) => {
  return {
    tagline: 'Create Vendor Login',
    content: (
      <div className="vendor-toggle-container">
        <div className="vendor-toggle">
          <div>
            <p>Enable Login</p>
          </div>
          <div className="vendor-toggle-control">
            <TurnToggle
              isActive={enableVendorLogin}
              onChange={state => onHandleToggleLogin(state)}
            />
          </div>
        </div>
      </div>
    ),
    actionButton: (
      <button
        className="button main-button is-primary"
        onClick={() => {
          setErrorMessage('')
          onHandleAddCurrentPropertyToExitingUser()
        }}>
        Done
        {isSubmitting && (
          <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
        )}
      </button>
    )
  }
}
