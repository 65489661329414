import React from 'react'

const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
  toggleAllRowsSelected,
  selectedFlatRows
}) => {
  return (
    <input
      style={{ marginTop: '8px' }}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}
      placeholder="Search"
    />
  )
}

export default DefaultColumnFilter
