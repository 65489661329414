import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { addButton } from 'ui/icons'
import { capitalize } from '../../utils/helperFunctions'
import moment from 'moment'
import AddNewSpaceModal from '../../parents/add-new-space-modal'

import { exportFile } from 'ui/icons'

import PermissionGate from '../permission-gate'
import './index.scss'
import EditionTable from './edition-table/edition-table'
import { headerData } from './headers'

const EditionList = ({
  type,
  data,
  labelKey,
  idKey,
  removeKey,
  dropdownKey,
  saveAction,
  loading,
  itemStructure,
  setModifiedData,
  isModified,
  singularTabName,
  deleteTemplateElement,
  pdbid,
  context,
  setError,
  setModalContent,
  closeModal,
  openModal,
  companyTemplates,
  createElementSpace,
  onRefreshElements
}) => {
  const [customData, setCustomData] = useState([])
  const [skipReset, setSkipReset] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const singularType = type.substring(0, type.length - 1)
  const listRef = useRef(null)
  const tableRef = useRef()

  const originalData = useMemo(() => {
    return cloneDeep(data) || []
  }, [data])

  const { unitSpaceList } = companyTemplates

  const hasError = useMemo(() => {
    return (customData || []).some(
      item => isEmpty(item[`${singularType}_name`]) || item.error
    )
  }, [customData])

  const saveTemplateHandler = () => {
    const elementsData = (customData || [])
      .filter(item => item.new || item.isUpdated)
      .map(item => ({
        [idKey]: item[idKey],
        [labelKey]: (item[labelKey] || '').trim(),
        status: item.status === 'active' ? null : item.status,
        onTemplate: item.onTemplate ? item.onTemplate : 'F'
      }))

    saveAction(type, elementsData)
  }

  const handleItemChange = (value, itemIndex) => {
    setSkipReset(() => true)
    setCustomData(prevData => {
      const newData = cloneDeep(prevData)

      const item = newData[itemIndex] || {}
      const originalItem = (originalData || []).find(
        originalElement => originalElement[idKey] === item[idKey]
      )
      const isUpdated =
        !originalItem || originalItem[`${singularType}_name`] !== value
      const isValueAlreadyExist = (newData || []).some(
        (updatedElement, index) =>
          itemIndex !== index &&
          (updatedElement[`${singularType}_name`] || '')
            .trim()
            .toLowerCase() === (value || '').trim().toLowerCase()
      )

      let error = ''

      if (isEmpty(value?.trim())) {
        error = `Please enter ${singularTabName} value.`
      } else if (isValueAlreadyExist) {
        error = 'Oops! Looks like this value already exists.'
      }

      newData[itemIndex] = {
        ...newData[itemIndex],
        [`${singularType}_name`]: value,
        isUpdated,
        error
      }

      return newData
    })
  }

  const handleDropdownChange = (value, itemIndex) => {
    setSkipReset(() => true)
    setCustomData(prevData => {
      const newData = [...prevData]
      const item = newData[itemIndex] || {}
      const originalItem = (originalData || []).find(
        originalElement => originalElement[idKey] === item[idKey]
      )
      const isUpdated = !originalItem || originalItem.status !== value

      newData[itemIndex] = {
        ...newData[itemIndex],
        status: value,
        isUpdated
      }
      return newData
    })
  }

  const handleItemRemove = async itemIndex => {
    const item = customData[itemIndex]

    if (item[idKey]) {
      const dataToDelete = {
        [idKey]: item[idKey]
      }

      await deleteTemplateElement(pdbid, context, type, dataToDelete, idKey)
    } else {
      setCustomData(prevData =>
        (prevData || []).filter((_element, index) => index !== itemIndex)
      )
    }
  }

  const addElement = () => {
    setCustomData(prevData => [
      { ...itemStructure, new: true, error: '' },
      ...prevData
    ])
  }

  useEffect(() => {
    if (data?.length) {
      const newData = cloneDeep(data)
      setCustomData(
        newData.map(item => ({ ...item, isUpdated: false, error: '' }))
      )
    } else {
      setCustomData([{ ...itemStructure, new: true, error: '' }])
    }
    setError(false)
  }, [data, type])

  useEffect(() => {
    let modified = false

    const lengthsDiffer =
      customData?.length &&
      customData?.filter(item => !item.deleted).length !== data?.length

    // Mark as modified
    // if any item on its label key or dropdown key is different
    // or if lengths are different compare to original data
    if (customData?.length) {
      for (let i = 0; i < customData.length; i++) {
        modified =
          !data[i] ||
          customData[i][labelKey] !== data[i][labelKey] ||
          (dropdownKey &&
            customData[i][dropdownKey] !== data[i][dropdownKey]) ||
          lengthsDiffer
        if (modified) break
      }
    }

    setModifiedData(modified)
  }, [customData])

  useEffect(() => {
    if ((customData[customData.length - 1] || {}).new) {
      if (listRef.current) {
        listRef.current.scrollToItem(customData.length - 1)
      }
    }
  }, [customData.length])

  useEffect(() => {
    setSkipReset(false)
  }, [customData.length])

  // Add Space Modal
  const onAddSpaceHandler = (isEdit = false, row = {}) => {
    setModalContent(() => (
      <AddNewSpaceModal
        data={customData}
        unitSpaceList={unitSpaceList}
        createElementSpace={createElementSpace}
        onRefreshElements={onRefreshElements}
        isEdit={isEdit}
        row={row}
        dismiss={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '600px',
      maxWidth: '94%',
      additionalClasses: 'inspection-modal'
    })
  }

  const tableColumns = useMemo(
    () =>
      headerData({
        singularType,
        labelKey,
        idKey,
        removeKey,
        onAddSpaceHandler,
      }),
    [singularType, labelKey, idKey, removeKey]
  )

  const exportCSV = () => {
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const allRows = tableRef.current?.getRows() || []
    const selectedRows = tableRef.current?.getSelectedRows() || []
    let defaultData = (selectedRows || []).length ? selectedRows : allRows
    const filterData = (defaultData || [])
      .filter(x => x[`${singularType}_name`] !== '')
      .map(list => {
        const {
          [`${singularType}_name`]: name,
          status,
          onTemplate,
          link = ''
        } = list || {}
        if (singularType === 'space') {
          return {
            [capitalize(singularType || '')]: name || '',
            Link: link,
            Status: status || '',
            Used: (onTemplate || '') === 'T' ? 'Yes' : 'No'
          }
        }
        return {
          [capitalize(singularType || '')]: name || '',
          Status: status || '',
          Used: (onTemplate || '') === 'T' ? 'Yes' : 'No'
        }
      })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${capitalize(singularType || '')}-data-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // const addBlockButton = (
  //   <button
  //     disabled={isRowEmptyAvailable}
  //     className="button is-success btn-save"
  //     onClick={addElement}>
  //     <img src={addButton} className="is-inline m-t-0" alt="Add New Element" />
  //     Add {capitalize(singularType || '')}{' '}
  //   </button>
  // )

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  // const addActionButton = () => {
  //   if (isRowEmptyAvailable) {
  //     return (
  //       <Tippy
  //         distance={20}
  //         content={'Blank row already exists'}
  //         placement="bottom"
  //         allowHTML
  //         theme="light">
  //         <span>{addBlockButton}</span>
  //       </Tippy>
  //     )
  //   }
  //   return addBlockButton
  // }

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  return (
    <div id="edition-list-container" className="edition-list-container ">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5 custom-heading">Add/Edit {type}</h2>
            <button
              className="button is-success btn-save"
              style={{ verticalAlign: 'middle' }}
              onClick={saveTemplateHandler}
              disabled={hasError || !isModified}>
              {loading && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
              )}
              {loading
                ? capitalize(`Saving ${type || ''}`)
                : capitalize(`Save ${type || ''}`)}
            </button>
          </div>
          <p className="description">
            This is the list of {type} that can be used when creating inspection
            templates and forms. Note: Any {singularType} that is currently used
            on a template or form cannot be deleted but can be marked
            “Inactive.”
          </p>
          <div className="action-button">
            <div className="edition-action-buttons">
              <PermissionGate name={'modify-elements'}>
                <button
                  className="button is-success btn-save"
                  onClick={() => {
                    if (capitalize(singularType || '') === 'Space') {
                      onAddSpaceHandler()
                    } else {
                      addElement()
                    }
                  }}>
                  <img
                    src={addButton}
                    style={{ marginRight: '6px' }}
                    className="is-inline m-t-0"
                    alt="Add New Element"
                  />
                  Add {capitalize(singularType || '')}{' '}
                </button>
              </PermissionGate>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <button className="export" onClick={() => exportCSV()}>
              <img src={exportFile} alt="Export" />
              Export
            </button>
          </div>
        </div>
        <EditionTable
          data={customData}
          tableColumns={tableColumns}
          skipReset={skipReset}
          onDropdownItemChange={handleDropdownChange}
          onItemChange={handleItemChange}
          onItemDelete={handleItemRemove}
          type={singularType}
          ref={tableRef}
          setIsFilterApplied={setIsFilterApplied}
          onRowSelectStateChange={setSelectedRows}
        />
      </div>
    </div>
  )
}

EditionList.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  labelKey: PropTypes.string,
  removeKey: PropTypes.string,
  dropdownKey: PropTypes.string,
  saveAction: PropTypes.func,
  loading: PropTypes.bool,
  itemStructure: PropTypes.object,
  setModifiedData: PropTypes.func,
  stateOptions: PropTypes.array
}

export default EditionList
