const TOOLTIP_OPEN = 'tooltip/TOOLTIP_OPEN'
const TOOLTIP_CLOSE = 'tooltip/TOOLTIP_CLOSE'
const TOOLTIP_SET_CONTENT = 'tooltip/SET_CONTENT'

const initialState = {
  isActive: false,
  isLast: false,
  content: null,
  width: '',
  height: '',
  top: '',
  left: '',
  target: null,
  moveDelta: 0,
  className: null,
  onMouseOut: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOOLTIP_OPEN:
      return {
        ...state,
        isActive: true,
        isLast: action.isLast,
        width: action.width,
        height: action.height,
        top: action.top,
        left: action.left,
        target: action.target,
        moveDelta: action.moveDelta,
        onMouseOut: action.onMouseOut
      }
    case TOOLTIP_CLOSE:
      return {
        ...initialState
      }
    case TOOLTIP_SET_CONTENT:
      return {
        ...state,
        content: action.content,
        className: action.className
      }
    default:
      return state
  }
}

export const openTooltip = (
  width = '',
  height = '',
  top = '',
  left = '',
  target = null,
  moveDelta = 0,
  onMouseOut = null,
  isLast = false
) => {
  return dispatch => {
    dispatch({
      type: TOOLTIP_OPEN,
      width,
      height,
      top,
      left,
      target,
      moveDelta,
      onMouseOut,
      isLast
    })
  }
}

export const closeTooltip = () => {
  return dispatch => {
    dispatch({
      type: TOOLTIP_CLOSE
    })
  }
}

export const setTooltipContent = (content, className = null) => {
  return dispatch => {
    dispatch({
      type: TOOLTIP_SET_CONTENT,
      content,
      className
    })
  }
}
