import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateString } from '../../utils/index'
import './index.scss'

const getReadAbleResponseRecieved = (status,submitted_at) => {
  if(status !== null && status !== '') {
    const statusLower = (status || '').toLowerCase()
    if(statusLower === 'closed' || statusLower === 'in-review' || statusLower === 'approved' || statusLower === 'rejected' || statusLower === 'rescinded') {
      return `${submitted_at ? formatDateString(submitted_at, 'MM/DD/YYYY') : 'Pending'}`
    }
  }
  return 'Pending'
}

export const getHeaderData = () => [
  {
    key: 'rfp_request_id',
    display: 'RFP Id',
    textAlign: 'left',
    notSortable: true,
    hidden: true
  },
  {
    key: 'simplifiedRFPFormName',
    display: 'RFP',
    textAlign: 'left',
    filterable: true,
    notSortable: false,
    template: row => {
      const { rfp_request_id, due_date, simplifiedRFPFormName } = row || {}
      const title = simplifiedRFPFormName || ''
      return <Link 
        className="request-title-link is-capitalized" 
        to={{
          pathname: `/request/${rfp_request_id || ''}/quotation`,
          state: {
            id: rfp_request_id || '',
            title,
            dueDate: due_date,
            gmView: true
          }
        }}
      >
        {title}
      </Link>
    },
  },
  {
    key: 'vendor_name',
    display: 'Vendor Requested',
    textAlign: 'left',
    notSortable: false,
    filterable: true
  },
  {
    key: 'simplifiedServices',
    display: 'Service(s)',
    textAlign: 'left',
    filterable: true,
    notSortable: false,
    getValues: data => {
      const allData = data || []
      return _.filter(
        _.reduce(
          _.flatten(
            _.map(allData, o =>
              _.map(o.services, s => ({
                key: s.name,
                caption: s.name
              }))
            )
          ),
          (acc, itr) =>
            _.find(acc, a => a.key === itr.key) ? acc : [...acc, itr],
          []
        ),
        Boolean
      )
    }, 
    evaluateMatch: (filter, element={}) =>{
      return _.intersection(_.map(element.services, s => s.name), (filter || [])).length > 0
    }
      
  },
  {
    key: 'due_date',
    display: 'Due Date',
    textAlign: 'left',
    notSortable: false,
    filterable: true,
    template: (row) => {
      const { due_date ='' } = row || {}
      return <span>{due_date ? formatDateString(due_date, 'MM/DD/YYYY') : 'N/A'}</span>
    }
  },
  {
    key: 'submitted_at',
    display: 'Response Received',
    textAlign: 'left',
    filterable: true,
    template: (row) => {
      const { responseRecievedStatus, submitted_at } = row || {}
      return getReadAbleResponseRecieved(responseRecievedStatus, submitted_at)
    },
    getValues: data =>  _.uniq(_.map((data || []), appr =>  getReadAbleResponseRecieved(appr.responseRecievedStatus, appr.submitted_at))),
    evaluateMatch: (filter, element={}) => (filter || []).indexOf(getReadAbleResponseRecieved(element.responseRecievedStatus, element.submitted_at)) >= 0
  },
  {
    key: 'status',
    display: 'Approval',
    textAlign: 'left',
    filterable: true,
    notSortable: false,
    template: row => {
      const { status } = row || {}
      if(status !== null && (status || '').toLowerCase() === 'approved') {
        return <span className="is-approved">Approved</span>
      }
      return <span>{status}</span>
    },
    getValues: data =>  _.uniq(_.map((data || []), appr =>  appr.status)),
    evaluateMatch: (filter, element={}) => (filter || []).indexOf(element.status) >= 0
  }
]
