import React from 'react'

const CurrentLeaseStatusCell = ({ row }) => {
  const { cell, row: currentRow, state } = row || {}
  const { id, original } = currentRow || {}
  const { selectedRowIds } = state || {}
  const { value } = cell || {}
  const { is_override, override_status } = original || {}

  const showRedColor =
    (is_override || '').toLowerCase() === 'true' &&
    override_status &&
    override_status !== 'Transfer In'

  const style = {}

  if (showRedColor) {
    style['color'] = 'red'
  }

  return (
    <div
      className="cell-item"
      title={value}
      style={{
        backgroundColor: Object.keys(selectedRowIds).includes(id)
          ? '#55C2B8'
          : null
      }}>
      <span style={style}>{value}</span>
    </div>
  )
}

export default CurrentLeaseStatusCell
