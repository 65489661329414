import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import VendorHeader from '../vendor-header'
import BigLoading from '../../components/big-loading'
import MobileWorkCard from '../../components/vendor-work-card'
import MobileEmptyState from '../../components/mobile-empty-state'
import VendorMessageHeader from '../../components/vendor-message-header'
import { getPendings } from '../../modules/vendor'
import { toggleSidebar } from '../../modules/sidebar'
import { noPendingWork } from '../../ui/icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import ColumnSorting from '../../components/app-sorting'
import './index.scss'

const selectStyles = {
  input: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  placeholder: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  control: (styles, state) => ({
    ...styles,
    minHeight: 23,
    maxHeight: '2.5em',
    cursor: 'pointer',
    height: '2.5em',
    border: '1px solid #e5e9f2',
    borderRadius: 2,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #e5e9f2'
    },
    backgroundColor: state.isDisabled ? 'ghostwhite' : styles.backgroundColor
  }),
  container: styles => ({
    ...styles,
    marginTop: '-3px'
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 20,
    width: '100%',
    padding: 0,
    position: 'relative'
  }),
  singleValue: styles => ({
    ...styles,
    marginLeft: '10px',
    marginBottom: '3px'
  }),
  indicatorContainer: styles => ({
    ...styles,
    paddingTop: '4px'
  })
}

const VendorPending = ({
  vendor,
  user,
  context,
  getPendings,
  toggleSidebar
}) => {
  const { pendingJobs: defaultPendingJobs, isRequesting } = vendor
  const [selectedUnit, setSelectedUnit] = useState('')
  const [selectedService, setSelectedService] = useState('')
  const [pendingJobs, setPendingJobs] = useState([])
  const [sorting, setSorting] = useState({ key: '', ascending: true })

  useEffect(() => {
    setPendingJobs(defaultPendingJobs)
  }, [defaultPendingJobs])

  const { project, property } = context
  const { pdbid, vendorid } = user.user_metadata

  useEffect(() => {
    if (project && property && pdbid && vendorid && !isRequesting) {
      getPendings(pdbid, { project, property }, vendorid)
    }
  }, [context, user])

  useEffect(() => {
    let defaultPendingJob = defaultPendingJobs || []
    if (selectedUnit?.unit && selectedService?.service) {
      defaultPendingJob = (defaultPendingJob || []).filter(
        o =>
          o.unit === selectedUnit?.unit &&
          o.service === selectedService?.service
      )
    } else if (selectedUnit?.unit || selectedService?.service) {
      defaultPendingJob = (defaultPendingJob || []).filter(
        o =>
          o.unit === selectedUnit?.unit ||
          o.service === selectedService?.service
      )
    } else {
      defaultPendingJob = defaultPendingJobs || []
    }
    setPendingJobs(defaultPendingJob)
  }, [selectedUnit, selectedService])

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending })
  }

  useEffect(() => {
    if (sorting?.key) {
      if (sorting?.ascending) {
        const myData = []
          .concat(pendingJobs || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() <
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setPendingJobs(myData)
      } else {
        const myData = []
          .concat(pendingJobs || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() >
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setPendingJobs(myData)
      }
    }
  }, [sorting])

  return (
    <div className="vendor-pending is-fullheight">
      <VendorHeader
        title="Pending Approval"
        toggleSidebar={toggleSidebar}
        refreshAction={() => {
          getPendings(pdbid, { project, property }, vendorid)
          setSelectedUnit('')
          setSelectedService('')
          setSorting({ key: '', ascending: false })
          setPendingJobs(defaultPendingJobs)
        }}
      />

      <div className="vendor-pending-content">
        {isRequesting ? (
          <BigLoading style={{ marginTop: 0 }} />
        ) : (
          <div>
            {pendingJobs.length ? (
              <>
                <VendorMessageHeader
                  title="These units are being inspected."
                  subtitle="Approved units will move to 'Approved' and units needing additional work will go back to 'My Work'."
                />
                {pendingJobs.length ? (
                  <div className="columns is-mobile p-t-sm">
                    <div className="column is-half">
                      <div className="columns">
                        <div className="column is-half">
                          <label htmlFor="unit" className="is-size-5">
                            Unit
                            <ColumnSorting
                              data={sorting}
                              applySorting={applySorting}
                              type="unit"
                            />
                          </label>
                          <MultiSelectDropdown
                            displayKey="unit"
                            value={selectedUnit}
                            defaultValues={pendingJobs}
                            onChange={v => setSelectedUnit(v)}
                            isClearable={true}
                            isMulti={false}
                            placeholder="Select Unit"
                            noOptionsMessage="No Unit Available"
                            customStyles={selectStyles}
                          />
                        </div>
                        <div className="column is-half">
                          <label htmlFor="unit" className="is-size-5">
                            Service
                            <ColumnSorting
                              data={sorting}
                              applySorting={applySorting}
                              type="service"
                            />
                          </label>
                          <MultiSelectDropdown
                            displayKey="service"
                            value={selectedService}
                            isClearable={true}
                            defaultValues={_.uniqBy(
                              pendingJobs || [],
                              obj => obj?.service
                            )}
                            onChange={v => setSelectedService(v)}
                            isMulti={false}
                            placeholder="Select Service"
                            noOptionsMessage="No Service Available"
                            customStyles={selectStyles}
                          />
                        </div>
                      </div>
                    </div>
                    {(selectedService?.service ||
                      selectedUnit?.unit ||
                      sorting?.key) && (
                      <div className="column is-half">
                        <label
                          htmlFor="clear"
                          style={{ color: 'white' }}
                          className="is-size-5">
                          Clear
                        </label>
                        <div>
                          <button
                            style={{ marginTop: '-3px' }}
                            onClick={() => {
                              setSelectedUnit('')
                              setSelectedService('')
                              setSorting({ key: '', ascending: false })
                              setPendingJobs(defaultPendingJobs)
                            }}
                            className="button is-primary">
                            Clear All Filter(s)
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="vendor-pending-cards">
                  <div className="columns is-mobile is-multiline">
                    {pendingJobs.map(order => (
                      <MobileWorkCard order={order} />
                    ))}
                  </div>
                </div>
              </>
            ) : null}

            {pendingJobs.length === 0 ? (
              <div className="vendor-pending-empty">
                <MobileEmptyState
                  image={noPendingWork}
                  title="No pending approvals."
                  subtitle="All completed work orders awaiting approval will be listed here."
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

VendorPending.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  vendor: PropTypes.object,
  getPendings: PropTypes.func,
  toggleSidebar: PropTypes.func
}

const mapStateToProps = ({ vendor, user, context }) => ({
  vendor,
  user,
  context
})

const mapDispatchToProps = {
  getPendings,
  toggleSidebar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorPending)
