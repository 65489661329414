import React, { useMemo } from 'react'
import { edit } from 'ui/icons'

import DataTableMenu from '../../../../components/data-table-menu'
import { useHistory } from 'react-router'

const ActionColumn = ({ row }) => {
  const history = useHistory()

  const { cell } = row || {}
  const { row: currentRow } = cell || {}
  const { original } = currentRow || {}
  const { form_id } = original || {}

  const menuOptions = useMemo(() => {
    return [
      {
        caption: 'Edit Form',
        icon: edit,
        action: () => {
          return history.push(
            `/inspections-setup/property-forms/manage?form=${form_id}`
          )
        }
      }
    ]
  }, [original, form_id])

  return (
    <div className="menu-item">
      <DataTableMenu options={menuOptions} row={row} />
    </div>
  )
}

export default ActionColumn
