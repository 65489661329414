import _ from 'lodash'
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import { headerData } from './header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '../../components/confirmation-modal'
import moment from 'moment'
import { useModals } from 'hooks'
import BigLoading from '../../components/big-loading'
import Paginator from '../../components/react-table-pagination'
import styled from 'styled-components'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import { exportFile, reopen } from 'ui/icons'
import { icons } from './icons'
import { usePermissionGate } from '../../helpers/hooks'

import './index.scss'
import { isEmpty } from 'lodash'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import '../../styles/common.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }
  select {
    width: 100%;
    margin-top: 8px !important;
  }
  .inspection-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;
    .tr {
      position: relative;
      :first-child {
        .th {
          display: none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      :first-child {
        //border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
    }
  }
`
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, setFilter },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}
      placeholder={'Search'}
    />
  )
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // To remove empty values from filter
  options = (options || []).filter(function(e) {
    return e
  })
  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const handleChange = () => {
      const filteredRows = (rows || []).filter(
        ({ original }) =>
          original?.unit_id === currentRow?.original?.unit_id &&
          original?.canSchedule
      )

      return (filteredRows || []).forEach(({ id, isSelected }) => {
        toggleRowSelected(id, !isSelected)
      })
    }

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            enableManualSelection ? handleChange() : onChange(e)
          }}
          {...rest}
          title={
            showTooltip
              ? 'The capacity for the selected date has been reached.'
              : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const Table = memo(
  forwardRef(
    (
      {
        columns,
        data,
        onRowSelectStateChange,
        isPhotoLoading,
        setSelectedPage,
        setSelectedPageRowData,
        rowsPerPage,
        setRowsPerPage,
        setIsFilterApplied
      },
      ref
    ) => {
      // Use the state and functions returned from useTable to build your UI

      const defaultColumn = React.useMemo(
        () => ({
          width: 100,
          Filter: DefaultColumnFilter
        }),
        []
      )
      const filterTypes = React.useMemo(
        () => ({
          customEquals: (rows, id, filterValue) => {
            if (!filterValue) return rows
            id = id[0]
            return rows.filter(row => filterValue?.[id] === row.values?.[id])
          }
        }),
        []
      )
      const datePerPage = rowsPerPage
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds, pageIndex, pageSize, filters },
        selectedFlatRows,
        toggleRowSelected,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize,
        setAllFilters,
        toggleAllRowsSelected
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          filterTypes,
          sortTypes: {
            alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
              const rowOneColumn = row1?.values[columnName]
              const rowTwoColumn = row2?.values[columnName]

              if (
                columnName === 'inspection_date' &&
                moment(rowOneColumn).isValid() &&
                moment(rowTwoColumn).isValid()
              ) {
                return moment(rowOneColumn).isAfter(rowTwoColumn) ? 1 : -1
              }

              return (rowOneColumn || '').toString().toLowerCase() >
                (rowTwoColumn || '').toString().toLowerCase()
                ? 1
                : -1
            }
          },
          initialState: { pageIndex: 0, pageSize: datePerPage }
        },
        useBlockLayout,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              width: 45,
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return (
                  <div className="checkbox-wrapper">
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                )
              },
              Cell: ({ row }) => {
                return (
                  <div style={{ textAlign: 'center' }}>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      currentRow={row}
                      rows={rows}
                      toggleRowSelected={toggleRowSelected}
                    />
                  </div>
                )
              }
            },
            ...columns
          ])
        }
      )

      useImperativeHandle(ref, () => ({
        getRows: () => {
          return rows?.map(row => row.original)
        },
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        rowCount: (rows || []).length,
        currentPage: pageIndex + 1,
        gotoPage,
        setPageSize,
        clearFilter: setAllFilters,
        toggleAllRowsSelected: toggleAllRowsSelected
      }))

      useEffect(() => {
        onRowSelectStateChange(selectedFlatRows)
      }, [selectedRowIds])

      useEffect(() => {
        setRowsPerPage(pageSize)
      }, [pageSize])

      useEffect(() => {
        setSelectedPage(pageIndex + 1)
      }, [pageIndex])

      useEffect(() => {
        setSelectedPageRowData((rows || []).length)
      }, [rows])

      useEffect(() => {
        setIsFilterApplied(filters)
      }, [filters])

      const isSort = column => {
        if (column?.isSorted) {
          return (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={!column.isSortedDesc ? icons.caretUpOn : icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{
                  display: 'block',
                  marginTop: '-3px'
                }}
                src={
                  column.isSortedDesc ? icons.caretDownOn : icons.caretDownOff
                }
                alt="Sort in descending order"
              />
            </div>
          )
        }
        if (column?.canSort) {
          return (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{
                  display: 'block',
                  marginTop: '-3px'
                }}
                src={icons.caretDownOff}
                alt="Sort in descending order"
              />
            </div>
          )
        }
        return ''
      }

      const RenderRow = useCallback(
        ({ index, style }) => {
          const row = page[index]
          prepareRow(row)
          return (
            <div
              {...row.getRowProps({
                style
              })}
              className="tr">
              {row.cells.map(cell => {
                let styleBody = {}
                if (cell.column.minWidth !== 0) {
                  styleBody.minWidth = cell.column.minWidth
                }
                if (cell.column.flexGrow !== 0) {
                  styleBody.flexGrow = cell.column.flexGrow
                }
                return (
                  <div
                    {...cell.getCellProps({
                      style: {
                        textAlign: cell.column.textAlign,
                        width: cell.column.width,
                        ...styleBody
                      }
                    })}
                    className="td">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows, selectedRowIds, page]
      )

      // Render the UI for your table
      return (
        <div {...getTableProps()} className="inspection-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr full-header">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width,
                        minWidth: column.minWidth,
                        flexGrow: column.flexGrow
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      {column.Header === 'Photos' && isPhotoLoading && (
                        <FontAwesomeIcon
                          color="#3DB3E2"
                          icon={faSpinner}
                          spin
                          className="m-l-sm"
                        />
                      )}
                      <span {...column.getSortByToggleProps()}>
                        {isSort(column)}
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            {(rows || []).length ? (
              <FixedSizeList
                height={300}
                itemCount={(page || []).length}
                itemSize={35}
                className="full-body list-container">
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      )
    }
  )
)

const InspectionList = props => {
  const {
    user,
    context,
    inspect,
    setModalContent,
    openModal,
    closeModal,
    location,
    damage,
    getInspectionDownloadMediaURL,
    editNewInspection,
    bulkUpdateInspectionsProfile,
    getFilteredInspectionList,
    downloadInspectionMedia,
    getDownloadState,
    getApprovedInspectionList,
    changeDamageInspectionStatus,
    getAdhocUnitSpaces
  } = props || {}
  const { pathname } = location || {}
  const { approvedInspectionsList, adhocUnitSpaces } = damage || {}
  const { data: allInspectionList, isRequesting, hasMadeInitialRequest } =
    approvedInspectionsList || {}
  const { data: allResidentsData } = adhocUnitSpaces || {}

  const [showModal, setModal] = useState(false)
  const [isPhotoLoading, setIsPhotoLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [updateInspectionList, setUpdateInspectionList] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [filteredInspectionList, setFilteredInspectionList] = useState([])
  const [showCustomModal, dismissCustomModal] = useModals()
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const { hasPermission } = usePermissionGate('damage-action')
  const [currentRow, setCurrentRow] = useState(null)

  const [showConfirmModal, dismissConfirmModal] = useModals()

  useEffect(() => {
    setFilteredInspectionList(allInspectionList)
  }, [allInspectionList])

  const tableRef = useRef()

  const onSuccess = () => {
    setErrorMessage('')
  }

  const onError = () => {
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}

    if (!isEmpty(context)) {
      getApprovedInspectionList(pdbid, context, onSuccess, onError)
      getAdhocUnitSpaces(pdbid, context)
    }
  }, [context, user])

  const onStatusUpdate = data => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    changeDamageInspectionStatus({
      pdbid,
      context,
      body: {
        items: data || []
      },
      onSuccess: () => {
        setErrorMessage('')
        getApprovedInspectionList(pdbid, context, onSuccess, onError)
      },
      onError: () => {
        console.log('object')
        setErrorMessage('Something went wrong. Please try again later !!')
      }
    })
  }

  const onChangeDamageInspectionStatus = rows => {
    let data = []
    if ((rows || []).length) {
      ;(rows || []).map(row => {
        const { inspection_id, status } = row || {}
        data.push({
          inspection_id,
          status: status === 'Pending' ? 'Damages Approved' : 'Approved'
        })
        return row
      })
      return onStatusUpdate(data)
    }
    if ((selectedRows || []).length !== 0) {
      ;(selectedRows || []).map(row => {
        const { original } = row || {}
        const { inspection_id, status } = original || {}
        data.push({
          inspection_id,
          status: status === 'Pending' ? 'Damages Approved' : 'Approved'
        })
        return row
      })
      return onStatusUpdate(data)
    }
  }

  // Row option based on conditions
  const getRowOption = (row = {}) => {
    const { status } = row || {}
    let options = []
    if (status === 'Approved') {
      return [
        ...options,
        {
          caption: 'Unapprove Damage',
          icon: reopen,
          action: () => {
            if (hasPermission) {
              onHandleApproveModal({
                msg:
                  'You are about to unapprove the damages of the selected inspection(s). All damages pertaining to these inspection(s) will be unapproved as well. You can manually change them back by the approve button, if needed.',
                dismissLabel: 'Cancel',
                modalTitle: 'Confirm',
                submitLabel: 'Yes',
                submitAction: () => {
                  dismissCustomModal()
                  onChangeDamageInspectionStatus([row])
                }
              })
            }
          }
        }
      ]
    }
    if (status === 'Pending') {
      return [
        ...options,
        {
          caption: 'Approve Damage',
          icon: reopen,
          action: () => {
            if (hasPermission) {
              onHandleApproveModal({
                msg:
                  'You are about to approve the damages of the selected inspection(s). All damages pertaining to these inspection(s) will be approved as well. You can manually change them back by the unapprove button, if needed.',
                dismissLabel: 'Cancel',
                modalTitle: 'Confirm',
                submitLabel: 'Yes',
                submitAction: () => {
                  dismissCustomModal()
                  onChangeDamageInspectionStatus([row])
                }
              })
            }
          }
        }
      ]
    }
    return options || []
  }

  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = properties.find(p => p.id === contextProperty)
    const project = projects.find(p => p.id === contextProject)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = selectedRows.length
      ? selectedRows.map(row => row?.original)
      : rows
    const filterData = (defaultData || []).map(list => {
      const { building, unit, type, inspection_date, status } = list || {}
      return {
        Building: building || '',
        Unit: unit || '',
        'Inspection Name': type ? `${type} Inspection` : '',
        'Inspection Date': inspection_date ? inspection_date : 'N/A',
        'Damage Status': status || ''
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:general/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-all-inspections-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // Confirmation Modal for action buttons
  const onHandleApproveModal = ({
    msg,
    submitAction,
    dismissLabel,
    modalTitle,
    submitLabel,
    subMsg,
    additionalSubmitButtonClasses
  }) => {
    showCustomModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        submit={submitAction}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        submitLabel={submitLabel}
        subMsg={subMsg}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        useLoading={true}
        dismiss={dismissCustomModal}
      />
    )
  }

  const tableColumns = headerData({
    getRowOption,
    hasPermission
  })

  const columns = useMemo(() => tableColumns)

  const actionButtonType = type => {
    let disabledStatusUpdate = true
    let showTooltip = false
    if ((selectedRows || []).length !== 0) {
      const simplifyRows = (selectedRows || []).map(row => row?.original)
      if (
        (simplifyRows || []).every(
          element =>
            element?.status === (type === 'approve' ? 'Pending' : 'Approved')
        )
      ) {
        disabledStatusUpdate = false
      }
      if (hasPermission && disabledStatusUpdate) {
        showTooltip = true
      }
    }
    const bulkStatusUpdateButton = (
      <button
        disabled={
          !(selectedRows || []).length || !hasPermission || disabledStatusUpdate
        }
        style={{
          cursor:
            !(selectedRows || []).length ||
            disabledStatusUpdate ||
            !hasPermission
              ? 'not-allowed'
              : 'pointer'
        }}
        onClick={() => {
          if (hasPermission) {
            if (type === 'approve') {
              onHandleApproveModal({
                msg:
                  'You are about to approve the damages of the selected inspection(s). All damages pertaining to these inspection(s) will be approved as well. You can manually change them back by the unapprove button, if needed.',
                dismissLabel: 'Cancel',
                modalTitle: 'Confirm',
                submitLabel: 'Yes',
                submitAction: () => {
                  dismissCustomModal()
                  onChangeDamageInspectionStatus()
                }
              })
            }
            if (type === 'unapprove') {
              onHandleApproveModal({
                msg:
                  'You are about to unapprove the damages of the selected inspection(s). All damages pertaining to these inspection(s) will be unapproved as well. You can manually change them back by the approve button, if needed.',
                dismissLabel: 'Cancel',
                modalTitle: 'Confirm',
                submitLabel: 'Yes',
                submitAction: () => {
                  dismissCustomModal()
                  onChangeDamageInspectionStatus()
                }
              })
            }
          }
        }}
        className={`button ${type === 'approve' ? 'is-primary' : 'is-danger'}`}>
        {type === 'approve' ? 'Approve' : 'Unapprove'}
      </button>
    )
    let bulkStatusbuttonToRender = bulkStatusUpdateButton
    if (showTooltip) {
      bulkStatusbuttonToRender = <span>{bulkStatusUpdateButton}</span>
    }
    return (
      <>
        <Tippy
          distance={20}
          content={
            (selectedRows || []).length &&
            hasPermission &&
            disabledStatusUpdate && (
              <div>
                <p>
                  It seems you have selected damages belonging to
                  mixed/different statuses. Please select damages of the same
                  status.
                </p>
              </div>
            )
          }
          placement="bottom"
          allowHTML
          theme="light">
          {bulkStatusbuttonToRender}
        </Tippy>
      </>
    )
  }

  const onhandleResetFilters = () => tableRef.current?.clearFilter([])

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  const caSplitsDecoded = val => {
    if (val == '1') {
      return 'All Residents'
    } else if (val == '2') {
      return 'Non-renewed Residents'
    } else if (val == '3') {
      return 'Custom'
    } else {
      return 'N/A'
    }
  }

  const getUniqueResident = unit => {
    const filteredData = allResidentsData?.filter(
      space => space.unit_name === unit
    )
    return [
      ...new Map(filteredData?.map(item => [item['value'], item]))?.values()
    ]
      ?.map(item => ({
        name: item?.resident,
        value: item?.resident,
        amount: 0,
        space_id: item?.value,
        inspection_damage_split_id: null,
        is_non_renewed: item?.is_non_renewed,
        space: item?.name,
        unit_space_ext_id: item?.unit_space_ext_id,
        lease_ext_id: item?.lease_ext_id,
        tenant_ext_id: item?.tenant_ext_id
      }))
      ?.filter(
        res =>
          res?.value !== undefined &&
          res?.value !== null &&
          res?.value !== '' &&
          res?.value !== ' '
      )
  }

  const excelRowCommonSpaceTemplate = (damageData, residentData) => {
    const {
      building,
      unit,
      space,
      item,
      observation,
      damage_code,
      damages_generate,
      ca_split,
      building_ext_id,
      property_ext_id,
      unit_ext_id
    } = damageData || {}
    const { name, amount, unit_space_ext_id, lease_ext_id, tenant_ext_id } =
      residentData || {}
    return {
      Property: `"${_.get(context, 'property')}"` || '',
      Building: building || '',
      Unit: unit || `"${unit}"` || '',
      Space: space || `"${space}"` || '',
      Item: `"${item}"` || '',
      Observation: `"${observation}"` || '',
      'Generate Damage':
        damages_generate || damages_generate === null ? 'Yes' : 'No',
      Resident: name ? name : 'N/A',
      'CA Split Type': caSplitsDecoded(ca_split),
      'Date Cost': amount || 0,
      'Damage Code': damage_code || '',
      'Property Ext ID': `"${property_ext_id || ''}"` || '',
      'Building Ext ID': `"${building_ext_id || ''}"` || '',
      'Unit Ext ID': `"${unit_ext_id || ''}"` || '',
      'Unitspace Ext ID': amount > 0 ? `"${unit_space_ext_id || ''}"` : '',
      'Lease Ext ID': amount > 0 ? `"${lease_ext_id || ''}"` : '',
      'Tenant Ext ID': amount > 0 ? `"${tenant_ext_id || ''}"` : ''
    }
  }

  const excelRowRegularSpaceTemplate = (damageData, isCommon = false) => {
    const {
      building,
      unit,
      space,
      item,
      observation,
      damage_code,
      damage_cost,
      damages_generate,
      resident,
      ca_split,
      building_ext_id,
      property_ext_id,
      unit_ext_id,
      unit_space_ext_id,
      lease_ext_id,
      tenant_ext_id
    } = damageData || {}
    return {
      Property: `"${_.get(context, 'property')}"` || '',
      Building: building || '',
      Unit: unit || `"${unit}"` || '',
      Space: space || `"${space}"` || '',
      Item: `"${item}"` || '',
      Observation: `"${observation}"` || '',
      'Generate Damage':
        damages_generate || damages_generate === null ? 'Yes' : 'No',
      Resident: resident ? resident : 'N/A',
      'CA Split Type': caSplitsDecoded(ca_split),
      'Date Cost': damage_cost || 0,
      'Damage Code': damage_code || '',
      'Property Ext ID': `"${property_ext_id || ''}"` || '',
      'Building Ext ID': `"${building_ext_id || ''}"` || '',
      'Unit Ext ID': `"${unit_ext_id || ''}"` || '',
      'Unitspace Ext ID': `"${unit_space_ext_id || ''}"`,
      'Lease Ext ID': `"${lease_ext_id || ''}"`,
      'Tenant Ext ID': `"${tenant_ext_id || ''}"`
    }
  }

  const onHandleBulkExport = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = properties.find(p => p.id === contextProperty)
    const project = projects.find(p => p.id === contextProject)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = filteredInspectionList || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows
    const filteredDamageGeneratedData = []
    ;(defaultData || []).forEach(row => {
      const { excel_export } = row || {}
      if (excel_export?.length) {
        const damage_generated = excel_export?.some(
          item => item?.damages_generate
        )
        if (damage_generated) {
          filteredDamageGeneratedData.push(row)
        }
      }
    })
    let customData = []
    let uniqueResidents = []
    const filterData = (filteredDamageGeneratedData || []).flatMap(data => {
      const { excel_export } = data || {}
      if (excel_export.length) {
        const excelData = (excel_export || [])
          ?.filter(item => item?.damages_generate)
          ?.forEach(list => {
            const { damage_cost, ca_split, is_ca, splits, unit_id } = list || {}
            if (is_ca) {
              if (ca_split == '1') {
                uniqueResidents = getUniqueResident(unit_id)
                const totalAmount = damage_cost
                const isDecimal = totalAmount % 1 !== 0
                const amountPerResident = totalAmount / uniqueResidents?.length
                uniqueResidents.forEach(resident => {
                  resident.amount = isDecimal
                    ? amountPerResident.toFixed(2)
                    : amountPerResident
                })
                uniqueResidents.map(res =>
                  customData.push(excelRowCommonSpaceTemplate(list, res))
                )
              }
              if (ca_split == '2') {
                uniqueResidents = getUniqueResident(unit_id)?.filter(
                  resident => resident.is_non_renewed
                )
                const totalAmount = damage_cost
                const isDecimal = totalAmount % 1 !== 0
                const amountPerResident = totalAmount / uniqueResidents?.length
                uniqueResidents.forEach(resident => {
                  resident.amount = isDecimal
                    ? amountPerResident.toFixed(2)
                    : amountPerResident
                })
                uniqueResidents.map(res =>
                  customData.push(excelRowCommonSpaceTemplate(list, res))
                )
              }
              if (ca_split == '3') {
                if (splits?.length) {
                  uniqueResidents = getUniqueResident(unit_id)?.map(data => {
                    splits.forEach(split => {
                      if (data?.space_id === split?.space_id) {
                        data.amount = split.charge
                        data.inspection_damage_split_id =
                          split.inspection_damage_split_id
                      }
                    })
                    return data
                  })
                }
                uniqueResidents.map(res =>
                  customData.push(excelRowCommonSpaceTemplate(list, res))
                )
              }
              if (ca_split === null) {
                if (
                  list?.resident !== undefined &&
                  list?.resident !== null &&
                  list?.resident !== '' &&
                  list?.resident !== ' '
                ) {
                  customData.push(excelRowRegularSpaceTemplate(list, true))
                }
              }
            } else {
              if (
                list?.resident !== undefined &&
                list?.resident !== null &&
                list?.resident !== '' &&
                list?.resident !== ' '
              ) {
                customData.push(excelRowRegularSpaceTemplate(list, false))
              }
            }
            return
          })
        customData.push({})
        customData.push([
          'Property',
          'Building',
          'Unit',
          'Space',
          'Item',
          'Observation',
          'Generate Damage',
          'Resident',
          'CA Split Type',
          'Date Cost',
          'Damage Code',
          'Property Ext ID',
          'Building Ext ID',
          'Unit Ext ID',
          'Unitspace Ext ID',
          'Lease Ext ID',
          'Tenant Ext ID'
        ])
      }

      return
    })
    const data = customData?.slice(0, -1) || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:general/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-damage-inspections-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div id="inspection-list-container" className="inspection-list-container ">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">All Damage Inspections</h2>
          </div>
          <div className="action-button">
            <div className="inspection-action-buttons">
              {actionButtonType('approve')}
              {actionButtonType('unapprove')}
              {(selectedRows || []).length !== 0 && (
                <>
                  <button
                    className="button is-primary-outlined"
                    onClick={e => {
                      e.preventDefault()
                      onHandleBulkExport()
                    }}>
                    Download Damages Inspection
                  </button>
                </>
              )}
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onhandleResetFilters()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <button className="export" onClick={() => exportCSV()}>
              <img src={exportFile} alt="Export Inspections" />
              Export
            </button>
          </div>
          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
              </div>
            </div>
          )}
        </div>
        {hasMadeInitialRequest && !isRequesting ? (
          <Styles>
            <Table
              ref={tableRef}
              columns={columns}
              data={filteredInspectionList}
              onRowSelectStateChange={setSelectedRows}
              isPhotoLoading={isPhotoLoading}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Styles>
        ) : (
          <BigLoading />
        )}
        <div className="table-pagination">
          {hasMadeInitialRequest && !isRequesting ? (
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default InspectionList
