import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from 'hooks'
import MultiSelectDropdown from 'components/multi-select-dropdown'
import {
  getCompanyTemplateDetails,
  shareCompanyTemplate
} from '../../modules/company-templates'
import { close } from 'ui/icons'

/**
 * Component for showing a share template modal
 *
 * @component
 * @example
 * return (
 *   <ShareTemplateModal
 *     dismiss = () => {}
 *     properties = [{}]
 *   />
 * )
 */
const ShareTemplateModal = ({
  dismiss = () => {},
  // properties,
  shared,
  templateId,
  onSubmit
}) => {
  const dispatch = useDispatch()
  const {
    user: { pdbid, properties, projects },
    context
  } = useAppContext()

  const [loading, setLoading] = useState(false)
  const [selectedProperties, setSelectedProperties] = useState([])

  const sharedProperties = useMemo(() => {
    const projectPropertyMappings = (shared || []).map(
      element => element.property
    )

    const propertyIds = []

    for (let projectProperty of projectPropertyMappings || []) {
      for (let project of projects || []) {
        if (projectProperty?.includes(project.id)) {
          propertyIds.push(projectProperty?.replace(`${project.id}_`, ''))
        }
      }
    }

    return (properties || []).filter(property =>
      (propertyIds || []).includes(property.id)
    )
  }, [shared, properties, projects])

  const propertiesSelectHandler = properties =>
    setSelectedProperties(properties)

  const submitHandler = () => {
    const propertiesToShare = []

    for (let property of selectedProperties) {
      const { project: propertyProjects } = property

      if (propertyProjects?.length) {
        for (let project of propertyProjects || []) {
          propertiesToShare.push({
            template_id: templateId,
            property: `${project.id}_${property.id}`,
            do_delete: 'false'
          })
        }
      } else {
        const foundProject = (projects || []).find(project =>
          (project.properties || []).includes(property.id)
        )

        if (foundProject) {
          propertiesToShare.push({
            template_id: templateId,
            property: `${foundProject.id}_${property.id}`,
            do_delete: 'false'
          })
        }
      }
    }

    const deletedProperties = (shared || [])
      .filter(
        ({ property }) =>
          !propertiesToShare
            .map(propertyToShare => propertyToShare.property)
            .includes(property)
      )
      .map(deletedProperty => ({
        ...deletedProperty,
        do_delete: 'true'
      }))

    const body = {
      items: [...propertiesToShare, ...deletedProperties]
    }

    setLoading(true)
    onSubmit(true)
    dispatch(
      shareCompanyTemplate(pdbid, context, body, () => {
        dispatch(getCompanyTemplateDetails(pdbid, context, templateId))
      })
    )
  }

  useEffect(() => {
    setSelectedProperties(sharedProperties)
  }, [sharedProperties])

  return (
    <>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Share with Properties
          </p>
          <p className="close is-pointer has-text-grey-light" onClick={dismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6">
            These properties will be able to make copies of this template to
            create site-specific inspection forms.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-full is-flex"
          style={{ alignItems: 'center' }}>
          <span style={{ flex: 1 }}>Properties:</span>
          <div style={{ flex: 4 }}>
            <MultiSelectDropdown
              displayKey="name"
              value={selectedProperties}
              defaultValues={properties}
              onChange={v => propertiesSelectHandler(v)}
              customStyles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 80,
                  position: 'relative'
                }),
                menu: provided => ({
                  ...provided,
                  position: 'relative'
                })
              }}
            />
          </div>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={dismiss}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={submitHandler}>
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Share
          </button>
        </div>
      </div>
    </>
  )
}

ShareTemplateModal.propTypes = {
  dismiss: PropTypes.func,
  properties: PropTypes.array,
  templateId: PropTypes.string
}

export default ShareTemplateModal
