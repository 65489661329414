import React, { useState } from 'react'

const ChangeOption = ({ changeOptionHandler, options, closed, index, setOpenedOption, setParentServiceOption, task }) => {
  const [changeOption, setChangeOption] = useState(false)
  const [serviceOption, setServiceOption] = useState('')

  const handleChangeOption = () => {
    setServiceOption('')
    setParentServiceOption('')
    setOpenedOption(index)
    setChangeOption(true)
    changeOptionHandler()
  }

  const handleChangeServiceOption = value => {
    setServiceOption(value)
    setParentServiceOption([value, task.task_id])
  }

  return (
    <>
      <p
        className="m-t-sm is-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <span>
          Inspection:{' '}
          <span className="has-text-weight-bold">{task.label}</span>
        </span>
        <button
          className="button is-text"
          style={{ color: '#3DB3E2' }}
          onClick={() => handleChangeOption()}>
          Change
        </button>
      </p>

      {changeOption && !closed && (
        <>
          <p className="m-t-md" style={{ fontSize: 14 }}>
            Change Option to:
          </p>
          <select
            className="input is-fullwidth"
            value={serviceOption}
            onChange={e => {
              handleChangeServiceOption(e.target.value)
            }}>
            <option value="">Select</option>
            {options.map(option => (
              <option key={option.item_option_id} value={option.item_option_id}>
                {option.item_option}
              </option>
            ))}
          </select>
        </>
      )}
    </>
  )
}

export default ChangeOption
