import React, { useMemo } from 'react'
import DataTableMenu from '../../../data-table-menu'

import { trash, edit } from 'ui/icons'
import { useModals } from '../../../../helpers/hooks'
import ConfirmationModal from '../../../confirmation-modal'
import PermissionGate from '../../../permission-gate'
import { capitalize } from '../../../../utils/helperFunctions'

const ActionColumn = ({ row, type, onAddSpaceHandler }) => {
  const { cell, onItemDelete } = row || {}
  const { row: currentRow } = cell || {}
  const { original, index } = currentRow || {}
  const { onTemplate } = original || {}

  const [showModal, dismissModal] = useModals()

  const menuOptions = useMemo(() => {
    let actionArray = []
    if ((type || '') === 'space') {
      actionArray.push({
        caption: 'Edit Space',
        icon: edit,
        action: () => onAddSpaceHandler(true, original)
      })
    }

    if (onTemplate !== 'T') {
      actionArray.push({
        caption: 'Delete',
        icon: trash,
        action: () => {
          showModal(
            { width: '480px' },
            <ConfirmationModal
              msg={
                <p>
                  You’re about to delete an{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {capitalize(type || '')}
                  </span>
                  . This action cannot be undone. Are you sure you want to
                  delete?
                </p>
              }
              dismissLabel="Cancel"
              submitLabel="Yes, Delete"
              submit={() => {
                onItemDelete(index)
                dismissModal()
              }}
              dismiss={dismissModal}
            />
          )
        }
      })
    }

    return actionArray
  }, [original])

  return (
    <div className="menu-item">
      <PermissionGate name={'modify-elements'}>
        {(onTemplate !== 'T' || (type || '') === 'space') && (
          <DataTableMenu options={menuOptions} row={row} />
        )}
      </PermissionGate>
    </div>
  )
}

export default ActionColumn
