import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { useModals } from 'hooks'
import UnsavedChangesModal from '../../components/unsaved-changes-modal'


const InspectionUnsavedModal = (props) => {
  const { activePrompt, setPendingSaving, setIsEdit, updateInspectionItem, user, context, currentInspection, inspection_id, unFilterInspectionData, isDisable } = props || {}
  const history = useHistory()
  const unblock = useRef()
  const [showModal, dismissModal] = useModals()
  const [currentPath, setCurrentPath] = useState('')

  const onHandleSave = (isLoading, errorMessage) => { 
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}  
    let updateBody = unFilterInspectionData || []
    if((unFilterInspectionData || []).length !== 0) {
      updateBody[0] = {
        ...updateBody[0],
        inspection_lines: currentInspection || []
      }
    }
    isLoading(true)
    errorMessage('')
    updateInspectionItem({
      pdbid, 
      context, 
      body: updateBody,
      inspection_id,
      onSuccess: () => {
        isLoading(false)
        setIsEdit(false)
        setPendingSaving(false)
        errorMessage('')
        dismissModal()
      },
      onError: (error) => {
        isLoading(false)
        errorMessage('Something went wrong, Please try again !!!')
      }
    })
    
  }

  useEffect(() => {
    if (activePrompt) {
      unblock.current = history.block((prompt) => {
        const { pathname } = prompt || {}
        const getPath = pathname.substring(
          pathname.indexOf('/') + 1, 
          pathname.lastIndexOf('/')
        )
        setCurrentPath(pathname)
        showModal(
          { width: '480px' },
          <UnsavedChangesModal
            message="It looks like you made some changes that haven’t been saved yet."
            subMessage="Do you want to save before you go?"
            leaveButtonText="Discard"
            stayButtonText="Save"
            additionalClassesForMessage="schedule-all-modal__text"
            showLoading={true}
            isSaveDisable={isDisable}
            onLeave={() => {
              unblock.current()
              let path = `/${getPath}/inspectboard`
              if(pathname === '/') {
                path = currentPath
              }
              history.push(path)
              dismissModal()
            }}
            onSave={(loading, errorMessage) => onHandleSave(loading, errorMessage)}
            onStay={() => dismissModal()}
          />
        )
        return false
      })
    }

    return () => {
      if (unblock.current) {
        unblock.current()
      }
    }
  }, [activePrompt,currentPath, isDisable])

  return null
}

InspectionUnsavedModal.propTypes = {
  activePrompt: PropTypes.bool,
  setPendingSaving: PropTypes.func,
  setIsEdit: PropTypes.func,
  updateInspectionItem: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object,
  currentInspection: PropTypes.array,
  unFilterInspectionData: PropTypes.array,
  inspection_id: PropTypes.string,
  isDisable: PropTypes.bool,
}

export default InspectionUnsavedModal
