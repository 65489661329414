import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { icons } from './icons'
import PermissionGate from '../../components/permission-gate'
import CapacityForm from '../capacity-form'
import { Button } from 'ui'
import { addButtonBlack } from 'icons'
import { formatDateString } from '../../utils'

const CapacitySchedule = ({
  isEditing,
  hasEllipsis,
  title,
  description,
  mainError,
  ellipsisClick,
  closeTooltip,
  cancel,
  submit,
  handleCapacityChange,
  selectedEditIndex,
  allCapacityData,
  setAllCapacityData,
  services
}) => {
  useEffect(() => () => closeTooltip(), [])

  const [tooltipOpened, setTooltipOpened] = useState(false)
  const [selectedService, setSelectedService] = useState('')
  // Handler for opening tooltip of capacity schedule card
  const optionsHandler = (e, parentIndex) => {
    if (tooltipOpened) {
      closeTooltip()
    } else {
      ellipsisClick(e, parentIndex)
    }
    setTooltipOpened(state => !state)
    setSelectedService('')
  }
  const capacityScheduleCardHeader = parentIndex => {
    return (
      <div>
        {!isEditing && hasEllipsis && (
          <PermissionGate name={'add-team-capacity'}>
            <div style={{ position: 'relative' }}>
              <button
                className="button p-l-none p-r-none"
                type="button"
                onClick={e => optionsHandler(e, parentIndex)}
                style={{
                  border: 0,
                  position: 'absolute',
                  top: '10px',
                  right: '10px'
                }}>
                <img src={icons.ellipsis} alt="Options" />
              </button>
            </div>
          </PermissionGate>
        )}
        <div className="columns is-vcentered">
          <div className="column is-full is-10-fullhd is-offset-1-fullhd">
            <p className="setup-list-fields__title">
              {title === 'New' ? '' : title}
            </p>
            {description && parentIndex === selectedEditIndex && (
              <p className="setup-list-fields__description m-t-md">
                {description}
              </p>
            )}
            {mainError && parentIndex === selectedEditIndex && (
              <p className="setup-list-fields__warning m-t-md">{mainError}</p>
            )}
          </div>
        </div>
      </div>
    )
  }
  const capacityScheduleCardInner = (capacities = [], parentIndex, key = '') =>
    parentIndex === selectedEditIndex ? (
      <>
        {(capacities || []).map((item, index) => {
          // Get all selected services
          const preSelectedServices = Object.keys(allCapacityData || {})
          let getFilteredServices = []
          // Get services which are not selected
          const getUnusedServices = (services || []).filter(
            s => !(preSelectedServices || []).includes(s.service_name || '')
          )
          // Get current selected services
          const getCurrentervices = (services || []).filter(
            s => s.service_name === key
          )
          // Checking length below due to unexpected spread error
          if ((getCurrentervices || []).length !== 0) {
            getFilteredServices.push(...getCurrentervices)
          }
          if ((getUnusedServices || []).length !== 0) {
            if ((getFilteredServices || []).length !== 0) {
              getFilteredServices = [
                ...getFilteredServices,
                ...getUnusedServices
              ]
            }
            if ((getFilteredServices || []).length === 0) {
              getFilteredServices = [...getUnusedServices]
            }
          }
          return (
            <div key={index}>
              {index === 0 && (
                <div className="capacity-form__row">
                  <p className="capacity-form__label">Service:</p>
                  <div className="is-fullwidth">
                    <select
                      className="select-input"
                      value={selectedService || item.servicetype_id}
                      onChange={e => {
                        const { value } = e.target
                        setSelectedService(value)
                      }}
                      // Disabled if capacity scedhudle is not new and service type exists
                      disabled={
                        key !== 'New' &&
                        (selectedService ||
                          item.service_id ||
                          item.servicetype_id)
                      }
                      id="type-select">
                      <option value="Select">Select</option>
                      {(getFilteredServices || []).map(service => {
                        const { service_name, service_id } = service || {}
                        return (
                          <option key={service_id} value={service_id}>
                            {service_name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              )}
              <CapacityForm
                fromDate={item.start_date}
                toDate={item.end_date}
                capacityValue={item.capacity}
                itemIndex={index}
                parentIndex={parentIndex}
                currentKey={key}
                parentUpdate={handleCapacityChange}
                // Check if any of these item exists
                selectedService={
                  selectedService || item.service_id || item.servicetype_id
                }
              />
              {index === (capacities || []).length - 1 && (
                <>
                  <Button
                    type="white"
                    size="content"
                    className="m-b-md"
                    style={{ marginLeft: 84, fontSize: 12 }}
                    onClick={() => {
                      // Create copy of data and push new date range and capacity
                      let copy = {}
                      if (Object.keys(allCapacityData || {}).length !== 0) {
                        copy = { ...allCapacityData }
                      }
                      copy[key].push({
                        start_date: new Date().setHours(0, 0, 0, 0),
                        end_date: new Date().setHours(0, 0, 0, 0),
                        capacity: 0,
                        service_id: selectedService || item.servicetype_id
                      })
                      setAllCapacityData(copy)
                    }}>
                    <img src={addButtonBlack} alt="Add Space Pricing" />
                    <span className="m-l-sm">Add date or date range</span>
                  </Button>
                </>
              )}
            </div>
          )
        })}
        <div
          className="columns m-b-noner"
          key="vendor-manage-form-buttons"
          style={{ justifyContent: 'center' }}>
          <div className="column is-one-quarter">
            <button
              type="button"
              className="button is-secondary is-fullwidth"
              onClick={() => {
                setTooltipOpened(false)
                cancel(setSelectedService)
              }}>
              Cancel
            </button>
          </div>
          <div className="column is-one-quarter">
            <button
              className="button is-primary is-fullwidth"
              type="button"
              onClick={() => {
                setTooltipOpened(false)
                submit(key, selectedService)
              }}>
              Submit
            </button>
          </div>
        </div>
      </>
    ) : (
      <>
        <div>
          <p style={{ fontWeight: 'bold', color: '#3DB3E2' }}>{key || ''}</p>
        </div>
        <div className="columns m-t-sm">
          <div className="column is-half" style={{ fontWeight: 'bold' }}>
            Date(s):
          </div>
          <div className="column is-half" style={{ fontWeight: 'bold' }}>
            Max Spaces:
          </div>
        </div>
        {(capacities || []).map((capacity, index) => (
          <div className="columns" key={index}>
            <div className="column is-half p-t-none">
              <span>
                {formatDateString(capacity['start_date'], 'MM/DD/YYYY')}
              </span>
              {capacity['end_date'] && (
                <span>
                  {` - ${formatDateString(
                    capacity['end_date'],
                    'MM/DD/YYYY'
                  )} `}
                </span>
              )}
            </div>
            <div className="column is-half p-t-none">
              {capacity['capacity']}
            </div>
          </div>
        ))}
      </>
    )

  return (
    <>
      {Object.keys(allCapacityData || {}).map(function(key, index) {
        return (
          <div
            key={index}
            className="box is-shadowless has-transition-opacity setup-list-fields">
            {capacityScheduleCardHeader(index)}
            <div className="columns">
              <div className="column is-full is-10-fullhd is-offset-1-fullhd">
                {capacityScheduleCardInner(
                  allCapacityData[key] || [],
                  index,
                  key
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

CapacitySchedule.propTypes = {
  isEditing: PropTypes.bool,
  hasEllipsis: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  mainError: PropTypes.string,
  selectedEditIndex: PropTypes.number,
  ellipsisClick: PropTypes.func,
  closeTooltip: PropTypes.func,
  cancel: PropTypes.func,
  submit: PropTypes.func,
  handleCapacityChange: PropTypes.func,
  allCapacityData: PropTypes.object,
  setAllCapacityData: PropTypes.func,
  services: PropTypes.array
}

export default CapacitySchedule
