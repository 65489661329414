import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { usePrevious } from '../../helpers/hooks'
import {
  getInspectList,
  getInspectStats,
  syncInspectData,
  checkSyncData,
  printReport,
  getPrintReport
} from '../../modules/inspect'
import { getInspectActivityLog } from '../../modules/inspect-activity'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import InspectMain from '../../parents/inspect-main'

const SYNC_INTERVAL = 15000

const Inspect = ({
  modal,
  responsive,
  auth,
  user,
  context,
  location,
  inspect,
  inspectActivity,
  getInspectActivityLog,
  printReport,
  getPrintReport,
  setModalContent,
  openModal,
  closeModal,
  getInspectList,
  getInspectStats,
  syncInspectData,
  checkSyncData
}) => {
  const prevContext = usePrevious(context)
  const [initialSync, setInitialSync] = useState(false)

  const syncInterval = useRef(null)
  const printInterval = useRef(null)

  const populateList = (force = false) => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { pdbid } = user.user_metadata
    if (
      isAuthenticated &&
      hasMgmtToken &&
      pdbid !== null &&
      (!inspect.listHasRequested || force)
    ) {
      getInspectList(pdbid, context)
    }
  }

  const populateStats = (force = false) => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { pdbid } = user.user_metadata
    if (
      isAuthenticated &&
      hasMgmtToken &&
      pdbid !== null &&
      (!inspect.statsHasRequested || force)
    ) {
      getInspectStats(pdbid, context)
    }
  }

  useEffect(() => {
    if (
      !inspect.listHasRequested ||
      !prevContext ||
      context.project !== prevContext.project ||
      context.property !== prevContext.property ||
      inspect.isRequestingUpdate
    ) {
      populateList(true)
    }
    if (
      !inspect.statsHasRequested ||
      !prevContext ||
      context.project !== prevContext.project ||
      context.property !== prevContext.property ||
      inspect.isRequestingUpdate
    ) {
      populateStats(true)
    }
  }, [
    context,
    inspect.listHasRequested,
    inspect.statsHasRequested,
    inspect.isRequestingUpdate,
    user.user_metadata
  ])

  useEffect(() => {
    if (
      inspect.listHasRequested &&
      inspect.statsHasRequested &&
      !inspect.listIsRequesting &&
      !inspect.statsIsRequesting &&
      !initialSync
    ) {
      const { pdbid } = user.user_metadata
      checkSyncData(pdbid, context)
      setInitialSync(true)
    }
  }, [inspect.listIsRequesting, inspect.statsIsRequesting])

  useEffect(
    () => () => {
      clearInterval(syncInterval.current)
      syncInterval.current = null
    },
    []
  )

  useEffect(() => {
    if (
      syncInterval &&
      !inspect.syncIsRequesting &&
      inspect.status !== 'inprogress'
    ) {
      clearInterval(syncInterval.current)
      syncInterval.current = null
    }
  }, [syncInterval, inspect])

  useEffect(() => {
    if (printInterval && !inspect.printIsRequesting) {
      clearInterval(printInterval.current)
      printInterval.current = null
    }
  }, [printInterval, inspect])

  const onSync = () => {
    const { pdbid } = user.user_metadata
    syncInspectData(pdbid, context)
    syncInterval.current = setInterval(() => {
      checkSyncData(pdbid, context)
    }, SYNC_INTERVAL)
  }

  const onPrintReport = body => {
    const { pdbid } = user.user_metadata
    printReport(pdbid, context, body)
    printInterval.current = setInterval(() => {
      getPrintReport(pdbid, context, inspect.reportId || '')
    }, SYNC_INTERVAL)
  }

  const onRefresh = () => {
    populateList(true)
    populateStats(true)
  }

  return (
    <div>
      <InspectMain
        modal={modal}
        user={user}
        context={context}
        responsive={responsive}
        inspect={inspect}
        inspectActivity={inspectActivity}
        getInspectActivityLog={getInspectActivityLog}
        location={location}
        setModalContent={setModalContent}
        openModal={openModal}
        closeModal={closeModal}
        onSync={onSync}
        onPrintReport={onPrintReport}
        onRefresh={onRefresh}
      />
    </div>
  )
}

Inspect.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  inspect: PropTypes.object,
  getInspectList: PropTypes.func,
  getInspectStats: PropTypes.func,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({
  modal,
  responsive,
  auth,
  user,
  context,
  inspect,
  inspectActivity
}) => ({
  modal,
  responsive,
  auth,
  user,
  context,
  inspect,
  inspectActivity
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalContent,
      openModal,
      closeModal,
      getInspectList,
      getInspectStats,
      getInspectActivityLog,
      syncInspectData,
      checkSyncData,
      printReport,
      getPrintReport
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Inspect)
)
