import React from 'react'
import PropTypes from 'prop-types'

const EMPTY = ''
const DEFAULTS = {
  address1: EMPTY,
  address2: EMPTY,
  address3: EMPTY,
  city: EMPTY,
  state: EMPTY,
  zip: EMPTY,
  country: EMPTY
}

/**
 * Component for showing complete address
 *
 * @component
 * @example
 * const defaults = {
 *  address1: '123 Street',
 *  address2: 'Suite 300',
 *  city: 'Chicago',
 *  state: 'IL',
 *  zip: '60007',
 *  country: 'United States of America'
 * }
 * return (
 *   <AddressDisplay defaults={defaults} />
 * )
 */
const AddressDisplay = ({ defaults = DEFAULTS }) => {
  const { address1, address2, city, state, zip, country } = defaults
  return (
    <div className="columns is-desktop is-mobile is-multiline">
      <div className="column is-full">{address1}</div>
      <div className={`column is-full p-t-none ${address2 ? '' : 'is-hidden'}`}>
        {address2}
      </div>
      <div className="column is-full p-t-none">
        {city}, {state} {zip}
      </div>
      <div className="column is-full p-t-none">{country}</div>
    </div>
  )
}

AddressDisplay.propTypes = {
  /**
   * Defaults for address info, with:
   *   address1, address2 and address 3 as texts with lines in address
   *   city, state, zip and country with geographic tags
   */
  defaults: PropTypes.object
}

AddressDisplay.defaultProps = {
  defaults: DEFAULTS
}

export default AddressDisplay
