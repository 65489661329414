import React from 'react'
import challenges from './challenges.json'

/**
 * Today's Challenge quote box
 *
 * @component
 * @example
 * return (
 *   <TodaysChallenge />
 * )
 */
const TodaysChallenge = () => {
  return (
    <div className="dashboard-card card-sm-semi has-text-centered">
      <div className="vertical-center standard-padding">
        <p className="m-b-sm heading-5" style={{ fontSize: 16, fontWeight: '500', color: '#3DB3E2' }}>Your Zen-able Moment</p>
        <p style={{ fontSize: 14 }}>{challenges[new Date().getDate()]}</p>
      </div>
    </div>
  )
}

export default TodaysChallenge
