import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  memo,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react'
import { headerData } from './header'
import BigLoading from '../../components/big-loading'
import { usePermissionGate } from '../..//helpers/hooks'
import AddChargeModal from '../add-charge-modal'
import AddChargesPOModal from '../add-charges-po-modal'
import ChargesBulkStatusUpdate from '../charges-bulk-status-update'
import styled from 'styled-components'
import moment from 'moment'
import { formatDateString, numberOfDays } from '../../utils'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import scrollbarWidth from './scrollbarWidth'
import { edit, exportFile, calendar, closeFilled } from 'ui/icons'
import { icons } from './icons'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { getOutsideFilters } from './header-data'
// For Date filter
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import cn from 'classnames'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../utils/calendarInputHelper'
import { DateUtils } from 'react-day-picker/DayPicker'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import Paginator from '../../components/react-table-pagination'
import DefaultColumnFilter from '../../components/react-table-column-filters/DefaultColumnFilter'
// import ChargesTable from './components/ChargesTable'
import SwitchButton from '../../components/forms-helper/SwitchButton'
import './index.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }
  select {
    width: 100%;
    margin-top: 8px !important;
  }
  .charges-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;

    .tr {
      position: relative;

      :first-child {
        .th {
          display: none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      // :first-child {
      //   border: 1px solid #e5e9f2;
      // }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      :last-child {
        border-right: 1px solid #e5e9f2;
      }
    }
  }
`

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // To remove empty values and - from filter
  options = (options || []).filter(e => e && e !== '-')

  return (
    <select
      value={filterValue || 'All'}
      onChange={e => {
        if (e.target.value === 'all') {
          setFilter(undefined)
        } else {
          setFilter(e.target.value || '')
        }
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="all">All</option>
      {id === 'vendor' && <option value="-">No Vendor</option>}
      {(options || []).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      headerCheckbox = false,
      toggleAllRowsSelected,
      title,
      disabled,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            if (!disabled) {
              onChange(e)
            }
          }}
          {...rest}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.2 : 1
          }}
          title={
            showTooltip ? 'This row cannot be selected.' : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const Table = memo(
  forwardRef(
    (
      {
        columns,
        data,
        onRowSelectStateChange,
        setIsFilterApplied,
        setSelectedPage,
        setSelectedPageRowData,
        rowsPerPage,
        setRowsPerPage
      },
      ref
    ) => {
      // Use the state and functions returned from useTable to build your UI

      const defaultColumn = React.useMemo(
        () => ({
          width: 100,
          Filter: DefaultColumnFilter
        }),
        []
      )

      const filterTypes = React.useMemo(
        () => ({
          customEquals: (rows, id, filterValue) => {
            if (!filterValue) return rows
            id = id[0]
            return rows.filter(row => filterValue?.[id] === row.values?.[id])
          },
          customIncludes: (rows, id, filterValue) => {
            if (!filterValue) return rows
            id = id[0]
            return rows.filter(
              row => filterValue?.[id] === row.values?.[id]?.split('-')[0]
            )
          }
        }),
        []
      )

      const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
      const datePerPage = rowsPerPage
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        state: { selectedRowIds, filters, pageIndex, pageSize },
        selectedFlatRows,
        toggleRowSelected,
        setAllFilters,
        toggleAllRowsSelected,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          filterTypes,
          sortTypes: {
            alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
              const rowOneColumn = row1?.values[columnName]
              const rowTwoColumn = row2?.values[columnName]
              const dateColumns = ['custom_complete_date']

              if (
                dateColumns.includes(columnName) &&
                moment(rowOneColumn).isValid() &&
                moment(rowTwoColumn).isValid()
              ) {
                return moment(rowOneColumn).isAfter(rowTwoColumn) ? 1 : -1
              }

              if (columnName === 'amount') {
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1
              }
              return (rowOneColumn || '').toString().toLowerCase() >
                (rowTwoColumn || '').toString().toLowerCase()
                ? 1
                : -1
            }
          },
          initialState: { pageIndex: 0, pageSize: datePerPage }
        },
        useBlockLayout,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              width: 45,
              Header: ({
                toggleRowSelected,
                isAllRowsSelected,
                selectedFlatRows,
                rows,
                toggleAllRowsSelected
              }) => {
                const updatedRows = useMemo(() => {
                  const checkRows = (rows || []).map(row => {
                    const { original } = row || {}
                    const { status, vendor_id } = original || {}
                    if (status === 'Added' || !vendor_id) {
                      row.isDisabled = true
                    } else {
                      row.isDisabled = false
                    }
                    return row
                  })
                  return checkRows
                }, [rows])
                const overridenOnChange = event => {
                  if (
                    (updatedRows || []).filter(r => r?.isDisabled === false)
                      .length
                  ) {
                    // Toggle all checkboxes to selected/unselected depending on whether the header checkbox is checked or not.
                    ;(updatedRows || [])
                      .filter(r => r?.isDisabled === false)
                      .forEach(row => {
                        toggleRowSelected(
                          row?.id,
                          event?.currentTarget?.checked
                        )
                      })
                  } else {
                    toggleAllRowsSelected(false)
                  }
                }
                const style = {
                  cursor: 'pointer'
                } // Cursor should be set to pointer while hovering over checkbox
                const checked =
                  isAllRowsSelected ||
                  (!isAllRowsSelected && (selectedFlatRows || []).length > 0) // header checkbox is only checked if all rows are checked
                const indeterminate =
                  !isAllRowsSelected && (selectedFlatRows || []).length > 0 // if some but not all rows are checked, header checkbox is indeterminate
                const disabled = useMemo(() => {
                  return (
                    (updatedRows || []).filter(r => r?.isDisabled === false)
                      .length === 0
                  )
                }, [updatedRows])
                const showTooltip = useMemo(() => {
                  return disabled
                }, [disabled])

                const newProps = {
                  onChange: overridenOnChange,
                  style: style,
                  checked: checked,
                  indeterminate: indeterminate,
                  disabled: disabled,
                  showTooltip: showTooltip
                }
                return (
                  <div>
                    <IndeterminateCheckbox {...newProps} />
                  </div>
                )
              },
              Cell: ({ row, rows }) => {
                const rowId = +row?.id
                const { status, vendor_id } = row?.original || {}

                const disabled = useMemo(() => {
                  return status === 'Added' || !vendor_id
                }, [rowId, selectedRowIds, status])

                const showTooltip = useMemo(() => {
                  return disabled
                }, [disabled])

                const overridenOnChange = event => {
                  const { original } = row || {}
                  const { status, vendor_id, unit_id, servicetype_id } =
                    original || {}
                  const getAllRowsData = (rows || [])
                    .filter(r => r?.isDisabled === false)
                    .map(data => ({ ...data.original, id: data.id }))
                    .filter(
                      d =>
                        d.unit_id === unit_id &&
                        d.status !== 'Will Not Be Added' &&
                        d.added !== 'true' &&
                        d.vendor_id === vendor_id &&
                        d.servicetype_id === servicetype_id
                    )
                  if (status === 'Will Not Be Added') {
                    toggleRowSelected(row?.id, event?.currentTarget?.checked)
                  } else if ((getAllRowsData || []).length) {
                    getAllRowsData.forEach(row => {
                      toggleRowSelected(row?.id, event?.currentTarget?.checked)
                    })
                  } else {
                    toggleRowSelected(row?.id, event?.currentTarget?.checked)
                  }
                }

                return (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      currentRow={row}
                      onChange={overridenOnChange}
                      rows={rows}
                      toggleRowSelected={toggleRowSelected}
                      disabled={disabled}
                      showTooltip={showTooltip}
                      headerCheckbox={false}
                    />
                  </div>
                )
              }
            },
            ...columns
          ])
        }
      )

      useImperativeHandle(ref, () => ({
        getRows: () => {
          return rows?.map(row => row.original)
        },
        clearFilter: setAllFilters,
        toggleAllRowsSelected: toggleAllRowsSelected,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        rowCount: (rows || []).length,
        currentPage: pageIndex + 1,
        gotoPage,
        setPageSize
      }))

      useEffect(() => {
        onRowSelectStateChange(selectedFlatRows)
      }, [selectedRowIds])

      useEffect(() => {
        setIsFilterApplied(filters)
      }, [filters])

      useEffect(() => {
        setRowsPerPage(pageSize)
      }, [pageSize])

      useEffect(() => {
        setSelectedPage(pageIndex + 1)
      }, [pageIndex])

      useEffect(() => {
        setSelectedPageRowData((rows || []).length)
      }, [rows])

      const RenderRow = React.useCallback(
        ({ index, style }) => {
          const row = page[index]
          prepareRow(row)
          return (
            <div
              {...row.getRowProps({
                style
              })}
              className="tr">
              {row.cells.map(cell => {
                return (
                  <div
                    {...cell.getCellProps({
                      style: {
                        textAlign: cell.column.textAlign,
                        width: cell.column.width
                      }
                    })}
                    className="td">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows, selectedRowIds, page]
      )
      const defaultTableHeight =
        document.getElementById('charges-list-container')?.offsetHeight -
        document.getElementById('data-table-header')?.offsetHeight -
        150
      // Render the UI for your table
      return (
        <div {...getTableProps()} className="charges-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      <span {...column.getSortByToggleProps()}>
                        {column.isSorted ? (
                          <div
                            style={{
                              verticalAlign: 'middle',
                              display: 'inline-block',
                              marginLeft: 5,
                              marginTop: -4
                            }}>
                            <img
                              style={{ display: 'block', marginTop: '3px' }}
                              src={
                                !column.isSortedDesc
                                  ? icons.caretUpOn
                                  : icons.caretUpOff
                              }
                              alt="Sort in ascending order"
                            />
                            <img
                              style={{ display: 'block', marginTop: '-3px' }}
                              src={
                                column.isSortedDesc
                                  ? icons.caretDownOn
                                  : icons.caretDownOff
                              }
                              alt="Sort in descending order"
                            />
                          </div>
                        ) : column.canSort ? (
                          <div
                            style={{
                              verticalAlign: 'middle',
                              display: 'inline-block',
                              marginLeft: 5,
                              marginTop: -4
                            }}>
                            <img
                              style={{ display: 'block', marginTop: '3px' }}
                              src={icons.caretUpOff}
                              alt="Sort in ascending order"
                            />
                            <img
                              style={{ display: 'block', marginTop: '-3px' }}
                              src={icons.caretDownOff}
                              alt="Sort in descending order"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {(rows || []).length ? (
              <FixedSizeList
                className="full-body list-container"
                height={defaultTableHeight}
                itemCount={(page || []).length}
                itemSize={35}
                width={totalColumnsWidth + scrollBarSize - 11}>
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      )
    }
  )
)

const ChargesList = props => {
  const {
    user,
    context,
    setModalContent,
    openModal,
    closeModal,
    getChargesList,
    charge,
    resetPurchaseOrderDetail
  } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [updateChargesList, setUpdateChargesList] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [showNotAdded, setShowNotAdded] = useState(true)

  const tableRef = useRef()

  const { hasPermission } = usePermissionGate('pay-order-actions')

  // Date Filter Code
  const [outsideFilters, setOutsideFilters] = useState({})
  const [dates, setDates] = useState({ from: new Date(), to: new Date() })
  const [
    scheduleFiltersDropdownOpen,
    setScheduleFiltersDropdownOpen
  ] = useState(false)

  const outsideFiltersRef = useDetectClickOutside({
    onTriggered: () => setScheduleFiltersDropdownOpen(false)
  })

  let datePickerRef = null

  const modifiers = {
    start: dates.from,
    end: dates.to,
    past: { before: new Date(dates.from) },
    pastSelection: { before: dates.from },
    weekS: { daysOfWeek: [1] },
    weekE: { daysOfWeek: [0] },
    firstOfMonth: day => day.getDate() === 1,
    lastOfMonth: day => day.getDate() === numberOfDays(null, day.getMonth() + 1)
  }

  const getActiveScheduledFilterLabel = () => {
    const activeFilter = Object.keys(outsideFilters).find(
      filter => outsideFilters[filter]['active']
    )
    if (activeFilter !== undefined) {
      return outsideFilters[activeFilter]['label']
    }
  }

  const handleDayClick = day => {
    day.setHours(0, 0, 0)
    const range = DateUtils.addDayToRange(day, dates)

    setOutsideFilters(getOutsideFilters({ from: range.from, to: range.to }))
    setOutsideFilters(outsideFilters => {
      return {
        ...outsideFilters,
        custom: {
          ...outsideFilters['custom'],
          active: true
        }
      }
    })

    setDates(range)
  }

  const customPickerInput = React.forwardRef((props, ref) => {
    const value =
      dates.from && dates.to
        ? `${dateFnsFormat(dates.from, 'MM/dd')} - ${dateFnsFormat(
            dates.to,
            'MM/dd'
          )}`
        : props.value
    return (
      <input
        {...props}
        value={value}
        ref={ref}
        id="datepicker-input"
        readOnly
      />
    )
  })

  useEffect(() => {
    setOutsideFilters(
      getOutsideFilters({ from: new Date(), to: new Date() }, true)
    )
  }, [])

  useEffect(() => {
    if (scheduleFiltersDropdownOpen)
      datePickerRef && datePickerRef.hideDayPicker()
  }, [scheduleFiltersDropdownOpen])
  // Date filter code end

  const { allCharges } = charge || {}
  const { data: allChargesList, isRequesting, hasMadeInitialRequest } =
    allCharges || {}

  const filteredChargesList = useMemo(() => {
    if (!showNotAdded) return allChargesList

    return (allChargesList || []).filter(
      charge => charge.status === 'Not Added'
    )
  }, [allChargesList, showNotAdded])

  const onSuccess = () => {
    setErrorMessage('')
  }

  const onError = () => {
    setErrorMessage('Something went wrong. Please try again later !!!')
  }

  useEffect(() => {
    getDefaultFilteredChargeData()
  }, [context, user])

  useEffect(() => {
    if (updateChargesList) {
      setOutsideFilters(
        getOutsideFilters({ from: new Date(), to: new Date() }, true)
      )
      getDefaultFilteredChargeData()
    }
  }, [updateChargesList])

  const getDefaultFilteredChargeData = () => {
    // To Shoe All data
    const today = new Date()
    const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
    getFilteredChargeData(convertTodayDate, 0)
  }

  const getFilteredChargeData = async (
    selectedTodayDate,
    selectedPriorDate
  ) => {
    setSelectedPageRowData(0)
    setRowsPerPage(30)
    setUpdateChargesList(false)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    await getChargesList(
      pdbid,
      context,
      selectedTodayDate,
      selectedPriorDate,
      onSuccess,
      onError
    )
  }

  // Add Charges Modal
  const onAddChargeHandler = () => {
    const defaultProps = {
      ...props,
      setUpdateChargesList
    }
    setModalContent(() => (
      <AddChargeModal
        {...defaultProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '500px',
      maxWidth: '94%',
      additionalClasses: 'add-charges-modal'
    })
  }

  // Add Charge PO Modal
  const onAddChargePOHandler = () => {
    let selectedPORows = []
    if ((selectedRows || []).length) {
      selectedPORows = (selectedRows || []).map(r => r?.original)
    }
    const defaultProps = {
      ...props,
      setUpdateChargesList,
      selectedPORows
    }
    setModalContent(() => (
      <AddChargesPOModal
        {...defaultProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '590px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-all-notes-modal'
    })
  }

  // Update charge status Modal
  const onUpdateChargesHandler = () => {
    let selectedChargeRows = []
    if ((selectedRows || []).length) {
      selectedChargeRows = (selectedRows || []).map(r => r?.original)
    }
    const defaultProps = {
      ...props,
      setUpdateChargesList,
      selectedChargeRows,
      status: selectedChargeRows[0]
    }
    setModalContent(() => (
      <ChargesBulkStatusUpdate
        {...defaultProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '590px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-all-notes-modal'
    })
  }

  // Edit Charges Modal
  const onEditChargeHandler = (row = {}) => {
    const {
      added,
      amount,
      unit_id,
      vendor_id,
      servicetype_id,
      task_id,
      note,
      unit
    } = row || {}
    const defaultProps = {
      ...props,
      setUpdateChargesList,
      isEdit: true,
      added,
      amount,
      unit_id: {
        unit_id,
        unit
      },
      vendor_id,
      servicetype_id,
      task_id,
      note
    }
    setModalContent(() => (
      <AddChargeModal
        {...defaultProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '500px',
      maxWidth: '94%',
      additionalClasses: 'add-charges-modal'
    })
  }

  // Row option based on conditions
  const getRowOption = (row = {}) => {
    const { status, vendor_id } = row || {}
    let options = []
    if (status !== 'Added' && vendor_id) {
      return [
        ...options,
        {
          caption: 'Edit Charges',
          icon: edit,
          action: () => onEditChargeHandler(row)
        }
      ]
    }
    return options || []
  }

  const tableColumns = headerData({
    getRowOption,
    SelectColumnFilter,
    resetPurchaseOrderDetail
  })

  const columns = useMemo(() => tableColumns, [])

  const onResetFilter = () => tableRef.current?.clearFilter([])
  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const userSwiftActions = () => {
    let disabled = true
    let disabledStatusUpdate = true
    let showTooltip = false
    if ((selectedRows || []).length !== 0) {
      const simplifyRows = (selectedRows || []).map(row => row?.original)
      if (
        (simplifyRows || []).every(
          element =>
            element?.vendor === (simplifyRows || [])[0]?.vendor &&
            element?.status === 'Not Added'
        )
      ) {
        disabled = false
      }
      if (
        (simplifyRows || []).every(
          element => element?.status === simplifyRows[0]?.status
        )
      ) {
        disabledStatusUpdate = false
      }
      if (hasPermission && disabledStatusUpdate) {
        showTooltip = true
      }
    }
    const bulkStatusUpdateButton = (
      <button
        disabled={
          !(selectedRows || []).length || !hasPermission || disabledStatusUpdate
        }
        style={{
          cursor:
            !(selectedRows || []).length ||
            disabledStatusUpdate ||
            !hasPermission
              ? 'not-allowed'
              : 'pointer'
        }}
        onClick={() => {
          if (hasPermission) {
            onUpdateChargesHandler()
          }
        }}
        className="button is-primary">
        Bulk Update Status
      </button>
    )
    let bulkStatusbuttonToRender = bulkStatusUpdateButton
    if (showTooltip) {
      bulkStatusbuttonToRender = <span>{bulkStatusUpdateButton}</span>
    }
    return (
      <>
        <button
          disabled={disabled || !hasPermission}
          className="button is-primary"
          style={{
            cursor: disabled || !hasPermission ? 'not-allowed' : 'pointer'
          }}
          onClick={() => {
            if (hasPermission) {
              onAddChargePOHandler()
            }
          }}>
          Add To PO
        </button>
        <Tippy
          distance={20}
          content={
            (selectedRows || []).length &&
            hasPermission &&
            disabledStatusUpdate && (
              <div>
                <p>
                  You cannot bulk change the status of the charges because you
                  have selected different status charges. Please select charges
                  of same status to enable this button.
                </p>
              </div>
            )
          }
          placement="bottom"
          allowHTML
          theme="light">
          {bulkStatusbuttonToRender}
        </Tippy>
      </>
    )
  }

  const exportCSV = () => {
    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const { property: contextProperty, project: contextProject } = context || {}
    const property = (properties || []).find(p => p?.id === contextProperty)
    const project = (projects || []).find(p => p?.id === contextProject)
    const names = {
      property: property?.name?.replace(/ /g, '_'),
      project: project?.name?.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows
    const filterData = (defaultData || []).map(list => {
      const {
        vendor,
        servicetype,
        item_options,
        full_unit,
        custom_complete_date,
        status,
        amount,
        po_number,
        po_status,
        type,
        price_type
      } = list || {}
      return {
        Vendor: vendor || '',
        Service: servicetype || '',
        Type: type || '',
        'Price Type': price_type || '',
        Option: item_options || '',
        Unit: full_unit || '',
        'Charges Date': custom_complete_date || '',
        Status: status || '',
        'Amount ($)': amount || '',
        'Purchase Order': po_number || '',
        'P.O Status': po_status || ''
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names?.project}-${names?.property}-all-charges-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    if (outsideFilters && Object.keys(outsideFilters).length) {
      if (errorMessage) {
        setErrorMessage('')
      }
      let filterKey = {}
      Object.keys(outsideFilters).forEach(filter => {
        if (outsideFilters[filter]['active']) {
          filterKey = outsideFilters[filter]
        }
      })
      if (Object.keys(filterKey || {}).length !== 0) {
        if (filterKey?.label === 'Last 30 Days') {
          const today = new Date()
          const priorDate = new Date().setDate(today.getDate() - 30)
          const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
          const convertPriorDate = formatDateString(priorDate, 'DD-MMM-YY')
          getFilteredChargeData(convertPriorDate, convertTodayDate)
        } else if (filterKey?.label === 'Yesterday') {
          const today = new Date()
          const yesterdayDate = new Date().setDate(today.getDate() - 1)
          const convertYesterdayDate = formatDateString(
            yesterdayDate,
            'DD-MMM-YY'
          )
          getFilteredChargeData(convertYesterdayDate, convertYesterdayDate)
        } else if (filterKey?.label === 'All') {
          const today = new Date()
          const convertTodayDate = formatDateString(today, 'DD-MMM-YY')
          getFilteredChargeData(convertTodayDate, 0)
        } else if (filterKey?.label === 'Custom') {
          const { from, to } = dates || {}
          const convertFromDate = formatDateString(from, 'DD-MMM-YY')
          const convertToDate = formatDateString(to, 'DD-MMM-YY')
          getFilteredChargeData(convertFromDate, convertToDate)
        }
      }
    }
  }, [outsideFilters])

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  return (
    <div className="charges-list-container" id="charges-list-container">
      <div className="data-table-wrapper">
        <div className="data-table-header" id="data-table-header">
          <div className="header">
            <h2 className="heading-5">Charges</h2>
          </div>
          <div className="action-button">
            <div className="inspection-action-buttons">
              <button
                className="button is-success"
                disabled={!hasPermission}
                onClick={e => {
                  if (hasPermission) {
                    onAddChargeHandler()
                  }
                }}>
                Create Charge
              </button>
              {userSwiftActions()}
              <div
                style={{ display: 'inline-block' }}
                className="calendar-container">
                <div
                  className={`dropdown filter-dropdown ${
                    scheduleFiltersDropdownOpen ? 'is-active' : ''
                  }`}
                  id="filter-dropdown"
                  ref={outsideFiltersRef}>
                  <div className="dropdown-trigger">
                    <button
                      className="button m-r-md is-secondary"
                      type="button"
                      aria-haspopup={true}
                      aria-controls="filter-options"
                      onClick={() =>
                        setScheduleFiltersDropdownOpen(
                          !scheduleFiltersDropdownOpen
                        )
                      }>
                      <img
                        src={calendar}
                        alt="Filter data"
                        className="m-r-xs"
                      />
                      :{' '}
                      {Object.keys(outsideFilters).length &&
                        getActiveScheduledFilterLabel()}
                    </button>
                  </div>
                  <div
                    className="dropdown-menu"
                    role="menu"
                    id="filter-options">
                    <div className="dropdown-content has-text-left">
                      {Object.keys(outsideFilters).map((filter, index) => (
                        <button
                          className={cn(
                            'button filter-button',
                            { 'is-primary': outsideFilters?.[filter]?.active },
                            {
                              'is-secondary': !outsideFilters?.[filter]?.active
                            }
                          )}
                          onClick={() => {
                            const currentActiveValue =
                              outsideFilters[filter]['active']
                            const activeFilter = Object.keys(
                              outsideFilters
                            ).find(filter => outsideFilters[filter]['active'])
                            setOutsideFilters(
                              getOutsideFilters({
                                from: dates.from,
                                to: dates.to
                              })
                            )
                            setOutsideFilters(outsideFilters => {
                              return {
                                ...outsideFilters,
                                [filter]: {
                                  ...outsideFilters[filter],
                                  active:
                                    filter === activeFilter
                                      ? true
                                      : !currentActiveValue
                                }
                              }
                            })
                            setScheduleFiltersDropdownOpen(false)
                          }}
                          key={`custom-filter-${index}`}>
                          {outsideFilters[filter]['icon'] && (
                            <img
                              src={outsideFilters[filter]['icon']}
                              alt={outsideFilters[filter]['label']}
                              className="p-r-md"
                              style={{ width: 34 }}
                            />
                          )}
                          {outsideFilters[filter]['label']}
                        </button>
                      ))}
                      <div
                        style={{
                          borderRadius: 4,
                          paddingLeft: '1em',
                          paddingRight: '1em',
                          paddingBottom: 'calc(0.5em - 1px)',
                          background:
                            Object.keys(outsideFilters).length &&
                            outsideFilters['custom']['active']
                              ? '#3cb3e2'
                              : 'inherit'
                        }}>
                        <DayPickerInput
                          onDayChange={handleDayClick}
                          dayPickerProps={{
                            modifiers: modifiers,
                            firstDayOfWeek: calendarConfig.firstDayOfWeek,
                            weekdaysShort: calendarConfig.weekdaysShort,
                            selectedDays: [
                              dates.from,
                              { from: dates.from, to: dates.to }
                            ],
                            month: new Date(dates.from)
                          }}
                          hideOnDayClick={false}
                          formatDate={formatDate}
                          format={'MM/dd'}
                          parseDate={parseDate}
                          placeholder={`${dateFnsFormat(
                            new Date(),
                            'MM/dd'
                          )} - ${dateFnsFormat(new Date(), 'MM/dd')}`}
                          component={customPickerInput}
                          ref={ref => (datePickerRef = ref)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <div className="right">
              <SwitchButton
                isActive={showNotAdded}
                onChange={setShowNotAdded}
                removeMargin
                leftLabel="Show All"
                rightLabel="Show Not Added"
              />
              <button
                className="export"
                disabled={!hasPermission}
                onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
          </div>
          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        {hasMadeInitialRequest && !isRequesting ? (
          <Styles>
            <Table
              ref={tableRef}
              columns={columns}
              data={filteredChargesList}
              onRowSelectStateChange={setSelectedRows}
              setIsFilterApplied={setIsFilterApplied}
              setSelectedPage={setSelectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Styles>
        ) : (
          <BigLoading />
        )}
        <div className="table-pagination">
          {hasMadeInitialRequest && !isRequesting ? (
            <Paginator
              previousPage={tableRef.current?.previousPage}
              nextPage={tableRef.current?.nextPage}
              rowsPerPage={rowsPerPage}
              rowCount={selectedPageRowData}
              currentPage={selectedPage}
              onChangePage={page => tableRef.current?.gotoPage(page - 1)}
              setRowsPerPage={pageSize => {
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 50, 70, 100]}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default ChargesList
