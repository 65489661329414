import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import Service from '../../service'
import { logout } from '../../modules/auth'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'


const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)

const initOptions = {
  domain: Service.DOMAIN.auth0,
  client_id: Service.CLIENT_ID,
  redirect_uri: window.location.origin,
  cacheLocation: 'localstorage'
}

export const Auth0Provider = ({
  children,
  auth,
  giveAccess,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
  const dispatch =  useDispatch()

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated)
  const [user, setUser] = useState()
  const [accessToken, setAccessToken] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)
    
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
        const token = await auth0FromHook.getTokenSilently()
        const getIdToken = await auth0FromHook.getIdTokenClaims()
        const { __raw } = getIdToken || {}
        Service.setIdTokenCookie(__raw || '')
        setAccessToken(token)
        if (!auth.isAuthenticated) {
          giveAccess(token)
        }
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isAuthenticated && !auth.isAuthenticated) {
      giveAccess(accessToken)
    }
  }, [isAuthenticated, accessToken, auth])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  
  const handleLogout = async (...p) => {
    try {
      await auth0Client.logout(...p)
    } catch(err) {
      console.log(err.message)
    }
  }
  
  useEffect(()=> {
    if(!isEmpty(auth0Client)) {
      auth0Client
        .isAuthenticated()
        .then(isLoggedIn => {
          if(!isLoggedIn) dispatch(logout())
        })
        .catch(err => console.log(err))
    }
  },[auth0Client, dispatch])

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        accessToken,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: handleLogout
      }}>
      {children}
    </Auth0Context.Provider>
  )
}
