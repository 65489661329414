import _ from 'lodash'
import Short from 'short-uuid'
import React, { useState, useEffect } from 'react'
import { icons } from './icons'

const EmptyState = () => (
  <div className="columns">
    <div className="column has-text-centered">
      <img src={icons.emptyPage} alt="Empty Page Icon" />
      <p className="is-size-5 m-t-md">No sorting has been specified yet!</p>
      <p className="has-text-grey is-size-7 m-t-sm">
        Please add new columns to sort your table.
      </p>
    </div>
  </div>
)

const SortColumn = ({ allColumns, currentSort, index, onSortChanged }) => {
  const columnNames = _.map(currentSort, s => s.replace(/^-+/, ''))
  const availableColumns = _.filter(
    allColumns,
    column =>
      columnNames.indexOf(column.key) < 0 ||
      column.key === _.get(currentSort, `${index}`, '').replace(/^-+/, '')
  )
  return (
    <tr>
      <td className="is-narrow no-wrap is-borderless">Sort by:</td>
      <td className="no-wrap is-borderless">
        <div className="select is-fullwidth">
          <select
            className="input is-inline"
            onChange={e =>
              onSortChanged(
                index,
                e.target.value,
                _.get(currentSort, `${index}.0`, '') === '-' ? 'desc' : 'asc'
              )
            }
            value={_.get(currentSort, index, '').replace(/^-+/, '')}>
            <option value="" key={Short.uuid()}>
              Select Column
            </option>
            {_.map(availableColumns, column => (
              <option value={column.key} key={Short.uuid()}>
                {column.caption}
              </option>
            ))}
          </select>
        </div>
      </td>
      <td className="no-wrap is-borderless">
        <div className="select is-fullwidth">
          <select
            className="input is-inline"
            onChange={e =>
              onSortChanged(
                index,
                _.get(currentSort, index).replace(/^-+/, ''),
                e.target.value
              )
            }
            value={_.get(currentSort, `${index}.0`, '') === '-' ? 'desc' : 'asc'}>
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
      </td>
    </tr>
  )
}

const SortModal = ({ open, startSort, allColumns, onSort, onClose }) => {
  const [sort, setSort] = useState([])
  const [adding, setAdding] = useState(true)

  useEffect(() => {
    setSort(startSort)
    if (sort.length) {
      setAdding(false)
    }
  }, [startSort])

  useEffect(() => {
    if (!sort.length) {
      setAdding(true)
    }
  }, [sort, open])

  const updateSort = (index, column, direction) => {
    const sortColumn = direction === 'asc' ? column : `-${column}`
    const sortCopy = [...sort]
    _.set(sortCopy, adding ? index - 1 : index, sortColumn)
    setSort(sortCopy)
    if (adding) setAdding(false)
  }

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body">
          <div className="columns is-desktop is-mobile">
            <div className="column">
              <p className="heading-4 is-pulled-left">Multiple Sort</p>
              <p
                className="is-pulled-right is-pointer is-unselectable has-text-grey-light"
                onClick={() => {
                  onClose()
                }}>
                X
              </p>
            </div>
          </div>
          <div className="columns is-multiline p-t-none m-t-none">
            <div className="column is-full p-t-none">
              {sort.length === 0 && !adding ? (
                <EmptyState />
              ) : (
                <table id="multi-sort" className="table">
                  <tbody>
                    {_.map(sort, (s, i) => (
                      <SortColumn
                        key={Short.uuid()}
                        allColumns={allColumns}
                        currentSort={sort}
                        index={i}
                        onSortChanged={updateSort}
                      />
                    ))}
                    {adding && (
                      <SortColumn
                        key={Short.uuid()}
                        allColumns={allColumns}
                        currentSort={sort}
                        index={sort.length + 1}
                        onSortChanged={updateSort}
                      />
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="columns is-vcentered">
            {!adding && (
              <div
                className="column is-narrow is-pointer p-r-md"
                onClick={() => setAdding(true)}>
                <img
                  alt="Add Filter"
                  style={{ height: '20px' }}
                  src={icons.setupAdd}
                  className="m-r-xs"
                />
                <span style={{ verticalAlign: 'bottom' }}>Add Level</span>
              </div>
            )}
            {sort.length > 0 && (
              <div
                className="column is-narrow is-pointer"
                onClick={() => setSort(sort.slice(0, sort.length - 1))}>
                <img
                  alt="Remove Last Filter"
                  style={{ height: '20px' }}
                  src={icons.trash}
                  className="m-r-xs"
                />
                <span style={{ verticalAlign: 'bottom' }}>Delete Level</span>
              </div>
            )}
          </div>
          <div className="center-container">
            <button className="button main-button is-secondary m-r-md" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button main-button is-primary"
              onClick={() => {
                onSort(sort)
              }}>
              Sort
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SortModal
