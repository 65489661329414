import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'ui'
import { v4 as uuidv4 } from 'uuid'
import { useDropzone } from 'react-dropzone'
import { useAppContext } from 'hooks'
import { closeModal } from 'modules/modal'
import {
  getInspectionUploadMediaURL,
  addInspectionMedia
} from 'modules/inspect'
import { remove as removeIcon } from 'icons'
import { attachInvoiceToPurchaseOrder } from 'modules/invoices'
import './index.scss'
import moment from 'moment'

const InvoiceAttachModal = ({ item }) => {
  const dispatch = useDispatch()

  const {
    user_metadata: { pdbid }
  } = useSelector(state => state.user)
  const context = useSelector(state => state.context)

  const { details: invoice, detailsIsRequesting, detailsIsError } = useSelector(
    state => state.invoices
  )
  const inspect = useSelector(state => state.inspect)

  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    dispatch(getInspectionUploadMediaURL(pdbid, context))
  }, [pdbid, context])

  useEffect(() => {
    if (!detailsIsRequesting && !detailsIsError && submitted) {
      dispatch(closeModal())
    }
  }, [detailsIsRequesting, detailsIsError, submitted])

  const onDrop = useCallback((acceptedFiles = [], rejectedFiles = []) => {
    setFile(null)
    setErrorMessage('')
    if (rejectedFiles.length !== 0) {
      return setErrorMessage(
        'This file type is not supported. Please select a PDF file instead.'
      )
    }
    if (acceptedFiles.length !== 0) {
      setFile(acceptedFiles[0])
    }
  }, [])

  const { getInputProps, getRootProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'application/pdf'
  })

  const uploadInvoice = async () => {
    const { property, project } = context || {}
    const uuid_ = uuidv4()
    const body = file || {}
    const randomNumber = Math.floor(Math.random() * 90000) + 10000
    const fileName = `${uuid_}_${randomNumber}_${file?.name || ''}`
    const path = `Inspections/${project}_${property}`
    const objectName = `${path}/${fileName}`
    const url = `${inspect.inspectionUploadMediaURL || ''}${objectName}`
    const approved_at = invoice?.approved_at
      ? moment(invoice?.approved_at).format('YYYY/MM/DD HH:mm:ss')
      : invoice?.approved_at

    try {
      await dispatch(addInspectionMedia(url, body, 'application/pdf'))
      await dispatch(
        attachInvoiceToPurchaseOrder(
          pdbid,
          context,
          invoice.po_id,
          fileName,
          invoice?.approved_by,
          approved_at,
          invoice?.status
        )
      )
      setSubmitted(true)
    } catch (e) {
      console.log({ error: e })
      setErrorMessage('Something went wrong. Please try again later !!!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Attach Invoice"
      okText="Attach"
      autoClose={false}
      okType="primary"
      titleSize="4"
      okDisabled={!file}
      cancelText="Cancel"
      loading={loading}
      onConfirm={() => {
        setLoading(true)
        setErrorMessage('')
        uploadInvoice()
      }}>
      <div className="columns">
        <div className="column has-text-centered">
          <div className="upload__box" {...getRootProps()}>
            <input className="upload__input" type="file" {...getInputProps()} />
            <label htmlFor="file">
              {file?.name ? (
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {file.name}
                </span>
              ) : (
                <span className="is-full">
                  Drag the vendor invoice file here
                  <br />
                  or
                </span>
              )}
              <span className="is-fake" onClick={open}>
                Browse
              </span>
            </label>
            {errorMessage && (
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={removeIcon} alt="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InvoiceAttachModal
