import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { formatDateString } from '../../utils/index'
import BigLoading from '../../components/big-loading'
import {
  vendorQuotationGet,
  updateVendorQuotation,
  vendorQuotationBusinessInformationGet,
  updateVendorBusinessInformation,
  resetVendorQuotationState,
  updateVendorQuotationCapacityByDate
} from '../../modules/vendor-quotation'
import {
  allRequestRFPGet
} from '../../modules/request-rfp'
import './index.scss'
import ProgressSection from '../../components/progress-section'
import VendorServiceComponent from '../../components/vendor-service-tabs'
import ReviewAndSubmit from '../../components/vendor-service-tabs/ReviewAndSubmit'

const VendorQuotationMain = (props) => {
  const { user, context, vendorQuotationGet, resetVendorQuotationState, requestRFPS,  updateVendorQuotation, updateVendorBusinessInformation, vendorQuotation, vendorQuotationBusinessInformationGet, updateVendorQuotationCapacityByDate } = props
  const { allRFP } = requestRFPS || {}
  const { data: rfpList } = allRFP || {} 
  const [vendorQuote, setVendorQuote] = useState({})
  const [selectedTab, setSelectedTab] = useState({})
  const [activeProgressItem, setActiveProgressItem] = useState({
    active: '',
    completed:[]
  })
  const [reviewAndSubmit, setReviewAndSubmit] = useState({
    active: false,
    prevTab:''
  })
  const [currentActiveTab, setCurrentActiveTab] = useState('')
  const [touchedChildTabs, setTouchedChildTabs] = useState({})
  const [isEdit, setIsEdit] = useState(true)
  const [isManualEntry, setIsManualEntry] = useState(false)

  // Set Maunal Entry For GM
  const updateManualEntry = (status) => {
    setIsManualEntry(status)
    setIsEdit(true)
  }

  // Data from props and router state
  const { location, history } = props || {}
  const { state } = location || {}
  const { title, dueDate, id, gmView } = state || {
    title: 'Vendor Name',
    dueDate: '11/30/2021'
  }
  // Redux Data
  const { vendorQuotation : quoteData, vendorQuotationBusinessInfo } = vendorQuotation || {}
  const {data,  constructed, isRequesting, hasMadeInitialRequest} = quoteData || {}
  const {data: businessData} = vendorQuotationBusinessInfo || {}
  const { submitted_at, status, approved_at, rejected_at, country } = businessData || {}
  //const [submitted_at, setsubmitted_at] = useState('')

  // Update Default country
  useEffect(() => {
    if(Object.keys(businessData || {}).length !== 0 && country === null) {
      const { user_metadata } = user || {}
      const { pdbid, pdbids } = user_metadata || {}
      const body = {
        id: id || '',
        data: {
          country : 'United States'
        }
      }
      updateVendorBusinessInformation({
        pdbid: (pdbid || pdbids[0]),
        context,
        body,
        oldData: businessData || {},
      })
    }
  },[businessData])

  // get selected rfp list

  useEffect(() => {
    if((rfpList || []).length === 0) {
      const { context, user, allRequestRFPGet } = props || {}
      const { user_metadata } = user || {}
      const { pdbid } = user_metadata || {}
      
      allRequestRFPGet(
        pdbid,
        context
      )
      return
    }
  }, [rfpList])

  const onChangeQuotationStatusByGM = (quoteStatus) => {

    if(quoteStatus) {
      const { match, context } = props || {}
      const { params } = match || {}
      const { id } = params || {}
      const { user_metadata } = user || {}
      const { pdbid, pdbids, properties } = user_metadata || {}
      const { property } = context || {}
      const date = moment().format('YYYY-MM-DD')
      let body = {
        id: id || '',
        data: {
          [quoteStatus] : date
        }
      }

      if(quoteStatus === 'approved_at') {
        const selectedRequest = (rfpList || []).find((item) =>  item.rfp_request_id === id)
        const selectedProperty = (properties || []).find(p => p.id === property)
        const { name: property_name } = selectedProperty || {}
        const { vendor_email } = selectedRequest || {}
        body = {
          ...body,
          data: {
            ...body.data,
            vendor_email,
            property_name
          }
        }
      }
      updateVendorBusinessInformation({
        pdbid: (pdbid || pdbids[0]),
        context,
        body,
        onSuccess: onSuccessQuotationStatusByGM,
        oldData: businessData || {},
      })
    }
  }

  

  const onSuccessQuotationStatusByGM = (quoteStatus) => {
    if(quoteStatus === 'success') {
      console.log('success')
    }
  }

  // Update Edit state of Quotation form
  useEffect(() => {
    if(gmView) {
      setIsEdit(false)
    } if(submitted_at) {
      setIsEdit(false)
    }
  },[gmView, submitted_at])

  // Request for getting Initial Data from API
  useEffect(() => {
    const { user_metadata } = user || {}
    const { pdbid, pdbids } = user_metadata || {}
    vendorQuotationGet((pdbid || pdbids[0]), context, id)
    vendorQuotationBusinessInformationGet((pdbid || pdbids[0]), context, id)
  }, [])

  // If Quote is submitted then check all progress bar items
  useEffect(() => {
    if(submitted_at) {
      const getIds = (progressSectionItems || []).map(pItem => pItem.itemId)
      updateActiveProgressItem(getIds, 'completed')
    }
  },[submitted_at])

  useEffect(() => {
    // If Quotation Object and Services are already Construct And Filtered
    if(constructed) {
      setVendorQuote(data)
    }
    // Create Quotation Object and Construct And Filtered Services
    else if(Object.keys(data || {}).length !== 0 && !constructed) {
      const description = 'Please provide the pricing for each option below and the capacity for each day during the timeframe listed for this project. When you are finished, click “Next Section” to move on.'
      const businessDescription = 'Please provide your business information. Fields marked with an asterisk (*) are required. When you are finished, click “Review” to verify all your responses before submitting.'
      const keys = Object.keys(data || [])
      const constructQuotationObject = {}
      let defaultSelectedTab = {}
      let constructTouchedChildTab = {};
      (keys || []).forEach((key,i) => {
        if(i === 0) {
          defaultSelectedTab = {
            id: (key || '').replace(' ', '_').toLowerCase(),
            serviceName: (key || ''),
            description,
            heading:`${(key || '')} & Capacity`,
            services: constructAndFilterServices(data[key] || []),
          }
        }
        constructQuotationObject[key] = {
          id: (key || '').replace(' ', '_').toLowerCase(),
          serviceName: (key || ''),
          description,
          heading:`${(key || '')} & Capacity`,
          services: constructAndFilterServices(data[key] || []),
        }
        constructTouchedChildTab[key] = {
          'Ad Hoc Pricing':false,
          'Floorplan Pricing': false,
          'Unit Type Pricing':false,
          'Capacity By Date': false,
        }
      })
      // Add Business Information Tab
      constructQuotationObject['Business Information'] = {
        id: 'business_information',
        serviceName: 'Business Information',
        heading: 'Business Information',
        description: businessDescription,
      }
      setSelectedTab(defaultSelectedTab || {})
      setVendorQuote(constructQuotationObject || {})
      setTouchedChildTabs(constructTouchedChildTab)
    }
  },[quoteData])


  // Set Current Active Tab and update Progress Section
  useEffect(() => {
    const { id, serviceName, services } = selectedTab || {}
    setCurrentActiveTab(serviceName || '')
    updateActiveProgressItem( id || '', 'active')
    if(Object.keys(services || {}).length !== 0) {
      onChangeTouchedChildTab(serviceName, Object.keys(services || {})[0] || '')
    } else {
      onChangeTouchedChildTab(serviceName, 'Ad Hoc Pricing')
    }
  },[selectedTab])

  // Update Progress section based on active or completed
  const updateActiveProgressItem = (values, type) => {
    if(type === 'active') {
      setActiveProgressItem((prevState) => ({
        ...prevState,
        active: values
      }))
    } else if(type === 'completed') {
      setActiveProgressItem((prevState) => ({
        ...prevState,
        completed: values
      }))
    }
  }

  // Change Touched child tab state
  const onChangeTouchedChildTab = (serviceName, type) => {
    setTouchedChildTabs((prevState) => ({
      ...prevState,
      [serviceName] : {
        ...prevState[serviceName],
        [type] : true
      }
    }))
  }

  // Set current selected Parent Tab
  const onHandleSelectedTab = (v) => {
    setSelectedTab(v)
  }

  // Change Active tab from Inside Child Component
  const onChangeCurrentActiveTab = (id) => {
    setCurrentActiveTab(id || '')
  }

  // Update vendor Quote Status
  const updateVendorQuoteStatus = (status) => {
    const { user_metadata } = user || {}
    const {  pdbid, pdbids } = user_metadata || {}
    const body = {
      id: id || '',
      data: {
        status
      }
    }
    updateVendorBusinessInformation({
      pdbid: (pdbid || pdbids[0]),
      context,
      body,
      oldData: businessData || {},
    })
  }

  // Update vendor quote data
  const onChangeVendorQuotation = (body, childTabInfo) => {
    const {user_metadata} = user || {}
    const { pdbid, pdbids } = user_metadata || {}
    const { childTabContent, childTabType } = childTabInfo || {}
    console.log(childTabType)
    const { serviceName } = selectedTab || {}
    const data = vendorQuote[serviceName] || {}
    const updateChildServices = {
      ...data,
      services:{
        ...data?.services || {},
        [childTabType]: childTabContent || []
      }
    }
    const updateParentServices = {
      ...vendorQuote,
      [serviceName]: updateChildServices
    }
    setVendorQuote(updateParentServices)
    if(childTabType === 'Capacity By Date') {
      updateVendorQuotationCapacityByDate({
        pdbid: (pdbid || pdbids[0]),
        context,
        body,
        newData: updateParentServices,
        oldData: vendorQuote
      })
    } else {
      updateVendorQuotation({
        pdbid: (pdbid || pdbids[0]),
        context,
        body,
        newData: updateParentServices,
        oldData: vendorQuote
      })
    }
  }

  // Change review and submit state for conditional renreding component below
  const onChangeReviewAndSubmit = (status, prevTab) => {
    setReviewAndSubmit({
      active: status,
      prevTab
    })
  }

  // Construct services data from vendor quote
  const constructAndFilterServices = (services) => {
    const capacityDate = []
    const floorPlan = []
    const adhocPricing = []
    const unitType = [];
    (services || []).map(cService => {
      const { item_option_id = '', request_item_id='', item_option = '', cost ='', notes='', floorplan_id='', capacity='', capacity_date='', bed_count='', bath_count='', unit_type, rfp_capacity_id } = cService || {}
      if(capacity_date) {
        const updateDateFormat = '20'+(capacity_date || '').toString().substring(2)
        const convertDateToReadable = updateDateFormat ? formatDateString(updateDateFormat, 'MM/DD/YYYY') : 'N/A'
        capacityDate.push({
          s_id: rfp_capacity_id || '',
          id: convertDateToReadable || '',
          type: convertDateToReadable || '',
          serviceName:'Capacity By Date',
          inputType: {
            capacity :{
              inputType: 'text',
              placeholder: 'Enter Capacity',
              value: capacity || ''
            },
            notes :{
              inputType: 'text',
              placeholder: 'Notes',
              value: notes || ''
            }
          }
        })
      } else if(unit_type && !capacity_date) {
        unitType.push({
          s_id: request_item_id || '',
          id: request_item_id || '',
          type: unit_type || '',
          serviceName:'Unit Type Pricing',
          inputType: {
            cost :{
              inputType: 'text',
              placeholder: 'Enter Cost',
              value: cost || ''
            },
            notes :{
              inputType: 'text',
              placeholder: 'Notes',
              value: notes || '',
            }
          }
        })
      } else if(floorplan_id && !capacity_date && !unit_type) {
        const label = `${bed_count || ''} x ${bath_count || ''}`
        floorPlan.push({
          s_id: request_item_id || '',
          id: floorplan_id || '',
          type: label || '',
          serviceName:'Floorplan Pricing',
          inputType: {
            cost :{
              inputType: 'text',
              placeholder: 'Enter Cost',
              value: cost || ''
            },
            notes :{
              inputType: 'text',
              placeholder: 'Notes',
              value: notes || ''
            }
          }
        })
      } else if(item_option_id && !capacity_date && !floorplan_id && !unit_type) {
        adhocPricing.push({
          s_id: request_item_id || '',
          id: item_option_id || '',
          type: item_option || '',
          serviceName:'Ad Hoc Pricing',
          inputType: {
            cost :{
              inputType: 'text',
              placeholder: 'Enter Cost',
              value: cost || ''
            },
            notes :{
              inputType: 'text',
              placeholder: 'Notes',
              value: notes || '',
            }
          }
        })
      }
    });

    (capacityDate ||[]).sort(function(a,b){
      return new Date(a.id) - new Date(b.id)
    })
    let servicesObject = {}
    // Add Adhoc only if data exists
    if((adhocPricing || []).length !== 0) {
      servicesObject['Ad Hoc Pricing'] = filterServicesForDuplicate(adhocPricing || [])
    }
    // Add Floor Plan only if data exists
    if((floorPlan || []).length !== 0) {
      servicesObject['Floorplan Pricing'] = filterServicesForDuplicate(floorPlan || [])
    }
    // Add Unit Type only if data exists
    if((unitType || []).length !== 0) {
      servicesObject['Unit Type Pricing'] = filterServicesForDuplicate(unitType || [])
    }
    // Add Capacity only if data exists
    if((capacityDate || []).length !== 0) {
      servicesObject['Capacity By Date'] = filterServicesForDuplicate(capacityDate || [])
    }
    return servicesObject
  }

  // Filter services for uplicate
  const filterServicesForDuplicate = (array) => {
    return (array || []).filter((item, index, array) => (array || []).findIndex(list => list.id == item.id) == index)
  }

  // Get Parent Service
  const services = Object.keys((vendorQuote || []))
  // Construct list for progress section
  const constructProgressSectionItems = ((services || []).map((vServices, index) => {
    return {
      itemId: (vServices || '').replace(' ', '_').toLowerCase(),
      title: (vServices || ''),
    }
  }) || [])
  // Add Review to progress section
  const newProgressSectionItems = {
    itemId: 'review_submit',
    title: 'Review & Submit',
  }
  // Compiling Progress section
  let progressSectionItems = []
  if((constructProgressSectionItems || []).length !== 0) {
    progressSectionItems = [...constructProgressSectionItems, newProgressSectionItems ]
  }

  //  Conditonal rendering based on review and submit tab
  const renderComponentConditionally = () => {
    if(vendorQuote!==undefined && vendorQuote.length !==0) {
      const { active } = reviewAndSubmit || {}
    
      return <div className="content-section columns">
        <div className="main-content-section column is-three-quarters">
          {active 
            ? <ReviewAndSubmit updateVendorQuoteStatus={ updateVendorQuoteStatus } onChangeCurrentActiveTab={ onChangeCurrentActiveTab }  updateActiveProgressItem={ updateActiveProgressItem } activeProgressItem = { activeProgressItem } user={ user } context= { context } id={ id } updateVendorBusinessInformation={ updateVendorBusinessInformation } reviewAndSubmit={reviewAndSubmit} onChangeReviewAndSubmit={ onChangeReviewAndSubmit } vendorQuotationBusinessInfo={ vendorQuotationBusinessInfo } vendorQuote={vendorQuote} />
            : <VendorServiceComponent quoteStatus={ status } onChangeQuotationStatusByGM={ onChangeQuotationStatusByGM } updateManualEntry={ updateManualEntry } isManualEntry= { isManualEntry } gmView = { gmView } updateVendorQuoteStatus={ updateVendorQuoteStatus } isEdit= { isEdit } submitted_at= { submitted_at } touchedChildTabs={ touchedChildTabs } onChangeTouchedChildTab={ onChangeTouchedChildTab } businessInformation={ businessData } currentActiveTab={ currentActiveTab } reviewAndSubmit={reviewAndSubmit}  onChangeReviewAndSubmit={ onChangeReviewAndSubmit } onChangeVendorQuotation={ onChangeVendorQuotation } activeProgressItem = { activeProgressItem } selectedTab={selectedTab} services={ services } vendorQuote={vendorQuote} onHandleSelectedTab={onHandleSelectedTab} updateActiveProgressItem={ updateActiveProgressItem } />
          }
        </div>
        <div className="progress-section-wrapper column is-one-quarter">
          <ProgressSection  submitted_at= { submitted_at } progressItems = { progressSectionItems } activeItem = { activeProgressItem }/>
        </div>
      </div>
    }
  }

  // Conditonal rendering based on due date and submitted date
  const displayDate = (dueDate, submittedDate, status,  rejected_at, approved_at) => {
    if(submittedDate) {
      if(status === 'approved') {
        return <span className="submitted-at"> 
          {`APPROVED: ${ approved_at ? formatDateString(approved_at, 'MM/DD/YYYY') : 'APPROVED DATE' }`}
        </span>
      }
      if(status === 'rejected' || status === 'closed') {
        return <span className="deadline"> 
          {`REJECTED: ${ rejected_at ? formatDateString(rejected_at, 'MM/DD/YYYY') : 'REJECTED DATE' }`}
        </span>
      }
      return <span className="submitted-at"> 
        {`SUBMITTED: ${ submittedDate ? formatDateString(submittedDate, 'MM/DD/YYYY') : 'SUBMITTED DATE' }`}
      </span> 
    } 
    return <span className="deadline"> 
      {`DUE: ${ dueDate ? formatDateString(dueDate, 'MM/DD/YYYY') : 'DUE DATE' }`}
    </span>
  }

  return (
    <div className="container setup-vendors-main animated fadeIn">
      { (hasMadeInitialRequest && !isRequesting) ? <div className="section quotation">
        <button className="go-back-button" onClick={(e) => {
          e.preventDefault()
          history.goBack()
          resetVendorQuotationState()
        }}>
          Back to List View
        </button>
        {(Object.keys(data || {}).length !== 0) ? <>
          <div className="top-header columns">
            <div className="column is-three-quarters">
              <div className="top-header-container">
                <h2 className="is-capitalized">
                  {title || 'Title'}
                </h2>
                {displayDate(dueDate, submitted_at, status, rejected_at, approved_at)}          
              </div>
            </div>
          </div>
        {renderComponentConditionally()}
        </> : <div class="notification is-danger is-light mt-30">
            No Data found in this Quotation
        </div>
        }
        
      </div> : <BigLoading /> 
      }
    </div> 
  )
}

VendorQuotationMain.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  context: PropTypes.object,
  vendorQuotation: PropTypes.object,
  vendorQuotationGet: PropTypes.func,
  updateVendorQuotation: PropTypes.func,
  vendorQuotationBusinessInformationGet: PropTypes.func,
  updateVendorBusinessInformation: PropTypes.func,
  resetVendorQuotationState: PropTypes.func,
  updateVendorQuotationCapacityByDate: PropTypes.func,
}

const mapStateToProps = ({ user, context, vendorQuotation, requestRFPS }) => ({
  user,
  context,
  vendorQuotation,
  requestRFPS
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      vendorQuotationGet,
      updateVendorQuotation,
      vendorQuotationBusinessInformationGet,
      resetVendorQuotationState,
      updateVendorBusinessInformation,
      updateVendorQuotationCapacityByDate,
      allRequestRFPGet
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VendorQuotationMain)
)

