import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getKeysList,
  getKeysStats,
  updateKey,
  getKeysSyncData,
  doSyncKeysData
} from '../../modules/keys'
import { getServiceColumns } from '../../modules/services'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'
import KeysMain from '../../parents/keys-main'

const INTERVAL_DURATION = 10000

const Keys = ({
  context,
  user,
  keys,
  services,
  responsive,
  location,
  setModalContent,
  openModal,
  closeModal,
  updateKey,
  setTooltipContent,
  openTooltip,
  closeTooltip,
  tooltip,
  modal,
  auth,
  getKeysList,
  getKeysStats,
  getServiceColumns,
  getKeysSyncData,
  doSyncKeysData
}) => {
  const prevContext = usePrevious(context)
  const prevKeys = usePrevious(keys)

  const syncInterval = useRef(null)
  const { property, project } = context || {}
  const { pdbid } = user.user_metadata
  const { keysSyncState } = keys || {}

  const handleSyncKeys = () => {
    const { pdbid } = user.user_metadata
    doSyncKeysData(pdbid, context)

    syncInterval.current = setInterval(() => {
      getKeysSyncData(pdbid, context)
    }, INTERVAL_DURATION)
  }

  useEffect(() => {
    if (property && project && pdbid) {
      getKeysSyncData(pdbid, context)
    }
  }, [property, project, pdbid])

  useEffect(() => {
    return () => {
      clearInterval(syncInterval.current)
      syncInterval.current = null
    }
  }, [])

  useEffect(() => {
    if (
      syncInterval &&
      !keysSyncState.keysSyncRequested &&
      keysSyncState.status !== 'inprogress'
    ) {
      clearInterval(syncInterval.current)
      syncInterval.current = null
    }
  }, [syncInterval, keysSyncState])

  // useEffect(() => {
  //   const { listIsRequesting, statsIsRequesting } = keys
  //   if (!listIsRequesting && !statsIsRequesting) {
  //     populateData()
  //   }
  // }, [user, auth])

  useEffect(() => {
    if (property && project && pdbid) {
      getServiceColumns({ pdbid, context })
    }
  }, [property, project, pdbid])

  useEffect(() => {
    const {
      updateIsRequesting,
      updateIsError,
      listHasRequested,
      statsHasRequested
    } = keys

    const madeSuccessfulUpdate =
      !updateIsRequesting &&
      !updateIsError &&
      _.get(prevKeys, 'updateIsRequesting') !== updateIsRequesting
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const ctxChanged = propertyChanged || projectChanged

    if (
      !listHasRequested ||
      !statsHasRequested ||
      madeSuccessfulUpdate ||
      ctxChanged
    )
      populateData()
  }, [context, keys])

  const populateData = () => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { pdbid } = user.user_metadata
    const { listIsRequesting, statsIsRequesting } = keys
    if (
      isAuthenticated &&
      hasMgmtToken &&
      pdbid !== null &&
      !listIsRequesting &&
      !statsIsRequesting
    ) {
      getKeysList(pdbid, context)
      getKeysStats(pdbid, context)
    }
  }

  const handleRefresh = () => populateData()

  const { statsHasRequested, listHasRequested } = keys

  return (
    <div>
      <KeysMain
        auth={auth}
        services={services}
        context={context}
        user={user}
        keys={keys}
        responsive={responsive}
        statsHasRequested={statsHasRequested}
        listHasRequested={listHasRequested}
        setModalContent={setModalContent}
        openModal={openModal}
        closeModal={closeModal}
        updateKey={updateKey}
        setTooltipContent={setTooltipContent}
        openTooltip={openTooltip}
        closeTooltip={closeTooltip}
        tooltip={tooltip}
        location={location}
        modal={modal}
        getKeysSyncData={getKeysSyncData}
        onRefresh={handleRefresh}
        onSyncKeys={handleSyncKeys}
      />
    </div>
  )
}

Keys.propTypes = {
  context: PropTypes.object,
  user: PropTypes.object,
  auth: PropTypes.object,
  keys: PropTypes.object,
  location: PropTypes.object,
  responsive: PropTypes.object,
  getKeysList: PropTypes.func,
  getKeysStats: PropTypes.func,
  updateKey: PropTypes.func,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  tooltip: PropTypes.object,
  openTooltip: PropTypes.func,
  closeTooltip: PropTypes.func,
  setTooltipContent: PropTypes.func,
  getServiceColumns: PropTypes.func
}

const mapStateToProps = ({
  context,
  user,
  auth,
  keys,
  responsive,
  tooltip,
  modal,
  services
}) => ({
  context,
  auth,
  user,
  keys,
  responsive,
  tooltip,
  modal,
  services
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getKeysList,
      getKeysStats,
      updateKey,
      openModal,
      closeModal,
      setModalContent,
      openTooltip,
      closeTooltip,
      setTooltipContent,
      getServiceColumns,
      getKeysSyncData,
      doSyncKeysData
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Keys)
)
