import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePurchaseOrderStatus } from 'modules/invoices'
import { Modal } from 'ui'
import './index.scss'

const InvoicesApproveModal = ({ invoice, ...restProps }) => {
  const dispatch = useDispatch()
  const {
    user_id,
    user_metadata: { pdbid }
  } = useSelector(state => state.user)
  const context = useSelector(state => state.context)

  return (
    <Modal
      title="Confirm Approval"
      okText="Approve"
      onConfirm={() =>
        dispatch(
          changePurchaseOrderStatus({
            pdbid,
            context,
            invoiceId: invoice.po_id,
            status: 'Pending AP Approval',
            approvedBy: user_id,
            invoice_file: invoice?.invoice_file
          })
        )
      }
      {...restProps}>
      <div className="columns">
        <div className="column has-text-centered">
          <p className="text-size-16">Are you ready to approve?</p>
        </div>
      </div>
    </Modal>
  )
}

export default InvoicesApproveModal
