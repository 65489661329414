import React, {
  useEffect,
  memo,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback
} from 'react'
import {
  useTable,
  useBlockLayout,
  useFilters,
  useRowSelect,
  useSortBy,
  usePagination
} from 'react-table'
import { FixedSizeList } from 'react-window'
import { icons } from '../icons'
import DefaultColumnFilter from '../../../components/react-table-column-filters/DefaultColumnFilter'
import { scrollbarWidth } from '../../../utils/helperFunctions'

import '../index.scss'
import moment from 'moment'

const IndeterminateCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      currentRow,
      rows,
      toggleRowSelected,
      enableManualSelection,
      showTooltip,
      onChange,
      headerCheckbox = false,
      toggleAllRowsSelected,
      title,
      disabled,
      ...rest
    },
    ref
  ) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          onChange={e => {
            if (!disabled) {
              onChange(e)
            }
          }}
          {...rest}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.2 : 1
          }}
          title={
            showTooltip ? 'This row cannot be selected.' : 'Toggle Row Selected'
          }
        />
      </>
    )
  }
)

const PayableTable = memo(
  forwardRef(
    (
      {
        columns,
        data,
        onRowSelectStateChange,
        setIsFilterApplied,
        setSelectedPage,
        setSelectedPageRowData,
        rowsPerPage,
        setRowsPerPage
      },
      ref
    ) => {
      // Use the state and functions returned from useTable to build your UI

      const defaultColumn = React.useMemo(
        () => ({
          width: 100,
          Filter: DefaultColumnFilter
        }),
        []
      )

      const filterTypes = React.useMemo(
        () => ({
          customEquals: (rows, id, filterValue) => {
            if (!filterValue) return rows
            id = id[0]
            return rows.filter(row => filterValue?.[id] === row.values?.[id])
          }
        }),
        []
      )
      const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
      const datePerPage = rowsPerPage
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds, filters, pageIndex, pageSize },
        selectedFlatRows,
        toggleRowSelected,
        setAllFilters,
        toggleAllRowsSelected,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        setPageSize,
        totalColumnsWidth
      } = useTable(
        {
          columns,
          data,
          defaultColumn,
          filterTypes,
          sortTypes: {
            alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
              const rowOneColumn = row1?.values[columnName]
              const rowTwoColumn = row2?.values[columnName]
              const dateColumns = [
                'custom_complete_date',
                'approved_at',
                'po_date',
                'due_date'
              ]

              if (
                dateColumns.includes(columnName) &&
                moment(rowOneColumn).isValid() &&
                moment(rowTwoColumn).isValid()
              ) {
                return moment(rowOneColumn).isAfter(rowTwoColumn) ? 1 : -1
              }

              if (columnName === 'total') {
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1
              }
              return (rowOneColumn || '').toString().toLowerCase() >
                (rowTwoColumn || '').toString().toLowerCase()
                ? 1
                : -1
            }
          },
          initialState: { pageIndex: 0, pageSize: datePerPage }
        },
        useBlockLayout,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              width: 45,
              Header: ({
                toggleRowSelected,
                isAllRowsSelected,
                selectedFlatRows,
                rows,
                toggleAllRowsSelected
              }) => {
                const updatedRows = useMemo(() => {
                  const checkRows = (rows || []).map(row => {
                    const { original } = row || {}
                    const { status } = original || {}
                    if (status !== 'Pending AP Approval') {
                      row.isDisabled = true
                    } else {
                      row.isDisabled = false
                    }
                    return row
                  })
                  return checkRows
                }, [rows])
                const overridenOnChange = event => {
                  if (
                    (updatedRows || []).filter(r => r?.isDisabled === false)
                      .length
                  ) {
                    // Toggle all checkboxes to selected/unselected depending on whether the header checkbox is checked or not.
                    ;(updatedRows || [])
                      .filter(r => r?.isDisabled === false)
                      .forEach(row => {
                        toggleRowSelected(
                          row?.id,
                          event?.currentTarget?.checked
                        )
                      })
                  } else {
                    toggleAllRowsSelected(false)
                  }
                }
                const style = {
                  cursor: 'pointer'
                } // Cursor should be set to pointer while hovering over checkbox
                const checked =
                  isAllRowsSelected ||
                  (!isAllRowsSelected && (selectedFlatRows || []).length > 0) // header checkbox is only checked if all rows are checked
                const indeterminate =
                  !isAllRowsSelected && (selectedFlatRows || []).length > 0 // if some but not all rows are checked, header checkbox is indeterminate
                const disabled = useMemo(() => {
                  return (
                    (updatedRows || []).filter(r => r?.isDisabled === false)
                      .length === 0
                  )
                }, [updatedRows])
                const showTooltip = useMemo(() => {
                  return disabled
                }, [disabled])

                const newProps = {
                  onChange: overridenOnChange,
                  style: style,
                  checked: checked,
                  indeterminate: indeterminate,
                  disabled: disabled,
                  showTooltip: showTooltip
                }
                return (
                  <div>
                    <IndeterminateCheckbox {...newProps} />
                  </div>
                )
              },
              Cell: ({ row }) => {
                const rowId = +row?.id
                const { status } = row?.original || {}

                const disabled = useMemo(() => {
                  return status !== 'Pending AP Approval'
                }, [rowId, selectedRowIds, status])

                const showTooltip = useMemo(() => {
                  return disabled
                }, [disabled])
                return (
                  <div style={{ textAlign: 'center' }}>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      currentRow={row}
                      rows={rows}
                      toggleRowSelected={toggleRowSelected}
                      disabled={disabled}
                      showTooltip={showTooltip}
                      headerCheckbox={false}
                    />
                  </div>
                )
              }
            },
            ...columns
          ])
        }
      )

      useImperativeHandle(ref, () => ({
        getRows: () => {
          return rows?.map(row => row.original)
        },
        clearFilter: setAllFilters,
        toggleAllRowsSelected: toggleAllRowsSelected,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        rowCount: (rows || []).length,
        currentPage: pageIndex + 1,
        gotoPage,
        setPageSize
      }))

      useEffect(() => {
        onRowSelectStateChange(selectedFlatRows)
      }, [selectedRowIds])

      useEffect(() => {
        setIsFilterApplied(filters)
      }, [filters])

      useEffect(() => {
        setRowsPerPage(pageSize)
      }, [pageSize])

      useEffect(() => {
        setSelectedPage(pageIndex + 1)
      }, [pageIndex])

      useEffect(() => {
        setSelectedPageRowData((rows || []).length)
      }, [rows])

      const RenderRow = useCallback(
        ({ index, style }) => {
          const row = page[index]
          prepareRow(row)
          return (
            <div
              {...row.getRowProps({
                style
              })}
              className="tr">
              {row.cells.map(cell => {
                let styleBody = {}

                if (cell.column.minWidth !== 0) {
                  styleBody.minWidth = cell.column.minWidth
                }

                if (cell.column.flexGrow) {
                  styleBody.flexGrow = cell.column.flexGrow
                }

                return (
                  <div
                    {...cell.getCellProps({
                      style: {
                        textAlign: cell.column.textAlign,
                        width: cell.column.width,
                        ...styleBody
                      }
                    })}
                    className="td">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        },
        [prepareRow, rows, selectedRowIds, page]
      )

      const isSort = column => {
        if (column?.isSorted) {
          return (
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -4
              }}>
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={!column.isSortedDesc ? icons.caretUpOn : icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{ display: 'block', marginTop: '-3px' }}
                src={
                  column.isSortedDesc ? icons.caretDownOn : icons.caretDownOff
                }
                alt="Sort in descending order"
              />
            </div>
          )
        }
        if (column?.canSort) {
          return (
            <div className="table-sort">
              <img
                style={{ display: 'block', marginTop: '3px' }}
                src={icons.caretUpOff}
                alt="Sort in ascending order"
              />
              <img
                style={{ display: 'block', marginTop: '-3px' }}
                src={icons.caretDownOff}
                alt="Sort in descending order"
              />
            </div>
          )
        }
        return ''
      }

      // Render the UI for your table
      return (
        <div {...getTableProps()} className="approvals-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      <span {...column.getSortByToggleProps()}>
                        {isSort(column)}
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {(rows || []).length ? (
              <FixedSizeList
                className="full-body list-container"
                height={300}
                itemCount={(page || []).length}
                itemSize={35}
                width={totalColumnsWidth + scrollBarSize - 11}>
                {RenderRow}
              </FixedSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      )
    }
  )
)

export default PayableTable
