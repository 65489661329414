import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component-with-filter'
import moment from 'moment'
import _ from 'lodash'
import { close, exportFile } from 'ui/icons'
import './index.scss'
// Table styles
const customStyles = {
  table: {
    style: {
      borderWidth: '1px',
      borderColor: '#e5e9f2',
      borderStyle: 'solid',
      overflowY: 'auto',
      maxHeight: '300px',
      height: '100%',
    }
  },
  headCells: {
    style: {
      paddingTop: '11px',
      paddingBottom: '11px',
      paddingLeft: '10px',
      paddingRight: '10px',
      fontSize: '10px',
      fontWeight: '900',
      color: '#122048',
      lineHeight: '14px',
      backgroundColor: '#CED4DA',
      flexGrow: 'inherit !important',
      '&:last-child': {
        flexGrow: '3 !important'
      }
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: '#e5e9f2',
      borderBottomStyle: 'solid',
      minHeight: 'none',
    }
  },
  cells: {
    style: {
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '10px',
      fontWeight: '300',
      fontFamily: 'Avenir',
      color: '#122048',
      lineHeight: '14px',
      whiteSpace: 'pre-wrap',
      flexGrow: 'inherit !important',
      '&:last-child': {
        flexGrow: '3 !important'
      }
    },
  },
  rows: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: '#e5e9f2',
      borderBottomStyle: 'solid',
      paddingLeft: '0px',
      paddingRight: '0px',
      minHeight: 'none',
      '&:first-child': {
        borderBottomWidth: '1px',
        borderBottomColor: '#e5e9f2',
        borderBottomStyle: 'solid',
      },
      '&:last-child': {
        borderBottomWidth: '0px',
      }
    }
  },
  pagination: {
    style: {
      borderWidth: '1px',
      borderColor: '#e5e9f2',
      borderStyle: 'solid',
      borderTopWidth: '0px',
      color: '#122048',
      fontSize: '14px',
      fontWeight: '300',
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}

const AllInspectionNotesModal = (props) => {
  const { onClose, allNotes, inspector, unit } = props || {}

  const exportCSV = () => {
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const newData = (allNotes || []).map((d) => {
      return (Object.keys(d) || []).map((e) => {
        if(typeof(d[e]) === 'object') {
          return (_.map(d[e], 'name') || []).join(';')
        }
        return d[e]
      })
    })    
    const data = allNotes || []
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(newData.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:text/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${unit}-notes-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
    
  return (
    <div className="inspection-note-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <h2 className="is-pulled-left modal-sync-title">Inspection Notes</h2>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onClose}>
            <img
              alt="Close Modal"
              src={close}
            />
          </p>
        </div>
      </div>
      <div className="is-full">
        <h4>{`Unit ${unit}`}</h4>
        <div>
          <div className="is-flex is-align-items-center notes-export-section">
            <p>{`Inspector: ${inspector || 'Unassigned'}`}</p>
            <button className="is-flex is-align-items-center" onClick={() => exportCSV()}><img src={exportFile} alt="Export Notes" />Export</button>
          </div>
        </div>
        
        <DataTable
          columns={[
            {
              name: 'Space',
              selector: row => row.Space,
            },
            {
              name: 'Item',
              selector: row => row.Item,
            },
            {
              name: 'Observation',
              selector: row => row.Observation,
            },
            {
              name: 'Message',
              selector: row => row.Message,
            }
          ]}
          customStyles={customStyles} 
          data={allNotes}
          fixedHeader
          responsive
        />
      </div>  
    </div>  
  )
}

AllInspectionNotesModal.propTypes = {
  onClose: PropTypes.func,
  allNotes: PropTypes.array,
  unit: PropTypes.string,
  inspector: PropTypes.string,
}

export default AllInspectionNotesModal
