import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'

const Draggable = ({
  dragType,
  dragData,
  previewClass,
  dragClass,
  children,
  canDrag,
  siblingSection,
  index,
  onSort,
  listId
}) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: dragType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },

    hover(dragItem, monitor, ...rest) {
      if (!ref.current) {
        return
      }

      const dragIndex = dragItem[`${dragType}_index`]
      const hoverIndex = index

      // Don't replace items with themselves

      if (dragIndex === undefined || hoverIndex === undefined) {
        return
      }

      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (listId === dragItem.listId && onSort) {
        onSort(
          dragIndex,
          hoverIndex,
          dragType,
          dragItem.space_index,
          dragItem.item_index,
          dragItem.observation_index
        )
        dragItem[`${dragType}_index`] = hoverIndex
      }
    }
  })

  const [{ isDragging }, drag] = useDrag(
    () => ({
      item: { type: dragType, ...dragData },
      canDrag,
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    }),
    [dragData]
  )

  drag(drop(ref))

  const opacity = isDragging ? 0 : 1

  return (
    <div
      className={previewClass ? previewClass.join(' ') : ''}
      ref={ref}
      data-handler-id={handlerId}
      style={{ border: isDragging ? '1px dotted #3DB3E2' : 'none', opacity }}>
      <div className={dragClass ? dragClass.join(' ') : ''}>{children}</div>
    </div>
    // {siblingSection}
  )
}

Draggable.propTypes = {
  canDrag: PropTypes.bool,
  dragClass: PropTypes.array,
  dragData: PropTypes.object,
  previewClass: PropTypes.array,
  dragType: PropTypes.string
}

export default Draggable
