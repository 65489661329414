import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { usePermissionGate } from '../../../../helpers/hooks'

const NameColumn = ({ row, type }) => {
  const { cell, onItemChange } = row || {}
  const {
    value: initialValue,
    row: {
      original: { error },
      index
    }
  } = cell || {}

  const { hasPermission } = usePermissionGate('modify-elements')
  const [value, setValue] = useState(initialValue)

  const optimizedHandleChange = useCallback(
    debounce(
      (value, index) => {
        onItemChange(value, index)
      },
      [200]
    ),
    []
  )

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (type === 'space') {
    return (
      <div style={{ marginTop: '4px' }}>
        <div
          className="cell-item"
          style={{ paddingLeft: '0px' }}
          title={initialValue}>
          {initialValue}
        </div>
      </div>
    )
  }

  return (
    <div title={value}>
      <input
        type="text"
        className={`input action-input ${error ? 'is-danger' : ''}`}
        value={value}
        onChange={event => {
          if (hasPermission) {
            optimizedHandleChange(event.target.value, index)
            setValue(event.target.value)
          }
        }}
        disabled={!hasPermission}
      />
      {error && <p className="help is-danger">{error}</p>}
    </div>
  )
}

export default NameColumn
