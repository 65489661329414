import _ from 'lodash'

export const getProperty = (id, allProperties) => {
  return (allProperties || []).filter(property => property?.property_id === id)
}

export const getProject = (id, allProperties) => {
  const propertyIds = (allProperties || [])
    .filter(property => property?.property_id === id)
    .map(property => property?.property_id)
  const selectedProject = (allProperties || []).filter(({ property_id }) =>
    propertyIds.includes(property_id)
  )
  return selectedProject || []
}

export const getRole = (id, allRoles) => {
  return (allRoles || []).filter(role => role?.role_id === id)
}

export const groupByAndContructData = ruleData => {
  const groupedItems = _.groupBy(ruleData || [], item => item.uniqueRuleCardId)

  let data = []
  let groupedItemsData = Object.keys(groupedItems || {}) || []

  groupedItemsData.forEach(itemData => {
    if (groupedItems[itemData]?.length) {
      // For single group by array data
      if (groupedItems[itemData]?.length === 1) {
        data.push(...groupedItems[itemData])
      }
      // For multiple group by array data
      if (groupedItems[itemData]?.length > 1) {
        // For adding same id data in single attb
        let properties = []
        let projects = []
        let remainingData = {}
        ;(groupedItems[itemData] || []).forEach(item => {
          remainingData = {
            ...item
          }
          properties.push(...item?.properties)
          projects.push(...item?.projects)
        })
        const newData = {
          ...remainingData,
          properties,
          projects: [
            ...new Map(
              (projects || []).map(item => [item['project_id'], item])
            ).values()
          ],
          role: groupedItems[itemData][0].role
        }
        data.push(newData)
      }
    }
  })
  return data
}
