import React from 'react'
import cn from 'classnames'
import RestrictedElement from '../../containers/restricted-element'
import EditingActions from './editing-actions'
import ReadingActions from './reading-actions'
import './index.scss'
import { isNewInvoice } from '../../utils'

const InvoiceTitle = ({ invoice, setVendorInvoiceNumberCaveat }) => {
  return (
    <div className="columns invoice-title">
      <div className={cn('column', 'is-pulled-left', 'is-size-3', 'p-none', {'invoice-title--edit': invoice.editing})}>
        {
          isNewInvoice(invoice) ?
            'New Purchase Order' :
            `${invoice.editing ? 'Editing - ' : ''} Purchase Order #${invoice.po_number}`
        }
      </div>
      <div className="column is-narrow has-text-right p-none">
        <RestrictedElement>
          {
            invoice.editing ? <EditingActions invoice={invoice} /> : <ReadingActions setVendorInvoiceNumberCaveat={setVendorInvoiceNumberCaveat} />
          }
        </RestrictedElement>
      </div>
    </div>
  )
}

export default InvoiceTitle
