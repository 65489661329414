import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const MobileEmptyState = ({ image, title, subtitle }) => {
  return (
    <div className="vendor-empty-state">
      <img src={image} className="vendor-empty-state-image" alt="empty"/>
      <div className="has-text-centered vendor-empty-state-title">{title}</div>
      <div className="has-text-centered vendor-empty-state-subtitle">{subtitle}</div>
    </div>
  )
}

MobileEmptyState.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default MobileEmptyState
