import React from 'react'

const DropdownButton = ({ icon, title, visible = true, ...restProps }) => (
  <>
    { visible && <button className="button filter-button is-secondary" {...restProps}>
      <img src={icon} alt={title} className="p-r-md" />
      {title}
    </button>
    }
  </>
)

export default DropdownButton
