import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { states, countries } from './constants'

const DEFAULT_DEFAULTS = {
  address1: '',
  address2: '',
  city: '',
  state: 'none',
  zip: '',
  country: 'United States of America'
}

/**
 * Component for editing full addresses
 *
 * @component
 * @example
 * const defaults = {
 *  address1: '123 Street',
 *  address2: 'Suite 300',
 *  city: 'Chicago',
 *  state: 'IL',
 *  zip: '60007',
 *  country: 'United States of America'
 * }
 * return (
 *   <AddressField defaults={defaults} />
 * )
 */
const AddressField = ({ defaults = DEFAULT_DEFAULTS }) => {
  const [selectedState, setSelectedState] = useState(DEFAULT_DEFAULTS.state)
  const [selectedCountry, setSelectedCountry] = useState(
    DEFAULT_DEFAULTS.country
  )

  useEffect(() => {
    const { state, country } = defaults
    if (state !== selectedState) {
      setSelectedState(state)
    }
    if (country !== selectedCountry) {
      setSelectedCountry(country)
    }
  }, [])

  const getOptions = arr => {
    let template = []
    for (let i = 0; i < arr.length; i += 1) {
      const a = arr[i]
      template.push(<option key={`option-${a}`}>{a}</option>)
    }
    return template
  }

  return (
    <div className="columns is-desktop is-mobile is-multiline">
      <div className="column is-full">
        <input
          type="text"
          className="input"
          placeholder="Address Line 1"
          defaultValue={defaults.address1}
          name="address1"
        />
      </div>
      <div className="column is-full p-t-none">
        <input
          type="text"
          className="input"
          placeholder="Address Line 2"
          defaultValue={defaults.address2}
          name="address2"
        />
      </div>
      <div className="column is-one-third p-t-none p-r-xs">
        <input
          type="text"
          className="input is-inline"
          placeholder="City"
          defaultValue={defaults.city}
          name="city"
        />
      </div>
      <div className="column is-one-third p-t-none p-r-xs p-l-xs">
        <select
          className="input is-inline"
          value={selectedState}
          onChange={e => setSelectedState(e.target.value)}>
          <option value="none">State</option>
          {getOptions(states)}
        </select>
        <input type="hidden" name="state" value={selectedState} />
      </div>
      <div className="column is-one-third p-t-none p-l-xs">
        <input
          type="text"
          className="input is-inline"
          placeholder="Zip"
          defaultValue={defaults.zip}
          name="zip"
        />
      </div>
      <div className="column is-full p-t-none">
        <select
          className="input is-inline"
          value={selectedCountry}
          onChange={e => setSelectedCountry(e.target.value)}>
          <option value="none">Select Country</option>
          {getOptions(countries)}
        </select>
        <input type="hidden" name="country" value={selectedCountry} />
      </div>
    </div>
  )
}

AddressField.propTypes = {
  gatherData: PropTypes.func,
  /**
   * Defaults for address info, with:
   *   address1 and address2 as texts with lines in address
   *   city, state, zip and country with geographic tags
   */
  defaults: PropTypes.object
}

AddressField.defaultProps = {
  defaults: DEFAULT_DEFAULTS
}

export default AddressField
