import _ from 'lodash'
import React from 'react'
import { FIRST_STYLE } from './constants'

export const setFilterValue = (filter, key, value = null) => {
  if (value === null) {
    const stripped = { ...filter }
    delete stripped[key]
    return stripped
  }
  return {
    ...filter,
    [key]: value
  }
}

export const removeFilterKey = (filter, key) =>
  setFilterValue(filter, key, null)

export const addFilterValue = (filter, key, value) =>
  setFilterValue(filter, key, [..._.get(filter, key, []), value])

export const removeFilterValue = (filter, key, value, rawValue, lastItem) => {
  let filters = filter[key]

  if(lastItem && key !== 'exception') {
    filters = filters.slice()
    filters.splice(filters.length - 1, 1)
    if (filters.length) {
      return setFilterValue(filter, key, filters)
    }
  }

  if (Array.isArray(filters) && !lastItem) {
    filters = filters.slice()
    filters.splice(filters.findIndex(el => rawValue ? rawValue.includes(el) : value.includes(el)), 1)
    if (filters.length) {
      return setFilterValue(filter, key, filters)
    }
  }

  return removeFilterKey(filter, key === 'exception' ? value : key)
}

export const getColor = color =>
  Array.isArray(color)
    ? `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${_.get(color, 3, '1')})`
    : color

export const intersectsContainer = (x, y, objects) => {
  let intersects = false
  let i = 0
  while (i < objects.length && !intersects) {
    const rect = objects[i].getBoundingClientRect()
    intersects =
      intersects ||
      (x >= rect.x &&
        x <= rect.x + rect.width &&
        y >= rect.y &&
        y <= rect.y + rect.height)
    i += 1
  }
  return intersects
}

export const getColumnStyle = (
  columns,
  index,
  isChild,
  startStyle,
  headerRefs
) => {
  const initialStyle = {
    ...startStyle,
    ..._.get(columns, `${index}.style`, {})
  }
  if (isChild && !columns[index].adjust) {
    const parentIndex = _.reduce(
      columns.slice(0, index),
      (span, col) => span + _.get(col, 'colSpan', 1),
      0
    )
    const upperLimit =
      index < columns.length - 1
        ? index + _.get(columns, `${index}.colSpan`, 1)
        : headerRefs.current.length
    let totalWidth = 0
    for (let i = parentIndex; i < upperLimit; i++) {
      const currentParent = headerRefs.current[i]
      totalWidth += currentParent.offsetWidth
    }
    initialStyle.width = `${totalWidth}px`
  }
  return initialStyle
}

export const getColumnWrapper = (index, content, ignore) => (
  <div
    style={{
      ...(!ignore && index === 0 ? FIRST_STYLE : {}),
      display: 'inline-block'
    }}>
    {content}
  </div>
)
