import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  getAdditionalActions,
  statusOptions,
  dropdownDefaultColorIndicator
} from './config'
import AdditionalActions from 'components/additional-actions'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import { question, questionFilled } from 'icons'
import { usePermissionGate } from '../../../../helpers/hooks'

const GeneralDetails = ({
  deleteForm,
  downloadForm,
  duplicateForm,
  parentTemplate,
  formStatus,
  setFormData
}) => {
  const status = formStatus === null ? 'active' : 'inactive'
  const [showToolTip, setShowToolTip] = useState(false)

  const { hasPermission } = usePermissionGate('create-or-modify-property-forms')

  const additionalActions = getAdditionalActions(
    duplicateForm,
    downloadForm,
    deleteForm
  )

  const toolTipIcon = useMemo(() => {
    return showToolTip ? questionFilled : question
  }, [showToolTip])

  const getIndicatorColor = () =>
    statusOptions.find(option => option.value === status)?.color ||
    dropdownDefaultColorIndicator

  return (
    <div className="template__details__container">
      <div className="template__details__general m-t-md">
        <p className="has-text-weight-bold">General</p>
        <div
          className="is-flex"
          style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <p className="m-t-md template__details__general__text">
            Template used:{' '}
            <strong>
              {parentTemplate ? parentTemplate : 'No parent template'}
            </strong>
          </p>
        </div>
      </div>
      <div className="template__details__general m-t-md">
        <div className="has-text-weight-bold">
          <span style={{ verticalAlign: 'middle' }}>Status</span>
          <Tippy
            interactive
            appendTo={() => document.body}
            content={
              <div>
                <p>
                  <strong>Active</strong> means that this form can be used to create an inspection.
                </p>
                <p className="m-t-md">
                  <strong>Inactive</strong> means that the form cannot be used to create an inspection. If the form was used in an inspection before it was marked "inactive", then the previously created inspections will still have access to it.
                </p>
              </div>
            }
            placement="bottom-start"
            allowHTML
            onShow={() => setShowToolTip(true)}
            onHide={() => setShowToolTip(false)}
            theme="status">
            <img src={toolTipIcon} alt="help" className="m-l-md" />
          </Tippy>
        </div>
        <div className="select template__details__general__select">
          <div
            className="select__color-indicator"
            style={{
              backgroundColor: getIndicatorColor(),
              borderColor: getIndicatorColor()
            }}></div>
          <select
            className="input"
            disabled={!hasPermission}
            onChange={event => {
              const { value } = event.target
              if (hasPermission) {
                setFormData(prevData => {
                  return {
                    ...prevData,
                    status: value === 'active' ? null : value
                  }
                })
              }
            }}
            value={status}>
            {statusOptions.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <AdditionalActions actions={additionalActions} />
    </div>
  )
}

GeneralDetails.propTypes = {
  deleteForm: PropTypes.object,
  downloadForm: PropTypes.object,
  duplicateForm: PropTypes.object,
  parentTemplate: PropTypes.object,
  setFormData: PropTypes.func
}

export default GeneralDetails
