export const AUTH_DOMAIN =
  process.env.REACT_APP_AUTH_DOMAIN || 'dev-008o7kbs.auth0.com'
export const AUTH_CLIENT_ID =
  process.env.REACT_APP_AUTH_CLIENT_ID || '8HNZW0xciSzSUA2Lfi6SaC2EscUfOgRp'
export const MGMT_CLIENT_ID =
  process.env.REACT_APP_MGMT_CLIENT_ID || 'qC58gD9Pa1KLRppO0JB2gv5ezMn74wSW' // we are testing to see if yarn build will replace these variables.
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'service.myturnable.com'
export const PRODUCT_FRUIT_ID = process.env.REACT_APP_PRODUCT_FRUIT_ID || ''
export const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID || ''
export default {
  AUTH_DOMAIN,
  AUTH_CLIENT_ID,
  MGMT_CLIENT_ID,
  API_BASE_URL,
  PRODUCT_FRUIT_ID
}
