import React from 'react'

const FieldTemplateNoPadding = ({ children }) => {
  return (
    <div className="columns is-vcentered mb-0">
      <div className="column is-full">
        <div className="columns is-desktop is-mobile content-spacing">
          {children}
        </div>
      </div>
    </div>
  )
}

export default FieldTemplateNoPadding
