import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import ScheduleMain from '../../parents/schedule-main'
import {
  getScheduleMainData,
  resetScheduleMainData,
  getSchedulingStatus,
  resetSchedulingStatus,
  autoscheduleService
} from '../../modules/schedule'
import { openModal, closeModal, setModalContent } from '../../modules/modal'

const Schedule = props => {
  const {
    getScheduleMainData,
    resetScheduleMainData,
    getSchedulingStatus,
    schedule,
    user,
    context,
    auth
  } = props
  const {
    mainDataHasRequested,
    mainDataError,
    mainDataIsRequesting,
    schedulingStatusHasRequested
  } = schedule
  const { isAuthenticated } = auth
  const { pdbid } = user.user_metadata

  useEffect(() => {
    return () => resetScheduleMainData(pdbid, context)
  }, [])

  useEffect(() => {
    if (
      isAuthenticated &&
      pdbid !== null &&
      !mainDataHasRequested &&
      !mainDataError
    ) {
      const { pdbid } = user.user_metadata
      getScheduleMainData(pdbid, context)
    }
  }, [context, user.user_metadata, schedule])

  useEffect(() => {
    if (isAuthenticated && pdbid !== null && !schedulingStatusHasRequested) {
      getSchedulingStatus(pdbid, context)
    }
  }, [context, user.user_metadata])

  return mainDataIsRequesting ||
    user.isRequesting ||
    !user.hasMadeInitialRequest ||
    !schedulingStatusHasRequested ? (
    <BigLoading />
  ) : (
    <ScheduleMain {...props} />
  )
}

Schedule.propTypes = {
  auth: PropTypes.object,
  turnboard: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ auth, user, context, schedule }) => ({
  auth,
  user,
  context,
  schedule
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getScheduleMainData,
      resetScheduleMainData,
      getSchedulingStatus,
      resetSchedulingStatus,
      autoscheduleService,
      openModal,
      closeModal,
      setModalContent
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Schedule)
)
