import Service from '../../service'
import invoicesListMock from '../../mocks/invoices.mock'
import invoiceDetailsMock from '../../mocks/invoice_details.mock'
import {
  INVOICE_APPROVE,
  INVOICE_CREATE_REQUESTED,
  INVOICE_CREATE_SUCCESS,
  INVOICE_DELETE_REQUESTED,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DETAILS_FAIL,
  INVOICE_DETAILS_REQUESTED,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_LINE_CREATE_REQUESTED,
  INVOICE_LINE_CREATE_SUCCESS,
  INVOICE_LINE_UPDATE_REQUESTED,
  INVOICE_LINE_UPDATE_SUCCESS,
  INVOICE_LINES_GENERATE_REQUESTED,
  INVOICE_LINES_GENERATE_SUCCESS,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUESTED,
  INVOICE_LIST_SUCCESS,
  INVOICE_PAYED,
  INVOICE_SENT,
  INVOICE_UPDATE_REQUESTED,
  INVOICE_UPDATE_SUCCESS,
  RESET_INVOICES,
  INVOICE_START_EDITING,
  INVOICE_FINISH_EDITING,
  INVOICE_BACK_TO_DRAFT,
  INVOICE_ADD_TAX_REQUESTED,
  INVOICE_ADD_TAX_SUCCESS,
  INVOICE_DETAILS_RESET,
  INVOICE_CHANGE_STATUS_REQUESTED,
  INVOICE_CHANGE_STATUS_SUCCESS,
  INVOICE_AMOUNT_PAID_REQUESTED,
  INVOICE_AMOUNT_PAID_SUCCESS
} from './types'
import { invoiceMock } from '../../mocks/invoice_details'
import moment from 'moment'

const _invoicesListFail = dispatch => {
  dispatch({
    type: INVOICE_LIST_FAIL
  })
}

const _invoiceDetailsFail = dispatch => {
  dispatch({
    type: INVOICE_DETAILS_FAIL
  })
}

const DEFAULT_VALUE = ''

const _itemAdapter = item => ({
  ...item,
  item_id: typeof item.item_id === 'string' ? item.item_id : DEFAULT_VALUE,
  service: typeof item.service === 'string' ? item.service : DEFAULT_VALUE,
  item: typeof item.Item === 'string' ? item.Item : DEFAULT_VALUE,
  unit: typeof item.Unit === 'string' ? item.Unit : DEFAULT_VALUE,
  description:
    typeof item.description === 'string' ? item.description : DEFAULT_VALUE,
  notes: typeof item.Notes === 'string' ? item.Notes : DEFAULT_VALUE,
  rate: typeof item.rate === 'string' ? item.rate : DEFAULT_VALUE,
  quantity: typeof item.quantity === 'string' ? item.quantity : DEFAULT_VALUE,
  line_total:
    typeof item.line_total === 'string' ? item.line_total : DEFAULT_VALUE
})

const _coerceToString = value => (typeof value === 'string' ? value : '')

const _singleInvoiceAdapter = invoice => {
  return {
    ...invoice,
    invoice_id: _coerceToString(invoice.invoice_id),
    invoice_uri: _coerceToString(invoice.invoice_uri),
    vendor_uri: _coerceToString(invoice.vendor_uri),
    vendor_id: _coerceToString(invoice.vendor_id),
    name: _coerceToString(invoice.name),
    phone: _coerceToString(invoice.phone),
    email: _coerceToString(invoice.email),
    country: _coerceToString(invoice.country),
    addressee: _coerceToString(invoice.addressee),
    address1: _coerceToString(invoice.address1),
    address2: _coerceToString(invoice.address2),
    address3: _coerceToString(invoice.address3),
    city: _coerceToString(invoice.city),
    state: _coerceToString(invoice.state),
    zip: _coerceToString(invoice.zip),
    invoice_date: _coerceToString(invoice.invoice_date),
    due_date: _coerceToString(invoice.due_date),
    invoice_number: _coerceToString(invoice.invoice_number),
    vendor_invoice_number: _coerceToString(invoice.vendor_invoice_number),
    amount_due: _coerceToString(invoice.amount_due),
    subtotal: _coerceToString(invoice.subtotal),
    discount: _coerceToString(invoice.discount),
    amount_paid: _coerceToString(invoice.amount_paid)
  }
}

const _detailsAdapter = details => ({
  ..._singleInvoiceAdapter(details),
  items: details.po_lines.map(item => _itemAdapter(item))
})

const _listAdapter = data => data.map(invoice => _singleInvoiceAdapter(invoice))

export const resetInvoices = () => {
  return dispatch => {
    dispatch({
      type: RESET_INVOICES
    })
  }
}

/**
 * Gets invoices list
 * @redux
 * @reduxActionCreator INVOICE_LIST_REQUESTED, INVOICE_LIST_SUCCESS, INVOICE_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getInvoicesList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: INVOICE_LIST_REQUESTED
    })
    return Service.getInvoicesList(pdbid, context)
      .then(async res => {
        const list = invoicesListMock
        dispatch({
          type: INVOICE_LIST_SUCCESS,
          list: _listAdapter(list.invoices)
        })
        return res
      })
      .catch(() => {
        _invoicesListFail(dispatch)
      })
  }
}

/**
 * Gets single invoice details and lines
 * @redux
 * @reduxActionCreator INVOICE_DETAILS_REQUESTED, INVOICE_DETAILS_SUCCESS, INVOICE_DETAILS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id for the invoice
 */
export const getInvoiceDetails = (pdbid, context, invoiceId) => {
  return dispatch => {
    dispatch({
      type: INVOICE_DETAILS_REQUESTED
    })
    return Service.getInvoiceDetails(pdbid, context, invoiceId)
      .then(async () => {
        const data = invoiceMock({
          invoice_id: invoiceId,
          invoice_number: `INV-${invoiceId}`
        })
        dispatch({
          type: INVOICE_DETAILS_SUCCESS,
          details: data
        })
        /*} else {
          _invoiceDetailsFail(dispatch)
        }*/
      })
      .catch(() => {
        _invoiceDetailsFail(dispatch)
      })
  }
}

/**
 * Update requested invoice status succcessful
 * @redux
 * @reduxActionCreator INVOICE_SENT, INVOICE_APPROVE, INVOICE_PAYED
 * @param {number} invoiceId - the invoice id for the request
 */

export const backToDraft = invoiceId => {
  return dispatch => {
    dispatch({
      type: INVOICE_BACK_TO_DRAFT,
      details: invoiceMock({
        status: 'draft',
        invoiceId,
        invoice_number: `INV-${invoiceId}`
      })
    })
  }
}

export const invoiceSent = invoiceId => {
  return dispatch => {
    dispatch({
      type: INVOICE_SENT,
      details: invoiceMock({
        status: 'sent',
        invoiceId,
        invoice_number: `INV-${invoiceId}`
      })
    })
  }
}

export const invoiceApprove = invoiceId => {
  return dispatch => {
    dispatch({
      type: INVOICE_APPROVE,
      details: invoiceMock({
        status: 'approved',
        invoiceId,
        invoice_number: `INV-${invoiceId}`
      })
    })
  }
}

export const invoicePayed = invoiceId => {
  return dispatch => {
    dispatch({
      type: INVOICE_PAYED,
      details: invoiceMock({
        status: 'approved',
        invoiceId,
        invoice_number: `INV-${invoiceId}`
      })
    })
  }
}

export const changePurchaseOrderStatus = ({
  pdbid,
  context,
  invoiceId,
  status,
  approvedBy,
  pdf = '',
  vendor_email = '',
  amount_due = '',
  po_number = '',
  company = '',
  invoice_file = ''
}) => {
  return dispatch => {
    dispatch({
      type: INVOICE_CHANGE_STATUS_REQUESTED
    })
    const new_date = new Date()
    const customDate = moment(new_date).format('YYYY/MM/DD HH:mm:ss')
    const body = {
      status,
      ...(status === 'approved'
        ? {
            approved_at: customDate,
            approved_by: approvedBy
          }
        : {
            approved_at: null,
            approved_by: null
          })
    }

    if (status === 'sent') {
      body.pdf = pdf
      body.vendor_email = vendor_email
      body.amount_due = amount_due
      body.po_number = po_number
      body.company = company
    }

    body.invoice_file = invoice_file

    return Service.changePurchaseOrderStatus(pdbid, context, invoiceId, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: INVOICE_CHANGE_STATUS_SUCCESS,
            details: data[0]
          })
        } else {
          _invoiceDetailsFail(dispatch)
        }
      })
      .catch(err => {
        console.log(err)
        _invoiceDetailsFail(dispatch)
      })
  }
}

export const invoiceAddTax = (pdbid, context, invoiceId, taxAmount) => {
  return dispatch => {
    dispatch({
      type: INVOICE_ADD_TAX_REQUESTED
    })
    return Service.addPurchaseOrderTax(pdbid, context, invoiceId, taxAmount)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: INVOICE_ADD_TAX_SUCCESS
          })
        } else {
          _invoiceDetailsFail(dispatch)
        }
      })
      .catch(err => {
        console.log(err)
        _invoiceDetailsFail(dispatch)
      })
  }
}

export const invoiceAddAmountPaid = (
  pdbid,
  context,
  invoiceId,
  amountPaid,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: INVOICE_AMOUNT_PAID_REQUESTED
    })
    return Service.addPurchaseOrderAmountPaid(
      pdbid,
      context,
      invoiceId,
      amountPaid
    )
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }
          dispatch({
            type: INVOICE_AMOUNT_PAID_SUCCESS
          })
        } else {
          if (onError) {
            onError()
          }
          _invoiceDetailsFail(dispatch)
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
        _invoiceDetailsFail(dispatch)
      })
  }
}

export const attachInvoiceToPurchaseOrder = (
  pdbid,
  context,
  invoiceId,
  invoiceFilename,
  approvedBy,
  approvedAt,
  status
) => {
  return dispatch => {
    dispatch({
      type: INVOICE_UPDATE_REQUESTED
    })
    return Service.attachInvoiceToPurchaseOrder(
      pdbid,
      context,
      invoiceId,
      invoiceFilename,
      approvedBy,
      approvedAt,
      status
    )
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          return dispatch({
            type: INVOICE_UPDATE_SUCCESS,
            payload: { data }
          })
        }
        _invoiceDetailsFail(dispatch)
      })
      .catch(err => {
        console.log(err)
        _invoiceDetailsFail(dispatch)
      })
  }
}

export const detachInvoiceFromPurchaseOrder = (
  pdbid,
  context,
  invoiceId,
  status
) => {
  return dispatch => {
    dispatch({
      type: INVOICE_UPDATE_REQUESTED
    })
    return Service.detachInvoiceFromPurchaseOrder(
      pdbid,
      context,
      invoiceId,
      status
    )
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          return dispatch({
            type: INVOICE_UPDATE_SUCCESS,
            payload: { data }
          })
        }
        _invoiceDetailsFail(dispatch)
      })
      .catch(err => {
        console.log(err)
        _invoiceDetailsFail(dispatch)
      })
  }
}

/**
 * Creates a new invoice
 * @redux
 * @reduxActionCreator INVOICE_CREATE_REQUESTED, INVOICE_CREATE_SUCCESS, INVOICE_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON version of the invoice to be created
 */
export const createInvoice = (pdbid, context, body) => {
  return async dispatch => {
    dispatch({
      type: INVOICE_CREATE_REQUESTED
    })

    try {
      let [createPurchaseOrdersData, tasksData] = await Promise.all([
        Service.createPurchaseOrder(pdbid, context, body),
        Service.getPurchaseTasks(pdbid, context, body.vendor_id)
      ])

      if (createPurchaseOrdersData.ok && tasksData.ok) {
        const createPurchaseOrdersRes = await createPurchaseOrdersData.json()
        const tasksDataRes = await tasksData.json()

        dispatch({
          type: INVOICE_CREATE_SUCCESS,
          invoice: _singleInvoiceAdapter(createPurchaseOrdersRes[0]),
          tasks: tasksDataRes
        })

        dispatch({
          type: INVOICE_DETAILS_SUCCESS,
          details: createPurchaseOrdersRes.pop(),
          tasks: tasksDataRes
        })
      }
    } catch (err) {
      console.log(err)
      _invoiceDetailsFail(dispatch)
    }
  }
}

/**
 * Resets invoice details
 * @redux
 * @reduxActionCreator INVOICE_DETAILS_RESET
 */
export const resetInvoiceDetails = () => {
  return async dispatch => {
    dispatch({
      type: INVOICE_DETAILS_RESET
    })
  }
}

/**
 * Updates the main data for an invoice
 * @redux
 * @reduxActionCreator INVOICE_UPDATE_REQUESTED, INVOICE_UPDATE_SUCCESS, INVOICE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id of the existing invoice
 * @param {Object} body - the JSON version of the new data for the invoice
 */
export const updateInvoice = (pdbid, context, invoiceId, body) => {
  return async dispatch => {
    dispatch({
      type: INVOICE_UPDATE_REQUESTED
    })
    try {
      const res = await Service.updateInvoice(pdbid, context, invoiceId, body)
      if (res.ok) {
        const data = await res.json()
        dispatch({
          type: INVOICE_UPDATE_SUCCESS,
          payload: { data }
        })
      } else {
        _invoiceDetailsFail(dispatch)
      }
    } catch (e) {
      _invoiceDetailsFail(dispatch)
    }
  }
}

/**
 * Removes an invoice from the database
//  * @redux
 * @reduxActionCreator INVOICE_DELETE_REQUESTED, INVOICE_DELETE_SUCCESS, INVOICE_DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id of the existing invoice
 */
export const deleteInvoice = (pdbid, context, po_id) => {
  return dispatch => {
    dispatch({
      type: INVOICE_DELETE_REQUESTED
    })
    return Service.deleteInvoice(pdbid, context, po_id)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: INVOICE_DELETE_SUCCESS,
            po_id
          })
        } else {
          _invoiceDetailsFail(dispatch)
        }
      })
      .catch(() => {
        _invoiceDetailsFail(dispatch)
      })
  }
}

/**
 * Automatically generates lines for an invoice based on
 * the available services provided by a vendor in a range
 * of dates
 * @redux
 * @reduxActionCreator INVOICE_LINES_GENERATE_REQUESTED, INVOICE_LINES_GENERATE_SUCCESS, INVOICE_LINES_GENERATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id of the existing invoice
 * @param {Object} body - the JSON data for the automatic generation
 */
export const generateInvoiceLines = (pdbid, context, invoiceId, body) => {
  return dispatch => {
    dispatch({
      type: INVOICE_LINES_GENERATE_REQUESTED
    })

    return Service.generateInvoiceLines(pdbid, context, invoiceId, body)
      .then(async () => {
        const data = invoiceDetailsMock
        dispatch({
          type: INVOICE_LINES_GENERATE_SUCCESS,
          payload: {
            invoice: _detailsAdapter(data),
            invoiceShort: _singleInvoiceAdapter(data),
            invoiceId
          }
        })
      })
      .catch(e => {
        console.error(e)
        _invoiceDetailsFail(dispatch)
      })
  }
}

/**
 * Creates a new line for an existing invoice
 * @redux
 * @reduxActionCreator INVOICE_LINE_CREATE_REQUESTED, INVOICE_LINE_CREATE_SUCCESS, INVOICE_LINE_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id of the existing invoice
 * @param {Object} body - the JSON data for the new invoice line
 */
export const createInvoiceLine = (pdbid, context, invoiceId, body) => {
  return dispatch => {
    dispatch({
      type: INVOICE_LINE_CREATE_REQUESTED
    })
    return Service.createInvoiceLine(pdbid, context, invoiceId, body)
      .then(async res => {
        if (res.ok) {
          const item = await res.json()
          dispatch({
            type: INVOICE_LINE_CREATE_SUCCESS,
            item: _itemAdapter(item)
          })
        } else {
          _invoiceDetailsFail(dispatch)
        }
      })
      .catch(() => {
        _invoiceDetailsFail(dispatch)
      })
  }
}

/**
 * Updates the data for an existing invoice line
 * @redux
 * @reduxActionCreator INVOICE_LINE_UPDATE_REQUESTED, INVOICE_LINE_UPDATE_SUCCESS, INVOICE_LINE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id of the existing invoice
 * @param {string} lineId - the id of the existing invoice line
 * @param {Object} body - the JSON data for the new invoice line
 */
export const updateInvoiceLine = (pdbid, context, invoiceId, lineId, body) => {
  return dispatch => {
    dispatch({
      type: INVOICE_LINE_UPDATE_REQUESTED
    })
    return Service.updateInvoiceLine(pdbid, context, invoiceId, lineId, body)
      .then(async res => {
        if (res.ok) {
          const item = await res.json()
          dispatch({
            type: INVOICE_LINE_UPDATE_SUCCESS,
            item: _itemAdapter(item)
          })
        } else {
          _invoiceDetailsFail(dispatch)
        }
      })
      .catch(() => {
        _invoiceDetailsFail(dispatch)
      })
  }
}

/**
 * Removes an existing line from an invoice
 * @redux
 * @reduxActionCreator INVOICE_LINE_DELETE_REQUESTED, INVOICE_LINE_DELETE_SUCCESS, INVOICE_LINE_DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} invoiceId - the id of the existing invoice
 * @param {string} lineId - the id of the existing invoice line
 */
export const deleteInvoiceLine = (_pdbid, _context, invoice, lineId) => {
  return dispatch => {
    dispatch({
      type: INVOICE_LINE_UPDATE_REQUESTED
    })

    const { items } = invoice
    const newItems = items.filter(item => item.item_id !== lineId)

    dispatch({
      type: INVOICE_LINE_UPDATE_SUCCESS,
      payload: { items: newItems }
    })
  }
}

export const startEditingInvoice = () => {
  return dispatch => {
    dispatch({ type: INVOICE_START_EDITING })
  }
}

export const finishEditingInvoice = () => {
  return dispatch => {
    dispatch({ type: INVOICE_FINISH_EDITING })
  }
}
