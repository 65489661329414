import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Wizard from '../../components/wizard'
import ScheduleFirstStep from './schedule-first-step'
import ScheduleSecondStep from './schedule-second-step'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

const ScheduleServiceWizard = ({ servicesToSchedule, goBackToMain, handleScheduleAllModal }) => {
  const hasSubflow = true
  const [mainWizardCurrentStep, setMainWizardCurrentStep] = useState(0)

  const backActionHandler = () => {
    if(mainWizardCurrentStep - 1 < 0) {
      goBackToMain()
    } else {
      setMainWizardCurrentStep(mainWizardCurrentStep - 1)
    }
  }
  
  const continueActionHandler = () => {
    if(mainWizardCurrentStep + 1 >= servicesToSchedule.length) {
      return null
    } else {
      setMainWizardCurrentStep(mainWizardCurrentStep + 1)
    }
  }

  return (
    <div className="column p-t-none">
      <Wizard
        steps={servicesToSchedule}
        mainWizardCurrentStep={mainWizardCurrentStep}
        setMainWizardCurrentStep={setMainWizardCurrentStep}
        goBackToMain={goBackToMain}
        hasSubflow={true}
        handleScheduleAllModal={handleScheduleAllModal}
      >
        <Wizard.WizardStep>
          <ScheduleFirstStep />
        </Wizard.WizardStep>
        <Wizard.WizardStep>
          <ScheduleSecondStep />
        </Wizard.WizardStep>
      </Wizard>

      {!hasSubflow && (
        <>
          <button onClick={backActionHandler} className="text-button">
            <FontAwesomeIcon icon={faChevronLeft} />
            Back
          </button>
          <button
            className="button is-primary is-pulled-right"
            onClick={continueActionHandler}>
            Continue
          </button>
        </>
      )}
    </div>
  )
}

ScheduleServiceWizard.propTypes = {
  goBackToMain: PropTypes.func,
  servicesToSchedule: PropTypes.array
}

export default ScheduleServiceWizard
