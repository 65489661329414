import available from './icons/available.svg'
import addButton from './icons/addButton.svg'
import addButtonBlack from './icons/add-black.svg'
import addInstance from './icons/add-instance.svg'
import addTax from './icons/add-tax.svg'
import attachInvoice from './icons/attach-invoice.svg'
import calendar from './icons/calendar.svg'
import carpetClean from './icons/cp-clean.svg'
import carpetReplace from './icons/cp-rep.svg'
import clean from './icons/clean.svg'
import close from './icons/close.svg'
import closeFilled from './icons/close-filled.svg'
import duplicate from './icons/duplicate.svg'
import download from './icons/download.svg'
import edit from './icons/edit.svg'
import emptyPage from './icons/emptypage.svg'
import filter from './icons/filter.svg'
import lock from './icons/lock.svg'
import paint from './icons/paint.svg'
import search from './icons/search.svg'
import share from './icons/share.svg'
import shareV2 from './icons/share-v2.svg'
import trash from './icons/trash.svg'
import transfers from './icons/transfers.svg'
import undo from './icons/undo.svg'
import unlock from './icons/unlock.svg'
import wall from './icons/crv-rep.svg'
import warning from './icons/warning.svg'
import pin from './icons/pin.svg'
import floorplan from './icons/floorplan.svg'
import checklistNotRead from './icons/checklist-not-read.svg'
import checklist from './icons/checklist.svg'
import noWork from './icons/no-work.svg'
import noPendingWork from './icons/no-pending-work.svg'
import done from './icons/done.svg'
import emptySearch from './icons/empty-search.svg'
import helpdesk from './icons/helpdesk.svg'
import home from './icons/home.svg'
import homeClicked from './icons/home-clicked.svg'
import pending from './icons/pending.svg'
import pendingClicked from './icons/pending-clicked.svg'
import approved from './icons/approved.svg'
import approvedClicked from './icons/approved-clicked.svg'
import tabSearch from './icons/tab-search.svg'
import tabSearchClicked from './icons/tab-search-clicked.svg'
import arrowRight from './icons/arrow-right.svg'
import arrowDown from './icons/arrow-down.svg'
import circleApproved from './icons/circle-approved.svg'
import circleApprovedClicked from './icons/circle-approved-clicked.svg'
import circleNotApproved from './icons/circle-not-approved.svg'
import circleNotApprovedClicked from './icons/circle-not-approved-clicked.svg'
import hamburger from './icons/hamburger.svg'
import refresh from './icons/refresh.svg'
import print from './icons/print.svg'
import setup from './icons/setup.svg'
import inspections from './icons/inspections.svg'
import analytics from './icons/analytics.svg'
import move from './icons/move.svg'
import doubleArrow from './icons/double-arrow.svg'
import question from './icons/question.svg'
import questionFilled from './icons/question-filled.svg'
import ellipsis from './icons/ellipsis.svg'
import camera from './icons/camera.svg'
import note from './icons/note.svg'
import exportFile from './icons/export.svg'
import approvedSolution from './icons/approved-solution.svg'
import closeOutline from './icons/close-outline.svg'
import remove from './icons/remove.svg'
import reopen from './icons/reopen.svg'
import caretUpOn from './icons/caret-up-full.svg'
import caretUpOff from './icons/caret-up-empty.svg'
import caretDownOn from './icons/caret-down-full.svg'
import caretDownOff from './icons/caret-down-empty.svg'
import caretDownOffWhite from './icons/caret-down-empty-white.svg'
import caretUpOffWhite from './icons/caret-up-empty-white.svg'

// main sidebar icons
import homeIcon from './icons/main-sidebar/home.svg'
import dashboardIcon from './icons/main-sidebar/dashboard.svg'
import turnboardIcon from './icons/main-sidebar/turnboard.svg'
import scheduleIcon from './icons/main-sidebar/schedule.svg'
import keysIcon from './icons/main-sidebar/keys.svg'
import walkIcon from './icons/main-sidebar/walk.svg'
import inspectIcon from './icons/main-sidebar/inspect.svg'
import servicesIcon from './icons/main-sidebar/services.svg'
import invoicesIcon from './icons/main-sidebar/invoices.svg'
import setupIcon from './icons/main-sidebar/setup.svg'

export {
  available,
  addButton,
  addButtonBlack,
  addInstance,
  addTax,
  attachInvoice,
  calendar,
  close,
  closeFilled,
  remove,
  duplicate,
  carpetClean,
  carpetReplace,
  clean,
  edit,
  emptyPage,
  filter,
  lock,
  paint,
  search,
  share,
  shareV2,
  trash,
  transfers,
  undo,
  unlock,
  wall,
  warning,
  pin,
  floorplan,
  checklist,
  checklistNotRead,
  noWork,
  noPendingWork,
  done,
  emptySearch,
  helpdesk,
  home,
  homeClicked,
  pending,
  pendingClicked,
  approved,
  approvedClicked,
  tabSearch,
  tabSearchClicked,
  arrowRight,
  arrowDown,
  circleApproved,
  circleApprovedClicked,
  circleNotApproved,
  circleNotApprovedClicked,
  hamburger,
  refresh,
  print,
  setup,
  inspections,
  analytics,
  move,
  doubleArrow,
  question,
  questionFilled,
  ellipsis,
  camera,
  download,
  note,
  exportFile,
  approvedSolution,
  closeOutline,
  reopen,
  caretUpOn,
  caretUpOff,
  caretDownOn,
  caretDownOff,
  caretDownOffWhite,
  caretUpOffWhite
}

export const mainSidebarIcons = {
  homeIcon,
  dashboardIcon,
  turnboardIcon,
  scheduleIcon,
  keysIcon,
  walkIcon,
  inspectIcon,
  servicesIcon,
  invoicesIcon,
  setupIcon
}
